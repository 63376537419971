import gql from 'graphql-tag'

/*====== Fragments =====*/
const transactionFragment = gql`
  fragment transactionFields on TransactionType {
    id
    referenceNum
    userId
    user {
      email
    }
    typeId
    type
    statusId
    status
    jobPost
    unlockTalentCredit
    rhTalentCredit
    featureJobCredit
    urgentJobCredit
    bosspoint
    isServiceGuaranteed
    isOnlineSupported
    isCredited
    amount
    totalAmount
    paymentKey
    itemName
    itemDescription
    creditTerm
    taxType
    taxRate
    currencyAmount
    currency
    currencyExchangeRate
    salesPersonEmail
    billerEmail
    billerFirstName
    billerLastName
    billerAddress
    billerCompany
    billerZipcode
    billerState
    billerCity
    billerCountry
    invoiceNum
    invoiceUrl
    receiptNum
    receiptUrl
    contractUrl
    remark
    paidAt
    expiredAt
    createdAt
    updatedAt
    bankAccountId
    onlineTransaction {
      id
      methodId
      paymentMethod
      paypalTransaction {
        status
        paymentUrl
        paypalTransactionId
      }
      braintreeTransaction {
        status
        braintreeTransactionId
      }
      callbackLog
      remark
      createdAt
      updatedAt
    }
    offlineTransaction {
      id
      transactionId
      status
      methodId
      remark
      createdAt
      updatedAt
    }
  }
`

const invoiceFragment = gql`
  fragment invoiceFields on InvoicesType {
    id
    userId
    paymentKey
    title
    price
    token
    transactionId
    paymentStatus
    recurringId
    firstName
    lastName
    email
    billerFirstName
    billerLastName
    billerAddress
    billerCompany
    billerCountry
    billerCity
    billerState
    billerZipcode
    notes
    invoiceUrl
    createdAt
    updatedAt
  }
`

const invoiceDetailFragment = gql`
  fragment invoiceDetailFields on InvoiceType {
    id
    userId
    paymentKey
    title
    price
    token
    transactionId
    paymentStatus
    recurringId
    firstName
    lastName
    email
    billerFirstName
    billerLastName
    billerAddress
    billerCompany
    billerCountry
    billerCity
    billerState
    billerZipcode
    invoiceUrl
    notes
    createdAt
    updatedAt
  }
`

const bossjobUserFragment = gql`
  fragment bossjobUserFields on BossjobUserType {
    firstName
    lastName
    email
  }
`

/*====== Local State Query ======*/
/* invoice file */
export const GET_LOCAL_INVOICE_FILE = gql`
  {
    invoiceFile @client {
      file
    }
  }
`

/*====== Query ======*/
export const GET_ALL_INVOICES = gql`
  query getAllInvoices(
    $page: Int
    $queryData: String
    $date: InvoiceDateTimeRange
    $typeKey: String
    $status: String
  ) {
    invoices(
      page: $page
      size: 15
      queryData: $queryData
      date: $date
      typeKey: $typeKey
      status: $status
    ) {
      data {
        invoices {
          ...invoiceFields
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
  ${invoiceFragment}
`

export const GET_INVOICE_BY_ID = gql`
  query getInvoiceById($invoiceId: Int!) {
    getInvoice(invoiceId: $invoiceId) {
      data {
        ...invoiceDetailFields
      }
    }
  }
  ${invoiceDetailFragment}
`
export const GET_TRANSACTION_BY_ID = gql`
  query getTransactionById($transactionId: Int!) {
    transaction(transactionId: $transactionId) {
      data {
        ...transactionFields
      }
    }
  }
  ${transactionFragment}
`

export const GET_TRANSACTION_LIST = gql`
  query getTransactionList(
    $size: Int
    $page: Int
    $userId: Int
    $query: String
    $createdDate: TransactionDateTimeRange
    $paidDate: TransactionDateTimeRange
    $typeId: Int
    $statusId: Int
  ) {
    transactionList(
      size: $size
      page: $page
      userId: $userId
      query: $query
      createdDate: $createdDate
      paidDate: $paidDate
      typeId: $typeId
      statusId: $statusId
    ) {
      ok
      message
      data {
        totalPages
        totalNum
        currentPage
        currentNum
        transactions {
          ...transactionFields
        }
      }
    }
  }
  ${transactionFragment}
`

export const GET_BOSSJOB_USER_BY_ID = gql`
  query getBossjobUserById($userId: Int!) {
    bossjobUser(userId: $userId) {
      data {
        ...bossjobUserFields
      }
    }
  }
  ${bossjobUserFragment}
`

/*====== Local State ======*/
export const GET_LOCAL_INVOICE_DETAILS = gql`
  {
    invoiceDetail @client {
      ...invoiceDetailFields
    }
  }
  ${invoiceDetailFragment}
`
export const GET_LOCAL_TRANSACTION_DETAILS = gql`
  {
    transactionDetail @client {
      ...transactionFields
    }
  }
  ${transactionFragment}
`

export const GET_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM = gql`
  {
    transactionDetailForNewForm @client {
      typeId
      type
      jobPost
      unlockTalentCredit
      rhTalentCredit
      featureJobCredit
      urgentJobCredit
      bosspoint
      isServiceGuaranteed
      isOnlineSupported
      amount
      totalAmount
      currencyAmount
      itemName
      itemDescription
      paymentKey
      creditTerm
      taxType
      taxRate
      currency
      currencyExchangeRate
      salesPersonEmail
      billerEmail
      billerFirstName
      billerLastName
      billerAddress
      billerCompany
      billerCity
      billerState
      billerZipcode
      billerCountry
      remark
      paymentStatus
      statusId
      bankAccountId
      paymentMethodId
    }
  }
`
