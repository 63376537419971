import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

/* Bootstrap */
import {
  Table,
  Modal,
  ModalBody,
  Button,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
// import {Input} from 'reactstrap'
/* Components */
import Pagination from 'shared/components/Pagination'

import { Query } from 'react-apollo'
// import { GET_ALL_BOSSJOB_USERS } from '../../schemas/queries'
import {
  // GET_ALL_EVENTS ,
  GET_EVENTS_BY_ID
} from '../../../schemas/queries'
import Loading from 'shared/components/Loading'
import { Can } from 'shared/configs/abilityContextConfig'
/* Helpers */
import { formatDate } from 'shared/helpers/datetime'
// import queryString from 'query-string'

const stickyStyle = {
  position: 'sticky',
  left: 0,
  backgroundColor: '#ffffff'
}
/**
 * BossHuntUserList - Bosshunt user list table
 *
 */

export const handleCopyText = text => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

class BossHuntUserList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.tableHeaders = [
      { label: 'ID', style: { ...stickyStyle }, key: item => item.id },
      {
        label: 'User ID',
        key: item => (
          <a href={`/bossjob/user/${item.userId}/recruiter/event_logs/1`}>
            {item.userId}
          </a>
        )
      },
      { label: 'Email', key: item => item.user.email },
      { label: 'Phone', key: item => item.user.phoneNum },
      { label: 'First Name', key: item => item.user.firstName },
      { label: 'Last Name', key: item => item.user.lastName },
      { label: 'Withdrawn Point', key: item => item.withdrawnPoint },
      {
        label: 'Wallet Address',
        value: item => item.walletAddress,
        key: item =>
          item.walletAddress ? (
            <span>
              {item.walletAddress}{' '}
              <Button
                color="link"
                size="sx"
                onClick={() => handleCopyText(item.walletAddress)}
              >
                copy
              </Button>
            </span>
          ) : null
      },
      {
        label: 'Created At',
        key: item => item.createdAt && formatDate(item.createdAt),
        onList: false
      },
      {
        label: 'Updated At',
        key: item => item.updatedAt && formatDate(item.updatedAt),
        onList: false
      },
      {
        label: 'Transaction Link',
        edit: item => item.transactionStatus.id === '4',
        value: item => item.transactionLink,
        style: { width: '230px' },
        key: item => (
          <a href={item.transactionLink} target="__blank">
            {item.transactionLink}
          </a>
        )
      },
      {
        label: 'Transaction Fee',
        edit: item => item.transactionStatus.id === '4',
        value: item => item.transactionFee,
        key: item => item.transactionFee
      },
      {
        label: 'Money',
        key: item => item.money
      },
      {
        label: 'network',
        key: item => item.network
      },
      { label: 'Transaction Status', key: item => item.transactionStatus.value }
    ]

    this.linkRef = createRef()
    this.feeRef = createRef()
    this.remarkRef = createRef()
  }

  handleChange(e) {
    if (e.target.checked) {
      this.props.appendSelectedUser(e.target.value)
    } else {
      this.props.removeSelectedUser(e.target.value)
    }
  }

  handleSubmit = async transactionStatusId => {
    const { currentItem } = this.state
    const { updateEventTransactionRequest } = this.props
    const { remarkRef, feeRef, linkRef } = this
    const variables = {
      transactionRequestId: currentItem.id,
      statusId: transactionStatusId,
      transactionLink: linkRef.current,
      transactionFee: feeRef.current,
      remarks: remarkRef.current
    }
    await updateEventTransactionRequest({ variables })
    this.setState({
      modal: false
    })
    this.props.refetch()
  }
  renderButtons = item => {
    const { transactionStatus } = item
    if (transactionStatus.id.toString() === '1') {
      return [
        <Button
          color="success"
          key={'success'}
          onClick={() => {
            this.handleSubmit('2')
          }}
        >
          Approve
        </Button>,
        <Button
          color="danger"
          key={'danger'}
          onClick={() => {
            this.handleSubmit('3')
          }}
        >
          Reject
        </Button>
      ]
    }
    if (transactionStatus.id.toString() === '2') {
      return [
        <Button color="danger" key="1" onClick={() => this.handleSubmit('3')}>
          Reject
        </Button>,
        <Button color="primary" key="2" onClick={() => this.handleSubmit('4')}>
          Complete
        </Button>
      ]
    }
    if (
      transactionStatus.id.toString() === '3' ||
      transactionStatus.id.toString() === '4'
    ) {
      return (
        <Button
          color="primary"
          key={'danger'}
          onClick={() => {
            this.handleSubmit(transactionStatus.id)
          }}
        >
          Complete
        </Button>
      )
    }
  }
  render() {
    const {
      headhunterList,
      currentPage,
      totalHeadhunters,
      totalPages
    } = this.props
    console.log('props', this.props)
    // console.log('headhunterList',headhunterList[0].userId)
    return (
      <React.Fragment>
        <div style={{ overflow: 'auto' }}>
          <Table bordered hover>
            <thead>
              <tr>
                {/* <th style={{ width: '40px' }} /> */}
                {this.tableHeaders
                  .filter(item => item.onList !== false)
                  .map((header, index) => (
                    <th key={index} style={header.style}>
                      {header.label}
                    </th>
                  ))}
                <th style={{ ...stickyStyle, right: 0 }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {headhunterList.map(headhunter => (
                <tr key={headhunter.id}>
                  {/* <td>
                    <Input
                      addon
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      onChange={this.handleChange}
                      value={headhunter.id}
                    />
                  </td> */}
                  {this.tableHeaders
                    .filter(item => item.onList !== false)
                    .map(item => (
                      <td style={item.style} key={headhunter.id}>
                        {item.key(headhunter)}
                      </td>
                    ))}
                  <td style={{ ...stickyStyle, right: 0 }}>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.linkRef.current = headhunter.transactionLink
                        this.feeRef.current = headhunter.transactionFee
                        this.remarkRef.current = headhunter.remarks
                        this.setState({
                          modal: !this.state.modal,
                          currentItem: headhunter
                        })
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Pagination
          currentPage={Number(currentPage)}
          totalCount={totalHeadhunters}
          totalPages={totalPages}
          pageRange={4}
        />
        <Modal
          isOpen={this.state.modal}
          size="lg"
          onExit={() => this.setState({ modal: false })}
        >
          <ModalHeader>Detail</ModalHeader>
          <ModalBody>
            {this.state.modal && (
              <Query
                query={GET_EVENTS_BY_ID}
                variables={{ transactionRequestId: this.state.currentItem.id }}
              >
                {res => {
                  console.log('res', res)
                  const { loading } = res
                  // const { eventTransactionRequestList } = res.data
                  if (loading) {
                    return <Loading />
                  }
                  return (
                    <div>
                      <form>
                        {this.tableHeaders
                          .filter(item => item.onList !== false)
                          .map((item, index) => {
                            return (
                              <div className="form-group row" key={index}>
                                <label
                                  className="col-sm-4 col-form-label"
                                  style={{ fontSize: 16, fontWeight: 500 }}
                                >
                                  {item.label}
                                </label>
                                <div className="col-sm-8">
                                  {item.edit &&
                                  item.edit(this.state.currentItem) ? (
                                      <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={item.value(
                                          this.state.currentItem
                                        )}
                                        onChange={e => {
                                          if (item.label === 'Transaction Link') {
                                            this.linkRef.current = e.target.value
                                          }
                                          if (item.label === 'Transaction Fee') {
                                            this.feeRef.current = e.target.value
                                          }
                                        }}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        readOnly
                                        className="form-control-plaintext"
                                        value={
                                          item.value
                                            ? item.value(this.state.currentItem)
                                            : item.key(this.state.currentItem)
                                        }
                                      />
                                    )}
                                </div>
                              </div>
                            )
                          })}
                        <div className="mb-3">
                          <label htmlFor="validationTextarea">Remark</label>
                          <textarea
                            className="form-control"
                            id="validationTextarea"
                            defaultValue={this.state.currentItem.remarks}
                            onChange={e => {
                              this.remarkRef.current = e.target.value
                            }}
                            placeholder="remark"
                          />
                        </div>
                      </form>
                    </div>
                  )
                }}
              </Query>
            )}
          </ModalBody>
          {this.state.currentItem && (
            <ModalFooter>
              <Can I="edit" a="event_transaction_request" passThrough>
                {can => {
                  return can ? this.renderButtons(this.state.currentItem) : null
                }}
              </Can>
              <Button
                onClick={() => {
                  this.setState({
                    modal: false
                  })
                }}
              >
                close
              </Button>
            </ModalFooter>
          )}
        </Modal>
      </React.Fragment>
    )
  }
}

BossHuntUserList.propTypes = {
  /* array of headhunter list */
  headhunterList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalHeadhunters: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  appendSelectedUser: PropTypes.func.isRequired,

  removeSelectedUser: PropTypes.func.isRequired
}

export default withRouter(BossHuntUserList)
