import React, { useState } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_RECRUITER_JOB_STATISTICS
} from '../../../schemas/queries'

/* Components */
import JobStatList from '../../../views/BossJobUserDetail/RecruiterInformation/Jobs/JobStatList/JobStatList'
import Loading from 'shared/components/Loading'

/* Vendors */
import queryString from 'query-string'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

/* Bootstrap */
import { Col, Row, Button, FormGroup, Label, Form, Input } from 'reactstrap'

/* Helpers */
import { convertToISOFormat } from 'shared/helpers/datetime'

/**
 * RecruiterJobStatContainer - container component to display recruiter's job statistics
 *
 * @returns {Component}
 *
 */
const RecruiterJobStatContainer = ({ match, location }) => {
  const { fromDate, toDate } = queryString.parse(location.search)
  const [dateFrom, setDateFrom] = useState(fromDate ? new Date(fromDate) : null)
  const [dateTo, setDateTo] = useState(toDate ? new Date(toDate) : null)
  const [error, setError] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [variables, setVariables] = useState({
    page: parseInt(match.params.page),
    queryData: null,
    date: null
  })

  const handleInputChange = e => {
    setSearchText(e.target.value)
  }

  const handleSubmit = e => {
    let newVariables = {
      page: parseInt(match.params.page),
      queryData: searchText != '' ? searchText : null,
      date:
        dateFrom && dateTo
          ? {
            fromDate: convertToISOFormat(dateFrom, true),
            toDate: convertToISOFormat(dateTo, true, false)
          }
          : null
    }
    setVariables(newVariables)
    e.preventDefault()
  }

  return (
    <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
      {({ data: { bossjobUserDetail } }) => (
        <Query
          query={GET_RECRUITER_JOB_STATISTICS}
          variables={{ ...variables, userId: bossjobUserDetail.id }}
        >
          {({ loading, data: { recruiterJobsStat } }) => {
            const jobStats =
              recruiterJobsStat && recruiterJobsStat.data.jobStats
                ? recruiterJobsStat.data.jobStats
                : null
            if (loading) {
              return <Loading />
            } else {
              return (
                <React.Fragment>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Row style={{ marginBottom: 25 }}>
                        <Label md={1} sm={12}>
                          Date
                        </Label>
                        <Col md={4} sm={12}>
                          <DateTimePicker
                            format="MMM D, YYYY"
                            time={false}
                            value={dateFrom}
                            placeholder="From"
                            onChange={newDate => {
                              setDateFrom(newDate)
                              setError(false)
                            }}
                          />
                        </Col>
                        <Col md={4} sm={12}>
                          <DateTimePicker
                            format="MMM D, YYYY"
                            time={false}
                            value={dateTo}
                            placeholder="To"
                            onChange={newDate => {
                              setDateTo(newDate)
                              setError(false)
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label md={1} sm={12}>
                          Search
                        </Label>
                        <Col md={4} sm={12}>
                          <Input
                            name="search"
                            placeholder=""
                            value={searchText}
                            onChange={handleInputChange}
                          />
                        </Col>
                        <Col md={2} sm={12}>
                          <Button type="submit" color="info">
                            Filter
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                  {error ? (
                    <div style={{ color: 'red', marginBottom: '20px' }}>
                      {' '}
                      Please select both dates{' '}
                    </div>
                  ) : null}
                  <h5>
                    Total active jobs*: {recruiterJobsStat.data.totalActiveJobs}
                  </h5>
                  <h5>
                    Total job views: {recruiterJobsStat.data.totalJobViews}
                  </h5>
                  <h5>
                    Total job applications:{' '}
                    {recruiterJobsStat.data.totalJobApplications}
                  </h5>
                  <p>(*) Not affected by date filter</p>
                  <JobStatList
                    jobStats={jobStats}
                    currentPage={match.params.page}
                    totalCount={recruiterJobsStat.data.totalCount}
                    totalPages={recruiterJobsStat.data.totalPages}
                  />
                </React.Fragment>
              )
            }
          }}
        </Query>
      )}
    </Query>
  )
}

RecruiterJobStatContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.match.isRequired
}

export default withRouter(RecruiterJobStatContainer)
