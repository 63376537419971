import {
  GET_LOCAL_AGENCY_DETAIL,
  GET_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM
} from '../schemas/queries'

export default {
  updateLocalAgencyDetail: (_, { key, value, category, del }, { cache }) => {
    const { agencyDetail } = cache.readQuery({
      query: GET_LOCAL_AGENCY_DETAIL
    })

    let newAgencyDetail
    const oldServiceList = [...agencyDetail.services]
    const newServiceList = [...oldServiceList]
    const oldIndustryList = [...agencyDetail.industries]
    const newIndustryList = [...oldIndustryList]
    const oldServiceLocationList = [...agencyDetail.serviceLocations]
    const newServiceLocationList = [...oldServiceLocationList]

    switch (key) {
    case 'location':
      newAgencyDetail = { id: value, name: '', __typename: 'LocationType' }
      cache.writeData({
        data: {
          agencyDetail: {
            ...agencyDetail,
            location: newAgencyDetail,
            __typename: 'updatedAgencyType'
          }
        }
      })
      return null
    case 'companySize':
      newAgencyDetail = { id: value, name: '', __typename: 'CompanySizeType' }
      cache.writeData({
        data: {
          agencyDetail: {
            ...agencyDetail,
            companySize: newAgencyDetail,
            __typename: 'updatedAgencyType'
          }
        }
      })
      return null
    case 'services':
      newAgencyDetail = { id: value, name: '', __typename: 'ServiceType' }

      // del represents index of item to be deleted
      if (del !== null && del >= 0) {
        const updatedServiceList = newServiceList.filter(
          (object, i) => i !== del
        )
        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              services: updatedServiceList
            }
          }
        })
      } else {
        //filter off object with id == ''
        const filteredNewServiceList = newServiceList.filter(
          object => object.id
        )
        //
        // then push new value to array
        filteredNewServiceList.push(newAgencyDetail)

        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              services: filteredNewServiceList
              // services: newServiceList
            }
          }
        })
      }
      return null
    case 'industries':
      newAgencyDetail = { id: value, name: '', __typename: 'IndustryType' }

      // del represents index of item to be deleted
      if (del !== null && del >= 0) {
        const updatedIndustryList = newIndustryList.filter(
          (object, i) => i !== del
        )
        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              industries: updatedIndustryList
            }
          }
        })
      } else {
        //filter off object with id == ''
        const filteredNewIndustryList = newIndustryList.filter(
          object => object.id
        )
        //
        // then push new value to array
        filteredNewIndustryList.push(newAgencyDetail)

        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              industries: filteredNewIndustryList
            }
          }
        })
      }
      return null
    case 'serviceLocations':
      newAgencyDetail = {
        id: value,
        name: '',
        __typename: 'ServiceLocationType'
      }

      // del represents index of item to be deleted
      if (del !== null && del >= 0) {
        const updatedServiceLocationList = newServiceLocationList.filter(
          (object, i) => i !== del
        )

        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              serviceLocations: updatedServiceLocationList
            }
          }
        })
      } else {
        //filter off object with id == ''
        const filteredNewServiceLocationList = newServiceLocationList.filter(
          object => object.id
        )

        // then push new value to array
        filteredNewServiceLocationList.push(newAgencyDetail)

        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              serviceLocations: filteredNewServiceLocationList
            }
          }
        })
      }
      return null
    default:
      // whenever apollo client fetches query results from server, it will automatically caches results locally
      // apollo client caches data by matching id & __typename
      // hence, to prevent mutating the single source of truth (the initial data fetched from server),
      // we intentionally change __typename
      switch (category) {
      case 'feeStructure':
        newAgencyDetail = {
          [key]: value,
          __typename: 'CompanyFeeStructureType'
        }
        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              feeStructure: {
                ...agencyDetail.feeStructure,
                ...newAgencyDetail
              },
              __typename: 'updatedAgencyType'
            }
          }
        })
        return null
      case 'turnaround':
        newAgencyDetail = {
          [key]: Number(value),
          __typename: 'CompanyTurnaroundTimeType'
        }
        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              turnaroundTime: {
                ...agencyDetail.turnaroundTime,
                ...newAgencyDetail
              },
              __typename: 'updatedAgencyType'
            }
          }
        })
        return null
      case 'guarantee':
        newAgencyDetail = {
          [key]: Number(value),
          __typename: 'CompanyGuaranteePeriodType'
        }
        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              guaranteePeriod: {
                ...agencyDetail.guaranteePeriod,
                ...newAgencyDetail
              },
              __typename: 'updatedAgencyType'
            }
          }
        })
        return null
      case 'replacement':
        newAgencyDetail = {
          [key]: value,
          __typename: 'CompanyFreeReplacementType'
        }
        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              freeReplacement: {
                ...agencyDetail.freeReplacement,
                ...newAgencyDetail
              },
              __typename: 'updatedAgencyType'
            }
          }
        })
        return null
      default:
        newAgencyDetail = { [key]: value, __typename: 'updatedAgencyType' }
        cache.writeData({
          data: {
            agencyDetail: {
              ...agencyDetail,
              ...newAgencyDetail
            }
          }
        })
        return null
      }
    }
  },
  updateLocalAgencyDetailForNewForm: (_, { key, value }, { cache }) => {
    const { agencyDetailForNewForm } = cache.readQuery({
      query: GET_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM
    })

    let newAgencyDetail
    switch (key) {
    case 'location':
      newAgencyDetail = { id: value, name: '', __typename: 'LocationType' }
      cache.writeData({
        data: {
          agencyDetailForNewForm: {
            ...agencyDetailForNewForm,
            location: newAgencyDetail,
            __typename: 'updatedAgencyType'
          }
        }
      })
      return null
    default:
      newAgencyDetail = {
        [key]: value,
        __typename: 'updatedAgencyType'
      }
      cache.writeData({
        data: {
          agencyDetailForNewForm: {
            ...agencyDetailForNewForm,
            ...newAgencyDetail
          }
        }
      })

      return null
    }
  }
}
