import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { compose, graphql } from 'react-apollo'
import { DELETE_BOSSJOB_USERS } from '../../schemas/mutations'

/* Components */
import BossJobUserListContainer from '../../containers/BossJobUserListContainer'
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * BossJobUsers - main Bossjob user list view
 *
 */
class BossJobUsers extends Component {
  static propTypes = {
    deleteUsers: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedUsers: []
    }

    this.appendSelectedUser = this.appendSelectedUser.bind(this)
    this.removeSelectedUser = this.removeSelectedUser.bind(this)
    this.deleteUsers = this.deleteUsers.bind(this)
  }

  /**
   *
   * @function appendSelectedUser - Append userId to selectedUsers state
   * @param {Number} userId
   */
  appendSelectedUser(userId) {
    this.setState({
      selectedUsers: [...this.state.selectedUsers, Number(userId)]
    })
  }

  /**
   *
   * @function removeSelectedUser - Remove specific userId from selectedUsers state
   * @param {Number} userId
   */
  removeSelectedUser(userId) {
    let selectedUsers = this.state.selectedUsers
    selectedUsers.splice(this.state.selectedUsers.indexOf(Number(userId)), 1)
    this.setState({
      selectedUsers: selectedUsers
    })
  }

  async deleteUsers() {
    try {
      let response = await this.props.deleteUsers({
        variables: {
          ids: this.state.selectedUsers
        }
      })

      if (response) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const typeOptions = [
      { all: 'All' },
      { jobseeker: 'Jobseeker' },
      { recruiter: 'Recruiter' }
    ]

    return (
      <div>
        <SearchBar hasType typeOptions={typeOptions} />
        <Can I="edit" a="bossjob_user" passThrough>
          {can => (
            <SectionWrapper
              sectionTitle="Bossjob Users"
              isMainPage
              handleDelete={can ? this.deleteUsers : null}
            >
              <BossJobUserListContainer
                appendSelectedUser={this.appendSelectedUser}
                removeSelectedUser={this.removeSelectedUser}
              />
            </SectionWrapper>
          )}
        </Can>
      </div>
    )
  }
}

export default compose(graphql(DELETE_BOSSJOB_USERS, { name: 'deleteUsers' }))(
  BossJobUsers
)
