import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    loginAdminUser(email: $email, password: $password) {
      accessToken
      ok
    }
    captureError(errorMessage: "Testing Error") @client
  }
`
