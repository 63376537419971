import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { graphql, compose } from 'react-apollo'
import { EXPORT_TRANSACTION_LIST_EXCEL } from '../../schemas/mutations'

/* Vendors */
import queryString from 'query-string'

/* Components */
import { Button } from 'reactstrap'
import ConfirmationAlert from 'shared/components/ConfirmationAlert'

/**
 * CloseJobButton - Apollo Component to export all jobs
 *
 * @returns {Component}
 */
class ExportTransactionListButton extends Component {
  static propTypes = {
    // exportPayload: PropTypes.object,

    exportTransactionListExcel: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isVisible: false }
  }

  render() {
    const isVisible = this.state.isVisible
    const { exportTransactionListExcel } = this.props

    const { search, fromDate, toDate, type, status } = queryString.parse(
      location.search
    )

    let statusId
    switch (status) {
    case 'pending':
      statusId = 1
      break
    case 'processing':
      statusId = 2
      break
    case 'completed':
      statusId = 3
      break
    case 'cancelled':
      statusId = 4
      break
    case 'failed':
      statusId = 5
      break
    default:
      break
    }

    let variables = {
      query: search ? search : null,
      createdDate:
        fromDate && toDate
          ? {
            fromDate,
            toDate
          }
          : null,
      typeId: type ? (type === 'online' ? 1 : 2) : null,
      statusId
    }

    const exportTransaction = async () => {
      exportTransactionListExcel({
        variables: variables
      })
        .then(response => {
          if (
            response.data &&
            response.data.exportTransactionListExcel.message
          ) {
            window.location.href = response.data.exportTransactionListExcel.url
            alert(response.data.exportTransactionListExcel.message)
          }
        })
        .catch(err => {
          alert(err.message)
        })

      this.setState({ isVisible: false })
    }

    return (
      <React.Fragment>
        <Button
          color="success"
          onClick={() => this.setState({ isVisible: true })}
        >
          Export Transactions Record
        </Button>
        <ConfirmationAlert
          isVisible={isVisible}
          title="Export all transactions"
          body="Are you sure you want to export all transactions with the selected filters?"
          onConfirm={exportTransaction}
          onClose={() => this.setState({ isVisible: false })}
        />
      </React.Fragment>
    )
  }
}

export default compose(
  graphql(EXPORT_TRANSACTION_LIST_EXCEL, {
    name: 'exportTransactionListExcel'
  })
)(ExportTransactionListButton)
