import gql from 'graphql-tag'

/** Local State **/
export const UPDATE_LOCAL_INVOICE_DETAILS = gql`
  mutation UpdateLocalInvoiceDetails($key: String, $value: String) {
    updateLocalInvoiceDetails(key: $key, value: $value) @client {
      ok
    }
  }
`
export const UPDATE_LOCAL_TRANSACTION_DETAILS = gql`
  mutation UpdateLocalTransactionDetails($key: String, $value: String) {
    updateLocalTransactionDetails(key: $key, value: $value) @client {
      ok
    }
  }
`
export const UPDATE_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM = gql`
  mutation UpdateLocalTransactionDetailsForNewForm(
    $key: String
    $value: String
  ) {
    updateLocalTransactionDetailsForNewForm(key: $key, value: $value) @client {
      ok
    }
  }
`

export const UPDATE_LOCAL_INVOICE_FILE = gql`
  mutation UpdateLocalInvoiceFile($file: UploadInvoiceFile) {
    updateLocalInvoiceFile(file: $file) @client {
      ok
    }
  }
`

/** Mutations **/
export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($invoiceId: Int!, $invoices: InvoiceObj) {
    updateInvoice(invoiceId: $invoiceId, invoices: $invoices) {
      ok
    }
  }
`

export const ADD_INVOICE = gql`
  mutation AddInvoice($invoices: AddInvoiceObj) {
    addInvoice(invoices: $invoices) {
      ok
    }
  }
`

export const UPLOAD_INVOICE_FILE = gql`
  mutation UploadInvoiceFile($file: Upload!, $id: Int!, $userId: Int!) {
    uploadInvoice(file: $file, invoiceId: $id, userId: $userId) {
      ok
    }
  }
`

export const ADD_OFFLINE_TRANSACTION = gql`
  mutation AddOfflineTransaction(
    $transactionInput: AddTransactionInput
    $userId: Int!
  ) {
    addOfflineTransaction(
      transactionInput: $transactionInput
      userId: $userId
    ) {
      ok
    }
  }
`

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction(
    $remark: String
    $statusId: Int!
    $transactionId: Int!
    $itemDescription: String
    $creditTerm: Int!
    $amount: Float!
    $currency: String!
    $currencyExchangeRate: Float!
    $taxType: String!
    $taxRate: Int!
    $salesPersonEmail: String
    $billerEmail: String
    $billerAddress: String!
    $billerCity: String!
    $billerCompany: String!
    $billerCountry: String!
    $billerFirstName: String!
    $billerLastName: String!
    $billerState: String!
    $billerZipcode: String!
    $bankAccountId: Int!
    $offlinePaymentMethodId: Int
  ) {
    updateTransaction(
      remark: $remark
      statusId: $statusId
      transactionId: $transactionId
      itemDescription: $itemDescription
      creditTerm: $creditTerm
      amount: $amount
      currency: $currency
      currencyExchangeRate: $currencyExchangeRate
      taxType: $taxType
      taxRate: $taxRate
      salesPersonEmail: $salesPersonEmail
      billerEmail: $billerEmail
      billerAddress: $billerAddress
      billerCity: $billerCity
      billerCompany: $billerCompany
      billerCountry: $billerCountry
      billerFirstName: $billerFirstName
      billerLastName: $billerLastName
      billerState: $billerState
      billerZipcode: $billerZipcode
      bankAccountId: $bankAccountId
      offlinePaymentMethodId: $offlinePaymentMethodId
    ) {
      ok
    }
  }
`

/** Get download url of transaction list of a recruiter in excel */
export const EXPORT_TRANSACTION_LIST_EXCEL = gql`
  mutation exportTransactionListExcel(
    $query: String
    $createdDate: TransactionDateTimeRange
    $typeId: Int
    $statusId: Int
  ) {
    exportTransactionListExcel(
      query: $query
      createdDate: $createdDate
      typeId: $typeId
      statusId: $statusId
    ) {
      ok
      message
      url
    }
  }
`

export const UPLOAD_TRANSACTION_CONTRACT = gql`
  mutation UploadTransactionContract(
    $contractFile: Upload!
    $transactionId: Int!
  ) {
    uploadTransactionContract(
      contractFile: $contractFile
      transactionId: $transactionId
    ) {
      ok
      message
    }
  }
`

export const SEND_INVOICE_EMAIL = gql`
  mutation SendInvoiceEmail($transactionId: Int!) {
    sendInvoiceEmail(transactionId: $transactionId) {
      ok
    }
  }
`

export const SEND_RECEIPT_EMAIL = gql`
  mutation SendReceiptEmail($transactionId: Int!) {
    sendReceiptEmail(transactionId: $transactionId) {
      ok
    }
  }
`
