import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_COMPANY_DETAIL } from '../../../schemas/queries'
import {
  UPDATE_LOCAL_COMPANY_DETAIL,
  UPDATE_COMPANY_DETAIL
} from '../../../schemas/mutations'

/* Components */
import SocialLinkForm from '../../../views/CompanyDetail/CompanyInformation/SocialLinkForm/SocialLinkForm'

/**
 * CompanySocialLinkContainer - container component to mutate Bossjob company description
 * and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class CompanySocialLinkContainer extends Component {
  static propTypes = {
    canEdit: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false
    }
  }

  /**
   *
   * @function handleUserInputUpdate - handle when user updating the field
   * and it will trigger the local data mutation
   *
   * @param apolloClient
   * @param key
   * @param value
   */
  handleUserInputUpdate(apolloClient, key, value) {
    apolloClient.mutate({
      mutation: UPDATE_LOCAL_COMPANY_DETAIL,
      variables: {
        key: key,
        value: value
      }
    })
  }

  /**
   *
   * @function saveCompanyDetail - handle when user updating the field
   * and it will trigger the network mutation
   *
   * @param apolloClient
   */
  saveCompanyDetail = async apolloClient => {
    this.setState({
      isLoading: true
    })

    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let benefits = companyDetail.benefits.map(benefit => benefit.benefitId)
    let cultures = companyDetail.cultures.map(culture => culture.cultureId)
    benefits = benefits.filter(q => q)
    cultures = cultures.filter(q => q)

    const formPayload = {
      description: companyDetail.description,
      descriptionHtml: companyDetail.descriptionHtml,
      culturesId: cultures,
      benefitsId: benefits,
      industryKey: companyDetail.industryKey,
      countryKey: companyDetail.countryKey,
      locationKey: companyDetail.locationKey,
      companySizeKey: companyDetail.companySizeKey
        ? companyDetail.companySizeKey
        : '0_to_50',
      name: companyDetail.name,
      facebookUrl: companyDetail.facebookUrl,
      linkedinUrl: companyDetail.linkedinUrl,
      instagramUrl: companyDetail.instagramUrl,
      youtubeUrl: companyDetail.youtubeUrl,
      linkedinCompanyId: companyDetail.linkedinCompanyId
    }

    let response = await apolloClient.mutate({
      mutation: UPDATE_COMPANY_DETAIL,
      variables: {
        companyId: companyDetail.id,
        payload: formPayload
      }
    })

    if (response) {
      window.location.reload()
    }
  }

  render() {
    return (
      <Query query={GET_LOCAL_COMPANY_DETAIL}>
        {({ data: { companyDetail } }) => {
          return (
            <React.Fragment>
              <SocialLinkForm
                companyDetail={companyDetail}
                handleChange={this.handleUserInputUpdate}
                handleSave={this.saveCompanyDetail}
                isLoading={this.state.isLoading}
                canEdit={this.props.canEdit}
              />
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default CompanySocialLinkContainer
