import gql from 'graphql-tag'

export const UPDATE_SUBSCRIPTION_PLAN_DETAIL = gql`
  mutation UpdateSubscriptionPlanDetail(
    $subscriptionPlanId: ID!
    $subscriptionPlanInput: UpdateSubscriptionPlanInput
    $subscriptionFeatureInput: UpdateSubscriptionFeatureInput
  ) {
    updateSubscriptionPlan(
      subscriptionPlanId: $subscriptionPlanId
      subscriptionPlanInput: $subscriptionPlanInput
      subscriptionFeatureInput: $subscriptionFeatureInput
    ) {
      ok
      message
    }
  }
`
export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation CreateSubscriptionPlan(
    $subscriptionPlanInput: CreateSubscriptionPlanInput
    $subscriptionFeatureInput: CreateSubscriptionFeatureInput
  ) {
    createSubscriptionPlan(
      subscriptionPlanInput: $subscriptionPlanInput
      subscriptionFeatureInput: $subscriptionFeatureInput
    ) {
      ok
      message
    }
  }
`

/* LOCAL STATE */
export const UPDATE_LOCAL_SUBSCRIPTION_PLAN_DETAIL = gql`
  mutation UpdateLocalSubscriptionPlanDetail($key: String, $value: String) {
    updateLocalSubscriptionPlanDetail(key: $key, value: $value) @client {
      ok
    }
  }
`
export const UPDATE_LOCAL_SUBSCRIPTION_PLAN_DETAIL_FOR_NEW_FORM = gql`
  mutation UpdateLocalSubscriptionPlanDetailForNewForm(
    $key: String
    $value: String
  ) {
    updateLocalSubscriptionPlanDetailForNewForm(key: $key, value: $value)
      @client {
      ok
    }
  }
`
