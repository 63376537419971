import gql from 'graphql-tag'

const FETCH_ANALYSIS_COMPANY = gql`
  query AnalysisCompany(
    $companyId: Int!
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    analysisCompany(
      companyId: $companyId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ok
      message
      data {
        verifiedHrCount
        activeHrCount
        activeJobCount
        publishedJobCount
        activeJobFunctionCount
        postedJobAllStatusCount
        viewJobCount
        chatInitiatedByTalentCount
        viewTalentCount
        chatInitiatedByBossCount
        effectiveTotalChatCount
      }
    }
  }
`

const FETCH_ANALYSIS_HR = gql`
  query AnalysisHr(
    $companyId: Int!
    $page: Int!
    $size: Int!
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    analysisHr(
      companyId: $companyId
      page: $page
      size: $size
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ok
      message
      data {
        totalPages
        currentPage
        size
        totalCount
        hrs {
          hrId
          viewTalentCount
          chatInitiatedByBossCount
          effectiveChatInitiatedByBossCount
          viewJobCount
          postedJobCount
          postedJobUrl
          publishedJobCount
          featureCreditUseCount
          urgentCreditUseCount
          unlockCreditUseCount
          chatInitiatedByTalentCount
          effectiveChatInitiatedByTalentCount
          totalChatCount
          effectiveTotalChatCount
          fullName
          email
        }
      }
    }
  }
`

const FETCH_ANALYSIS_JOB = gql`
  query AnalysisJob(
    $companyId: Int!
    $page: Int!
    $size: Int!
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    analysisJob(
      companyId: $companyId
      page: $page
      size: $size
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ok
      message
      data {
        totalPages
        currentPage
        size
        totalCount
        jobs {
          activeJobCount
          postedJobCount
          viewTalentCount
          chatInitiatedByBossCount
          effectiveChatInitiatedByBossCount
          viewJobCount
          chatInitiatedByTalentCount
          effectiveChatInitiatedByTalentCount
          totalChatCount
          effectiveTotalChatCount
          titleName
        }
      }
    }
  }
`

const FETCH_ANALYSIS_COMPANY_EXPORT_EXCEL = gql`
  mutation AnalysisCompanyExcel(
    $companyId: Int!
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    analysisCompanyExcel(
      companyId: $companyId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ok
      message
    }
  }
`

const FETCH_ANALYSIS_JOB_EXPORT_EXCEL = gql`
  mutation AnalysisJobExcel(
    $companyId: Int!
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    analysisJobExcel(
      companyId: $companyId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ok
      message
    }
  }
`

const FETCH_ANALYSIS_HR_EXPORT_EXCEL = gql`
  mutation AnalysisHrExcel(
    $companyId: Int!
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    analysisHrExcel(
      companyId: $companyId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ok
      message
    }
  }
`

export {
  FETCH_ANALYSIS_COMPANY,
  FETCH_ANALYSIS_HR,
  FETCH_ANALYSIS_JOB,
  FETCH_ANALYSIS_COMPANY_EXPORT_EXCEL,
  FETCH_ANALYSIS_JOB_EXPORT_EXCEL,
  FETCH_ANALYSIS_HR_EXPORT_EXCEL
}
