import React from 'react'
import { withRouter } from 'react-router-dom'

/* Companies */
import SearchBar from './SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'
import NeptuneCompaniesListContainer from '../../containers/NeptuneCompaniesListContainer'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

const NeptuneCompanies = () => {
  return (
    <main>
      <SearchBar />

      <Can I="edit" a="bossjob_user" passThrough>
        {() => (
          <SectionWrapper sectionTitle="Neptune companies" isMainPage>
            <NeptuneCompaniesListContainer />
          </SectionWrapper>
        )}
      </Can>
    </main>
  )
}

export default withRouter(NeptuneCompanies)
