import React from 'react'

/* Components */
// import SectionWrapper from 'shared/components/SectionWrapper'
// import Loading from 'shared/components/Loading'
// import FeaturedBannerContainer from './FeaturedBannerContainer'
// import { Query } from 'react-apollo'
// import { GET_FEATURED_BANNER } from '../../schemas/queries'
import LandingPageBannerFilterContainer from './LandingPageBannerFilterContainer'

/**
 * Companies - main Companies list view
 *
 */
const LandingPageBanner = () => (
  <div>
    {/* <SectionWrapper sectionTitle="Landing Page Banner" isMainPage>
      <p style={{ color: 'red', fontSize: '16px' }}>
        Sort order must be unique among all the banners! (create and edit banner
        1 by 1 as the page will be refreshed upon request)
      </p>
      <Query query={GET_FEATURED_BANNER}>
        {({ data: { featureBanners }, loading, error }) => {
          if (loading) {
            return <Loading />
          } else if (error) {
            return <div>Something went wrong.</div>
          }

          // const featuredCompanies = banners.data.map(obj => {
          //   return {
          //     id: obj.company.id,
          //     value: obj.company.name
          //   }
          // })

          if (featureBanners) {
            return <FeaturedBannerContainer featureBanners={featureBanners} />
          }
          return null
        }}
      </Query>
    </SectionWrapper> */}
    <LandingPageBannerFilterContainer />
  </div>
)

export default LandingPageBanner
