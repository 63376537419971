import React from 'react'

import { Table } from 'reactstrap'
import Pagination from 'shared/components/Pagination'

const CompanySalesPersonLogsList = ({
  data,
  currentPage,
  totalPages,
  totalCount,
  adminList
}) => {
  const logs = data.getCompanySalesPersonLogs.data.companySalesPersonLogs
  const getEmailById = (adminList, id) => {
    const admin = adminList.find(admin => admin.id == id)
    return admin ? admin.email : null
  }
  return (
    <>
      <Table bordered>
        <thead>
          <tr>
            <th />
            <th>ID</th>
            <th>Sales Person</th>
            <th>Appointed by</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {logs
            ? logs.map(log => {
              const email = getEmailById(adminList, log.appointedByAdminId)
              return (
                <tr key={log.id}>
                  <td />
                  <td>{log.id}</td>
                  <td>
                    {log.salesPersonEmail ? log.salesPersonEmail : 'None'}
                  </td>
                  <td>{email ? email : 'System'}</td>
                  <td>
                    {log.createdAt.slice(0, 10)} ( {log.createdAt.slice(11)} )
                  </td>
                </tr>
              )
            })
            : null}
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalCount={totalCount}
        totalPages={totalPages}
        pageRange={4}
      />
    </>
  )
}

export default CompanySalesPersonLogsList
