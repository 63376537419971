import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
// import { compose, graphql } from 'react-apollo'
// import { DELETE_BOSSJOB_USERS } from '../../schemas/mutations'

/* Components */
import BossHuntUserListContainer from '../../containers/BossHuntUserListContainer'
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'
import { withRouter } from 'react-router-dom'
import { Can } from 'shared/configs/abilityContextConfig'
// import _ from 'lodash'
// import { queryString } from 'query-string';

/**
 * BossHuntUsers - main Bosshunt user list view
 *
 */
class BossHuntUsers extends Component {
  static propTypes = {
    deleteUsers: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedUsers: []
    }
  }

  /**
   *
   * @function appendSelectedUser - Append userId to selectedUsers state
   * @param {Number} userId
   */
  appendSelectedUser = userId => {
    this.setState({
      selectedUsers: [...this.state.selectedUsers, Number(userId)]
    })
  }

  /**
   *
   * @function removeSelectedUser - Remove specific userId from selectedUsers state
   * @param {Number} userId
   */
  removeSelectedUser = userId => {
    let selectedUsers = this.state.selectedUsers
    selectedUsers.splice(this.state.selectedUsers.indexOf(Number(userId)), 1)
    this.setState({
      selectedUsers: selectedUsers
    })
  }

  addUsers = () => (window.location.href = '/bosshunt/user/new')

  // deleteUsers = async () => {
  //   try {
  //     let response = await this.props.deleteUsers({
  //       variables: {
  //         ids: this.state.selectedUsers
  //       }
  //     })

  //     if (response) {
  //       window.location.reload()
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  onSearch = _queryString => {
    this.props.history.push({ search: _queryString })
    // this.setState({
    //   queryString,
    // })
  }

  render() {
    const statusOptions = [
      { All: 'All' },
      { Pending: 'Pending' },
      { Rejected: 'Rejected' },
      { Approve: 'Approve' },
      { Completed: 'Completed' }
    ]
    // userId, withdrawnPoint, transactionStatusId
    return (
      <div>
        <SearchBar
          hasStatus
          statusOptions={statusOptions}
          searchArr={[
            { key: 'ID', value: 'userId', placeholder: 'User ID' },
            {
              key: 'Point',
              value: 'withdrawnPoint',
              placeholder: 'Withdrawn Point'
            }
          ]}
          onSearch={this.onSearch}
        />
        <Can I="edit" a="event_transaction_request" passThrough>
          <SectionWrapper
            sectionTitle="Event Request"
            isMainPage
            // handleAdd={can ? this.addUsers : null}
            // handleDelete={can ? this.deleteUsers : null}
          >
            <BossHuntUserListContainer
              queryString={this.state.queryString}
              appendSelectedUser={this.appendSelectedUser}
              removeSelectedUser={this.removeSelectedUser}
            />
          </SectionWrapper>
        </Can>
      </div>
    )
  }
}

export default withRouter(BossHuntUsers)
// export default compose(graphql(DELETE_BOSSJOB_USERS, { name: 'deleteUsers' }))(
//   BossHuntUsers
// )
