import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Vendors */
import FontAwesome from 'react-fontawesome'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/**
 * BosspointHistoryList - Bossjob user list table
 *
 */
const BosspointHistoryList = ({
  bossPointList,
  currentPage,
  totalCount,
  totalPages,
  handleModal
}) => (
  <Can I="edit" a="points" passThrough>
    {can => (
      <React.Fragment>
        <Table bordered>
          <thead>
            <tr>
              <th />
              <th>Description</th>
              <th style={{ textAlign: 'center' }}>Quantity</th>
              <th>Date</th>
              <th>Note</th>
              {can && <th>Add note</th>}
            </tr>
          </thead>
          <tbody>
            {bossPointList.map(item => (
              <tr key={`bosspoint-${item.id}`}>
                <td />
                <td>{item.title}</td>
                <td style={{ textAlign: 'center' }}>{item.point}</td>
                <td>{formatDate(item.createdAt, 'DD/MM/YYYY HH:mm:ss')}</td>
                <td>{item.notes}</td>
                {can && (
                  <td>
                    <Button
                      color="info"
                      onClick={() =>
                        handleModal({
                          variables: {
                            isVisible: true,
                            modalData: {
                              header: 'Add note',
                              id: item.id,
                              notes: item.notes,
                              __typename: 'ModalDetail'
                            }
                          }
                        })
                      }
                    >
                      <FontAwesome name="edit" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={Number(currentPage)}
          totalCount={totalCount}
          totalPages={totalPages}
          pageRange={4}
        />
      </React.Fragment>
    )}
  </Can>
)

BosspointHistoryList.propTypes = {
  /* array of user list */
  bossPointList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalCount: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  handleModal: PropTypes.func.isRequired
}

export default BosspointHistoryList
