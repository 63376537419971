import React from 'react'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

import RecruiterSettingsFormContainer from './RecruiterSettingsFormContainer'
import NotAuthorize from 'shared/components/NotAuthorize'

export default () => (
  <Can I="view" a="recruiter_settings" passThrough>
    {can => (can ? <RecruiterSettingsFormContainer /> : <NotAuthorize />)}
  </Can>
)
