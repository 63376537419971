import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import { UPDATE_LOCAL_BOSSHUNT_USER } from '../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import { GET_LOCAL_BOSSHUNT_USER_DETAIL } from '../../../schemas/queries'
import SpecializationForm from '../../../views/BossHuntUserDetail/MyAds/SpecializationForm.jsx'

const SpecializationFormContainer = ({
  match,
  headhunterServerData,
  handleChangeForExistingForm,
  handleSpecializationChange
}) => {
  return (
    <Query query={GET_LOCAL_BOSSHUNT_USER_DETAIL}>
      {({ loading, data: { bosshuntUserDetail } }) => {
        if (loading) {
          return <Loading />
        }
        return (
          <SpecializationForm
            match={match}
            userDetail={bosshuntUserDetail}
            headhunterServerData={headhunterServerData}
            handleChange={handleChangeForExistingForm}
            handleSpecializationChange={handleSpecializationChange}
          />
        )
      }}
    </Query>
  )
}

SpecializationFormContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  headhunterServerData: PropTypes.object.isRequired,
  handleSpecializationChange: PropTypes.func,
  handleChangeForExistingForm: PropTypes.func
}

export default compose(
  graphql(UPDATE_LOCAL_BOSSHUNT_USER, {
    name: 'updateLocalBosshuntUser'
  })
)(SpecializationFormContainer)
