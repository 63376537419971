import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_RECRUITER_JOB_LIST_BY_ID
} from '../../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import JobList from '../../../views/BossJobUserDetail/RecruiterInformation/Jobs/JobList'

/**
 * RecruiterJobListContainer - container component to query recruiter's job list
 * and return JobList component when is done
 *
 * @returns {Component}
 *
 */
const RecruiterJobListContainer = ({ match, location }) => {
  const {
    search,
    fromDate,
    toDate,
    status,
    supportedCountryId
  } = queryString.parse(location.search)

  let variables = {
    page: match.params.page,
    queryData: search ? search : null,
    date:
      fromDate && toDate
        ? {
          fromDate,
          toDate
        }
        : null,
    statusKey: status,
    countryId: supportedCountryId,
    size: 100
  }

  const exportPayload = {
    recruiterId: match.params.id,
    fromDate: fromDate,
    toDate: toDate,
    status: status,
    supportedCountryId: supportedCountryId
  }

  return (
    <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
      {({ data: { bossjobUserDetail } }) => (
        <Query
          query={GET_RECRUITER_JOB_LIST_BY_ID}
          variables={{ ...variables, userId: bossjobUserDetail.id }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loading />
            } else {
              return (
                <JobList
                  jobList={data.recruiterJobs.data.jobs}
                  currentPage={match.params.page}
                  totalCount={data.recruiterJobs.data.totalCount}
                  totalPages={data.recruiterJobs.data.totalPages}
                  exportPayload={exportPayload}
                />
              )
            }
          }}
        </Query>
      )}
    </Query>
  )
}

RecruiterJobListContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.match.isRequired
}

export default withRouter(RecruiterJobListContainer)
