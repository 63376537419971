/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
// import ToggleButton from 'shared/components/ToggleButton'

/**
 * PaymentDetailForm - A part of the Subscription Detail form which
 * contains additional payment details e.g: payment terms, installment etc
 *
 */
const PaymentDetailForm = ({
  handleChange,
  customHandleChange,
  handleChangeForNewFormInstallment,
  handleChangeForNewFormTotalAmount,
  subscriptionDetail,
  subscriptionTransactionHistory,
  isDisabled
}) => {
  const [bankOptionList, setBankOptionList] = useState([])
  useEffect(
    () => {
      let optionList = []
      if (subscriptionDetail && subscriptionDetail.paymentMethodId == 1) {
        optionList = [
          { bankAccountId: 3, label: 'PH BANK' },
          { bankAccountId: 1, label: 'SG BANK PHP' },
          { bankAccountId: 2, label: 'SG BANK USD' }
        ]
        customHandleChange('bankAccountId', 3)
      } else {
        optionList = [{ bankAccountId: 3, label: 'PH BANK' }]
      }
      setBankOptionList(optionList)
    },
    [subscriptionDetail.paymentMethodId]
  )

  const _renderTotalAmountField = () => {
    return (
      <FormGroup>
        <Label>Total Amount (PHP)</Label>
        <Input
          type="number"
          name="totalAmount"
          data-installment={subscriptionDetail.noOfInstallment}
          value={
            subscriptionDetail && subscriptionDetail.totalAmount
              ? subscriptionDetail.totalAmount
              : null
          }
          onChange={handleChangeForNewFormTotalAmount}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  const _renderInstallmentField = () => {
    let installmentOptions = []
    const isDivisible = duration => {
      for (let number = 1; number <= duration; number++) {
        if (duration % number === 0) {
          installmentOptions.push(number)
        }
      }
    }
    if (subscriptionDetail && subscriptionDetail.paymentDuration)
      isDivisible(subscriptionDetail.paymentDuration)
    return (
      <FormGroup>
        <Label>Installment</Label>
        <Input
          type="select"
          name="noOfInstallment"
          value={
            subscriptionDetail &&
            subscriptionDetail.noOfInstallment &&
            subscriptionDetail.noOfInstallment
          }
          onChange={handleChangeForNewFormInstallment}
          disabled={isDisabled}
        >
          {installmentOptions.map((option, index) => (
            <option
              data-total-amount={subscriptionDetail.totalAmount}
              key={index}
              value={option}
            >
              {option}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  }

  const _renderInvoiceAmountField = () => {
    return (
      <FormGroup>
        <Label>Invoice Amount (PHP)</Label>
        <Input
          type="text"
          name="invoiceAmount"
          disabled={true}
          value={
            subscriptionDetail &&
            subscriptionDetail.amount &&
            subscriptionDetail.paymentTypeId === 2
              ? subscriptionDetail.amount
              : subscriptionDetail.invoiceAmount
                ? subscriptionDetail.invoiceAmount
                : null
          }
        />
      </FormGroup>
    )
  }

  const _renderPaymentMethodField = () => {
    if (subscriptionDetail && subscriptionDetail.paymentMethodId) {
      return (
        <FormGroup>
          <Label>Payment Method</Label>
          <Input
            type="select"
            name="paymentMethodId"
            onChange={handleChange}
            value={
              subscriptionDetail && subscriptionDetail.paymentMethodId
                ? subscriptionDetail.paymentMethodId
                : null
            }
          >
            <option value={1}>Bank Transfer</option>
            <option value={2}>Cheque</option>
          </Input>
        </FormGroup>
      )
    }
  }

  const _renderPaymentTermsField = () => {
    return (
      <FormGroup>
        <Label>Payment Terms (days)</Label>
        <Input
          type="number"
          name="creditTerm"
          onChange={handleChange}
          disabled={isDisabled}
          value={
            subscriptionDetail && subscriptionDetail.creditTerm
              ? subscriptionDetail.creditTerm
              : ''
          }
        />
      </FormGroup>
    )
  }
  const _renderTaxTypeField = () => {
    return (
      <FormGroup>
        <Label>Tax Type</Label>
        <Input
          type="select"
          name="taxType"
          onChange={handleChange}
          disabled={isDisabled}
          value={
            subscriptionTransactionHistory &&
            subscriptionTransactionHistory[0] &&
            subscriptionTransactionHistory[0].transaction &&
            subscriptionTransactionHistory[0].transaction.taxType
              ? subscriptionTransactionHistory[0].transaction.taxType
              : subscriptionDetail && subscriptionDetail.taxType
                ? subscriptionDetail.taxType
                : ''
          }
        >
          <option value="gst">GST</option>
          <option value="vat">VAT</option>
        </Input>
      </FormGroup>
    )
  }
  const _renderTaxRateField = () => {
    return (
      <FormGroup>
        <Label>Tax Rate (%)</Label>
        <Input
          type="number"
          name="taxRate"
          onChange={handleChange}
          disabled={isDisabled}
          value={
            subscriptionTransactionHistory &&
            subscriptionTransactionHistory[0] &&
            subscriptionTransactionHistory[0].transaction &&
            subscriptionTransactionHistory[0].transaction.taxRate
              ? subscriptionTransactionHistory[0].transaction.taxRate
              : subscriptionDetail && subscriptionDetail.taxRate
                ? subscriptionDetail.taxRate
                : ''
          }
        />
      </FormGroup>
    )
  }

  const _renderPaymentInfoField = () => {
    if (subscriptionDetail && subscriptionDetail.paymentMethodId) {
      return (
        <FormGroup>
          <Label>Payment Info</Label>
          <Input
            type="select"
            name="bankAccountId"
            value={
              subscriptionDetail && subscriptionDetail.bankAccountId
                ? subscriptionDetail.bankAccountId
                : null
            }
            onChange={handleChange}
            disabled={isDisabled}
          >
            {bankOptionList.map(option => (
              <option key={option.label} value={option.bankAccountId}>
                {option.label}
              </option>
            ))}
          </Input>
        </FormGroup>
      )
    }
  }

  return (
    <SectionWrapper sectionTitle="Pricing and Payment Details">
      <Form>
        <Row>
          <Col md={6}>{_renderTotalAmountField()}</Col>
          <Col md={6}>{_renderInstallmentField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderInvoiceAmountField()}</Col>
        </Row>
        {subscriptionDetail &&
          subscriptionDetail.paymentMethodId && (
            <Row>
              <Col md={6}>{_renderPaymentMethodField()}</Col>
              <Col md={6}>{_renderPaymentInfoField()}</Col>
            </Row>
          )}
        <Row>
          <Col md={6}>{_renderPaymentTermsField()}</Col>
          <Col md={6}>{_renderTaxTypeField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderTaxRateField()}</Col>
        </Row>
      </Form>
    </SectionWrapper>
  )
}

PaymentDetailForm.propTypes = {
  transactionDetail: PropTypes.object,
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func.isRequired,
  customHandleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

export default PaymentDetailForm
