import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Bootstrap */
import { Row, Col } from 'reactstrap'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import {
  GET_SUBSCRIPTION_BY_ID,
  GET_BOSSJOB_USER_BY_ID,
  GET_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM
} from '../../schemas/queries'
import { GET_SUBSCRIPTION_PLAN_LIST } from 'modules/subscription_plans/schemas/queries'
import { GET_ME } from '../../../users/schemas/queries'
import {
  UPDATE_LOCAL_SUBSCRIPTION_DETAILS,
  UPDATE_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM
} from '../../schemas/mutations'

/* Components */
import DetailForm from '../../views/SubscriptionDetail/Forms/DetailForm'
import BillingAddressForm from '../../views/SubscriptionDetail/Forms/BillingAddressForm'
import SubscriptionTransactionHistory from '../../views/SubscriptionDetail/SubscriptionTransactionHistory'
import SubscriptionCreditHistory from '../../views/SubscriptionDetail/SubscriptionCreditHistory'
import SubmitForm from '../../views/SubscriptionDetail/Forms/SubmitForm'
import PaymentDetailForm from '../../views/SubscriptionDetail/Forms/PaymentDetailForm'
import ContractUploadForm from '../../views/SubscriptionDetail/Forms/ContractUploadForm/ContractUploadForm'
import Loading from 'shared/components/Loading'

/**
 * SubscriptionDetailContainer
 *
 * @returns {Component}
 */
class SubscriptionDetailContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    updateLocalSubscriptionDetails: PropTypes.func.isRequired,
    updateLocalSubscriptionDetailsForNewForm: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      subscriptionId: props.match.params.subscriptionId,
      userId: props.match.params.userId,
      file: null,
      subscriptionPlanIndex: 0
    }
  }

  _updateLocalSubscriptionDetailState = (key, value) => {
    const { updateLocalSubscriptionDetails } = this.props
    updateLocalSubscriptionDetails({
      variables: {
        key: key,
        value: value
      }
    })
  }
  _updateLocalSubscriptionDetailStateForNewForm = (key, value) => {
    const { updateLocalSubscriptionDetailsForNewForm } = this.props
    updateLocalSubscriptionDetailsForNewForm({
      variables: {
        key: key,
        value: value
      }
    })
  }

  handleChangeForExistingForm = e => {
    this._updateLocalSubscriptionDetailState(e.target.name, e.target.value)
  }

  handleChangeForNewForm = e => {
    this._updateLocalSubscriptionDetailStateForNewForm(
      e.target.name,
      e.target.value
    )
  }

  customHandleChangeForNewForm = (key, value) => {
    this._updateLocalSubscriptionDetailStateForNewForm(key, value)
  }

  customHandleChangeForExistingForm = (key, value) => {
    this._updateLocalSubscriptionDetailState(key, value)
  }

  handleChangeForNewFormSubscriptionPlanId = e => {
    const data = e.target.options[e.target.selectedIndex].dataset

    this._updateLocalSubscriptionDetailStateForNewForm(
      e.target.name,
      e.target.value
    )
    this._updateLocalSubscriptionDetailStateForNewForm('name', data.name)
    this._updateLocalSubscriptionDetailStateForNewForm(
      'jobPost',
      data.monthlyJobCredit
    )
    this._updateLocalSubscriptionDetailStateForNewForm('amount', data.amount)
    this._updateLocalSubscriptionDetailStateForNewForm(
      'totalAmount',
      data.amount
    )
    this._updateLocalSubscriptionDetailStateForNewForm(
      'invoiceAmount',
      data.amount
    )
    this._updateLocalSubscriptionDetailStateForNewForm(
      'unlockTalentCredit',
      data.monthlyUnlockTalentCredit
    )
    this._updateLocalSubscriptionDetailStateForNewForm(
      'rhTalentCredit',
      data.monthlyRhTalentCredit
    )
    this._updateLocalSubscriptionDetailStateForNewForm(
      'featureJobCredit',
      data.monthlyFeatureJobCredit
    )
    this._updateLocalSubscriptionDetailStateForNewForm(
      'urgentJobCredit',
      data.monthlyUrgentJobCredit
    )

    this.setState({
      subscriptionPlanIndex: parseInt(data.subscriptionPlanIndex)
    })
  }

  handleChangeForNewFormDuration = e => {
    const data = e.target.options[e.target.selectedIndex].dataset
    const totalAmount = e.target.value * data.amount
    this._updateLocalSubscriptionDetailStateForNewForm(
      e.target.name,
      Number(e.target.value)
    )

    this._updateLocalSubscriptionDetailStateForNewForm(
      'totalAmount',
      totalAmount
    )
    this._updateLocalSubscriptionDetailStateForNewForm(
      'invoiceAmount',
      totalAmount
    )
  }

  handleChangeForNewFormInstallment = e => {
    const data = e.target.options[e.target.selectedIndex].dataset
    const invoiceAmount = data.totalAmount / e.target.value

    this._updateLocalSubscriptionDetailStateForNewForm(
      e.target.name,
      e.target.value
    )

    this._updateLocalSubscriptionDetailStateForNewForm(
      'invoiceAmount',
      invoiceAmount
    )
  }

  handleChangeForNewFormTotalAmount = e => {
    const data = e.target.dataset
    const invoiceAmount = e.target.value / data.installment

    this._updateLocalSubscriptionDetailStateForNewForm(
      e.target.name,
      e.target.value
    )

    this._updateLocalSubscriptionDetailStateForNewForm(
      'invoiceAmount',
      invoiceAmount
    )
  }

  endDateFormatter = (value, duration) => {
    const monthThatDoesNotHave31Days = [2, 4, 6, 9, 11]
    const startDate = new Date(value)
    const month = startDate.getMonth() + 1 // as January = 0
    const day = startDate.getDate()
    const year = startDate.getFullYear()

    let endDay = startDate.getDate()
    let endMonth = month + duration - 1
    let endYear = year

    // if day falls on 29/30/31, and next month is February, endDate should be 1 of March
    // E.g: startDate is 31 Jan, endDate should be 1 Mar
    if (endDay >= 29 && endDay <= 31 && endMonth === 1) {
      endDay = 0
      endMonth += 1
    }
    // if day falls on 31, and next month last day is 30, endDate should be last day of next month
    // E.g: startDate is 31 May, endDate should be 30 June
    else if (day === 31 && monthThatDoesNotHave31Days.includes(endMonth - 1)) {
      endDay = 0
      endMonth += 1
    }
    return new Date(endYear, endMonth, endDay, 0, 0, 0, 0)
  }

  renderExistingForm(subscriptionId) {
    let getSubscriptionPlanListVariables = {
      page: 1,
      size: 999,
      sort: 2
    }

    const { subscriptionPlanIndex } = this.state

    return (
      <Query query={GET_SUBSCRIPTION_BY_ID} variables={{ subscriptionId }}>
        {({ loading, data: { subscription }, client, refetch }) => {
          if (loading) return <Loading />
          client.writeData({
            data: {
              subscriptionDetail: subscription.data
            }
          })
          return (
            <Query
              query={GET_BOSSJOB_USER_BY_ID}
              variables={{ userId: subscription.data.userId }}
            >
              {({ loading, data: { bossjobUser } }) => {
                if (loading) return <Loading />
                return (
                  <Query
                    query={GET_SUBSCRIPTION_PLAN_LIST}
                    variables={getSubscriptionPlanListVariables}
                  >
                    {({ loading, data: { subscriptionPlanList } }) => {
                      if (loading) return <Loading />
                      return (
                        <Row>
                          <Col md={8}>
                            <DetailForm
                              subscriptionId={subscriptionId}
                              subscriptionDetail={subscription.data}
                              subscriptionPlanList={
                                subscriptionPlanList.data.subscriptionPlans
                              }
                              subscriptionPlanIndex={subscriptionPlanIndex}
                              handleChange={this.handleChangeForExistingForm}
                              handleChangeForSubscriptionPlanId={
                                this.handleChangeForExistingForm
                              }
                              isDisabled={subscriptionId !== null}
                            />
                            <PaymentDetailForm
                              subscriptionDetail={subscription.data}
                              subscriptionTransactionHistory={
                                subscription.data.transactionHistories
                              }
                              handleChange={this.handleChangeForNewForm}
                              customHandleChange={
                                this.customHandleChangeForExistingForm
                              }
                              isDisabled={true}
                            />
                            <ContractUploadForm
                              subscriptionId={subscriptionId}
                              subscriptionDetail={subscription.data}
                            />
                            <SubscriptionTransactionHistory
                              transactionHistories={
                                subscription.data.transactionHistories
                              }
                            />
                            <SubscriptionCreditHistory
                              creditHistories={
                                subscription.data.creditHistories
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <SubmitForm
                              subscriptionDetail={subscription.data}
                              handleChange={this.handleChangeForExistingForm}
                              formType="Existing"
                              userId={subscription.data.userId}
                              userName={`${bossjobUser.data.firstName} ${
                                bossjobUser.data.lastName
                              }`}
                              refetch={refetch}
                              isDisabled={subscriptionId !== null}
                            />
                          </Col>
                        </Row>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }

  renderNewForm() {
    const { userId, subscriptionPlanIndex } = this.state

    let getSubscriptionPlanListVariables = {
      page: 1,
      size: 999,
      sort: 2
    }

    return (
      <Query
        query={GET_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM}
        variables={{ userId }}
      >
        {({ loading, data: { subscriptionDetailForNewForm } }) => {
          if (loading) return <Loading />
          return (
            <Query query={GET_ME}>
              {({ loading, data }) => {
                if (loading) {
                  return <Loading />
                }
                return (
                  <Query query={GET_BOSSJOB_USER_BY_ID} variables={{ userId }}>
                    {({ loading, data: { bossjobUser } }) => {
                      if (loading) return <Loading />
                      return (
                        <Query
                          query={GET_SUBSCRIPTION_PLAN_LIST}
                          variables={getSubscriptionPlanListVariables}
                        >
                          {({ loading, data: { subscriptionPlanList } }) => {
                            if (loading) return <Loading />
                            return (
                              <Row>
                                <Col md={8}>
                                  <DetailForm
                                    subscriptionDetail={
                                      subscriptionDetailForNewForm
                                    }
                                    subscriptionPlanList={
                                      subscriptionPlanList.data
                                        .subscriptionPlans
                                    }
                                    subscriptionPlanIndex={
                                      subscriptionPlanIndex
                                    }
                                    handleChange={this.handleChangeForNewForm}
                                    handleChangeForSubscriptionPlanId={
                                      this
                                        .handleChangeForNewFormSubscriptionPlanId
                                    }
                                    customHandleChangeForNewForm={
                                      this.customHandleChangeForNewForm
                                    }
                                    handleChangeForDuration={
                                      this.handleChangeForNewFormDuration
                                    }
                                    isDisabled={false}
                                    endDateFormatter={this.endDateFormatter}
                                  />
                                  <PaymentDetailForm
                                    subscriptionDetail={
                                      subscriptionDetailForNewForm
                                    }
                                    customHandleChange={
                                      this.customHandleChangeForNewForm
                                    }
                                    handleChange={this.handleChangeForNewForm}
                                    handleChangeForNewFormInstallment={
                                      this.handleChangeForNewFormInstallment
                                    }
                                    handleChangeForNewFormTotalAmount={
                                      this.handleChangeForNewFormTotalAmount
                                    }
                                    isDisabled={false}
                                  />
                                  <BillingAddressForm
                                    subscriptionDetail={
                                      subscriptionDetailForNewForm
                                    }
                                    handleChange={this.handleChangeForNewForm}
                                  />
                                </Col>
                                <Col md={3}>
                                  <SubmitForm
                                    subscriptionDetail={
                                      subscriptionDetailForNewForm
                                    }
                                    handleChange={this.handleChangeForNewForm}
                                    customHandleChangeForNewForm={
                                      this.customHandleChangeForNewForm
                                    }
                                    formType="New"
                                    submitType="Add"
                                    userId={userId}
                                    userName={`${bossjobUser.data.firstName} ${
                                      bossjobUser.data.lastName
                                    }`}
                                    salesPersonEmail={data.me.email}
                                    billerEmail={bossjobUser.data.email}
                                  />
                                </Col>
                              </Row>
                            )
                          }}
                        </Query>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }

  render() {
    const { subscriptionId } = this.state
    if (subscriptionId) {
      return this.renderExistingForm(subscriptionId)
    } else {
      return this.renderNewForm()
    }
  }
}

export default compose(
  graphql(UPDATE_LOCAL_SUBSCRIPTION_DETAILS, {
    name: 'updateLocalSubscriptionDetails'
  }),
  graphql(UPDATE_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM, {
    name: 'updateLocalSubscriptionDetailsForNewForm'
  })
)(withRouter(SubscriptionDetailContainer))
