import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import UpdateButtonContainer from '../../../../containers/SubscriptionDetailContainer/UpdateButtonContainer'
import SubmitButtonContainer from '../../../../containers/SubscriptionDetailContainer/SubmitButtonContainer'
import CancelSubscriptionButton from './CancelSubscriptionButton/CancelSubscriptionButton'

// /* Permission */
// import { Can } from 'shared/configs/abilityContextConfig'

/**
 * Submit Form - A part of the Transaction Detail form which
 * contains transaction details e.g: Reference Number, Amount,
 * Item Name, Description and Payment Method
 *
 */

const SubmitForm = ({
  userId,
  subscriptionDetail,
  isDisabled,
  refetch,
  formType,
  handleChange,
  customHandleChangeForNewForm,
  billerEmail,
  salesPersonEmail
}) => {
  useEffect(() => {
    if (formType === 'New') {
      customHandleChangeForNewForm('billerEmail', billerEmail)
      customHandleChangeForNewForm('salesPersonEmail', salesPersonEmail)
    }
  }, [])

  const _renderRemarkField = () => (
    <FormGroup>
      <Label style={{ fontWeight: 600 }}>Remarks</Label>
      <Input
        type="textarea"
        name="remark"
        onChange={handleChange}
        value={
          subscriptionDetail && subscriptionDetail.remark
            ? subscriptionDetail.remark
            : ''
        }
        disabled={isDisabled}
      />
    </FormGroup>
  )

  const _renderBillerEmail = () => (
    <FormGroup>
      <Label style={{ fontWeight: 600 }}>Biller Email*</Label>
      <Input
        type="email"
        name="billerEmail"
        onChange={handleChange}
        value={
          subscriptionDetail && subscriptionDetail.billerEmail
            ? subscriptionDetail.billerEmail
            : ''
        }
        disabled={isDisabled}
      />
    </FormGroup>
  )

  const _renderSalesPersonEmail = () => (
    <FormGroup>
      <Label style={{ fontWeight: 600 }}>Sales Person Email*</Label>
      <Input
        type="email"
        name="salesPersonEmail"
        onChange={handleChange}
        value={
          subscriptionDetail && subscriptionDetail.salesPersonEmail
            ? subscriptionDetail.salesPersonEmail
            : ''
        }
        disabled={false}
      />
    </FormGroup>
  )

  const _renderSubscriptionStatus = () => (
    <div style={{ marginBottom: '10px' }}>
      <Label style={{ fontWeight: 600, marginRight: '5px' }}>Status:</Label>
      <span>
        {subscriptionDetail && subscriptionDetail.isActive
          ? 'Active'
          : 'Inactive'}
      </span>
    </div>
  )

  const _renderSubscriptionCreditStatus = () => (
    <div style={{ marginBottom: '10px' }}>
      <Label style={{ fontWeight: 600, marginRight: '5px' }}>
        Credit item status:
      </Label>
      <span>
        {subscriptionDetail && subscriptionDetail.isStopped
          ? 'Paused'
          : 'Continue'}
      </span>
    </div>
  )

  const style = {
    bold: {
      fontWeight: 600
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px'
  }

  return (
    <SectionWrapper sectionTitle="Status">
      <Form>
        <Row>
          <Col>{_renderRemarkField()}</Col>
        </Row>
        <Row>
          <Col>
            {_renderBillerEmail()}
            {/* {formType === 'Existing' ? null : _renderBillerEmail()} */}
          </Col>
        </Row>
        <Row>
          <Col>{_renderSalesPersonEmail()}</Col>
        </Row>
        <Row>
          <Col>{_renderSubscriptionStatus()}</Col>
        </Row>
        <Row>
          <Col>{_renderSubscriptionCreditStatus()}</Col>
        </Row>
      </Form>
      {formType === 'Existing' ? (
        subscriptionDetail && subscriptionDetail.isActive === true ? (
          <div style={style}>
            <UpdateButtonContainer />
            <CancelSubscriptionButton
              refetch={refetch}
              subscriptionDetail={subscriptionDetail}
            />
          </div>
        ) : (
          <div style={style}>
            <UpdateButtonContainer />
          </div>
        )
      ) : (
        <div style={style}>
          <SubmitButtonContainer userId={userId} isDisabled={isDisabled} />
        </div>
      )}
    </SectionWrapper>
  )
}

SubmitForm.propTypes = {
  subscriptionDetail: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  formType: PropTypes.any,
  userName: PropTypes.string.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
  refetch: PropTypes.func,
  customHandleChangeForNewForm: PropTypes.func,
  billerEmail: PropTypes.string,
  salesPersonEmail: PropTypes.string
}

export default SubmitForm
