import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, compose, graphql } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_BLACKLIST_USERS_BY_ID
} from '../../../schemas/queries'
import { REMOVE_BLACKLISTED_USER } from '../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import BlacklistedUserList from '../../../views/BossJobUserDetail/Blacklists/BlacklistedUserList'

class BlacklistedUserListContainer extends Component {
  static propTypes = {
    removeBlackListedUser: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.removeUser = this.removeUser.bind(this)
  }

  async removeUser(blacklistId) {
    const { removeBlackListedUser } = this.props
    try {
      let response = await removeBlackListedUser({
        variables: {
          id: blacklistId
        }
      })

      if (response) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <Query
            query={GET_BLACKLIST_USERS_BY_ID}
            variables={{ userId: bossjobUserDetail.id }}
          >
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else {
                return (
                  <BlacklistedUserList
                    userList={data.blacklistUser.data}
                    handleDelete={this.removeUser}
                  />
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}

export default compose(
  graphql(REMOVE_BLACKLISTED_USER, { name: 'removeBlackListedUser' })
)(BlacklistedUserListContainer)
