import React, { Component } from 'react'

/* Apollo */
import { Query, Mutation } from 'react-apollo'
import {
  GET_BOSSJOB_RECRUITER_SETTINGS,
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_BOSSJOB_RECRUITER_VERIFY_STATUS
} from '../../../schemas/queries'
import {
  UPDATE_RECRUITER_SETTINGS,
  UPDATE_RECRUITER_COMPANY
} from '../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import RecruiterSettingsForm from '../../../views/BossJobUserDetail/RecruiterInformation/RecruiterSettingsForm'

/**
 * RecruiterSettingsFormContainer - container component to mutate Bossjob recruiter setting
 * and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class RecruiterSettingsFormContainer extends Component {
  render() {
    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <Query
            query={GET_BOSSJOB_RECRUITER_VERIFY_STATUS}
            variables={{ userId: bossjobUserDetail.id }}
            fetchPolicy="network-only"
          >
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else {
                const isVerify = data.bossjobUser.data.isVerify
                return (
                  <Query
                    query={GET_BOSSJOB_RECRUITER_SETTINGS}
                    variables={{ userId: bossjobUserDetail.id }}
                    fetchPolicy="network-only"
                  >
                    {({ loading, data }) => {
                      if (loading) {
                        return <Loading />
                      } else {
                        let settings = data.recruiterSettings.data
                          ? data.recruiterSettings.data
                          : {}
                        settings.userId = bossjobUserDetail.id
                        settings.isVerify = isVerify === 1 ? true : false

                        return (
                          <Mutation
                            mutation={UPDATE_RECRUITER_SETTINGS}
                            onCompleted={() => {
                              window.location.reload()
                            }}
                          >
                            {(UpdateRecruiterSettings, { loading }) => {
                              let isUpdatingSetting = loading

                              return (
                                <Mutation mutation={UPDATE_RECRUITER_COMPANY}>
                                  {(UpdateRecruiterCompany, { loading }) => (
                                    <RecruiterSettingsForm
                                      settings={settings}
                                      handleUpdateSetting={
                                        UpdateRecruiterSettings
                                      }
                                      handleUpdateCompany={
                                        UpdateRecruiterCompany
                                      }
                                      isUpdating={loading || isUpdatingSetting}
                                      isInactive={
                                        bossjobUserDetail.statusKey ===
                                        'inactive'
                                      }
                                    />
                                  )}
                                </Mutation>
                              )
                            }}
                          </Mutation>
                        )
                      }
                    }}
                  </Query>
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}

export default RecruiterSettingsFormContainer
