import moment from 'moment'

/**
 * convertToISOFormat -> convert date to ISO format
 */
export const convertToISOFormat = (date, hasTime = false, start = true) => {
  let newdate = moment(date).format('YYYY-MM-DD')

  if (hasTime) {
    if (start) {
      /* if start date then add midnight timing */
      newdate = newdate + 'T00:00:00'
    } else {
      /* if end date then add closer to midnight timing */
      newdate = newdate + 'T23:59:59'
    }
    return newdate
  } else {
    return newdate
  }
}

/**
 * formatDate -> format date to a selected format
 */
export const formatDate = (date, format = 'DD/MM/YYYY HH:mm') => {
  if (!date) return '-'
  return moment(date).format(format)
}
