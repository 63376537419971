import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { compose, graphql } from 'react-apollo'
import { DELETE_ADMIN_USERS } from '../../../schemas/mutations'

/* Bootstrap */
import { Table, Button, Input } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Vendors */
import FontAwesome from 'react-fontawesome'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/**
 * UserList - user list table
 *
 */
class UserList extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.deleteAdminUser = this.deleteAdminUser.bind(this)
  }

  handleChange(e) {
    if (e.target.checked) {
      this.props.appendSelectedUser(e.target.value)
    } else {
      this.props.removeSelectedUser(e.target.value)
    }
  }

  async deleteAdminUser(userId) {
    console.log(userId)
    try {
      let response = await this.props.deleteAdminUsers({
        variables: {
          ids: [Number(userId)]
        }
      })

      if (response.data.deleteAdminUser.ok) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const { userList, currentPage, totalUsers, totalPages, myId } = this.props

    return (
      <React.Fragment>
        <Table bordered>
          <thead>
            <tr>
              <th />
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userList.map(user => {
              if (user.id !== myId) {
                return (
                  <tr key={`admin-${user.id}`}>
                    <td>
                      <Input
                        addon
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        onChange={this.handleChange}
                        value={user.id}
                      />
                    </td>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.role.roleName}</td>
                    <td>{user.isActive === 1 ? 'Active' : 'Inactive'}</td>
                    <td>{formatDate(user.createdAt, 'DD/MM/YYYY HH:mm:Ss')}</td>
                    <td>
                      <a href={`/user/${user.id}`}>
                        <Button color="info">
                          <FontAwesome name="edit" />
                        </Button>
                      </a>
                      <Can I="edit" a="admin_user">
                        <Button
                          color="danger"
                          onClick={() => this.deleteAdminUser(user.id)}
                          style={{ marginLeft: '5px' }}
                        >
                          <FontAwesome name="trash" />
                        </Button>
                      </Can>
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>
        </Table>
        <Pagination
          currentPage={Number(currentPage)}
          totalCount={Number(totalUsers)}
          totalPages={Number(totalPages)}
          pageRange={4}
        />
      </React.Fragment>
    )
  }
}

UserList.propTypes = {
  /* array of user list */
  userList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalUsers: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  appendSelectedUser: PropTypes.func.isRequired,

  removeSelectedUser: PropTypes.func.isRequired,

  deleteAdminUsers: PropTypes.func.isRequired,

  myId: PropTypes.string.isRequired
}

export default compose(
  graphql(DELETE_ADMIN_USERS, { name: 'deleteAdminUsers' })
)(UserList)
