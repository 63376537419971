import React, { Component } from 'react'

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

class PaginationComponent extends Component {
  constructor(props) {
    super(props)
  }

  getPageList = () => {
    const MAX_PAGES = 5 // Maximum number of visible pages without ellipsis
    const visiblePages = []
    const { currentPage, totalPages } = this.props
    const pageNumbers = Array.from(
      { length: this.props.totalPages },
      (_, index) => index + 1
    )

    if (totalPages <= MAX_PAGES) {
      return pageNumbers
    }

    const halfMaxPages = Math.floor(MAX_PAGES / 2)
    let startPage = Math.max(currentPage - halfMaxPages, 1)
    const endPage = Math.min(currentPage + halfMaxPages, totalPages)

    if (endPage - startPage < MAX_PAGES - 1) {
      startPage = Math.max(endPage - MAX_PAGES + 1, 1)
    }

    if (startPage > 1) {
      visiblePages.push(1)
      if (startPage > 2) {
        visiblePages.push('...')
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i)
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        visiblePages.push('...')
      }
      visiblePages.push(totalPages)
    }

    return visiblePages
  }

  render() {
    // const { currentPage, totalCount, totalPages, pageRange } = this.props
    const { currentPage, totalPages, handleChangeChatPage } = this.props

    return (
      <Pagination aria-label="Page navigation">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handleChangeChatPage(currentPage - 1)}
          />
        </PaginationItem>
        {this.getPageList().map(page => (
          <PaginationItem key={page} active={page === currentPage}>
            <PaginationLink onClick={() => handleChangeChatPage(page)}>
              {page === '...' ? '...' : page}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            onClick={() => handleChangeChatPage(currentPage + 1)}
          />
        </PaginationItem>
      </Pagination>
    )
  }
}

export default PaginationComponent
