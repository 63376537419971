import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button, Row, Col, Label, FormGroup, Input } from 'reactstrap'

/* Apollo */
import { graphql, compose } from 'react-apollo'

/* Component */
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'
import { UPLOAD_TRANSACTION_CONTRACT } from '../../schemas/mutations'

const Attachments = ({
  transactionDetail,
  transactionId,
  uploadTransactionContract
}) => {
  const [file, setFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const handleFileSelect = e => {
    const file = e.target.files[0]
    if (e.target.validity.valid) {
      setFile(file)
    }
  }

  const handleFileUpload = async e => {
    try {
      e.preventDefault()
      setIsUploading(true)

      await uploadTransactionContract({
        variables: {
          contractFile: file,
          transactionId: transactionId
        }
      })
      setIsUploading(false)
      window.location.reload()
    } catch (err) {
      console.log('error uploading contract', err)
    }
  }

  return (
    <SectionWrapper sectionTitle="Attachments">
      <Row style={{ marginBottom: '10px' }}>
        <Col md={6}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Label>Invoice Attachment:</Label>
            <a
              href={transactionDetail.invoiceUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transactionDetail.invoiceNum
                ? transactionDetail.invoiceNum
                : '-'}
            </a>
          </div>
        </Col>
        <Col md={6}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Label>Receipt Attachment:</Label>
            <a
              href={transactionDetail.receiptUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transactionDetail.receiptNum
                ? transactionDetail.receiptNum
                : '-'}
            </a>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        <Col md={6}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Label style={{ marginRight: '5px' }}>Contract Attachment :</Label>
            <a
              href={transactionDetail.contractUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transactionDetail.contractUrl ? 'Contract' : ' -'}
            </a>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        <Col md={6}>
          <FormGroup>
            <Label>
              {transactionDetail.contractUrl
                ? 'Choose a file to replace existing contract'
                : 'Upload Contract'}
            </Label>
            <Input
              type="file"
              name="contractFile"
              onChange={handleFileSelect}
            />
          </FormGroup>
        </Col>
        <Col
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center'
          }}
        >
          {isUploading ? (
            <Loading />
          ) : (
            <Button
              type="primary"
              color="info"
              onClick={handleFileUpload}
              disabled={!file}
            >
              {transactionDetail.contractUrl ? 'Replace' : 'Upload'}
            </Button>
          )}
        </Col>
      </Row>
    </SectionWrapper>
  )
}

Attachments.propTypes = {
  transactionDetail: PropTypes.object.isRequired,
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  uploadTransactionContract: PropTypes.func.isRequired
}

export default compose(
  graphql(UPLOAD_TRANSACTION_CONTRACT, {
    name: 'uploadTransactionContract'
  })
)(Attachments)
