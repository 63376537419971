import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import {
  Table,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'
import Loading from 'shared/components/Loading'

/* Vendors */
// import FontAwesome from 'react-fontawesome'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'
import { Query } from 'react-apollo'
import {
  GET_RECRUITER_DOCUMENT,
  GET_RECRUITER_IDS_BY_COMPANY_ID
} from '../../../../schemas/queries'

const isDocument = method => method === 'join_with_document'
/**
 * RecruiterList - Bossjob recruiter list table
 *
 */
class RecruiterList extends Component {
  static propTypes = {
    /* array of user list */
    userList: PropTypes.array.isRequired,

    currentPage: PropTypes.number.isRequired,

    totalUsers: PropTypes.number.isRequired,

    totalPages: PropTypes.number.isRequired,

    adminId: PropTypes.number,

    handleApproveRecruiter: PropTypes.func,

    handleRejectRecruiter: PropTypes.func,
    changeRequiredPendingReviewRecruiter: PropTypes.func,
    handleChangeRole: PropTypes.func,
    hasDocument: PropTypes.bool,
    handleRemoveRecruiter: PropTypes.func,
    approveRecruiterDocument: PropTypes.func,
    handleReassignCoordinator: PropTypes.func,
    rejectedRecruiterDocument: PropTypes.func,

    companyId: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isItemActionsVisible: {},
      isLoading: false,
      deletedId: 0,
      editingText: {},
      editingRecruiter: null,
      documents: {},
      editingReject: null,
      editingRejectText: {}
    }
  }

  toggleActions(recruiterId) {
    this.setState({
      isItemActionsVisible: {
        ...this.state.isItemActionsVisible,
        [recruiterId]:
          this.state.isItemActionsVisible[recruiterId] === undefined
            ? true
            : !this.state.isItemActionsVisible[recruiterId]
      }
    })
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  displayStatus(adminId, userId, roleKey) {
    if (adminId === userId) {
      return 'Administrator (Coordinator)'
    } else if (roleKey) {
      return this.capitalizeFirstLetter(roleKey)
    } else {
      return 'Not available'
    }
  }

  render() {
    const {
      userList,
      currentPage,
      totalUsers,
      totalPages,
      adminId,
      handleChangeRole,
      handleRejectRecruiter,
      handleApproveRecruiter,
      handleRemoveRecruiter,
      handleReassignCoordinator,
      changeRequiredPendingReviewRecruiter,
      approveRecruiterDocument,
      companyId,
      hasDocument,
      rejectedRecruiterDocument
    } = this.props

    const recruiterRoleKeys = ['administrator', 'manager', 'recruiter']
    const [ADMINISTRATOR_ROLE, MANAGER_ROLE, RECRUITER_ROLE] = recruiterRoleKeys
    return (
      <React.Fragment>
        <Table bordered>
          <thead>
            <tr>
              <th />
              <th>User ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              {hasDocument && <th style={{ textAlign: 'center' }}>Document</th>}
              <th style={{ textAlign: 'center' }}>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userList.map(user => (
              <tr key={`recruiter-${user.userId}`}>
                <td />
                <td>
                  <a href={`/bossjob/user/${user.userId}`}>{user.userId}</a>
                </td>
                <td>{user.user.firstName}</td>
                <td>{user.user.lastName}</td>
                <td>{user.user.email}</td>
                <td style={{ textAlign: 'center' }}>
                  {this.capitalizeFirstLetter(user.statusKey)}
                </td>
                {hasDocument && (
                  <td style={{ textAlign: 'center' }}>
                    {isDocument(user.joinCompanyMethod) ? (
                      <Query
                        query={GET_RECRUITER_DOCUMENT}
                        variables={{ companyId, recruiterId: user.userId }}
                        onCompleted={data => {
                          if (!this.state.documents[user.userId]) {
                            this.setState({
                              ...this.state,
                              documents: {
                                ...this.state.documents,
                                [user.userId]: data.getRecruiterDocument.data
                              }
                            })
                          }
                        }}
                      >
                        {({ loading, data }) => {
                          if (loading) {
                            return 'loading'
                          } else {
                            return (
                              <>
                                <button
                                  type="link"
                                  onClick={() =>
                                    window.open(
                                      data.getRecruiterDocument.data.url
                                    )
                                  }
                                >
                                  View
                                </button>
                                <br />
                                {data.getRecruiterDocument.data
                                  .changeRequiredReason && (
                                  <div
                                    style={{
                                      maxWidth: 200,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis'
                                    }}
                                    title={
                                      data.getRecruiterDocument.data
                                        .changeRequiredReason
                                    }
                                  >
                                    Reason:
                                    {
                                      data.getRecruiterDocument.data
                                        .changeRequiredReason
                                    }
                                  </div>
                                )}
                              </>
                            )
                          }
                        }}
                      </Query>
                    ) : (
                      '--'
                    )}
                  </td>
                )}
                <td style={{ textAlign: 'center' }}>
                  {this.displayStatus(adminId, user.userId, user.roleKey)}
                </td>
                <td>
                  <Can I="edit" a="companies">
                    {user.userId !== adminId ? (
                      <>
                        <ButtonDropdown
                          isOpen={this.state.isItemActionsVisible[user.userId]}
                          toggle={() => this.toggleActions(user.userId)}
                          disabled={
                            isDocument(user.joinCompanyMethod) &&
                            !this.state.documents[user.userId]
                          }
                        >
                          <DropdownToggle color="info" caret>
                            {user.statusKey === 'active'
                              ? 'Change Role'
                              : 'Approve Role'}
                          </DropdownToggle>
                          <DropdownMenu>
                            {recruiterRoleKeys.map((recruiterRoleKey, idx) => {
                              if (recruiterRoleKey == user.roleKey) return
                              return (
                                <React.Fragment key={`roleKey_${idx}`}>
                                  <DropdownItem
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      if (user.statusKey === 'active') {
                                        handleChangeRole(
                                          companyId,
                                          user.userId,
                                          recruiterRoleKey
                                        )
                                      } else if (user.statusKey === 'pending') {
                                        if (
                                          isDocument(user.joinCompanyMethod)
                                        ) {
                                          approveRecruiterDocument(
                                            this.state.documents[user.userId]
                                              .id,
                                            recruiterRoleKey
                                          )
                                        } else {
                                          handleApproveRecruiter(
                                            companyId,
                                            user.userId,
                                            recruiterRoleKey
                                          )
                                        }
                                      }
                                    }}
                                  >
                                    {this.capitalizeFirstLetter(
                                      recruiterRoleKey
                                    )}
                                  </DropdownItem>
                                  <DropdownItem divider />
                                </React.Fragment>
                              )
                            })}
                          </DropdownMenu>
                          {user.statusKey === 'active' && (
                            <Button
                              style={{ marginLeft: '10px' }}
                              disabled={
                                this.state.isLoading &&
                                this.state.deletedId === user.userId
                              }
                              color="danger"
                              onClick={() => {
                                if (
                                  confirm(
                                    'All jobs under this recruiter will be closed or deleted, are you sure to remove this recruiter?'
                                  )
                                ) {
                                  this.setState({ isLoading: true })
                                  this.setState({ deletedId: user.userId })
                                  handleRemoveRecruiter(
                                    companyId,
                                    user.userId
                                  ).catch(err => alert(err))
                                }
                              }}
                            >
                              {this.state.isLoading &&
                              this.state.deletedId === user.userId
                                ? 'Deleting...'
                                : 'Remove Recruiter'}
                            </Button>
                          )}
                        </ButtonDropdown>
                        {user.statusKey === 'pending' &&
                          isDocument(user.joinCompanyMethod) &&
                          (!this.state.documents[user.userId] ||
                            !this.state.documents[user.userId]
                              .changeRequiredReason) && (
                          <Button
                            style={{ marginLeft: '10px' }}
                            onClick={
                              () => {
                                this.setState({
                                  ...this.state,
                                  editingRecruiter: user.userId
                                })
                              }
                              // handleRejectRecruiter(companyId, user.userId)
                            }
                          >
                              Change Required
                          </Button>
                        )}
                        {user.statusKey === 'pending' && (
                          <Button
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              if (isDocument(user.joinCompanyMethod)) {
                                this.setState({
                                  ...this.state,
                                  editingReject: user.userId
                                })
                              } else {
                                handleRejectRecruiter(companyId, user.userId)
                              }
                            }}
                          >
                            Reject
                          </Button>
                        )}
                      </>
                    ) : (
                      <Query
                        query={GET_RECRUITER_IDS_BY_COMPANY_ID}
                        variables={{ companyId: companyId, page: 1 }}
                      >
                        {({ loading, data }) => {
                          if (loading) {
                            return <Loading />
                          } else {
                            const userList =
                              data.getCompanyRecruiters.data.recruiters
                            return (
                              <>
                                <ButtonDropdown
                                  isOpen={
                                    this.state.isItemActionsVisible[user.userId]
                                  }
                                  toggle={() => this.toggleActions(user.userId)}
                                >
                                  <DropdownToggle color="info" caret>
                                    Reappoint Coordinator
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{
                                      overflow: 'scroll',
                                      maxHeight: '175px'
                                    }}
                                  >
                                    {userList.map(recruiter => {
                                      if (recruiter.userId === adminId) return
                                      else if (
                                        [MANAGER_ROLE, RECRUITER_ROLE].includes(
                                          recruiter.roleKey
                                        )
                                      )
                                        return
                                      else if (
                                        recruiter.roleKey === ADMINISTRATOR_ROLE
                                      ) {
                                        return (
                                          <React.Fragment
                                            key={`userId_${recruiter.userId}`}
                                          >
                                            <DropdownItem
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                if (
                                                  confirm(
                                                    `Confirm to transfer company subscription and coordinator to recruiter ${
                                                      recruiter.userId
                                                    }?`
                                                  )
                                                ) {
                                                  handleReassignCoordinator(
                                                    companyId,
                                                    adminId,
                                                    recruiter.userId
                                                  )
                                                }
                                              }}
                                            >
                                              {recruiter.user.firstName +
                                                ' ' +
                                                recruiter.user.lastName}{' '}
                                              ({recruiter.userId})
                                            </DropdownItem>
                                            <DropdownItem divider />
                                          </React.Fragment>
                                        )
                                      }
                                    })}
                                  </DropdownMenu>
                                </ButtonDropdown>
                              </>
                            )
                          }
                        }}
                      </Query>
                    )}
                  </Can>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={Number(currentPage)}
          totalCount={totalUsers}
          totalPages={totalPages}
          pageRange={4}
        />
        <Modal isOpen={!!this.state.editingRecruiter}>
          <ModalHeader
            toggle={() => {
              this.setState({ ...this.state, editingRecruiter: null })
            }}
          >
            {'Please enter the reason'}
          </ModalHeader>
          <ModalBody>
            <Input
              placeholder="Input text here"
              type="textarea"
              style={{
                color: 'black',
                width: '100%',
                height: '100%',
                minHeight: 256
              }}
              value={
                this.state.editingRecruiter
                  ? this.state.editingText[this.state.editingRecruiter]
                  : ''
              }
              onChange={e => {
                this.setState({
                  ...this.state,
                  editingText: {
                    ...this.state.editingText,
                    [this.state.editingRecruiter]: e.target.value
                  }
                })
              }}
            >
              {}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              disabled={
                // !this.state.documents[this.state.editingRecruiter] &&
                !this.state.editingText[this.state.editingRecruiter]
              }
              onClick={() => {
                changeRequiredPendingReviewRecruiter(
                  this.state.documents[this.state.editingRecruiter].id,
                  this.state.editingText[this.state.editingRecruiter]
                ).then(() => {
                  this.setState({ ...this.state, editingRecruiter: null })
                })
              }}
            >
              Send
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={!!this.state.editingReject}>
          <ModalHeader
            toggle={() => {
              this.setState({ ...this.state, editingReject: null })
            }}
          >
            {'Please enter the reason'}
          </ModalHeader>
          <ModalBody>
            <Input
              placeholder="Input text here"
              type="textarea"
              style={{
                color: 'black',
                width: '100%',
                height: '100%',
                minHeight: 256
              }}
              value={
                this.state.editingReject
                  ? this.state.editingRejectText[this.state.editingReject]
                  : ''
              }
              onChange={e => {
                this.setState({
                  ...this.state,
                  editingRejectText: {
                    ...this.state.editingRejectText,
                    [this.state.editingReject]: e.target.value
                  }
                })
              }}
            >
              {}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              disabled={
                // !this.state.documents[this.state.editingRecruiter] &&
                !this.state.editingRejectText[this.state.editingReject]
              }
              onClick={() => {
                rejectedRecruiterDocument(
                  this.state.documents[this.state.editingReject].id,
                  this.state.editingRejectText[this.state.editingReject]
                ).then(() => {
                  this.setState({ ...this.state, editingRecruiter: null })
                })
              }}
            >
              Send
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

export default RecruiterList
