import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import UpdateButtonContainer from '../../../../containers/TransactionDetailContainer/UpdateButtonContainer'
import SubmitButtonContainer from '../../../../containers/TransactionDetailContainer/SubmitButtonContainer'
import SendInvoiceAndReceiptButton from './SendInvoiceAndReceiptButton/SendInvoiceAndReceiptButton'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * Submit Form - A part of the Transaction Detail form which
 * contains transaction details e.g: Reference Number, Amount,
 * Item Name, Description and Payment Method
 *
 * @returns {Component}
 */

const SubmitForm = ({
  submitType,
  userName,
  userId,
  transactionDetail,
  isDisabled,
  handleChange,
  customHandleChange,
  billerEmail,
  salesPersonEmail
}) => {
  useEffect(() => {
    if (submitType === 'Add') {
      customHandleChange('billerEmail', billerEmail)
      customHandleChange('salesPersonEmail', salesPersonEmail)
    }
  }, [])

  const _renderBillerEmail = () => {
    return (
      <FormGroup>
        <Label style={{ fontWeight: 600 }}>Biller Email*</Label>
        <Input
          type="email"
          name="billerEmail"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerEmail
              ? transactionDetail.billerEmail
              : ''
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  const _renderSalesPersonEmail = () => {
    return (
      <FormGroup>
        <Label style={{ fontWeight: 600 }}>Sales Person Email*</Label>
        <Input
          type="email"
          name="salesPersonEmail"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.salesPersonEmail
              ? transactionDetail.salesPersonEmail
              : ''
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  const _renderRemarkField = () => {
    return (
      <FormGroup>
        <Label style={{ fontWeight: 600 }}>Remarks</Label>
        <Input
          type="textarea"
          name="remark"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.remark
              ? transactionDetail.remark
              : null
          }
        />
      </FormGroup>
    )
  }

  const _renderStatusField = () => {
    var statusOptions

    if (transactionDetail.status === 'Completed') {
      statusOptions = (
        <React.Fragment>
          <option value="3">Completed</option>
          <option value="6">Refunded</option>
        </React.Fragment>
      )
    } else if (transactionDetail.status === 'Refunded') {
      statusOptions = (
        <React.Fragment>
          <option value="6">Refunded</option>
        </React.Fragment>
      )
    } else {
      statusOptions = (
        <React.Fragment>
          <option value="1">Pending</option>
          <option value="2">Processing</option>
          <option value="3">Completed</option>
          <option value="4">Cancelled</option>
          <option value="5">Failed</option>
        </React.Fragment>
      )
    }
    return (
      <FormGroup>
        <Label style={{ fontWeight: 600 }}>Payment Status</Label>
        <Input
          type="select"
          name="statusId"
          disabled={
            transactionDetail && transactionDetail.status === 'Refunded'
          }
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.statusId
              ? transactionDetail.statusId
              : null
          }
        >
          {statusOptions}
        </Input>
      </FormGroup>
    )
  }

  const style = {
    bold: {
      fontWeight: 600
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px'
  }
  return (
    <SectionWrapper sectionTitle="Status">
      <Form>
        <Row>
          <Col>{_renderRemarkField()}</Col>
        </Row>
        <Row>
          <Col>{_renderSalesPersonEmail()}</Col>
        </Row>
        <Row>
          <Col>{_renderBillerEmail()}</Col>
        </Row>
        <Row>
          <Col>
            <div style={style}>
              <Label style={style.bold}>Invoice Date</Label>
              {transactionDetail && transactionDetail.createdAt
                ? formatDate(transactionDetail.createdAt, 'YYYY-MM-DD HH:mm:ss')
                : formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss')}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={style}>
              <Label style={style.bold}>Payment Date</Label>
              {transactionDetail && transactionDetail.paidAt
                ? formatDate(transactionDetail.paidAt, 'YYYY-MM-DD HH:mm:ss')
                : '-'}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={style}>
              <Label style={style.bold}>Expired Date</Label>
              {transactionDetail && transactionDetail.expiredAt
                ? formatDate(transactionDetail.expiredAt, 'YYYY-MM-DD HH:mm:ss')
                : '-'}
            </div>
          </Col>
        </Row>
        <div style={style}>
          <span style={style.bold}>Is Item Credited: </span>
          {transactionDetail && transactionDetail.isCredited ? 'Yes' : 'No'}
        </div>
        <div style={style}>
          <span style={style.bold}>Purchased by: </span>
          <a href={`/bossjob/user/${userId}/account`}>{userName}</a>
        </div>
        <Row>
          <Col>{_renderStatusField()}</Col>
        </Row>
      </Form>

      <div style={style}>
        <Can I="edit" a="invoice">
          {submitType === 'Update' ? (
            <div>
              <UpdateButtonContainer />
              <SendInvoiceAndReceiptButton
                transactionDetail={transactionDetail}
              />
            </div>
          ) : (
            <SubmitButtonContainer userId={userId} />
          )}
        </Can>
      </div>
    </SectionWrapper>
  )
}

SubmitForm.propTypes = {
  transactionDetail: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  customHandleChange: PropTypes.func.isRequired,
  submitType: PropTypes.any.isRequired,
  userName: PropTypes.string.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
  billerEmail: PropTypes.string,
  salesPersonEmail: PropTypes.string
}

export default SubmitForm
