import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Route, Redirect } from 'react-router-dom'

/**
 * Route to verify authenticated user
 *
 * @returns {Component}
 */
class Routing extends PureComponent {
  static propTypes = {
    /* To-be Route Component */
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

    /* location object: */
    location: ReactRouterPropTypes.location
  }

  render() {
    const { component: Component, ...rest } = this.props
    const token = localStorage.getItem('accessToken')
    return (
      <Route
        {...rest}
        render={props =>
          token ? <Component {...props} /> : <Redirect to={'/login'} />
        }
      />
    )
  }
}

export default Routing
