import gql from 'graphql-tag'

/*====== Local State ======*/
/* Bosshunt user */

export const UPDATE_EVENT_REQUEST = gql`
  mutation updateEventTransactionRequest(
    $transactionRequestId: Int!
    $statusId: Int!
    $remarks: String
    $transactionLink: String
    $transactionFee: Float
  ) {
    updateEventTransactionRequest(
      transactionRequestId: $transactionRequestId
      statusId: $statusId
      remarks: $remarks
      transactionLink: $transactionLink
      transactionFee: $transactionFee
    ) {
      message
      ok
    }
  }
`
export const UPDATE_LOCAL_BOSSHUNT_USER = gql`
  mutation UpdateLocalBosshuntUser(
    $key: String
    $value: String
    $category: String
    $del: Int
  ) {
    updateLocalBosshuntUser(
      key: $key
      value: $value
      category: $category
      del: $del
    ) @client {
      ok
    }
  }
`
export const UPDATE_LOCAL_BOSSHUNT_USER_FOR_NEW_FORM = gql`
  mutation UpdateLocalBosshuntUserForNewForm($key: String, $value: String) {
    updateLocalBosshuntUserForNewForm(key: $key, value: $value) @client {
      ok
    }
  }
`

/* Add/Register a new bosshunt user a.k.a headhunter */
export const ADD_HEADHUNTER = gql`
  mutation RegisterHeadhunter(
    $headhunterInput: RegisterHeadhunterInput
    $companyId: Int
  ) {
    registerHeadhunter(
      headhunterInput: $headhunterInput
      companyId: $companyId
    ) {
      ok
      message
    }
  }
`

export const UPDATE_HEADHUNTER_DETAIL = gql`
  mutation UpdateHeadhunter(
    $headhunterId: ID!
    $updateHeadhunterInput: UpdateHeadhunterInput
  ) {
    updateHeadhunter(
      headhunterId: $headhunterId
      updateHeadhunterInput: $updateHeadhunterInput
    ) {
      ok
      message
    }
  }
`
export const CHANGE_HEADHUNTER_AGENCY = gql`
  mutation ChangeHeadhunterCompany($companyId: Int!, $headhunterId: Int!) {
    changeHeadhunterCompany(
      companyId: $companyId
      headhunterId: $headhunterId
    ) {
      ok
      message
    }
  }
`

export const UNASSIGN_HEADHUNTER_AGENCY = gql`
  mutation UnassignHeadhunterCompany($headhunterId: Int!) {
    deleteHeadhunterCompany(headhunterId: $headhunterId) {
      ok
      message
    }
  }
`
/* Upload user avatar */
export const UPLOAD_USER_AVATAR = gql`
  mutation UploadUserAvatar($id: Int!, $file: Upload!) {
    uploadJobseekerAvatar(userId: $id, file: $file) {
      ok
      message
    }
  }
`
/* Resend/email login credentials */
export const RESEND_HEADHUNTER_PASSWORD = gql`
  mutation ResendHeadhunterPassword($headhunterId: ID!) {
    resendHeadhunterPassword(headhunterId: $headhunterId) {
      ok
      message
    }
  }
`

/* Update MyAds's feeStructure detail */
export const UPDATE_HEADHUNTER_FEE = gql`
  mutation UpdateHeadhunterFee(
    $headhunterId: ID!
    $headhunterFeeStructureInput: HeadhunterFeeStructureInput!
    $headhunterFreeReplacementInput: HeadhunterFreeReplacementInput
    $headhunterGuaranteePeriodInput: HeadhunterGuaranteePeriodInput
    $headhunterTurnaroundTimeInput: HeadhunterTurnaroundTimeInput
  ) {
    updateHeadhunterFee(
      headhunterId: $headhunterId
      headhunterFeeStructureInput: $headhunterFeeStructureInput
      headhunterFreeReplacementInput: $headhunterFreeReplacementInput
      headhunterGuaranteePeriodInput: $headhunterGuaranteePeriodInput
      headhunterTurnaroundTimeInput: $headhunterTurnaroundTimeInput
    ) {
      ok
      message
    }
  }
`

/* Update MyAds's specialization detail */
export const UPDATE_HEADHUNTER_SPECIALIZATION = gql`
  mutation UpdateHeadhunterSpecialization(
    $headhunterId: ID!
    $industryIds: [Int]!
    $serviceIds: [Int]!
    $serviceLocationIds: [Int]!
  ) {
    updateHeadhunterSpecialization(
      headhunterId: $headhunterId
      industryIds: $industryIds
      serviceIds: $serviceIds
      serviceLocationIds: $serviceLocationIds
    ) {
      ok
      message
    }
  }
`

/* Activate headhunter's ad under MyAds's */
export const ACTIVATE_HEADHUNTER_AD = gql`
  mutation ActivateHeadhunterAd($headhunterId: ID!) {
    activateHeadhunterAd(headhunterId: $headhunterId) {
      ok
      message
    }
  }
`
/* Deactivate headhunter's ad under MyAds's */
export const DEACTIVATE_HEADHUNTER_AD = gql`
  mutation DeactivateHeadhunterAd($headhunterId: ID!) {
    deactivateHeadhunterAd(headhunterId: $headhunterId) {
      ok
      message
    }
  }
`
