import { GET_LOCAL_ROLE_NODES } from '../schemas/queries'

export default {
  updateRoleNode: (_, { access, roleNodeId }, { cache }) => {
    const { rolePermissions } = cache.readQuery({
      query: GET_LOCAL_ROLE_NODES
    })

    rolePermissions.forEach(node => {
      if (node.id === roleNodeId) {
        node.canEdit = access.canEdit
        node.canView = access.canView
      }
    })

    cache.writeData({
      data: {
        rolePermissions: rolePermissions
      }
    })

    return null
  }
}
