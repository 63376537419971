import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Input } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/**
 * AgencyList - Bosshunt agency list table
 *
 */
const AgencyList = ({
  agencyList,
  currentPage,
  totalCompanies,
  totalPages,
  appendSelectedAgency,
  removeSelectedAgency
}) => {
  const handleChange = e => {
    if (e.target.checked) {
      appendSelectedAgency(e.target.value)
    } else {
      removeSelectedAgency(e.target.value)
    }
  }

  return (
    <React.Fragment>
      <Table bordered>
        <thead>
          <tr>
            <th />
            <th>ID</th>
            <th>Agency Name</th>
            <th>Address</th>
            <th style={{ textAlign: 'center' }}>No. of Headhunters</th>
            <th style={{ textAlign: 'center' }}>Status</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {agencyList &&
            agencyList.map(agency => (
              <tr key={`agency-${agency.id}`}>
                <td>
                  <Input
                    addon
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    onChange={handleChange}
                    value={agency.id}
                  />
                </td>
                <td>{agency.id}</td>
                <td>
                  <a href={`/bosshunt/agency/${agency.id}`}>{agency.name}</a>
                </td>
                <td>{agency.address}</td>
                <td style={{ textAlign: 'center' }}>
                  <a href={`/bosshunt/agency/${agency.id}/headhunters/1`}>
                    {agency.recruiterAmount}
                  </a>
                </td>
                <td style={{ textAlign: 'center' }}>
                  {agency.isActive ? 'Active' : 'Inactive'}
                </td>
                <td>{formatDate(agency.createdAt, 'DD/MM/YYYY HH:mm:Ss')}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalCompanies}
        totalPages={totalPages}
        pageRange={4}
      />
    </React.Fragment>
  )
}

AgencyList.propTypes = {
  /* array of agency list */
  agencyList: PropTypes.array.isRequired,

  getNext: PropTypes.func.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalCompanies: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  appendSelectedAgency: PropTypes.func.isRequired,

  removeSelectedAgency: PropTypes.func.isRequired
}

export default AgencyList
