import React from 'react'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

/* Components */
import Main from './Main'
import Layout from 'shared/components/Layout'
import NotAuthorize from 'shared/components/NotAuthorize'

export default () => (
  <Layout>
    <Can I="view" a="website_outage_settings" passThrough>
      {can => (can ? <Main /> : <NotAuthorize />)}
    </Can>
  </Layout>
)
