import gql from 'graphql-tag'

/* Get featured company list */
export const GET_FEATURED_COMPANIES = gql`
  query getFeaturedCompanies($countryId: Int!) {
    featureCompanies(countryId: $countryId) {
      ok
      message
      data {
        id
        companyId
        company {
          id
          name
        }
        priority
      }
    }
  }
`

/* Get featured company banner list */
export const GET_FEATURED_BANNER = gql`
  query getFeatureBanners($countryId: Int!) {
    featureBanners(countryId: $countryId) {
      ok
      message
      data {
        id
        countryId
        logoUrl
        coverPicUrl
        title
        description
        ctaText
        ctaUrl
        sortOrder
        createdAt
        updatedAt
        colorCode
        descriptionColor
        buttonColor
        ctaTextColor
        mobileCoverPicUrl
        tabletCoverPicUrl
      }
    }
  }
`
/* Get job page left side banner list */
export const GET_JOB_PAGE_LEFT_SIDE_BANNER = gql`
  query {
    leftSideBanners {
      ok
      message
      data {
        id
        alt
        imageUrl
        ctaUrl
        createdAt
        updatedAt
      }
    }
  }
`
/* Get job page right side banner list */
export const GET_JOB_PAGE_RIGHT_SIDE_BANNER = gql`
  query {
    rightSideBanners {
      ok
      message
      data {
        id
        alt
        imageUrl
        mobileImageUrl
        ctaUrl
        createdAt
        updatedAt
      }
    }
  }
`

/* Get new product feature announcement list */
export const GET_NEW_PRODUCT_FEATURE_LIST = gql`
  query {
    productFeatureBanners {
      ok
      message
      data {
        id
        title
        subTitle
        description
        ctaText
        ctaUrl
        sortOrder
        createdAt
        updatedAt
      }
    }
  }
`
