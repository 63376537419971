import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_LOCAL_CREDIT_MODAL_DATA,
  GET_LOCAL_BOSSJOB_USER_SOCIAL_LINK
} from '../schemas/queries'

export default {
  updateBossjobUser: (_, { key, value }, { cache }) => {
    const { bossjobUserDetail } = cache.readQuery({
      query: GET_LOCAL_BOSSJOB_USER_DETAIL
    })

    let newBossjobUserDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        bossjobUserDetail: {
          ...bossjobUserDetail,
          ...newBossjobUserDetail
        }
      }
    })

    return null
  },

  handleCreditModal: (_, { isVisible, modalData }, { cache }) => {
    const { creditModal } = cache.readQuery({
      query: GET_LOCAL_CREDIT_MODAL_DATA
    })
    const newCreditModal = {
      isVisible: isVisible,
      modalData: modalData
    }
    cache.writeData({
      data: {
        creditModal: {
          ...creditModal,
          ...newCreditModal
        }
      }
    })

    return null
  },

  addSocialLink: (_, { socialType, username }, { cache }) => {
    const { bossjobUserSocialLinks } = cache.readQuery({
      query: GET_LOCAL_BOSSJOB_USER_SOCIAL_LINK
    })

    let socialLinks = bossjobUserSocialLinks
    socialLinks.push({
      socialType: socialType,
      username: username,
      __typename: 'SocialLinkObj'
    })

    cache.writeData({
      data: {
        bossjobUserSocialLinks: socialLinks
      }
    })

    return null
  },

  editSocialLink: (_, { socialType, username, index }, { cache }) => {
    const { bossjobUserSocialLinks } = cache.readQuery({
      query: GET_LOCAL_BOSSJOB_USER_SOCIAL_LINK
    })

    let socialLinks = bossjobUserSocialLinks
    socialLinks[index] = {
      socialType: socialType,
      username: username,
      __typename: 'SocialLinkObj'
    }

    cache.writeData({
      data: {
        bossjobUserSocialLinks: socialLinks
      }
    })

    return null
  },

  deleteSocialLink: (_, { index }, { cache }) => {
    const { bossjobUserSocialLinks } = cache.readQuery({
      query: GET_LOCAL_BOSSJOB_USER_SOCIAL_LINK
    })

    let socialLinks = bossjobUserSocialLinks

    socialLinks.splice(index, 1)

    cache.writeData({
      data: {
        bossjobUserSocialLinks: socialLinks
      }
    })

    return null
  }
}
