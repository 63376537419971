import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Component */
import SectionWrapper from 'shared/components/SectionWrapper'
// import Loading from 'shared/components/Loading'
import InformationFormContainer from '../../../containers/BossJobUserDetailContainer/InformationFormContainer'
// import SocialLinksContainer from '../../../containers/BossJobUserDetailContainer/SocialLinksContainer'
import WorkExperiences from './WorkExperiences'
import Educations from './Educations'
import Skills from './Skills'
// import ResumeTemplates from './ResumeTemplates'
import JobPreference from './JobPreference'
import UploadedResume from './UploadedResume'

/* Apollo */
// import { Query } from 'react-apollo'
// import { GET_USER_SOCIAL_LINKS } from '../../../schemas/queries'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * UserProfile - main wrapper component for the Profile page
 *
 * @returns {Component}
 */
const UserProfile = () => (
  <div>
    {/** Resume Template section */}
    {/* <SectionWrapper sectionTitle="Resume Template">
      <ResumeTemplates />
    </SectionWrapper> */}

    {/** Uploaded Resume section */}
    <Can I="view" a="resume">
      <SectionWrapper
        sectionTitle="Uploaded Resume"
        style={{ marginTop: '20px' }}
      >
        <UploadedResume />
      </SectionWrapper>
    </Can>

    {/** User information section */}
    <SectionWrapper sectionTitle="Information" style={{ marginTop: '20px' }}>
      <InformationFormContainer />
    </SectionWrapper>

    {/** User social links section */}
    {/* <Query
      query={GET_USER_SOCIAL_LINKS}
      variables={{ userId: match.params.id }}
    >
      {({ loading, data: { socialLinks }, client }) => {
        if (loading) {
          return <Loading />
        }
        if (socialLinks && socialLinks.data) {
          client.writeData({
            data: { bossjobUserSocialLinks: socialLinks.data }
          })

          return (
            <Can I="view" a="jobseeker_social_links">
              <SocialLinksContainer />
            </Can>
          )
        }
      }}
    </Query> */}

    {/** User work experience section */}
    <Can I="view" a="jobseeker_workxps">
      <WorkExperiences />
    </Can>

    {/** User educations section */}
    <Can I="view" a="jobseeker_edu">
      <Educations />
    </Can>

    {/** User skills section */}
    <Skills />

    {/** User job preference section */}
    <Can I="view" a="jobseeker_pref">
      <SectionWrapper
        sectionTitle="Job Preferences"
        style={{ marginTop: '20px' }}
      >
        <JobPreference />
      </SectionWrapper>
    </Can>
  </div>
)

UserProfile.propTypes = {
  match: ReactRouterPropTypes
}

export default withRouter(UserProfile)
