import gql from 'graphql-tag'

export const GET_EVENT_PARTICIPANT_STATISTIC = gql`
  query getEventParticipantStatistic {
    eventParticipantStatistic {
      ok
      message
      data {
        countryId
        participants
      }
    }
  }
`

export const GET_EVENT_ACTIVITY_DATA = gql`
  query eventActivityDataRequest {
    eventActivityDataRequest {
      ok
      message
      totalPointsEarned
      totalPointsRedeemed
      data {
        missionId
        points
        participants
        redeemLimit
        name
      }
    }
  }
`
export const GET_ALL_EVENTS = gql`
  query eventTransaction(
    $size: Int
    $page: Int
    $userId: Int
    $withdrawnPoint: Int
    $transactionStatusId: Int
    $createdDate: EventTransactionRequestDateTimeRange
  ) {
    eventTransactionRequestList(
      size: $size
      page: $page
      userId: $userId
      withdrawnPoint: $withdrawnPoint
      transactionStatusId: $transactionStatusId
      createdDate: $createdDate
    ) {
      data {
        currentNum
        currentPage
        totalNum
        totalPages
        eventTransactionRequests {
          id
          userId
          withdrawnPoint
          walletAddress
          transactionStatusId
          transactionLink
          money
          transactionFee
          createdAt
          updatedAt
          remarks
          network
          user {
            firstName
            lastName
            email
            phoneNum
          }
          transactionStatus {
            id
            value
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const GET_EVENTS_BY_ID = gql`
  query eventTransaction($transactionRequestId: Int!) {
    eventTransactionRequest(transactionRequestId: $transactionRequestId) {
      data {
        id
        userId
        withdrawnPoint
        walletAddress
        transactionStatusId
        transactionLink
        createdAt
        updatedAt
        remarks
        network
        user {
          firstName
          lastName
          email
          phoneNum
        }
        transactionStatus {
          id
          value
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const GET_RECRUITER_EVENT_ACTIVITY_LOGS = gql`
  query recruiterEventActivity($userId: Int!, $size: Int, $page: Int) {
    recruiterEventActivity(userId: $userId, size: $size, page: $page) {
      ok
      message
      data {
        totalPages
        currentPage
        size
        totalCount
        usedPoint
        remainingPoint
        eventActivityUsedPoint
        eventActivityRemainingPoint
        activityLogs {
          id
          date
          missionId
          transactionId
          point
          invitedByUserId
          acceptedByUserId
          joinCompanyId
          redPacketCode
          referralCode
          createdAt
          updatedAt
        }
      }
    }
  }
`
