import React from 'react'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

import OtpEmailLogs from '../../../containers/OtpEmailLogsContainer/OtpEmailLogsContainer'
import NotAuthorize from 'shared/components/NotAuthorize'

export default () => (
  <Can I="view" a="bossjob_user" passThrough>
    {can => (can ? <OtpEmailLogs /> : <NotAuthorize />)}
  </Can>
)
