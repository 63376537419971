import React, { Component } from 'react'
import { Col, FormGroup, Label } from 'reactstrap'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

// import { Button } from 'reactstrap'

import styles from '../CompanyAnalytics.module.scss'

class Head extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      fromDate,
      toDate,
      handleUpdateBeginDate,
      handleUpdateEndDate
    } = this.props

    return (
      <div>
        <div className="col-24">
          <div className="col-24">
            <div className="card col-24">
              <div className="card-body col-24">
                <div className="row">
                  <div className="col-6">
                    <FormGroup className={styles.form} row>
                      <Label md={2} sm={12}>
                        Date
                      </Label>
                      <Col md={5} sm={12}>
                        <DateTimePicker
                          format="MMM D, YYYY"
                          time={false}
                          value={fromDate}
                          placeholder="From"
                          onChange={newDate => {
                            handleUpdateBeginDate(newDate)
                          }}
                        />
                      </Col>
                      <Col md={5} sm={12}>
                        <DateTimePicker
                          format="MMM D, YYYY"
                          time={false}
                          value={toDate}
                          placeholder="To"
                          onChange={newDate => handleUpdateEndDate(newDate)}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    {/* <Button
                      type="submit"
                      color="info"
                      onClick={() => {
                        this.setState({
                          modal: true
                        })
                      }}
                    >
                      Search
                    </Button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Head
