import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter, Route, Redirect } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_BOSSHUNT_USER_BY_ID } from '../../schemas/queries'

/* Components */
import Navigation from './Navigation'
import AccountSetting from './AccountSetting'
import MyAds from './MyAds'
// import MyResume from './MyResume'
import Loading from 'shared/components/Loading'

/**
 * BossHuntUserDetail - main wrapper component for Bosshunt user details and match url to corresponding component
 *
 * @returns {Component}
 */
const BossHuntUserDetail = ({ match }) => {
  const userId = match.params.id
  // if there is no userId (userId ==== 'new'), bring user to AccountSetting page
  if (userId === 'new') {
    return (
      <div>
        <React.Fragment>
          <Route
            exact
            path={`${match.path}`}
            component={() => <Redirect to={`${match.url}/account`} />}
          />
          <Route
            exact
            path="/bosshunt/user/:id/account"
            render={() => <AccountSetting match={match} />}
          />
        </React.Fragment>
      </div>
    )
  } else {
    return (
      <div>
        <Navigation />

        <Query
          query={GET_BOSSHUNT_USER_BY_ID}
          variables={{ headhunterId: match.params.id }}
        >
          {({ loading, data: { headhunter }, client }) => {
            if (loading) {
              return <Loading />
            }
            if (headhunter && headhunter.data) {
              client.writeData({
                data: { bosshuntUserDetail: headhunter.data }
              })
              if (!headhunter.data.feeStructure) {
                client.writeData({
                  data: {
                    bosshuntUserDetail: {
                      ...headhunter.data,
                      // necessary to initialize the bosshuntUserDetail with the following:
                      // failing which will result in cache unable to be updated with data
                      // because initial data structure returned from back end did not contain this:
                      feeStructure: {
                        ...headhunter.data.feeStructure,
                        isContingentFee: false,
                        isRetainedFee: false,
                        isOtherFee: false,
                        annualGrossSalaryFrom: null,
                        annualGrossSalaryTo: null,
                        monthlyFeeFrom: null,
                        monthlyFeeTo: null,
                        otherFee: null,
                        __typename: 'HeadhunterFeeStructureType'
                      }
                    }
                  }
                })
              }
              if (!headhunter.data.turnaroundTime) {
                client.writeData({
                  data: {
                    bosshuntUserDetail: {
                      ...headhunter.data,
                      // necessary to initialize the bosshuntUserDetail with the following:
                      // failing which will result in cache unable to be updated with data
                      // because initial data structure returned from back end did not contain this:
                      turnaroundTime: {
                        ...headhunter.data.turnaroundTime,
                        turnaroundTimeId: 1,
                        fromPeriod: null,
                        toPeriod: null,
                        __typename: 'HeadhunterTurnaroundTimeType'
                      }
                    }
                  }
                })
              }
              if (!headhunter.data.guaranteePeriod) {
                client.writeData({
                  data: {
                    bosshuntUserDetail: {
                      ...headhunter.data,
                      // necessary to initialize the bosshuntUserDetail with the following:
                      // failing which will result in cache unable to be updated with data
                      // because initial data structure returned from back end did not contain this:
                      guaranteePeriod: {
                        ...headhunter.data.guaranteePeriod,
                        guaranteePeriodId: 1,
                        fromPeriod: null,
                        toPeriod: null,
                        __typename: 'HeadhunterGuaranteePeriodType'
                      }
                    }
                  }
                })
              }
              if (!headhunter.data.freeReplacement) {
                client.writeData({
                  data: {
                    bosshuntUserDetail: {
                      ...headhunter.data,
                      // necessary to initialize the bosshuntUserDetail with the following:
                      // failing which will result in cache unable to be updated with data
                      // because initial data structure returned from back end did not contain this:
                      freeReplacement: {
                        ...headhunter.data.freeReplacement,
                        maxFreeReplacement: null,
                        __typename: 'HeadhunterFreeReplacementType'
                      }
                    }
                  }
                })
              }
              if (!headhunter.data.services) {
                client.writeData({
                  data: {
                    bosshuntUserDetail: {
                      ...headhunter.data,
                      // necessary to initialize the agencyDetail with the following:
                      // failing which will result in cache unable to be updated with data
                      services: []
                    }
                  }
                })
              }
              if (!headhunter.data.industries) {
                client.writeData({
                  data: {
                    bosshuntUserDetail: {
                      ...headhunter.data,
                      // necessary to initialize the agencyDetail with the following:
                      // failing which will result in cache unable to be updated with data
                      industries: []
                    }
                  }
                })
              }
              if (!headhunter.data.serviceLocations) {
                client.writeData({
                  data: {
                    bosshuntUserDetail: {
                      ...headhunter.data,
                      // necessary to initialize the agencyDetail with the following:
                      // failing which will result in cache unable to be updated with data
                      serviceLocations: []
                    }
                  }
                })
              }
              return (
                <React.Fragment>
                  <Route
                    exact
                    path={`${match.path}`}
                    component={() => <Redirect to={`${match.url}/account`} />}
                  />
                  <Route
                    exact
                    path="/bosshunt/user/:id/account"
                    render={() => (
                      <AccountSetting
                        match={match}
                        headhunterServerData={headhunter.data}
                      />
                    )}
                  />
                  <Route
                    path="/bosshunt/user/:id/my-ads"
                    render={() => (
                      <MyAds
                        match={match}
                        headhunterServerData={headhunter.data}
                      />
                    )}
                  />
                  {/* <Route
                    exact
                    path="/bosshunt/user/:id/myresume/:page"
                    component={MyResume}
                  />
                  <Route
                    path="/bosshunt/user/:id/pipeline/:page"
                    // component={RecruiterInformation}
                  /> */}
                </React.Fragment>
              )
            }
            return null
          }}
        </Query>
      </div>
    )
  }
}

BossHuntUserDetail.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default withRouter(BossHuntUserDetail)
