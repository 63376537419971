import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Mutation } from 'react-apollo'
import {
  SEND_INVOICE_EMAIL,
  SEND_RECEIPT_EMAIL
} from '../../../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Bootstrap */
import { Button } from 'reactstrap'

/**
 * SendInvoiceAndReceiptButton - Handles the Apollo logic side of the send invoice and send receipt buttons
 *
 * @returns {Component}
 */
const SendInvoiceAndReceiptButton = ({ transactionDetail }) => {
  const [invoiceMessage, toggleInvoiceMessage] = useState(false)
  const [receiptMessage, toggleReceiptMessage] = useState(false)
  const style = {
    bold: {
      fontWeight: 600
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px'
  }
  const renderInvoiceMessage = () => {
    toggleInvoiceMessage(true)
  }
  const renderReceiptMessage = () => {
    toggleReceiptMessage(true)
  }
  return (
    <div>
      <Can I="edit" a="invoice">
        <Mutation
          mutation={SEND_INVOICE_EMAIL}
          onCompleted={renderInvoiceMessage}
        >
          {(SendInvoiceEmail, { loading }) => {
            if (loading) return <Loading />
            return (
              <div style={style}>
                <Button
                  color="info"
                  name="invoice"
                  onClick={() => {
                    const variables = {
                      transactionId: transactionDetail.id
                    }
                    SendInvoiceEmail({ variables: variables })
                  }}
                >
                  Send Invoice
                </Button>
                {invoiceMessage ? <span>Invoice successfully sent</span> : null}
              </div>
            )
          }}
        </Mutation>
      </Can>
      {transactionDetail && transactionDetail.status === 'Completed' ? (
        <Can I="edit" a="invoice">
          <Mutation
            mutation={SEND_RECEIPT_EMAIL}
            onCompleted={renderReceiptMessage}
          >
            {(SendReceiptEmail, { loading }) => {
              if (loading) return <Loading />
              return (
                <div style={style}>
                  <Button
                    color="info"
                    name="receipt"
                    onClick={() => {
                      const variables = {
                        transactionId: transactionDetail.id
                      }
                      SendReceiptEmail({ variables: variables })
                    }}
                  >
                    Send Receipt
                  </Button>
                  {receiptMessage ? (
                    <span>Receipt successfully sent</span>
                  ) : null}
                </div>
              )
            }}
          </Mutation>
        </Can>
      ) : null}
    </div>
  )
}

SendInvoiceAndReceiptButton.propTypes = {
  transactionDetail: PropTypes.object.isRequired,
  SendInvoiceEmail: PropTypes.func,
  SendReceiptEmail: PropTypes.func
}

export default SendInvoiceAndReceiptButton
