import React, { Component } from 'react'

/* Bootstrap */
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Alert
} from 'reactstrap'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

import { Mutation } from 'react-apollo'
import { UPDATE_NOTIFY_MAINTAIN_INFO } from '../../schemas/mutations'

import { langMapReverse, hasEmpty, validSubmitDate } from '../../utils'

import history from '../../../../shared/helpers/history'

class EditForm extends Component {
  constructor(props) {
    super(props)
    const params = langMapReverse(props.data)

    this.state = {
      error: false,
      errorMessage: '',
      submitUuid: '',
      startedAt: null,
      endedAt: null,
      en: '',
      zhCN: '',
      zhTW: '',
      ja: '',
      id: '',
      ms: '',
      tr: '',
      ...params
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value })
  }

  render() {
    const {
      submitUuid,
      startedAt,
      endedAt,
      ms,
      en,
      zhCN,
      zhTW,
      id,
      ja,
      tr
    } = this.state

    return (
      <Form>
        <FormGroup>
          <Label>
            Effective time <span className="text-danger">*</span>
          </Label>
          <Row>
            <Col md={4} sm={12}>
              <DateTimePicker
                format="YYYY-MM-DD HH:mm A"
                time={true}
                value={new Date(startedAt)}
                placeholder="From"
                onChange={val => this.handleChange('startedAt', val)}
              />
            </Col>
            <div className="align-content-center">-</div>
            <Col md={4} sm={12}>
              <DateTimePicker
                format="YYYY-MM-DD HH:mm A"
                time={true}
                value={new Date(endedAt)}
                placeholder="To"
                onChange={val => this.handleChange('endedAt', val)}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>
            Content (English) <span className="text-danger">*</span>
          </Label>
          <Input
            name="en"
            value={en}
            maxLength={300}
            onChange={e => this.handleChange('en', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Content (Chinese Simplified) <span className="text-danger">*</span>
          </Label>
          <Input
            name="zhCN"
            value={zhCN}
            maxLength={300}
            onChange={e => this.handleChange('zhCN', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Chinese Traditional)</Label>
          <Input
            name="zhTW"
            value={zhTW}
            maxLength={300}
            onChange={e => this.handleChange('zhTW', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Japanese)</Label>
          <Input
            name="ja"
            value={ja}
            maxLength={300}
            onChange={e => this.handleChange('ja', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Indonesian)</Label>
          <Input
            name="id"
            value={id}
            maxLength={300}
            onChange={e => this.handleChange('id', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Malay)</Label>
          <Input
            name="ms"
            value={ms}
            maxLength={300}
            onChange={e => this.handleChange('ms', e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Turkish)</Label>
          <Input
            name="tr"
            value={tr}
            maxLength={300}
            onChange={e => this.handleChange('tr', e.target.value)}
          />
        </FormGroup>

        {this.state.error && (
          <Alert color="danger">{this.state.errorMessage}</Alert>
        )}

        <Can I="edit" a="website_outage_settings">
          <Mutation
            mutation={UPDATE_NOTIFY_MAINTAIN_INFO}
            onCompleted={() => {
              setTimeout(() => {
                this.setState({ alert: false })
                history.push('/maintenance/list/1')
              }, 1700)
            }}
            onError={graphQLError => {
              if (graphQLError) {
                this.setState({
                  error: true,
                  errorMessage: graphQLError.message
                })
              }
            }}
          >
            {(updateWebsiteOutage, { data, loading, error }) => (
              <div style={{ textAlign: 'right' }}>
                <Button
                  color="info"
                  onClick={() => {
                    const input = {
                      startedAt: startedAt
                        ? new Date(startedAt).toISOString()
                        : null,
                      endedAt: endedAt ? new Date(endedAt).toISOString() : null,
                      contentList: [
                        { value: en.trim() || '', languageCode: 'en' },
                        { value: zhCN.trim() || '', languageCode: 'zh-CN' },
                        { value: zhTW.trim() || '', languageCode: 'zh-TW' },
                        { value: id.trim() || '', languageCode: 'id' },
                        { value: ja.trim() || '', languageCode: 'ja' },
                        { value: ms.trim() || '', languageCode: 'ms' },
                        { value: tr.trim() || '', languageCode: 'tr' }
                      ]
                    }

                    const validDataMessage = validSubmitDate({
                      startedAt,
                      endedAt,
                      en: en.trim(),
                      zhCN: zhCN.trim()
                    })

                    if (validDataMessage) {
                      this.setState({
                        error: true,
                        errorMessage: validDataMessage
                      })
                      return
                    }

                    this.setState({
                      error: false,
                      errorMessage: ''
                    })

                    updateWebsiteOutage({
                      variables: {
                        submitUuid,
                        websiteOutageInput: input
                      }
                    })
                  }}
                >
                  Update
                </Button>
              </div>
            )}
          </Mutation>
        </Can>
      </Form>
    )
  }
}

export default EditForm
