/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

/**
 * SubscriptionList
 *
 * @returns {Component}
 */
const SubscriptionPlanList = ({
  subscriptionPlanList,
  currentPage,
  totalNum,
  totalPages
}) => {
  return (
    <>
      <Table bordered>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Category</th>
            <th>Monthly Fee</th>
            <th>Annual Fee</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptionPlanList.map((subscriptionPlan, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'center' }}>
                <a
                  href={`/bossjob/subscription-plan/detail/${
                    subscriptionPlan.id
                  }`}
                >
                  {subscriptionPlan.id}
                </a>
              </td>
              <td>{subscriptionPlan.name}</td>
              <td>{subscriptionPlan.category}</td>
              <td>{subscriptionPlan.monthlyFee}</td>
              <td>{subscriptionPlan.annualFee}</td>
              <td>
                <div style={{ textAlign: 'center' }}>
                  <a
                    href={`/bossjob/subscription-plan/detail/${
                      subscriptionPlan.id
                    }`}
                  >
                    <Button color="success">
                      <img src={Zoom} alt="Zoom" width="16" height="16" />
                    </Button>
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalNum}
        totalPages={totalPages}
        pageRange={10}
      />
    </>
  )
}

SubscriptionPlanList.propTypes = {
  subscriptionPlanList: PropTypes.array.isRequired,
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalNum: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired
}

export default SubscriptionPlanList
