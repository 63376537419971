import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, Mutation } from 'react-apollo'
import {
  GET_LOCAL_COMPANY_DETAIL,
  GET_COMPANY_DOCUMENT,
  GET_COMPANY_DOCUMENT_BY_ADMIN
} from '../../../schemas/queries'
import {
  UPLOAD_COMPANY_DOCUMENT,
  UPLOAD_COMPANY_DOCUMENT_BY_ADMIN
} from '../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import CompanyDocument from '../../../views/CompanyDetail/CompanyInformation/CompanyDocument'

/**
 * CompanyDocumentContainer - container component to query and mutate
 * company information and document and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class CompanyDocumentContainer extends Component {
  static propTypes = {
    canEdit: PropTypes.bool,
    fileId: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      file: null
    }

    this.handleFileSelect = this.handleFileSelect.bind(this)
  }

  handleFileSelect(file) {
    this.setState({
      file: file
    })
  }

  handleUploadDocumentResult(data) {
    if (data.uploadCompanyDocument.ok === false) {
      window.alert(data.uploadCompanyDocument.message)
    } else if (data.uploadCompanyDocument.ok === true) {
      window.location.reload()
    }
  }

  handleUploadDocumentResultByAdmin(data) {
    if (data.uploadCompanyDocumentByAdmin.ok === false) {
      window.alert(data.uploadCompanyDocumentByAdmin.message)
    } else if (data.uploadCompanyDocumentByAdmin.ok === true) {
      window.location.reload()
    }
  }

  render() {
    const { fileId } = this.props
    return (
      <Query query={GET_LOCAL_COMPANY_DETAIL}>
        {({ data: { companyDetail } }) => (
          <Query
            query={
              fileId === 'company-admin-file'
                ? GET_COMPANY_DOCUMENT_BY_ADMIN
                : GET_COMPANY_DOCUMENT
            }
            variables={{ companyId: companyDetail.id }}
          >
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else {
                let documentData = {}
                if (fileId === 'company-file') {
                  documentData =
                    data &&
                    data.getCompany &&
                    data.getCompany.data &&
                    data.getCompany.data.document
                }
                if (fileId === 'company-admin-file') {
                  documentData = {
                    url:
                      (data &&
                        data.getCompanyDocumentByAdmin &&
                        data.getCompanyDocumentByAdmin.data) ||
                      ''
                  }
                }

                return (
                  <Mutation
                    mutation={
                      fileId === 'company-admin-file'
                        ? UPLOAD_COMPANY_DOCUMENT_BY_ADMIN
                        : UPLOAD_COMPANY_DOCUMENT
                    }
                    onCompleted={data =>
                      fileId === 'company-admin-file'
                        ? this.handleUploadDocumentResultByAdmin(data)
                        : this.handleUploadDocumentResult(data)
                    }
                  >
                    {(UploadCompanyDocument, { loading }) => {
                      return (
                        <CompanyDocument
                          document={documentData}
                          file={this.state.file}
                          fileId={fileId}
                          handleFileSelect={this.handleFileSelect}
                          handleFileUpload={UploadCompanyDocument}
                          companyId={companyDetail.id}
                          isUploading={loading}
                          canEdit={this.props.canEdit}
                        />
                      )
                    }}
                  </Mutation>
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}
CompanyDocumentContainer.propTypes = {
  companyDetail: PropTypes.object,
  fileId: PropTypes.string
}

export default CompanyDocumentContainer
