export default {
  agencyDetail: {
    __typename: 'CompanyType',
    data: {
      feeStructure: {
        isContingentFee: false,
        isRetainedFee: false,
        isOtherFee: false,
        annualGrossSalaryFrom: null,
        annualGrossSalaryTo: null,
        monthlyFeeFrom: null,
        monthlyFeeTo: null,
        otherFee: null,
        __typename: 'HeadhunterFeeStructureType'
      },
      turnaroundTime: {
        turnaroundTimeId: 1,
        fromPeriod: null,
        toPeriod: null,
        __typename: 'HeadhunterTurnaroundTimeType'
      },
      guaranteePeriod: {
        guaranteePeriodId: 1,
        fromPeriod: null,
        toPeriod: null,
        __typename: 'HeadhunterGuaranteePeriodType'
      },
      freeReplacement: {
        maxFreeReplacement: null,
        __typename: 'HeadhunterFreeReplacementType'
      },
      companySize: {
        id: null,
        name: null,
        __typename: 'CompanySizeType'
      },
      location: {
        id: null,
        name: null,
        __typename: 'LocationType'
      }
    }
  },
  agencyDetailForNewForm: {
    __typename: 'AddAgency',
    name: null,
    websiteUrl: null,
    logo: null,
    cover: null,
    facebookUrl: null,
    description: null,
    descriptionHtml: null,
    address: null,
    contactNum: null,
    email: null,
    poeaLicense: null,
    doleLicense: null,
    foundingYear: null,
    companySize: {
      id: null,
      name: null,
      __typename: 'CompanySizeType'
    },
    isActive: true,
    isFeatured: false,
    location: {
      id: null,
      name: null,
      __typename: 'LocationType'
    }
    // services: {
    //   id: null,
    //   name: null,
    //   __typename: 'ServiceType'
    // },
    // industries: {
    //   id: null,
    //   name: null,
    //   __typename: 'IndustryType'
    // },
    // serviceLocations: {
    //   id: null,
    //   name: null,
    //   __typename: 'ServiceLocationType'
    // }
    // // locationId: null,
    // // serviceIds: null,
    // // industryIds: null,
  }
}
