import { GET_LOCAL_NEPTUNE_COMPANY_DETAIL } from '../schemas/queries'

export default {
  updateNeptuneCompany: (_, { key, value }, { cache }) => {
    const { getNeptuneCompany } = cache.readQuery({
      query: GET_LOCAL_NEPTUNE_COMPANY_DETAIL
    })

    let newCompanyDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        getNeptuneCompany: {
          ...getNeptuneCompany,
          ...newCompanyDetail
        }
      }
    })

    return null
  }
}
