import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_LOCAL_COMPANY_DETAIL,
  GET_COMPANY_REVIEWS_BY_COMPANY_ID
} from '../../../schemas/queries'

/* Component */
import Loading from 'shared/components/Loading'
import CompanyReviewList from '../../../views/CompanyDetail/CompanyReviews/CompanyReviewList'

/**
 * CompanyReviewListContainer - container component to query company review list
 * under a company and return CompanyReviewList component when is done
 *
 * @returns {Component}
 *
 */
const CompanyReviewListContainer = ({ match, ...props }) => (
  <Query query={GET_LOCAL_COMPANY_DETAIL}>
    {({ data: { companyDetail } }) => (
      <Query
        query={GET_COMPANY_REVIEWS_BY_COMPANY_ID}
        variables={{ companyId: companyDetail.id, page: match.params.page }}
      >
        {({ loading, data }) => {
          if (loading) {
            return <Loading />
          } else {
            return (
              <CompanyReviewList
                reviewList={data.companyReviews.data.reviews}
                totalCount={data.companyReviews.data.totalCount}
                totalPages={data.companyReviews.data.totalPages}
                currentPage={data.companyReviews.data.currentPage}
                {...props}
              />
            )
          }
        }}
      </Query>
    )}
  </Query>
)

CompanyReviewListContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default withRouter(CompanyReviewListContainer)
