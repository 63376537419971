import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

/* Vendors */
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import moment from 'moment'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'

/* Helpers */
import { convertToISOFormat, formatDate } from 'shared/helpers/datetime'

/**
 * DetailForm - A part of the Transaction Detail form which
 * contains transaction details e.g: Reference Number, Amount,
 * Item Name, Description and Payment Method
 *
 * @returns {Component}
 */
const DetailForm = ({
  subscriptionId,
  handleChange,
  customHandleChangeForNewForm,
  handleChangeForSubscriptionPlanId,
  handleChangeForDuration,
  subscriptionDetail,
  subscriptionPlanList,
  isDisabled,
  endDateFormatter
}) => {
  const [startDate, setStartDate] = useState(
    subscriptionDetail && subscriptionDetail.startedAt
      ? subscriptionDetail.startedAt
      : new Date()
  )
  const [endDate, setEndDate] = useState(
    subscriptionDetail && subscriptionDetail.endedAt
      ? new Date(subscriptionDetail.endedAt)
      : ''
  )

  useEffect(
    () => {
      if (!subscriptionId) {
        customHandleChangeForNewForm(
          'startedAt',
          convertToISOFormat(startDate, true)
        )
        if (subscriptionDetail && subscriptionDetail.paymentDuration) {
          setEndDate(
            endDateFormatter(startDate, subscriptionDetail.paymentDuration)
          )
          customHandleChangeForNewForm(
            'endedAt',
            convertToISOFormat(endDate, true, false)
          )
        }
      }
    },
    [startDate]
  )

  useEffect(
    () => {
      if (!subscriptionId) {
        if (subscriptionDetail && subscriptionDetail.paymentDuration) {
          setEndDate(
            endDateFormatter(
              startDate,
              subscriptionDetail && subscriptionDetail.paymentDuration
            )
          )
        }
      }
    },
    [subscriptionDetail && subscriptionDetail.paymentDuration]
  )

  const _renderSubscriptionIDField = () => {
    return (
      <FormGroup>
        <Label>Subscription ID</Label>
        <Input
          type="text"
          name="id"
          onChange={handleChange}
          disabled={true}
          value={
            subscriptionDetail && subscriptionDetail.id
              ? subscriptionDetail.id
              : ''
          }
        />
      </FormGroup>
    )
  }

  const _renderSubscriptionPlanNameField = () => {
    return (
      <FormGroup>
        <Label for="paymentKey">Subscription Plan Name*</Label>
        <Input
          type="select"
          name="subscriptionPlanId"
          onChange={handleChangeForSubscriptionPlanId}
          disabled={isDisabled}
          value={
            subscriptionDetail && subscriptionDetail.subscriptionPlanId
              ? subscriptionDetail.subscriptionPlanId
              : 1
          }
        >
          {subscriptionPlanList.map((subscriptionPlan, index) => (
            <option
              key={index}
              value={subscriptionPlan.id}
              data-name={subscriptionPlan.name}
              data-amount={subscriptionPlan.monthlyFee}
              data-monthly-job-credit={subscriptionPlan.monthlyJobCredit}
              data-subscription-plan-index={index}
              data-monthly-unlock-talent-credit={
                subscriptionPlan.monthlyUnlockTalentCredit
              }
              data-monthly-rh-talent-credit={
                subscriptionPlan.monthlyRhTalentCredit
              }
              data-monthly-feature-job-credit={
                subscriptionPlan.monthlyFeatureJobCredit
              }
              data-monthly-urgent-job-credit={
                subscriptionPlan.monthlyUrgentJobCredit
              }
            >
              {subscriptionPlan.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  }

  const _renderAmountField = () => {
    return (
      <FormGroup>
        <Label>Monthly price (PHP)*</Label>
        <Input
          type="number"
          name="amount"
          value={
            (subscriptionDetail &&
              subscriptionDetail.amount &&
              !subscriptionId) ||
            (subscriptionDetail && subscriptionDetail.paymentTypeId === 1)
              ? subscriptionDetail.amount
              : null
          }
          onChange={handleChange}
          disabled
        />
      </FormGroup>
    )
  }

  const _renderDescriptionField = () => {
    return (
      <FormGroup>
        <Label>Description</Label>
        <Input
          type="textarea"
          name="description"
          disabled={isDisabled}
          value={
            subscriptionDetail && subscriptionDetail.description
              ? subscriptionDetail.description
              : ''
          }
          onChange={handleChange}
        />
      </FormGroup>
    )
  }

  const _renderStartDateField = () => {
    return (
      <FormGroup>
        <Label>Start Date</Label>
        {!subscriptionId ? (
          <DateTimePicker
            name="startDate"
            format="MMM D, YYYY"
            time={false}
            value={new Date(startDate)}
            placeholder="From"
            disabled={isDisabled}
            onChange={newDate => {
              setStartDate(newDate)
            }}
          />
        ) : (
          <Input
            name="startDate"
            type="text"
            value={moment(subscriptionDetail.startedAt).format('MMM DD, YYYY')}
            disabled={isDisabled}
          />
        )}
      </FormGroup>
    )
  }

  const _renderEndDateField = () => {
    return (
      <FormGroup>
        <Label>End Date</Label>
        {!subscriptionId ? (
          <DateTimePicker
            name="endDate"
            format="MMM D, YYYY"
            time={false}
            value={new Date(endDate)}
            placeholder="To"
            disabled
          />
        ) : (
          <Input
            name="endDate"
            type="text"
            value={moment(subscriptionDetail.endedAt).format('MMM DD, YYYY')}
            disabled={isDisabled}
          />
        )}
      </FormGroup>
    )
  }

  const _renderLastPaymentDateField = () => {
    return (
      <FormGroup>
        <Label>Last Payment Date</Label>
        <p>
          {subscriptionDetail && subscriptionDetail.lastPaymentAt
            ? formatDate(
              subscriptionDetail.lastPaymentAt,
              'DD/MM/YYYY HH:mm:ss'
            )
            : '-'}
        </p>
      </FormGroup>
    )
  }

  const _renderNextPaymentDateField = () => {
    return (
      <FormGroup>
        <Label>Next Payment Date</Label>
        <p>
          {subscriptionDetail && subscriptionDetail.nextPaymentAt
            ? formatDate(
              subscriptionDetail.nextPaymentAt,
              'DD/MM/YYYY HH:mm:ss'
            )
            : '-'}
        </p>
      </FormGroup>
    )
  }

  const _renderLastCreditDateField = () => {
    return (
      <FormGroup>
        <Label>Last Credit Date</Label>
        <p>
          {subscriptionDetail && subscriptionDetail.lastCreditAt
            ? formatDate(subscriptionDetail.lastCreditAt, 'DD/MM/YYYY HH:mm:ss')
            : '-'}
        </p>
      </FormGroup>
    )
  }

  const _renderNextCreditDateField = () => {
    return (
      <FormGroup>
        <Label>Next Credit Date</Label>
        <p>
          {subscriptionDetail && subscriptionDetail.nextCreditAt
            ? formatDate(subscriptionDetail.nextCreditAt, 'DD/MM/YYYY HH:mm:ss')
            : '-'}
        </p>
      </FormGroup>
    )
  }

  const _renderDurationField = () => {
    const durationOptions = [
      { label: '1 month', value: 1 },
      { label: '2 months', value: 2 },
      { label: '3 months', value: 3 },
      { label: '4 months', value: 4 },
      { label: '5 months', value: 5 },
      { label: '6 months', value: 6 },
      { label: '7 months', value: 7 },
      { label: '8 months', value: 8 },
      { label: '9 months', value: 9 },
      { label: '10 months', value: 10 },
      { label: '11 months', value: 11 },
      { label: '12 months', value: 12 }
    ]

    return (
      <FormGroup>
        <Label>Duration*</Label>
        <Input
          type="select"
          name="paymentDuration"
          value={subscriptionDetail && subscriptionDetail.paymentDuration}
          onChange={
            handleChangeForDuration ? handleChangeForDuration : handleChange
          }
          disabled={isDisabled}
        >
          {durationOptions.map(option => (
            <option
              data-amount={
                subscriptionDetail && subscriptionDetail.amount
                  ? subscriptionDetail.amount
                  : null
              }
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  }

  const _renderJobPostQuantityField = () => {
    return (
      <FormGroup>
        <Label># of job posts*</Label>
        <Input
          type="number"
          name="jobPost"
          value={
            subscriptionDetail &&
            subscriptionDetail.jobPost &&
            subscriptionDetail.jobPost
          }
          onChange={handleChange}
        />
      </FormGroup>
    )
  }

  const _renderUnlockTalentCreditField = () => {
    return (
      <FormGroup>
        <Label># of talent credits*</Label>
        <Input
          type="number"
          name="unlockTalentCredit"
          value={
            subscriptionDetail &&
            subscriptionDetail.unlockTalentCredit &&
            subscriptionDetail.unlockTalentCredit
          }
          onChange={handleChange}
        />
      </FormGroup>
    )
  }

  const _renderRhTalentCreditField = () => {
    return (
      <FormGroup>
        <Label># of rh talent credits*</Label>
        <Input
          type="number"
          name="rhTalentCredit"
          value={
            subscriptionDetail &&
            subscriptionDetail.rhTalentCredit &&
            subscriptionDetail.rhTalentCredit
          }
          onChange={handleChange}
        />
      </FormGroup>
    )
  }

  const _renderFeatureJobCreditField = () => {
    return (
      <FormGroup>
        <Label># of feature job credits*</Label>
        <Input
          type="number"
          name="featureJobCredit"
          value={
            subscriptionDetail &&
            subscriptionDetail.featureJobCredit &&
            subscriptionDetail.featureJobCredit
          }
          onChange={handleChange}
        />
      </FormGroup>
    )
  }

  const _renderUrgentJobCreditField = () => {
    return (
      <FormGroup>
        <Label># of urgent job credits*</Label>
        <Input
          type="number"
          name="urgentJobCredit"
          value={
            subscriptionDetail &&
            subscriptionDetail.urgentJobCredit &&
            subscriptionDetail.urgentJobCredit
          }
          onChange={handleChange}
        />
      </FormGroup>
    )
  }

  const _renderBossPointQuantityField = () => {
    return (
      <FormGroup>
        <Label># of BossPoints*</Label>
        <Input
          type="number"
          name="bosspoint"
          value={
            subscriptionDetail && subscriptionDetail.bosspoint
              ? subscriptionDetail.bosspoint
              : ''
          }
          placeholder={0}
          onChange={handleChange}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  return (
    <SectionWrapper sectionTitle="Subscription Details">
      <Form>
        <Row>
          <Col md={6}>{_renderSubscriptionIDField()}</Col>
          <Col md={6}>{_renderDescriptionField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderSubscriptionPlanNameField()}</Col>
          <Col md={6}>{_renderAmountField()}</Col>
        </Row>
        <Row>
          <Col md={12}>{_renderJobPostQuantityField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderUnlockTalentCreditField()}</Col>
          <Col md={6}>{_renderRhTalentCreditField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderFeatureJobCreditField()}</Col>
          <Col md={6}>{_renderUrgentJobCreditField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderBossPointQuantityField()}</Col>
          <Col md={6}>{_renderDurationField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderStartDateField()}</Col>
          <Col md={6}>{_renderEndDateField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderLastPaymentDateField()}</Col>
          <Col md={6}>{_renderNextPaymentDateField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderLastCreditDateField()}</Col>
          <Col md={6}>{_renderNextCreditDateField()}</Col>
        </Row>
      </Form>
    </SectionWrapper>
  )
}

DetailForm.propTypes = {
  subscriptionId: PropTypes.string,
  subscriptionDetail: PropTypes.object,
  subscriptionPlanList: PropTypes.array.isRequired,
  transactionId: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  handleChangeForSubscriptionPlanId: PropTypes.func.isRequired,
  handleChangeForDuration: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
  customHandleChangeForNewForm: PropTypes.func,
  endDateFormatter: PropTypes.func,
  subscriptionPlanIndex: PropTypes.number.isRequired
}

export default DetailForm
