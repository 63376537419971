import React, { Component } from 'react'

/* Apollo */
import { Mutation } from 'react-apollo'
import { ADD_ROLE } from '../../schemas/mutations'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Component */
import SectionWrapper from 'shared/components/SectionWrapper'

class AddRole extends Component {
  constructor(props) {
    super(props)

    this.state = {
      roleName: ''
    }
  }

  render() {
    const { roleName } = this.state

    return (
      <SectionWrapper sectionTitle="Add Role" isMainPage>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input
              name="name"
              value={roleName}
              onChange={e => {
                this.setState({ roleName: e.target.value })
              }}
            />
          </FormGroup>
          <Mutation
            mutation={ADD_ROLE}
            onCompleted={() => (window.location.href = '/roles')}
          >
            {AddRole => (
              <div style={{ textAlign: 'right' }}>
                <Button
                  color="info"
                  onClick={() => {
                    if (roleName === '') return

                    AddRole({
                      variables: {
                        name: roleName
                      }
                    })
                  }}
                >
                  Add
                </Button>
              </div>
            )}
          </Mutation>
        </Form>
      </SectionWrapper>
    )
  }
}

export default AddRole
