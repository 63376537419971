exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formSection__3d77d {\n  margin-top: 22px;\n}\n\n.button__2HTH5 {\n  width: 100%;\n}", "", {"version":3,"sources":["/app/src/modules/jobs/containers/JobDetailContainer/JobDetailContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAgB;CAClB;;AAEA;EACE,YAAW;CACb","file":"JobDetailContainer.module.scss","sourcesContent":[".formSection {\n  margin-top: 22px;\n}\n\n.button {\n  width: 100%;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formSection": "formSection__3d77d",
	"button": "button__2HTH5"
};