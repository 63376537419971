exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formWrapper__2D2yK {\n  border-bottom: 1px solid #d8d8d8;\n  margin-bottom: 30px;\n  padding-bottom: 20px;\n}\n\n.addressDetail__3truQ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.isDefaultCheckbox__1V7Ih {\n  left: 120%;\n}", "", {"version":3,"sources":["/app/src/modules/companies/views/CompanyDetail/CompanyInformation/CompanyWorkingAddress/CompanyWorkingAddressForm/CompanyWorkingAddressForm.module.scss"],"names":[],"mappings":"AAAA;EACE,iCAAgC;EAChC,oBAAmB;EACnB,qBAAoB;CACtB;;AAEA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,0BAAmB;MAAnB,uBAAmB;UAAnB,oBAAmB;CACrB;;AAEA;EACE,WAAU;CACZ","file":"CompanyWorkingAddressForm.module.scss","sourcesContent":[".formWrapper {\n  border-bottom: 1px solid #d8d8d8;\n  margin-bottom: 30px;\n  padding-bottom: 20px;\n}\n\n.addressDetail {\n  display: flex;\n  align-items: center;\n}\n\n.isDefaultCheckbox {\n  left: 120%;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formWrapper": "formWrapper__2D2yK",
	"addressDetail": "addressDetail__3truQ",
	"isDefaultCheckbox": "isDefaultCheckbox__1V7Ih"
};