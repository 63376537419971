import React from 'react'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_BOSSHUNT_USER_DETAIL } from '../../../schemas/queries'
import { GET_AGENCY_LIST } from '../../../../agencies/schemas/queries'

/* Component */
import Loading from 'shared/components/Loading'
import AccountSettingFormContainer from '../../../containers/BossHuntUserDetailContainer/AccountSettingFormContainer'

/**
 * AccountSetting - main wrapper component for the Account Setting page
 *
 * @returns {Component}
 */
const AccountSetting = ({ ...props }) => (
  <Query query={GET_LOCAL_BOSSHUNT_USER_DETAIL}>
    {({ loading, data: { bosshuntUserDetail } }) => {
      if (loading) {
        return <Loading />
      }
      return (
        <Query query={GET_AGENCY_LIST} variables={{ size: 9999 }}>
          {({ loading, data: { headhunterCompanyList }, client }) => {
            if (loading) {
              return <Loading />
            }
            let agencyList = headhunterCompanyList.data.companies
            let localAgencyList = [
              { id: 0, name: 'null', __typename: 'CompanyType' },
              ...agencyList
            ]
            client.writeData({
              data: {
                bosshuntAgencyList: {
                  ...agencyList,
                  ...localAgencyList
                }
              }
            })
            return (
              <AccountSettingFormContainer
                {...props}
                userData={bosshuntUserDetail}
                agencyList={localAgencyList}
              />
            )
          }}
        </Query>
      )
    }}
  </Query>
)

export default AccountSetting
