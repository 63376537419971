exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".searchList__2HAaO {\n  position: absolute;\n  top: 38px;\n  background: #fff;\n  width: 100%;\n  z-index: 50;\n}\n.searchList__2HAaO ul {\n  margin-left: 0;\n  padding: 10px;\n}\n.searchList__2HAaO ul li {\n  list-style: none;\n  padding: 15px 5px;\n  border-bottom: 1px solid #d8d8d8;\n  cursor: pointer;\n  -webkit-transition: 200ms ease-in-out all;\n  transition: 200ms ease-in-out all;\n}\n.searchList__2HAaO ul li:hover {\n  background: #ddd;\n}", "", {"version":3,"sources":["/app/src/modules/marketing/views/TopCompanies/FeaturedCompanyContainer/components/CompanyInputField/CompanyInputField.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAkB;EAClB,UAAS;EACT,iBAAgB;EAChB,YAAW;EACX,YAAW;CACb;AACA;EACE,eAAc;EACd,cAAa;CACf;AACA;EACE,iBAAgB;EAChB,kBAAiB;EACjB,iCAAgC;EAChC,gBAAe;EACf,0CAAiC;EAAjC,kCAAiC;CACnC;AACA;EACE,iBAAgB;CAClB","file":"CompanyInputField.module.scss","sourcesContent":[".searchList {\n  position: absolute;\n  top: 38px;\n  background: #fff;\n  width: 100%;\n  z-index: 50;\n}\n.searchList ul {\n  margin-left: 0;\n  padding: 10px;\n}\n.searchList ul li {\n  list-style: none;\n  padding: 15px 5px;\n  border-bottom: 1px solid #d8d8d8;\n  cursor: pointer;\n  transition: 200ms ease-in-out all;\n}\n.searchList ul li:hover {\n  background: #ddd;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"searchList": "searchList__2HAaO"
};