import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Styling */
import styles from './CompanyWorkingAddressForm.module.scss'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'
import {
  ADD_COMPANY_WORKING_ADDRESS,
  DELETE_COMPANY_WORKING_ADDRESS,
  UPDATE_COMPANY_WORKING_ADDRESS
} from '../../../../../schemas/mutations'
import GoogleMap from '../../../../../../../shared/components/GoogleMap/GoogleMap'
import { useEffect } from 'react'

const CompanyWorkingAddressForm = ({
  workingAddress,
  isNew,
  refetch,
  cancelAdding,
  successAdding,
  canEdit
}) => {
  const [workingAddressInput, setWorkingAddressInput] = useState({
    id: isNew ? null : workingAddress.id,
    companyId: workingAddress.companyId,
    floorUnit: workingAddress.floorUnit ? workingAddress.floorUnit : '',
    address: workingAddress.address ? workingAddress.address : '',
    longitude: workingAddress.longitude ? workingAddress.longitude : '',
    latitude: workingAddress.latitude ? workingAddress.latitude : '',
    isDefault: workingAddress.isDefault ? workingAddress.isDefault : false
  })
  const [isMapModalOpen, setIsMapModalOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(
    () => {
      const refetchWorkingAddressData = () => {
        setWorkingAddressInput({ ...workingAddress })
      }
      refetchWorkingAddressData()
    },
    [workingAddress]
  )

  const handleMapCancel = () => {
    setIsMapModalOpen(false)
  }

  const handleMapOk = placeDetail => {
    setWorkingAddressInput({
      ...workingAddressInput,
      address: placeDetail.name + ', ' + placeDetail.formatted_address,
      latitude: placeDetail.geometry.location.lat(),
      longitude: placeDetail.geometry.location.lng()
    })
    setIsMapModalOpen(false)
  }

  const deleteWorkAddress = async (
    apolloClient,
    workingAddressId,
    companyId
  ) => {
    let response = await apolloClient.mutate({
      mutation: DELETE_COMPANY_WORKING_ADDRESS,
      variables: {
        workingAddressId: workingAddressId,
        companyId: companyId
      }
    })

    if (response) {
      window.alert('Successfully deleted work address')
      refetch()
      setWorkingAddressInput({ ...workingAddress })
    }
    setIsSubmitting(false)
  }

  const updateWorkAddress = async (apolloClient, workingAddressData) => {
    try {
      setIsSubmitting(true)
      let response = await apolloClient.mutate({
        mutation: UPDATE_COMPANY_WORKING_ADDRESS,
        variables: {
          payload: {
            id: workingAddressData.id,
            companyId: workingAddressData.companyId,
            floorUnit: workingAddressData.floorUnit,
            address: workingAddressData.address,
            longitude: workingAddressData.longitude,
            latitude: workingAddressData.latitude,
            isDefault: workingAddressData.isDefault
          }
        }
      })

      if (response) {
        window.alert('Successfully updated work address')
        refetch()
        setWorkingAddressInput({ ...workingAddress })
        setIsSubmitting(false)
      }
    } catch (error) {
      setIsSubmitting(false)
      window.alert('Address field is compulsory')
    }
  }

  const addWorkAddress = async (apolloClient, workingAddressData) => {
    try {
      setIsSubmitting(true)
      let response = await apolloClient.mutate({
        mutation: ADD_COMPANY_WORKING_ADDRESS,
        variables: {
          payload: {
            companyId: workingAddressData.companyId,
            floorUnit: workingAddressData.floorUnit,
            address: workingAddressData.address,
            longitude: workingAddressData.longitude,
            latitude: workingAddressData.latitude,
            isDefault: workingAddressData.isDefault
          }
        }
      })

      if (response) {
        window.alert('Successfully added work address')
        successAdding()
        setIsSubmitting(false)
      }
    } catch (error) {
      setIsSubmitting(false)
      window.alert('Address field is compulsory')
    }
  }

  return (
    <Form className={styles.formWrapper}>
      <Row>
        <Col md={10}>
          <FormGroup row>
            <Label md={3} sm={12}>
              Floor/Unit:
            </Label>
            <Col md={6} sm={9} className={styles.addressDetail}>
              <Input
                name="floorUnit"
                value={workingAddressInput.floorUnit}
                onChange={e =>
                  setWorkingAddressInput({
                    ...workingAddressInput,
                    floorUnit: e.target.value
                  })
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={12}>
              Address:
            </Label>
            <Col md={6} sm={9} className={styles.addressDetail}>
              {workingAddressInput.address}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={12}>
              Coordinate:
            </Label>
            <Col md={6} sm={9} className={styles.addressDetail}>
              <div style={{ marginRight: '10px' }}>
                Lat: {workingAddressInput.latitude}
              </div>
              <div>Lng: {workingAddressInput.longitude}</div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={12}>
              Is Default:
              <Input
                type="checkbox"
                name="isDefault"
                className={styles.isDefaultCheckbox}
                checked={workingAddressInput.isDefault}
                onChange={() =>
                  setWorkingAddressInput({
                    ...workingAddressInput,
                    isDefault: !workingAddressInput.isDefault
                  })
                }
              />
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Can I="edit" a="companies">
        {can =>
          can ? (
            <ApolloConsumer>
              {client => {
                return (
                  <React.Fragment>
                    {isNew ? (
                      <React.Fragment>
                        <div style={{ textAlign: 'right' }}>
                          <Button color="danger" onClick={() => cancelAdding()}>
                            Cancel
                          </Button>
                          <Button
                            style={{ margin: '0 10px' }}
                            color="info"
                            onClick={() => setIsMapModalOpen(true)}
                            disabled={!canEdit}
                          >
                            Edit Address
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              addWorkAddress(client, workingAddressInput)
                            }}
                            disabled={isSubmitting || !canEdit}
                          >
                            Add New
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            color="danger"
                            onClick={() => {
                              setIsSubmitting(true)
                              if (
                                confirm(
                                  'Confirm to DELETE selected working address?'
                                )
                              ) {
                                deleteWorkAddress(
                                  client,
                                  workingAddress.id,
                                  workingAddress.companyId
                                )
                              }
                            }}
                            disabled={isSubmitting || !canEdit}
                          >
                            Delete
                          </Button>
                          <Button
                            style={{ margin: '0 10px' }}
                            color="info"
                            onClick={() => setIsMapModalOpen(true)}
                            disabled={!canEdit}
                          >
                            Edit Address
                          </Button>
                          <Button
                            color="info"
                            onClick={() => {
                              updateWorkAddress(client, workingAddressInput)
                            }}
                            disabled={!canEdit}
                          >
                            Update
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                    {isMapModalOpen && (
                      <GoogleMap
                        handleCancel={handleMapCancel}
                        handleOk={handleMapOk}
                        isModalOpen={isMapModalOpen}
                        defaultAddress={
                          workingAddressInput.address
                            ? workingAddressInput.address
                            : ''
                        }
                        lat={workingAddressInput.latitude}
                        lng={workingAddressInput.longitude}
                      />
                    )}
                  </React.Fragment>
                )
              }}
            </ApolloConsumer>
          ) : (
            <></>
          )
        }
      </Can>
    </Form>
  )
}

CompanyWorkingAddressForm.propTypes = {
  workingAddress: PropTypes.object,
  isNew: PropTypes.bool,
  refetch: PropTypes.func,
  cancelAdding: PropTypes.func,
  successAdding: PropTypes.func,
  canEdit: PropTypes.bool
}

export default CompanyWorkingAddressForm
