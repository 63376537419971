export default {
  bossjobUserDetail: {
    __typename: 'BossjobUserSQLType',
    data: {}
  },
  creditModal: {
    __typename: 'CreditModalData',
    isVisible: false,
    modalData: null
  },
  bossjobUserSocialLinks: []
}
