exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".subMenu__2kc_v {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 40px;\n}\n.subMenuItem__1R6J3 {\n  margin-right: 10px;\n  padding: 10px 15px;\n  color: #212529;\n  font-weight: bold;\n}\n.subMenuItemActive__2F2sW {\n  border: 1px solid #20a8d8;\n  border-radius: 5px;\n  color: #20a8d8;\n}", "", {"version":3,"sources":["/app/src/modules/bossjob_users/views/BossJobUserDetail/RecruiterInformation/RecruiterInformation.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,wBAA2B;MAA3B,qBAA2B;UAA3B,4BAA2B;EAC3B,0BAAmB;MAAnB,uBAAmB;UAAnB,oBAAmB;EACnB,oBAAmB;CACrB;AACA;EACE,mBAAkB;EAClB,mBAAkB;EAClB,eAAc;EACd,kBAAiB;CACnB;AACA;EACE,0BAAyB;EACzB,mBAAkB;EAClB,eAAc;CAChB","file":"RecruiterInformation.module.scss","sourcesContent":[".subMenu {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 40px;\n}\n.subMenuItem {\n  margin-right: 10px;\n  padding: 10px 15px;\n  color: #212529;\n  font-weight: bold;\n}\n.subMenuItemActive {\n  border: 1px solid #20a8d8;\n  border-radius: 5px;\n  color: #20a8d8;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"subMenu": "subMenu__2kc_v",
	"subMenuItem": "subMenuItem__1R6J3",
	"subMenuItemActive": "subMenuItemActive__2F2sW"
};