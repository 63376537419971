/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
// import ToggleButton from 'shared/components/ToggleButton'

/**
 * PaymentDetailForm - A part of the Transaction Detail form which
 * contains transaction payment details e.g: VAT, exchange rate etc
 *
 */
const PaymentDetailForm = ({
  isDisabled,
  handleChange,
  customHandleChange,
  transactionDetail
}) => {
  const [bankOptionList, setBankOptionList] = useState([])
  const bankTransferOptionList = [
    { bankAccountId: 3, label: 'PH BANK' },
    { bankAccountId: 1, label: 'SG BANK PHP' },
    { bankAccountId: 2, label: 'SG BANK USD' }
  ]
  const chequeOptionList = [{ bankAccountId: 3, label: 'PH BANK' }]
  useEffect(
    () => {
      if (
        (transactionDetail &&
          transactionDetail.offlineTransaction &&
          transactionDetail.offlineTransaction.methodId == 1) ||
        (transactionDetail &&
          transactionDetail.paymentMethodId &&
          transactionDetail.paymentMethodId == 1)
      ) {
        // if is new form, set default value of bankAccountId to 3
        if (!isDisabled) {
          customHandleChange('bankAccountId', 3)
        }
        setBankOptionList(bankTransferOptionList)
      } else {
        setBankOptionList(chequeOptionList)
      }
    },
    [
      transactionDetail && transactionDetail.offlineTransaction,
      transactionDetail && transactionDetail.paymentMethodId
    ]
  )

  useEffect(
    () => {
      const amountAfterTax = +(
        transactionDetail.amount *
        (1 + transactionDetail.taxRate / 100)
      ).toFixed(4)
      customHandleChange('totalAmount', amountAfterTax)
    },
    [transactionDetail.taxRate, transactionDetail.taxType]
  )

  useEffect(
    () => {
      const amountAfterExchangeRate = +(
        transactionDetail.totalAmount * transactionDetail.currencyExchangeRate
      ).toFixed(4)
      customHandleChange('currencyAmount', amountAfterExchangeRate)
    },
    [transactionDetail.currencyExchangeRate]
  )

  const _renderPaymentMethodField = () => {
    return (
      <FormGroup>
        <Label>Payment Method</Label>
        <Input
          type="select"
          name="paymentMethodId"
          value={
            transactionDetail &&
            transactionDetail.offlineTransaction &&
            transactionDetail.offlineTransaction.methodId
              ? transactionDetail.offlineTransaction.methodId
              : transactionDetail && transactionDetail.paymentMethodId
                ? transactionDetail.paymentMethodId
                : null
          }
          onChange={handleChange}
        >
          <option value={1}>Bank Transfer</option>
          <option value={2}>Cheque</option>
        </Input>
      </FormGroup>
    )
  }
  const _renderPaymentInfoField = () => {
    return (
      <FormGroup>
        <Label>Payment Info</Label>
        <Input
          type="select"
          name="bankAccountId"
          value={
            transactionDetail && transactionDetail.bankAccountId
              ? transactionDetail.bankAccountId
              : null
          }
          onChange={handleChange}
        >
          {bankOptionList.map(option => (
            <option key={option.label} value={option.bankAccountId}>
              {option.label}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  }

  const _renderPaymentTermsField = () => {
    return (
      <FormGroup>
        <Label>Payment Terms </Label>
        <Input
          type="text"
          name="creditTerm"
          value={
            transactionDetail && transactionDetail.creditTerm
              ? transactionDetail.creditTerm
              : null
          }
          onChange={handleChange}
        />
      </FormGroup>
    )
  }

  const _renderTaxTypeField = () => {
    return (
      <FormGroup>
        <Label>Tax Type</Label>
        <Input
          type="select"
          name="taxType"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.taxType
              ? transactionDetail.taxType
              : ''
          }
        >
          <option value="gst">GST</option>
          <option value="vat">VAT</option>
        </Input>
      </FormGroup>
    )
  }

  const _renderTaxRateField = () => {
    return (
      <FormGroup>
        <Label>Tax Rate (%)</Label>
        <Input
          type="number"
          name="taxRate"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.taxRate
              ? transactionDetail.taxRate
              : ''
          }
        />
      </FormGroup>
    )
  }

  const _renderAmountAfterTaxField = () => {
    return (
      <FormGroup>
        <Label>Amount after tax (PHP)</Label>
        <Input
          type="number"
          name="vat"
          value={
            transactionDetail && transactionDetail.totalAmount
              ? transactionDetail.totalAmount
              : null
          }
          onChange={handleChange}
          disabled={true}
        />
      </FormGroup>
    )
  }

  const _renderCurrencyField = () => {
    return (
      <FormGroup>
        <Label>Currency</Label>
        <Input
          type="select"
          name="currency"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.currency
              ? transactionDetail.currency
              : null
          }
        >
          <option value="php">PHP</option>
          <option value="sgd">SGD</option>
          <option value="usd">USD</option>
        </Input>
      </FormGroup>
    )
  }
  const _renderExchangeRateField = () => {
    // if transactionDetail.currency is PHP or default, set default exchange rate to 1
    return (
      <FormGroup>
        <Label>Exchange Rate</Label>
        <Input
          type="number"
          name="currencyExchangeRate"
          onChange={handleChange}
          value={
            transactionDetail &&
            transactionDetail.currencyExchangeRate &&
            transactionDetail.currencyExchangeRate
          }
        />
      </FormGroup>
    )
  }

  const _renderAmountAfterExchangeRateField = () => {
    return (
      <FormGroup>
        <Label>
          Total Amount{' '}
          {transactionDetail && transactionDetail.currency
            ? `(${transactionDetail.currency.toUpperCase()})`
            : null}{' '}
        </Label>
        <Input
          type="number"
          name="currencyAmount"
          value={
            transactionDetail && transactionDetail.currencyAmount
              ? transactionDetail.currencyAmount
              : null
          }
          onChange={handleChange}
          disabled={true}
        />
      </FormGroup>
    )
  }

  return (
    <SectionWrapper sectionTitle="Additional Payment Details">
      <Form>
        {transactionDetail.type !== 'Online' && (
          <Row>
            <Col md={6}>{_renderPaymentMethodField()}</Col>
            <Col md={6}>{_renderPaymentInfoField()}</Col>
          </Row>
        )}
        <Row>
          <Col md={6}>{_renderPaymentTermsField()}</Col>
          <Col md={6}>{_renderTaxTypeField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderTaxRateField()}</Col>
          <Col md={6}>{_renderAmountAfterTaxField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderCurrencyField()}</Col>
          <Col md={6}>{_renderExchangeRateField()}</Col>
        </Row>
        <Row>
          <Col md={6}>{_renderAmountAfterExchangeRateField()}</Col>
        </Row>
      </Form>
    </SectionWrapper>
  )
}

PaymentDetailForm.propTypes = {
  transactionDetail: PropTypes.object,
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func.isRequired,
  customHandleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func
}

export default PaymentDetailForm
