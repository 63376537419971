import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

const MultiSelectDropdown = ({ field, options, onSelect, ids }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(ids)
  console.log(selectedOptions)

  const toggleDropdown = event => {
    if (
      ['Apply', 'Clear Selection', 'Exit Selection'].includes(
        event.target.innerText
      )
    ) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const handleOptionClick = option => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option))
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const handleApplyClick = () => {
    onSelect(selectedOptions, field)
  }

  const handleClearSelection = () => {
    setSelectedOptions([])
    onSelect([], field)
  }

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
      <DropdownToggle caret>
        {selectedOptions.length > 0
          ? `${selectedOptions.length} selected`
          : 'Select options'}
      </DropdownToggle>
      <DropdownMenu>
        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {options &&
            options.map(option => (
              <DropdownItem
                key={`${option.key}_${option.id}`}
                active={selectedOptions.includes(option.id)}
                onClick={() => handleOptionClick(option.id)}
              >
                {option.value || option.name}
              </DropdownItem>
            ))}
        </div>
        <DropdownItem divider />
        <DropdownItem>
          <div
            className="btn btn-primary"
            value="apply"
            onClick={handleApplyClick}
          >
            Apply
          </div>
        </DropdownItem>
        <DropdownItem>
          <div
            className="btn btn-danger"
            value="clear"
            onClick={handleClearSelection}
          >
            Clear Selection
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="btn btn-dark" value="exit" onClick={toggleDropdown}>
            Exit Selection
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

MultiSelectDropdown.propTypes = {
  field: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number)
}

MultiSelectDropdown.defaultProps = {
  ids: []
}

export default MultiSelectDropdown
