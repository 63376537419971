import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Vendors */
import FontAwesome from 'react-fontawesome'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

const BlacklistedUserList = ({ userList, handleDelete }) => (
  <Can I="edit" a="blacklist" passThrough>
    {can => (
      <Table bordered>
        <thead>
          <tr>
            <th />
            <th>User ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Created</th>
            {can && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {userList.map(user => (
            <tr key={`blacklisted-${user.id}`}>
              <td />
              <td>
                <a href={`/bossjob/user/${user.blacklistedUser.id}`}>
                  {user.blacklistedUser.id}
                </a>
              </td>
              <td>{user.blacklistedUser.firstName}</td>
              <td>{user.blacklistedUser.lastName}</td>
              <td>{user.createdAt}</td>
              {can && (
                <td>
                  <Button color="danger" onClick={() => handleDelete(user.id)}>
                    <FontAwesome name="trash" />
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </Can>
)

BlacklistedUserList.propTypes = {
  userList: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired
}

export default BlacklistedUserList
