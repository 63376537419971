import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Routing from './Routing'

import withSession from './withSession'

/* Views */
import Login from 'modules/authentication/views/Login'

/* === Admin Users === */
import MyAccount from 'modules/users/views/MyAccount'
import Users from 'modules/users/views/Users'
import AddUser from 'modules/users/views/AddUser'
import UserDetail from 'modules/users/views/UserDetail'

/* === Roles === */
import Roles from 'modules/roles/views/Roles'
import AddRole from 'modules/roles/views/AddRole'
import RolePermission from 'modules/roles/views/RolePermission'

/* === BossJob Users === */
import BossJobUsers from 'modules/bossjob_users/views/BossJobUsers'
import BossJobUserDetail from 'modules/bossjob_users/views/BossJobUserDetail'

/* === Jobs === */
import Jobs from 'modules/jobs/views/Jobs'
import JobDetail from 'modules/jobs/views/JobDetail'
import PostJob from 'modules/jobs/views/PostJob'

/* === Companies === */
import Companies from 'modules/companies/views/Companies'
import CompanyDetail from 'modules/companies/views/CompanyDetail'

/* === Neptune companies === */
import NeptuneCompanies from 'modules/neptuneCompanies/views/NeptuneCompanies'
import NeptuneCompany from 'modules/neptuneCompanies/views/NeptuneCompany'

/* === Marketing === */
import TopCompanies from 'modules/marketing/views/TopCompanies'
import LandingPageBanner from 'modules/marketing/views/LandingPageBanner'
import LeftSideBanner from 'modules/marketing/views/LeftSideBanner'
import RightSideBanner from 'modules/marketing/views/RightSideBanner'
import ProductFeatureAnnouncement from 'modules/marketing/views/ProductFeatureAnnouncement'

/* === Chat === */
import Chat from 'modules/chat/views/Chat'
import ChatDetail from 'modules/chat/views/ChatDetail'

/* === Invoice === */
import Transaction from 'modules/transactions/views/Transaction'
import TransactionDetail from 'modules/transactions/views/TransactionDetail'

/* === Subscription Plans ===  */
import SubscriptionPlans from 'modules/subscription_plans/views/SubscriptionPlans'
import SubscriptionPlanDetail from 'modules/subscription_plans/views/SubscriptionPlanDetail'

/* === Subscriptions === */
import Subscription from '../../../modules/subscriptions/views/Subscription'
import SubscriptionDetail from 'modules/subscriptions/views/SubscriptionDetail'

/* === Tools === */
import BulkRegistrationDocument from 'modules/tools/views/BulkRegistrationDocument'
import JoinCompanyData from '../../../modules/tools/views/JoinCompanyData'

/* === BossHunt Users === */
import BossHuntUsers from 'modules/bosshunt_users/views/BossHuntUsers'
import BossHuntUserDetail from 'modules/bosshunt_users/views/BossHuntUserDetail'
// import AccountSetting from 'modules/bosshunt_users/views/BossHuntUserDetail/AccountSetting'

/* === BossHunt Agencies === */
import Agencies from 'modules/agencies/views/Agencies'
import AgencyDetail from 'modules/agencies/views/AgencyDetail'
import MyResumeDetail from '../../../modules/bosshunt_users/views/BossHuntUserDetail/MyResume/MyResumeDetail'
// import AgencySettings from '../../../modules/agencies/views/AgencyDetail/AgencySettings'
// import AgencySettings from 'modules/agencies/views/AgencyDetail/AgencySettings'
import RequestDemo from 'modules/bosshunt_request/views/RequestDemo'

import EventsList from 'modules/events/views/BossHuntUsers'
// Events Statistics
import EventStatistics from 'modules/events/views/EventStatistics'

// Maintenance
import MaintenanceList from 'modules/maintenance/views/List'
import MaintenanceAdd from 'modules/maintenance/views/Add'
import MaintenanceEdit from 'modules/maintenance/views/Edit'

import RedirectPage from '../../../modules/redirectPage'

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />

        {/* <Routing exact path="/" component={withSession(Users)} /> */}
        <Route exact path="/" component={RedirectPage} />

        {/* <-------------------------- Admin Users -------------------------- > */}
        <Routing
          exact
          path="/user/myaccount"
          component={withSession(MyAccount)}
        />
        <Route
          exact
          path="/users"
          component={() => <Redirect to="/users/1" />}
        />
        <Routing exact path="/users/:page" component={withSession(Users)} />
        <Routing exact path="/user/add" component={withSession(AddUser)} />
        <Routing exact path="/user/:id" component={withSession(UserDetail)} />

        {/* Roles */}
        <Routing exact path="/roles" component={withSession(Roles)} />
        <Routing exact path="/roles/add" component={withSession(AddRole)} />
        <Routing
          exact
          path="/role/:id"
          component={withSession(RolePermission)}
        />

        {/* <-------------------------- BossJob Users -------------------------- > */}
        <Route
          exact
          path="/bossjob/users"
          component={() => <Redirect to="/bossjob/users/1" />}
        />
        <Routing
          exact
          path="/bossjob/users/:page"
          component={withSession(BossJobUsers)}
        />
        <Routing
          path="/bossjob/user/:id"
          component={withSession(BossJobUserDetail)}
        />

        {/* Jobs */}
        <Route
          exact
          path="/bossjob/jobs"
          component={() => <Redirect to="/bossjob/jobs/1" />}
        />
        <Route
          exact
          path="/bossjob/job"
          component={() => <Redirect to="/bossjob/job/1" />}
        />
        <Routing path="/bossjob/jobs/:page" component={withSession(Jobs)} />
        <Routing
          exact
          path="/bossjob/recruiter/:recruiterId/job/new"
          component={withSession(PostJob)}
        />
        <Routing
          path="/bossjob/job/:jobId"
          component={withSession(JobDetail)}
        />

        {/* Companies */}
        <Route
          exact
          path="/bossjob/companies"
          component={() => <Redirect to="/bossjob/companies/1" />}
        />
        <Routing
          path="/bossjob/companies/:page"
          component={withSession(Companies)}
        />
        <Routing
          path="/bossjob/company/:id"
          component={withSession(CompanyDetail)}
        />

        {/* NeptuneCompanies */}
        <Route
          exact
          path="/bossjob/NeptuneCompanies"
          component={() => <Redirect to="/bossjob/NeptuneCompanies/1" />}
        />
        <Routing
          path="/bossjob/NeptuneCompanies/:page"
          component={withSession(NeptuneCompanies)}
        />
        <Routing
          path="/bossjob/NeptuneCompany/:id"
          component={withSession(NeptuneCompany)}
        />

        {/* Marketing */}
        <Route
          exact
          path="/bossjob/featured-company/list"
          component={withSession(TopCompanies)}
        />
        <Route
          exact
          path="/bossjob/landing-page-banner"
          component={withSession(LandingPageBanner)}
        />
        <Route
          exact
          path="/bossjob/left-side-banner"
          component={withSession(LeftSideBanner)}
        />

        <Route
          exact
          path="/bossjob/right-side-banner"
          component={withSession(RightSideBanner)}
        />

        <Route
          exact
          path="/bossjob/new-product-feature-announcement"
          component={withSession(ProductFeatureAnnouncement)}
        />

        {/* Chat */}
        <Route
          exact
          path="/bossjob/chat"
          component={() => <Redirect to="/bossjob/chat/1" />}
        />
        <Routing path="/bossjob/chat/:page" component={withSession(Chat)} />
        <Routing
          path="/bossjob/chat-detail/:dialogueId/:page"
          component={withSession(ChatDetail)}
        />

        {/* Invoice
        // <Route
        //   exact
        //   path="/bossjob/invoices"
        //   component={() => <Redirect to="/bossjob/invoices/1" />}
        // />
        // <Routing
        //   path="/bossjob/invoices/:page"
        //   component={withSession(Invoice)}
        // />
        // <Routing
        //   path="/bossjob/invoice/detail/:invoiceId"
        //   component={withSession(InvoiceDetail)}
        // />
        // <Routing
        //   path="/bossjob/invoice/new/:userId"
        //   component={withSession(InvoiceDetail)}
        // /> */}
        {/* Transaction */}
        <Route
          exact
          path="/bossjob/transactions"
          component={() => <Redirect to="/bossjob/transactions/1" />}
        />
        <Routing
          path="/bossjob/transactions/:page"
          component={withSession(Transaction)}
        />
        <Routing
          path="/bossjob/transaction/detail/:transactionId"
          component={withSession(TransactionDetail)}
        />
        <Routing
          path="/bossjob/transaction/new/:userId"
          component={withSession(TransactionDetail)}
        />

        {/* Subscription plans */}
        <Route
          exact
          path="/bossjob/subscription-plans"
          component={() => <Redirect to="/bossjob/subscription-plans/1" />}
        />
        <Routing
          path="/bossjob/subscription-plans/:page"
          component={withSession(SubscriptionPlans)}
        />
        <Routing
          path="/bossjob/subscription-plan/detail/:subscriptionPlanId"
          component={withSession(SubscriptionPlanDetail)}
        />
        <Routing
          path="/bossjob/subscription-plan/new"
          component={withSession(SubscriptionPlanDetail)}
        />

        {/* Subscriptions */}
        <Route
          exact
          path="/bossjob/subscriptions"
          component={() => <Redirect to="/bossjob/subscriptions/1" />}
        />
        <Routing
          path="/bossjob/subscriptions/:page"
          component={withSession(Subscription)}
        />
        <Routing
          path="/bossjob/subscription/detail/:subscriptionId"
          component={withSession(SubscriptionDetail)}
        />

        <Routing
          path="/bossjob/subscription/new/:userId"
          component={withSession(SubscriptionDetail)}
        />

        {/* Tools */}
        <Route
          exact
          path="/bossjob/bulk-registration-document"
          component={withSession(BulkRegistrationDocument)}
        />

        <Route
          exact
          path="/bossjob/join-company-data"
          component={() => <Redirect to="/bossjob/join-company-data/1" />}
        />

        <Routing
          path="/bossjob/join-company-data/:page"
          component={withSession(JoinCompanyData)}
        />

        {/* Events */}
        <Route
          exact
          path="/event-statistics"
          component={withSession(EventStatistics)}
        />

        {/* <-------------------------- Bosshunt users -------------------------- > */}
        <Route
          exact
          path="/bosshunt/users"
          component={() => <Redirect to="/bosshunt/users/1" />}
        />

        <Routing
          exact
          path="/bosshunt/users/:page"
          component={withSession(BossHuntUsers)}
        />
        <Routing
          path="/bosshunt/user/:id"
          component={withSession(BossHuntUserDetail)}
        />

        {/* My resume */}
        <Routing
          path="/bosshunt/myresume/detail/:id"
          component={withSession(MyResumeDetail)}
        />

        {/* <-------------------------- Bosshunt agencies -------------------------- > */}
        <Route
          exact
          path="/bosshunt/agencies"
          component={() => <Redirect to="/bosshunt/agencies/1" />}
        />

        <Routing
          exact
          path="/bosshunt/agencies/:page"
          component={withSession(Agencies)}
        />

        <Routing
          path="/bosshunt/agency/:id"
          component={withSession(AgencyDetail)}
        />

        <Route
          exact
          path="/bosshunt/request"
          component={() => <Redirect to="/bosshunt/request/1" />}
        />

        <Routing
          exact
          path="/bosshunt/request/:page"
          component={withSession(RequestDemo)}
        />

        <Routing
          exact
          path="/events/list/:page"
          component={withSession(EventsList)}
        />
        <Routing
          exact
          path="/events/list/:id"
          component={withSession(RequestDemo)}
        />

        <Route
          exact
          path="/maintenance/list"
          component={() => <Redirect to="/maintenance/list/1" />}
        />
        <Routing
          exact
          path="/maintenance/list/add"
          component={withSession(MaintenanceAdd)}
        />
        <Routing
          exact
          path="/maintenance/list/:page"
          component={withSession(MaintenanceList)}
        />
        <Routing
          exact
          path="/maintenance/edit/:id"
          component={withSession(MaintenanceEdit)}
        />
      </Switch>
    )
  }
}

export default Routes
