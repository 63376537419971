import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Header from '../Header'
import Sidebar from '../Sidebar'

/**
 *
 * Layout - wrapper component to wrap the views component and show header and sidebar
 */
class Layout extends Component {
  static propTypes = {
    /* Component children */
    children: PropTypes.object
  }
  render() {
    const { children } = this.props
    return (
      // <div>
      <div style={{ position: 'relative' }}>
        <Header />
        <Sidebar />
        <div
          style={{ paddingTop: '1em', paddingLeft: '230px', textAlign: 'left' }}
        >
          {children}
        </div>
      </div>
    )
  }
}

export default Layout
