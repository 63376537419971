import React from 'react'

import { Query } from 'react-apollo'
import {
  GET_EVENT_ACTIVITY_DATA,
  GET_EVENT_PARTICIPANT_STATISTIC
} from '../../schemas/queries'

import Loading from 'shared/components/Loading'
import SectionWrapper from '../../../../shared/components/SectionWrapper'
import EventActivityDataList from './EventActivityDataList'
import EventParticipantList from './EventParticipantList'

const EventStatistics = () => {
  return (
    <div>
      <SectionWrapper sectionTitle="Event Statistics" isMainPage>
        <Query query={GET_EVENT_ACTIVITY_DATA}>
          {({ loading, data }) => {
            if (loading) {
              return <Loading />
            } else if (data) {
              return <EventActivityDataList data={data} />
            }
          }}
        </Query>
        <Query query={GET_EVENT_PARTICIPANT_STATISTIC}>
          {({ loading, data }) => {
            if (loading) {
              return <Loading />
            } else if (data) {
              return (
                <EventParticipantList
                  data={data.eventParticipantStatistic.data}
                />
              )
            }
          }}
        </Query>
      </SectionWrapper>
    </div>
  )
}

export default EventStatistics
