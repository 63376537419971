import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap'

/**
 * CompanyBenefits -
 *
 * @returns {Component}
 */
const CompanyBenefits = ({
  config,
  benefits,
  handleChange,
  saveCompany,
  apolloClient,
  canEdit,
  isLoading
}) => {
  const companyBenefitLists = config.company_benefit_lists
  const benefitCategory = [
    ...new Set(companyBenefitLists.map(benefit => benefit.category))
  ]

  const renderCheckboxField = benefit => {
    return (
      <FormGroup
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        {companyBenefitLists.map(({ id, value, category }) => {
          if (benefit == category) {
            return (
              <div key={id} style={{ display: 'flex', marginRight: '50px' }}>
                <Label>
                  <Input
                    type="checkbox"
                    name="companyBenefit"
                    value={id}
                    checked={
                      benefits &&
                      benefits.filter(
                        benefit => benefit.benefitId === Number(id)
                      ).length > 0
                        ? true
                        : false
                    }
                    onChange={handleChange}
                  />
                  {value}
                </Label>
              </div>
            )
          }
        })}
      </FormGroup>
    )
  }

  return (
    <Form style={{ display: 'flex', flexDirection: 'column' }}>
      {benefitCategory.map((benefit, index) => {
        return (
          <Row key={index}>
            <Col md="2" style={{ textTransform: 'capitalize' }}>
              {benefit.replace(/_/g, ' ')}
            </Col>
            <Col md="10">{renderCheckboxField(benefit)}</Col>
          </Row>
        )
      })}
      <Button
        style={{ display: 'flex', alignSelf: 'flex-end' }}
        color="info"
        disabled={isLoading || !canEdit}
        onClick={() => saveCompany(apolloClient)}
      >
        Save
      </Button>
    </Form>
  )
}

CompanyBenefits.propTypes = {
  config: PropTypes.object.isRequired,
  benefits: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  apolloClient: PropTypes.any,
  isLoading: PropTypes.bool,
  saveCompany: PropTypes.func.isRequired,
  canEdit: PropTypes.bool
}

export default CompanyBenefits
