import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'
import ExportJoinCompanyButton from '../../../containers/ExportJoinCompanyDataButton/ExportJoinCompanyButton'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

const JoinCompanyDataList = ({
  joinRequestList,
  currentPage,
  totalRequests,
  totalPages,
  exportPayload,
  fromDate,
  toDate
}) => {
  console.log(toDate)
  return (
    <>
      <div style={{ float: 'left', display: 'flex' }}>
        <div style={{ marginLeft: '10px' }}>
          <h5>
            Period: {fromDate.replace(/T|Z/g, ' ')} to{' '}
            {toDate.replace(/T|Z/g, ' ')}
          </h5>
        </div>
      </div>
      <div style={{ float: 'right', display: 'flex' }}>
        <div style={{ marginRight: '10px', marginBottom: '5px' }}>
          <ExportJoinCompanyButton exportPayload={exportPayload} />
        </div>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th />
            <th>Id</th>
            <th>User Id</th>
            <th>User Email</th>
            <th>Request Type</th>
            <th>Company Legal Name</th>
            <th>Status</th>
            <th>Created Time</th>
          </tr>
        </thead>
        <tbody>
          {joinRequestList.map(joinRequest => (
            <tr key={joinRequest.id}>
              <td />
              <td>{joinRequest.id}</td>
              <td>
                <a href={`/bossjob/user/${joinRequest.userId}`}>
                  {joinRequest.userId}
                </a>
              </td>
              <td>{joinRequest.userEmail}</td>
              <td>{joinRequest.requestType}</td>
              <td>
                <a href={`/bossjob/company/${joinRequest.companyId}`}>
                  {joinRequest.companyLegalName}
                </a>
              </td>
              <td>{joinRequest.status}</td>
              <td>{formatDate(joinRequest.createdTime)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalRequests}
        totalPages={totalPages}
        pageRange={4}
      />
    </>
  )
}

JoinCompanyDataList.propTypes = {
  joinRequestList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalRequests: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired
}

export default JoinCompanyDataList
