import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Bootstrap */
import { Col, Card, CardBody } from 'reactstrap'

/* Assets */
import styles from './Navigation.module.scss'

/**
 * Navigation - navigation component to show menu of Bossjob user detail
 *
 * @returns {Component}
 *
 */
const Navigation = ({ match, location }) => {
  return (
    <div className={styles.navigation}>
      <Col md="12">
        <Card>
          <CardBody>
            <div className={styles.menuList}>
              <div>
                <a
                  href={`/bossjob/user/${match.params.id}/account`}
                  className={
                    location.pathname.includes('account')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Account Setting
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/user/${match.params.id}/profile`}
                  className={
                    location.pathname.includes('profile')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Resume
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/user/${match.params.id}/recruiter`}
                  className={
                    location.pathname.includes('recruiter')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Recruiter Information
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/user/${match.params.id}/bosspoints/1`}
                  className={
                    location.pathname.includes('bosspoints')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Bosspoints
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/user/${match.params.id}/otp-email-logs/1`}
                  className={
                    location.pathname.includes('otp-email-logs')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  OTP Email Logs
                </a>
              </div>
              {/* <div>
                <a
                  href={`/bossjob/user/${match.params.id}/blacklist`}
                  className={
                    location.pathname.includes('blacklist')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Blacklist
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/user/${match.params.id}/chat/1`}
                  className={
                    location.pathname.includes('chat')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Chats
                </a>
              </div> */}
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}

Navigation.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,

  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(Navigation)
