import React from 'react'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import BlacklistedUserListContainer from '../../../containers/BossJobUserDetailContainer/BlacklistedUserListContainer'

const Blacklists = () => (
  <SectionWrapper sectionTitle="Blacklisted Users">
    <BlacklistedUserListContainer />
  </SectionWrapper>
)

export default Blacklists
