import React from 'react'

/* Components */
// import SectionWrapper from 'shared/components/SectionWrapper'
// import Loading from 'shared/components/Loading'
// import FeaturedCompanyContainer from './FeaturedCompanyContainer'
// import Navigation from '../Navigation'
// import { Query } from 'react-apollo'
// import { GET_FEATURED_COMPANIES } from '../../schemas/queries'
import FeaturedCountryFilterContainer from './FeaturedCountryFilterContainer'

/**
 * Companies - main Companies list view
 *
 */
const FeaturedCompany = () => (
  <div>
    {/* <Navigation />
    <SectionWrapper sectionTitle="Top Companies" isMainPage>
      <p style={{ color: 'red' }}>
        Please add companies in multiple of 6 or 8(for better looking in front
        end)!
      </p>
      <Query query={GET_FEATURED_COMPANIES}>
        {({ data: { featureCompanies }, loading, error }) => {
          if (loading) {
            return <Loading />
          } else if (error) {
            return <div>Something went wrong.</div>
          }

          const featuredCompanies = featureCompanies.data.map(obj => {
            return {
              id: obj.company.id,
              value: obj.company.name
            }
          })

          if (featureCompanies) {
            return (
              <FeaturedCompanyContainer featuredCompanies={featuredCompanies} />
            )
          }
          return null
        }}
      </Query>
    </SectionWrapper> */}
    <FeaturedCountryFilterContainer />
  </div>
)

export default FeaturedCompany
