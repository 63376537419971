import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Component */
import PopUpMessage from 'shared/components/PopUpMessage'

class ImageField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageError: false
    }

    this.closeModal = this.closeModal.bind(this)
  }

  isFileValid(file) {
    return file.size <= 5242880
  }

  closeModal() {
    this.setState({
      imageError: false
    })
  }

  render() {
    const {
      hasPermission,
      name,
      imageSrc,
      handleChange,
      style,
      edit
    } = this.props
    const companyPicture = name.includes('companyPicture')
    return (
      <React.Fragment>
        <label
          htmlFor={name}
          style={{
            cursor:
              (edit && companyPicture && hasPermission) ||
              (!edit && !companyPicture && hasPermission)
                ? 'pointer'
                : 'default'
          }}
        >
          <img
            src={imageSrc}
            width={name === 'companyCover' || name === 'agencyCover' ? 400 : 90}
            height={
              name === 'companyCover' || name === 'agencyCover' ? 79.07 : 90
            }
            style={style}
          />

          {(edit && companyPicture && hasPermission) ||
          (!edit && !companyPicture && hasPermission) ? (
              <input
                type="file"
                required
                name={name}
                id={name}
                onChange={e => {
                  const file = e.target.files[0]
                  if (e.target.validity.valid) {
                    if (this.isFileValid(file)) {
                      handleChange(e)
                    }
                  } else {
                    e.target.value = null
                    this.setState({
                      imageError: true
                    })
                  }
                }}
                style={{ display: 'none' }}
              />
            ) : null}
        </label>
        <PopUpMessage
          isVisible={this.state.imageError}
          message="Your image size is more than 5MB. Please select a new file."
          closeModal={this.closeModal}
          error
        />
      </React.Fragment>
    )
  }
}

ImageField.propTypes = {
  hasPermission: PropTypes.bool.isRequired,

  /** input field name */
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  edit: PropTypes.bool
}

export default ImageField
