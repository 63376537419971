import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ALL_JOBS } from '../../schemas/queries'

/* Components */
import JobsList from '../../views/Jobs/JobsList'
import Loading from 'shared/components/Loading'

/**
 * JobsListContainer - Apollo Component to fetch the jobs
 *
 * @returns {Component}
 */
class JobsListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    appendSelectedJob: PropTypes.func.isRequired,
    removeSelectedJob: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const {
      search,
      fromDate,
      toDate,
      status,
      supportedCountryId,
      workArrangementIds
    } = queryString.parse(location.search)

    let variables = {
      page: this.state.page,
      query: search ? search : null,
      date:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null,
      statusKey: status,
      countryId: supportedCountryId ? supportedCountryId : null,
      workArrangementIds: workArrangementIds ? workArrangementIds : null
    }

    return (
      <Query query={GET_ALL_JOBS} variables={variables}>
        {({ loading, data }) => {
          const jobsList = data
          if (loading) {
            return <Loading />
          }

          if (jobsList) {
            return (
              <JobsList
                jobsList={jobsList.allJobs.data.jobs}
                currentPage={this.state.page}
                totalJobs={jobsList.allJobs.data.totalCount}
                totalPages={jobsList.allJobs.data.totalPages}
                appendSelectedJob={this.props.appendSelectedJob}
                removeSelectedJob={this.props.removeSelectedJob}
              />
            )
          }

          return null
        }}
      </Query>
    )
  }
}

export default withRouter(JobsListContainer)
