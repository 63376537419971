import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'

/**
 * DetailForm - A part of the Transaction Detail form which
 * contains transaction details e.g: Reference Number, Amount,
 * Item Name, Description and Payment Type
 *
 * @returns {Component}
 */
class DetailForm extends Component {
  static propTypes = {
    transactionDetail: PropTypes.object,
    transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func
  }

  paymentMethods = [
    { Online: 'Online - Credit/Debit Card' },
    { Offline: 'Offline - Cheque/Bank Transfer' }
  ]

  /**
   *
   * @function _renderReferenceNumberField
   * @returns {Component}
   */
  _renderReferenceNumberField() {
    const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>Reference Number</Label>
        <Input
          type="text"
          name="id"
          onChange={handleChange}
          disabled={true}
          value={
            transactionDetail && transactionDetail.referenceNum
              ? transactionDetail.referenceNum
              : null
          }
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderPaypalReferenceNumberField
   * @returns {Component}
   */
  _renderGatewayReferenceNumberField() {
    const { handleChange, transactionDetail } = this.props
    const { onlineTransaction } = transactionDetail

    return onlineTransaction ? (
      <FormGroup>
        <Label>
          {transactionDetail.onlineTransaction
            ? transactionDetail.onlineTransaction.paymentMethod.toUpperCase()
            : null}{' '}
          Reference Number
        </Label>
        <Input
          type="text"
          name="id"
          onChange={handleChange}
          disabled={true}
          value={
            transactionDetail &&
            transactionDetail.onlineTransaction.paypalTransaction
              ? transactionDetail.onlineTransaction.paypalTransaction
                .paypalTransactionId
              : transactionDetail.onlineTransaction.braintreeTransaction
                .braintreeTransactionId
          }
        />
      </FormGroup>
    ) : null
  }

  /**
   *
   * @function _renderItemNameField
   * @returns {Component}
   */
  _renderItemNameField() {
    const {
      handleChange,
      transactionDetail,
      // transactionId,
      isDisabled
    } = this.props
    return (
      <FormGroup>
        <Label for="paymentKey">Item Name</Label>
        <Input
          type="text"
          name="itemName"
          onChange={handleChange}
          disabled={isDisabled}
          value={
            transactionDetail && transactionDetail.itemName
              ? transactionDetail.itemName
              : null
          }
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function renderDescriptionField
   * @returns {Component}
   */
  _renderDescriptionField() {
    const { transactionDetail, handleChange } = this.props
    return (
      <FormGroup>
        <Label>Description</Label>
        <Input
          type="textarea"
          name="itemDescription"
          value={
            transactionDetail && transactionDetail.itemDescription
              ? transactionDetail.itemDescription
              : null
          }
          onChange={handleChange}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderAmountField
   * @returns {Component}
   */
  _renderAmountField() {
    const { handleChange, transactionDetail, isDisabled } = this.props
    return (
      <FormGroup>
        <Label>Net Amount (PHP) </Label>
        <Input
          type="text"
          name="amount"
          value={
            transactionDetail && transactionDetail.amount
              ? transactionDetail.amount
              : null
          }
          onChange={handleChange}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobPostQuantityField
   * @returns {Component}
   */
  _renderJobPostQuantityField() {
    const { handleChange, transactionDetail, isDisabled } = this.props
    return (
      <FormGroup>
        <Label># of job posts</Label>
        <Input
          type="text"
          name="jobPost"
          value={
            transactionDetail && transactionDetail.jobPost
              ? transactionDetail.jobPost
              : null
          }
          onChange={handleChange}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderUnlockTalentCreditQuantityField
   * @returns {Component}
   */
  _renderUnlockTalentCreditQuantityField() {
    const { handleChange, transactionDetail, isDisabled } = this.props
    return (
      <FormGroup>
        <Label># of talent credits</Label>
        <Input
          type="text"
          name="unlockTalentCredit"
          value={
            transactionDetail && transactionDetail.unlockTalentCredit
              ? transactionDetail.unlockTalentCredit
              : null
          }
          onChange={handleChange}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderRhTalentCreditQuantityField
   * @returns {Component}
   */
  _renderRhTalentCreditQuantityField() {
    const { handleChange, transactionDetail, isDisabled } = this.props
    return (
      <FormGroup>
        <Label># of rh talent credits</Label>
        <Input
          type="text"
          name="rhTalentCredit"
          value={
            transactionDetail && transactionDetail.rhTalentCredit
              ? transactionDetail.rhTalentCredit
              : null
          }
          onChange={handleChange}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderFeatureJobCreditQuantityField
   * @returns {Component}
   */
  _renderFeatureJobCreditQuantityField() {
    const { handleChange, transactionDetail, isDisabled } = this.props
    return (
      <FormGroup>
        <Label># of feature job credits</Label>
        <Input
          type="text"
          name="featureJobCredit"
          value={
            transactionDetail && transactionDetail.featureJobCredit
              ? transactionDetail.featureJobCredit
              : null
          }
          onChange={handleChange}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderUrgentJobCreditQuantityField
   * @returns {Component}
   */
  _renderUrgentJobCreditQuantityField() {
    const { handleChange, transactionDetail, isDisabled } = this.props
    return (
      <FormGroup>
        <Label># of urgent job credits</Label>
        <Input
          type="text"
          name="urgentJobCredit"
          value={
            transactionDetail && transactionDetail.urgentJobCredit
              ? transactionDetail.urgentJobCredit
              : null
          }
          onChange={handleChange}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderBossPointQuantityField
   * @returns {Component}
   */
  _renderBossPointQuantityField() {
    const { handleChange, transactionDetail, isDisabled } = this.props
    return (
      <FormGroup>
        <Label># of BossPoints</Label>
        <Input
          type="text"
          name="bosspoint"
          value={
            transactionDetail && transactionDetail.bosspoint
              ? transactionDetail.bosspoint
              : null
          }
          onChange={handleChange}
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderPaymentMethodField
   *
   * typeId: 1: Online, 2: Offline
   * @returns {Component}
   */
  _renderPaymentMethodField() {
    const { handleChange, transactionDetail } = this.props

    return (
      <FormGroup>
        <Label>Payment Type</Label>
        <Input
          type="text"
          name="paymentMethod"
          value={
            transactionDetail && transactionDetail.type
              ? transactionDetail.type
              : null
          }
          onChange={handleChange}
          disabled={true}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderCallbackLogLink
   * @returns {Component}
   */
  _renderCallbackLogLink() {
    const { transactionDetail } = this.props
    const { callbackLog } =
      transactionDetail && transactionDetail.onlineTransaction
        ? transactionDetail.onlineTransaction
        : null || ''
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Label>Callback Log Link</Label>
        {callbackLog ? (
          <a href={callbackLog} target="_blank" rel="noopener noreferrer">
            {callbackLog}
          </a>
        ) : (
          '-'
        )}
      </div>
    )
  }

  render() {
    return (
      <SectionWrapper sectionTitle="Transaction Details">
        <Form>
          <Row>
            <Col md={6}>{this._renderReferenceNumberField()}</Col>
            <Col md={6}>{this._renderGatewayReferenceNumberField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{this._renderItemNameField()}</Col>
            <Col md={6}>{this._renderDescriptionField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{this._renderAmountField()}</Col>
            <Col md={6}>{this._renderPaymentMethodField()}</Col>
          </Row>
          <Row>
            <Col md={12}>{this._renderJobPostQuantityField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{this._renderUnlockTalentCreditQuantityField()}</Col>
            <Col md={6}>{this._renderRhTalentCreditQuantityField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{this._renderFeatureJobCreditQuantityField()}</Col>
            <Col md={6}>{this._renderUrgentJobCreditQuantityField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{this._renderBossPointQuantityField()}</Col>
          </Row>
          <Row>
            <Col md={12}>{this._renderCallbackLogLink()}</Col>
          </Row>
        </Form>
      </SectionWrapper>
    )
  }
}

export default DetailForm
