import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

const UserDetailForm = ({
  userDetail,
  roleOptions,
  handleChange,
  handleSave
}) => (
  <Form>
    <FormGroup>
      <Label>Name</Label>
      <Input
        name="name"
        value={userDetail.name}
        onChange={e => handleChange('name', e.target.value)}
      />
    </FormGroup>
    <FormGroup>
      <Label>Email</Label>
      <Input name="email" disabled value={userDetail.email} />
    </FormGroup>
    <FormGroup>
      <Label>Role</Label>
      <Input
        type="select"
        name="role"
        value={userDetail.role.id}
        onChange={e =>
          handleChange('role', { id: e.target.value, __typename: 'RolesType' })
        }
      >
        {roleOptions.map(role => (
          <option key={`role-${role.id}`} value={role.id}>
            {role.roleName}
          </option>
        ))}
      </Input>
    </FormGroup>
    <Can I="edit" a="admin_user">
      <div style={{ textAlign: 'right' }}>
        <Button color="info" onClick={() => handleSave()}>
          Save
        </Button>
      </div>
    </Can>
  </Form>
)

UserDetailForm.propTypes = {
  userDetail: PropTypes.object.isRequired,
  roleOptions: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
}

export default UserDetailForm
