import gql from 'graphql-tag'

/*====== Fragments =====*/
/* company detail */
const companyDetailFragment = gql`
  fragment CompanyDetail on CompaniesType {
    id
    coordinatorId
    name
    legalName
    logo
    industryKey
    companySizeKey
    isVerify
    isActive
    locationKey
    countryKey
    registeredCountryId
    address
    website
    phoneNum
    description
    descriptionHtml
    financingStageId
    salesPersonEmail
    cultures {
      cultureId
    }
    benefits {
      benefitId
    }
    coverPicUrl
    pictures {
      id
      name
      url
      sortOrder
    }
    facebookUrl
    instagramUrl
    linkedinUrl
    youtubeUrl
    linkedinCompanyId
    deletedAt
    deleteCompanyReason
    verificationMethod
    companyCountryNumber {
      id
      companyNumber
      statusKey
      changeRequiredReason
      rejectedReason
      activeReason
    }
  }
`

/* company review */
const companyReviewFragment = gql`
  fragment CompanyReviewDetail on CompanyReviewsType {
    id
    userId
    companyId
    goodContent
    challengeContent
  }
`

/*====== Local State ======*/
/* Bossjob company */
export const GET_LOCAL_COMPANY_DETAIL = gql`
  {
    companyDetail @client {
      ...CompanyDetail
    }
  }
  ${companyDetailFragment}
`

/*====== Query ======*/
/** Get all Bossjob companies */
export const GET_ALL_COMPANIES = gql`
  query getAllCompanies(
    $page: Int
    $query: String
    $date: CompaniesDateTimeRange
    $isCompanyPendingVerification: Boolean
    $authenticationStatus: String
    $activeStatus: String
    $countryId: Int
    $salesPersonEmail: String
    $registeredCountryId: Int
    $salesStatus: String
  ) {
    allCompanies(
      size: 15
      page: $page
      queryData: $query
      date: $date
      isCompanyPendingVerification: $isCompanyPendingVerification
      authenticationStatus: $authenticationStatus
      activeStatus: $activeStatus
      countryId: $countryId
      salesPersonEmail: $salesPersonEmail
      registeredCountryId: $registeredCountryId
      salesStatus: $salesStatus
    ) {
      data {
        companies {
          id
          name
          legalName
          recruiterAmount
          isVerify
          createdAt
          publishedAt
          deletedAt
          deleteCompanyReason
          authenticationStatus
          salesPersonEmail
          registeredCountryId
          verificationMethod
          verificationInfo
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

/** Get Bossjob company by company ID */
export const GET_COMPANY_BY_ID = gql`
  query getCompanyByID($companyId: Int!) {
    getCompany(companyId: $companyId) {
      data {
        ...CompanyDetail
      }
    }
  }
  ${companyDetailFragment}
`

/** Get Bossjob company by company ID */
export const GET_COMPANY_VERIFICATION_METHOD = gql`
  query getCompanyVerificationMethod($companyId: Int!) {
    getCompany(companyId: $companyId) {
      data {
        id
        verificationInfo
        corppassInfo
      }
    }
  }
`

/** Get Bossjob company document by company ID */
export const GET_COMPANY_DOCUMENT = gql`
  query getCompanyByID($companyId: Int!) {
    getCompany(companyId: $companyId) {
      data {
        document {
          id
          url
          statusKey
        }
      }
    }
  }
`

/** Get Bossjob company document by admin by company ID */
export const GET_COMPANY_DOCUMENT_BY_ADMIN = gql`
  query getCompanyID($companyId: Int!) {
    getCompanyDocumentByAdmin(companyId: $companyId) {
      ok
      message
      data
    }
  }
`

/**Get all company documents by company ID (including rejected) */
export const GET_COMPANY_DOCUMENTS = gql`
  query getCompanyDocuments($companyId: Int!) {
    getCompanyDocuments(companyId: $companyId) {
      data {
        id
        recruiterId
        rejectedReason
        changeRequiredReason
        createdAt
        updatedAt
        statusKey
        activeReason
      }
    }
  }
`

/** Get Bossjob recruiter document by company ID */
export const GET_RECRUITER_DOCUMENT = gql`
  query getRecruiterDocument($companyId: Int!, $recruiterId: Int!) {
    getRecruiterDocument(companyId: $companyId, recruiterId: $recruiterId) {
      ok
      message
      data {
        id
        companyId
        recruiterId
        url
        statusKey
        description
        changeRequiredReason
        rejectedReason
        createdAt
      }
    }
  }
`
/** Get Bossjob company document by company ID */
export const GET_COMPANY_WORKING_ADDRESS_LIST = gql`
  query getCompanyWorkingAddresses($companyId: Int!) {
    getCompanyWorkingAddresses(companyId: $companyId) {
      data {
        id
        companyId
        floorUnit
        address
        longitude
        latitude
        isDefault
      }
    }
  }
`

/** Get recruiter list by company ID  */
export const GET_RECRUITER_LIST_BY_COMPANY_ID = gql`
  query getRecruiterListByCompany($companyId: Int!, $page: Int) {
    getCompanyRecruiters(size: 10, companyId: $companyId, page: $page) {
      data {
        recruiters {
          userId
          workingPeriodFrom
          user {
            firstName
            lastName
            email
          }
          statusKey
          roleKey
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

/** Get recruiter list by company ID  */
export const GET_RECRUITER_IDS_BY_COMPANY_ID = gql`
  query getRecruiterListByCompany($companyId: Int!, $page: Int) {
    getCompanyRecruiters(size: 100, companyId: $companyId, page: $page) {
      data {
        recruiters {
          userId
          user {
            firstName
            lastName
            email
          }
          roleKey
        }
      }
    }
  }
`

/** Get recruiter list by company ID  */
export const GET_PENDING_REVIEW_RECRUITER_LIST_BY_COMPANY_ID = gql`
  query getPendingReviewRecruiterListByCompany($companyId: Int!, $page: Int) {
    getCompanyPendingReviewRecruiters(
      size: 10
      companyId: $companyId
      page: $page
    ) {
      data {
        recruiters {
          userId
          workingPeriodFrom
          user {
            firstName
            lastName
            email
          }
          joinCompanyMethod
          statusKey
          roleKey
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

/** Get recruiter list by company ID  */
export const GET_COMPANY_COORDINATOR = gql`
  query getCompanyCoordinator($companyId: Int!) {
    getCompanyCoordinator(companyId: $companyId) {
      ok
      message
      data {
        id
        userId
        companyId
        jobTitle
        statusKey
        roleKey
        role
        user {
          email
          firstName
          lastName
          recruiterOnboardingContactCountryId
          recruiterOnboardingContactNumber
        }
      }
    }
  }
`

/** Get company reviews by companyID  */
export const GET_COMPANY_REVIEWS_BY_COMPANY_ID = gql`
  query getCompanyReviewsById($companyId: Int!, $page: Int) {
    companyReviews(size: 15, companyId: $companyId, page: $page) {
      data {
        reviews {
          ...CompanyReviewDetail
          createdAt
          flags {
            id
          }
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
  ${companyReviewFragment}
`

/** Get company reviews detail */
export const GET_COMPANY_REVIEW_DETAIL_BY_ID = gql`
  query getCompanyReviewDetail($id: Int!) {
    companyReview(reviewId: $id) {
      data {
        ...CompanyReviewDetail
        firstName
        lastName
        workLifeBalRate
        managementRate
        benefitsRate
        workingXpRate
        overallExpRate
        recommend
        flags {
          id
          userId
          key
          value
          firstName
          lastName
          createdAt
        }
      }
    }
  }
  ${companyReviewFragment}
`
export const GET_COMPANY_SALES_PERSON_LOGS = gql`
  query getCompanySalesPersonLogs($page: Int, $size: Int, $companyId: Int!) {
    getCompanySalesPersonLogs(page: $page, size: $size, companyId: $companyId) {
      data {
        currentPage
        totalPages
        size
        totalCount
        companySalesPersonLogs {
          id
          companyId
          salesPersonEmail
          appointedByAdminId
          createdAt
        }
      }
    }
  }
`
