import gql from 'graphql-tag'

/*====== Fragments =====*/
const subscriptionPlanFragment = gql`
  fragment subscriptionPlanFields on SubscriptionPlanType {
    id
    categoryId
    name
    category
    monthlyFee
    annualFee
    monthlyJobCredit
    monthlyFeatureJobCredit
    monthlyUrgentJobCredit
    monthlyUnlockTalentCredit
    monthlyRhTalentCredit
    maxNoOfScreeningQuestionsPerJob
    maxNoOfEmailsToReceiveResumePerJob
    partnerNetwork
    featureJob
    urgentJob
    autoGenerateJobDescription
    screeningQuestion
    jobMirroring
    redirectExternalUrl
    jobSalaryMasking
    viewApplicationOnWebsite
    applicantStatusTracking
    receiveResumeInEmail
    advanceResumeScoring
    exportApplicationInExcel
    liveChatWithApplicant
    brandedEmployerCareerPage
    topEmployerLogo
    basicStatisticJobViewAndApplication
    exportJobStatisticInExcel
    phoneChatEmailSupport
    dedicatedAccountManager
    roboHeadhunting
    resumeRequest
    bosshuntAts
    customizeChatAutoResponse
    autoApproveJob
    autoReplyChatMessage
    createdAt
    updatedAt
  }
`
/*====== Query ======*/
export const GET_SUBSCRIPTION_PLAN_LIST = gql`
  query getSubscriptionPlanList(
    $size: Int
    $page: Int
    $sort: Int
    $queryData: String
  ) {
    subscriptionPlanList(
      size: $size
      page: $page
      sort: $sort
      queryData: $queryData
    ) {
      ok
      message
      data {
        totalPages
        totalNum
        currentPage
        currentNum
        subscriptionPlans {
          ...subscriptionPlanFields
        }
      }
    }
  }
  ${subscriptionPlanFragment}
`

export const GET_SUBSCRIPTION_PLAN_DETAIL = gql`
  query getSubscriptionPlanDetail($subscriptionPlanId: Int) {
    subscriptionPlan(subscriptionPlanId: $subscriptionPlanId) {
      ok
      message
      data {
        ...subscriptionPlanFields
      }
    }
  }
  ${subscriptionPlanFragment}
`

export const GET_LOCAL_SUBSCRIPTION_PLAN_DETAIL = gql`
  {
    subscriptionPlanDetail @client {
      ...subscriptionPlanFields
    }
  }
  ${subscriptionPlanFragment}
`
export const GET_LOCAL_SUBSCRIPTION_PLAN_DETAIL_FOR_NEW_FORM = gql`
  {
    subscriptionPlanDetailForNewForm @client {
      categoryId
      name
      monthlyFee
      annualFee
      monthlyJobCredit
      monthlyFeatureJobCredit
      monthlyUrgentJobCredit
      monthlyUnlockTalentCredit
      monthlyRhTalentCredit
      maxNoOfScreeningQuestionsPerJob
      maxNoOfEmailsToReceiveResumePerJob
      partnerNetwork
      featureJob
      urgentJob
      autoGenerateJobDescription
      screeningQuestion
      jobMirroring
      redirectExternalUrl
      jobSalaryMasking
      viewApplicationOnWebsite
      applicantStatusTracking
      receiveResumeInEmail
      advanceResumeScoring
      exportApplicationInExcel
      liveChatWithApplicant
      brandedEmployerCareerPage
      topEmployerLogo
      basicStatisticJobViewAndApplication
      exportJobStatisticInExcel
      phoneChatEmailSupport
      dedicatedAccountManager
      roboHeadhunting
      resumeRequest
      bosshuntAts
      customizeChatAutoResponse
      autoApproveJob
      autoReplyChatMessage
    }
  }
`
