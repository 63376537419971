exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pagination__2XvyY {\n  display: inline-block;\n  border-right: 1px solid #dee2e6;\n  padding: 4px 12px;\n}\n.pagination__2XvyY:last-child {\n  border-right: none;\n}\n.paginationWrapper__17s4G {\n  display: inline-block;\n  border: 1px solid #dee2e6;\n  border-radius: 2px;\n}\n.paginationHeader__1FS63 {\n  display: inline-block;\n  padding: 4px 12px;\n  border-right: 1px solid #dee2e6;\n}\n.pagination__2XvyY a {\n  display: block;\n}", "", {"version":3,"sources":["/app/src/shared/components/Pagination/Pagination.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAqB;EACrB,gCAA+B;EAC/B,kBAAiB;CACnB;AACA;EACE,mBAAkB;CACpB;AACA;EACE,sBAAqB;EACrB,0BAAyB;EACzB,mBAAkB;CACpB;AACA;EACE,sBAAqB;EACrB,kBAAiB;EACjB,gCAA+B;CACjC;AACA;EACE,eAAc;CAChB","file":"Pagination.module.scss","sourcesContent":[".pagination {\n  display: inline-block;\n  border-right: 1px solid #dee2e6;\n  padding: 4px 12px;\n}\n.pagination:last-child {\n  border-right: none;\n}\n.paginationWrapper {\n  display: inline-block;\n  border: 1px solid #dee2e6;\n  border-radius: 2px;\n}\n.paginationHeader {\n  display: inline-block;\n  padding: 4px 12px;\n  border-right: 1px solid #dee2e6;\n}\n.pagination a {\n  display: block;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pagination": "pagination__2XvyY",
	"paginationWrapper": "paginationWrapper__17s4G",
	"paginationHeader": "paginationHeader__1FS63"
};