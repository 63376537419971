import React from 'react'
import PropTypes from 'prop-types'

/* Vendors */
import classNames from 'classnames'

/* Assets */
import Spinner from './Spinner.svg'
import styles from './ToggleButton.module.scss'

const ToggleButton = ({ isChecked, isLoading, disabled, ...rest }) => (
  <div style={{ display: 'inline-block' }}>
    <label className={styles.switch}>
      <input
        type="checkbox"
        defaultChecked={isChecked}
        {...rest}
        disabled={isLoading || disabled}
      />
      <span
        className={
          isLoading ? classNames(styles.slider, styles.disabled) : styles.slider
        }
      />
    </label>
    <span
      className={
        isLoading ? styles.loader : classNames(styles.loader, styles.visible)
      }
    >
      <img src={Spinner} alt="spinner" height="20px" width="20px" />
    </span>
  </div>
)

ToggleButton.propTypes = {
  /** to set default checked attribute */
  isChecked: PropTypes.bool,

  /** to show loading state */
  isLoading: PropTypes.bool,

  /** to disabled toggle button */
  disabled: PropTypes.bool
}

export default ToggleButton
