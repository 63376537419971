import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { compose, graphql } from 'react-apollo'
import { DELETE_COMPANY_REVIEWS } from '../../../../schemas/mutations'

/* Bootstrap */
import { Row, Col, FormGroup, Label } from 'reactstrap'

/* Component */
import SectionWrapper from 'shared/components/SectionWrapper'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Assets */
import ActiveStar from './active-star.svg'
import InActiveStar from './inactive-star.svg'
import HalfStar from './half-star.svg'

import styles from './CompanyReviewDetail.module.scss'

/**
 * CompanyReviewDetail - component that render review detail
 *
 * @returns {Component}
 */
class CompanyReviewDetail extends Component {
  static propTypes = {
    review: PropTypes.object.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    deleteReview: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.deleteReview = this.deleteReview.bind(this)
  }

  async deleteReview() {
    try {
      let response = await this.props.deleteReview({
        variables: {
          ids: [this.props.review.id]
        }
      })

      if (response) {
        window.location.href = `/bossjob/company/${
          this.props.match.params.id
        }/reviews/1`
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * renderRating - render star image according to the number of received rating
   * @param {Int} rating
   */
  renderRating(rating) {
    let children = []

    for (let count = 1; count <= rating; count++) {
      children.push(
        <img
          key={`star-count-${count}`}
          src={ActiveStar}
          className={styles.star}
        />
      )

      if (rating - count === 0.5) {
        children.push(
          <img
            key={`star-count-${count}`}
            src={HalfStar}
            className={styles.star}
          />
        )
      }
    }

    for (let count = rating + 1; count <= 5; count++) {
      children.push(
        <img
          key={`star-count-${count}`}
          src={InActiveStar}
          className={styles.star}
        />
      )
    }

    return children
  }

  renderDetail() {
    const { review } = this.props
    return (
      <React.Fragment>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Reviewer
              </Label>
              <Col md={9} sm={12}>
                <div style={{ padding: '7px' }}>
                  <a
                    href={`/bossjob/user/${review.userId}`}
                    style={{ textDecoration: 'underline' }}
                  >
                    {review.firstName} {review.lastName}
                  </a>
                </div>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <FormGroup row>
              <Label md={2} sm={12}>
                Good
              </Label>
              <Col md={9} sm={12}>
                <div style={{ padding: '7px' }}>{review.goodContent}</div>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <FormGroup row>
              <Label md={2} sm={12}>
                Challenge
              </Label>
              <Col md={9} sm={12}>
                <div style={{ padding: '7px' }}>{review.challengeContent}</div>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Work life balance
              </Label>
              <Col md={9} sm={12}>
                {this.renderRating(review.workLifeBalRate)}
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Management
              </Label>
              <Col md={9} sm={12}>
                {this.renderRating(review.managementRate)}
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Work environment
              </Label>
              <Col md={9} sm={12}>
                {this.renderRating(review.workingXpRate)}
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Benefits
              </Label>
              <Col md={9} sm={12}>
                {this.renderRating(review.benefitsRate)}
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Overall
              </Label>
              <Col md={9} sm={12}>
                {this.renderRating(review.overallExpRate)}
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Recommend
              </Label>
              <Col md={9} sm={12}>
                <div style={{ padding: '7px' }}>
                  {review.recommend ? 'Recommended' : 'Not Recommended'}
                </div>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  render() {
    return (
      <Can I="edit" a="companies" passThrough>
        {can => (
          <SectionWrapper
            sectionTitle="Company Review"
            handleDelete={can ? this.deleteReview : null}
          >
            {this.renderDetail()}
          </SectionWrapper>
        )}
      </Can>
    )
  }
}

export default compose(
  graphql(DELETE_COMPANY_REVIEWS, { name: 'deleteReview' })
)(withRouter(CompanyReviewDetail))
