import moment from 'moment'

export function hasEmpty(data) {
  if (!data) return true
  const { startedAt, endedAt, contentList } = data
  if (!contentList) return true
  if (contentList.length === 0) return true

  if (!startedAt || !endedAt) return true

  const arr = contentList.filter(item => {
    return !item.value
  })
  return arr.length > 0
}

const langMap = {
  'zh-CN': 'zhCN',
  'zh-TW': 'zhTW'
}
export function langMapReverse(data) {
  if (!data) return {}
  const { startedAt, endedAt, submitUuid, contentList } = data
  const rest = {}
  contentList.forEach(item => {
    const key = langMap[item.languageCode] || item.languageCode
    rest[key] = item.value
  })
  return {
    startedAt,
    endedAt,
    submitUuid,
    ...rest
  }
}

export function validSubmitDate(data) {
  if (!data) return 'Some fields are empty.'
  const { startedAt, endedAt, en, zhCN } = data

  if (!startedAt || !endedAt) return 'Start time or end time fields are empty.'

  const isAfterMinute = moment(endedAt).isAfter(startedAt, 'minute')

  if (!isAfterMinute) return 'End time cannot be earlier than start time.'

  if (!en) return 'English field is empty.'

  if (!zhCN) return 'Chinese field is empty.'

  return ''
}
