import React, { Component } from 'react'
import PropTypes from 'react'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_BOSSJOB_CHAT_AND_JOB_CREDITS
} from '../../../../schemas/queries'
import {
  UPDATE_LOCAL_CREDIT_MODAL,
  ADD_BOSSJOB_USER_CREDITS,
  UPDATE_BOSSJOB_USER_CREDITS_NOTES
  // ADD_BOSSJOB_USER_JOB_CREDITS
} from '../../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import CreditBox from 'shared/components/CreditBox'
import CreditModal from 'shared/components/CreditModal'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Assets */
import styles from './Credits.module.scss'

/**
 * Credits - Credits wrapper
 *
 * @returns {Component}
 *
 */
class Credits extends Component {
  static propTypes = {
    updateLocalCreditModal: PropTypes.func,
    addBossjobUserCredits: PropTypes.func,
    updateBossjobUserCreditsNotes: PropTypes.func,
    addBossjobUserJobCredit: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      typeId: ''
    }

    this.handleAdjustModalClick = this.handleAdjustModalClick.bind(this)
  }

  /**
   * @func handleAdjustModalClick - Used to toggle between Credit and Job state before adjusting
   *
   * @param {Object} variables
   * @param {String} modalType
   */
  handleAdjustModalClick(variables) {
    this.props.updateLocalCreditModal(variables)
  }

  selectedCategory = typeId => {
    this.setState({
      typeId
    })
  }

  render() {
    const {
      updateLocalCreditModal,
      addBossjobUserCredits,
      updateBossjobUserCreditsNotes
    } = this.props

    const { typeId } = this.state

    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <Query
            query={GET_BOSSJOB_CHAT_AND_JOB_CREDITS}
            variables={{ userId: bossjobUserDetail.id }}
          >
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else {
                console.log('data', data)
                return (
                  <div className={styles.wrapper}>
                    <Can I="edit" a="credits" passThrough>
                      {can =>
                        can && (
                          <>
                            {/** Modal for adjusting user's points */}
                            <CreditModal
                              identifier="credit"
                              handleModal={updateLocalCreditModal}
                              handleAdd={addBossjobUserCredits}
                              handleUpdate={updateBossjobUserCreditsNotes}
                              userId={bossjobUserDetail.id}
                              typeId={typeId !== '' ? typeId : null}
                              refetch={() => window.location.reload()}
                            />

                            <CreditBox
                              identifier="TALENT_CREDIT"
                              typeId={1}
                              selectCategory={typeId =>
                                this.selectedCategory(typeId)
                              }
                              handleModal={variables =>
                                this.handleAdjustModalClick(variables, 'credit')
                              }
                              totalCredits={
                                data.userCreditHistory.data.unlockTalentCredits
                              }
                              subText="Talent Credits"
                              modalHeader="Adjust Talent Credits"
                              hasPermission
                            />

                            <CreditBox
                              identifier="FEATURE_JOB_CREDIT"
                              typeId={2}
                              selectCategory={typeId =>
                                this.selectedCategory(typeId)
                              }
                              handleModal={variables =>
                                this.handleAdjustModalClick(variables, 'credit')
                              }
                              totalCredits={
                                data.userCreditHistory.data.featureJobCredits
                              }
                              subText="Feature Job Credits"
                              modalHeader="Adjust Feature Job Credits"
                              hasPermission
                            />

                            <CreditBox
                              identifier="URGENT_JOB_CREDIT"
                              typeId={3}
                              selectCategory={typeId =>
                                this.selectedCategory(typeId)
                              }
                              handleModal={variables =>
                                this.handleAdjustModalClick(variables, 'credit')
                              }
                              totalCredits={
                                data.userCreditHistory.data.urgentJobCredits
                              }
                              subText="Urgent Job Credits"
                              modalHeader="Adjust Urgent Job Credits"
                              hasPermission
                            />

                            <CreditBox
                              identifier="ROBO_HEADHUNT_TALENT_CREDIT"
                              typeId={4}
                              selectCategory={typeId =>
                                this.selectedCategory(typeId)
                              }
                              handleModal={variables =>
                                this.handleAdjustModalClick(variables, 'credit')
                              }
                              totalCredits={
                                data.userCreditHistory.data
                                  .roboHeadhuntTalentCredits
                              }
                              subText="Robo Headhunt Talent Credits"
                              modalHeader="Adjust Robo Headhunted Talent Credits"
                              hasPermission
                            />
                          </>
                        )
                      }
                    </Can>
                  </div>
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}

export default compose(
  graphql(UPDATE_LOCAL_CREDIT_MODAL, { name: 'updateLocalCreditModal' }),
  graphql(ADD_BOSSJOB_USER_CREDITS, { name: 'addBossjobUserCredits' }),
  graphql(UPDATE_BOSSJOB_USER_CREDITS_NOTES, {
    name: 'updateBossjobUserCreditsNotes'
  })
  // graphql(ADD_BOSSJOB_USER_JOB_CREDITS, { name: 'addBossjobUserJobCredit' })
)(Credits)
