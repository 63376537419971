exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formWrapper__3GI6H {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.addFormButton__aGXbG {\n  margin-top: 35px;\n  display: block;\n  color: white;\n  font-size: 18px;\n}", "", {"version":3,"sources":["/app/src/modules/marketing/views/LandingPageBanner/FeaturedBannerContainer/FeaturedBannerContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,oBAAe;MAAf,gBAAe;CACjB;;AAEA;EACE,iBAAgB;EAChB,eAAc;EACd,aAAY;EACZ,gBAAe;CACjB","file":"FeaturedBannerContainer.module.scss","sourcesContent":[".formWrapper {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.addFormButton {\n  margin-top: 35px;\n  display: block;\n  color: white;\n  font-size: 18px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formWrapper": "formWrapper__3GI6H",
	"addFormButton": "addFormButton__aGXbG"
};