export default {
  jobDetail: {
    __typename: 'JobDetailType',
    data: {}
  },
  postJobDetail: {
    __typename: 'PostJobDetailType',
    id: '',
    jobTitle: '',
    jobTitleRef: '',
    jobTitleId: undefined,
    relatedJobs: '',
    jobTypeKey: 'full_time',
    xpLvlKey: '1_to_3_years',
    degreeKey: 'diploma',
    currencyId: 1,
    salaryRangeFrom: 10000,
    jobLocationKey: 'alaminos',
    salaryRangeTo: 15000,
    jobCountryKey: 'ph',
    floorUnit: '',
    address: '',
    latitude: '',
    longitude: '',
    jobDescriptionHtml: '',
    jobDescription: '',
    jobRequirementsHtml: '',
    jobRequirements: '',
    screeningMessage: '',
    screeningQuestions: [
      {
        id: '',
        question: '',
        __typename: 'JobScreeningQuestionType'
      }
    ],
    resumeForwardingEmails: [
      {
        id: '',
        email: '',
        __typename: 'JobResumeForwardingEmailType'
      }
    ],
    jobSkills: '',
    jobCategories: [
      {
        __typename: 'JobCategoryType',
        category: {
          id: 52,
          value: 'Actuarial/Statistics',
          __typename: 'JobCategoryListType'
        }
      }
    ],
    jobBenefits: [
      {
        benefitId: '',
        __typename: 'JobBenefitsType'
      }
    ],
    jdUrl: '',

    salaryTypeId: 1,
    dailySalaryRangeFrom: 500,
    dailySalaryRangeTo: 1000,
    hourlySalaryRangeFrom: 50,
    hourlySalaryRangeTo: 100
  }
}
