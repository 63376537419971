import gql from 'graphql-tag'

/* Get featured company list */
export const GET_SUPPORTED_COUNTRY = gql`
  query getSupportedCountry {
    getSupportedCountry {
      ok
      message
      data {
        id
        key
        code
        value
        isBossjobSupported
      }
    }
  }
`
