import React, { Component } from 'react'
import PropTypes from 'react'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_JOB_POST_BALANCE_OF_RECRUITER
} from '../../../../schemas/queries'
import {
  UPDATE_LOCAL_CREDIT_MODAL,
  ADD_BOSSJOB_USER_JOB_POST_CREDITS,
  UPDATE_BOSSJOB_USER_CREDITS_NOTES
} from '../../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import CreditBox from 'shared/components/CreditBox'
import CreditModal from 'shared/components/CreditModal'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Assets */
import styles from './JobPostCredits.module.scss'

/**
 * Credits - Credits wrapper
 *
 * @returns {Component}
 *
 */
class JobPostCredits extends Component {
  static propTypes = {
    updateLocalCreditModal: PropTypes.func,
    addBossjobUserJobPostCredits: PropTypes.func,
    updateBossjobUserCreditsNotes: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      typeId: ''
    }

    this.handleAdjustModalClick = this.handleAdjustModalClick.bind(this)
  }

  /**
   * @func handleAdjustModalClick - Used to toggle between free, paid and trial state before adjusting
   *
   * @param {Object} variables
   * @param {String} modalType
   */
  handleAdjustModalClick = variables => {
    this.props.updateLocalCreditModal(variables)
  }

  // handleAdjustModalClick(variables) {
  //   this.setState(
  //     {
  //       modalType: modalType
  //     },
  //     () => {
  //       console.log('variables', variables)
  //       this.props.updateLocalCreditModal(variables)
  //     }
  //   )
  // }

  selectedCategory = typeId => {
    this.setState({
      typeId
    })
  }

  render() {
    const {
      addBossjobUserJobPostCredits,
      updateLocalCreditModal,
      updateBossjobUserCreditsNotes
    } = this.props
    const { typeId } = this.state
    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <Query
            query={GET_JOB_POST_BALANCE_OF_RECRUITER}
            variables={{ userId: bossjobUserDetail.id }}
          >
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else {
                return (
                  <div className={styles.wrapper}>
                    <Can I="edit" a="credits" passThrough>
                      {can =>
                        can && (
                          <>
                            {/** Modal for adjusting user's number of job posts */}
                            <CreditModal
                              identifier="jobPost"
                              handleModal={updateLocalCreditModal}
                              handleAdd={addBossjobUserJobPostCredits}
                              handleUpdate={updateBossjobUserCreditsNotes}
                              userId={bossjobUserDetail.id}
                              typeId={typeId !== '' ? typeId : null}
                              refetch={() => window.location.reload()}
                            />

                            <CreditBox
                              identifier="PAID_JOB_POST"
                              typeId={2}
                              selectCategory={typeId =>
                                this.selectedCategory(typeId)
                              }
                              handleModal={variables =>
                                this.handleAdjustModalClick(variables)
                              }
                              totalPosts={
                                data.jobPostBalance.data.availablePaidJobPosts
                              }
                              subText="Paid posts available"
                              modalHeader="Adjust Paid Job Posts"
                              hasPermission
                            />
                            <CreditBox
                              identifier="FREE_JOB_POST"
                              typeId={3}
                              selectCategory={typeId =>
                                this.selectedCategory(typeId)
                              }
                              handleModal={variables =>
                                this.handleAdjustModalClick(variables)
                              }
                              totalPosts={
                                data.jobPostBalance.data.availableFreeJobPosts
                              }
                              subText="Free posts available"
                              modalHeader="Adjust Free Job Posts"
                              hasPermission
                            />
                            <CreditBox
                              identifier="TRIAL_JOB_POST"
                              typeId={1}
                              selectCategory={typeId =>
                                this.selectedCategory(typeId)
                              }
                              handleModal={variables =>
                                this.handleAdjustModalClick(variables)
                              }
                              totalPosts={
                                data.jobPostBalance.data.availableTrialJobPosts
                              }
                              subText="Trial posts Available"
                              modalHeader="Adjust Trial Job Posts"
                              hasPermission
                            />
                          </>
                        )
                      }
                    </Can>
                  </div>
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}

export default compose(
  graphql(UPDATE_LOCAL_CREDIT_MODAL, { name: 'updateLocalCreditModal' }),
  graphql(ADD_BOSSJOB_USER_JOB_POST_CREDITS, {
    name: 'addBossjobUserJobPostCredits'
  }),
  graphql(UPDATE_BOSSJOB_USER_CREDITS_NOTES, {
    name: 'updateBossjobUserCreditsNotes'
  })
)(JobPostCredits)
