import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter, Route, Redirect } from 'react-router-dom'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import JobPostCreditsHistoryListContainer from '../../../../../containers/BossJobUserDetailContainer/JobPostCreditsHistoryListContainer/JobPostCreditsHistoryListContainer'

/* Assets */
import styles from '../../RecruiterInformation.module.scss'
const JobPostCreditsDetailWrapper = ({ match }) => (
  <SectionWrapper sectionTitle="">
    <div className={styles.subMenu} style={{ justifyContent: 'space-between' }}>
      <div className={styles.subMenuItem}>Usage History</div>
    </div>

    <Route
      exact
      path={`${match.path}`}
      component={() => <Redirect to={`${match.url}/history/1`} />}
    />

    <Route
      exact
      path="/bossjob/user/:id/recruiter/job-post/history/:page"
      component={JobPostCreditsHistoryListContainer}
    />
  </SectionWrapper>
)

JobPostCreditsDetailWrapper.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(JobPostCreditsDetailWrapper)
