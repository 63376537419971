import gql from 'graphql-tag'

export const APPROVE_NEPTUNE_COMPANY_DOCUMENT = gql`
  mutation ApproveNeptuneCompanyDocument($neptuneCompanyId: Int!) {
    approveNeptuneCompanyDocument(neptuneCompanyId: $neptuneCompanyId) {
      ok
      message
    }
  }
`

export const REJECT_NEPTUNE_COMPANY_DOCUMENT = gql`
  mutation RejectNeptuneCompanyDocument(
    $neptuneCompanyId: Int!
    $rejectedReason: String!
  ) {
    rejectNeptuneCompanyDocument(
      neptuneCompanyId: $neptuneCompanyId
      rejectedReason: $rejectedReason
    ) {
      ok
      message
    }
  }
`

export const REQUIRE_CHANGE_COMPANY_DOCUMENT = gql`
  mutation RequireChangeCompanyDocument(
    $neptuneCompanyId: Int!
    $changeRequiredReason: String!
  ) {
    requireChangeNeptuneCompanyDocument(
      neptuneCompanyId: $neptuneCompanyId
      changeRequiredReason: $changeRequiredReason
    ) {
      ok
      message
    }
  }
`

/* Upload company document */
export const UPLOAD_COMPANY_DOCUMENT = gql`
  mutation UploadCompanyDocument($id: Int!, $file: Upload!) {
    uploadCompanyDocument(companyId: $id, file: $file) {
      ok
      message
    }
  }
`
