import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_BOSSPOINT_HISTORY_BY_ID
} from '../../../schemas/queries'
import {
  UPDATE_LOCAL_CREDIT_MODAL,
  ADD_BOSSPOINT_TO_USER,
  UPDATE_BOSSPOINT_NOTE_TO_USER
} from '../../../schemas/mutations'

/* Bootstrap */
import { Col } from 'reactstrap'

/* Components */
import CreditBox from 'shared/components/CreditBox'
import CreditModal from 'shared/components/CreditModal'
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import BosspointHistoryList from './BosspointHistoryList'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

const selectedCategory = () => {
  return
}

/**
 * @function getBosspoints - render user's bosspoint component
 * @param {*} totalPoints
 * @param {*} handleBosspointModal
 */
const getBosspoints = (totalPoints, handleBosspointModal, userStatusKey) => (
  <Col md="12">
    <Can I="edit" a="points" passThrough>
      {can => (
        <CreditBox
          identifier="BOSSPOINT"
          handleModal={handleBosspointModal}
          totalCredits={totalPoints}
          selectCategory={selectedCategory}
          subText="Bosspoints"
          hasPermission={can && userStatusKey !== 'inactive'}
          modalHeader="Add Bosspoint quantity"
        />
      )}
    </Can>
  </Col>
)

/**
 * Bosspoints - main wrapper component for the Bosspoints page
 *
 * @returns {Component}
 */
const Bosspoints = ({
  match,
  updateLocalCreditModal,
  adjustUserBosspoint,
  updateBosspointNote
}) => (
  <div>
    <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
      {({ data: { bossjobUserDetail } }) => (
        <Query
          query={GET_BOSSPOINT_HISTORY_BY_ID}
          variables={{ userId: bossjobUserDetail.id, page: match.params.page }}
        >
          {({ loading, data, refetch }) => {
            if (loading) {
              return <Loading />
            } else {
              return (
                <div>
                  {/** Total bosspoints section */}
                  {getBosspoints(
                    data.userPoints.data.totalPoints,
                    updateLocalCreditModal,
                    bossjobUserDetail.statusKey
                  )}

                  {/** Modal for adjusting user's points */}
                  <CreditModal
                    identifier="bosspoint"
                    handleModal={updateLocalCreditModal}
                    handleAdd={adjustUserBosspoint}
                    handleUpdate={updateBosspointNote}
                    userId={bossjobUserDetail.id}
                    refetch={refetch}
                  />

                  <SectionWrapper
                    sectionTitle="History"
                    style={{ marginTop: '20px' }}
                  >
                    <BosspointHistoryList
                      bossPointList={data.userPoints.data.points}
                      totalCount={data.userPoints.data.totalCount}
                      totalPages={data.userPoints.data.totalPages}
                      currentPage={Number(match.params.page)}
                      handleModal={updateLocalCreditModal}
                    />
                  </SectionWrapper>
                </div>
              )
            }
          }}
        </Query>
      )}
    </Query>
  </div>
)

Bosspoints.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  updateLocalCreditModal: PropTypes.func.isRequired,
  adjustUserBosspoint: PropTypes.func.isRequired,
  updateBosspointNote: PropTypes.func.isRequired
}

export default compose(
  graphql(UPDATE_LOCAL_CREDIT_MODAL, { name: 'updateLocalCreditModal' }),
  graphql(ADD_BOSSPOINT_TO_USER, { name: 'adjustUserBosspoint' }),
  graphql(UPDATE_BOSSPOINT_NOTE_TO_USER, { name: 'updateBosspointNote' })
)(withRouter(Bosspoints))
