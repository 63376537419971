import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import ToggleButton from 'shared/components/ToggleButton'
import Loading from 'shared/components/Loading'

import styles from '../AgencyDetail.module.scss'

/* Apollo */
import { withApollo, compose, graphql } from 'react-apollo'
import { UPDATE_AGENCY_FEE } from '../../../schemas/mutations'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Helpers */
import {
  turnaroundPeriodList,
  guaranteePeriodList
} from 'shared/helpers/feePeriod'

const FeesForm = ({
  handleChange,
  handleToggle,
  agencyDetail,
  updateAgencyFee
}) => {
  console.log('agencyDetail', agencyDetail)
  const [isLoading, setLoadingState] = useState(false)
  const [error, setError] = useState('')
  const updateFee = async () => {
    setLoadingState(true)
    try {
      let updateFeeResponse = await updateAgencyFee({
        variables: {
          companyId: agencyDetail && agencyDetail.id,
          companyFeeStructureInput: {
            isContingentFee:
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isContingentFee
                ? agencyDetail.feeStructure.isContingentFee
                : false,
            isRetainedFee:
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isRetainedFee
                ? agencyDetail.feeStructure.isRetainedFee
                : false,
            isOtherFee:
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isOtherFee
                ? agencyDetail.feeStructure.isOtherFee
                : false,
            annualGrossSalaryFrom:
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.annualGrossSalaryFrom
                ? agencyDetail.feeStructure.annualGrossSalaryFrom
                : null,
            annualGrossSalaryTo:
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.annualGrossSalaryTo
                ? agencyDetail.feeStructure.annualGrossSalaryTo
                : null,
            monthlyFeeFrom:
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.monthlyFeeFrom
                ? agencyDetail.feeStructure.monthlyFeeFrom
                : null,
            monthlyFeeTo:
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.monthlyFeeTo
                ? agencyDetail.feeStructure.monthlyFeeTo
                : null,
            otherFee:
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.otherFee
                ? agencyDetail.feeStructure.otherFee
                : null
          },
          companyFreeReplacementInput: {
            maxFreeReplacement:
              agencyDetail &&
              agencyDetail.freeReplacement &&
              agencyDetail.freeReplacement.maxFreeReplacement
                ? agencyDetail.freeReplacement.maxFreeReplacement
                : null
          },
          companyTurnaroundTimeInput: {
            turnaroundTimeId:
              agencyDetail &&
              agencyDetail.turnaroundTime &&
              agencyDetail.turnaroundTime.turnaroundTimeId
                ? agencyDetail.turnaroundTime.turnaroundTimeId
                : null,
            fromPeriod:
              agencyDetail &&
              agencyDetail.turnaroundTime &&
              agencyDetail.turnaroundTime.fromPeriod
                ? agencyDetail.turnaroundTime.fromPeriod
                : null,
            toPeriod:
              agencyDetail &&
              agencyDetail.turnaroundTime &&
              agencyDetail.turnaroundTime.toPeriod
                ? agencyDetail.turnaroundTime.toPeriod
                : null
          },
          companyGuaranteePeriodInput: {
            guaranteePeriodId:
              agencyDetail &&
              agencyDetail.guaranteePeriod &&
              agencyDetail.guaranteePeriod.guaranteePeriodId
                ? agencyDetail.guaranteePeriod.guaranteePeriodId
                : null,
            fromPeriod:
              agencyDetail &&
              agencyDetail.guaranteePeriod &&
              agencyDetail.guaranteePeriod.fromPeriod
                ? agencyDetail.guaranteePeriod.fromPeriod
                : null,
            toPeriod:
              agencyDetail &&
              agencyDetail.guaranteePeriod &&
              agencyDetail.guaranteePeriod.toPeriod
                ? agencyDetail.guaranteePeriod.toPeriod
                : null
          }
        }
      })

      if (updateFeeResponse) {
        let status = updateFeeResponse.data.updateHeadhunterCompanyFee

        if (status.ok) {
          setLoadingState(false)
          // just a UX to show user it has been updated
          window.location.reload()
        } else {
          setLoadingState(false)
          setError('Something went wrong, please notify and try again later')
        }
      }
    } catch (err) {
      setLoadingState(false)
      setError(err)
    }
  }
  const _renderContingentFeeToggle = () => {
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Contingent Fee
        </Label>
        <Col md={8} sm={12}>
          <ToggleButton
            name="isContingentFee"
            value={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isContingentFee
                ? agencyDetail.feeStructure.isContingentFee
                : false
            }
            isChecked={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isContingentFee
            }
            onChange={() =>
              handleToggle(
                'isContingentFee',
                !agencyDetail.feeStructure.isContingentFee,
                'feeStructure'
              )
            }
          />
        </Col>
      </FormGroup>
    )
  }
  const _renderRetainedFeeToggle = () => {
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Retained Fee
        </Label>
        <Col md={8} sm={12}>
          <ToggleButton
            name="isRetainedFee"
            value={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isRetainedFee
                ? agencyDetail.feeStructure.isRetainedFee
                : false
            }
            isChecked={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isRetainedFee
            }
            onChange={() =>
              handleToggle(
                'isRetainedFee',
                !agencyDetail.feeStructure.isRetainedFee,
                'feeStructure'
              )
            }
          />
        </Col>
      </FormGroup>
    )
  }
  const _renderOtherFeeToggle = () => {
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Other Fee
        </Label>
        <Col md={8} sm={12}>
          <ToggleButton
            name="isOtherFee"
            value={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isOtherFee
                ? agencyDetail.feeStructure.isOtherFee
                : false
            }
            isChecked={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.isOtherFee
            }
            onChange={() =>
              handleToggle(
                'isOtherFee',
                !agencyDetail.feeStructure.isRetainedFee,
                'feeStructure'
              )
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderContingentFeeMinField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Contingent Fee (Min) (%)
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="annualGrossSalaryFrom"
            onChange={e => handleChange(e, 'feeStructure')}
            value={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.annualGrossSalaryFrom
                ? agencyDetail.feeStructure.annualGrossSalaryFrom
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderContingentFeeMaxField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Contingent Fee (Max) (%)
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="annualGrossSalaryTo"
            onChange={e => handleChange(e, 'feeStructure')}
            value={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.annualGrossSalaryTo
                ? agencyDetail.feeStructure.annualGrossSalaryTo
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderRetainedFeeMinField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Retained Fee (Min) (PHP)
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="monthlyFeeFrom"
            onChange={e => handleChange(e, 'feeStructure')}
            value={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.monthlyFeeFrom
                ? agencyDetail.feeStructure.monthlyFeeFrom
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderRetainedFeeMaxField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Retained Fee (Max) (PHP)
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="monthlyFeeTo"
            onChange={e => handleChange(e, 'feeStructure')}
            value={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.monthlyFeeTo
                ? agencyDetail.feeStructure.monthlyFeeTo
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderOtherFeeField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Other fee
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="text"
            name="otherFee"
            onChange={e => handleChange(e, 'feeStructure')}
            value={
              agencyDetail &&
              agencyDetail.feeStructure &&
              agencyDetail.feeStructure.otherFee
                ? agencyDetail.feeStructure.otherFee
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderTurnaroundPeriodField = () => {
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Turnaround period
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="select"
            name="turnaroundTimeId"
            onChange={e => handleChange(e, 'turnaround')}
          >
            {turnaroundPeriodList().map(period => (
              <option key={period.id} value={period.id}>
                {period.name}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  const _renderTurnaroundMinField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Turnaround Time (Min)
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="fromPeriod"
            onChange={e => handleChange(e, 'turnaround')}
            value={
              agencyDetail &&
              agencyDetail.turnaroundTime &&
              agencyDetail.turnaroundTime.fromPeriod
                ? agencyDetail.turnaroundTime.fromPeriod
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderTurnaroundMaxField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Turnaround Time (Max)
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="toPeriod"
            onChange={e => handleChange(e, 'turnaround')}
            value={
              agencyDetail &&
              agencyDetail.turnaroundTime &&
              agencyDetail.turnaroundTime.toPeriod
                ? agencyDetail.turnaroundTime.toPeriod
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }
  const _renderGuaranteePeriodField = () => {
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Guarantee period
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="select"
            name="guaranteePeriodId"
            onChange={e => handleChange(e, 'guarantee')}
          >
            {guaranteePeriodList().map(period => (
              <option key={period.id} value={period.id}>
                {period.name}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  const _renderGuaranteePeriodMinField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Guarantee Period (Min)
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="fromPeriod"
            onChange={e => handleChange(e, 'guarantee')}
            value={
              agencyDetail &&
              agencyDetail.guaranteePeriod &&
              agencyDetail.guaranteePeriod.fromPeriod
                ? agencyDetail.guaranteePeriod.fromPeriod
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderGuaranteePeriodMaxField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Guarantee Period (Max)
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="toPeriod"
            onChange={e => handleChange(e, 'guarantee')}
            value={
              agencyDetail &&
              agencyDetail.guaranteePeriod &&
              agencyDetail.guaranteePeriod.toPeriod
                ? agencyDetail.guaranteePeriod.toPeriod
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }

  const _renderFreeReplacementField = () => {
    // const { handleChange, transactionDetail } = this.props
    return (
      <FormGroup row>
        <Label md={4} sm={12}>
          Max number of free replacements
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="number"
            name="maxFreeReplacement"
            onChange={e => handleChange(e, 'replacement')}
            value={
              agencyDetail &&
              agencyDetail.freeReplacement &&
              agencyDetail.freeReplacement.maxFreeReplacement
                ? agencyDetail.freeReplacement.maxFreeReplacement
                : null
            }
          />
        </Col>
      </FormGroup>
    )
  }
  return (
    <div>
      <SectionWrapper sectionTitle="Fee Structure">
        <Form>
          <Row>
            <Col md={6}>{_renderContingentFeeToggle()}</Col>
          </Row>
          <Row>
            <Col md={6}>{_renderContingentFeeMinField()}</Col>
            <Col md={6}>{_renderContingentFeeMaxField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{_renderRetainedFeeToggle()}</Col>
          </Row>
          <Row>
            <Col md={6}>{_renderRetainedFeeMinField()}</Col>
            <Col md={6}>{_renderRetainedFeeMaxField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{_renderOtherFeeToggle()}</Col>
          </Row>
          <Row>
            <Col md={6}>{_renderOtherFeeField()}</Col>
          </Row>
        </Form>
      </SectionWrapper>
      <SectionWrapper sectionTitle="Turnaround Time">
        <Form>
          <Row>
            <Col md={6}>{_renderTurnaroundPeriodField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{_renderTurnaroundMinField()}</Col>
            <Col md={6}>{_renderTurnaroundMaxField()}</Col>
          </Row>
        </Form>
      </SectionWrapper>
      <SectionWrapper sectionTitle="Guarantee Period">
        <Form>
          <Row>
            <Col md={6}>{_renderGuaranteePeriodField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{_renderGuaranteePeriodMinField()}</Col>
            <Col md={6}>{_renderGuaranteePeriodMaxField()}</Col>
          </Row>
        </Form>
      </SectionWrapper>
      <SectionWrapper sectionTitle="Free replacements">
        <Form>
          <Row>
            <Col md={6}>{_renderFreeReplacementField()}</Col>
          </Row>
        </Form>
      </SectionWrapper>
      {error ? (
        <span style={{ color: 'red', marginBottom: '10px' }}>{error}</span>
      ) : null}
      <Can I="edit" a="headhunters">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '4vw',
            margin: '20px'
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <Button
              color="info"
              className={styles.saveBtn}
              onClick={() => updateFee()}
            >
              Update
            </Button>
          )}
        </div>
      </Can>
    </div>
  )
  // }
}

FeesForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  updateAgencyFee: PropTypes.func.isRequired,
  agencyDetail: PropTypes.object.isRequired
}

export default compose(
  withApollo,
  graphql(UPDATE_AGENCY_FEE, { name: 'updateAgencyFee' })
)(FeesForm)
