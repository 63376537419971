import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { getNeptuneCompanyConfigList } from 'shared/helpers/newConfigList'
import Loading from 'shared/components/Loading'
import { convertToISOFormat } from '../../../../shared/helpers/datetime'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ALL_USERS, GET_ME } from 'modules/users/schemas/queries'
import queryString from 'query-string'

/* Bootstrap */
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
  Form
} from 'reactstrap'

const SearchBar = ({ match }) => {
  // const [searches, setSearches] = useState({})

  const [name, setName] = useState(null)
  const [legalName, setLegalName] = useState(null)

  const [country, setCountry] = useState([])
  const [countryOptions, setCountryOptions] = useState([])

  const [companyType, setCompanyType] = useState(null)
  const [companyTypeLists, setCompanyTypeLists] = useState([])

  const [companyOperatingValue, setCompanyOperatingValue] = useState(null)
  const [
    companyOperatingStatusLists,
    setCompanyOperatingStatusLists
  ] = useState([])

  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null)

  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const {
        country_lists,
        company_type_lists,
        company_operating_status_lists
      } = await getNeptuneCompanyConfigList()
      setCountryOptions(country_lists)
      setCompanyTypeLists(company_type_lists)
      setCompanyOperatingStatusLists(company_operating_status_lists)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const {
      companyOperatingValue,
      companyType,
      country,
      legalName,
      name,
      selectedSalesPerson,
      fromDate,
      toDate
    } = queryString.parse(location.search)

    setCompanyOperatingValue(companyOperatingValue)
    setCompanyType(companyType)
    setCountry(country)
    setLegalName(legalName)
    setName(name)
    setSelectedSalesPerson(selectedSalesPerson)
    fromDate && setFromDate(new Date(fromDate))
    toDate && setToDate(new Date(toDate))
  }, [])

  const handleSearch = () => {
    let host = match.url + '?'
    let url = ''

    if (name) {
      url += `name=${encodeURIComponent(name)}&`
    }

    if (legalName) {
      url += `legalName=${encodeURIComponent(legalName)}&`
    }

    if (country) {
      url += `country=${encodeURIComponent(country)}&`
    }

    if (companyType) {
      url += `companyType=${encodeURIComponent(companyType)}&`
    }

    if (companyOperatingValue) {
      url += `companyOperatingValue=${encodeURIComponent(
        companyOperatingValue
      )}&`
    }

    if (selectedSalesPerson) {
      url += `selectedSalesPerson=${encodeURIComponent(selectedSalesPerson)}&`
    }

    if (fromDate && toDate) {
      url += `fromDate=${convertToISOFormat(
        fromDate,
        true
      )}&toDate=${convertToISOFormat(toDate, true, false)}&`
    }

    window.location = host + url
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleSearch()
  }

  const renderCountryField = () => {
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Register Country:
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="countryOption"
            value={country}
            onChange={e => setCountry(e.target.value)}
          >
            <option value="">All</option>
            {countryOptions.map((option, index) => {
              return (
                <option key={index} value={option.id}>
                  {option.value}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  const renderCompanyTypeField = () => {
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Company Type：
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="companyType"
            value={companyType}
            onChange={e => setCompanyType(e.target.value)}
          >
            <option value="">All</option>
            {companyTypeLists.map((option, index) => {
              return (
                <option key={index} value={option.id}>
                  {option.value}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  const renderCompanyOperatingStatusListsField = () => {
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Company Type：
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="companyOperatingValue"
            value={companyOperatingValue}
            onChange={e => setCompanyOperatingValue(e.target.value)}
          >
            <option value="">All</option>
            {companyOperatingStatusLists.map((option, index) => {
              return (
                <option key={index} value={option.id}>
                  {option.value}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  const renderSalesPersonField = () => {
    return (
      <Query query={GET_ALL_USERS} variables={{ size: 1000 }}>
        {({ loading, data }) => {
          const allUsers = data
          if (loading) {
            return <Loading />
          } else {
            if (allUsers && allUsers.allAdminUsers.data.adminUsers) {
              return (
                <Query query={GET_ME}>
                  {({ loading, data }) => {
                    if (loading) {
                      return <Loading />
                    } else {
                      let users = allUsers.allAdminUsers.data.adminUsers
                      return (
                        <>
                          <FormGroup row>
                            <Label lg={3} sm={12}>
                              Salesperson
                            </Label>
                            <Col md={7} sm={12}>
                              <Input
                                type="select"
                                name="salesPersonEmail"
                                value={selectedSalesPerson}
                                onChange={e =>
                                  setSelectedSalesPerson(e.target.value)
                                }
                              >
                                <option value={''}>All</option>
                                <option value={'None'}>No Salesperson</option>
                                {users.map((user, index) => {
                                  if (user.role.roleName == 'BD Team') {
                                    return (
                                      <option key={index} value={user.id}>
                                        {user.email}
                                      </option>
                                    )
                                  }
                                })}
                              </Input>
                            </Col>
                          </FormGroup>
                        </>
                      )
                    }
                  }}
                </Query>
              )
            }
          }
        }}
      </Query>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Col md="12" style={{ marginBottom: 25 }}>
        <Card>
          <CardBody>
            <Row>
              <Col lg={5} md={12}>
                <FormGroup row>
                  <Label lg={3} md={6} sm={12}>
                    Display Name:
                  </Label>
                  <Col lg={8} md={6} sm={12}>
                    <Input
                      name="name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </Col>
                </FormGroup>
              </Col>

              <Col lg={5} md={12}>
                <FormGroup row>
                  <Label lg={3} md={6} sm={12}>
                    Legal Name:
                  </Label>
                  <Col lg={8} md={6} sm={12}>
                    <Input
                      name="legalName"
                      value={legalName}
                      onChange={e => setLegalName(e.target.value)}
                    />
                  </Col>
                </FormGroup>
              </Col>

              <Col lg={2} md={2}>
                <Button type="submit" color="info" onClick={handleSearch}>
                  Search
                </Button>
              </Col>

              <Col md={5} sm={12}>
                {renderCountryField()}
              </Col>

              <Col md={5} sm={12}>
                {renderCompanyTypeField()}
              </Col>

              <Col md={5} sm={12}>
                {renderCompanyOperatingStatusListsField()}
              </Col>

              <Col md={5} sm={12}>
                {renderSalesPersonField()}
              </Col>

              <Col md={5} sm={12}>
                <FormGroup row>
                  <Label md={2} sm={12}>
                    Date
                  </Label>
                  <Col md={5} sm={12}>
                    <DateTimePicker
                      format="MMM D, YYYY"
                      time={false}
                      value={fromDate}
                      placeholder="From"
                      onChange={newDate => {
                        setFromDate(newDate)
                      }}
                    />
                  </Col>
                  <Col md={5} sm={12}>
                    <DateTimePicker
                      format="MMM D, YYYY"
                      time={false}
                      value={toDate}
                      placeholder="To"
                      onChange={newDate => setToDate(newDate)}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Form>
  )
}

export default withRouter(SearchBar)
