exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".navigation__25zHM {\n  margin-bottom: 25px;\n}\n\n.menu__Ke4n3 {\n  padding: 10px 15px;\n  font-weight: bold;\n  color: #2f353a;\n}\n.menuActive__294d8 {\n  background: #20a8d8;\n  color: #fff;\n  border-radius: 5px;\n  pointer-events: none;\n  cursor: default;\n}\n.menuList__etU6i {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}", "", {"version":3,"sources":["/app/src/modules/bossjob_users/views/BossJobUserDetail/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAmB;CACrB;;AAEA;EACE,mBAAkB;EAClB,kBAAiB;EACjB,eAAc;CAChB;AACA;EACE,oBAAmB;EACnB,YAAW;EACX,mBAAkB;EAClB,qBAAoB;EACpB,gBAAe;CACjB;AACA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,0BAA8B;MAA9B,uBAA8B;UAA9B,+BAA8B;CAChC","file":"Navigation.module.scss","sourcesContent":[".navigation {\n  margin-bottom: 25px;\n}\n\n.menu {\n  padding: 10px 15px;\n  font-weight: bold;\n  color: #2f353a;\n}\n.menuActive {\n  background: #20a8d8;\n  color: #fff;\n  border-radius: 5px;\n  pointer-events: none;\n  cursor: default;\n}\n.menuList {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"navigation": "navigation__25zHM",
	"menu": "menu__Ke4n3",
	"menuActive": "menuActive__294d8",
	"menuList": "menuList__etU6i"
};