import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_COMPANY_DETAIL } from '../../../schemas/queries'
import {
  UPDATE_LOCAL_COMPANY_DETAIL,
  UPDATE_COMPANY_DETAIL,
  UPLOAD_COMPANY_LOGO,
  UPDATE_COMPANY_COVER
} from '../../../schemas/mutations'

/* Components */
import CroppingImageTool from 'shared/components/CroppingImageTool'
import CompanyInformationForm from '../../../views/CompanyDetail/CompanyInformation/CompanyInformationForm'

/* Vendors */
import imageCompression from 'browser-image-compression'

/**
 * CompanyInformationContainer - container component to query and mutate
 * company information and document and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class CompanyInformationContainer extends Component {
  static propTypes = {
    config: PropTypes.object,
    canEdit: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      uploadType: '',
      isUpdating: false,
      imageFile: null,
      companyCoverFile: null,
      isCroppingVisible: false,
      imagePreviewUrl: '',
      companyCoverPreviewUrl: '',
      fileType: '',
      updateCompanyError: ''
    }

    this.saveCompanyDetail = this.saveCompanyDetail.bind(this)
    this.handleCroppingVisibility = this.handleCroppingVisibility.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleCroppingImage = this.handleCroppingImage.bind(this)
  }

  /**
   *
   * @function handleUserInputUpdate - handle when user updating the field
   * and it will trigger the local data mutation
   *
   * @param apolloClient
   * @param key
   * @param value
   */
  handleUserInputUpdate(apolloClient, key, value) {
    return apolloClient.mutate({
      mutation: UPDATE_LOCAL_COMPANY_DETAIL,
      variables: {
        key: key,
        value: value
      }
    })
  }

  /**
   *
   * @function saveCompanyDetail - handle when user updating the field
   * and it will trigger the network mutation
   *
   * @param apolloClient
   */
  async saveCompanyDetail(apolloClient) {
    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    // front end validation
    if (
      companyDetail.name.includes('+') ||
      companyDetail.legalName.includes('+')
    ) {
      return this.setState({
        updateCompanyError: 'The name contains restricted characters: +'
      })
    }

    if (
      companyDetail.industryKey === (null || '') ||
      companyDetail.financingStageId === (null || '') ||
      companyDetail.countryKey === (null || '') ||
      companyDetail.companySizeKey === (null || '')
    ) {
      return this.setState({
        updateCompanyError:
          'Please ensure that you have selected a value for all dropdown lists'
      })
    }

    this.setState({
      isUpdating: true
    })

    const formPayload = {
      name: companyDetail.name,
      legalName: companyDetail.legalName,
      industryKey: companyDetail.industryKey
        ? companyDetail.industryKey
        : 'accounting_and_finance',
      countryKey: companyDetail.countryKey ? companyDetail.countryKey : 'ph',
      locationKey: companyDetail.locationKey
        ? companyDetail.locationKey
        : 'manila',
      registeredCountryId: companyDetail.registeredCountryId,
      companySizeKey: companyDetail.companySizeKey
        ? companyDetail.companySizeKey
        : '0_to_50',
      financingStageId: companyDetail.financingStageId
        ? companyDetail.financingStageId
        : '1',
      website: companyDetail.website,
      phoneNum: companyDetail.phoneNum,
      address: companyDetail.address,
      companyNumber: companyDetail.companyCountryNumber.companyNumber
    }

    let response = await apolloClient.mutate({
      mutation: UPDATE_COMPANY_DETAIL,
      variables: {
        companyId: companyDetail.id,
        payload: formPayload
      }
    })

    if (
      response.data &&
      response.data.updateCompany &&
      !response.data.updateCompany.ok
    ) {
      return this.setState({
        updateCompanyError: response.data.updateCompany.message,
        isUpdating: false
      })
    }

    let responseLogo = null
    if (this.state.imageFile !== null) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true
      }
      const compressedCompanyLogo = await imageCompression(
        this.state.imageFile,
        options
      )

      responseLogo = await apolloClient.mutate({
        mutation: UPLOAD_COMPANY_LOGO,
        variables: {
          id: companyDetail.id,
          file: compressedCompanyLogo
        }
      })
    }

    let responseCompanyCover = null
    if (this.state.companyCoverFile !== null) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      const compressedCompanyCover = await imageCompression(
        this.state.companyCoverFile,
        options
      )

      responseCompanyCover = await apolloClient.mutate({
        mutation: UPDATE_COMPANY_COVER,
        variables: {
          companyId: companyDetail.id,
          coverPic: compressedCompanyCover
        }
      })
    }

    if (
      (this.state.imageFile !== null &&
        response.data.updateCompany.ok &&
        responseLogo.data.uploadCompanyLogo.ok) ||
      (this.state.imageFile === null && response.data.updateCompany.ok) ||
      (this.state.companyCoverFile !== null &&
        response.data.updateCompany.ok &&
        responseCompanyCover.data.updateCompanyCover.ok)
    ) {
      window.location.reload()
    }
  }

  /**
   *
   * @function handleCroppingVisibility - handle when user cropping or not cropping the image
   *
   * @param {Boolean} visible
   */
  handleCroppingVisibility(visible) {
    this.setState({
      isCroppingVisible: visible
    })
  }

  /**
   *
   * @function handleImageChange - handle when user select new image
   *
   * @param {File} imageFile
   */
  handleImageChange(e) {
    if (window.FileReader) {
      let file = e.target.files[0]
      let reader = new FileReader()

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
      }

      //reset input file
      e.target.value = null
      switch (e.target.name) {
        case 'companyLogo':
          reader.onloadend = () => {
            let imagePreviewUrl = reader.result
            this.setState({
              imagePreviewUrl,
              fileType: file.type,
              uploadType: 'logo'
            })
            this.handleCroppingVisibility(true)
          }
          break
        case 'companyCover':
          reader.onloadend = () => {
            let companyCoverPreviewUrl = reader.result
            this.setState({
              companyCoverPreviewUrl,
              fileType: file.type,
              uploadType: 'companyCover'
            })
            this.handleCroppingVisibility(true)
          }
          break
        default:
          break
      }
    }
  }

  /**
   *
   * @function handleCroppingImage - handle when user done with cropping image
   *
   * @param {String} url
   * @param {File} blobFile
   */
  handleCroppingImage(url, blobFile) {
    const { uploadType } = this.state
    switch (uploadType) {
      case 'logo':
        this.setState({
          imagePreviewUrl: url
        })

        if (blobFile) {
          // for passing image blob file to backend API
          this.setState({
            imageFile: blobFile
          })
        }
        break
      case 'companyCover':
        this.setState({
          companyCoverPreviewUrl: url
        })
        if (blobFile) {
          // for passing image blob file to backend API
          this.setState({
            companyCoverFile: blobFile
          })
        }
        break
      default:
        break
    }
  }

  render() {
    const { config } = this.props
    const { updateCompanyError, uploadType } = this.state
    const { canEdit } = this.props
    return (
      <Query query={GET_LOCAL_COMPANY_DETAIL}>
        {({ data: { companyDetail } }) => (
          <React.Fragment>
            {config && (
              <CompanyInformationForm
                companyData={companyDetail}
                handleChange={this.handleUserInputUpdate}
                saveCompany={this.saveCompanyDetail}
                isLoading={this.state.isUpdating}
                handleImageChange={this.handleImageChange}
                previewLogo={this.state.imagePreviewUrl}
                previewCover={this.state.companyCoverPreviewUrl}
                options={config}
                updateCompanyError={updateCompanyError}
                canEdit={canEdit}
              />
            )}

            <CroppingImageTool
              isVisible={this.state.isCroppingVisible}
              width={uploadType === 'companyCover' ? 400 : 250}
              height={uploadType === 'companyCover' ? 79.07 : 250}
              handleCloseModal={this.handleCroppingVisibility}
              imageSrc={
                uploadType === 'logo'
                  ? this.state.imagePreviewUrl
                  : this.state.companyCoverPreviewUrl
              }
              fileType={this.state.fileType}
              handleCroppingImage={this.handleCroppingImage}
            />
          </React.Fragment>
        )}
      </Query>
    )
  }
}

export default CompanyInformationContainer
