/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button } from 'reactstrap'

/* Apollo */
import { withApollo, compose, graphql } from 'react-apollo'
import { ADD_OFFLINE_TRANSACTION } from '../../../../../schemas/mutations'

/**
 * SubmitButton - Presentational Component to Update InvoiceDetail
 *
 * @returns {Component}
 */
class SubmitButton extends Component {
  static propTypes = {
    transactionDetailForNewForm: PropTypes.object,
    addOfflineTransaction: PropTypes.func.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }

  handleClick = () => {
    const { transactionDetail, addOfflineTransaction, userId } = this.props

    const { transactionDetailForNewForm } = transactionDetail

    let bankAccountId = transactionDetailForNewForm.bankAccountId
    if (
      transactionDetailForNewForm &&
      transactionDetailForNewForm.paymentMethodId &&
      transactionDetailForNewForm.paymentMethodId == 2
    ) {
      bankAccountId = null
    }
    addOfflineTransaction({
      variables: {
        userId,
        transactionInput: {
          jobPost: transactionDetailForNewForm.jobPost,
          unlockTalentCredit: transactionDetailForNewForm.unlockTalentCredit,
          rhTalentCredit: transactionDetailForNewForm.rhTalentCredit,
          featureJobCredit: transactionDetailForNewForm.featureJobCredit,
          urgentJobCredit: transactionDetailForNewForm.urgentJobCredit,
          bosspoint: transactionDetailForNewForm.bosspoint,
          // to remove isServiceGuaranteed & isOnlineSupported field when it's removed on backend
          isServiceGuaranteed: true,
          isOnlineSupported: true,
          amount: transactionDetailForNewForm.amount,
          itemName: transactionDetailForNewForm.itemName,
          itemDescription: transactionDetailForNewForm.itemDescription,
          billerEmail: transactionDetailForNewForm.billerEmail,
          billerFirstName: transactionDetailForNewForm.billerFirstName,
          billerLastName: transactionDetailForNewForm.billerLastName,
          billerAddress: transactionDetailForNewForm.billerAddress,
          billerCompany: transactionDetailForNewForm.billerCompany,
          billerCity: transactionDetailForNewForm.billerCity,
          billerState: transactionDetailForNewForm.billerState,
          billerZipcode: transactionDetailForNewForm.billerZipcode,
          billerCountry: transactionDetailForNewForm.billerCountry,
          remark: transactionDetailForNewForm.remark,
          salesPersonEmail: transactionDetailForNewForm.salesPersonEmail,
          creditTerm: Number(transactionDetailForNewForm.creditTerm),
          taxType: transactionDetailForNewForm.taxType,
          taxRate: Number(transactionDetailForNewForm.taxRate),
          currency: transactionDetailForNewForm.currency,
          currencyExchangeRate: Number(
            transactionDetailForNewForm.currencyExchangeRate
          ),
          paymentMethodId:
            transactionDetailForNewForm &&
            transactionDetailForNewForm.paymentMethodId
              ? Number(transactionDetailForNewForm.paymentMethodId)
              : null,
          bankAccountId
        }
      }
    })
      .then(response => response.data.addOfflineTransaction)
      .then(status => {
        if (status.ok)
          window.location.href = `/bossjob/user/${userId}/recruiter/transaction/1`
      })
      .catch(err => console.log(err))
  }

  render() {
    return (
      <Button
        style={{ marginBottom: '15px' }}
        color="info"
        onClick={this.handleClick}
      >
        Submit
      </Button>
    )
  }
}

export default compose(
  withApollo,
  graphql(ADD_OFFLINE_TRANSACTION, { name: 'addOfflineTransaction' })
)(SubmitButton)
