import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'
import {
  UPDATE_BOSSJOB_USER_PREFERENCES,
  ADD_BOSSJOB_USER_PREFERENCES,
  DELETE_BOSSJOB_USER_PREFERENCES
} from '../../../../../schemas/mutations'
import { uniqBy } from 'lodash-es'
/* Bootstrap */
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert
} from 'reactstrap'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'
import {
  getSalariesTypeOptions,
  getCurrentShouldShowObject,
  getSalaryOption
} from '../../../../../../../shared/helpers/useSalariesUtil'

/* Assets */
import styles from './JobPreferenceForm.module.scss'

/**
 * JobPreferenceForm - form component for BossJobUserDetail - Resume page - job preference section
 *
 * @returns {Component}
 */
class JobPreferenceForm extends Component {
  constructor(props) {
    super(props)
    console.log(props.jobPreference, 'jobPreference')
    const { jobPreference } = props

    const {
      id: prefId = '',
      jobTitleId: jobTitleId = '',
      // locationKey: location = 'manila',
      countryKey: country = 'ph',
      jobTitle: jobTitle = '',
      industryKey: industry = '',
      salaryRangeFrom: salaryFrom = 10000,
      salaryRangeTo: salaryTo = 15000,
      jobTypeKey: jobType = 'full_time',
      // bbc: job = 'full_time',
      salaryTypeId = 1,

      dailySalaryRangeFrom,
      dailySalaryRangeTo,
      hourlySalaryRangeFrom,
      hourlySalaryRangeTo,
      salaryRangeFrom,
      salaryRangeTo,
      currencyId = 1,
      locationId,
      prefRegionId,
      prefCountryId,
      locationKey
    } = props.jobPreference || {}

    this.state = {
      messageObj: { isVisible: false, error: false, message: '' },
      locations: [],
      jobFunctionQuery: '',
      locationId,
      prefRegionId,
      prefCountryId,
      locationKey,
      prefId: prefId ? prefId : '',
      jobTitleId: jobTitleId ? jobTitleId : '',
      location: '',
      country: country ? country : 'ph',
      jobTitle: jobTitle ? jobTitle : '',
      industry: industry ? industry : '',
      salaryFrom: salaryFrom ? salaryFrom : 10000,
      salaryTo: salaryTo ? salaryTo : 15000,
      jobType: jobType ? jobType : 'full_time',

      salaryTypeId: salaryTypeId,
      jobPreference: jobPreference,
      dailySalaryRangeFrom: dailySalaryRangeFrom
        ? Number(dailySalaryRangeFrom)
        : null,
      dailySalaryRangeTo: dailySalaryRangeTo
        ? Number(dailySalaryRangeTo)
        : null,
      hourlySalaryRangeFrom: hourlySalaryRangeFrom
        ? Number(hourlySalaryRangeFrom)
        : null,
      hourlySalaryRangeTo: hourlySalaryRangeTo
        ? Number(hourlySalaryRangeTo)
        : null,
      salaryRangeFrom: salaryRangeFrom ? Number(salaryRangeFrom) : null,
      salaryRangeTo: salaryRangeTo ? Number(salaryRangeTo) : null,
      currencyId: currencyId
    }

    this.addOrEditJobPreferences = this.addOrEditJobPreferences.bind(this)
  }

  /**
   *
   * @function renderSalaryOption - render salary options with the corresponding value
   *
   * @param {Number} start
   * @param {Number} end
   * @returns {Component}
   */
  renderSalaryOption(start, end) {
    let salaryOptions = []

    for (let salary = start; salary <= end; salary += 5000) {
      salaryOptions.push(salary)
    }

    return salaryOptions.map(salary => (
      <option key={`salary-start-${salary}`} value={salary}>
        {salary}
      </option>
    ))
  }

  /**
   *
   * @function addOrEditJobPreferences - asynchronous edit function to call GraphQL mutation
   * which will refetch the list after edit is done
   *
   * @param {*} apolloClient
   */
  async addOrEditJobPreferences(apolloClient) {
    const {
      salaryTypeId,
      dailySalaryRangeFrom,
      dailySalaryRangeTo,
      hourlySalaryRangeFrom,
      hourlySalaryRangeTo,
      salaryRangeFrom,
      salaryRangeTo,

      jobTitle,
      jobTitleId,
      industry,
      jobType,
      location,
      country
    } = this.state

    let payload = {
      jobTitle: jobTitle,
      jobTitleId: jobTitleId !== '' ? jobTitleId : null,
      industryKey: industry,
      jobTypeKey: jobType,
      //  locationKey: location,
      countryKey: country,
      currencyKey: 'php',

      //Forgive me such do on the code, otherwise I will have so much work, please
      currencyId: 1,
      workArrangementId: 1,

      salaryTypeId: salaryTypeId
    }

    const locationArr = (location && location.split('-')) || []

    if (locationArr.length > 0) {
      const type = locationArr[0]
      const id = locationArr[1]
      const keyMap = {
        country: 'prefCountryId',
        region: 'prefRegionId',
        location: 'locationId'
      }
      const key = keyMap[type] || 'locationId'
      payload[key] = id
    }

    if (salaryTypeId === 1) {
      payload.salaryRangeFrom = parseInt(salaryRangeFrom)
      payload.salaryRangeTo = parseInt(salaryRangeTo)
    }

    if (salaryTypeId === 2) {
      payload.dailySalaryRangeFrom = parseInt(dailySalaryRangeFrom)
      payload.dailySalaryRangeTo = parseInt(dailySalaryRangeTo)
    }

    if (salaryTypeId === 3) {
      payload.hourlySalaryRangeFrom = parseInt(hourlySalaryRangeFrom)
      payload.hourlySalaryRangeTo = parseInt(hourlySalaryRangeTo)
    }

    let mutation = ADD_BOSSJOB_USER_PREFERENCES

    if (this.props.jobPreference && this.props.jobPreference.id) {
      payload.prefId = this.props.jobPreference.id
      mutation = UPDATE_BOSSJOB_USER_PREFERENCES
    }

    let response = await apolloClient.mutate({
      mutation: mutation,
      variables: {
        userId: this.props.userId,
        payload: payload
      }
    })

    if (response) {
      if (this.props.jobPreference) {
        const message = 'Updated job preference'
        this.setState({
          messageObj: { isVisible: true, error: false, message: message }
        })
      } else {
        this.props.successAdding()
      }
    }
  }

  /**
   *
   * @function deleteJobPreferences - asynchronous edit function to call GraphQL mutation
   * which will refetch the list after edit is done
   *
   * @param {*} apolloClient
   */
  async deleteJobPreferences(apolloClient, prefId) {
    let mutation = DELETE_BOSSJOB_USER_PREFERENCES

    let response = await apolloClient.mutate({
      mutation: mutation,
      variables: {
        userId: this.props.userId,
        prefId: prefId
      }
    })

    if (response) {
      window.alert('Successfully deleted job preference')
      this.props.refetch()
    }
  }

  initCountryList(countryLists) {
    const findLists = countryLists.find(e => e.key === this.state.country)
    const regionLists = findLists.region_lists.map(e => ({
      ...e,
      id: 'region-' + e.id,
      type: 'region'
    }))

    const uniArr = uniqBy(regionLists, 'id')
    const list = []
    list.push({
      id: 'country-' + findLists.id,
      key: findLists.key,
      label: findLists.value,
      value: findLists.value,
      type: 'country'
    })
    uniArr.map(e => {
      list.push({
        id: e.id,
        key: e.key,
        label: e.value || e.display_name,
        value: e.id,
        type: e.type || 'region'
      })

      if (e.location_lists && e.location_lists.length > 0) {
        e.location_lists.map(k => {
          list.push({
            id: 'location-' + k.id,
            value: k.id,
            key: k.key,
            type: 'location',
            label: `${k.value} (${e.value || e.display_name})`
          })
        })
      }
    })
    return list
  }

  render() {
    const {
      messageObj,
      prefId,
      jobTitleId,
      location,
      country,
      jobTitle,
      industry,
      jobType,
      jobFunctionQuery,
      jobPreference,
      salaryTypeId,
      locationId,
      prefRegionId,
      prefCountryId,
      locationKey
    } = this.state

    const {
      industryList,
      mainJobFunctionList,
      countryLists
    } = this.props.options

    const countryListsNew = this.initCountryList(countryLists)

    let newLocation =
      location ||
      (prefCountryId
        ? `country-${prefCountryId}`
        : prefRegionId
          ? `region-${prefRegionId}`
          : locationId || locationKey
            ? `location-${locationId || locationKey}`
            : '')

    const { currencyId = 1 } = jobPreference || {}
    const {
      currencyIdToSalaryRangesMap,
      currentSalaryFrom,
      currentSalaryTo,
      fromName,
      toName
    } = getCurrentShouldShowObject(salaryTypeId, this.state)

    return (
      <Form className={styles.formWrapper}>
        {messageObj.isVisible && (
          <Alert color={messageObj.error ? 'danger' : 'success'}>
            {messageObj.message}
          </Alert>
        )}
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Job Function Name
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="text"
                  name="jobFunctionQuery"
                  id="jobFunctionQuery"
                  placeholder="Enter job function title here to filter"
                  value={jobFunctionQuery}
                  onChange={e =>
                    this.setState({ jobFunctionQuery: e.target.value })
                  }
                />
                <Input
                  type="select"
                  name="jobTitleId"
                  value={jobTitleId}
                  onChange={e =>
                    this.setState({
                      jobTitleId: e.target.value
                    })
                  }
                >
                  {mainJobFunctionList &&
                    mainJobFunctionList.map(mainFunctionObj => {
                      const mainFunction = mainFunctionObj.value
                      const subFunctionObjs = mainFunctionObj.sub_function_list
                      const filteredJobTitles =
                        jobFunctionQuery !== ''
                          ? subFunctionObjs.flatMap(subFunctionObj =>
                              subFunctionObj.job_titles.filter(
                                jobTitle =>
                                  jobTitle.value
                                    .toLowerCase()
                                    .includes(jobFunctionQuery.toLowerCase()) ||
                                  jobTitle.id == jobTitleId
                              )
                            )
                          : subFunctionObjs.flatMap(
                              subFunctionObj => subFunctionObj.job_titles
                            )
                      return subFunctionObjs.map((subFunctionObj, i) => {
                        return subFunctionObj.job_titles.map(
                          (jobTitle, idx) => {
                            return (
                              <React.Fragment key={`jobFunction_${i}_${idx}`}>
                                {i === 0 &&
                                  idx === 0 &&
                                  jobTitleId === '' && (
                                    <option key={'not_selected'} value={''}>
                                      Not selected
                                    </option>
                                  )}
                                {idx === 0 &&
                                  subFunctionObj.job_titles.some(title =>
                                    title.value
                                      .toLowerCase()
                                      .includes(jobFunctionQuery.toLowerCase())
                                  ) && (
                                    <option
                                      key={`subFunction_${
                                        subFunctionObj.value
                                      }`}
                                      disabled
                                    >
                                      {mainFunction} - {subFunctionObj.value}
                                    </option>
                                  )}
                                {filteredJobTitles.includes(jobTitle) ? (
                                  <option
                                    key={`jobTitleId_${jobTitle.id}`}
                                    value={jobTitle.id}
                                  >
                                    {jobTitle.value}
                                  </option>
                                ) : null}
                              </React.Fragment>
                            )
                          }
                        )
                      })
                    })}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Job Title
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  name="jobTitle"
                  value={jobTitle}
                  onChange={e => this.setState({ jobTitle: e.target.value })}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Industry
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="select"
                  name="industry"
                  value={industry}
                  onChange={e => this.setState({ industry: e.target.value })}
                >
                  {industryList &&
                    industryList.map((industry, index) => (
                      <option key={`industry-${index}`} value={industry.key}>
                        {industry.value}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup row>
              <Label md={2} sm={12}>
                Job Type
              </Label>
              <Col md={9} sm={12}>
                <FormGroup
                  check
                  style={{ display: 'inline-block', marginRight: '1.5em' }}
                >
                  <Label check>
                    <Input
                      disabled={this.props.isInactive}
                      type="radio"
                      name="jobType"
                      value="full_time"
                      checked={jobType === 'full_time'}
                      onChange={e => this.setState({ jobType: e.target.value })}
                    />
                    Full Time
                  </Label>
                </FormGroup>
                <FormGroup
                  check
                  style={{ display: 'inline-block', marginRight: '1.5em' }}
                >
                  <Label check>
                    <Input
                      disabled={this.props.isInactive}
                      type="radio"
                      name="jobType"
                      value="freelance"
                      checked={jobType === 'freelance'}
                      onChange={e => this.setState({ jobType: e.target.value })}
                    />
                    Freelance
                  </Label>
                </FormGroup>
                <FormGroup
                  check
                  style={{ display: 'inline-block', marginRight: '1.5em' }}
                >
                  <Label check>
                    <Input
                      disabled={this.props.isInactive}
                      type="radio"
                      name="jobType"
                      value="part_time"
                      checked={jobType === 'part_time'}
                      onChange={e => this.setState({ jobType: e.target.value })}
                    />
                    Part Time
                  </Label>
                </FormGroup>
                <FormGroup
                  check
                  style={{ display: 'inline-block', marginRight: '1.5em' }}
                >
                  <Label check>
                    <Input
                      disabled={this.props.isInactive}
                      type="radio"
                      name="jobType"
                      value="internship"
                      checked={jobType === 'internship'}
                      onChange={e => this.setState({ jobType: e.target.value })}
                    />
                    Internship
                  </Label>
                </FormGroup>
                <FormGroup
                  check
                  style={{ display: 'inline-block', marginRight: '1.5em' }}
                >
                  <Label check>
                    <Input
                      disabled={this.props.isInactive}
                      type="radio"
                      name="jobType"
                      value="contract"
                      checked={jobType === 'contract'}
                      onChange={e => this.setState({ jobType: e.target.value })}
                    />
                    Contract
                  </Label>
                </FormGroup>
                <FormGroup
                  check
                  style={{ display: 'inline-block', marginRight: '1.5em' }}
                >
                  <Label check>
                    <Input
                      disabled={this.props.isInactive}
                      type="radio"
                      name="jobType"
                      value="temp"
                      checked={jobType === 'temp'}
                      onChange={e => this.setState({ jobType: e.target.value })}
                    />
                    Temp
                  </Label>
                </FormGroup>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Location
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="select"
                  name="location"
                  value={newLocation}
                  onChange={e => {
                    this.setState({ location: e.target.value })
                  }}
                >
                  {/* {countryLists
                    .filter(country => country.key === this.state.country)[0]
                    .region_lists.map(region =>
                      region.location_lists.map(location => (
                        <option
                          key={`location-${location.id}`}
                          value={location.key}
                        >
                          {location.value} ({region.display_name})
                        </option>
                      ))
                    )} */}
                  {countryListsNew.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Country
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="select"
                  name="country"
                  value={country}
                  onChange={e => this.setState({ country: e.target.value })}
                  disabled={true}
                >
                  {countryLists
                    .filter(country => country.is_bossjob_supported)
                    .map((country, index) => (
                      <option key={`country-${index}`} value={country.key}>
                        {country.value}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Salary Type
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="select"
                  name="salaryTypeId"
                  value={salaryTypeId}
                  onChange={e => {
                    this.setState({
                      salaryTypeId: Number(e.target.value)
                    })
                  }}
                >
                  {getSalariesTypeOptions(jobType).map((item, index) => (
                    <option key={`country-${index}`} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Salary From
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="select"
                  name="salaryFrom"
                  value={currentSalaryFrom} //
                  onChange={e => {
                    this.setState({
                      [fromName]: Number(e.target.value),
                      [toName]:
                        Number(e.target.value) +
                        Number(currencyIdToSalaryRangesMap[currencyId].interval)
                    })
                  }}
                >
                  {/* {this.renderSalaryOption(10000, 200000)} */}
                  {currencyIdToSalaryRangesMap &&
                    getSalaryOption(
                      currencyIdToSalaryRangesMap[currencyId],
                      'lower'
                    ).map(salary => (
                      <option key={`salary-start-${salary}`} value={salary}>
                        {salary}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Salary To
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="select"
                  name="salaryTo"
                  value={currentSalaryTo}
                  onChange={e =>
                    this.setState({ [toName]: Number(e.target.value) })
                  }
                >
                  {/* {this.renderSalaryOption(
                    Number(salaryFrom) + 5000,
                    Number(salaryFrom) * 2
                  )} */}
                  {currencyIdToSalaryRangesMap &&
                    getSalaryOption(
                      currencyIdToSalaryRangesMap[currencyId],
                      'upper'
                    )
                      .filter(
                        salary =>
                          salary > currentSalaryFrom &&
                          salary <= currentSalaryFrom * 2
                      )
                      .map(salary => (
                        <option key={`salary-end-${salary}`} value={salary}>
                          {salary}
                        </option>
                      ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {!this.props.isInactive && (
          <Can I="edit" a="bossjob_user">
            <ApolloConsumer>
              {client => {
                return (
                  <React.Fragment>
                    {this.props.new ? (
                      <React.Fragment>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            color="danger"
                            onClick={() => this.props.cancelAdding()}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="info"
                            onClick={() => this.addOrEditJobPreferences(client)}
                          >
                            Add New
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            color="danger"
                            onClick={() => {
                              if (
                                confirm(
                                  'Confirm to delete selected job preference?'
                                )
                              ) {
                                this.deleteJobPreferences(client, prefId)
                              }
                            }}
                          >
                            Delete
                          </Button>
                          <Button
                            color="info"
                            onClick={() => this.addOrEditJobPreferences(client)}
                          >
                            Save
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )
              }}
            </ApolloConsumer>
          </Can>
        )}
      </Form>
    )
  }
}

JobPreferenceForm.propTypes = {
  jobPreference: PropTypes.object,
  userId: PropTypes.number,
  refetch: PropTypes.func,
  isInactive: PropTypes.bool,
  new: PropTypes.bool,
  cancelAdding: PropTypes.func,
  successAdding: PropTypes.func,
  options: PropTypes.object
}

export default JobPreferenceForm
