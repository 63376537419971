import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@googlemaps/js-api-loader'

import { Button, Modal, ModalFooter } from 'reactstrap'

/* Config */
import CONFIG from 'config'

/* import './GoogleMap.css' */
import styles from './GoogleMap.module.scss'

const loader = new Loader({
  apiKey: CONFIG.GOOGLE_MAP_API_KEY,
  version: 'weekly',
  libraries: ['places']
})

const GoogleMap = ({
  handleCancel,
  handleOk,
  isModalOpen,
  defaultAddress,
  lat,
  lng
}) => {
  let marker
  const search = useRef()
  const searchCart = useRef()
  const mapInput = useRef()
  const [address, setAddress] = useState(null)

  useEffect(
    () => {
      if (!isModalOpen) {
        return
      }

      loader.load().then(() => {
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: {
            lat: Number(lat) || 1.283041,
            lng: Number(lng) || 103.829056
          },
          zoom: 16,
          disableDefaultUI: true,
          // fullscreenControl: true,
          zoomControl: true
        })

        // initMaerker
        if (lat && lng) {
          placeMarkerAndPanTo({ lat, lng }, map)
        }

        initInput(map)
        initSearchCart(map)

        // const input = inputDom as HTMLInputElement

        // const options = {
        //   fields: ['address_components', 'geometry', 'icon', 'name'],
        //   strictBounds: false,
        //   types: ['establishment']
        // }
        // const autocomplete = new google.maps.places.Autocomplete(input, options)

        // autocomplete.addListener('place_changed', () => {
        //   const placeResult = autocomplete.getPlace()
        //   setAddress(placeResult)
        //   map.setCenter(placeResult.geometry?.location)
        //   placeMarkerAndPanTo(placeResult.geometry?.location, map)
        // })

        search.current = new window.google.maps.places.PlacesService(map)

        map.addListener('click', ev => {
          placeMarkerAndPanTo(ev.latLng, map)
          if (ev.placeId) {
            searchPlaceDetail(ev.placeId, map)
          } else {
            searchPlaceList(ev.latLng, map)
          }
        })
      })
    },
    [isModalOpen]
  )

  const placeMarkerAndPanTo = (latLng, map) => {
    if (marker) {
      marker.setMap(null)
    }
    marker = new window.google.maps.Marker({
      position: latLng,
      map: map
    })
  }

  const searchPlace = (ev, map) => {
    if (ev.target.value) {
      // var request = {
      //   query: ev.target?.value,
      //   fields: ['ALL']
      // }
      // search.current.findPlaceFromQuery(request, function (results, status) {
      //   if (status === google.maps.places.PlacesServiceStatus.OK) {
      //     renderSearchPlaceList(results, map)
      //   }
      // })
      let request = {
        query: ev.target.value
      }
      search.current.textSearch(request, function(results, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          renderSearchPlaceList(results, map)
        }
      })
    }
  }

  const searchPlaceDetail = (placeId, map) => {
    const request = {
      placeId: placeId,
      fields: ['ALL']
    }

    const service = new window.google.maps.places.PlacesService(map)
    service.getDetails(request, callback)

    function callback(place, status) {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        mapInput.current.value = place.name + place.formatted_address
        setAddress(place)
        map.setCenter(place.geometry.location)
      }
    }
  }

  const searchPlaceList = (latLng, map) => {
    var pyrmont = new window.google.maps.LatLng(latLng.lat(), latLng.lng())
    var request = {
      location: pyrmont,
      rankBy: window.google.maps.places.RankBy.DISTANCE,
      type: ['All']
    }

    const service = new window.google.maps.places.PlacesService(map)
    service.nearbySearch(request, callback)

    function callback(results, status) {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        mapInput.current.value = ''
        renderSearchPlaceList(results, map)
      }
    }
  }

  const initInput = map => {
    mapInput.current = document.createElement('input')
    mapInput.current.type = 'text'
    mapInput.current.placeholder = 'Search map'
    mapInput.current.value = defaultAddress ? defaultAddress : ''
    mapInput.current.id = 'pac-input'
    mapInput.current.style.marginTop = '29px'
    mapInput.current.style.marginLeft = '37px'
    mapInput.current.style.paddingLeft = '15px'
    mapInput.current.style.width = '312px'
    mapInput.current.style.height = '38px'
    mapInput.current.style.fontSize = '14px'
    mapInput.current.addEventListener('focus', () => {
      (searchCart.current.innerHTML = ''),
      (mapInput.current.placeholder = 'Search map')
    })
    mapInput.current.addEventListener('input', ev => searchPlace(ev, map))
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      mapInput.current
    )
  }

  const initSearchCart = map => {
    searchCart.current = document.createElement('div')
    searchCart.current.id = 'place-list-wrapper'
    searchCart.current.style.backgroundColor = '#fff'
    searchCart.current.style.marginTop = '2px'
    searchCart.current.style.marginLeft = '37px'
    searchCart.current.style.width = '312px'
    searchCart.current.style.borderRadius = '10px'
    searchCart.current.style.paddingLeft = '24px'
    searchCart.current.style.paddingRight = '24px'
    searchCart.current.style.maxHeight = '159px'
    searchCart.current.style.overflowY = 'auto'
    map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(
      searchCart.current
    )
  }

  const renderSearchPlaceList = (placeList, map) => {
    (mapInput.current.placeholder = 'Please select your location in the list'),
    (searchCart.current.innerHTML = '')

    const fragment = document.createDocumentFragment()
    for (let i = 0; i < placeList.length && i < placeList.length; i++) {
      const li = document.createElement('li')
      const span = document.createElement('span')

      li.innerText = placeList[i].name
      li.style.width = '100%'
      li.style.height = '38px'
      li.style.lineHeight = '38px'
      li.style.fontSize = '14px'
      li.style.whiteSpace = 'nowrap'
      li.style.textOverflow = 'ellipsis'
      li.style.overflow = 'hidden'
      li.style.cursor = 'pointer'
      li.addEventListener('click', () => handleSelectPlace(placeList[i], map))

      if (placeList[i].vicinity || placeList[i].formatted_address) {
        span.innerText = placeList[i].vicinity || placeList[i].formatted_address
        span.style.color = '#707070'
        span.style.paddingLeft = '4px'

        li.appendChild(span)
      }
      fragment.appendChild(li)
    }

    searchCart.current.appendChild(fragment)
  }

  const handleSelectPlace = (place, map) => {
    placeMarkerAndPanTo(place.geometry.location, map)

    if (place.place_id) {
      searchPlaceDetail(place.place_id, map)
    } else {
      mapInput.current.value = place.name + place.formatted_address
      setAddress(place)
    }
  }

  return (
    <div>
      <Modal
        className={styles.mapWrapper}
        isOpen={isModalOpen}
        onClosed={() => handleCancel()}
      >
        <div id="map" style={{ width: '100%', height: 'calc(100vh - 70px)' }} />

        <ModalFooter>
          <Button onClick={() => handleCancel()}>Cancel</Button>
          <Button
            onClick={() => {
              handleOk(address)
              setAddress(null)
            }}
            disabled={!address}
            type="primary"
          >
            Next
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

GoogleMap.propTypes = {
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  isModalOpen: PropTypes.bool,
  defaultAddress: PropTypes.string,
  lat: PropTypes.string,
  lng: PropTypes.string
}

export default GoogleMap
