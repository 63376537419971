exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".container__2TrOc {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n\n.addFormButton__2iGHN {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-item-align: end;\n      align-self: flex-end;\n}", "", {"version":3,"sources":["/app/src/modules/marketing/views/ProductFeatureAnnouncement/ProductFeatureAnnouncementContainer/ProductFeatureAnnouncementContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,6BAAsB;EAAtB,8BAAsB;MAAtB,2BAAsB;UAAtB,uBAAsB;CACxB;;AAEA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,yBAAoB;MAApB,qBAAoB;CACtB","file":"ProductFeatureAnnouncementContainer.module.scss","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n}\n\n.addFormButton {\n  display: flex;\n  align-self: flex-end;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "container__2TrOc",
	"addFormButton": "addFormButton__2iGHN"
};