import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
// import { GET_ALL_BOSSJOB_USERS } from '../../schemas/queries'
import { GET_ALL_EVENTS } from '../../schemas/queries'
import { UPDATE_EVENT_REQUEST } from '../../schemas/mutations'

/* Components */
import BossHuntUserList from '../../views/BossHuntUsers/BossHuntUserList'
import Loading from 'shared/components/Loading'

/**
 * BossHuntUserListContainer - container component to query Bossjob user list
 * and return BossHuntUserList component when is done
 *
 * @returns {Component}
 *
 */
class BossHuntUserListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const {
      userId,
      search,
      withdrawnPoint,
      fromDate,
      toDate,
      status
    } = queryString.parse(location.search)

    console.log({ search, fromDate, toDate, status })
    // const queryJSON = queryString.parse(this.props.queryString)
    // console.log('queryJSON',queryJSON)
    let isActive
    const map = {
      Rejected: '3',
      Approve: '2',
      Pending: '1',
      Completed: '4',
      undefined: null
    }
    let variables = {
      page: parseInt(this.state.page),
      userId,
      transactionStatusId: map[status],
      withdrawnPoint,
      createdDate:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null
      // isActive
    }
    console.log({
      variables,
      createdDate:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null,
      isActive,
      GET_ALL_EVENTS
    })

    return (
      <Query query={GET_ALL_EVENTS} variables={variables}>
        {res => {
          // console.log('GET_ALL_EVENTS ',res)
          const { loading } = res
          const { eventTransactionRequestList } = res.data
          if (loading) {
            return <Loading />
          }
          if (eventTransactionRequestList && eventTransactionRequestList.data) {
            return (
              <BossHuntUserList
                headhunterList={
                  eventTransactionRequestList.data.eventTransactionRequests
                }
                currentPage={Number(this.state.page)}
                totalHeadhunters={eventTransactionRequestList.data.totalNum}
                totalPages={eventTransactionRequestList.data.totalPages}
                {...this.props}
                refetch={res.refetch}
              />
            )
          }
          return null
        }}
      </Query>
    )
  }
}

export default compose(
  graphql(UPDATE_EVENT_REQUEST, {
    name: 'updateEventTransactionRequest'
  })
)(withRouter(BossHuntUserListContainer))
