exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".editIcon__2EYiX {\n  background-color: white;\n}\n\n.selectCheckBox__39Xrv {\n  text-align: center;\n}", "", {"version":3,"sources":["/app/src/modules/jobs/views/Jobs/JobsList/JobsList.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAuB;CACzB;;AAEA;EACE,mBAAkB;CACpB","file":"JobsList.module.scss","sourcesContent":[".editIcon {\n  background-color: white;\n}\n\n.selectCheckBox {\n  text-align: center;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"editIcon": "editIcon__2EYiX",
	"selectCheckBox": "selectCheckBox__39Xrv"
};