import abilityConfig from '../configs/abilityConfig'

/**
 * formatAbilities - to format roleNodes array from graphQL to CASL format
 *
 * @returns {Array}
 */
function formatAbilities(roleNodes) {
  let rules = []
  console.log(roleNodes)
  roleNodes.map(role => {
    // add rule for view
    if (role.canView === 1) {
      rules.push({
        subject: role.key,
        action: 'view'
      })
    }

    // add rule for edit
    if (role.canEdit === 1) {
      rules.push({
        subject: role.key,
        action: 'edit'
      })
    }
  })

  return rules
}

/**
 * updateAbility - to update CASL ability rules with the given rules
 */
export const updateAbility = abilities => {
  abilityConfig.update(formatAbilities(abilities))
}

/**
 * isAbilityRuleEmpty - to check if CASL ability rules is empty
 */
export const isAbilityRuleEmpty = () => {
  const rules = abilityConfig.rules
  if (Object.keys(rules).length === 0 || abilityConfig.rules.length === 0) {
    return true
  }

  return false
}
