import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Label, Input } from 'reactstrap'

import styles from './RightSideBannerForm.module.scss'

const RightSideBannerForm = ({
  index,
  sideBanner,
  addSideBanner,
  updateSideBanner,
  removeSideBanner
}) => {
  const [updating, setUpdating] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const handleSubmit = e => {
    try {
      setUpdating(true)
      if (!sideBanner.id) {
        return addSideBanner(e)
      }
      return updateSideBanner(e)
    } catch (err) {
      setUpdating(false)
      console.log(err)
    }
  }

  const handleDelete = bannerId => {
    try {
      setDeleting(true)
      removeSideBanner(bannerId)
      return setDeleting(false)
    } catch (err) {
      setDeleting(false)
      console.log(err)
    }
  }

  return (
    <fieldset key={index} className={styles.fieldset}>
      <legend className={styles.legend}>{`Banner ${index + 1}`}</legend>
      <Form onSubmit={handleSubmit}>
        <div className={styles.form}>
          {sideBanner.id ? (
            <Input type="hidden" name="id" id="id" value={sideBanner.id} />
          ) : null}
          <Label className={styles.inputLabel} for="bannerAlt">
            Image alt
          </Label>
          <Input
            className={styles.input}
            type="text"
            name="bannerAlt"
            defaultValue={sideBanner.alt}
            placeholder="E.g: Company A"
          />
        </div>
        <div className={styles.form}>
          <Label className={styles.inputLabel} for="bannerCtaUrl">
            Image link
          </Label>
          <Input
            className={styles.input}
            type="text"
            name="bannerCtaUrl"
            defaultValue={sideBanner.ctaUrl}
            placeholder="url to redirect user to when clicked on the banner"
          />
        </div>
        <div className={styles.formFile}>
          <div className={styles.inputWrapper}>
            <Label className={styles.inputLabel}> Desktop Image </Label>
            {sideBanner.imageUrl ? (
              <a
                className={styles.inputLink}
                style={{ float: 'left', marginBottom: '15px' }}
                href={sideBanner.imageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                View current banner
              </a>
            ) : null}
          </div>
          <Input
            style={{ float: 'left', marginBottom: '15px' }}
            type="file"
            name="bannerImage"
            id="bannerImage" /*required={!featureBanner.id}*/
          />
        </div>
        <div className={styles.formFile}>
          <div className={styles.inputWrapper}>
            <Label className={styles.inputLabel}> Mobile Image </Label>
            {sideBanner.mobileImageUrl ? (
              <a
                style={{ float: 'left', marginBottom: '15px' }}
                href={sideBanner.mobileImageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                View current banner
              </a>
            ) : null}
          </div>
          <Input
            style={{ float: 'left', marginBottom: '15px' }}
            type="file"
            name="bannerMobileImage"
            id="bannerMobileImage" /*required={!featureBanner.id}*/
          />
        </div>
        <Button
          disabled={updating}
          type="primary"
          onClick={() => {}}
          color={sideBanner.id ? 'success' : 'primary'}
          className={styles.button}
        >
          {sideBanner.id
            ? updating
              ? 'Updating...'
              : 'Update Banner'
            : updating
              ? 'Creating...'
              : 'Create Banner'}
        </Button>
        {sideBanner.id ? (
          <Button
            disabled={deleting}
            type="button"
            color="danger"
            onClick={() => handleDelete(sideBanner.id)}
            className={styles.button}
          >
            {deleting ? 'Deleting...' : 'Delete'}
          </Button>
        ) : null}
      </Form>
    </fieldset>
  )
}

RightSideBannerForm.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sideBanner: PropTypes.object,
  addSideBanner: PropTypes.func.isRequired,
  updateSideBanner: PropTypes.func.isRequired,
  removeSideBanner: PropTypes.func.isRequired
}

RightSideBannerForm.defaultProps = {
  sideBanner: {}
}

export default memo(RightSideBannerForm)
