import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

/* Apollo */
import { ApolloProvider } from 'react-apollo'
import { client } from './shared/configs/apolloClientConfig'

/* CASL */
import { AbilityContext } from './shared/configs/abilityContextConfig'
import abilityConfig from './shared/configs/abilityConfig'

/* Components */
import App from './App'

/* Assets */
import 'bootstrap/scss/bootstrap.scss'
import './index.scss'

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <AbilityContext.Provider value={abilityConfig}>
        <App />
      </AbilityContext.Provider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
