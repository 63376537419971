import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Input, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'
import CloseJobButton from 'modules/jobs/containers/CloseJobButton'
import DeleteJobButton from 'modules/jobs/containers/DeleteJobButton'
import ExpireJobButton from 'modules/jobs/containers/ExpireJobButton'
import ExportJobButton from 'modules/jobs/containers/ExportJobButton'
import ExportJobApplicantButton from 'modules/jobs/containers/ExportJobApplicantButton'

/* Apollo */
import { graphql, compose } from 'react-apollo'
import {
  EXPORT_JOB_EXCEL,
  EXPORT_JOB_APPLICANT_EXCEL
} from '../../../../../schemas/mutations'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'
import Download from 'shared/assets/images/download.png'
import Applicant from 'shared/assets/images/applicant.png'

/* Helpers */
import { getNewConfigList } from 'shared/helpers/newConfigList'

/**
 * JobList - Bossjob recruiter job list table
 *
 */
const JobList = ({
  jobList,
  currentPage,
  totalCount,
  totalPages,
  exportPayload,
  exportJobExcel,
  exportJobApplicantExcel
}) => {
  const [isSelectingAll, setIsSelectingAll] = useState(false)
  const [check, setCheck] = useState({})
  const [configList, setConfigList] = useState({})

  useEffect(() => {
    async function updateConfig() {
      const data = await getNewConfigList()
      setConfigList({
        countryLists: data.country_lists
      })
    }

    updateConfig()
  }, [])

  /**
   * @function appendSelectedJob - Append jobId to selectedJobs state
   */
  const appendSelectedJob = (jobId, recruiterId) => {
    const updatedCheckedState = { ...check }
    updatedCheckedState[jobId] = recruiterId
    setCheck(updatedCheckedState)
  }

  /**
   * @function removeSelectedJob - Remove specific jobId from selectedJobs state
   */
  const removeSelectedJob = jobId => {
    const updatedCheckedState = { ...check }
    updatedCheckedState[jobId] = undefined
    setCheck(updatedCheckedState)
  }

  const handleSelectedBoxChange = e => {
    if (e.target.checked) {
      appendSelectedJob(
        e.target.value.split('-')[0],
        e.target.value.split('-')[1] || 'no recruiter id'
      )
    } else {
      removeSelectedJob(e.target.value.split('-')[0])
    }
  }

  useEffect(
    () => {
      if (isSelectingAll) {
        const updatedCheckedState = {}
        jobList.forEach(job => {
          updatedCheckedState[job.id] = job.recruiterId || 'no recruiter id'
        })
        setCheck(updatedCheckedState)
      } else {
        setCheck({})
      }
    },
    [isSelectingAll]
  )

  const getSelectedJobs = () => {
    const selectedJobIds = Object.keys(check)

    return selectedJobIds.map(jobId => ({ [jobId]: check[jobId] }))
  }

  return (
    <React.Fragment>
      <div style={{ marginBottom: '20px', display: 'flow-root' }}>
        <div style={{ float: 'left', display: 'flex' }}>
          <div style={{ marginRight: '10px' }}>
            <ExpireJobButton selectedJobs={getSelectedJobs()} />
          </div>
          <div style={{ marginRight: '10px' }}>
            <DeleteJobButton selectedJobs={getSelectedJobs()} />
          </div>
          <CloseJobButton selectedJobs={getSelectedJobs()} />
        </div>
        <div style={{ float: 'right', display: 'flex' }}>
          <div style={{ marginRight: '10px' }}>
            <ExportJobButton exportPayload={exportPayload} />
          </div>
          <div>
            <ExportJobApplicantButton exportPayload={exportPayload} />
          </div>
        </div>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th>
              <div style={{ textAlign: 'center' }}>
                <Input
                  addon
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  onChange={() => {
                    setIsSelectingAll(!isSelectingAll)
                  }}
                  value={'isSelectingAll'}
                  checked={isSelectingAll}
                />
              </div>
            </th>
            <th>ID</th>
            <th>Job Title</th>
            <th>No. of Views</th>
            <th>No. of Applications</th>
            <th>Status</th>
            <th>Country</th>
            <th>Created</th>
            <th>Last Edited</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {configList &&
            jobList.map(job => {
              const jobCountry =
                configList.countryLists &&
                configList.countryLists.find(
                  country => country.id == job.countryId
                )
              return (
                <tr key={`job-${job.id}`}>
                  <td>
                    <div style={{ textAlign: 'center' }}>
                      <Input
                        addon
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        onChange={handleSelectedBoxChange}
                        value={`${job.id}-${job.recruiterId}`}
                        checked={!!check[job.id]}
                      />
                    </div>
                  </td>
                  <td>
                    <a href={`/bossjob/job/${job.id}`}>{job.id}</a>
                  </td>
                  <td>{job.jobTitle}</td>
                  <td>{job.noOfViews}</td>
                  <td>{job.noOfApplications}</td>
                  <td>{job.statusKey}</td>
                  <td>{jobCountry ? jobCountry.value : 'null'}</td>
                  <td>{job.createdAt}</td>
                  <td>{job.updatedAt}</td>
                  <td>
                    <div style={{ textAlign: 'center', display: 'flex' }}>
                      <a href={`/bossjob/job/${job.id}`}>
                        <Button color="success">
                          <img src={Zoom} alt="Zoom" width="16" height="16" />
                        </Button>
                      </a>
                      <Button
                        style={{ marginLeft: '10px' }}
                        color="success"
                        onClick={() =>
                          exportJobExcel({
                            variables: { jobId: job.id }
                          }).then(response => {
                            if (
                              response.data &&
                              response.data.exportJobExcel.url
                            ) {
                              window.location.href =
                                response.data.exportJobExcel.url
                            }
                          })
                        }
                      >
                        <img
                          src={Download}
                          alt="Download"
                          width="16"
                          height="16"
                        />
                      </Button>
                      <Button
                        style={{ marginLeft: '10px' }}
                        color="success"
                        onClick={() =>
                          exportJobApplicantExcel({
                            variables: { jobId: job.id }
                          }).then(response => {
                            if (
                              response.data &&
                              response.data.exportJobApplicantExcel.url
                            ) {
                              window.location.href =
                                response.data.exportJobApplicantExcel.url
                            }
                          })
                        }
                      >
                        <img
                          src={Applicant}
                          alt="Applicant"
                          width="16"
                          height="16"
                        />
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalCount}
        totalPages={totalPages}
        pageRange={4}
      />
    </React.Fragment>
  )
}

JobList.propTypes = {
  /* array of job list */
  jobList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalCount: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  exportPayload: PropTypes.object.isRequired,

  exportJobExcel: PropTypes.func.isRequired,

  exportJobApplicantExcel: PropTypes.func.isRequired
}

export default compose(
  graphql(EXPORT_JOB_EXCEL, {
    name: 'exportJobExcel'
  }),
  graphql(EXPORT_JOB_APPLICANT_EXCEL, {
    name: 'exportJobApplicantExcel'
  })
)(JobList)
