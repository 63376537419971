import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, compose, graphql, withApollo } from 'react-apollo'
import {
  GET_LOCAL_ADMIN_USER_DETAIL,
  GET_ALL_ROLES
} from '../../schemas/queries'
import {
  UPDATE_LOCAL_ADMIN_USER,
  UPDATE_ADMIN_USER
} from '../../schemas/mutations'

/* Components */
import UserDetailForm from '../../views/UserDetail/UserDetailForm'
import Loading from 'shared/components/Loading'

class UserDetailFormContainer extends Component {
  static propTypes = {
    updateLocalAdminUser: PropTypes.func.isRequired,
    saveAdminUser: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isUpdating: false
    }

    this.handleUserInputUpdate = this.handleUserInputUpdate.bind(this)
    this.saveUserDetail = this.saveUserDetail.bind(this)
  }

  /**
   *
   * @function handleUserInputUpdate - handle when user updating the field
   * and it will trigger the local data mutation
   *
   * @param key
   * @param value
   */
  handleUserInputUpdate(key, value) {
    this.props.updateLocalAdminUser({
      variables: {
        key: key,
        value: value
      }
    })
  }

  /**
   *
   * @function saveUserDetail - call network mutation to update user detail
   * with the local state and reload the page once done
   *
   */
  async saveUserDetail() {
    this.setState({ isUpdating: true })

    const { adminUserDetail } = this.props.client.readQuery({
      query: GET_LOCAL_ADMIN_USER_DETAIL
    })

    const payload = {
      id: adminUserDetail.id,
      payload: {
        name: adminUserDetail.name,
        roleId: adminUserDetail.role.id
      }
    }

    try {
      let response = await this.props.saveAdminUser({
        variables: payload
      })

      if (response) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    return (
      <Query query={GET_LOCAL_ADMIN_USER_DETAIL}>
        {({ data: { adminUserDetail } }) => (
          <Query query={GET_ALL_ROLES}>
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else {
                console.log(data.allRoles)
                return (
                  <UserDetailForm
                    handleChange={this.handleUserInputUpdate}
                    handleSave={this.saveUserDetail}
                    userDetail={adminUserDetail}
                    roleOptions={data.allRoles}
                  />
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}

export default compose(
  withApollo,
  graphql(UPDATE_LOCAL_ADMIN_USER, { name: 'updateLocalAdminUser' }),
  graphql(UPDATE_ADMIN_USER, { name: 'saveAdminUser' })
)(UserDetailFormContainer)
