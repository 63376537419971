import React, { Component } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import CONFIG from 'config'

import SectionWrapper from 'shared/components/SectionWrapper'
import TextEditorField from 'shared/components/TextEditorField'
import Skills from 'modules/bossjob_users/views/BossJobUserDetail/UserProfile/Skills'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Col,
  Row,
  Button
} from 'reactstrap'

import { withRouter } from 'react-router-dom'

import {
  getSalariesTypeOptions,
  getCurrentShouldShowObject,
  getSalaryOption
} from 'shared/helpers/useSalariesUtil'

class PostJobContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: {},
      locations: [],
      benefits: [],
      featured: false,
      urgent: false,
      isURL: false,
      externalApplyUrl: '',
      isSubmitting: false,
      salaryTypeId: 1
    }

    this.handleCheckBoxToggle = this.handleCheckBoxToggle.bind(this)
    this.handleURLChange = this.handleURLChange.bind(this)
  }

  componentDidMount() {
    this._fetchFieldOptions()
  }

  handleCheckBoxToggle(e) {
    let key = e.target.name
    this.setState(
      {
        [key]: !this.state[key]
      },
      () => {
        this.props.handleCheckBoxChange(key, this.state[key])
      }
    )
  }

  handleURLChange(e) {
    this.setState(
      {
        externalApplyUrl: e.target.value
      },
      () => {
        this.props.handleExternalURLChange(this.state.externalApplyUrl)
      }
    )
  }

  /**
   *
   * @function renderSalaryOption - render salary options with the corresponding value
   *
   * @param {Number} start
   * @param {Number} end
   * @returns {Component}
   */

  formatSalary = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  renderSalaryOption(start, end) {
    let salaryOptions = []

    for (let salary = start; salary <= end; salary += 5000) {
      salaryOptions.push(salary)
    }

    return salaryOptions.map(salary => (
      <option key={`salary-start-${salary}`} value={salary}>
        {this.formatSalary(salary)}
      </option>
    ))
  }

  // getSalaryOption = (start, end) => {
  //   let salaryOptions = []

  //   for (let salary = start; salary <= end; salary += 5000) {
  //     salaryOptions.push(salary)
  //   }

  //   return salaryOptions
  // }

  /**
   *
   * @function _fetchFieldOptions - Fetch all the form field options
   */
  _fetchFieldOptions() {
    fetch(`${CONFIG.DATA_BOSSJOB_URL}/config?country_code=ph`)
      .then(response => response.json())
      .then(data => {
        const { location_lists, job_benefit_lists } = data.data.inputs
        let locations = []
        location_lists.forEach(
          option =>
            Array.isArray(option.locations) && option.locations.length > 0
              ? locations.push(...option.locations)
              : null
        )
        locations.sort((a, b) => (a.key > b.key ? 1 : -1))
        this.setState({
          options: data.data,
          locations,
          benefits: job_benefit_lists
        })
      })
  }

  // TODO: change to render joh function field
  /**
   *
   * @function _renderJobCategoryField
   * @returns {Component}
   */
  _renderJobCategoryField() {
    const { options } = this.state
    const {
      postJobDetail: { jobCategories },
      handleDynamicInputFieldChange,
      isInvalid
    } = this.props
    const keyName = 'jobCategories'
    return (
      <FormGroup>
        <Label for="jobCategory">Job Category*</Label>
        {jobCategories.length > 0 &&
          jobCategories.map((category, index) => (
            <div
              style={{ position: 'relative', marginBottom: '10px' }}
              key={`job-category-${index}`}
            >
              {index > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    left: '-15px',
                    top: '7px',
                    fontWeight: 'bold',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    handleDynamicInputFieldChange(
                      keyName,
                      'delete',
                      null,
                      index
                    )
                  }
                >
                  x
                </div>
              )}
              <Input
                type="select"
                name={`jobCategory-${index}`}
                id="jobCategory"
                value={category.category.id}
                disabled={isInvalid}
                onChange={e => {
                  e.preventDefault()
                  handleDynamicInputFieldChange(
                    keyName,
                    'edit',
                    e.target.value,
                    index
                  )
                }}
              >
                {options.inputs &&
                  options.inputs.job_category_lists.map(
                    (jobCategory, index) => {
                      return (
                        <option key={index} value={jobCategory.id}>
                          {jobCategory.value}
                        </option>
                      )
                    }
                  )}
              </Input>
            </div>
          ))}
        {jobCategories.length < 3 &&
          options.inputs && (
          <div
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() =>
              handleDynamicInputFieldChange(
                keyName,
                'add',
                options.inputs.job_category_lists[0].id
              )
            }
          >
              + Add category (up to 3 categories)
          </div>
        )}
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobTitleRefField
   * @returns {Component}
   */
  _renderJobTitleRefField() {
    const {
      postJobDetail: { jobTitleRef },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobTitleRef">Job Title Reference</Label>
        <Input
          type="text"
          name="jobTitleRef"
          id="jobTitleRef"
          value={jobTitleRef}
          onChange={handleChange}
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobTitleField
   * @returns {Component}
   */
  _renderJobTitleField() {
    const { postJobDetail, handleChange, isInvalid } = this.props
    return (
      <FormGroup>
        <Label for="jobTitle">Job Title*</Label>
        <Input
          type="text"
          name="jobTitle"
          id="jobTitle"
          value={postJobDetail.jobTitle}
          onChange={handleChange}
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobTypeField
   * @returns {Component}
   */
  _renderJobTypeField() {
    const { options } = this.state
    const {
      postJobDetail: { jobTypeKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobTypeKey">Job Type*</Label>
        <Input
          type="select"
          name="jobTypeKey"
          id="jobTypeKey"
          value={jobTypeKey}
          onChange={handleChange}
          disabled={isInvalid}
        >
          {options.inputs &&
            options.inputs.job_types.map((jobType, index) => {
              return (
                <option key={index} value={Object.keys(jobType)[0]}>
                  {Object.values(jobType)[0]}
                </option>
              )
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderMinimumExperienceField
   * @returns {Component}
   */
  _renderMinimumExperienceField() {
    const { options } = this.state
    const {
      postJobDetail: { xpLvlKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="xpLvlKey">Minimum Experience*</Label>
        <Input
          type="select"
          name="xpLvlKey"
          id="xpLvlKey"
          value={xpLvlKey}
          onChange={handleChange}
          disabled={isInvalid}
        >
          {options.inputs &&
            options.inputs.xp_lvls.map((xpLvl, index) => {
              return (
                <option key={index} value={Object.keys(xpLvl)[0]}>
                  {Object.values(xpLvl)[0]}
                </option>
              )
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderMinimumQualificationField
   * @returns {Component}
   */
  _renderMinimumQualificationField() {
    const { options } = this.state
    const {
      postJobDetail: { degreeKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="degreeKey">Minimum Qualification*</Label>
        <Input
          type="select"
          name="degreeKey"
          id="degreeKey"
          value={degreeKey}
          onChange={handleChange}
          disabled={isInvalid}
        >
          {options.inputs &&
            options.inputs.degrees.map((degree, index) => {
              return (
                <option key={index} value={Object.keys(degree)[0]}>
                  {Object.values(degree)[0]}
                </option>
              )
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderMonthlySalaryFromField
   * @returns {Component}
   */
  _renderMonthlySalaryFromField() {
    const {
      postJobDetail,
      handleChange,
      isInvalid,
      updateLocalPostJobDetailState
    } = this.props

    const { currencyId = 1, salaryTypeId } = postJobDetail || {}
    const {
      currencyIdToSalaryRangesMap,
      currentSalaryFrom,
      fromName,
      toName
    } = getCurrentShouldShowObject(salaryTypeId, postJobDetail)

    return (
      <FormGroup>
        <Label md={3} sm={12}>
          Salary From
        </Label>
        <Col md={9} sm={12}>
          <Input
            type="select"
            name={fromName}
            value={currentSalaryFrom} //
            onChange={e => {
              handleChange(e)
              const currency = currencyIdToSalaryRangesMap[currencyId]
              const salaryTo =
                Number(e.target.value) + Number(currency.interval)
              updateLocalPostJobDetailState(toName, salaryTo)
            }}
            disabled={isInvalid}
          >
            {/* {this.renderSalaryOption(10000, 200000)} */}
            {currencyIdToSalaryRangesMap &&
              getSalaryOption(
                currencyIdToSalaryRangesMap[currencyId],
                'lower'
              ).map(salary => (
                <option key={`salary-start-${salary}`} value={salary}>
                  {salary}
                </option>
              ))}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderMonthlySalaryToField
   * @returns {Component}
   */
  _renderMonthlySalaryToField() {
    const { isInvalid, postJobDetail, handleChange } = this.props

    const { currencyId = 1, salaryTypeId } = postJobDetail || {}

    const {
      currencyIdToSalaryRangesMap,
      currentSalaryFrom,
      currentSalaryTo,
      toName
    } = getCurrentShouldShowObject(salaryTypeId, postJobDetail)

    return (
      <FormGroup>
        <Label md={3} sm={12}>
          Salary To
        </Label>
        <Col md={9} sm={12}>
          <Input
            type="select"
            name={toName}
            value={currentSalaryTo}
            onChange={handleChange}
            disabled={isInvalid}
          >
            {currencyIdToSalaryRangesMap &&
              getSalaryOption(currencyIdToSalaryRangesMap[currencyId], 'upper')
                .filter(
                  salary =>
                    salary > currentSalaryFrom &&
                    salary <= currentSalaryFrom * 2
                )
                .map(salary => (
                  <option key={`salary-end-${salary}`} value={salary}>
                    {salary}
                  </option>
                ))}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderStockBenefitsField
   * @returns {Component}
   */
  _renderStockBenefitsField() {
    const { handleJobBenefitsFieldChange } = this.props
    const { benefits } = this.state
    return (
      <FormGroup
        style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}
      >
        {benefits &&
          benefits.stock &&
          benefits.stock.map(({ id, name }) => {
            return (
              <div key={id} style={{ display: 'flex', marginRight: '50px' }}>
                <Label>
                  <Input
                    type="checkbox"
                    name="jobBenefits"
                    value={id}
                    onChange={handleJobBenefitsFieldChange}
                  />
                  {name}
                </Label>
              </div>
            )
          })}
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderVariableBenefitsField
   * @returns {Component}
   */
  _renderVariableBenefitsField() {
    const {
      postJobDetail: { jobBenefits },
      handleJobBenefitsFieldChange
    } = this.props
    const { benefits } = this.state
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        {benefits &&
          benefits.variable &&
          benefits.variable.map(({ id, name }) => {
            return (
              <div key={id} style={{ display: 'flex', marginRight: '50px' }}>
                <Label>
                  <Input
                    type="checkbox"
                    name="jobBenefits"
                    value={id}
                    checked={
                      jobBenefits &&
                      jobBenefits.filter(
                        jobBenefit => jobBenefit.benefitId === Number(id)
                      ).length > 0
                        ? true
                        : false
                    }
                    onChange={handleJobBenefitsFieldChange}
                  />
                  {name}
                </Label>
              </div>
            )
          })}
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderAllowancesField
   * @returns {Component}
   */
  _renderAllowancesField() {
    const {
      postJobDetail: { jobBenefits },
      handleJobBenefitsFieldChange
    } = this.props
    const { benefits } = this.state
    return (
      <FormGroup
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        {benefits &&
          benefits.allowance &&
          benefits.allowance.map(({ id, name }) => {
            return (
              <div key={id} style={{ display: 'flex', marginRight: '50px' }}>
                <Label>
                  <Input
                    type="checkbox"
                    name="jobBenefits"
                    value={id}
                    checked={
                      jobBenefits &&
                      jobBenefits.filter(
                        jobBenefit => jobBenefit.benefitId === Number(id)
                      ).length > 0
                        ? true
                        : false
                    }
                    onChange={handleJobBenefitsFieldChange}
                  />
                  {name}
                </Label>
              </div>
            )
          })}
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderWorkLocationField
   * @returns {Component}
   */
  _renderWorkLocationField() {
    const { locations } = this.state
    const {
      postJobDetail: { jobLocationKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobLocationKey">Work Location*</Label>
        <Input
          type="select"
          name="jobLocationKey"
          id="jobLocation"
          value={jobLocationKey}
          onChange={handleChange}
          disabled={isInvalid}
        >
          {locations.map((location, index) => {
            return (
              <option key={index} value={location.key}>
                {location.value} ({location.region})
              </option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderCountryField
   * @returns {Component}
   */
  _renderCountryField() {
    const { options } = this.state
    const {
      postJobDetail: { jobCountryKey, jobLocationKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobCountryKey">Country*</Label>
        <Input
          type="select"
          name="jobCountryKey"
          id="jobCountryKey"
          value={jobCountryKey}
          onChange={handleChange}
          disabled={isInvalid || jobLocationKey !== 'overseas'}
        >
          {options.inputs &&
            options.inputs.country_lists.map((country, index) => {
              return (
                <option key={index} value={Object.keys(country)[0]}>
                  {Object.values(country)[0]}
                </option>
              )
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderAddressField
   * @returns {Component}
   */
  _renderAddressField() {
    const {
      postJobDetail: { floorUnit, address, longitude, latitude },
      defaultWorkAddress,
      handleChange,
      isInvalid,
      workAddresses,
      companyId
    } = this.props

    const matchWorkAddress = workAddresses.filter(
      workAddress =>
        workAddress.floorUnit == floorUnit &&
        workAddress.address == address &&
        workAddress.longitude == longitude &&
        workAddress.latitude == latitude
    )

    const isDefaultWorkAddressInList =
      defaultWorkAddress &&
      workAddresses.filter(
        workAddress =>
          workAddress.floorUnit == defaultWorkAddress.floorUnit &&
          workAddress.address == defaultWorkAddress.address &&
          workAddress.longitude == defaultWorkAddress.longitude &&
          workAddress.latitude == defaultWorkAddress.latitude
      ).length > 0

    return (
      <FormGroup>
        <Label for="address">
          Working Address* (floor/unit, address (lat,lng))
        </Label>
        <Input
          type="select"
          name="work-address"
          id="address"
          value={matchWorkAddress ? matchWorkAddress.id : ''}
          onChange={handleChange}
          disabled={isInvalid}
        >
          <option key="empty_work_address" value="">
            Not Selected
          </option>
          {defaultWorkAddress &&
            !isDefaultWorkAddressInList && (
            <option key="work_address_default" value="defaultAddress">
              {`${defaultWorkAddress.floorUnit}, ${
                defaultWorkAddress.address
              } (${defaultWorkAddress.latitude}, ${
                defaultWorkAddress.longitude
              })`}
            </option>
          )}
          {workAddresses &&
            workAddresses.map(workAddress => {
              return (
                <option
                  key={`work_address_${workAddress.id}`}
                  value={workAddress.id}
                >
                  {`${workAddress.floorUnit}, ${workAddress.address} (${
                    workAddress.latitude
                  }, ${workAddress.longitude})`}
                </option>
              )
            })}
        </Input>
        <a
          href={`/bossjob/company/${companyId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Add new working address in company setting
        </a>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobDescriptionField
   * @returns {Component}
   */
  _renderJobDescriptionField() {
    const {
      postJobDetail: { jobDescriptionHtml },
      handleTextEditorChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobDescription">
          Job Description* (minimum 10 characters)
        </Label>
        <TextEditorField
          name="jobDescription"
          value={jobDescriptionHtml}
          handleChange={(htmlValue, textValue) =>
            handleTextEditorChange('jobDescription', textValue, htmlValue)
          }
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobRequirementField
   * @returns {Component}
   */
  _renderJobRequirementField() {
    const {
      postJobDetail: { jobRequirementsHtml },
      handleTextEditorChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobRequirements">
          Job Requirements* (minimum 10 characters)
        </Label>
        <TextEditorField
          name="jobRequirements"
          value={jobRequirementsHtml}
          handleChange={(htmlValue, textValue) =>
            handleTextEditorChange('jobRequirements', textValue, htmlValue)
          }
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  _renderScreeningQuestionField() {
    const {
      postJobDetail: { screeningQuestions },
      recruiterSubscriptionPlanDetail,
      handleDynamicInputFieldChange,
      isInvalid
    } = this.props
    const keyName = 'screeningQuestions'
    if (
      recruiterSubscriptionPlanDetail &&
      recruiterSubscriptionPlanDetail.maxNoOfScreeningQuestionsPerJob
    ) {
      const {
        maxNoOfScreeningQuestionsPerJob
      } = recruiterSubscriptionPlanDetail
      return (
        <FormGroup>
          <Label for="screeningQuestion">Screening Question</Label>
          {screeningQuestions.map((nothing, index) => (
            <React.Fragment key={index}>
              <Row>
                <Col md="1">
                  <Label for="screeningQuestion">{index + 1}:</Label>
                </Col>
                <Col md={index > 0 ? '10' : '11'}>
                  <Input
                    type="text"
                    name="screeningQuestion"
                    id="screeningQuestion"
                    value={screeningQuestions[index].question}
                    onChange={e =>
                      handleDynamicInputFieldChange(
                        keyName,
                        'edit',
                        e.target.value,
                        index
                      )
                    }
                    disabled={isInvalid}
                  />
                </Col>
                {index > 0 && (
                  <Col md="1">
                    <div
                      style={{
                        position: 'absolute',
                        top: '7px',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        handleDynamicInputFieldChange(
                          keyName,
                          'delete',
                          null,
                          index
                        )
                      }
                    >
                      x
                    </div>
                  </Col>
                )}
              </Row>
            </React.Fragment>
          ))}
          {screeningQuestions.length < maxNoOfScreeningQuestionsPerJob && (
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                handleDynamicInputFieldChange(
                  keyName,
                  'add',
                  screeningQuestions.length
                )
              }
            >
              {`+ Add Screening Question (up to ${maxNoOfScreeningQuestionsPerJob} screening question)`}
            </div>
          )}
        </FormGroup>
      )
    }
  }

  _renderResumeEmailField() {
    const {
      postJobDetail: { resumeForwardingEmails },
      recruiterSubscriptionPlanDetail,
      handleDynamicInputFieldChange,
      isInvalid
    } = this.props
    const keyName = 'resumeForwardingEmails'
    if (
      recruiterSubscriptionPlanDetail &&
      recruiterSubscriptionPlanDetail.maxNoOfEmailsToReceiveResumePerJob
    ) {
      const {
        maxNoOfEmailsToReceiveResumePerJob
      } = recruiterSubscriptionPlanDetail
      return (
        <FormGroup>
          <Label for="resumeForwardingEmail">Resume Forwarding Email</Label>
          {resumeForwardingEmails.map((nothing, index) => (
            <React.Fragment key={index}>
              <Row>
                <Col md="1">
                  <Label for="resumeForwardingEmail">{index + 1}:</Label>
                </Col>
                <Col md={index > 0 ? '10' : '11'}>
                  <Input
                    type="text"
                    name="resumeForwardingEmail"
                    id="resumeForwardingEmail"
                    value={resumeForwardingEmails[index].email}
                    onChange={e =>
                      handleDynamicInputFieldChange(
                        keyName,
                        'edit',
                        e.target.value,
                        index
                      )
                    }
                    disabled={isInvalid}
                  />
                </Col>
                {index > 0 && (
                  <Col md="1">
                    <div
                      style={{
                        position: 'absolute',
                        top: '7px',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        handleDynamicInputFieldChange(
                          keyName,
                          'delete',
                          null,
                          index
                        )
                      }
                    >
                      x
                    </div>
                  </Col>
                )}
              </Row>
            </React.Fragment>
          ))}
          {resumeForwardingEmails.length <
            maxNoOfEmailsToReceiveResumePerJob && (
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                handleDynamicInputFieldChange(
                  keyName,
                  'add',
                  resumeForwardingEmails.length
                )
              }
            >
              {`+ Add resume forwarding email (up to ${maxNoOfEmailsToReceiveResumePerJob} email)`}
            </div>
          )}
        </FormGroup>
      )
    }
  }

  /**
   *
   * @function _renderSkillsField
   * @returns {Component}
   */
  _renderSkillsField() {
    const {
      postJobDetail: { jobSkills },
      updateLocalPostJobDetailState
    } = this.props
    return (
      <FormGroup>
        <Skills
          type="jobDetail"
          skills={jobSkills}
          handleChange={value =>
            updateLocalPostJobDetailState('jobSkills', value)
          }
        />
      </FormGroup>
    )
  }

  /* Render function job title */
  _renderFunctionJobTitleField() {
    const {
      postJobDetail: { jobTitleId },
      handleChange,
      options: { jobFunctionList }
    } = this.props

    return (
      <FormGroup row>
        <Label md={3} sm={12}>
          Job Function Name
        </Label>
        <Col md={6} sm={9}>
          <Input
            type="select"
            name="jobTitleId"
            value={jobTitleId}
            onChange={handleChange}
          >
            {jobFunctionList &&
              jobFunctionList.map(mainFunctionObj => {
                const mainFunction = Object.keys(mainFunctionObj)[0]
                const subFunctionObjs = mainFunctionObj[mainFunction]
                return subFunctionObjs.map((subFunctionObj, i) => {
                  return subFunctionObj.job_titles.map((jobTitle, idx) => {
                    return (
                      <React.Fragment key={`jobFunction_${i}_${idx}`}>
                        {i === 0 &&
                          idx === 0 &&
                          jobTitleId === '' && (
                          <option key={'not_selected'} value="">
                              Not selected
                          </option>
                        )}
                        {idx === 0 && (
                          <option
                            key={`subFunction_${subFunctionObj.value}`}
                            disabled
                          >
                            {mainFunction} - {subFunctionObj.value}
                          </option>
                        )}
                        <option
                          key={`jobTitleId_${jobTitle.id}`}
                          value={jobTitle.id}
                        >
                          {jobTitle.value}
                        </option>
                      </React.Fragment>
                    )
                  })
                })
              })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderExternalURLField
   * @returns {Component}
   */

  handleSubmit = async e => {
    try {
      e.preventDefault()
      this.setState({
        isSubmitting: true
      })
      const { postNewJob, postJobDetail } = this.props
      const { recruiterId } = this.props.match.params
      const { source } = queryString.parse(location.search)

      const jobCategories = postJobDetail.jobCategories.map(
        category => category.category.id
      )

      let screeningQuestions = postJobDetail.screeningQuestions.map(obj => {
        return obj.question
      })
      screeningQuestions = screeningQuestions.filter(q => q)

      let resumeForwardingEmails = postJobDetail.resumeForwardingEmails.map(
        obj => {
          return obj.email
        }
      )
      resumeForwardingEmails = resumeForwardingEmails.filter(q => q)

      let jobBenefits = postJobDetail.jobBenefits.map(
        jobBenefit => jobBenefit.benefitId
      )
      if (!postJobDetail.jobTitleId) {
        alert('1232131')
        return
      }
      jobBenefits = jobBenefits.filter(q => q)
      const payload = {
        degreeKey: postJobDetail.degreeKey,
        jobLocationKey: postJobDetail.jobLocationKey,
        jobTypeKey: postJobDetail.jobTypeKey,
        xpLvlKey: postJobDetail.xpLvlKey,
        jobTitle: postJobDetail.jobTitle,
        jobTitleRef: postJobDetail.jobTitleRef,
        jobTitleId: postJobDetail.jobTitleId,
        floorUnit: postJobDetail.floorUnit,
        address: postJobDetail.address,
        latitude: postJobDetail.latitude,
        longitude: postJobDetail.longitude,
        jobDescription: postJobDetail.jobDescription,
        jobDescriptionHtml: postJobDetail.jobDescriptionHtml,
        jobRequirements: postJobDetail.jobRequirements,
        jobRequirementsHtml: postJobDetail.jobRequirementsHtml,
        jobCategoryId: jobCategories,
        // salaryRangeFrom: parseInt(postJobDetail.salaryRangeFrom),
        // salaryRangeTo: parseInt(postJobDetail.salaryRangeTo),
        jobSkills: postJobDetail.jobSkills,
        externalApplyUrl: postJobDetail.externalApplyUrl,
        jobCountryKey: postJobDetail.jobCountryKey,
        screeningQuestions,
        resumeForwardingEmails,
        jobBenefitsId: jobBenefits,
        source: source,

        // currencyId: 1,
        salaryTypeId: postJobDetail.salaryTypeId
      }

      if (postJobDetail.salaryTypeId == 1) {
        payload.salaryRangeFrom = parseInt(postJobDetail.salaryRangeFrom)
        payload.salaryRangeTo = parseInt(postJobDetail.salaryRangeTo)
      }

      if (postJobDetail.salaryTypeId == 2) {
        payload.dailySalaryRangeFrom = parseInt(
          postJobDetail.dailySalaryRangeFrom
        )
        payload.dailySalaryRangeTo = parseInt(postJobDetail.dailySalaryRangeTo)
      }

      if (postJobDetail.salaryTypeId == 3) {
        payload.hourlySalaryRangeFrom = parseInt(
          postJobDetail.hourlySalaryRangeFrom
        )
        payload.hourlySalaryRangeTo = parseInt(
          postJobDetail.hourlySalaryRangeTo
        )
      }

      const response = await postNewJob({
        variables: {
          jobInput: payload,
          recruiterId
        }
      })

      if (response.data && response.data.addJob) {
        if (response.data.addJob.jobId) {
          this.props.history.push(`/bossjob/job/${response.data.addJob.jobId}`)
        } else if (!response.data.addJob.ok && response.data.addJob.message) {
          window.alert(response.data.addJob.message)
        }
      }
      this.setState({
        isSubmitting: false
      })
    } catch (err) {
      this.setState({
        isSubmitting: false
      })
      return err
    }
  }

  _renderFunctionJobSalaryField() {
    const {
      handleChange,
      isInvalid,
      postJobDetail: { jobTypeKey, salaryTypeId }
    } = this.props

    return (
      <FormGroup>
        <Label md={3} sm={12}>
          Salary Type
        </Label>
        <Col md={9} sm={12}>
          <Input
            disabled={isInvalid}
            type="select"
            name="salaryTypeId"
            value={salaryTypeId}
            onChange={handleChange}
          >
            {getSalariesTypeOptions(jobTypeKey).map((item, index) => (
              <option key={`country-${index}`} value={item.id}>
                {item.value}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  render() {
    const { isInvalid } = this.props

    return (
      <Row>
        <Col md="12">
          <SectionWrapper sectionTitle="Post Job">
            <Form>
              <Container>
                <Row>
                  <Col>{this._renderFunctionJobTitleField()}</Col>
                </Row>
                <Row>
                  <Col md="6">{this._renderJobTitleField()}</Col>
                  <Col md="6">{this._renderJobTitleRefField()}</Col>
                </Row>
                <Row>
                  <Col md="6">{this._renderJobTypeField()}</Col>
                  <Col md="6">{this._renderMinimumExperienceField()}</Col>
                </Row>

                <Row>
                  <Col md="6">{this._renderMinimumQualificationField()}</Col>
                  <Col md="6">{this._renderWorkLocationField()}</Col>
                </Row>

                <Row>
                  <Col md="12">{this._renderAddressField()}</Col>
                </Row>

                <Row>
                  <Col md="6">{this._renderCountryField()}</Col>
                </Row>

                <Row>
                  <Col md={6}>{this._renderFunctionJobSalaryField()}</Col>
                </Row>

                <Row>
                  <Col md="6">{this._renderMonthlySalaryFromField()}</Col>
                  <Col md="6">{this._renderMonthlySalaryToField()}</Col>
                </Row>

                <Row style={{ alignItems: 'baseline' }}>
                  <Col md="3">Stock benefits:</Col>
                  <Col md="9">{this._renderStockBenefitsField()}</Col>
                </Row>

                <Row>
                  <Col md="3">Variable benefits:</Col>
                  <Col md="9">{this._renderVariableBenefitsField()}</Col>
                </Row>

                <Row>
                  <Col md="3">Allowances:</Col>
                  <Col md="9">{this._renderAllowancesField()}</Col>
                </Row>

                <Row>
                  <Col md="12">{this._renderJobDescriptionField()}</Col>
                </Row>

                <Row>
                  <Col md="12">{this._renderJobRequirementField()}</Col>
                </Row>

                <Row>
                  <Col md="12">{this._renderResumeEmailField()}</Col>
                </Row>

                <Row>
                  <Col md="12">{this._renderSkillsField()}</Col>
                </Row>
              </Container>
              <Button
                color="primary"
                onClick={this.handleSubmit}
                style={{ width: '100%', padding: '10px 0', fontSize: '18px' }}
                disabled={isInvalid || this.state.isSubmitting}
              >
                Post
              </Button>
            </Form>
          </SectionWrapper>
        </Col>
      </Row>
    )
  }
}

PostJobContainer.propTypes = {
  match: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleTextEditorChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  updateLocalPostJobDetailState: PropTypes.func.isRequired,
  handleDynamicInputFieldChange: PropTypes.func.isRequired,
  postJobDetail: PropTypes.object.isRequired,
  recruiterSubscriptionPlanDetail: PropTypes.object.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleExternalURLChange: PropTypes.func.isRequired,
  handleJobBenefitsFieldChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  workAddresses: PropTypes.array,
  defaultWorkAddress: PropTypes.object,
  options: PropTypes.object,
  companyId: PropTypes.number
}

export default withRouter(PostJobContainer)
