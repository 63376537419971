import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'
import RequestDemoList from './RequestDemoList/RequestDemoList'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_REQUEST_LIST } from '../../schemas/queries'

/**
 * Request Demo - main request list view
 *
 */
const RequestDemo = props => {
  return (
    <SectionWrapper sectionTitle="Request Demo" isMainPage>
      <Query
        query={GET_REQUEST_LIST}
        variables={{ page: props.match.params.page || 1, size: 10 }}
      >
        {({ loading, data: { demoRequestList } }) => {
          if (loading) {
            return <Loading />
          } else {
            console.log({ demoRequestList })
            return <RequestDemoList demoRequestList={demoRequestList.data} />
          }
        }}
      </Query>
    </SectionWrapper>
  )
}

RequestDemo.propTypes = {
  match: PropTypes.object.isRequired
}

export default withRouter(RequestDemo)
