import React from 'react'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'
import { Query } from 'react-apollo'
import { GET_NEW_PRODUCT_FEATURE_LIST } from '../../schemas/queries'
import ProductFeatureAnnouncementContainer from './ProductFeatureAnnouncementContainer/ProductFeatureAnnouncementContainer'

const ProductFeatureAnnouncement = () => (
  <div>
    <SectionWrapper sectionTitle="New Product Feature Announcement" isMainPage>
      <p style={{ color: 'red', fontSize: '16px' }}>
        *Note: Sort order values must be unique.
      </p>
      <Query query={GET_NEW_PRODUCT_FEATURE_LIST}>
        {({ data: { productFeatureBanners }, loading }) => {
          if (loading) {
            return <Loading />
          }
          if (productFeatureBanners) {
            return (
              <ProductFeatureAnnouncementContainer
                list={productFeatureBanners}
              />
            )
          }
          return null
        }}
      </Query>
    </SectionWrapper>
  </div>
)

export default ProductFeatureAnnouncement
