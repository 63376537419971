import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import { GET_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM } from '../../../schemas/queries'
import { ADD_OFFLINE_SUBSCRIPTION } from '../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import SubmitButton from '../../../views/SubscriptionDetail/Forms/SubmitForm/SubmitButton'

/**
 * SubmitButtonContainer - Handles the Apollo logic side of the UpdateButton
 *
 * @returns {Component}
 */
const SubmitButtonContainer = ({
  userId,
  addOfflineSubscription,
  isDisabled
}) => (
  <Query query={GET_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM}>
    {({ loading, data }) => {
      if (loading) return <Loading />
      return (
        <SubmitButton
          subscriptionDetail={data}
          addOfflineSubscription={addOfflineSubscription}
          userId={userId}
          isDisabled={isDisabled}
        />
      )
    }}
  </Query>
)

SubmitButtonContainer.propTypes = {
  addOfflineSubscription: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool
}

export default compose(
  graphql(ADD_OFFLINE_SUBSCRIPTION, { name: 'addOfflineSubscription' })
)(SubmitButtonContainer)
