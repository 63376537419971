import gql from 'graphql-tag'

export const UPDATE_FEATURED_COMPANIES = gql`
  mutation UpdateFeaturedCompanies(
    $featureCompanies: [UpdateFeatureCompanyObject!]!
    $countryId: Int!
  ) {
    updateFeatureCompanies(
      featureCompanies: $featureCompanies
      countryId: $countryId
    ) {
      ok
      message
    }
  }
`

export const ADD_FEATURED_BANNER = gql`
  mutation AddFeaturedBanner($featureBannerInput: FeatureBannerInput!) {
    addFeatureBanner(featureBannerInput: $featureBannerInput) {
      ok
      message
    }
  }
`

export const UPDATE_FEATURED_BANNER = gql`
  mutation UpdateFeaturedBanner(
    $bannerId: Int!
    $featureBannerInput: FeatureBannerInput!
  ) {
    updateFeatureBanner(
      bannerId: $bannerId
      featureBannerInput: $featureBannerInput
    ) {
      ok
      message
    }
  }
`

export const REMOVE_FEATURED_BANNER = gql`
  mutation RemoveFeaturedBanner($bannerId: Int!) {
    deleteFeatureBanner(bannerId: $bannerId) {
      ok
      message
    }
  }
`

export const ADD_JOB_PAGE_LEFT_SIDE_BANNER = gql`
  mutation AddJobPageLeftSideBanner(
    $sideBannerInput: JobPageLeftSideBannerInput
  ) {
    addJobPageLeftSideBanner(sideBannerInput: $sideBannerInput) {
      ok
      message
    }
  }
`

export const UPDATE_JOB_PAGE_LEFT_SIDE_BANNER = gql`
  mutation UpdateJobPageLeftSideBanner(
    $bannerId: Int!
    $sideBannerInput: JobPageLeftSideBannerInput
  ) {
    updateJobPageLeftSideBanner(
      bannerId: $bannerId
      sideBannerInput: $sideBannerInput
    ) {
      ok
      message
    }
  }
`

export const REMOVE_JOB_PAGE_LEFT_SIDE_BANNER = gql`
  mutation RemoveJobPageLeftSideBanner($bannerId: Int!) {
    deleteJobPageLeftSideBanner(bannerId: $bannerId) {
      ok
      message
    }
  }
`

export const ADD_JOB_PAGE_RIGHT_SIDE_BANNER = gql`
  mutation AddJobPageRightSideBanner(
    $sideBannerInput: JobPageRightSideBannerInput
  ) {
    addJobPageRightSideBanner(sideBannerInput: $sideBannerInput) {
      ok
      message
    }
  }
`

export const UPDATE_JOB_PAGE_RIGHT_SIDE_BANNER = gql`
  mutation UpdateJobPageRightSideBanner(
    $bannerId: Int!
    $sideBannerInput: JobPageRightSideBannerInput
  ) {
    updateJobPageRightSideBanner(
      bannerId: $bannerId
      sideBannerInput: $sideBannerInput
    ) {
      ok
      message
    }
  }
`

export const REMOVE_JOB_PAGE_RIGHT_SIDE_BANNER = gql`
  mutation RemoveJobPageRightSideBanner($bannerId: Int!) {
    deleteJobPageRightSideBanner(bannerId: $bannerId) {
      ok
      message
    }
  }
`

export const ADD_NEW_PRODUCT_FEATURE_ANNOUNCEMENT = gql`
  mutation AddProductFeatureBanner(
    $productFeatureBannerInput: ProductFeatureBannerInput
  ) {
    addProductFeatureBanner(
      productFeatureBannerInput: $productFeatureBannerInput
    ) {
      ok
      message
    }
  }
`

export const UPDATE_NEW_PRODUCT_FEATURE_ANNOUNCEMENT = gql`
  mutation UpdateProductFeatureBanner(
    $featureId: Int!
    $productFeatureBannerInput: ProductFeatureBannerInput
  ) {
    updateProductFeatureBanner(
      featureId: $featureId
      productFeatureBannerInput: $productFeatureBannerInput
    ) {
      ok
      message
    }
  }
`

export const REMOVE_NEW_PRODUCT_FEATURE_ANNOUNCEMENT = gql`
  mutation RemoveProductFeatureBanner($featureId: Int!) {
    deleteProductFeatureBanner(featureId: $featureId) {
      ok
      message
    }
  }
`
