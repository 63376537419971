import React from 'react'
// import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Mutation, compose, graphql } from 'react-apollo'
import { DEACTIVATE_SUBSCRIPTION } from '../../../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Bootstrap */
import { Button } from 'reactstrap'

/**
 * CancelSubscriptionButton - Handles the Apollo logic side of the send invoice and send receipt buttons
 *
 * @returns {Component}
 */
const CancelSubscriptionButton = ({ refetch, subscriptionDetail }) => {
  const style = {
    bold: {
      fontWeight: 600
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px'
  }

  return (
    <div>
      <Can I="edit" a="invoice">
        <Mutation mutation={DEACTIVATE_SUBSCRIPTION}>
          {(deactiveSubscription, { loading }) => {
            if (loading) return <Loading />
            return (
              <div style={style}>
                <Button
                  color="danger"
                  onClick={async () => {
                    try {
                      if (
                        window.confirm(
                          `Confirm to deactivate subscription ${
                            subscriptionDetail.id
                          }`
                        )
                      ) {
                        const variables = {
                          subscriptionId: subscriptionDetail.id
                        }
                        await deactiveSubscription({ variables: variables })
                        refetch()
                        return
                      }
                    } catch (err) {
                      console.log('err', err)
                    }
                  }}
                >
                  Deactivate Plan
                </Button>
              </div>
            )
          }}
        </Mutation>
      </Can>
    </div>
  )
}

CancelSubscriptionButton.propTypes = {
  subscriptionDetail: PropTypes.object.isRequired,
  deactiveSubscription: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
}

export default compose(
  graphql(DEACTIVATE_SUBSCRIPTION, { name: 'deactiveSubscription' })
)(CancelSubscriptionButton)
