import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'

import { GET_HEADHUNTERS_UNDER_AGENCY } from '../../../schemas/queries'

/* Components */
import HeadhunterList from '../../../views/AgencyDetail/Headhunters/HeadhunterList'
import Loading from 'shared/components/Loading'

/**
 * HeadhunterListContainer - container component to display list of headhunters under
 * an agency
 *
 * @returns {Component}
 *
 */
class HeadhunterListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page,
      companyId: props.match.params.id
    }
  }

  render() {
    const { page, companyId } = this.state

    let variables = {
      page: parseInt(page),
      companyId: parseInt(companyId)
    }

    return (
      <Query query={GET_HEADHUNTERS_UNDER_AGENCY} variables={variables}>
        {({ loading, data: { headhunterList } }) => {
          if (loading) {
            return <Loading />
          }
          if (headhunterList && headhunterList.data) {
            return (
              <HeadhunterList
                headhunterList={headhunterList.data.headhunters}
                currentPage={page}
                totalHeadhunters={headhunterList.data.totalNum}
                totalPages={headhunterList.data.totalPages}
              />
            )
          }
          return null
        }}
      </Query>
    )
  }
}

export default withRouter(HeadhunterListContainer)
