const noticePeriodOption = [
  {
    id: 1,
    value: 'Immediate'
  },
  {
    id: 2,
    value: 'One week'
  },
  {
    id: 3,
    value: 'Two weeks'
  },
  {
    id: 4,
    value: 'Three weeks'
  },
  {
    id: 5,
    value: 'One month'
  },
  {
    id: 6,
    value: 'Two months or above'
  }
]

export default noticePeriodOption
