import React from 'react'

/* Bootstrap */
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

/* Components */
import AddForm from './AddForm'

const MaintenanceAdd = () => (
  <div>
    <Container>
      <Row style={{ justifyContent: 'center' }}>
        <Col md="10">
          <div>
            <Card>
              <CardBody>
                <CardTitle>Add Maintenance</CardTitle>
                <AddForm />
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default MaintenanceAdd
