import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Component */
import Loading from 'shared/components/Loading'
import ImageField from 'shared/components/ImageField'

/* Helpers */
// import { getCountries, getLocations } from 'shared/helpers/locations'
import { locationList } from 'shared/helpers/locations'
import { companySizeList } from 'shared/helpers/companySize'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'
import { useState, useEffect } from 'react'

/**
 * AgencyInformationForm - form component for Company page - Information section
 *
 * @returns {Component}
 */
const AgencyInformationForm = ({
  agencyId,
  agencyDetail,
  handleChange,
  saveCompany,
  addAgency,
  isLoading,
  handleImageChange,
  previewLogo,
  previewCover,
  submitType,
  errorMessage
  // ...props
}) => {
  const [locations, setLocations] = useState([])
  useEffect(async () => {
    const updateLocation = async () => {
      const locations = await locationList()
      setLocations(locations)
    }
    updateLocation()
  }, [])
  return (
    <ApolloConsumer>
      {client => (
        <Form>
          {agencyId === 'new' ? null : (
            <div style={{ textAlign: 'center', marginBottom: '2em' }}>
              <Can I="edit" a="companies" passThrough>
                {can => (
                  <ImageField
                    hasPermission={can}
                    name="agencyLogo"
                    imageSrc={
                      previewLogo !== ''
                        ? previewLogo
                        : agencyDetail && agencyDetail.logo
                          ? agencyDetail.logo
                          : previewLogo
                    }
                    handleChange={handleImageChange}
                  />
                )}
              </Can>
            </div>
          )}
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Agency Name
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="name"
                    value={
                      agencyDetail && agencyDetail.name
                        ? agencyDetail.name
                        : null
                    }
                    // value={
                    //   agencyDetail && agencyDetail.name ? agencyDetail.name : null
                    // }
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Location
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    type="select"
                    name="location"
                    value={
                      agencyDetail &&
                      agencyDetail.location &&
                      agencyDetail.location.id
                        ? agencyDetail.location.id
                        : null
                    }
                    onChange={handleChange}
                  >
                    <option key="null" />
                    {locations.map(location => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Full Address
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="address"
                    // value={address}
                    value={
                      agencyDetail && agencyDetail.address
                        ? agencyDetail.address
                        : null
                    }
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Website
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="websiteUrl"
                    // value={websiteUrl}
                    value={
                      agencyDetail && agencyDetail.websiteUrl
                        ? agencyDetail.websiteUrl
                        : null
                    }
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Contact email
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="email"
                    // value={email}
                    value={
                      agencyDetail && agencyDetail.email
                        ? agencyDetail.email
                        : null
                    }
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Mobile
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="contactNum"
                    type="number"
                    // value={contactNum}
                    value={
                      agencyDetail && agencyDetail.contactNum
                        ? agencyDetail.contactNum
                        : null
                    }
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          {agencyId === 'new' ? null : (
            <Row>
              <Col md={6}>
                <FormGroup row>
                  <Label md={3} sm={12}>
                    Company Size
                  </Label>
                  <Col md={9} sm={12}>
                    <Input
                      name="companySize"
                      type="select"
                      value={
                        agencyDetail &&
                        agencyDetail.companySize &&
                        agencyDetail.companySize.id
                          ? agencyDetail.companySize.id
                          : null
                      }
                      onChange={handleChange}
                    >
                      <option key="null" />
                      {companySizeList().map(companySize => (
                        <option key={companySize.id} value={companySize.id}>
                          {companySize.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup row>
                  <Label md={3} sm={12}>
                    Founding Year
                  </Label>
                  <Col md={9} sm={12}>
                    <Input
                      name="foundingYear"
                      type="number"
                      value={
                        agencyDetail && agencyDetail.foundingYear
                          ? agencyDetail.foundingYear
                          : null
                      }
                      onChange={handleChange}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  POEA License
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="poeaLicense"
                    // value={license}
                    value={
                      agencyDetail && agencyDetail.poeaLicense
                        ? agencyDetail.poeaLicense
                        : null
                    }
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  DOLE License
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="doleLicense"
                    // value={license}
                    value={
                      agencyDetail && agencyDetail.doleLicense
                        ? agencyDetail.doleLicense
                        : null
                    }
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {agencyId === 'new' ? null : (
              <Col md={6}>
                <FormGroup row>
                  <Label md={3} sm={12}>
                    Status
                  </Label>
                  <Col md={9} sm={12}>
                    <Input
                      name="isActive"
                      type="select"
                      value={
                        agencyDetail && agencyDetail.isActive
                          ? agencyDetail.isActive
                          : null
                      }
                      onChange={handleChange}
                    >
                      <option
                        selected={
                          agencyDetail && agencyDetail.isActive == true
                            ? true
                            : false
                        }
                        value={true}
                      >
                        Active
                      </option>
                      <option
                        selected={
                          agencyDetail && agencyDetail.isActive == false
                            ? true
                            : false
                        }
                        value={false}
                      >
                        Inactive
                      </option>
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            )}
            {agencyId === 'new' ? null : (
              <Col md={6}>
                <FormGroup row>
                  <Label md={3} sm={12}>
                    Featured Status
                  </Label>
                  <Col md={9} sm={12}>
                    <Input
                      name="isFeatured"
                      type="select"
                      value={
                        agencyDetail && agencyDetail.isFeatured
                          ? agencyDetail.isFeatured
                          : null
                      }
                      onChange={handleChange}
                    >
                      <option
                        selected={
                          agencyDetail && agencyDetail.isFeatured == true
                            ? true
                            : false
                        }
                        value={true}
                      >
                        Featured
                      </option>
                      <option
                        selected={
                          agencyDetail && agencyDetail.isFeatured == false
                            ? true
                            : false
                        }
                        value={false}
                      >
                        Not Featured
                      </option>
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            )}
          </Row>
          {agencyId === 'new' ? null : (
            <Row>
              <Col md={6}>
                <FormGroup row>
                  <Label md={3} sm={12}>
                    Cover Picture
                  </Label>
                  <div style={{ padding: '15px' }}>
                    <Can I="edit" a="companies" passThrough>
                      {can => (
                        <ImageField
                          hasPermission={can}
                          name="agencyCover"
                          imageSrc={
                            previewCover !== ''
                              ? previewCover
                              : agencyDetail && agencyDetail.cover
                                ? agencyDetail.cover
                                : null
                          }
                          handleChange={handleImageChange}
                        />
                      )}
                    </Can>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={6}>
              <Label style={{ color: 'red' }} md={12} sm={12}>
                {errorMessage}
              </Label>
            </Col>
          </Row>
          <Can I="edit" a="companies">
            <div style={{ textAlign: 'right' }}>
              {isLoading ? (
                <Loading />
              ) : submitType === 'add' ? (
                <Button
                  color="info"
                  onClick={() => addAgency(client)}
                  disabled={isLoading}
                >
                  Add
                </Button>
              ) : (
                <Button
                  color="info"
                  onClick={() => saveCompany(client)}
                  disabled={isLoading}
                >
                  Update
                </Button>
              )}
            </div>
          </Can>
        </Form>
      )}
    </ApolloConsumer>
  )
}

AgencyInformationForm.propTypes = {
  agencyId: PropTypes.any,
  agencyDetail: PropTypes.object,
  handleChange: PropTypes.func,
  saveCompany: PropTypes.func,
  addAgency: PropTypes.func,
  isLoading: PropTypes.bool,
  handleImageChange: PropTypes.func,
  previewLogo: PropTypes.string,
  previewCover: PropTypes.string,
  locationList: PropTypes.func,
  submitType: PropTypes.string,
  props: PropTypes.object,
  errorMessage: PropTypes.string
}

export default AgencyInformationForm
