import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { compose, graphql } from 'react-apollo'
import { DELETE_ADMIN_USERS } from '../../schemas/mutations'

/* Components */
import UserListContainer from '../../containers/UserListContainer'
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * Users - main user list view
 *
 */
class Users extends Component {
  static propTypes = {
    deleteAdminUsers: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedAdminUsers: []
    }

    this.appendSelectedUser = this.appendSelectedUser.bind(this)
    this.removeSelectedUser = this.removeSelectedUser.bind(this)
    this.deleteUsers = this.deleteUsers.bind(this)
  }

  /**
   *
   * @function appendSelectedUser - Append userId to selectedAdminUsers state
   * @param {Number} userId
   */
  appendSelectedUser(userId) {
    this.setState({
      selectedAdminUsers: [...this.state.selectedAdminUsers, Number(userId)]
    })
  }

  /**
   *
   * @function removeSelectedUser - Remove specific userId from selectedAdminUsers state
   * @param {Number} userId
   */
  removeSelectedUser(userId) {
    let selectedAdminUsers = this.state.selectedAdminUsers
    selectedAdminUsers.splice(
      this.state.selectedAdminUsers.indexOf(Number(userId)),
      1
    )
    this.setState({
      selectedAdminUsers: selectedAdminUsers
    })
  }

  /**
   *
   * @function deleteUsers - call network mutation to delete selected admin users
   * @param {Number} userId
   */
  async deleteUsers() {
    try {
      let response = await this.props.deleteAdminUsers({
        variables: {
          ids: this.state.selectedAdminUsers
        }
      })

      if (response) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    return (
      <div>
        <SearchBar />
        <Can I="edit" a="admin_user" passThrough>
          {can => (
            <SectionWrapper
              sectionTitle="Users"
              isMainPage
              addUrl={can ? '/user/add' : null}
              handleDelete={can ? this.deleteUsers : null}
            >
              <UserListContainer
                appendSelectedUser={this.appendSelectedUser}
                removeSelectedUser={this.removeSelectedUser}
              />
            </SectionWrapper>
          )}
        </Can>
      </div>
    )
  }
}

export default compose(
  graphql(DELETE_ADMIN_USERS, { name: 'deleteAdminUsers' })
)(Users)
