import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Components */
import RecruiterJobListContainer from '../../../../containers/BossJobUserDetailContainer/RecruiterJobListContainer'
import SectionWrapper from 'shared/components/SectionWrapper'
import SearchBar from 'shared/components/SearchBar'
import Loading from 'shared/components/Loading'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Bootstrap */
import { Button } from 'reactstrap'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_BOSSJOB_RECRUITER_SETTINGS } from '../../../../schemas/queries'
import { CREATE_DRAFT_JOB } from '../../../../../jobs/schemas/mutations'

/* Vendors */
/**
 * Jobs - main recruiter job list view
 *
 */
const Jobs = ({ history, match }) => {
  const statusOptions = [
    { all: 'All' },
    { active: 'Active' },
    { expired: 'Expired' },
    { closed: 'Closed' },
    { deleted: 'Deleted' },
    { pending: 'Pending' },
    { draft: 'Draft' },
    { change_required: 'Change Required' }
  ]

  const createDraftJob = async apolloClient => {
    try {
      const result = await apolloClient.mutate({
        mutation: CREATE_DRAFT_JOB,
        variables: {
          recruiterId: parseInt(match.params.id)
        }
      })
      const { ok, jobId } = result.data.createDraftJob
      if (ok) {
        if (
          window.confirm(`Do you want to redirect to job(${jobId}) created?`)
        ) {
          history.push(`/bossjob/job/${jobId}`)
        }
      }
    } catch (err) {
      window.alert('Error:', err)
    }
  }

  const renderJobActionButtons = (settings, client) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          color="primary"
          style={{ marginRight: '10px' }}
          onClick={() => createDraftJob(client)}
          disabled={settings.statusKey !== 'active'}
        >
          Create Draft Job
        </Button>
        <Button
          color="primary"
          onClick={() =>
            history.push(
              `/bossjob/recruiter/${match.params.id}/job/new?companyId=${
                settings.companyId
              }`
            )
          }
          disabled
        >
          Post Job
        </Button>
        {settings.statusKey !== 'active' && (
          <div style={{ color: 'red', fontSize: '10px' }}>
            This user is not an active recruiter
          </div>
        )}
      </div>
    )
  }

  return (
    <Query
      query={GET_BOSSJOB_RECRUITER_SETTINGS}
      variables={{ userId: match.params.id }}
    >
      {({ loading, data, client }) => {
        if (loading) return <Loading />
        return (
          <div>
            <SectionWrapper
              sectionTitle="Jobs"
              isMainPage
              otherActions={renderJobActionButtons(
                data.recruiterSettings.data,
                client
              )}
            >
              <SearchBar
                hasStatus
                hasSupportedCountry
                statusOptions={statusOptions}
              />
              <RecruiterJobListContainer />
            </SectionWrapper>
          </div>
        )
      }}
    </Query>
  )
}

Jobs.propTypes = {
  history: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired
}

export default withRouter(Jobs)
