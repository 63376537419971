import gql from 'graphql-tag'

/*====== Fragments =====*/
/* user detail */
const roleNodeFragment = gql`
  fragment RoleNode on RoleNodeType {
    id
    key
    description
    canView
    canEdit
    isActive
  }
`
/*====== End Fragments =====*/

/* ==== Local State ==== */
/* Role Nodes */
export const GET_LOCAL_ROLE_NODES = gql`
  {
    rolePermissions @client {
      ...RoleNode
    }
  }
  ${roleNodeFragment}
`
/* ==== End Local State ==== */

/** Get all role list */
export const GET_ALL_ROLES = gql`
  query getAllRoles($query: String, $date: DateTimeRangeRoles) {
    allRoles(IDOrName: $query, date: $date) {
      id
      roleName
      createdAt
      updatedAt
      isActive
    }
  }
`

/** Get role by id */
export const GET_ROLE_BY_ID = gql`
  query getRoleByID($id: String) {
    allRoles(IDOrName: $id) {
      id
      roleName
      createdAt
      updatedAt
      isActive
      roleNodes {
        ...RoleNode
      }
    }
  }
  ${roleNodeFragment}
`
