import React from 'react'
import PropTypes from 'prop-types'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Bootstrap */
import { Row, Form, FormGroup, Label, Button, Input } from 'reactstrap'

const CompanyAuthenticationForm = ({
  errorMessage,
  apolloClient,
  authStatusKey,
  isTextareaInvalid,
  isRejectTextareaInvalid,
  handleSelectChange,
  handleSubmit,
  handleRequireChangeReason,
  handleRejectedReason,
  canEdit,
  isShowApproveReason,
  handleApproveReason
}) => {
  return (
    <Form>
      <Row style={{ paddingLeft: '1em' }}>
        <FormGroup>
          <Label for="statusKey">Status Key</Label>
          <Input
            type="select"
            name="statusKey"
            value={authStatusKey}
            onChange={e => {
              handleSelectChange(`${e.target.value}`)
            }}
          >
            <option value="approve">Approve</option>
            <option value="reject">Reject</option>
            <option value="change_required">Change Required</option>
          </Input>
        </FormGroup>
      </Row>

      {isShowApproveReason ? null : (
        <Row style={{ paddingLeft: '1em' }}>
          <FormGroup>
            <Label for="rejectedReason">Approve Reason</Label>
            <Input
              type="textarea"
              name="rejectedReason"
              id="rejectedReason"
              onChange={e => handleApproveReason(`${e.target.value}`)}
              disabled={isShowApproveReason}
            />
          </FormGroup>
        </Row>
      )}

      {isTextareaInvalid ? null : (
        <Row style={{ paddingLeft: '1em' }}>
          <FormGroup>
            <Label for="changeRequiredReason">Change Required Reason</Label>
            <Input
              type="textarea"
              name="changeRequiredReason"
              id="changeRequiredReason"
              onChange={e => handleRequireChangeReason(`${e.target.value}`)}
              disabled={isTextareaInvalid}
            />
          </FormGroup>
        </Row>
      )}
      {isRejectTextareaInvalid ? null : (
        <Row style={{ paddingLeft: '1em' }}>
          <FormGroup>
            <Label for="rejectedReason">Rejected Reason</Label>
            <Input
              type="textarea"
              name="rejectedReason"
              id="rejectedReason"
              onChange={e => handleRejectedReason(`${e.target.value}`)}
              disabled={isRejectTextareaInvalid}
            />
          </FormGroup>
        </Row>
      )}
      <Row style={{ paddingLeft: '1em' }}>
        <Can I="edit" a="companies">
          <Button
            color="info"
            disabled={!canEdit}
            onClick={() => handleSubmit(apolloClient)}
          >
            Submit
          </Button>
        </Can>
      </Row>
      <span>{errorMessage ? errorMessage : ''}</span>
    </Form>
  )
}

CompanyAuthenticationForm.propTypes = {
  isUpdating: PropTypes.bool,
  errorMessage: PropTypes.string,
  apolloClient: PropTypes.object,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
  companyDetail: PropTypes.object,
  authStatusKey: PropTypes.string,
  isTextareaInvalid: PropTypes.bool.isRequired,
  isRejectTextareaInvalid: PropTypes.bool.isRequired,
  handleSelectChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleRequireChangeReason: PropTypes.func.isRequired,
  handleRejectedReason: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  isShowApproveReason: PropTypes.bool,
  handleApproveReason: PropTypes.func
}

export default CompanyAuthenticationForm
