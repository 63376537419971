import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, Label, Button, FormGroup, Input, Col } from 'reactstrap'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * Company Status - main wrapper component for the Company page - Company Status section
 *
 * @returns {Component}
 */
class CompanyStatus extends Component {
  constructor(props) {
    super(props)
  }

  handleClick() {
    if (
      confirm(
        'All jobs and recruiters under this company will be removed and company will be deleted, are you sure you want to deactivate this company?'
      )
    ) {
      this.props.deactivateCompany(this.props.apolloClient)
    }
  }

  render() {
    const { isLoading, isVerify, canEdit } = this.props
    return (
      <Form>
        <FormGroup row>
          <Label sm={2}>
            Company Status: {isVerify ? 'Verified' : 'Unverified'}
          </Label>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="deletedReason">
            Delete Company Reason
          </Label>
          <Col sm={10}>
            <Input
              type="textarea"
              name="deletedReason"
              id="deletedReason"
              onChange={e =>
                this.props.handleDeleteCompanyReason(`${e.target.value}`)
              }
            />
          </Col>
        </FormGroup>
        <Can I="edit" a="companies">
          <div style={{ textAlign: 'right' }}>
            <Button
              color="danger"
              disabled={isLoading || !canEdit}
              onClick={() => this.handleClick()}
            >
              Delete Company
            </Button>
          </div>
        </Can>
      </Form>
    )
  }
}

CompanyStatus.propTypes = {
  handleChange: PropTypes.func.isRequired,
  apolloClient: PropTypes.object.isRequired,
  activateCompany: PropTypes.func.isRequired,
  deactivateCompany: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isVerify: PropTypes.bool.isRequired,
  handleDeleteCompanyReason: PropTypes.func.isRequired,
  canEdit: PropTypes.bool
}

export default CompanyStatus
