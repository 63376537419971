import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, compose, graphql } from 'react-apollo'
import { UPDATE_BOSSJOB_USER_DETAIL } from '../../../schemas/mutations'
import { GET_LOCAL_BOSSJOB_USER_DETAIL } from '../../../schemas/queries'

/* Components */
import InformationForm from '../../../views/BossJobUserDetail/UserProfile/InformationForm'

/**
 * InformationFormContainer - container component to mutate Bossjob user information
 * and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class InformationFormContainer extends Component {
  static propTypes = {
    updateUserDetail: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isUpdating: false
    }

    this.handleUpdate = this.handleUpdate.bind(this)
  }

  async handleUpdate(payload) {
    this.setState({
      isUpdating: true
    })

    let response = await this.props.updateUserDetail({
      variables: payload
    })

    if (response.data.updateBossjobUsers.ok) {
      window.location.reload()
    }
  }

  render() {
    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <InformationForm
            userData={bossjobUserDetail}
            updateUser={this.handleUpdate}
            isUpdating={this.state.isUpdating}
          />
        )}
      </Query>
    )
  }
}

export default compose(
  graphql(UPDATE_BOSSJOB_USER_DETAIL, { name: 'updateUserDetail' })
)(InformationFormContainer)
