import React from 'react'
import { Link } from 'react-router-dom'

/* Bootstrap Component */
import {
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

/* Helpers */
import { logout } from '../../helpers/authentication'

/* Assets */
import BossJobLogo from '../../assets/images/bossjobLogo.png'
import styles from './Header.module.scss'

/**
 * Header - header component to render navigation bar
 */
const Header = () => (
  <header>
    <Navbar className={styles.nav}>
      <NavbarBrand href="/">
        <img src={BossJobLogo} width="104" height="28" />
      </NavbarBrand>
      <Nav navbar>
        <UncontrolledDropdown>
          <DropdownToggle nav caret>
            My Account
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <Link to="/user/myaccount">Account Setting</Link>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                logout()
                window.location = '/login'
              }}
              style={{ cursor: 'pointer' }}
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  </header>
)

export default Header
