import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_BOSSJOB_USER_CHAT_LIST_BY_ID } from '../../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import MessageList from '../../../views/BossJobUserDetail/Chat/MessageList'

/**
 * MessageListContainer - Displays Bossjob User's role messages
 *
 * @returns {Component}
 */
class MessageListContainer extends Component {
  static propTypes = {
    userType: PropTypes.string.isRequired,
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      userId: props.match.params.id,
      page: props.match.params.page
    }
  }

  render() {
    const { userType } = this.props
    const { userId, page } = this.state
    const { search, fromDate, toDate } = queryString.parse(location.search)
    let variables = {
      userId: parseInt(userId),
      userType: parseInt(userType),
      page: page,
      query: search ? search : null,
      date:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null
    }

    return (
      <Query query={GET_BOSSJOB_USER_CHAT_LIST_BY_ID} variables={variables}>
        {({ loading, data: { userDialogues } }) => {
          if (loading) return <Loading />
          return (
            <MessageList
              dialoguesList={userDialogues.data.dialogues}
              totalCount={userDialogues.data.totalCount}
              totalPages={userDialogues.data.totalPages}
              currentPage={userDialogues.data.currentPage}
            />
          )
        }}
      </Query>
    )
  }
}

export default withRouter(MessageListContainer)
