import React, { useState } from 'react'

/* Bootstrap */
import { Col, Card, CardTitle, CardBody } from 'reactstrap'

/* Components */
import SearchBar from 'shared/components/SearchBar'
import JobsListContainer from '../../containers/JobsListContainer'
import CloseJobButton from '../../containers/CloseJobButton'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Assets */
import styles from 'shared/assets/scss/main.module.scss'

// BossJobUsers - main Bossjob user list view
const Jobs = () => {
  const [selectedJobs, setSelectedJobs] = useState([])
  const statusOptions = [
    { all: 'All' },
    { active: 'Active' },
    { expired: 'Expired' },
    { closed: 'Closed' },
    { deleted: 'Deleted' },
    { pending: 'Pending' },
    { draft: 'Draft' },
    { change_required: 'Change Required' },
    { rejected: 'Rejected' },
    { company_pending_verification: 'Company Pending Verification' }
  ]

  /**
   * @function appendSelectedJob - Append jobId to selectedJobs state
   */
  const appendSelectedJob = (jobId, recruiterId) => {
    let jobObject = { [jobId]: recruiterId }
    setSelectedJobs([...selectedJobs, jobObject])
  }

  /**
   * @function removeSelectedJob - Remove specific jobId from selectedJobs state
   */
  const removeSelectedJob = (jobId, recruiterId) => {
    let jobObject = { [jobId]: recruiterId }
    let selectedJobIds = [...selectedJobs]
    selectedJobIds.splice(selectedJobs.indexOf(jobObject), 1)
    setSelectedJobs(selectedJobIds)
  }

  return (
    <div>
      <SearchBar
        hasStatus
        hasSupportedCountry
        hasWorkArrangementFilter
        statusOptions={statusOptions}
      />
      <Col md="12">
        <Card>
          <CardTitle className={styles.pageTitle}>
            Jobs
            <Can I="edit" a="jobs">
              <div>
                <CloseJobButton selectedJobs={selectedJobs} />
              </div>
            </Can>
          </CardTitle>
          <CardBody>
            <JobsListContainer
              appendSelectedJob={appendSelectedJob}
              removeSelectedJob={removeSelectedJob}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}

export default Jobs
