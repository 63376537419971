import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_COMPANY_DETAIL } from '../../../schemas/queries'

/* Components */
import PhotoAlbumForm from '../../../views/CompanyDetail/CompanyInformation/PhotoAlbumForm/PhotoAlbumForm'

/* Bootstrap */
import { Button } from 'reactstrap'

/**
 * CompanyPhotoAlbumContainer - container component to query and mutate
 * company information and document and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class CompanyPhotoAlbumContainer extends Component {
  static propTypes = {
    canEdit: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      edit: false
    }
  }

  handleEdit = () => {
    this.setState({
      edit: true
    })
  }
  handleCancel = () => {
    this.setState({
      edit: false
    })
  }

  render() {
    const { edit } = this.state
    const { canEdit } = this.props
    const photoArray = Array.from(Array(6), (value, index) => index + 1)
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {edit ? null : (
          <Button
            onClick={this.handleEdit}
            color="info"
            style={{ display: 'flex', alignSelf: 'flex-end' }}
            disabled={!canEdit}
          >
            Edit
          </Button>
        )}

        {edit ? (
          <Button
            onClick={this.handleCancel}
            color="danger"
            style={{ display: 'flex', alignSelf: 'flex-end' }}
          >
            Cancel
          </Button>
        ) : null}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Query query={GET_LOCAL_COMPANY_DETAIL}>
            {({ data: { companyDetail } }) => (
              <React.Fragment>
                {photoArray.map(value => {
                  return (
                    <PhotoAlbumForm
                      key={value}
                      companyData={companyDetail}
                      sortOrder={value}
                      edit={edit}
                    />
                  )
                })}
              </React.Fragment>
            )}
          </Query>
        </div>
      </div>
    )
  }
}

export default CompanyPhotoAlbumContainer
