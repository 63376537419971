import React from 'react'
import { Link } from 'react-router-dom'

import CONFIG from 'config'

/* Assets */
import styles from './Sidebar.module.scss'

/**
 * Sidebar - sidebar to show admin application menus
 */
const Sidebar = () => {
  const handleGoingToNeptune = () => {
    const url =
      CONFIG.HOST_ENV === 'production'
        ? 'https://neptune-admin.bossjob.com'
        : 'https://demo-neptune-admin.bossjob.com'

    const token = localStorage.getItem('accessToken')

    if (token) {
      window.open(url + '?accessToken=' + token, '_blank')
    }
  }

  return (
    <nav className={styles.sidebar}>
      <ul className={styles.mainNavigation}>
        <li>
          Manage
          <ul className={styles.subNavigation}>
            <li>
              <Link to="/bossjob/users">Users</Link>
            </li>
            <li>
              <Link to="/bossjob/companies">Companies</Link>
            </li>
            <li>
              <Link to="/bossjob/NeptuneCompanies">Neptune Company Check</Link>
            </li>
            <li>
              <Link to="/bossjob/jobs">Jobs</Link>
            </li>
            {/* <li>
            <Link to="/bossjob/chat">Dialogues</Link>
          </li> */}
          </ul>
        </li>
        <li>
          Tool
          <ul className={styles.subNavigation}>
            {/* <li>
            <Link to="/bossjob/invoices">Invoice</Link>
          </li> */}
            <li>
              <Link to="/bossjob/transactions">Transactions</Link>
            </li>
            <li>
              <Link to="/bossjob/subscriptions">Subscriptions</Link>
            </li>
            <li>
              <Link to="/bossjob/subscription-plans">Subscription Plans</Link>
            </li>
          </ul>
        </li>
        <li>
          Marketing
          <ul className={styles.subNavigation}>
            <li>
              <Link to="/bossjob/featured-company/list">
                Featured Companies
              </Link>
            </li>
            <li>
              <Link to="/bossjob/landing-page-banner">
                Company Page Banners
              </Link>
            </li>
            {/* <li>
            <Link to="/bossjob/left-side-banner">
              Job Page Left Side Banners
            </Link>
          </li>
          <li>
            <Link to="/bossjob/right-side-banner">
              Job Page Right Side Banners
            </Link>
          </li>
          <li>
            <Link to="/bossjob/new-product-feature-announcement">
              New Product Feature Announcement
            </Link>
          </li> */}
          </ul>
        </li>
        {/* <li>
        Bosshunt
        <ul className={styles.subNavigation}>
          <li>
            <Link to="/bosshunt/users">Users</Link>
          </li>
          <li>
            <Link to="/bosshunt/agencies">Agencies</Link>
          </li>
          <li>
            <Link to="/bosshunt/request">Request Demo</Link>
          </li>
        </ul>
      </li> */}
        <li>
          Utility
          <ul className={styles.subNavigation}>
            <li>
              <Link to="/bossjob/bulk-registration-document">Import Users</Link>
            </li>
            <li>
              <Link to="/bossjob/join-company-data">Join Company Data</Link>
            </li>
          </ul>
        </li>
      </ul>
      <ul className={styles.mainNavigation}>
        <li>
          Access
          <ul className={styles.subNavigation}>
            <li>
              <Link to="/users">Users</Link>
            </li>
            <li>
              <Link to="/roles">Roles</Link>
            </li>
          </ul>
        </li>
      </ul>
      <ul className={styles.mainNavigation}>
        <li>
          Events
          <ul className={styles.subNavigation}>
            <li>
              <Link to="/events/list/1">list</Link>
            </li>
            <li>
              <Link to="/event-statistics">Statistics</Link>
            </li>
          </ul>
        </li>
      </ul>
      <ul className={styles.mainNavigation}>
        <li>
          NOTIFICATION
          <ul className={styles.subNavigation}>
            <li>
              <Link to="/maintenance/list/1">Maintenance</Link>
            </li>
          </ul>
        </li>
      </ul>

      <ul className={styles.mainNavigation}>
        <li>
          <span onClick={handleGoingToNeptune} className={styles.neptune}>
            Neptune
          </span>
        </li>
      </ul>
    </nav>
  )
}

export default Sidebar
