import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, Mutation } from 'react-apollo'
import {
  GET_BOSSJOB_USER_SKILLS,
  GET_LOCAL_BOSSJOB_USER_DETAIL
} from '../../../../schemas/queries'
import { UPDATE_BOSSJOB_USER_SKILLS } from '../../../../schemas/mutations'

/* Bootstrap */
import { Row, Col, Badge, Label, Button } from 'reactstrap'

/* Components */
//import SkillsForm from './SkillsForm'
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import SearchWithSuggestion from 'shared/components/SearchWithSuggestion'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Assets */
import styles from './Skills.module.scss'

/**
 * Skills - main wrapper component for the Profile page - Skills section
 *
 * @returns {Component}
 */
class Skills extends Component {
  static propTypes = {
    // If this is "jobDetail", it's being rendered in the jobDetail form
    type: PropTypes.string,

    // Passed down from jobDetailInformationForm to update it's local state
    handleChange: PropTypes.func,

    // Passed down from jobDetailInformationForm's skills
    skills: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      skillList: null,
      userId: null,
      errorMessage: ''
    }
  }

  handleJobSkillsChange = skills => {
    this.updateSkillList(skills)
    this.props.handleChange(this.state.skillList.join(','))
  }

  updateSkillList = skills => {
    let list = this.state.skillList
    if (list.length > 9) {
      this.setState({ errorMessage: 'Only a maximum of 10 skills allowed' })
    } else {
      list.push(skills)
      this.setState({ skillList: list })
    }
  }

  removeSkill(index) {
    const { type } = this.props

    let list = this.state.skillList
    list.splice(index, 1)

    this.setState({ skillList: list }, () => {
      if (type === 'jobDetail') {
        this.props.handleChange(this.state.skillList.join(','))
      }
    })
  }

  renderSkills(skills, userStatusKey) {
    const { type } = this.props

    if (this.state.skillList === null) {
      this.setState({
        skillList:
          skills && skills.jobSkills !== '' && skills.jobSkills !== null
            ? skills.jobSkills.split(',')
            : []
      })

      return null
    }

    return (
      <Col md={12}>
        <Row>
          <Label md={2}>Skills</Label>
          <Col md={10}>
            {userStatusKey !== 'inactive' && (
              <Can I="edit" a="bossjob_user">
                <SearchWithSuggestion
                  handleClick={
                    type === 'jobDetail'
                      ? this.handleJobSkillsChange
                      : this.updateSkillList
                  }
                  searchApi="/search/skill_lists"
                />
              </Can>
            )}
            <Row>
              <Label className={styles.errorMessage}>
                {this.state.errorMessage}
              </Label>
            </Row>
            {this.state.skillList.length !== 0 &&
            this.state.skillList[0] !== '' ? (
                this.state.skillList.map((skill, index) => (
                  <div className={styles.skillItem} key={`skill-${index}`}>
                    <Badge style={{ fontSize: '0.9em' }} color="secondary" pill>
                      {skill}
                    </Badge>
                    <Can I="edit" a="bossjob_user">
                      <span
                        className={styles.delete}
                        onClick={() => this.removeSkill(index)}
                      >
                      x
                      </span>
                    </Can>
                  </div>
                ))
              ) : (
                <div>No skill has been added</div>
              )}
          </Col>
        </Row>
        {type !== 'jobDetail' &&
          userStatusKey !== 'inactive' && (
          <Can I="edit" a="bossjob_user">
            <div style={{ textAlign: 'right' }}>
              <Button
                color="danger"
                onClick={() => {
                  this.setState({ skillList: null })
                }}
              >
                  Cancel
              </Button>
              <Mutation
                mutation={UPDATE_BOSSJOB_USER_SKILLS}
                onCompleted={() => window.location.reload()}
              >
                {(UpdateSkills, { loading }) => (
                  <Button
                    color="info"
                    disabled={loading}
                    onClick={() => {
                      const variables = {
                        userId: this.state.userId,
                        payload: {
                          skills: this.state.skillList.join(',')
                        }
                      }

                      UpdateSkills({ variables: variables })
                    }}
                  >
                      Save
                  </Button>
                )}
              </Mutation>
            </div>
          </Can>
        )}
      </Col>
    )
  }

  render() {
    const { type, skills } = this.props
    return (
      <div>
        {type === 'jobDetail' ? (
          this.renderSkills({ jobSkills: skills }, 'active')
        ) : (
          <SectionWrapper sectionTitle="Skills">
            <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
              {({ data: { bossjobUserDetail } }) => {
                if (this.state.userId === null) {
                  this.setState({ userId: bossjobUserDetail.id })
                }

                return (
                  <Query
                    query={GET_BOSSJOB_USER_SKILLS}
                    variables={{ userId: bossjobUserDetail.id }}
                    fetchPolicy="network-only"
                  >
                    {({ loading, data, error }) => {
                      if (error) {
                        console.log(error)
                      }
                      if (loading) {
                        return <Loading />
                      }

                      if (data && data.bossjobUser.data) {
                        const skills = data.bossjobUser.data.skills

                        return this.renderSkills(
                          skills,
                          bossjobUserDetail.statusKey
                        )
                      }

                      return null
                    }}
                  </Query>
                )
              }}
            </Query>
          </SectionWrapper>
        )}
      </div>
    )
  }
}

export default Skills
