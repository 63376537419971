import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ADMIN_USER_DETAIL, GET_ME } from '../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import UserDetailFormContainer from '../../containers/UserDetailFormContainer'

const UserDetail = ({ match }) => (
  <Query query={GET_ADMIN_USER_DETAIL} variables={{ id: match.params.id }}>
    {({ loading, data, client }) => {
      if (loading) {
        return <Loading />
      } else {
        const { me } = client.readQuery({
          query: GET_ME
        })

        if (me.id !== match.params.id) {
          client.writeData({
            data: { adminUserDetail: data.adminUser.data }
          })
          return (
            <SectionWrapper sectionTitle="User detail">
              <UserDetailFormContainer />
            </SectionWrapper>
          )
        }
        return null
      }
    }}
  </Query>
)

UserDetail.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default withRouter(UserDetail)
