import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * SocialLinkForm - form component for Company page - Information section
 *
 * @returns {Component}
 */
const SocialLinkForm = ({
  companyDetail: {
    facebookUrl,
    instagramUrl,
    youtubeUrl,
    linkedinUrl,
    linkedinCompanyId
  },
  handleChange,
  handleSave,
  isLoading,
  updateCompanyError,
  canEdit
}) => (
  <ApolloConsumer>
    {client => (
      <Form>
        <div style={{ textAlign: 'center', marginBottom: '2em' }} />
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Facebook
              </Label>
              <Col md={9} sm={12}>
                <Input
                  name="facebookUrl"
                  value={facebookUrl}
                  onChange={e =>
                    handleChange(client, 'facebookUrl', e.target.value)
                  }
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                LinkedIn
              </Label>
              <Col md={9} sm={12}>
                <Input
                  name="linkedinUrl"
                  value={linkedinUrl}
                  onChange={e =>
                    handleChange(client, 'linkedinUrl', e.target.value)
                  }
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Instagram
              </Label>
              <Col md={9} sm={12}>
                <Input
                  name="instagramUrl"
                  value={instagramUrl}
                  onChange={e =>
                    handleChange(client, 'instagramUrl', e.target.value)
                  }
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Youtube
              </Label>
              <Col md={9} sm={12}>
                <Input
                  name="youtubeUrl"
                  value={youtubeUrl}
                  onChange={e =>
                    handleChange(client, 'youtubeUrl', e.target.value)
                  }
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Linkedin Company Id
              </Label>
              <Col md={9} sm={12}>
                <Input
                  name="linkedinCompanyId"
                  value={linkedinCompanyId}
                  onChange={e =>
                    handleChange(client, 'linkedinCompanyId', e.target.value)
                  }
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Label style={{ color: 'red' }} md={12} sm={12}>
              {updateCompanyError}
            </Label>
          </Col>
        </Row>
        <Can I="edit" a="companies">
          <div style={{ textAlign: 'right' }}>
            <Button
              color="info"
              onClick={() => handleSave(client)}
              disabled={isLoading || !canEdit}
            >
              Update
            </Button>
          </div>
        </Can>
      </Form>
    )}
  </ApolloConsumer>
)

SocialLinkForm.propTypes = {
  facebookUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  linkedinCompanyId: PropTypes.string,
  companyDetail: PropTypes.object,
  updateCompanyError: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  canEdit: PropTypes.bool
}

export default SocialLinkForm
