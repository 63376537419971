import React, { useState } from 'react'

/* Apollo */
import { graphql, compose } from 'react-apollo'
import { EXPORT_JOIN_COMPANY_EXCEL } from '../../schemas/mutations'

/* Components */
import { Button } from 'reactstrap'
import ConfirmationAlert from 'shared/components/ConfirmationAlert'

const ExportJoinCompanyButton = ({ exportPayload, exportJoinCompanyExcel }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const exportRequests = async () => {
    exportJoinCompanyExcel({
      variables: exportPayload
    }).then(response => {
      if (response.data && response.data.exportJoinCompanyExcel.ok) {
        alert('Successfully exported data to excel file')
        setLoading(false)
        downloadFile(response.data.exportJoinCompanyExcel.url)
      }
    })
    setIsVisible(false)
  }

  const downloadFile = url => {
    const link = document.createElement('a')
    link.href = url
    link.download = 'file'
    link.click()
  }

  return (
    <>
      <Button color="success" onClick={() => setIsVisible(true)}>
        {loading ? 'Exporting...' : 'Export Requests'}
      </Button>
      <ConfirmationAlert
        isVisible={isVisible}
        title="Export join request data"
        body="Are you sure you want to export all join requests with the selected filters?"
        onConfirm={() => {
          exportRequests()
          setLoading(true)
        }}
        onClose={() => setIsVisible(false)}
      />
    </>
  )
}

export default compose(
  graphql(EXPORT_JOIN_COMPANY_EXCEL, {
    name: 'exportJoinCompanyExcel'
  })
)(ExportJoinCompanyButton)
