exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".searchList__w4br- {\n  position: absolute;\n  top: 40px;\n  background: #fff;\n  z-index: 3;\n  -webkit-box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.16);\n          box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.16);\n}\n.searchList__w4br- ul {\n  margin-left: 0;\n  padding: 10px 15px;\n}\n.searchList__w4br- ul li {\n  list-style: none;\n  padding: 15px 0;\n  border-bottom: 1px solid #d8d8d8;\n  cursor: pointer;\n}", "", {"version":3,"sources":["/app/src/modules/bossjob_users/views/BossJobUserDetail/RecruiterInformation/RecruiterSettingsForm/RecruiterSettingsForm.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAkB;EAClB,UAAS;EACT,iBAAgB;EAChB,WAAU;EACV,uDAA8C;UAA9C,+CAA8C;CAChD;AACA;EACE,eAAc;EACd,mBAAkB;CACpB;AACA;EACE,iBAAgB;EAChB,gBAAe;EACf,iCAAgC;EAChC,gBAAe;CACjB","file":"RecruiterSettingsForm.module.scss","sourcesContent":[".searchList {\n  position: absolute;\n  top: 40px;\n  background: #fff;\n  z-index: 3;\n  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.16);\n}\n.searchList ul {\n  margin-left: 0;\n  padding: 10px 15px;\n}\n.searchList ul li {\n  list-style: none;\n  padding: 15px 0;\n  border-bottom: 1px solid #d8d8d8;\n  cursor: pointer;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"searchList": "searchList__w4br-"
};