import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Col, Form, FormGroup, Label, Button } from 'reactstrap'

/* Component */
import TextEditorField from 'shared/components/TextEditorField'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * DescriptionForm - form component for Company page - Description section
 *
 * @returns {Component}
 */
class DescriptionForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      description: props.description ? props.description : '',
      descriptionHtml: props.descriptionHtml ? props.descriptionHtml : ''
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(html, text) {
    this.props.handleChange(this.props.apolloClient, 'description', text)
    this.props.handleChange(this.props.apolloClient, 'descriptionHtml', html)
  }

  render() {
    const { descriptionHtml, saveCompany, isLoading, canEdit } = this.props

    return (
      <Form>
        <FormGroup row>
          <Label md={2} sm={12}>
            Description
          </Label>
          <Col md={10} sm={12}>
            <Can I="edit" a="companies" passThrough>
              {can =>
                can ? (
                  <div>
                    <TextEditorField
                      value={descriptionHtml ? descriptionHtml : ''}
                      handleChange={this.handleChange}
                    />
                  </div>
                ) : (
                  <div>
                    {descriptionHtml !== '' ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                      />
                    ) : (
                      <em>No company description</em>
                    )}
                  </div>
                )
              }
            </Can>
          </Col>
        </FormGroup>
        <Can I="edit" a="companies">
          <div style={{ textAlign: 'right' }}>
            <Button
              color="info"
              onClick={() => saveCompany(this.props.apolloClient)}
              disabled={isLoading || !canEdit}
            >
              Update
            </Button>
          </div>
        </Can>
      </Form>
    )
  }
}

DescriptionForm.propTypes = {
  description: PropTypes.string,
  descriptionHtml: PropTypes.string,
  handleChange: PropTypes.func,
  saveCompany: PropTypes.func,
  isLoading: PropTypes.bool,
  apolloClient: PropTypes.object,
  canEdit: PropTypes.bool
}

export default DescriptionForm
