import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_RECRUITER_EVENT_ACTIVITY_LOGS } from '../../schemas/queries'

import { Table } from 'reactstrap'

/* Components */
import Loading from 'shared/components/Loading'
import Pagination from 'shared/components/Pagination'

const RecruiterEventActivityLogs = ({ match }) => {
  const missionNames = {
    1: 'Gift Box',
    2: 'Upload Profile Picture',
    3: 'Complete Company Information',
    4: 'Initiate a chat with talent',
    5: 'Reply a greeting',
    6: 'KPI Competition',
    7: 'Daily gift basket',
    8: 'Invite new user to join Bossjob',
    9: 'Invite new company to join Bossjob'
  }

  return (
    <Query
      query={GET_RECRUITER_EVENT_ACTIVITY_LOGS}
      variables={{
        userId: match.params.id,
        page: match.params.page
      }}
    >
      {({ loading, data }) => {
        if (loading) {
          return <Loading />
        } else if (data) {
          console.log(data)
          let currentPage = data.recruiterEventActivity.data.currentPage
          let totalPages = data.recruiterEventActivity.data.totalPages
          let totalCount = data.recruiterEventActivity.data.totalCount
          let activityLogs = data.recruiterEventActivity.data.activityLogs

          let usedPoint = data.recruiterEventActivity.data.usedPoint
          let remainingPoint = data.recruiterEventActivity.data.remainingPoint
          let eventActivityUsedPoint =
            data.recruiterEventActivity.data.eventActivityUsedPoint
          let eventActivityRemainingPoint =
            data.recruiterEventActivity.data.eventActivityRemainingPoint
          return (
            <>
              <table style={{ border: '1px solid' }}>
                <tr>
                  <td>Used Points: </td>
                  <td>{usedPoint}</td>
                </tr>
                <tr>
                  <td>Event Activity Used Points: </td>
                  <td>{eventActivityUsedPoint}</td>
                </tr>
                <tr>
                  <td>Remaining Points: </td>
                  <td>{remainingPoint}</td>
                </tr>
                <tr>
                  <td>Event Activity Remaining Points: </td>
                  <td>{eventActivityRemainingPoint}</td>
                </tr>
              </table>
              <br />
              <Table bordered>
                <thead>
                  <tr>
                    <th>Log ID</th>
                    <th>Date</th>
                    <th>Mission</th>
                    <th>Transaction ID</th>
                    <th>Points</th>
                    <th>Invited by User ID</th>
                    <th>Accepted by User ID</th>
                    <th>Join Company ID</th>
                    <th>Red Packet Code</th>
                    <th>Referral Code</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {activityLogs.map(activityLog => (
                    <tr key={activityLog.id}>
                      <td>{activityLog.id}</td>
                      <td>{activityLog.date}</td>
                      <td>{missionNames[activityLog.missionId]}</td>
                      <td>{activityLog.transactionId}</td>
                      <td>{activityLog.point}</td>
                      <td>
                        {' '}
                        <a
                          href={`/bossjob/user/${activityLog.invitedByUserId}`}
                        >
                          {activityLog.invitedByUserId}
                        </a>
                      </td>
                      <td>
                        {' '}
                        <a
                          href={`/bossjob/user/${activityLog.acceptedByUserId}`}
                        >
                          {activityLog.acceptedByUserId}
                        </a>
                      </td>
                      <td>
                        <a
                          href={`/bossjob/company/${activityLog.joinCompanyId}`}
                        >
                          {activityLog.joinCompanyId}
                        </a>
                      </td>
                      <td>{activityLog.redPacketCode}</td>
                      <td>{activityLog.referralCode}</td>
                      <td>{activityLog.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                currentPage={currentPage}
                totalCount={totalCount}
                totalPages={totalPages}
                pageRange={4}
              />
            </>
          )
        }
      }}
    </Query>
  )
}

RecruiterEventActivityLogs.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default RecruiterEventActivityLogs
