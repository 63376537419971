import {
  GET_LOCAL_SUBSCRIPTION_DETAILS,
  GET_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM
} from '../schemas/queries'

export default {
  updateLocalSubscriptionDetails: (_, { key, value }, { cache }) => {
    const { subscriptionDetail } = cache.readQuery({
      query: GET_LOCAL_SUBSCRIPTION_DETAILS
    })

    let newSubscriptionDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        subscriptionDetail: {
          ...subscriptionDetail,
          ...newSubscriptionDetail
        }
      }
    })

    return null
  },
  updateLocalSubscriptionDetailsForNewForm: (_, { key, value }, { cache }) => {
    const { subscriptionDetailForNewForm } = cache.readQuery({
      query: GET_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM
    })

    let newSubscriptionDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        subscriptionDetailForNewForm: {
          ...subscriptionDetailForNewForm,
          ...newSubscriptionDetail
        }
      }
    })

    return null
  }
}
