import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Router */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_OTP_EMAIL_LOGS
} from '../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import OtpEmailLogsList from '../../views/BossJobUserDetail/OtpEmailLogs/OtpEmailLogsList'

const OtpEmailLogs = ({ match }) => {
  return (
    <div>
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <Query
            query={GET_OTP_EMAIL_LOGS}
            variables={{
              userId: bossjobUserDetail.id,
              page: match.params.page,
              size: 15
            }}
          >
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else {
                let currentPage = data.getEmailLogs.data.currentPage
                let totalPages = data.getEmailLogs.data.totalPages
                let totalCount = data.getEmailLogs.data.totalCount
                data = data.getEmailLogs.data
                return (
                  <div>
                    <SectionWrapper
                      sectionTitle="OTP Email Logs"
                      style={{ marginTop: '20px' }}
                    >
                      <OtpEmailLogsList
                        data={data}
                        currentPage={Number(currentPage)}
                        totalPages={totalPages}
                        totalCount={totalCount}
                      />
                    </SectionWrapper>
                  </div>
                )
              }
            }}
          </Query>
        )}
      </Query>
    </div>
  )
}

OtpEmailLogs.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}
export default withRouter(OtpEmailLogs)
