import gql from 'graphql-tag'

/*====== Local State ======*/
/* Admin user */
export const UPDATE_LOCAL_ADMIN_USER = gql`
  mutation updateLocalAdminUser($key: String, $value: String) {
    updateAdminUser(key: $key, value: $value) @client
  }
`
/*====== End Local State ======*/

/** update my own account */
export const MY_ACCOUNT_MUTATION = gql`
  mutation MyAccountMutation($payload: SelfAdminUserInput) {
    updateSelfAdminUser(adminUserInput: $payload) {
      ok
    }
  }
`

/** update admin user */
export const UPDATE_ADMIN_USER = gql`
  mutation UpdateAdminUser($id: ID!, $payload: AdminUserInput) {
    updateAdminUser(adminUserId: $id, adminUserInput: $payload) {
      ok
    }
  }
`
export const REGISTER_ADMIN_USER = gql`
  mutation RegisterAdminUser(
    $name: String
    $email: String
    $roleId: Int
    $password: String
  ) {
    registerAdminUser(
      name: $name
      email: $email
      roleId: $roleId
      password: $password
    ) {
      ok
    }
  }
`

/** disabled admin users */
export const DISABLE_ADMIN_USERS = gql`
  mutation DisableAdminUsers($ids: [Int]!) {
    disableAdminUser(adminUserId: $ids) {
      ok
    }
  }
`

/** delete admin users */
export const DELETE_ADMIN_USERS = gql`
  mutation DeleteAdminUsers($ids: [Int]!) {
    deleteAdminUser(adminUserId: $ids) {
      ok
      message
    }
  }
`
