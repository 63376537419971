import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'

import { Query } from 'react-apollo'
import { GET_SUPPORTED_COUNTRY } from '../../../../country/schemas/queries'

import Loading from 'shared/components/Loading'

const EventParticipantList = ({ data }) => {
  return (
    <>
      <Query query={GET_SUPPORTED_COUNTRY}>
        {({ loading, data: countryData }) => {
          if (loading) {
            return <Loading />
          } else if (countryData) {
            return (
              <Table bordered>
                <thead>
                  <tr>
                    <th>Overall Participant Statistic</th>
                    <th>Number of Participants</th>
                  </tr>
                </thead>
                <tbody>
                  {countryData && data
                    ? data.map(country => {
                      const matchingValue = countryData.getSupportedCountry.data.find(
                        supportedCountry =>
                          supportedCountry.id === country.countryId
                      )
                      return (
                        <tr key={country.countryId}>
                          <td>{matchingValue.value}</td>
                          <td>{country.participants}</td>
                        </tr>
                      )
                    })
                    : null}
                </tbody>
              </Table>
            )
          }
        }}
      </Query>
    </>
  )
}

EventParticipantList.propTypes = {
  data: PropTypes.array.isRequired
}

export default EventParticipantList
