import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Input } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
// import { formatDate } from 'shared/helpers/datetime'

/**
 * BossHuntUserList - Bosshunt user list table
 *
 */
class BossHuntUserList extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    if (e.target.checked) {
      this.props.appendSelectedUser(e.target.value)
    } else {
      this.props.removeSelectedUser(e.target.value)
    }
  }

  render() {
    const {
      headhunterList,
      currentPage,
      totalHeadhunters,
      totalPages
    } = this.props

    return (
      <React.Fragment>
        <Table bordered style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th style={{ width: '40px' }} />
              <th style={{ width: '75px' }}>User ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Agency Name</th>
              <th style={{ width: '230px' }}>Email</th>
              <th>Mobile Number</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {headhunterList.map(headhunter => (
              <tr key={headhunter.id}>
                <td>
                  <Input
                    addon
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    onChange={this.handleChange}
                    value={headhunter.id}
                  />
                </td>
                <td>
                  <a href={`/bosshunt/user/${headhunter.id}`}>
                    {headhunter.id}
                  </a>
                </td>
                <td>{headhunter.firstName}</td>
                <td>{headhunter.lastName}</td>
                <td> {headhunter.company ? headhunter.company.name : '-'} </td>
                <td>{headhunter.email}</td>
                <td>{headhunter.contactNum}</td>
                <td>
                  {headhunter.isActive && headhunter.isActive == true
                    ? 'Active'
                    : 'Inactive'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={Number(currentPage)}
          totalCount={totalHeadhunters}
          totalPages={totalPages}
          pageRange={4}
        />
      </React.Fragment>
    )
  }
}

BossHuntUserList.propTypes = {
  /* array of headhunter list */
  headhunterList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalHeadhunters: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  appendSelectedUser: PropTypes.func.isRequired,

  removeSelectedUser: PropTypes.func.isRequired
}

export default BossHuntUserList
