import { useState } from 'react'

/* Client */
import { client } from '../../../../shared/configs/apolloClientConfig'
import { COMPANY_REFERRAL_CODE } from '../../schemas/mutations'

/* Util */
import copy from 'copy-to-clipboard'

const useReferralLink = () => {
  const [referralLink, setReferralLink] = useState(null)
  const [loading, setLoading] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const handleCancelPopover = () => {
    setPopoverOpen(!popoverOpen)
  }

  const handleMyInvitation = async (companyId = 0) => {
    setLoading(true)
    client
      .mutate({
        mutation: COMPANY_REFERRAL_CODE,
        variables: {
          companyId
        }
      })
      .then(result => {
        const {
          data: {
            adminCompanyReferral: { referralLink, ok }
          }
        } = result
        if (ok == true) {
          setReferralLink(referralLink)
          handleCancelPopover()
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCopyLink = async () => {
    try {
      await copy(referralLink)
      alert('Success')
    } catch (error) {
      alert('Copy error')
    }
  }

  return {
    handleMyInvitation,
    handleCancelPopover,
    popoverOpen,
    referralLink,
    loading,
    handleCopyLink
  }
}

export default useReferralLink
