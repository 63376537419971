import React, { Component } from 'react'

/* Apollo */
import { GET_LOCAL_BOSSJOB_USER_DETAIL } from '../../../schemas/queries'
import {
  UPDATE_BOSSJOB_USER_DETAIL,
  UPDATE_LOCAL_BOSSJOB_USER,
  UPLOAD_USER_AVATAR,
  RESET_USER_PASSWORD
} from '../../../schemas/mutations'

/* Components */
import CroppingImageTool from 'shared/components/CroppingImageTool'
import AccountSettingForm from '../../../views/BossJobUserDetail/AccountSetting/AccountSettingForm'
import AccountStatusForm from '../../../views/BossJobUserDetail/AccountSetting/AccountStatusForm'
import ResetPassword from '../../../views/BossJobUserDetail/AccountSetting/ResetPassword'
import SectionWrapper from 'shared/components/SectionWrapper'

/**
 * AccountSettingFormContainer - container component to mutate Bossjob user account setting
 * and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class AccountSettingFormContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isUpdating: false,
      file: null,
      isCroppingVisible: false,
      imagePreviewUrl: '',
      fileType: ''
    }

    this.saveUserDetail = this.saveUserDetail.bind(this)
    this.handleCroppingVisibility = this.handleCroppingVisibility.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleCroppingImage = this.handleCroppingImage.bind(this)
  }

  /**
   *
   * @function handleUserInputUpdate - handle when user updating the field
   * and it will trigger the local data mutation
   *
   * @param apolloClient
   * @param key
   * @param value
   */
  handleUserInputUpdate(apolloClient, key, value) {
    apolloClient.mutate({
      mutation: UPDATE_LOCAL_BOSSJOB_USER,
      variables: {
        key: key,
        value: value
      }
    })
  }

  /**
   *
   * @function saveUserDetail - handle when user updating the field
   * and it will trigger the local data mutation
   *
   * @param apolloClient
   */
  async saveUserDetail(apolloClient) {
    this.setState({ isUpdating: true })

    const { bossjobUserDetail } = apolloClient.readQuery({
      query: GET_LOCAL_BOSSJOB_USER_DETAIL
    })

    const formPayload = {
      userId: bossjobUserDetail.id,
      payload: {
        firstName: bossjobUserDetail.firstName,
        lastName: bossjobUserDetail.lastName,
        email: bossjobUserDetail.email,
        mobileCountryId: parseInt(bossjobUserDetail.mobileCountryId),
        phoneNum: bossjobUserDetail.phoneNum
          ? bossjobUserDetail.phoneNum
          : null,
        statusKey: bossjobUserDetail.statusKey,
        isSubscribe: bossjobUserDetail.isSubscribe,
        isEmailVerify: bossjobUserDetail.isEmailVerify,
        isMobileVerified: bossjobUserDetail.isMobileVerified
      }
    }

    let response = await apolloClient.mutate({
      mutation: UPDATE_BOSSJOB_USER_DETAIL,
      variables: formPayload
    })

    let responseAvatar = null

    if (this.state.file !== null) {
      responseAvatar = await apolloClient.mutate({
        mutation: UPLOAD_USER_AVATAR,
        variables: {
          id: bossjobUserDetail.id,
          file: this.state.file
        }
      })
    }

    if (
      (this.state.file !== null &&
        response.data.updateBossjobUsers.ok &&
        responseAvatar.data.uploadJobseekerAvatar.ok) ||
      (this.state.file === null && response.data.updateBossjobUsers.ok)
    ) {
      window.location.reload()
    }
  }

  /**
   *
   * @function handlePasswordReset - handle when admin confirms a password reset
   * and it will trigger the local data mutation
   *
   * @param apolloClient
   */
  async handlePasswordReset(apolloClient) {
    const {
      bossjobUserDetail: { id }
    } = apolloClient.readQuery({
      query: GET_LOCAL_BOSSJOB_USER_DETAIL
    })
    let response
    await apolloClient
      .mutate({
        mutation: RESET_USER_PASSWORD,
        variables: {
          userId: id
        }
      })
      .then(res => {
        const { data } = res
        response = { data: data.resetBossjobUserPassword }
      })
      .catch(e => {
        const errors = JSON.parse(JSON.stringify(e))
        response = { errors: errors.networkError.result.errors[0] }
      })
    return response
  }

  /**
   *
   * @function handleCroppingVisibility - handle when user cropping or not cropping the image
   *
   * @param {Boolean} visible
   */
  handleCroppingVisibility(visible) {
    this.setState({
      isCroppingVisible: visible
    })
  }

  /**
   *
   * @function handleImageChange - handle when user select new image
   *
   * @param {File} file
   */
  handleImageChange(e) {
    if (window.FileReader) {
      let file = e.target.files[0]
      let reader = new FileReader()

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
      }

      //reset input file
      e.target.value = null
      reader.onloadend = () => {
        let imagePreviewUrl = reader.result
        this.setState({
          imagePreviewUrl: imagePreviewUrl,
          fileType: file.type
        })
        this.handleCroppingVisibility(true)
      }
    }
  }

  /**
   *
   * @function handleCroppingImage - handle when user done with cropping image
   *
   * @param {String} imageUrl
   * @param {File} blobFile
   */
  handleCroppingImage(imageUrl, blobFile) {
    this.setState({
      imagePreviewUrl: imageUrl
    })

    if (blobFile) {
      // for passing image blob file to backend API
      this.setState({
        file: blobFile
      })
    }
  }

  render() {
    const { isUpdating } = this.state

    return (
      <React.Fragment>
        <SectionWrapper sectionTitle="Account Setting">
          <AccountSettingForm
            {...this.props}
            updateUser={this.saveUserDetail}
            handleChange={this.handleUserInputUpdate}
            isUpdating={isUpdating}
            handleImageChange={this.handleImageChange}
            previewLogo={this.state.imagePreviewUrl}
          />
        </SectionWrapper>

        <SectionWrapper
          sectionTitle="Account Status"
          style={{ marginTop: '20px' }}
        >
          <AccountStatusForm
            {...this.props}
            updateUser={this.saveUserDetail}
            handleChange={this.handleUserInputUpdate}
            isUpdating={isUpdating}
          />
        </SectionWrapper>
        <SectionWrapper
          sectionTitle="Reset password"
          style={{ marginTop: '20px' }}
        >
          <ResetPassword
            handlePasswordReset={this.handlePasswordReset}
            isUpdating={isUpdating}
          />
        </SectionWrapper>
        <CroppingImageTool
          isVisible={this.state.isCroppingVisible}
          width={250}
          height={250}
          handleCloseModal={this.handleCroppingVisibility}
          imageSrc={this.state.imagePreviewUrl}
          fileType={this.state.fileType}
          handleCroppingImage={this.handleCroppingImage}
        />
      </React.Fragment>
    )
  }
}

export default AccountSettingFormContainer
