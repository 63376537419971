exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".saveBtn__3eOr1 {\n  margin: 20px;\n  position: absolute;\n  right: 0;\n}", "", {"version":3,"sources":["/app/src/modules/agencies/views/AgencyDetail/AgencyDetail.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAY;EACZ,mBAAkB;EAClB,SAAQ;CACV","file":"AgencyDetail.module.scss","sourcesContent":[".saveBtn {\n  margin: 20px;\n  position: absolute;\n  right: 0;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"saveBtn": "saveBtn__3eOr1"
};