import gql from 'graphql-tag'

/*====== Local State ======*/
/* Company Detail */
export const UPDATE_LOCAL_COMPANY_DETAIL = gql`
  mutation updateLocalCompanyDetail($key: String, $value: String) {
    updateCompany(key: $key, value: $value) @client
  }
`

/** Update Local Company Culture **/
export const UPDATE_LOCAL_COMPANY_CULTURE = gql`
  mutation UpdateLocalCompanyCulture($cultureId: Int, $isChecked: Bool) {
    updateLocalCompanyCulture(cultureId: $cultureId, isChecked: $isChecked)
      @client {
      ok
    }
  }
`
/** Update Local Company Benefit **/
export const UPDATE_LOCAL_COMPANY_BENEFIT = gql`
  mutation UpdateLocalCompanyBenefit($benefitId: Int, $isChecked: Bool) {
    updateLocalCompanyBenefit(benefitId: $benefitId, isChecked: $isChecked)
      @client {
      ok
    }
  }
`

/*====== End Local State ======*/

/* Update Company Detail */
export const UPDATE_COMPANY_DETAIL = gql`
  mutation UpdateCompany($companyId: Int!, $payload: UpdateCompanyInputs) {
    updateCompany(companyId: $companyId, companyInputs: $payload) {
      ok
      message
    }
  }
`

/* Update Company Verification */
export const UPDATE_COMPANY_VERIFICATION = gql`
  mutation UpdateCompanyVerification($companyId: Int!, $isVerify: Boolean!) {
    updateCompanyVerification(companyId: $companyId, isVerify: $isVerify) {
      ok
      message
    }
  }
`

/* Activate Company */
export const ACTIVATE_COMPANY = gql`
  mutation ActivateCompany($companyId: Int!) {
    activateCompany(companyId: $companyId) {
      ok
      message
    }
  }
`

/* Deactivate Company */
export const DEACTIVATE_COMPANY = gql`
  mutation DeactivateCompany($companyId: Int!, $deleteCompanyReason: String) {
    deactivateCompany(
      companyId: $companyId
      deleteCompanyReason: $deleteCompanyReason
    ) {
      ok
      message
    }
  }
`

/* Reject recruiter in a company */
export const REJECT_PENDING_REVIEW_RECRUITER = gql`
  mutation RejectPendingReviewRecruiter($companyId: Int!, $recruiterId: Int!) {
    rejectPendingReviewRecruiter(
      companyId: $companyId
      recruiterId: $recruiterId
    ) {
      ok
      message
    }
  }
`

/* Reject recruiter in a company */
export const CHANGE_REQUIRED_PENDING_REVIEW_RECRUITER = gql`
  mutation changeRequiredRecruiterDocument(
    $recruiterDocumentId: Int!
    $changeRequiredReason: String!
  ) {
    changeRequiredRecruiterDocument(
      changeRequiredReason: $changeRequiredReason
      recruiterDocumentId: $recruiterDocumentId
    ) {
      ok
      message
    }
  }
`

/* Approve recruiter in a company */
export const APPROVE_PENDING_REVIEW_RECRUITER = gql`
  mutation ApprovePendingReviewRecruiter(
    $companyId: Int!
    $recruiterId: Int!
    $recruiterRoleKey: String!
  ) {
    approvePendingReviewRecruiter(
      companyId: $companyId
      recruiterId: $recruiterId
      recruiterRoleKey: $recruiterRoleKey
    ) {
      ok
      message
    }
  }
`
/* Approve recruiter with a document */
export const APPROVE_PENDING_REVIEW_RECRUITER_DOCUMENT = gql`
  mutation approveRecruiterDocument(
    $recruiterDocumentId: Int!
    $recruiterRoleKey: String!
  ) {
    approveRecruiterDocument(
      recruiterDocumentId: $recruiterDocumentId
      recruiterRoleKey: $recruiterRoleKey
    ) {
      ok
      message
    }
  }
`

/* Approve recruiter with a document */
export const REJECT_RECRUITER_DOCUMENT = gql`
  mutation rejectedRecruiterDocument(
    $recruiterDocumentId: Int!
    $rejectedReason: String!
  ) {
    rejectedRecruiterDocument(
      recruiterDocumentId: $recruiterDocumentId
      rejectedReason: $rejectedReason
    ) {
      ok
      message
    }
  }
`

/* Change admin rights in a company */
export const CHANGE_RECRUITER_ROLE = gql`
  mutation ChangeRecruiterRole(
    $companyId: Int!
    $recruiterId: Int!
    $recruiterRoleKey: String!
  ) {
    changeRecruiterRole(
      companyId: $companyId
      recruiterId: $recruiterId
      recruiterRoleKey: $recruiterRoleKey
    ) {
      ok
      message
    }
  }
`

/* Remove recruiter from a company */
export const REMOVE_RECRUITER = gql`
  mutation RemoveRecruiter($companyId: Int!, $recruiterId: Int!) {
    removeRecruiter(companyId: $companyId, recruiterId: $recruiterId) {
      ok
      message
    }
  }
`

/* Reassign coordinator from a company */
export const REASSIGN_COORDINATOR = gql`
  mutation ReassignCoordinator(
    $companyId: Int!
    $coordinatorId: Int!
    $recruiterId: Int!
  ) {
    reassignCoordinator(
      companyId: $companyId
      coordinatorId: $coordinatorId
      recruiterId: $recruiterId
    ) {
      ok
      message
    }
  }
`

/* Delete company reviews */
export const DELETE_COMPANY_REVIEWS = gql`
  mutation DeleteCompanyReview($ids: [Int]!) {
    deleteCompanyReview(reviewId: $ids) {
      ok
    }
  }
`

/* Upload company document */
export const UPLOAD_COMPANY_DOCUMENT = gql`
  mutation UploadCompanyDocument($id: Int!, $file: Upload!) {
    uploadCompanyDocument(companyId: $id, file: $file) {
      ok
      message
    }
  }
`

/* Upload company document by admin */
export const UPLOAD_COMPANY_DOCUMENT_BY_ADMIN = gql`
  mutation UploadCompanyDocument($companyId: Int!, $file: Upload!) {
    uploadCompanyDocumentByAdmin(companyId: $companyId, file: $file) {
      ok
      message
    }
  }
`

/* Upload company logo */
export const UPLOAD_COMPANY_LOGO = gql`
  mutation UploadCompanyLogo($id: Int!, $file: Upload!) {
    uploadCompanyLogo(companyId: $id, file: $file) {
      ok
    }
  }
`

/* Upload/update company cover (banner) */
export const UPDATE_COMPANY_COVER = gql`
  mutation UpdateCompanyCover($companyId: Int!, $coverPic: Upload!) {
    updateCompanyCover(companyId: $companyId, coverPic: $coverPic) {
      ok
    }
  }
`

/* 
Add company picture 
Sort order, eg: 1,2,3,4,5,6
*/
export const ADD_COMPANY_PICTURE = gql`
  mutation AddCompanyPicture(
    $companyId: Int!
    $pic: Upload!
    $sortOrder: Int!
  ) {
    addCompanyPicture(companyId: $companyId, pic: $pic, sortOrder: $sortOrder) {
      ok
      message
    }
  }
`

/* Update company picture */
export const UPDATE_COMPANY_PICTURE = gql`
  mutation UpdateCompanyPicture($companyId: Int!, $pic: Upload!, $picId: Int!) {
    updateCompanyPicture(companyId: $companyId, pic: $pic, picId: $picId) {
      ok
      message
    }
  }
`

/* Delete company picture */
export const DELETE_COMPANY_PICTURE = gql`
  mutation DeleteCompanyPicture($companyId: Int!, $picId: Int!) {
    deleteCompanyPicture(companyId: $companyId, picId: $picId) {
      ok
      message
    }
  }
`

/* Approve company document */
export const APPROVE_COMPANY_DOCUMENT = gql`
  mutation ApproveCompanyDocument($companyId: Int!, $activeReason: String) {
    approveCompanyDocument(companyId: $companyId, activeReason: $activeReason) {
      ok
      message
    }
  }
`

/* Reject company document */
export const REJECT_COMPANY_DOCUMENT = gql`
  mutation RejectCompanyDocument($companyId: Int!, $rejectedReason: String!) {
    rejectCompanyDocument(
      companyId: $companyId
      rejectedReason: $rejectedReason
    ) {
      ok
      message
    }
  }
`

/* Require change company document */
export const REQUIRE_CHANGE_COMPANY_DOCUMENT = gql`
  mutation RequireChangeCompanyDocument(
    $companyId: Int!
    $changeRequiredReason: String!
  ) {
    requireChangeCompanyDocument(
      companyId: $companyId
      changeRequiredReason: $changeRequiredReason
    ) {
      ok
      message
    }
  }
`

/* Add company working address */
export const ADD_COMPANY_WORKING_ADDRESS = gql`
  mutation addCompanyWorkingAddress($payload: AddCompanyWorkingAddressInput) {
    addCompanyWorkingAddress(companyWorkingAddressInput: $payload) {
      ok
      message
    }
  }
`

/* Update company working address */
export const UPDATE_COMPANY_WORKING_ADDRESS = gql`
  mutation updateCompanyWorkingAddress(
    $payload: UpdateCompanyWorkingAddressInput
  ) {
    updateCompanyWorkingAddress(companyWorkingAddressInput: $payload) {
      ok
      message
    }
  }
`

/* Delete company working address */
export const DELETE_COMPANY_WORKING_ADDRESS = gql`
  mutation deleteCompanyWorkingAddress(
    $workingAddressId: Int!
    $companyId: Int!
  ) {
    deleteCompanyWorkingAddress(
      workingAddressId: $workingAddressId
      companyId: $companyId
    ) {
      ok
      message
    }
  }
`

/* Company Referral code */
export const COMPANY_REFERRAL_CODE = gql`
  mutation AdminCompanyReferral($companyId: Int!) {
    adminCompanyReferral(companyId: $companyId) {
      ok
      adminCompanyReferralCode
      referralLink
      message
    }
  }
`
