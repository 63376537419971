import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { GET_SUPPORTED_COUNTRY } from '../../schemas/queries'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'

class SupportedCountrySelect extends Component {
  render() {
    const { onChange, countryId } = this.props

    return (
      <Query query={GET_SUPPORTED_COUNTRY} fetchPolicy="no-cache">
        {({ data: { getSupportedCountry }, loading, error }) => {
          if (loading) {
            return <div>Loading...</div>
          } else if (error) {
            return <div>Something went wrong.</div>
          }

          const countryOptions = getSupportedCountry.data

          return (
            <Input
              type="select"
              name="countrySelect"
              value={countryId}
              onChange={selectedOption => onChange(selectedOption.target.value)}
            >
              {countryOptions.map(c => {
                return (
                  <option key={c.key} value={c.id}>
                    {c.value}
                  </option>
                )
              })}
            </Input>
          )
        }}
      </Query>
    )
  }
}

SupportedCountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  countryId: PropTypes.number
}

export default SupportedCountrySelect
