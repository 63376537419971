import React from 'react'

/* Components */
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'
import SubscriptionListContainer from '../../containers/SubscriptionListContainer'
import Loading from 'shared/components/Loading'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_SUBSCRIPTION_PLAN_LIST } from 'modules/subscription_plans/schemas/queries'

const statusOptions = [
  { all: 'All' },
  { active: 'Active' },
  { inactive: 'Inactive' }
]

const getSubscriptionPlanListVariables = {
  page: 1,
  size: 999,
  sort: 2
}

const Subscription = () => (
  <Query
    query={GET_SUBSCRIPTION_PLAN_LIST}
    variables={getSubscriptionPlanListVariables}
  >
    {({ loading, data: { subscriptionPlanList } }) => {
      if (loading) return <Loading />

      let subscriptionPlans = subscriptionPlanList.data.subscriptionPlans

      let subscriptionPlanOptions = subscriptionPlans.map(subscriptionPlan => {
        return {
          [subscriptionPlan.id]: subscriptionPlan.name
        }
      })

      return (
        <>
          <SearchBar
            hasStatus
            hasMinInstallment
            hasSubscriptionPlan
            placeholderForSearch={'User Email'}
            statusOptions={statusOptions}
            subscriptionPlanOptions={subscriptionPlanOptions}
            hideDate={true}
          />
          <SectionWrapper sectionTitle="Subscriptions">
            <SubscriptionListContainer />
          </SectionWrapper>
        </>
      )
    }}
  </Query>
)
export default Subscription
