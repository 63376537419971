import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_NOTIFY_MAINTAIN_LIST } from '../../schemas/queries'

/* Components */
import MaintenanceTable from '../../views/List/Table'
import Loading from '../../../../shared/components/Loading'
import { get } from 'lodash-es'

const ListContainer = ({ match, location, ...props }) => {
  const page = match.params.page

  return (
    <Query
      query={GET_NOTIFY_MAINTAIN_LIST}
      variables={{ page: page, size: 10 }}
    >
      {({ loading, data }) => {
        if (loading) {
          return <Loading />
        } else {
          const dataList = get(data, 'getWebsiteOutageList.data.dataList', [])
          const totalCount = get(
            data,
            'getWebsiteOutageList.data.totalCount',
            0
          )
          const totalPage = get(data, 'getWebsiteOutageList.data.totalPage', 0)
          return (
            <MaintenanceTable
              list={dataList}
              currentPage={page}
              totalPages={totalPage}
              totalCount={totalCount}
              {...props}
            />
          )
        }
      }}
    </Query>
  )
}

ListContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(ListContainer)
