exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".inputWrapper__3fXGr {\n  margin-bottom: 10px;\n  padding-bottom: 10px;\n  border-bottom: 1px solid #bbb;\n}\n\n.listItem__qVaym {\n  font-size: 0.9em;\n  margin-right: 15px;\n  cursor: pointer;\n}\n.listItemWrapper__22jyM {\n  margin-top: 15px;\n}", "", {"version":3,"sources":["/app/src/shared/components/SearchWithSuggestion/SearchWithSuggestion.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAmB;EACnB,qBAAoB;EACpB,8BAA6B;CAC/B;;AAEA;EACE,iBAAgB;EAChB,mBAAkB;EAClB,gBAAe;CACjB;AACA;EACE,iBAAgB;CAClB","file":"SearchWithSuggestion.module.scss","sourcesContent":[".inputWrapper {\n  margin-bottom: 10px;\n  padding-bottom: 10px;\n  border-bottom: 1px solid #bbb;\n}\n\n.listItem {\n  font-size: 0.9em;\n  margin-right: 15px;\n  cursor: pointer;\n}\n.listItemWrapper {\n  margin-top: 15px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"inputWrapper": "inputWrapper__3fXGr",
	"listItem": "listItem__qVaym",
	"listItemWrapper": "listItemWrapper__22jyM"
};