import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, compose, graphql } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_JOB_POST_HISTORY_OF_RECRUITER
} from '../../../schemas/queries'
import { UPDATE_LOCAL_CREDIT_MODAL } from '../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import JobPostCreditsHistoryList from '../../../views/BossJobUserDetail/RecruiterInformation/JobPostCredits/JobPostCreditsHistoryList/JobPostCreditsHistoryList'

/**
 * JobPostCreditsHistoryListContainer - container component to query job post history list
 * and return JobPostCreditsHistoryList component when is done
 *
 * @returns {Component}
 *
 */
class JobPostCreditsHistoryListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    updateLocalCreditModal: PropTypes.func.isRequred
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { updateLocalCreditModal } = this.props
    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <Query
            query={GET_JOB_POST_HISTORY_OF_RECRUITER}
            variables={{ userId: bossjobUserDetail.id, page: this.state.page }}
          >
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else {
                return (
                  <JobPostCreditsHistoryList
                    historyList={data.jobPostHistory.data.histories}
                    currentPage={Number(this.state.page)}
                    totalCounts={data.jobPostHistory.data.totalNum}
                    totalPages={data.jobPostHistory.data.totalPages}
                    handleModal={updateLocalCreditModal}
                  />
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}

export default compose(
  graphql(UPDATE_LOCAL_CREDIT_MODAL, { name: 'updateLocalCreditModal' })
)(withRouter(JobPostCreditsHistoryListContainer))
