import React from 'react'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

import Agencies from './Agencies'
import Layout from 'shared/components/Layout'
import NotAuthorize from 'shared/components/NotAuthorize'

export default () => (
  <Layout>
    <Can I="view" a="companies" passThrough>
      {can => (can ? <Agencies /> : <NotAuthorize />)}
    </Can>
  </Layout>
)
