import gql from 'graphql-tag'

/*====== Local State ======*/
/* Admin user */
export const GET_LOCAL_ADMIN_USER_DETAIL = gql`
  {
    adminUserDetail @client {
      id
      email
      name
      role {
        id
      }
    }
  }
`
/*====== End Local State ======*/

/** Get my account info */
export const GET_ME = gql`
  query {
    me {
      id
      email
      name
      role {
        roleNodes {
          id
          key
          canView
          canEdit
        }
      }
    }
  }
`

/** Get all users */
export const GET_ALL_USERS = gql`
  query getAllAdminUsers(
    $page: Int
    $query: String
    $date: DateTimeInput
    $size: Int
  ) {
    allAdminUsers(size: $size, page: $page, queryData: $query, date: $date) {
      data {
        adminUsers {
          id
          email
          name
          createdAt
          isActive
          role {
            roleName
          }
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

/** Get all roles */
export const GET_ALL_ROLES = gql`
  query {
    allRoles {
      id
      roleName
    }
  }
`

/** Get user detail by id */
export const GET_ADMIN_USER_DETAIL = gql`
  query getAdminUserDetail($id: Int!) {
    adminUser(userId: $id) {
      data {
        id
        email
        name
        role {
          id
        }
      }
    }
  }
`
