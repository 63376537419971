import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ALL_USERS, GET_ME } from '../../schemas/queries'

/* Components */
import UserList from '../../views/Users/UserList'
import Loading from '../../../../shared/components/Loading'

/**
 * UserListContainer - container component to query user list
 * and return UserList component when is done
 *
 * @returns {Component}
 *
 */
const UserListContainer = ({ match, location, ...props }) => {
  const { search, fromDate, toDate } = queryString.parse(location.search)

  let variables = {
    page: match.params.page,
    query: search ? search : null,
    date:
      fromDate && toDate
        ? {
          fromDate: fromDate,
          toDate: toDate
        }
        : null
  }

  return (
    <Query query={GET_ALL_USERS} variables={variables}>
      {({ loading, data }) => {
        const allUsers = data
        if (loading) {
          return <Loading />
        } else {
          if (allUsers && allUsers.allAdminUsers.data.adminUsers) {
            return (
              <Query query={GET_ME}>
                {({ loading, data }) => {
                  if (loading) {
                    return <Loading />
                  } else {
                    return (
                      <UserList
                        userList={allUsers.allAdminUsers.data.adminUsers}
                        currentPage={Number(match.params.page)}
                        totalUsers={allUsers.allAdminUsers.data.totalCount}
                        totalPages={allUsers.allAdminUsers.data.totalPages}
                        myId={data.me.id}
                        {...props}
                      />
                    )
                  }
                }}
              </Query>
            )
          }
        }
      }}
    </Query>
  )
}

UserListContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(UserListContainer)
