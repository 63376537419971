import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CONFIG from 'config'

/* Component */
import SectionWrapper from 'shared/components/SectionWrapper'
import CompanyInformationContainer from '../../../containers/CompanyDetailContainers/CompanyInformationContainer'
import CompanyFormContainer from '../../../containers/CompanyDetailContainers/CompanyFormContainer'
import CompanyDocumentContainer from '../../../containers/CompanyDetailContainers/CompanyDocumentContainer'
import CompanyPhotoAlbumContainer from '../../../containers/CompanyDetailContainers/CompanyPhotoAlbumContainer/CompanyPhotoAlbumContainer'
import CompanySocialLinkContainer from '../../../containers/CompanyDetailContainers/CompanySocialLinkContainer'
import CompanyAuthenticationContainer from '../../../containers/CompanyDetailContainers/CompanyAuthenticationContainer/CompanyAuthenticationContainer'
import CompanyVerificationInfoContainer from '../../../containers/CompanyDetailContainers/CompanyVerificationInfoContainer/'

/**
 * CompanyInformation - main wrapper component for the Company Information page
 *
 * @returns {Component}
 */
const CompanyInformation = ({ canEdit, match }) => {
  const [config, setConfig] = useState(null)
  useEffect(() => {
    async function fetchConfig() {
      const config = await fetch(
        `${CONFIG.BOSSJOB_CONFIG_URL}/sg/list`
      ).then(response => response.json())

      setConfig(config.data)
    }
    fetchConfig()
  }, [])

  return (
    <div>
      {/** Information section */}
      <SectionWrapper sectionTitle="Information" style={{ marginTop: '20px' }}>
        <CompanyInformationContainer config={config} canEdit={canEdit} />
      </SectionWrapper>

      {/** Social Links section */}
      <SectionWrapper sectionTitle="Social Links" style={{ marginTop: '20px' }}>
        <CompanySocialLinkContainer canEdit={canEdit} />
      </SectionWrapper>

      {/** Company verification info section */}
      <SectionWrapper
        sectionTitle="Company Verification Information"
        style={{ marginTop: '20px' }}
      >
        <CompanyVerificationInfoContainer canEdit={canEdit} match={match} />
      </SectionWrapper>

      {/** Document section */}
      <SectionWrapper
        sectionTitle="Document Status and Upload Document"
        style={{ marginTop: '20px' }}
      >
        <CompanyDocumentContainer canEdit={canEdit} fileId="company-file" />
      </SectionWrapper>

      {/** Document section */}
      <SectionWrapper
        sectionTitle="Company Information Attachment"
        style={{ marginTop: '20px' }}
      >
        <CompanyDocumentContainer
          canEdit={canEdit}
          fileId="company-admin-file"
        />
      </SectionWrapper>

      {/** Manage Company Document Verification Status section **/}
      <SectionWrapper
        sectionTitle="Manage Company Document Verification Status"
        style={{ marginTop: '20px' }}
      >
        <CompanyAuthenticationContainer canEdit={canEdit} />
      </SectionWrapper>

      {/** Company Photo Album section */}
      <SectionWrapper
        sectionTitle="Company Photo Album (Max 1 MB)"
        style={{ marginTop: '20px' }}
      >
        <CompanyPhotoAlbumContainer canEdit={canEdit} />
      </SectionWrapper>

      {/** Working Address, Description, Culture and Benefits sections */}
      <CompanyFormContainer config={config} canEdit={canEdit} />
    </div>
  )
}

CompanyInformation.propTypes = {
  props: PropTypes.object,
  data: PropTypes.object,
  canEdit: PropTypes.bool,
  match: PropTypes.object
}
export default CompanyInformation
