import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter, Route, Redirect } from 'react-router-dom'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import CreditHistoryListContainer from '../../../../../containers/BossJobUserDetailContainer/CreditHistoryListContainer'

/* Assets */
import styles from '../../RecruiterInformation.module.scss'

const CreditDetailWrapper = ({ match }) => (
  // const CreditDetailWrapper = ({ match, location }) => (
  <SectionWrapper>
    <div className={styles.subMenu} style={{ justifyContent: 'space-between' }}>
      <div className={styles.subMenuItem}>Usage History</div>
    </div>

    <Route
      exact
      path={`${match.path}`}
      component={() => <Redirect to={`${match.url}/history/1`} />}
    />

    <Route
      exact
      path="/bossjob/user/:id/recruiter/credits/history/:page"
      component={CreditHistoryListContainer}
    />
  </SectionWrapper>
)

CreditDetailWrapper.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(CreditDetailWrapper)
