import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
// import { compose, graphql } from 'react-apollo'
// import { DELETE_BOSSJOB_USERS } from '../../schemas/mutations'

/* Components */
import BossHuntUserListContainer from '../../containers/BossHuntUserListContainer'
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * BossHuntUsers - main Bosshunt user list view
 *
 */
class BossHuntUsers extends Component {
  static propTypes = {
    deleteUsers: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedUsers: []
    }
  }

  /**
   *
   * @function appendSelectedUser - Append userId to selectedUsers state
   * @param {Number} userId
   */
  appendSelectedUser = userId => {
    this.setState({
      selectedUsers: [...this.state.selectedUsers, Number(userId)]
    })
  }

  /**
   *
   * @function removeSelectedUser - Remove specific userId from selectedUsers state
   * @param {Number} userId
   */
  removeSelectedUser = userId => {
    let selectedUsers = this.state.selectedUsers
    selectedUsers.splice(this.state.selectedUsers.indexOf(Number(userId)), 1)
    this.setState({
      selectedUsers: selectedUsers
    })
  }

  addUsers = () => (window.location.href = '/bosshunt/user/new')

  // deleteUsers = async () => {
  //   try {
  //     let response = await this.props.deleteUsers({
  //       variables: {
  //         ids: this.state.selectedUsers
  //       }
  //     })

  //     if (response) {
  //       window.location.reload()
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  render() {
    const statusOptions = [
      { all: 'All' },
      { active: 'Active' },
      { inactive: 'Inactive' }
    ]

    return (
      <div>
        <SearchBar
          hasStatus
          statusOptions={statusOptions}
          placeholderForSearch={'ID, Name, Email, Mobile, Agency'}
        />
        <Can I="edit" a="bossjob_user" passThrough>
          {can => (
            <SectionWrapper
              sectionTitle="Bosshunt Users"
              isMainPage
              handleAdd={can ? this.addUsers : null}
              // handleDelete={can ? this.deleteUsers : null}
            >
              <BossHuntUserListContainer
                appendSelectedUser={this.appendSelectedUser}
                removeSelectedUser={this.removeSelectedUser}
              />
            </SectionWrapper>
          )}
        </Can>
      </div>
    )
  }
}

export default BossHuntUsers
// export default compose(graphql(DELETE_BOSSJOB_USERS, { name: 'deleteUsers' }))(
//   BossHuntUsers
// )
