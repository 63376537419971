import history from './history'

/**
 * setAccessToken -> set access token to local storage
 */
export const setAccessToken = accessToken => {
  localStorage.clear()
  localStorage.setItem('accessToken', accessToken)
  history.push('/bossjob/jobs/1')
}

/**
 * logout -> clear local storage
 */
export const logout = () => {
  localStorage.removeItem('accessToken')
  localStorage.clear()
}
