import React from 'react'

/* Bootstrap */
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

/* Components */
import MyAccountFormContainer from '../../containers/MyAccountFormContainer'

/**
 * MyAccount - user account setting view that will show form
 */
const MyAccount = () => (
  <div>
    <Container>
      <Row style={{ justifyContent: 'center' }}>
        <Col md="10">
          <div>
            <Card>
              <CardBody>
                <CardTitle>My Account Setting</CardTitle>
                <MyAccountFormContainer />
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default MyAccount
