let GRAPHQL_URL,
  SEARCH_BOSSJOB_URL,
  RESUME_URL,
  DATA_BOSSJOB_URL,
  DATA_BOSSHUNT_URL,
  BOSSHUNT_BUCKET_URL,
  BOSSJOB_CONFIG_URL,
  COMPANY_SERVICE_URL,
  HOST_ENV

const hostname = window && window.location && window.location.hostname

if (hostname === 'dev.admin-panel.bossjob.com') {
  HOST_ENV = 'development'
  GRAPHQL_URL = 'https://dev.api.bossjob.com/admin'
  SEARCH_BOSSJOB_URL = 'https://dev.search.bossjob.com/api/v1'
  RESUME_URL = 'https://dev.document.bossjob.com:5000/resume'
  DATA_BOSSJOB_URL = 'https://dev.api.bossjob.com/data'
  DATA_BOSSHUNT_URL = 'https://dev.api.bosshunt.ph/headhunters/api/v1'
  BOSSHUNT_BUCKET_URL = 'https://dev-assets.bosshunt.ph/'
  BOSSJOB_CONFIG_URL = 'https://dev.api.bossjob.com/config'
  COMPANY_SERVICE_URL = 'https://dev.api.bossjob.com/companies'
} else if (hostname === 'admin-panel.bossjob.com') {
  HOST_ENV = 'production'
  GRAPHQL_URL = 'https://api.bossjob.com/admin'
  SEARCH_BOSSJOB_URL = 'https://search.bossjob.com/api/v1'
  RESUME_URL = 'https://document.bossjob.com:5000/resume'
  DATA_BOSSJOB_URL = 'https://api.bossjob.com/data'
  DATA_BOSSHUNT_URL = 'https://api.bosshunt.ph/headhunters/api/v1'
  BOSSHUNT_BUCKET_URL = 'https://assets.bosshunt.ph/'
  BOSSJOB_CONFIG_URL = 'https://api.bossjob.com/config'
  COMPANY_SERVICE_URL = 'https://api.bossjob.com/companies'
} else {
  HOST_ENV = 'local'
  GRAPHQL_URL = 'https://dev.api.bossjob.com/admin'
  SEARCH_BOSSJOB_URL = 'https://dev.search.bossjob.com/api/v1'
  RESUME_URL = 'https://dev.document.bossjob.com:5000/resume'
  DATA_BOSSJOB_URL = 'https://dev.api.bossjob.com/data'
  DATA_BOSSHUNT_URL = 'https://dev.api.bosshunt.ph/headhunters/api/v1'
  BOSSHUNT_BUCKET_URL = 'https://dev-assets.bosshunt.ph/'
  BOSSJOB_CONFIG_URL = 'https://dev.api.bossjob.com/config'
  COMPANY_SERVICE_URL = 'https://dev.api.bossjob.com/companies'
}

const GOOGLE_MAP_API_KEY = 'AIzaSyCNjj8XNR7J7QqgojDn2NnqHYJJcDkT5Ac'

export default {
  GRAPHQL_URL,
  SEARCH_BOSSJOB_URL,
  RESUME_URL,
  DATA_BOSSJOB_URL,
  DATA_BOSSHUNT_URL,
  BOSSHUNT_BUCKET_URL,
  BOSSJOB_CONFIG_URL,
  COMPANY_SERVICE_URL,
  GOOGLE_MAP_API_KEY,
  HOST_ENV
}
