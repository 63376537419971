import React, { useState, useEffect } from 'react'
import CONFIG from 'config'
import PropTypes from 'prop-types'
import { Query, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Alert } from 'reactstrap'

/* Companies */
import SectionWrapper from 'shared/components/SectionWrapper'
import InformationContainer from './InformationContainer'
import DocumentContainer from './DocumentContainer'
import Loading from 'shared/components/Loading'
import AuthenticationContainer from './AuthenticationContainer'
import CompanyVerificationInfoContainer from './CompanyVerificationInfoContainer'

import {
  GET_NEPTUNE_COMPANY_BY_ID,
  GET_COMPANY_DOCUMENTS,
  GET_ME,
  GET_ALL_USERS
} from '../../schemas/queries'

const NeptuneCompany = ({ client, match }) => {
  const [config, setConfig] = useState(null)
  const [adminList, setAdminList] = useState([])
  const [userEmail, setUserEmail] = useState('')

  const {
    params: { id }
  } = match

  useEffect(() => {
    const fetchUserEmail = async () => {
      const response = await client.query({ query: GET_ME })
      setUserEmail(response.data.me.email)
    }
    fetchUserEmail()
  }, [])

  useEffect(() => {
    const fetchAdminList = async () => {
      const response = await client.query({
        query: GET_ALL_USERS,
        variables: { size: 1000 }
      })
      const users = response.data.allAdminUsers.data.adminUsers
      setAdminList(users.filter(user => user.role.roleName === 'Administrator'))
    }
    fetchAdminList()
  }, [])

  useEffect(() => {
    async function fetchConfig() {
      const config = await fetch(`${CONFIG.BOSSJOB_CONFIG_URL}/sg/list`).then(
        response => response.json()
      )

      setConfig(config.data)
    }
    fetchConfig()
  }, [])

  return (
    <main>
      <Query
        query={GET_NEPTUNE_COMPANY_BY_ID}
        variables={{ NeptuneCompanyId: parseInt(id) }}
      >
        {({ loading, data, client }) => {
          if (loading) {
            return <Loading />
          } else {
            client.writeData({
              data: { getNeptuneCompany: data.getNeptuneCompany }
            })

            const canEdit = adminList.some(user => user.email === userEmail)

            return (
              <React.Fragment>
                <SectionWrapper
                  sectionTitle="Information"
                  style={{ marginTop: '20px' }}
                >
                  <InformationContainer config={config} canEdit={canEdit} />
                </SectionWrapper>

                <SectionWrapper
                  sectionTitle="Document Status and Upload Document"
                  style={{ marginTop: '20px' }}
                >
                  <DocumentContainer canEdit={canEdit} />
                </SectionWrapper>

                <SectionWrapper
                  sectionTitle="Company Verification Information"
                  style={{ marginTop: '20px' }}
                >
                  <CompanyVerificationInfoContainer match={match} />
                </SectionWrapper>

                <SectionWrapper
                  sectionTitle="Manage Company Document Verification Status"
                  style={{ marginTop: '20px' }}
                >
                  <AuthenticationContainer
                    canEdit={canEdit}
                    client={client}
                    id={id}
                  />
                </SectionWrapper>
              </React.Fragment>
            )
          }
        }}
      </Query>
    </main>
  )
}

NeptuneCompany.prototype = {
  match: PropTypes.object,
  canEdit: PropTypes.bool
}

export default withRouter(withApollo(NeptuneCompany))
