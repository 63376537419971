/*eslint-disable*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Vendors */
import AvatarEditor from 'react-avatar-editor'

/* Bootstrap */
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class CroppingImageTool extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    imageSrc: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    fileType: PropTypes.string,
    croppingData: PropTypes.object,
    handleCroppingImage: PropTypes.func.isRequired.target,
    // For Company Photo Album
    sortOrder: PropTypes.number
  }

  constructor(props) {
    super(props)
    this.state = {
      scale: 1
    }

    this.closeModal = this.closeModal.bind(this)
    this.handleScaleChange = this.handleScaleChange.bind(this)
    this.saveImage = this.saveImage.bind(this)
  }

  closeModal() {
    this.setState({
      scale: 1
    })

    this.props.handleCloseModal(false)
    this.props.handleCroppingImage('', null)
  }

  saveImage(e) {
    let sortOrder = e.target.id
    if (this.editor) {
      const canvas = this.editor.getImage()
      /* pre-fill white background for .jpg images */
      const context = canvas.getContext('2d')
      context.globalCompositeOperation = 'destination-over'
      context.fillStyle = '#FFFFFF'
      context.fillRect(0, 0, canvas.width, canvas.height)

      canvas.toBlob(
        blob => {
          let imageURL = URL.createObjectURL(blob)
          this.props.handleCroppingImage(imageURL, blob, sortOrder)
        },
        this.props.fileType,
        0.8
      )
    }
    this.closeModal()
  }

  handleScaleChange(event) {
    this.setState({
      ...this.state,
      scale: parseFloat(event.target.value).toFixed(2)
    })
  }

  setEditorRef = editor => (this.editor = editor)

  render() {
    const { width, height, imageSrc, isVisible, sortOrder } = this.props
    return (
      <Modal isOpen={isVisible} size="lg">
        <ModalHeader toggle={this.closeModal}>Cropping Tool</ModalHeader>
        <ModalBody style={{ textAlign: 'center' }}>
          <AvatarEditor
            ref={this.setEditorRef}
            image={imageSrc}
            width={width}
            height={height}
            border={50}
            scale={Number(this.state.scale)}
            rotate={0}
            color={[188, 188, 188, 0.6]}
          />
          <div>
            Zoom:{' '}
            <input
              type="range"
              defaultValue={this.state.scale}
              max="2"
              step="0.01"
              onChange={this.handleScaleChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="info" id={sortOrder} onClick={e => this.saveImage(e)}>
            Save changes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default CroppingImageTool
