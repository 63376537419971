import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

/**
 * TransactionList
 *
 * @returns {Component}
 */
const TransactionList = ({
  transactionList,
  currentPage,
  totalNum,
  totalPages
}) => (
  <>
    <Table bordered>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>TXN Ref No.</th>
          <th style={{ textAlign: 'center' }}>Company Name</th>
          <th style={{ textAlign: 'center' }}>Item Name</th>
          <th style={{ textAlign: 'center' }}>Amount (PHP)</th>
          <th style={{ textAlign: 'center' }}>Email</th>
          <th style={{ textAlign: 'center' }}>Type</th>
          <th style={{ textAlign: 'center' }}>Invoice Date</th>
          <th style={{ textAlign: 'center' }}>Status</th>
          <th style={{ textAlign: 'center' }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {transactionList.map((transaction, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'center' }}>
              <a href={`/bossjob/transaction/detail/${transaction.id}`}>
                {transaction.referenceNum}
              </a>
            </td>
            <td style={{ textAlign: 'center' }}>{transaction.billerCompany}</td>
            <td style={{ textAlign: 'center' }}>{transaction.itemName}</td>
            <td style={{ textAlign: 'center' }}>{transaction.amount}</td>
            <td style={{ textAlign: 'center' }}>
              <a href={`/bossjob/user/${transaction.userId}`}>
                {transaction.user.email}
              </a>
            </td>
            <td style={{ textAlign: 'center' }}>
              {transaction.typeId === 1 ? 'online' : 'offline'}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatDate(transaction.createdAt, 'DD/MM/YYYY HH:mm:Ss')}
            </td>
            <td style={{ textAlign: 'center' }}>{transaction.status}</td>
            <td>
              <div style={{ textAlign: 'center' }}>
                <a href={`/bossjob/transaction/detail/${transaction.id}`}>
                  <Button color="success">
                    <img src={Zoom} alt="Zoom" width="16" height="16" />
                  </Button>
                </a>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Pagination
      currentPage={Number(currentPage)}
      totalCount={totalNum}
      totalPages={totalPages}
      pageRange={4}
    />
  </>
)

TransactionList.propTypes = {
  transactionList: PropTypes.array.isRequired,
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalNum: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
}

export default TransactionList
