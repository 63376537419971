import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

// const managePaymentKey = paymentKey => {
//   const type = paymentKey.replace(/_/g, ' ')

//   return type
// }

/**
 * TransactionHistoryList - Bossjob recruiter credit history list table
 *
 */
const TransactionHistoryList = ({
  historyList,
  currentPage,
  totalNum,
  totalPages
}) => (
  <React.Fragment>
    <Table bordered>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>TXN Ref No.</th>
          <th style={{ textAlign: 'center' }}>Item Name</th>
          <th style={{ textAlign: 'center' }}>Amount (PHP)</th>
          <th style={{ textAlign: 'center' }}>Type</th>
          <th style={{ textAlign: 'center' }}>Invoice Date</th>
          <th style={{ textAlign: 'center' }}>Status</th>
          <th style={{ textAlign: 'center' }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {historyList.map((transaction, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'center' }}>
              <a href={`/bossjob/transaction/detail/${transaction.id}`}>
                {transaction.referenceNum}
              </a>
            </td>
            <td style={{ textAlign: 'center' }}>{transaction.itemName}</td>
            <td style={{ textAlign: 'center' }}>{transaction.amount}</td>
            <td style={{ textAlign: 'center' }}>
              {transaction.typeId === 1 ? 'online' : 'offline'}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatDate(transaction.createdAt, 'DD/MM/YYYY HH:mm:Ss')}
            </td>
            <td style={{ textAlign: 'center' }}>{transaction.status}</td>
            <td>
              <div style={{ textAlign: 'center' }}>
                <a href={`/bossjob/transaction/detail/${transaction.id}`}>
                  <Button color="success">
                    <img src={Zoom} alt="Zoom" width="16" height="16" />
                  </Button>
                </a>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Pagination
      currentPage={Number(currentPage)}
      totalCount={Number(totalNum)}
      totalPages={Number(totalPages)}
      pageRange={4}
    />
  </React.Fragment>
)

TransactionHistoryList.propTypes = {
  /* array of history list */
  historyList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalNum: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired
}

export default TransactionHistoryList
