import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { withClientState } from 'apollo-link-state'

/* Config */
import CONFIG from '../../config'

/* Default state */
import authenticationDefaults from 'modules/authentication/defaults'
import bossjobUserDefaults from 'modules/bossjob_users/defaults'
import companiesDefaults from 'modules/companies/defaults'
import jobDefaults from 'modules/jobs/defaults'
import chatDefaults from 'modules/chat/defaults'
import transactionDefaults from 'modules/transactions/defaults'
import subscriptionDefaults from 'modules/subscriptions/defaults'
import subscriptionPlanDefaults from 'modules/subscription_plans/defaults'
import adminUserDefaults from 'modules/users/defaults'
import roleDefaults from 'modules/roles/defaults'
import bosshuntUserDefaults from 'modules/bosshunt_users/defaults'
import agenciesDefaults from 'modules/agencies/defaults'
import neptuneCompaniesDefaults from 'modules/neptuneCompanies/defaults'
import maintenanceDefaults from 'modules/maintenance/defaults'

/* Resolvers */
import authenticationResolvers from 'modules/authentication/resolvers'
import bossjobUserResolvers from 'modules/bossjob_users/resolvers'
import companiesResolvers from 'modules/companies/resolvers'
import jobResolvers from 'modules/jobs/resolvers'
import transactionResolvers from 'modules/transactions/resolvers'
import subscriptionResolvers from 'modules/subscriptions/resolvers'
import subscriptionPlanResolvers from 'modules/subscription_plans/resolvers'
import adminUserResolvers from 'modules/users/resolvers'
import roleResolvers from 'modules/roles/resolvers'
import bosshuntUserResolvers from 'modules/bosshunt_users/resolvers'
import agenciesResolvers from 'modules/agencies/resolvers'
import neptuneCompaniesResolvers from 'modules/neptuneCompanies/resolvers'
import maintenanceResolvers from 'modules/maintenance/resolvers'

const httpLink = createUploadLink({
  uri: `${CONFIG.GRAPHQL_URL}/graphql`,
  credentials: 'omit'
})

const cache = new InMemoryCache()

const stateLink = withClientState({
  cache,
  defaults: {
    ...authenticationDefaults,
    ...bossjobUserDefaults,
    ...companiesDefaults,
    ...jobDefaults,
    ...chatDefaults,
    ...transactionDefaults,
    ...subscriptionDefaults,
    ...subscriptionPlanDefaults,
    ...adminUserDefaults,
    ...roleDefaults,
    ...bosshuntUserDefaults,
    ...agenciesDefaults,
    ...neptuneCompaniesDefaults,
    ...maintenanceDefaults
  },
  resolvers: {
    Mutation: {
      ...authenticationResolvers,
      ...jobResolvers,
      ...bossjobUserResolvers,
      ...companiesResolvers,
      ...transactionResolvers,
      ...subscriptionResolvers,
      ...subscriptionPlanResolvers,
      ...adminUserResolvers,
      ...roleResolvers,
      ...bosshuntUserResolvers,
      ...agenciesResolvers,
      ...neptuneCompaniesResolvers,
      ...maintenanceResolvers
    }
  }
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([stateLink, authLink, httpLink])
})
