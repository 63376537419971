import gql from 'graphql-tag'

/*====== Fragments =====*/

const bossjobUserFragment = gql`
  fragment bossjobUserFields on BossjobUserType {
    firstName
    lastName
    email
  }
`

const subscriptionFragment = gql`
  fragment subscriptionFields on SubscriptionType {
    id
    userId
    user {
      email
    }
    paymentKey
    paymentTypeId
    paymentType
    name
    subscriptionPlanId
    description
    totalAmount
    amount
    noOfInstallment
    paymentDuration
    jobPost
    unlockTalentCredit
    rhTalentCredit
    featureJobCredit
    urgentJobCredit
    bosspoint
    remark
    totalBilledAmount
    cycle
    transactionHistories {
      transactionId
      transaction {
        taxRate
        taxType
      }
      amount
      isPaid
      paidAt
      createdAt
    }
    creditHistories {
      cycle
      jobPost
      unlockTalentCredit
      rhTalentCredit
      featureJobCredit
      urgentJobCredit
      createdAt
    }
    isActive
    startedAt
    endedAt
    contractUrl
    salesPersonEmail
    creditTerm
    bankAccountId
    nextPaymentAt
    lastPaymentAt
    nextCreditAt
    lastCreditAt
    isStopped
    billerEmail
    createdAt
    updatedAt
  }
`
/*====== Query ======*/
export const GET_SUBSCRIPTION_LIST = gql`
  query getSubscriptionList(
    $size: Int
    $page: Int
    $email: String
    $isActive: Boolean
    $minNoOfInstallment: Int
    $subscriptionPlanId: Int
  ) {
    subscriptionList(
      size: $size
      page: $page
      email: $email
      isActive: $isActive
      minNoOfInstallment: $minNoOfInstallment
      subscriptionPlanId: $subscriptionPlanId
    ) {
      ok
      data {
        totalPages
        totalNum
        currentPage
        currentNum
        subscriptions {
          id
          userId
          user {
            id
            firstName
            lastName
            email
          }
          paymentKey
          name
          description
          amount
          totalBilledAmount
          cycle
          nextPaymentAt
          lastPaymentAt
          nextCreditAt
          lastCreditAt
          createdAt
          updatedAt
          isActive
        }
      }
    }
  }
`
export const GET_BOSSJOB_USER_BY_ID = gql`
  query getBossjobUserById($userId: Int!) {
    bossjobUser(userId: $userId) {
      data {
        ...bossjobUserFields
      }
    }
  }
  ${bossjobUserFragment}
`

export const GET_SUBSCRIPTION_BY_ID = gql`
  query getSubscriptionById($subscriptionId: Int) {
    subscription(subscriptionId: $subscriptionId) {
      data {
        ...subscriptionFields
      }
    }
  }
  ${subscriptionFragment}
`

export const GET_LOCAL_SUBSCRIPTION_DETAILS = gql`
  {
    subscriptionDetail @client {
      ...subscriptionFields
    }
  }
  ${subscriptionFragment}
`

export const GET_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM = gql`
  {
    subscriptionDetailForNewForm @client {
      name
      subscriptionPlanId
      description
      paymentDuration
      noOfInstallment
      invoiceAmount
      totalAmount
      amount
      jobPost
      unlockTalentCredit
      rhTalentCredit
      featureJobCredit
      urgentJobCredit
      bosspoint
      remark
      salesPersonEmail
      contractFile
      startedAt
      endedAt
      creditTerm
      taxType
      taxRate
      bankAccountId
      paymentMethodId
      billerEmail
      billerFirstName
      billerLastName
      billerAddress
      billerCompany
      billerCity
      billerState
      billerZipcode
      billerCountry
    }
  }
`
