import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Bootstrap */
import { Button } from 'reactstrap'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_TRANSACTIONS_OF_RECRUITER
} from '../../../schemas/queries'

/* Components */
import TransactionHistoryList from '../../../views/BossJobUserDetail/RecruiterInformation/TransactionHistory/TransactionHistoryList'
import Loading from 'shared/components/Loading'

/* Vendors */
import queryString from 'query-string'

/**
 * TransactionHistoryListContainer - container component to query invoice history list
 * and return TransactionHistoryList component when is done
 *
 * @returns {Component}
 *
 */
class TransactionHistoryListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    updateLocalCreditModal: PropTypes.func.isRequred
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { match } = this.props
    const { search, fromDate, toDate, type, status } = queryString.parse(
      location.search
    )

    let statusId

    switch (status) {
    case 'pending':
      statusId = 1
      break
    case 'processing':
      statusId = 2
      break
    case 'completed':
      statusId = 3
      break
    case 'cancelled':
      statusId = 4
      break
    case 'failed':
      statusId = 5
      break
    default:
      break
    }

    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <Query
            query={GET_TRANSACTIONS_OF_RECRUITER}
            variables={{
              userId: bossjobUserDetail.id,
              page: this.state.page,
              query: search ? search : null,
              createdDate:
                fromDate && toDate
                  ? {
                    fromDate,
                    toDate
                  }
                  : null,
              typeId: type ? (type === 'online' ? 1 : 2) : null,
              statusId
            }}
          >
            {({ loading, data: { transactionList } }) => {
              if (loading) {
                return <Loading />
              } else {
                return (
                  <>
                    <div
                      style={
                        location.pathname.includes('transaction')
                          ? { display: 'flex', justifyContent: 'flex-end' }
                          : { display: 'none' }
                      }
                    >
                      <a href={`/bossjob/transaction/new/${match.params.id}`}>
                        <Button color="info">Add</Button>
                      </a>
                    </div>
                    <br />
                    <TransactionHistoryList
                      historyList={transactionList.data.transactions}
                      currentPage={Number(this.state.page)}
                      totalNum={transactionList.data.totalNum}
                      totalPages={transactionList.data.totalPages}
                    />
                  </>
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}

TransactionHistoryListContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(TransactionHistoryListContainer)
