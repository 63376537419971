exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".subMenu__3cOGy {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.subMenuItem__3gyBa {\n  margin-right: 10px;\n  padding: 10px 15px;\n  color: #212529;\n  font-weight: bold;\n}\n.subMenuItemActive__2JABB {\n  border: 1px solid #20a8d8;\n  border-radius: 5px;\n  color: #20a8d8;\n}\n\n.saveBtn__32dlz {\n  margin: 20px;\n  position: absolute;\n  right: 0;\n}", "", {"version":3,"sources":["/app/src/modules/bosshunt_users/views/BossHuntUserDetail/MyAds/MyAds.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,wBAA2B;MAA3B,qBAA2B;UAA3B,4BAA2B;EAC3B,0BAAmB;MAAnB,uBAAmB;UAAnB,oBAAmB;CACrB;AACA;EACE,mBAAkB;EAClB,mBAAkB;EAClB,eAAc;EACd,kBAAiB;CACnB;AACA;EACE,0BAAyB;EACzB,mBAAkB;EAClB,eAAc;CAChB;;AAEA;EACE,aAAY;EACZ,mBAAkB;EAClB,SAAQ;CACV","file":"MyAds.module.scss","sourcesContent":[".subMenu {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n.subMenuItem {\n  margin-right: 10px;\n  padding: 10px 15px;\n  color: #212529;\n  font-weight: bold;\n}\n.subMenuItemActive {\n  border: 1px solid #20a8d8;\n  border-radius: 5px;\n  color: #20a8d8;\n}\n\n.saveBtn {\n  margin: 20px;\n  position: absolute;\n  right: 0;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"subMenu": "subMenu__3cOGy",
	"subMenuItem": "subMenuItem__3gyBa",
	"subMenuItemActive": "subMenuItemActive__2JABB",
	"saveBtn": "saveBtn__32dlz"
};