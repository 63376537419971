import {
  GET_LOCAL_JOB_DETAILS,
  GET_LOCAL_POST_JOB_DETAILS
} from '../schemas/queries'

export default {
  updateLocalJobDetails: (_, { key, value }, { cache }) => {
    const { jobDetail } = cache.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    let newJobDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        jobDetail: {
          ...jobDetail,
          ...newJobDetail
        }
      }
    })

    return null
  },
  updateLocalScreeningQuestions: (_, { question, index }, { cache }) => {
    const { jobDetail } = cache.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    let screeningQuestions = jobDetail.screeningQuestions
    const questions = Object.assign({}, screeningQuestions[index])
    screeningQuestions[index] = {
      ...questions,
      question: question,
      __typename: 'JobScreeningQuestionType'
    }

    cache.writeData({
      data: {
        jobDetail: {
          ...jobDetail,
          screeningQuestions: screeningQuestions
        }
      }
    })

    return null
  },
  updateLocalJobForwardingEmails: (_, { email, index }, { cache }) => {
    const { jobDetail } = cache.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    let resumeForwardingEmails = jobDetail.resumeForwardingEmails
    const emails = Object.assign({}, resumeForwardingEmails[index])
    resumeForwardingEmails[index] = {
      ...emails,
      email: email,
      __typename: 'JobResumeForwardingEmailType'
    }

    cache.writeData({
      data: {
        jobDetail: {
          ...jobDetail,
          resumeForwardingEmails: resumeForwardingEmails
        }
      }
    })

    return null
  },
  updateLocalJobBenefits: (_, { jobBenefitId, isChecked }, { cache }) => {
    const { jobDetail } = cache.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    let jobBenefits = jobDetail.jobBenefits ? jobDetail.jobBenefits : []

    // append benefit to array if isChecked = true and vice versa
    if (isChecked) {
      // if there are no duplicated jobBenefitId, append to existing array
      if (
        !jobBenefits.filter(
          jobBenefit => jobBenefit.benefitId === Number(jobBenefitId)
        ).length > 0
      ) {
        const jobBenefitsObj = Object.assign(
          {},
          { benefitId: Number(jobBenefitId), __typename: 'JobBenefitsType' }
        )
        jobBenefits.push(jobBenefitsObj)
      }
    } else {
      jobBenefits = jobBenefits.filter(
        jobBenefit => jobBenefit.benefitId !== Number(jobBenefitId)
      )
    }

    cache.writeData({
      data: {
        jobDetail: {
          ...jobDetail,
          jobBenefits: jobBenefits
        }
      }
    })
    return null
  },
  addLocalDynamicInputField: (_, { keyName, obj }, { cache }) => {
    const { jobDetail } = cache.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })
    jobDetail[keyName].push(obj)
    cache.writeData({
      data: {
        jobDetail: {
          ...jobDetail
        }
      }
    })

    return null
  },
  deleteLocalDynamicInputField: (_, { keyName, index }, { cache }) => {
    const { jobDetail } = cache.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    jobDetail[keyName].splice(index, 1)

    cache.writeData({
      data: {
        jobDetail: {
          ...jobDetail
        }
      }
    })

    return null
  },
  addLocalPostJobDynamicInputField: (_, { keyName, obj }, { cache }) => {
    const { postJobDetail } = cache.readQuery({
      query: GET_LOCAL_POST_JOB_DETAILS
    })

    postJobDetail[keyName].push(obj)
    cache.writeData({
      data: {
        postJobDetail: {
          ...postJobDetail
        }
      }
    })

    return null
  },
  deleteLocalPostJobDynamicInputField: (_, { keyName, index }, { cache }) => {
    const { postJobDetail } = cache.readQuery({
      query: GET_LOCAL_POST_JOB_DETAILS
    })

    postJobDetail[keyName].splice(index, 1)

    cache.writeData({
      data: {
        postJobDetail: {
          ...postJobDetail
        }
      }
    })

    return null
  },
  updateLocalPostJobScreeningQuestions: (_, { question, index }, { cache }) => {
    const { postJobDetail } = cache.readQuery({
      query: GET_LOCAL_POST_JOB_DETAILS
    })

    let screeningQuestions = postJobDetail.screeningQuestions

    const questions = Object.assign({}, screeningQuestions[index])
    screeningQuestions[index] = {
      ...questions,
      question: question,
      __typename: 'JobScreeningQuestionType'
    }

    cache.writeData({
      data: {
        postJobDetail: {
          ...postJobDetail,
          screeningQuestions: screeningQuestions
        }
      }
    })

    return null
  },
  updateLocalPostJobForwardingEmails: (_, { email, index }, { cache }) => {
    const { postJobDetail } = cache.readQuery({
      query: GET_LOCAL_POST_JOB_DETAILS
    })

    let resumeForwardingEmails = postJobDetail.resumeForwardingEmails

    const emails = Object.assign({}, resumeForwardingEmails[index])
    resumeForwardingEmails[index] = {
      ...emails,
      email: email,
      __typename: 'JobResumeForwardingEmailType'
    }

    cache.writeData({
      data: {
        postJobDetail: {
          ...postJobDetail,
          resumeForwardingEmails: resumeForwardingEmails
        }
      }
    })

    return null
  },

  updateLocalJobCategories: (_, { categoryId, index }, { cache }) => {
    const { jobDetail } = cache.readQuery({
      query: GET_LOCAL_JOB_DETAILS
    })

    //jobDetail.jobCategories[index].category.id = Number(categoryId)
    //jobDetail.jobCategories[index].categoryId = Number(categoryId)

    let jobCategories = jobDetail.jobCategories

    const category = Object.assign({}, jobCategories[index])
    jobCategories[index] = {
      ...category,
      categoryId: Number(categoryId),
      category: {
        ...category.category,
        id: Number(categoryId)
      },
      __typename: 'JobCategoriesType'
    }

    if (jobCategories[index].id === null) {
      jobCategories[index].id = Number(categoryId) * 10000
    }

    cache.writeData({
      data: {
        jobDetail: {
          ...jobDetail,
          jobCategories: jobCategories
        }
      }
    })

    return null
  },
  updateLocalPostJobDetails: (_, { key, value }, { cache }) => {
    const data = cache.readQuery({
      query: GET_LOCAL_POST_JOB_DETAILS
    })

    let newPostJobDetail = {
      [key]: value
    }
    cache.writeData({
      data: {
        postJobDetail: {
          ...data.postJobDetail,
          ...newPostJobDetail
        }
      }
    })

    return null
  },

  updateLocalPostJobCategories: (_, { categoryId, index }, { cache }) => {
    const { postJobDetail } = cache.readQuery({
      query: GET_LOCAL_POST_JOB_DETAILS
    })

    //postJobDetail.jobCategories[index].category.id = Number(categoryId)
    //postJobDetail.jobCategories[index].categoryId = Number(categoryId)

    let jobCategories = postJobDetail.jobCategories

    const category = Object.assign({}, jobCategories[index])
    jobCategories[index] = {
      ...category,
      categoryId: Number(categoryId),
      category: {
        ...category.category,
        id: Number(categoryId)
      },
      __typename: 'JobCategoriesType'
    }

    if (jobCategories[index].id === null) {
      jobCategories[index].id = Number(categoryId) * 10000
    }

    cache.writeData({
      data: {
        postJobDetail: {
          ...postJobDetail,
          jobCategories: jobCategories
        }
      }
    })

    return null
  },
  updateLocalPostJobBenefits: (_, { jobBenefitId, isChecked }, { cache }) => {
    const { postJobDetail } = cache.readQuery({
      query: GET_LOCAL_POST_JOB_DETAILS
    })

    let jobBenefits = postJobDetail.jobBenefits ? postJobDetail.jobBenefits : []

    // append benefit to array if isChecked = true and vice versa
    if (isChecked) {
      // if there are no duplicated jobBenefitId, append to existing array
      if (
        !jobBenefits.filter(
          jobBenefit => jobBenefit.benefitId === Number(jobBenefitId)
        ).length > 0
      ) {
        const jobBenefitsObj = Object.assign(
          {},
          { benefitId: Number(jobBenefitId), __typename: 'JobBenefitsType' }
        )
        jobBenefits.push(jobBenefitsObj)
      }
    } else {
      jobBenefits = jobBenefits.filter(
        jobBenefit => jobBenefit.benefitId !== Number(jobBenefitId)
      )
    }

    cache.writeData({
      data: {
        postJobDetail: {
          ...postJobDetail,
          jobBenefits: jobBenefits
        }
      }
    })
    return null
  }
}
