import React from 'react'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ME } from '../../schemas/queries'

/* Components */
import Loading from '../../../../shared/components/Loading'
import MyAccountForm from '../../views/MyAccount/MyAccountForm'

/**
 * MyAccountFormContainer - container component to query user data
 * befor passing it to MyAccountForm component
 *
 * @returns {Component}
 *
 */
const MyAccountFormContainer = () => (
  <Query query={GET_ME} fetchPolicy="no-cache">
    {({ loading, data }) => {
      console.log(data)
      if (loading) {
        return <Loading />
      }

      if (data && data.me) {
        return <MyAccountForm user={data.me} />
      }

      return null
    }}
  </Query>
)

export default MyAccountFormContainer
