import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/**
 * HeadhunterList - List of headhunters under Bosshunt agency
 *
 */

const HeadhunterList = ({
  headhunterList,
  currentPage,
  totalHeadhunters,
  totalPages
}) => {
  return (
    <React.Fragment>
      <Table bordered style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '75px' }}>User ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Job Title</th>
            <th style={{ width: '230px' }}>Email</th>
            <th>Mobile Number</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {headhunterList.map(headhunter => (
            <tr key={headhunter.id}>
              <td style={{ textAlign: 'center' }}>
                <a href={`/bosshunt/user/${headhunter.id}`}>{headhunter.id}</a>
              </td>
              <td>{headhunter.firstName}</td>
              <td>{headhunter.lastName}</td>
              <td> {headhunter.company ? headhunter.company.name : '-'} </td>
              <td>{headhunter.email}</td>
              <td>{headhunter.contactNum}</td>
              <td>
                {headhunter.isActive && headhunter.isActive == true
                  ? 'Active'
                  : 'Inactive'}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalHeadhunters}
        totalPages={totalPages}
        pageRange={4}
      />
    </React.Fragment>
  )
}

HeadhunterList.propTypes = {
  /* array of headhunter list */
  headhunterList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalHeadhunters: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired
}

export default HeadhunterList
