import React from 'react'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

import JobDetail from './JobDetail'
import Layout from 'shared/components/Layout'
import NotAuthorize from 'shared/components/NotAuthorize'

export default () => (
  <Layout>
    <Can I="edit" a="jobs" passThrough>
      {can => (can ? <JobDetail /> : <NotAuthorize />)}
    </Can>
  </Layout>
)
