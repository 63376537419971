import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button, Input, InputGroup, InputGroupAddon, Col } from 'reactstrap'

import CONFIG from 'config'

import styles from './CompanyInputField.module.scss'

class CompanyInputField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      companyList: []
    }
  }

  onChange = value => {
    const { handleChange, index, countryId } = this.props

    if (value !== '') {
      fetch(`${CONFIG.COMPANY_SERVICE_URL}/search?query=${value}&size=6`, {
        headers: {
          'country-id': countryId
        }
      })
        .then(response => response.json())
        .then(data => {
          this.setState({
            companyList: data.data.companies
          })
        })
    } else {
      this.setState({
        companyList: []
      })
    }

    handleChange(value, index)
  }

  handleClick = company => {
    const { handleChange, index } = this.props
    handleChange(company.name, index, company.id)
    this.setState({
      companyList: []
    })
  }

  render() {
    const { company, index, removeForm } = this.props
    const { companyList } = this.state

    return (
      <InputGroup style={index > 0 ? { marginTop: '20px' } : {}} key={index}>
        <InputGroupAddon addonType="prepend">{`${index + 1}.`}</InputGroupAddon>
        <Col
          md={9}
          sm={12}
          style={{ position: 'relative', paddingLeft: '0', paddingRight: '0' }}
        >
          <Input
            style={{ borderRadius: '0' }}
            value={company.value}
            required
            name={`name_${index + 1}`}
            placeholder="Company name..."
            autoComplete="off"
            onChange={e => this.onChange(e.target.value)}
          />
          {companyList.length !== 0 && (
            <div className={styles.searchList}>
              <ul>
                {companyList.map(company => (
                  <li
                    key={`company-${company.id}`}
                    onClick={() => this.handleClick(company)}
                  >
                    {company.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Col>
        <InputGroupAddon addonType="append">
          <Button
            type="button"
            color="danger"
            onClick={() => removeForm(index)}
          >
            Remove
          </Button>
        </InputGroupAddon>
      </InputGroup>
    )
  }
}

CompanyInputField.propTypes = {
  company: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  removeForm: PropTypes.func.isRequired,
  countryId: PropTypes.number.isRequired
}

export default CompanyInputField
