import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_TRANSACTION_LIST } from '../../schemas/queries'

/* Components */
import TransactionList from '../../views/Transaction/TransactionList'
import Loading from 'shared/components/Loading'

/**
 * TransactionListContainer
 *
 * @returns {Component}
 */
class TransactionListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { page } = this.state

    const { search, fromDate, toDate, type, status } = queryString.parse(
      location.search
    )

    let statusId

    switch (status) {
    case 'pending':
      statusId = 1
      break
    case 'processing':
      statusId = 2
      break
    case 'completed':
      statusId = 3
      break
    case 'cancelled':
      statusId = 4
      break
    case 'failed':
      statusId = 5
      break
    default:
      break
    }

    let variables = {
      page: this.state.page,
      query: search ? search : null,
      createdDate:
        fromDate && toDate
          ? {
            fromDate,
            toDate
          }
          : null,
      typeId: type ? (type === 'online' ? 1 : 2) : null,
      statusId
    }

    return (
      <Query query={GET_TRANSACTION_LIST} variables={variables}>
        {({ loading, data: { transactionList } }) => {
          if (loading) return <Loading />
          return (
            <TransactionList
              transactionList={transactionList.data.transactions}
              totalNum={transactionList.data.totalNum}
              currentPage={page}
              totalPages={transactionList.data.totalPages}
            />
          )
        }}
      </Query>
    )
  }
}

export default withRouter(TransactionListContainer)
