import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Mutation } from 'react-apollo'
import { REGISTER_ADMIN_USER } from '../../../schemas/mutations'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap'

/* Helper */
import history from '../../../../../shared/helpers/history'

/**
 * AddUserForm - form component for AddUser page
 *
 * @returns {Component}
 */
class AddUserForm extends Component {
  static propTypes = {
    /* list of roles option */
    rolesOption: PropTypes.array.isRequired
  }
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      roleId: 1,
      error: false,
      errorMessage: '',
      alert: false
    }
  }

  render() {
    const { name, email, roleId } = this.state
    const { rolesOption } = this.props
    console.log(rolesOption)
    return (
      <Form>
        <FormGroup>
          <Label>Name</Label>
          <Input
            name="name"
            value={name}
            onChange={e => {
              this.setState({ name: e.target.value })
              if (this.state.error) {
                this.setState({
                  error: false,
                  errorMessage: ''
                })
              }
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            name="email"
            value={email}
            onChange={e => {
              this.setState({ email: e.target.value })
              if (this.state.error) {
                this.setState({
                  error: false,
                  errorMessage: ''
                })
              }
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Role</Label>
          <Input
            type="select"
            name="role"
            defaultValue={roleId}
            onChange={e => this.setState({ roleId: e.target.value })}
          >
            {rolesOption.map(role => (
              <option key={role.id} value={role.id}>
                {role.roleName}
              </option>
            ))}
          </Input>
        </FormGroup>
        {this.state.alert && <Alert>New admin user has been added</Alert>}
        {this.state.error && (
          <Alert color="danger">{this.state.errorMessage}</Alert>
        )}
        <Mutation
          mutation={REGISTER_ADMIN_USER}
          onCompleted={() => {
            this.setState({ alert: true })
            setTimeout(() => {
              this.setState({ alert: false })
              history.push('/users')
            }, 1700)
          }}
          onError={graphQLError => {
            if (graphQLError) {
              this.setState({
                error: true,
                errorMessage: graphQLError.message
              })
            }
          }}
        >
          {RegisterAdminUser => (
            <div style={{ textAlign: 'right' }}>
              <Button
                color="info"
                onClick={() => {
                  // form validation
                  if (this.state.name === '' || this.state.email === '') {
                    this.setState({
                      error: true,
                      errorMessage: 'Name and Email fields should not be empty'
                    })
                  } else {
                    const randomPassword =
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15)

                    const payload = {
                      name: name,
                      email: email,
                      roleId: roleId,
                      password: randomPassword
                    }
                    RegisterAdminUser({
                      variables: { ...payload }
                    })
                  }
                }}
              >
                Add
              </Button>
            </div>
          )}
        </Mutation>
      </Form>
    )
  }
}

export default AddUserForm
