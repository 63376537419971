import React, { useEffect, useState } from 'react'
import { Row, Form, FormGroup, Label, Button, Input, Col } from 'reactstrap'

import { Can } from 'shared/configs/abilityContextConfig'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_NEPTUNE_COMPANY_DETAIL } from '../../../schemas/queries'

import {
  APPROVE_NEPTUNE_COMPANY_DOCUMENT,
  REJECT_NEPTUNE_COMPANY_DOCUMENT,
  REQUIRE_CHANGE_COMPANY_DOCUMENT
} from '../../../schemas/mutations'

const AuthenticationContainer = ({ canEdit, client, id }) => {
  const [authStatusKey, setAuthStatusKey] = useState('approve')
  const [submitLoading, setSubmitLoading] = useState(false)

  const [reason, setReason] = useState('Invalid document')

  const handleSubmit = () => {
    if (authStatusKey == 'approve') {
      handleApprove()
      return
    }

    if (authStatusKey == 'reject') {
      handleReject()
      return
    }

    if (authStatusKey == 'change_required') {
      handleRequire()
      return
    }
  }

  const handleApprove = async () => {
    setSubmitLoading(true)
    const {
      data: { approveNeptuneCompanyDocument }
    } = await client.mutate({
      mutation: APPROVE_NEPTUNE_COMPANY_DOCUMENT,
      variables: {
        neptuneCompanyId: parseInt(id),
        reason: reason
      }
    })

    setSubmitLoading(false)
    const { message, ok } = approveNeptuneCompanyDocument
    if (!ok) {
      alert(message)
    } else {
      alert('Success')
    }
  }

  const handleReject = async () => {
    setSubmitLoading(true)
    const {
      data: { rejectNeptuneCompanyDocument }
    } = await client.mutate({
      mutation: REJECT_NEPTUNE_COMPANY_DOCUMENT,
      variables: {
        neptuneCompanyId: parseInt(id),
        rejectedReason: reason
      }
    })

    setSubmitLoading(false)
    const { message, ok } = rejectNeptuneCompanyDocument
    if (!ok) {
      alert(message)
    } else {
      alert('Success')
    }
  }

  const handleRequire = async () => {
    setSubmitLoading(true)
    const {
      data: { requireChangeNeptuneCompanyDocument }
    } = await client.mutate({
      mutation: REQUIRE_CHANGE_COMPANY_DOCUMENT,
      variables: {
        neptuneCompanyId: parseInt(id),
        changeRequiredReason: reason
      }
    })

    setSubmitLoading(false)
    const { message = null, ok } = requireChangeNeptuneCompanyDocument || {}
    if (!ok) {
      alert(message)
    } else {
      alert('Success')
    }
  }

  return (
    <Query query={GET_LOCAL_NEPTUNE_COMPANY_DETAIL}>
      {({ data: { getNeptuneCompany }, loading, error }) => {
        const { message } = getNeptuneCompany || {}

        //
        const onlyCode = null

        if (loading) return <div>Loading...</div>

        if (error) return <div>Error : {message}</div>

        return (
          <div>
            {/* <Row style={{ paddingBottom: '10px' }}>
              <Col>Current state: {statusKey}</Col>
            </Row> */}

            <Form>
              <Row style={{ paddingLeft: '1em' }}>
                <FormGroup>
                  <Label for="statusKey">Change status Key</Label>
                  <Input
                    type="select"
                    name="statusKey"
                    value={authStatusKey}
                    onChange={e => {
                      setAuthStatusKey(`${e.target.value}`)
                    }}
                  >
                    <option value="approve">Approve</option>
                    <option value="reject">Reject</option>
                    <option value="change_required">Change Required</option>
                  </Input>
                </FormGroup>
              </Row>

              <Row style={{ paddingLeft: '1em' }}>
                <FormGroup>
                  <Label for="changeRequiredReason">Reason</Label>
                  <Input
                    type="textarea"
                    name="changeRequiredReason"
                    id="changeRequiredReason"
                    onChange={e => setReason(`${e.target.value}`)}
                  />
                </FormGroup>
              </Row>

              <Row style={{ paddingLeft: '1em' }}>
                <Can I="edit" a="companies">
                  <Button
                    color="info"
                    disabled={!canEdit || submitLoading}
                    onClick={() => handleSubmit()}
                  >
                    {loading ? 'Loading...' : 'Submit'}
                  </Button>
                </Can>
              </Row>
            </Form>
          </div>
        )
      }}
    </Query>
  )
}

export default AuthenticationContainer
