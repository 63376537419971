import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, compose } from 'react-apollo'
import {
  ADD_FEATURED_BANNER,
  UPDATE_FEATURED_BANNER,
  REMOVE_FEATURED_BANNER
} from '../../../schemas/mutations'
import { Button } from 'reactstrap'

import FeaturedBannerInputForm from './components/FeaturedBannerInputForm'
import styles from './FeaturedBannerContainer.module.scss'

class FeaturedBannerContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      countryId: props.countryId,
      formAmount:
        (props.featureBanners.data && props.featureBanners.data.length) || 0
    }
  }

  addForm = () => {
    this.setState(prevState => ({ formAmount: prevState.formAmount + 1 }))
  }

  removeForm = () => {
    this.setState(prevState => ({
      formAmount: prevState.formAmount <= 0 ? 0 : prevState.formAmount - 1
    }))
  }

  handleRemoveFeatureBanner = async bannerId => {
    try {
      const { removeFeatureBanner } = this.props

      await removeFeatureBanner({
        variables: {
          bannerId
        }
      })

      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  handleAddFeatureBanner = async e => {
    try {
      e.preventDefault()
      const { addFeatureBanner } = this.props
      const formData = new FormData(e.target)

      const { countryId } = this.state

      const payload = {
        countryId,
        sortOrder: formData.get('sortOrder'),
        // ...(formData.get('title') && { title: formData.get('title') }),
        // ...(formData.get('logoPic').size && {
        //   logoPic: formData.get('logoPic')
        // }),
        // ...(formData.get('description') && {
        //   description: formData.get('description')
        // }),
        ...(formData.get('coverPic').size && {
          coverPic: formData.get('coverPic')
        }),
        // ...(formData.get('ctaText') && { ctaText: formData.get('ctaText') }),
        ...(formData.get('ctaUrl') && { ctaUrl: formData.get('ctaUrl') }),
        // ...(formData.get('colorCode') && {
        //   colorCode: formData.get('colorCode')
        // }),
        // ...(formData.get('descriptionColorCode') && {
        //   descriptionColor: formData.get('descriptionColorCode')
        // }),
        // ...(formData.get('ctaButtonColorCode') && {
        //   buttonColor: formData.get('ctaButtonColorCode')
        // }),
        // ...(formData.get('ctaTextColorCode') && {
        //   ctaTextColor: formData.get('ctaTextColorCode')
        // }),
        ...(formData.get('mobileCoverPic').size && {
          mobileCoverPic: formData.get('mobileCoverPic')
        }),
        ...(formData.get('tabletCoverPic').size && {
          tabletCoverPic: formData.get('tabletCoverPic')
        })
      }

      await addFeatureBanner({
        variables: {
          featureBannerInput: payload
        }
      })

      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  handleUpdateFeatureBanner = async e => {
    try {
      e.preventDefault()
      const { updateFeatureBanner } = this.props
      const formData = new FormData(e.target)

      const { countryId } = this.state

      const payload = {
        countryId,
        sortOrder: formData.get('sortOrder'),
        // ...(formData.get('title') && { title: formData.get('title') }),
        // ...(formData.get('logoPic').size && {
        //   logoPic: formData.get('logoPic')
        // }),
        // ...(formData.get('description') && {
        //   description: formData.get('description')
        // }),
        ...(formData.get('coverPic').size && {
          coverPic: formData.get('coverPic')
        }),
        // ...(formData.get('ctaText') && { ctaText: formData.get('ctaText') }),
        ...(formData.get('ctaUrl') && { ctaUrl: formData.get('ctaUrl') }),
        // ...(formData.get('colorCode') && {
        //   colorCode: formData.get('colorCode')
        // }),
        // ...(formData.get('descriptionColorCode') && {
        //   descriptionColor: formData.get('descriptionColorCode')
        // }),
        // ...(formData.get('ctaButtonColorCode') && {
        //   buttonColor: formData.get('ctaButtonColorCode')
        // }),
        // ...(formData.get('ctaTextColorCode') && {
        //   ctaTextColor: formData.get('ctaTextColorCode')
        // }),
        ...(formData.get('mobileCoverPic').size && {
          mobileCoverPic: formData.get('mobileCoverPic')
        }),
        ...(formData.get('tabletCoverPic').size && {
          tabletCoverPic: formData.get('tabletCoverPic')
        })
      }

      await updateFeatureBanner({
        variables: {
          bannerId: formData.get('bannerId'),
          featureBannerInput: payload
        }
      })
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const {
      featureBanners: { data }
    } = this.props
    const { formAmount } = this.state

    return (
      <section>
        <div className={styles.formWrapper}>
          {[...Array(formAmount)].map((nothing, index) => {
            // eslint-disable-line
            return (
              <FeaturedBannerInputForm
                key={index}
                featureBanner={data[index] || {}}
                addFeatureBanner={this.handleAddFeatureBanner}
                updateFeatureBanner={this.handleUpdateFeatureBanner}
                removeFeatureBanner={this.handleRemoveFeatureBanner}
              />
            )
          })}
        </div>
        <Button
          type="primary"
          color="success"
          className={styles.addFormButton}
          onClick={this.addForm}
        >
          Add Feature Banner
        </Button>
      </section>
    )
  }
}

FeaturedBannerContainer.propTypes = {
  countryId: PropTypes.number.isRequired,
  featureBanners: PropTypes.object.isRequired
}

export default compose(
  graphql(ADD_FEATURED_BANNER, { name: 'addFeatureBanner' }),
  graphql(UPDATE_FEATURED_BANNER, { name: 'updateFeatureBanner' }),
  graphql(REMOVE_FEATURED_BANNER, { name: 'removeFeatureBanner' })
)(FeaturedBannerContainer)
