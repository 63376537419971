import React, { Component } from 'react'

/* Apollo */
import { Query, Mutation } from 'react-apollo'
import { GET_LOCAL_BOSSJOB_USER_DETAIL } from '../../../../schemas/queries'
import { DELETE_RESUME, UPLOAD_RESUME } from '../../../../schemas/mutations'

/* Component */
import FileUploadField from 'shared/components/FileUploadField'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'
import { Button } from 'reactstrap'

/**
 * UploadedResume - main wrapper component for the Profile page - Uploaded resume section
 *
 * @returns {Component}
 */
class UploadedResume extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: null
    }

    this.handleFileSelect = this.handleFileSelect.bind(this)
  }

  handleFileSelect(file) {
    this.setState({
      file: file
    })
  }

  _renderFileInput(userId) {
    return (
      <Mutation
        mutation={UPLOAD_RESUME}
        onCompleted={() => window.location.reload()}
      >
        {(UploadResume, { loading }) => (
          <FileUploadField
            file={this.state.file}
            handleFileSelect={this.handleFileSelect}
            handleFileUpload={UploadResume}
            userId={userId}
            isUploading={loading}
          />
        )}
      </Mutation>
    )
  }

  _renderFileDelete(userId, resumeId) {
    return (
      <Mutation
        mutation={DELETE_RESUME}
        onCompleted={() => window.location.reload()}
      >
        {(DeleteResume, { loading }) => (
          <Button
            disabled={loading}
            color="danger"
            onClick={() => {
              const variables = {
                userId: userId,
                resumeId: resumeId
              }
              DeleteResume({ variables: variables })
            }}
          >
            Delete
          </Button>
        )}
      </Mutation>
    )
  }

  render() {
    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => {
          return (
            <>
              {bossjobUserDetail.resumes &&
                bossjobUserDetail.resumes.map((resume, idx) => {
                  return (
                    <div key={idx}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'baseline',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div style={{ marginBottom: '15px' }}>
                          {idx + 1}.{'   '}
                          <a
                            href={resume.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {resume.filename && resume.filename !== ''
                              ? resume.filename
                              : 'Resume.pdf'}
                          </a>
                        </div>
                        <div>
                          {this._renderFileDelete(
                            bossjobUserDetail.id,
                            resume.id
                          )}
                        </div>
                      </div>
                      {idx == 2 && (
                        <p>
                          <br />
                          Maximum allow to upload 3 resumes.
                        </p>
                      )}
                    </div>
                  )
                })}
              <Can I="edit" a="resume">
                <div>{this._renderFileInput(bossjobUserDetail.id)}</div>
              </Can>
            </>
          )
        }}
      </Query>
    )
  }
}

export default UploadedResume
