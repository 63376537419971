exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".nav__2g47R {\n  background-color: #fff;\n}", "", {"version":3,"sources":["/app/src/shared/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAsB;CACxB","file":"Header.module.scss","sourcesContent":[".nav {\n  background-color: #fff;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"nav": "nav__2g47R"
};