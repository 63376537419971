import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { compose, graphql } from 'react-apollo'
import { GET_LOCAL_CREDIT_MODAL_DATA } from 'modules/bossjob_users/schemas/queries'

/* Bootstrap */
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Input,
  FormFeedback
} from 'reactstrap'

class CreditModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
      remark: '',
      invalid: false,
      loading: false,
      errorMessage: '',
      submitError: ''
    }

    this.closeModal = this.closeModal.bind(this)
    this.manageValue = this.manageValue.bind(this)
    this.adjustCredit = this.adjustCredit.bind(this)
    this.renderForm = this.renderForm.bind(this)
    this.updateRemarks = this.updateRemarks.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.modalData &&
      newProps.modalData.creditModal &&
      newProps.modalData.creditModal.isVisible !==
        this.props.modalData.creditModal.isVisible
    ) {
      this.setState({
        remark: newProps.modalData.creditModal.modalData
          ? newProps.modalData.creditModal.modalData.remarks
          : ''
      })
    }
  }

  /**
   *
   * @function manageValue - handle +/- button to update credit state
   *
   * @param type
   */
  manageValue(type) {
    const { value } = this.state
    const { identifier } = this.props

    let minValue = -1000
    let maxValue = 1000

    if (identifier === 'bosspoint') {
      minValue = -1000000
      maxValue = 1000000
    }

    let dynamicValue =
      type === 'add' ? parseInt(value) + 1 : parseInt(value) - 1

    if (dynamicValue >= minValue && dynamicValue <= maxValue) {
      let dynamicRemark = this.renderDynamicRemark(type, value)

      this.setState({
        value: dynamicValue,
        remark: dynamicRemark
      })
    }
  }

  /**
   *
   * @function onInputChange - handle input field change
   *
   * @param e //event
   */
  onInputChange = e => {
    const { identifier } = this.props
    let minValue = -1000
    let maxValue = 1000
    let dynamicValue = e.target.value
    let dynamicRemark = ''
    let errorMessage = ''

    if (identifier === 'bosspoint') {
      minValue = -1000000
      maxValue = 1000000
    }

    if (dynamicValue === '-') {
      errorMessage = 'This field cannot be empty'
    } else {
      if (parseInt(e.target.value)) {
        dynamicValue = parseInt(e.target.value)

        if (dynamicValue < minValue) {
          dynamicValue = minValue
        } else if (dynamicValue > maxValue) {
          dynamicValue = maxValue
        }

        dynamicRemark = this.renderDynamicRemark('', dynamicValue)
      } else {
        dynamicValue = ''
      }
    }

    this.setState({
      errorMessage: errorMessage,
      value: dynamicValue,
      remark: dynamicRemark
    })
  }

  /**
   *
   * @function renderDynamicRemark - populate dynamic remarks based on value and credit types selected
   *
   * @param type
   */

  renderDynamicRemark = (manageValueType = '', value) => {
    const { typeId, identifier } = this.props
    let type
    let creditType
    let dynamicValue
    let receivedOrDeducted

    if (identifier === 'jobPost') {
      switch (typeId) {
      case 1:
        type = 'trial job post'
        break
      case 2:
        type = 'paid job post'
        break
      case 3:
        type = 'free job post'
        break
      default:
        type = 'job post'
        break
      }
    } else if (identifier === 'credit') {
      switch (typeId) {
      case 1:
        type = 'talent credit'
        break
      case 2:
        type = 'feature job credit'
        break
      case 3:
        type = 'urgent job credit'
        break
      case 4:
        type = 'robo headhunt talent credit'
        break
      default:
        type = 'credit'
        break
      }
    } else {
      type = 'bosspoint'
    }

    // if manageValueType.length === 0, it means that user manually keying in values instead of using '+/-' buttons
    if (manageValueType.length === 0) {
      dynamicValue = parseInt(value)
    } else {
      dynamicValue =
        manageValueType === 'add' ? parseInt(value) + 1 : parseInt(value) - 1
    }

    creditType = this.renderPlural(type, dynamicValue)

    // to determine the first word of remark
    receivedOrDeducted = dynamicValue > 0 ? 'Received' : 'Deducted'

    // if dynamicValue is a negative, turn it into a positive
    if (dynamicValue < 0) dynamicValue = dynamicValue * -1

    return dynamicValue && dynamicValue.length !== 0
      ? `${receivedOrDeducted} ${dynamicValue} ${creditType}`
      : ''
  }

  // to determine if it's plural or singular
  renderPlural = (type, dynamicValue) => {
    return `${type}${dynamicValue > 1 || dynamicValue < -1 ? 's' : ''}`
  }

  /**
   *
   * @function closeModal - reset component state and call resolver to update
   * apollo local state
   */
  closeModal() {
    const { handleModal } = this.props

    /* reset state */
    this.setState({
      inititalSetRemark: false,
      value: 0,
      remark: '',
      loading: false
    })

    /* update local state */
    handleModal({
      variables: {
        isVisible: false,
        modalData: null
      }
    })
  }

  /**
   *
   * @function adjustCredit - call network mutation to add new job posts/credits
   * then close modal and refetch the query once done
   */
  async adjustCredit() {
    const { handleAdd, userId, refetch, typeId, identifier } = this.props
    const { value, remark } = this.state

    if (remark && remark.length > 0) {
      this.setState({
        ...this.state,
        loading: true
      })

      try {
        let response = await handleAdd(
          identifier === 'jobPost'
            ? {
              variables: {
                userId: userId,
                typeId: typeId,
                value: value,
                remark: remark
              }
            }
            : identifier === 'credit'
              ? {
                variables: {
                  userId: userId,
                  typeId: typeId,
                  value: value,
                  remark: remark
                }
              }
              : {
                variables: {
                  userId: userId,
                  points: value,
                  notes: remark
                }
              }
        )

        if (response) {
          this.closeModal()
          refetch()
        }
      } catch (err) {
        this.setState({
          submitError: err.message
        })
      }
    } else {
      this.setState({
        invalid: true
      })
    }
  }

  /**
   *
   * @function updateRemarks - call network mutation to update remarks
   * for a specific credit id
   *
   * @param id
   */
  async updateRemarks(id) {
    const { handleUpdate, refetch } = this.props
    const { remark } = this.state

    try {
      let response = await handleUpdate({
        variables: {
          id: id,
          remarks: remark
        }
      })

      if (response) {
        this.closeModal()
        refetch()
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *
   * @function renderForm - render form with value and remark fields
   *
   * @returns {Component}
   */
  renderForm(withCreditField) {
    const { value, remark, invalid, errorMessage, submitError } = this.state
    return (
      <Form>
        {withCreditField && (
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button outline onClick={() => this.manageValue('minus')}>
                  -
                </Button>
              </InputGroupAddon>
              <Input value={value} name="value" onChange={this.onInputChange} />
              <InputGroupAddon addonType="append">
                <Button outline onClick={() => this.manageValue('add')}>
                  +
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {value === 0 ? (
              <p style={{ color: 'red' }}>Value cannot be zero</p>
            ) : null}
            {value === '' ? (
              <p style={{ color: 'red' }}>This field cannot be empty</p>
            ) : null}
            {errorMessage && errorMessage.length ? (
              <p style={{ color: 'red' }}>{errorMessage}</p>
            ) : null}
          </FormGroup>
        )}

        <FormGroup>
          <Label for="exampleText">Description</Label>
          <Input
            type="textarea"
            name="remark"
            value={remark}
            onChange={e => this.setState({ remark: e.target.value })}
            invalid={invalid}
          />
          <FormFeedback>This field cannot be left empty</FormFeedback>
        </FormGroup>
        <p style={{ color: 'red' }}>{submitError}</p>
      </Form>
    )
  }

  render() {
    const { modalData } = this.props

    if (!modalData) {
      return null
    }

    const { loading, errorMessage, value } = this.state
    return (
      <Modal isOpen={modalData.creditModal && modalData.creditModal.isVisible}>
        <ModalHeader toggle={this.closeModal}>
          {modalData && modalData.creditModal && modalData.creditModal.modalData
            ? modalData.creditModal.modalData.header
            : ''}
        </ModalHeader>
        <ModalBody>
          {this.renderForm(
            modalData &&
            modalData.creditModal &&
            modalData.creditModal.modalData &&
            modalData.creditModal.modalData.id
              ? false
              : true
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            disabled={
              loading ||
              (errorMessage && errorMessage.length > 0) ||
              value.length === 0 ||
              value === 0
            }
            onClick={() =>
              modalData.creditModal.modalData &&
              modalData.creditModal.modalData.id
                ? this.updateRemarks(modalData.creditModal.modalData.id)
                : this.adjustCredit()
            }
          >
            Save changes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

CreditModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  typeId: PropTypes.number.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  modalData: PropTypes.object,
  identifier: PropTypes.string.isRequired
}

export default compose(
  graphql(GET_LOCAL_CREDIT_MODAL_DATA, { name: 'modalData' })
)(CreditModal)
