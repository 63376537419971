import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
// import { GET_ALL_BOSSJOB_USERS } from '../../schemas/queries'
import { GET_ALL_BOSSHUNT_USERS } from '../../schemas/queries'

/* Components */
import BossHuntUserList from '../../views/BossHuntUsers/BossHuntUserList'
import Loading from 'shared/components/Loading'

/**
 * BossHuntUserListContainer - container component to query Bossjob user list
 * and return BossHuntUserList component when is done
 *
 * @returns {Component}
 *
 */
class BossHuntUserListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { search, fromDate, toDate, status } = queryString.parse(
      location.search
    )

    let isActive

    switch (status) {
    case 'active':
      isActive = true
      break
    case 'inactive':
      isActive = false
      break
    default:
      break
    }
    let variables = {
      page: parseInt(this.state.page),
      query: search ? search : null,
      createdDate:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null,
      isActive
    }

    return (
      <Query query={GET_ALL_BOSSHUNT_USERS} variables={variables}>
        {({ loading, data: { headhunterList } }) => {
          if (loading) {
            return <Loading />
          }
          if (headhunterList && headhunterList.data) {
            return (
              <BossHuntUserList
                headhunterList={headhunterList.data.headhunters}
                currentPage={Number(this.state.page)}
                totalHeadhunters={headhunterList.data.totalNum}
                totalPages={headhunterList.data.totalPages}
                {...this.props}
              />
            )
          }
          return null
        }}
      </Query>
    )
  }
}

export default withRouter(BossHuntUserListContainer)
