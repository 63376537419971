import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import Tab from './Tab/Tab'
import Head from './Head/Head'
import JobTable from './JobTable/JobTable'
import ChatTable from './ChatTable/ChatTable'
import Loading from 'shared/components/Loading'
import CompanyTable from './CompanyTable/CompanyTable'
import { client } from '../../../../../shared/configs/apolloClientConfig'

import { Query } from 'react-apollo'
import {
  FETCH_ANALYSIS_COMPANY,
  FETCH_ANALYSIS_HR,
  FETCH_ANALYSIS_JOB,
  FETCH_ANALYSIS_COMPANY_EXPORT_EXCEL,
  FETCH_ANALYSIS_JOB_EXPORT_EXCEL,
  FETCH_ANALYSIS_HR_EXPORT_EXCEL
} from './queries'

import { convertToISOFormat } from '../../../../../shared/helpers/datetime'

import 'react-widgets/lib/scss/react-widgets.scss'
//import styles from './CompanyAnalytics.module.scss'

class CompanyAnalytics extends Component {
  constructor(props) {
    super(props)

    const { match } = props
    const today = moment()

    this.state = {
      companyId: match.params.id,
      fromDate: new Date(today.clone().subtract(7, 'days')),
      toDate: new Date(today),
      tabActive: 1,
      chatPage: 1,
      jobPage: 1,
      exportLoading: false,
      modal: false,
      message: null
    }
  }

  search = () => {
    console.log(convertToISOFormat(this.state.fromDate), '///')
  }

  handleUpdateBeginDate = newDate => {
    this.setState({ fromDate: newDate })
  }

  handleUpdateEndDate = newDate => {
    this.setState({ toDate: newDate })
  }

  handleSwitchActiveTab = index => {
    this.setState({ tabActive: index })
  }

  handleChangeChatPage = index => {
    this.setState({
      chatPage: index
    })
  }

  handleChangeJobPage = index => {
    this.setState({
      jobPage: index
    })
  }

  handleExportRequest = async () => {
    let message
    let response

    const { tabActive, fromDate, toDate, companyId } = this.state
    this.setState({
      exportLoading: true
    })

    if (tabActive == 1) {
      response = await client.mutate({
        mutation: FETCH_ANALYSIS_COMPANY_EXPORT_EXCEL,
        variables: {
          companyId,
          fromDate: moment(fromDate).format('YYYY-MM-DDT00:00:00'),
          toDate: moment(toDate).format('YYYY-MM-DDT23:59:59')
        }
      })

      if (
        response.data &&
        response.data.analysisCompanyExcel &&
        response.data.analysisCompanyExcel.message
      ) {
        message = response.data.analysisCompanyExcel.message
      }
    } else if (tabActive == 2) {
      response = await client.mutate({
        mutation: FETCH_ANALYSIS_HR_EXPORT_EXCEL,
        variables: {
          companyId,
          fromDate: moment(fromDate).format('YYYY-MM-DDT00:00:00'),
          toDate: moment(toDate).format('YYYY-MM-DDT23:59:59')
        }
      })

      if (
        response.data &&
        response.data.analysisHrExcel &&
        response.data.analysisHrExcel.message
      ) {
        message = response.data.analysisHrExcel.message
      }
    } else if (tabActive == 3) {
      response = await client.mutate({
        mutation: FETCH_ANALYSIS_JOB_EXPORT_EXCEL,
        variables: {
          companyId,
          fromDate: moment(fromDate).format('YYYY-MM-DDT00:00:00'),
          toDate: moment(toDate).format('YYYY-MM-DDT23:59:59')
        }
      })
      if (
        response.data &&
        response.data.analysisJobExcel &&
        response.data.analysisJobExcel.message
      ) {
        message = response.data.analysisJobExcel.message
      }
    }

    if (message) {
      this.setState(
        {
          message
        },
        function() {
          this.toggle()
        }
      )
    }
    this.setState({
      exportLoading: false
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const {
      fromDate,
      toDate,
      tabActive,
      chatPage,
      jobPage,
      exportLoading,
      companyId,
      modal,
      message
    } = this.state

    const formatFromDate = moment(this.state.fromDate).format(
      'YYYY-MM-DDT00:00:00'
    )
    const formatToDate = moment(this.state.toDate).format('YYYY-MM-DDT23:59:59')

    const showFormatFromDate = moment(this.state.fromDate).format('YYYY-MM-DD')
    const showFormatToDate = moment(this.state.toDate).format('YYYY-MM-DD')

    const variables = {
      companyId,
      fromDate: formatFromDate,
      toDate: formatToDate
    }

    const paramsForChatHr = {
      ...variables,
      page: chatPage,
      size: 10
    }

    const paramsForChatJob = {
      ...variables,
      page: jobPage,
      size: 10
    }

    return (
      <div className="col">
        <Head
          fromDate={fromDate}
          toDate={toDate}
          handleUpdateBeginDate={this.handleUpdateBeginDate}
          handleUpdateEndDate={this.handleUpdateEndDate}
        />

        <Tab
          active={tabActive}
          handleSwitchActiveTab={this.handleSwitchActiveTab}
          handleExportRequest={this.handleExportRequest}
          exportLoading={exportLoading}
          fromDate={showFormatFromDate}
          toDate={showFormatToDate}
        />

        {tabActive == 1 ? (
          <Query query={FETCH_ANALYSIS_COMPANY} variables={variables}>
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else if (
                data &&
                data.analysisCompany &&
                data.analysisCompany.ok
              ) {
                const company = data.analysisCompany.data
                return <CompanyTable company={company} />
              } else {
                return <div>No data available</div> // Add a default return statement
              }
            }}
          </Query>
        ) : null}

        {tabActive == 2 ? (
          <Query query={FETCH_ANALYSIS_HR} variables={paramsForChatHr}>
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else if (data && data.analysisHr && data.analysisHr.ok) {
                return (
                  <ChatTable
                    data={data.analysisHr.data}
                    handleChangeChatPage={this.handleChangeChatPage}
                  />
                )
              } else {
                return <div>No data available</div> // Add a default return statement
              }
            }}
          </Query>
        ) : null}

        {tabActive == 3 ? (
          <Query query={FETCH_ANALYSIS_JOB} variables={paramsForChatJob}>
            {({ loading, data }) => {
              if (loading) {
                return <Loading />
              } else if (data && data.analysisJob && data.analysisJob.ok) {
                return (
                  <JobTable
                    data={data.analysisJob.data}
                    handleChangeJobPage={this.handleChangeJobPage}
                  />
                )
              } else {
                return <div>No data available</div> // Add a default return statement
              }
            }}
          </Query>
        ) : null}

        <Modal isOpen={modal} toggle={() => this.toggle()}>
          <ModalHeader toggle={() => this.toggle()}>Export Request</ModalHeader>
          <ModalBody>{message}</ModalBody>
        </Modal>
      </div>
    )
  }
}

export default withRouter(CompanyAnalytics)
