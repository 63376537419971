import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { graphql, compose } from 'react-apollo'
import { UPDATE_FEATURED_COMPANIES } from '../../../schemas/mutations'

import CompanyInputField from './components/CompanyInputField'
import { Button, Form } from 'reactstrap'

class FeaturedCompanyContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      countryId: props.countryId,
      companies: props.featuredCompanies || [{ value: '' }],
      isSubmitting: false
    }
  }

  static propTypes = {
    featuredCompanies: PropTypes.array,
    countryId: PropTypes.number
  }

  handleSubmit = async e => {
    e.preventDefault()
    try {
      this.setState({
        isSubmitting: true
      })
      const { companies, countryId } = this.state
      const { updateFeaturedCompanies } = this.props
      let priority = 0

      let companyIds = companies
        .map(company => {
          if (company.id) {
            priority++
            return {
              companyId: company.id,
              priority
            }
          }
          return null
        })
        .filter(company => !!company)

      await updateFeaturedCompanies({
        variables: {
          countryId: countryId,
          featureCompanies: companyIds
        }
      })

      this.setState({ isSubmitting: false })
      window.location.reload()
    } catch (err) {
      this.setState({ isSubmitting: false })
      console.log(err)
    }
  }

  addForm = () => {
    this.setState(prevState => ({
      companies: [...prevState.companies, { value: '' }]
    }))
  }

  removeForm = index => {
    this.setState(prevState => {
      prevState.companies.splice(index, 1)
      return {
        companies: [...prevState.companies]
      }
    })
  }

  handleChange = (value, index, id) => {
    this.setState(prevState => {
      const companies = prevState.companies
      companies[index] = {
        value,
        ...(id && { id })
      }

      if (!id) {
        companies[index].id = ''
      }

      return {
        companies
      }
    })
  }

  CompanyInputList = () => {
    const { companies, countryId } = this.state

    return companies.map((nothing, index) => {
      // eslint-disable-line
      return (
        <CompanyInputField
          key={index}
          company={companies[index]}
          handleChange={this.handleChange}
          index={index}
          removeForm={this.removeForm}
          countryId={countryId}
        />
      )
    })
  }

  render() {
    const { CompanyInputList } = this
    const { isSubmitting } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <CompanyInputList />
        <Button
          type="primary"
          color="primary"
          style={{
            marginTop: '20px',
            display: 'block',
            color: 'white',
            fontWeight: 'bold',
            width: '200px',
            fontSize: '20px'
          }}
          onClick={this.addForm}
        >
          +
        </Button>
        <Button
          type="submit"
          style={{ marginTop: '50px' }}
          color="success"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Form>
    )
  }
}

export default compose(
  graphql(UPDATE_FEATURED_COMPANIES, { name: 'updateFeaturedCompanies' })
)(FeaturedCompanyContainer)
