exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".avatar__1m3wA {\n  width: 90px;\n  height: 90px;\n  text-align: center;\n}", "", {"version":3,"sources":["/app/src/modules/neptuneCompanies/views/NeptuneCompany/InformationContainer/informationContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAW;EACX,aAAY;EACZ,mBAAkB;CACpB","file":"informationContainer.module.scss","sourcesContent":[".avatar {\n  width: 90px;\n  height: 90px;\n  text-align: center;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"avatar": "avatar__1m3wA"
};