import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Bootstrap */
import { Button, Alert } from 'reactstrap'

/* Components */
import ConfirmationAlert from 'shared/components/ConfirmationAlert'

/**
 * ResetPassword - Button component for BossJobUserDetail - AccountSetting page
 *
 * @returns {Component}
 */
const ResetPassword = ({ handlePasswordReset, isUpdating }) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [status, setStatus] = useState('')
  return (
    <ApolloConsumer>
      {client => (
        <React.Fragment>
          <Alert color={status} isOpen={!!alertMessage}>
            {alertMessage}
          </Alert>
          <Button
            color="danger"
            onClick={() => setConfirmationVisible(true)}
            disabled={isUpdating}
          >
            Reset password
          </Button>

          <ConfirmationAlert
            isVisible={confirmationVisible}
            onConfirm={async () => {
              let response = await handlePasswordReset(client)
              let success
              let message
              if (response.data) {
                success = response.data.ok
                message = response.data.message
              } else {
                success = false
                message = response.errors.message
              }
              setConfirmationVisible(false)
              setStatus(success ? 'success' : 'danger')
              setAlertMessage(message)
            }}
            onClose={() => setConfirmationVisible(false)}
            title="Reset password"
            body="Are you sure you want to reset this user's password?"
          />
        </React.Fragment>
      )}
    </ApolloConsumer>
  )
}

ResetPassword.propTypes = {
  handlePasswordReset: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool
}

export default ResetPassword
