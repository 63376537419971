import React from 'react'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'
import { Query } from 'react-apollo'
import { GET_JOB_PAGE_LEFT_SIDE_BANNER } from '../../schemas/queries'
import LeftSideBannerContainer from './LeftSideBannerContainer/LeftSideBannerContainer'

const LeftSideBanner = () => (
  <div>
    <SectionWrapper sectionTitle="Job Page Left Side Banner" isMainPage>
      <p style={{ color: 'red', fontSize: '16px' }}>
        *Note: The side banner to be displayed is configured to be chosen
        randomly
      </p>
      <Query query={GET_JOB_PAGE_LEFT_SIDE_BANNER}>
        {({ data: { leftSideBanners }, loading }) => {
          if (loading) {
            return <Loading />
          }
          if (leftSideBanners) {
            return <LeftSideBannerContainer sideBanners={leftSideBanners} />
          }
          return null
        }}
      </Query>
    </SectionWrapper>
  </div>
)

export default LeftSideBanner
