import React from 'react'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ALL_ROLES } from '../../schemas/queries'

/* Components */
import Loading from '../../../../shared/components/Loading'
import AddUserForm from '../../views/AddUser/AddUserForm'

const AddUserFormContainer = () => (
  <Query query={GET_ALL_ROLES}>
    {({ loading, data }) => {
      if (loading) {
        return <Loading />
      }

      if (data && data.allRoles) {
        return <AddUserForm rolesOption={data.allRoles} />
      }
    }}
  </Query>
)

export default AddUserFormContainer
