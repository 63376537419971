import gql from 'graphql-tag'

const getNeptuneCompanyFragment = gql`
  fragment getNeptuneCompany on NeptuneCompanyResponse {
    ok
    message
    data {
      neptuneCompany {
        id
        name
        legalName
        country {
          id
          value
        }
        companyBusinessInformation {
          id
          companyType {
            id
            value
          }
          establishedDate
        }
        companyFinancial {
          id
          operatingStatus {
            id
            value
          }
        }
      }
      neptuneCompanyDocument {
        id
        url
        statusKey
        changeRequiredReason
        rejectedReason
        isVerify
      }
      applicantRecruiter {
        id
        firstName
        lastName
        phoneNum
        avatar
        recruiterWorkExperience {
          id
          jobTitle
        }
      }
      companyCountryNumber {
        id
        companyNumber
        statusKey
        activeReason
        rejectedReason
        changeRequiredReason
      }
    }
  }
`

/** Get all Bossjob users */
export const GET_ALL_BOSSJOB_USERS = gql`
  query getAllUsers(
    $page: Int
    $query: String
    $date: DateTimeRange
    $activeKey: Int
  ) {
    allBossjobUsers(
      size: 15
      page: $page
      queryData: $query
      date: $date
      activeKey: $activeKey
    ) {
      data {
        users {
          id
          email
          firstName
          lastName
          phoneNum
          statusKey
          activeKey
          createdAt
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

export const GET_LOCAL_NEPTUNE_COMPANY_DETAIL = gql`
  {
    getNeptuneCompany @client {
      ...getNeptuneCompany
    }
  }
  ${getNeptuneCompanyFragment}
`

/** Get Bossjob company document by company ID */
export const GET_COMPANY_DOCUMENT = gql`
  query getCompanyByID($companyId: Int!) {
    getCompany(companyId: $companyId) {
      data {
        document {
          id
          url
          statusKey
        }
      }
    }
  }
`

/** Get Bossjob company by company ID */
export const GET_NEPTUNE_COMPANY_BY_ID = gql`
  query getNeptuneCompanyById($NeptuneCompanyId: Int!) {
    getNeptuneCompany(neptuneCompanyId: $NeptuneCompanyId) {
      ...getNeptuneCompany
    }
  }
  ${getNeptuneCompanyFragment}
`

/**Get all company documents by company ID (including rejected) */
export const GET_COMPANY_DOCUMENTS = gql`
  query getCompanyDocuments($companyId: Int!) {
    getCompanyDocuments(companyId: $companyId) {
      data {
        id
        recruiterId
        rejectedReason
        changeRequiredReason
        createdAt
        updatedAt
      }
    }
  }
`

/** Get my account info */
export const GET_ME = gql`
  query {
    me {
      id
      email
      name
      role {
        roleNodes {
          id
          key
          canView
          canEdit
        }
      }
    }
  }
`

/** Get all users */
export const GET_ALL_USERS = gql`
  query getAllAdminUsers(
    $page: Int
    $query: String
    $date: DateTimeInput
    $size: Int
  ) {
    allAdminUsers(size: $size, page: $page, queryData: $query, date: $date) {
      data {
        adminUsers {
          id
          email
          name
          createdAt
          isActive
          role {
            roleName
          }
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

export const REQUEST_CLAIM_NEPTUNE_COMPANY_LIST = gql`
  query getRequestClaimNeptuneCompanyList(
    $page: Int
    $size: Int
    $name: String
    $legalName: String
    $operatingStatusIds: [Int]
    $companyTypeIds: [Int]
    $countryIds: [Int]
    $salespersonIds: [Int]
  ) {
    requestClaimNeptuneCompanyList(
      page: $page
      size: $size
      name: $name
      legalName: $legalName
      operatingStatusIds: $operatingStatusIds
      companyTypeIds: $companyTypeIds
      countryIds: $countryIds
      salespersonIds: $salespersonIds
    ) {
      ok
      message
      data {
        totalPages
        totalNum
        currentPage
        currentNum
        items {
          neptuneCompany {
            id
            name
            legalName
            country {
              id
              value
            }
            companyBusinessInformation {
              id
              companyType {
                id
                value
              }
              establishedDate
            }
            companyFinancial {
              id
              operatingStatus {
                id
                value
              }
            }
          }
        }
      }
    }
  }
`

/** Get Bossjob company by company ID */
// export const GET_COMPANY__VERIFICATION_METHOD = gql`
//   query getCompanyVerificationMethod($companyId: Int!) {
//     getCompany(companyId: $companyId) {
//       data {
//         id
//         verificationInfo
//         corppassInfo
//       }
//     }
//   }
// `

const getNeptuneCompanyVerificationMethod = gql`
  fragment getNeptuneCompany on NeptuneCompanyResponse {
    ok
    message
    data {
      neptuneCompany {
        id
        name
        legalName
        verificationInfo
      }
    }
  }
`

export const GET_NEPTUNE_COMPANY_VERIFICATION_METHOD = gql`
  query getNeptuneCompanyById($neptuneCompanyId: Int!) {
    getNeptuneCompany(neptuneCompanyId: $neptuneCompanyId) {
      ...getNeptuneCompany
    }
  }
  ${getNeptuneCompanyVerificationMethod}
`
