import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Bootstrap */
import { Row, Col } from 'reactstrap'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import {
  GET_TRANSACTION_BY_ID,
  GET_BOSSJOB_USER_BY_ID,
  GET_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM
} from '../../schemas/queries'
import { GET_ME } from '../../../users/schemas/queries'
import {
  UPDATE_LOCAL_TRANSACTION_DETAILS,
  UPDATE_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM
} from '../../schemas/mutations'

/* Components */
import DetailForm from '../../views/TransactionDetail/Forms/DetailForm'
import BillingAddressForm from '../../views/TransactionDetail/Forms/BillingAddressForm'
import PaymentDetailForm from '../../views/TransactionDetail/Forms/PaymentDetailForm'
import SubmitForm from '../../views/TransactionDetail/Forms/SubmitForm'
import Loading from 'shared/components/Loading'
import Attachments from '../../views/TransactionDetail/Attachments'

/**
 * TransactionDetailContainer
 *
 * @returns {Component}
 */
class TransactionDetailContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    updateLocalTransactionDetails: PropTypes.func.isRequired,
    updateLocalTransactionDetailsForNewForm: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      transactionId: props.match.params.transactionId,
      userId: props.match.params.userId,
      file: null
    }
  }

  handleFileChange = file => {
    this.setState({
      file: file
    })
  }

  _updateLocalTransactionDetailState = (key, value) => {
    const { updateLocalTransactionDetails } = this.props
    updateLocalTransactionDetails({
      variables: {
        key: key,
        value: value
      }
    })
  }
  _updateLocalTransactionDetailStateForNewForm = (key, value) => {
    const { updateLocalTransactionDetailsForNewForm } = this.props
    updateLocalTransactionDetailsForNewForm({
      variables: {
        key: key,
        value: value
      }
    })
  }

  handleChangeForExistingForm = e => {
    this._updateLocalTransactionDetailState(e.target.name, e.target.value)
  }

  handleChangeForNewForm = e => {
    this._updateLocalTransactionDetailStateForNewForm(
      e.target.name,
      e.target.value
    )
  }

  handleChangeForNewFormToggle = (key, value) => {
    this._updateLocalTransactionDetailStateForNewForm(key, value)
  }

  customHandleChangeForNewForm = (key, value) => {
    this._updateLocalTransactionDetailStateForNewForm(key, value)
  }

  customHandleChangeForExistingForm = (key, value) => {
    this._updateLocalTransactionDetailState(key, value)
  }

  renderExistingForm(transactionId) {
    return (
      <Query query={GET_TRANSACTION_BY_ID} variables={{ transactionId }}>
        {({ loading, data: { transaction }, client }) => {
          if (loading) return <Loading />
          client.writeData({
            data: {
              transactionDetail: transaction.data
            }
          })
          return (
            <Query
              query={GET_BOSSJOB_USER_BY_ID}
              variables={{ userId: transaction.data.userId }}
            >
              {({ loading, data: { bossjobUser } }) => {
                if (loading) return <Loading />
                return (
                  <Row>
                    <Col md={8}>
                      <DetailForm
                        transactionId={transactionId}
                        transactionDetail={transaction.data}
                        handleChange={this.handleChangeForExistingForm}
                        isDisabled={transactionId !== null}
                      />
                      <PaymentDetailForm
                        transactionDetail={transaction.data}
                        handleChange={this.handleChangeForExistingForm}
                        isDisabled={transactionId !== null}
                        customHandleChange={
                          this.customHandleChangeForExistingForm
                        }
                      />
                      <BillingAddressForm
                        transactionDetail={transaction.data}
                        handleChange={this.handleChangeForExistingForm}
                        // billing form will only be disabled if status is no longer pending
                        isDisabled={transaction.data.status !== 'Pending'}
                      />
                      <Attachments
                        transactionDetail={transaction.data}
                        transactionId={transactionId}
                      />
                    </Col>
                    <Col md={3}>
                      <SubmitForm
                        transactionDetail={transaction.data}
                        handleChange={this.handleChangeForExistingForm}
                        customHandleChange={
                          this.customHandleChangeForExistingForm
                        }
                        submitType="Update"
                        userId={transaction.data.userId}
                        userName={`${bossjobUser.data.firstName} ${
                          bossjobUser.data.lastName
                        }`}
                      />
                    </Col>
                  </Row>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }

  renderNewForm() {
    const { userId } = this.state
    return (
      <Query
        query={GET_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM}
        variables={{ userId }}
      >
        {({ loading, data: { transactionDetailForNewForm } }) => {
          if (loading) return <Loading />
          return (
            <Query query={GET_ME}>
              {({ loading, data }) => {
                if (loading) {
                  return <Loading />
                }
                return (
                  <Query query={GET_BOSSJOB_USER_BY_ID} variables={{ userId }}>
                    {({ loading, data: { bossjobUser } }) => {
                      if (loading) return <Loading />
                      return (
                        <Row>
                          <Col md={8}>
                            <DetailForm
                              transactionDetail={transactionDetailForNewForm}
                              handleChange={this.handleChangeForNewForm}
                              handleToggle={this.handleChangeForNewFormToggle}
                              isDisabled={false}
                            />
                            <PaymentDetailForm
                              transactionDetail={transactionDetailForNewForm}
                              handleChange={this.handleChangeForNewForm}
                              isDisabled={false}
                              customHandleChange={
                                this.customHandleChangeForNewForm
                              }
                            />
                            <BillingAddressForm
                              transactionDetail={transactionDetailForNewForm}
                              handleChange={this.handleChangeForNewForm}
                            />
                          </Col>
                          <Col md={3}>
                            <SubmitForm
                              transactionDetail={transactionDetailForNewForm}
                              handleChange={this.handleChangeForNewForm}
                              customHandleChange={
                                this.customHandleChangeForNewForm
                              }
                              submitType="Add"
                              userId={userId}
                              userName={`${bossjobUser.data.firstName} ${
                                bossjobUser.data.lastName
                              }`}
                              salesPersonEmail={data.me.email}
                              billerEmail={bossjobUser.data.email}
                            />
                          </Col>
                        </Row>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }

  render() {
    const { transactionId } = this.state
    if (transactionId) {
      return this.renderExistingForm(transactionId)
    } else {
      return this.renderNewForm()
    }
  }
}

export default compose(
  graphql(UPDATE_LOCAL_TRANSACTION_DETAILS, {
    name: 'updateLocalTransactionDetails'
  }),
  graphql(UPDATE_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM, {
    name: 'updateLocalTransactionDetailsForNewForm'
  })
)(withRouter(TransactionDetailContainer))
