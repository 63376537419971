import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import { UPDATE_TRANSACTION } from '../../../schemas/mutations'
import { GET_LOCAL_TRANSACTION_DETAILS } from '../../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import UpdateButton from '../../../views/TransactionDetail/Forms/SubmitForm/UpdateButton'

/**
 * UpdateButtonContainer - Handles the Apollo logic side of the UpdateButton
 *
 * @returns {Component}
 */
const UpdateButtonContainer = ({ UpdateTransaction }) => (
  <Query query={GET_LOCAL_TRANSACTION_DETAILS}>
    {({ loading, data: { transactionDetail } }) => {
      if (loading) return <Loading />
      return (
        <UpdateButton
          transactionDetail={transactionDetail}
          UpdateTransaction={UpdateTransaction}
        />
      )
    }}
  </Query>
)

UpdateButtonContainer.propTypes = {
  UpdateTransaction: PropTypes.func.isRequired,
  transactionDetail: PropTypes.object
}

export default compose(
  graphql(UPDATE_TRANSACTION, { name: 'UpdateTransaction' })
)(UpdateButtonContainer)
