import React from 'react'
import PropTypes from 'prop-types'

/* Component */
import { Table } from 'reactstrap'
import SectionWrapper from 'shared/components/SectionWrapper'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

const SubscriptionCreditHistory = ({ creditHistories }) => (
  <SectionWrapper sectionTitle="Credit History">
    <Table bordered>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>Cycle</th>
          <th style={{ textAlign: 'center' }}>Job Post </th>
          <th style={{ textAlign: 'center' }}>Talent Credit</th>
          <th style={{ textAlign: 'center' }}>Feature Job Credit</th>
          <th style={{ textAlign: 'center' }}>Urgent Job Credit</th>
          <th style={{ textAlign: 'center' }}>Credited At</th>
        </tr>
      </thead>
      <tbody>
        {creditHistories &&
          creditHistories.map((creditHistory, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'center' }}>{index + 1}</td>
              <td style={{ textAlign: 'center' }}>{creditHistory.jobPost}</td>
              <td style={{ textAlign: 'center' }}>
                {creditHistory.unlockTalentCredit}
              </td>
              <td style={{ textAlign: 'center' }}>
                {creditHistory.featureJobCredit}
              </td>
              <td style={{ textAlign: 'center' }}>
                {creditHistory.urgentJobCredit}
              </td>
              <td style={{ textAlign: 'center' }}>
                {formatDate(creditHistory.createdAt, 'DD/MM/YYYY HH:mm:Ss')}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  </SectionWrapper>
)

SubscriptionCreditHistory.propTypes = {
  creditHistories: PropTypes.array.isRequired
}

export default SubscriptionCreditHistory
