import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Apollo */
import { Query } from 'react-apollo'

/* Components */
import Loading from 'shared/components/Loading'
import { GET_LOCAL_AGENCY_DETAIL } from '../../../schemas/queries'
import SpecializationForm from '../../../views/AgencyDetail/SpecializationForm/SpecializationForm'

const SpecializationFormContainer = ({
  match,
  handleChangeForExistingForm,
  handleSpecializationChange
}) => {
  return (
    <Query query={GET_LOCAL_AGENCY_DETAIL}>
      {({ loading, data: { agencyDetail } }) => {
        if (loading) {
          return <Loading />
        }
        return (
          <SpecializationForm
            match={match}
            agencyDetail={agencyDetail}
            handleChange={handleChangeForExistingForm}
            handleSpecializationChange={handleSpecializationChange}
          />
        )
      }}
    </Query>
  )
}

SpecializationFormContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  handleSpecializationChange: PropTypes.func,
  handleChangeForExistingForm: PropTypes.func
}

export default SpecializationFormContainer
