exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".chip__1k4Qr {\n  border: 1px solid gray;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  padding: 5px 18px;\n  font-size: 13px;\n  letter-spacing: 0.75px;\n  border-radius: 20px;\n  color: grey;\n  border-color: grey;\n  margin: 10px 5px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.chip__1k4Qr::after {\n  content: \"x\";\n  margin-left: 10px;\n  cursor: pointer;\n}", "", {"version":3,"sources":["/app/src/shared/components/Chip/Chip.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAsB;EACtB,+BAAsB;UAAtB,uBAAsB;EACtB,kBAAiB;EACjB,gBAAe;EACf,uBAAsB;EACtB,oBAAmB;EACnB,YAAW;EACX,mBAAkB;EAClB,iBAAgB;EAChB,qBAAa;EAAb,qBAAa;EAAb,cAAa;CACf;AACA;EACE,aAAY;EACZ,kBAAiB;EACjB,gBAAe;CACjB","file":"Chip.module.scss","sourcesContent":[".chip {\n  border: 1px solid gray;\n  box-sizing: border-box;\n  padding: 5px 18px;\n  font-size: 13px;\n  letter-spacing: 0.75px;\n  border-radius: 20px;\n  color: grey;\n  border-color: grey;\n  margin: 10px 5px;\n  display: flex;\n}\n.chip::after {\n  content: \"x\";\n  margin-left: 10px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"chip": "chip__1k4Qr"
};