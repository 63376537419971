import React from 'react'

/* Boostrap Components */
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

/* Components */
import LoginForm from './LoginForm'

/* Assets */
import styles from './Login.module.scss'

/**
 * Login - main login view to show login form
 *
 * @returns {Component}
 */
const Login = () => (
  <div className={styles.loginWrapper}>
    <Container>
      <Row style={{ justifyContent: 'center' }}>
        <Col md="6">
          <div className={styles.login}>
            <Card>
              <CardBody>
                <CardTitle>Login</CardTitle>
                <LoginForm />
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Login
