import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

/**
 * SubscriptionHistoryList - Bossjob recruiter credit history list table
 *
 */
const SubscriptionHistoryList = ({
  historyList,
  currentPage,
  totalNum,
  totalPages
}) => (
  <React.Fragment>
    <Table bordered>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>Subscription ID</th>
          <th style={{ textAlign: 'center' }}>Date</th>
          <th style={{ textAlign: 'center' }}>Item Name</th>
          <th style={{ textAlign: 'center' }}>Frequency</th>
          <th style={{ textAlign: 'center' }}>Current Cycle</th>
          <th style={{ textAlign: 'center' }}>Last Payment</th>
          <th style={{ textAlign: 'center' }}>Next Payment</th>
          <th style={{ textAlign: 'center' }}>Active</th>
          <th style={{ textAlign: 'center' }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {historyList.map((subscription, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'center' }}>
              <a href={`/bossjob/subscription/detail/${subscription.id}`}>
                {subscription.id}
              </a>
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatDate(subscription.createdAt, 'DD/MM/YYYY HH:mm:Ss')}
            </td>
            <td style={{ textAlign: 'center' }}>{subscription.name}</td>
            <td style={{ textAlign: 'center' }}>{subscription.frequency}</td>
            <td style={{ textAlign: 'center' }}>{subscription.cycle}</td>
            <td>
              {subscription.lastPaymentAt ? (
                formatDate(subscription.lastPaymentAt, 'DD/MM/YYYY HH:mm:Ss')
              ) : (
                <span style={{ display: 'block', textAlign: 'center' }}>-</span>
              )}
            </td>
            <td>
              {subscription.nextPaymentAt
                ? formatDate(subscription.nextPaymentAt, 'DD/MM/YYYY HH:mm:Ss')
                : '-'}
            </td>
            <td style={{ textAlign: 'center' }}>
              {subscription.isActive ? 'True' : 'False'}
            </td>
            <td>
              <div style={{ textAlign: 'center' }}>
                <a href={`/bossjob/subscription/detail/${subscription.id}`}>
                  <Button color="success">
                    <img src={Zoom} alt="Zoom" width="16" height="16" />
                  </Button>
                </a>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Pagination
      currentPage={Number(currentPage)}
      totalCount={Number(totalNum)}
      totalPages={Number(totalPages)}
      pageRange={4}
    />
  </React.Fragment>
)

SubscriptionHistoryList.propTypes = {
  /* array of history list */
  historyList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalNum: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired
}

export default SubscriptionHistoryList
