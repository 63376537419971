import React from 'react'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import DialogueDetailContainer from '../../containers/DialogueDetailContainer'
import ChatDetailHeaderContainer from '../../containers/ChatDetailHeaderContainer'

const ChatDetail = () => (
  <>
    <ChatDetailHeaderContainer />
    <SectionWrapper sectionTitle="Dialogue">
      <DialogueDetailContainer />
    </SectionWrapper>
  </>
)

export default ChatDetail
