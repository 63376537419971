import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'
import ReferralCode from '../ReferralCode'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

const statusMapping = {
  verified: 'Verified',
  change_required: 'Change Required',
  pending: 'Pending',
  rejected: 'Rejected',
  document_not_uploaded: 'Document not Uploaded'
}

const CompanyList = ({
  companyList,
  currentPage,
  totalCompanies,
  totalPages,
  supportedCountries
}) => {
  const resolveCountry = id => {
    const registeredCountry = supportedCountries.find(
      country => country.id == id
    )
    if (registeredCountry) {
      return registeredCountry.value
    }
    return '-'
  }
  return (
    <React.Fragment>
      <Table bordered>
        <thead>
          <tr>
            <th />
            <th>ID</th>
            <th>Legal Name</th>
            <th style={{ textAlign: 'center' }}>No. of Recruiters</th>
            <th style={{ textAlign: 'center' }}>Authentication Status</th>
            <th>Registered Country</th>
            <th>Active Status</th>
            <th>Current verification method</th>
            <th>Created</th>
            <th>Activated</th>
            <th>Deleted</th>
            <th>Salesperson</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companyList.map(company => (
            <tr key={`company-${company.id}`}>
              <td />
              <td>
                <a href={`/bossjob/company/${company.id}`}>{company.id}</a>
              </td>
              <td>{company.legalName}</td>
              <td style={{ textAlign: 'center' }}>
                <a href={`/bossjob/company/${company.id}/recruiters/1`}>
                  {company.recruiterAmount}
                </a>
              </td>
              <td style={{ textAlign: 'center' }}>
                {company.authenticationStatus
                  ? statusMapping[company.authenticationStatus]
                  : 'Document not Uploaded'}
              </td>
              <td>{resolveCountry(company.registeredCountryId)}</td>
              <td>
                {!company.isVerify || company.deletedAt != null
                  ? 'Inactive'
                  : 'Active'}
              </td>
              <td>{company.verificationInfo.verification_method}</td>
              <td>{formatDate(company.createdAt, 'DD/MM/YYYY HH:mm:Ss')}</td>
              <td>
                {company.publishedAt != null
                  ? formatDate(company.publishedAt, 'DD/MM/YYYY HH:mm:Ss')
                  : '-'}
              </td>
              <td>
                {company.deletedAt != null
                  ? formatDate(company.deletedAt, 'DD/MM/YYYY HH:mm:Ss')
                  : '-'}
              </td>
              <td>{company.salesPersonEmail}</td>
              <td>
                <div style={{ textAlign: 'center' }}>
                  <a href={`/bossjob/company/${company.id}`}>
                    <Button color="success">
                      <img src={Zoom} alt="Zoom" width="16" height="16" />
                    </Button>
                  </a>

                  <ReferralCode id={company.id} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalCompanies}
        totalPages={totalPages}
        pageRange={4}
      />
    </React.Fragment>
  )
}

CompanyList.propTypes = {
  /* array of company list */
  companyList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalCompanies: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  supportedCountries: PropTypes.array.isRequired
}

export default CompanyList
