import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * AccountStatusForm - form component for BossJobUserDetail - AccountSetting page
 *
 * @returns {Component}
 */
const AccountStatusForm = ({
  userData: { statusKey },
  handleChange,
  updateUser,
  isUpdating
}) => (
  <ApolloConsumer>
    {client => (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Account Status
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="select"
                  name="accountStatus"
                  value={statusKey}
                  onChange={e => {
                    handleChange(client, 'statusKey', e.target.value)
                  }}
                >
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="inactive">Inactive</option>
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Can I="edit" a="bossjob_user">
          <div style={{ textAlign: 'right' }}>
            <Button
              color="info"
              onClick={() => updateUser(client)}
              disabled={isUpdating}
            >
              Save
            </Button>
          </div>
        </Can>
      </Form>
    )}
  </ApolloConsumer>
)

AccountStatusForm.propTypes = {
  userData: PropTypes.object,

  updateUser: PropTypes.func.isRequired,

  handleChange: PropTypes.func.isRequired,

  isUpdating: PropTypes.bool.isRequired
}

export default AccountStatusForm
