import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Custom Hooks */
import useWindowDimensions from 'shared/hooks/useWindowDimensions'

/**
 * JobStatList - Bossjob recruiter job statistic list table
 *
 */
const JobStatList = ({ jobStats, currentPage, totalCount, totalPages }) => {
  // eslint-disable-next-line no-unused-vars
  const { height, width } = useWindowDimensions()
  const width_limit = 1900

  return (
    <React.Fragment>
      <Table bordered>
        <thead>
          <tr>
            <th>ID</th>
            <th>Job Title</th>
            {width >= width_limit && <th>Status</th>}
            <th>Activity Score</th>
            <th>Total Views</th>
            <th>Total Applications</th>
            <th>Total Interviewed</th>
            <th>Total Hired</th>
            <th>Not Suitable (Interview)</th>
            <th>Not Suitable (Application)</th>
            <th>Initiated By Jobseeker</th>
            <th>Initiated By Recruiter</th>
            <th>Total In Progress</th>
            {width >= width_limit && (
              <>
                <th>Published at</th>
                <th>Expired at</th>
                <th>Created at</th>
                <th>Deleted at</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {jobStats &&
            jobStats.map(jobStat => (
              <tr key={`job-${jobStat.jobId}`}>
                <td>
                  <a href={`/bossjob/job/${jobStat.jobId}`}>{jobStat.jobId}</a>
                </td>
                <td>{jobStat.jobTitle}</td>
                {width >= width_limit && <td>{jobStat.statusKey}</td>}
                <td>{jobStat.jobActivityScore}</td>
                <td>{jobStat.totalViews}</td>
                <td>{jobStat.totalApplications}</td>
                <td>{jobStat.totalInterviewed}</td>
                <td>{jobStat.totalHired}</td>
                <td>{jobStat.totalNotSuitableInterview}</td>
                <td>{jobStat.totalNotSuitableApplication}</td>
                <td>{jobStat.initiatedByJobseeker}</td>
                <td>{jobStat.initiatedByRecruiter}</td>
                <td>{jobStat.totalInProgress}</td>
                {width >= width_limit && (
                  <>
                    <td>{jobStat.publishedAt}</td>
                    <td>{jobStat.expiredAt}</td>
                    <td>{jobStat.createdAt}</td>
                    <td>{jobStat.deletedAt ? jobStat.deletedAt : '-'}</td>
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalCount}
        totalPages={totalPages}
        pageRange={4}
      />
    </React.Fragment>
  )
}

JobStatList.propTypes = {
  /* array of job list */
  jobStats: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalCount: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired
}

export default JobStatList
