import React from 'react'

/* Assets */
import styles from './Loading.module.scss'

/**
 * Loading - reusable loading component
 */
const Loading = () => (
  <div className={styles.loadingWrapper}>
    <div className={styles.loading}>
      <div />
      <div />
      <div />
    </div>
  </div>
)

export default Loading
