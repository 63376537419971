import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, compose } from 'react-apollo'
import {
  ADD_NEW_PRODUCT_FEATURE_ANNOUNCEMENT,
  UPDATE_NEW_PRODUCT_FEATURE_ANNOUNCEMENT,
  REMOVE_NEW_PRODUCT_FEATURE_ANNOUNCEMENT
} from '../../../schemas/mutations'
import { Button } from 'reactstrap'

import ProductFeatureAnnouncementForm from './components/ProductFeatureAnnouncementForm'
import styles from './ProductFeatureAnnouncementContainer.module.scss'

const ProductFeatureAnnouncementContainer = ({
  list,
  list: { data },
  addProductFeatureAnnouncement,
  updateProductFeatureAnnouncement,
  removeProductFeatureAnnouncement
}) => {
  const [formAmount, setFormAmount] =
    useState(list.data && list.data.length) || 0

  const addForm = () => {
    setFormAmount(prevState => {
      return prevState + 1
    })
  }

  const handleRemoveProductFeatureAnnouncement = async featureId => {
    try {
      await removeProductFeatureAnnouncement({
        variables: {
          featureId: Number(featureId)
        }
      })

      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  const handleAddProductFeatureAnnouncement = async e => {
    try {
      e.preventDefault()
      const formData = new FormData(e.target)

      let payload = {
        ...(formData.get('title') && {
          title: formData.get('title')
        }),
        ...(formData.get('subTitle') && {
          subTitle: formData.get('subTitle')
        }),
        ...(formData.get('description') && {
          description: formData.get('description')
        }),
        ...(formData.get('ctaText') && {
          ctaText: formData.get('ctaText')
        }),
        ...(formData.get('ctaUrl') && {
          ctaUrl: formData.get('ctaUrl')
        }),
        ...(formData.get('sortOrder') && {
          sortOrder: Number(formData.get('sortOrder'))
        })
      }

      await addProductFeatureAnnouncement({
        variables: {
          productFeatureBannerInput: payload
        }
      })

      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  const handleUpdateProductFeatureAnnouncement = async e => {
    try {
      e.preventDefault()
      const formData = new FormData(e.target)

      let payload = {
        ...(formData.get('title') && {
          title: formData.get('title')
        }),
        ...(formData.get('subTitle') && {
          subTitle: formData.get('subTitle')
        }),
        ...(formData.get('description') && {
          description: formData.get('description')
        }),
        ...(formData.get('ctaText') && {
          ctaText: formData.get('ctaText')
        }),
        ...(formData.get('ctaUrl') && {
          ctaUrl: formData.get('ctaUrl')
        }),
        ...(formData.get('sortOrder') && {
          sortOrder: Number(formData.get('sortOrder'))
        })
      }

      await updateProductFeatureAnnouncement({
        variables: {
          featureId: Number(formData.get('id')),
          productFeatureBannerInput: payload
        }
      })

      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <section className={styles.container}>
      <Button
        type="primary"
        color="primary"
        className={styles.addFormButton}
        onClick={addForm}
      >
        Add Side Banner
      </Button>
      <div className={styles.formWrapper}>
        {[...Array(formAmount)].map((nothing, index) => {
          // eslint-disable-line
          return (
            <ProductFeatureAnnouncementForm
              key={index}
              index={index}
              list={data[index] || {}}
              addProductFeatureAnnouncement={
                handleAddProductFeatureAnnouncement
              }
              updateProductFeatureAnnouncement={
                handleUpdateProductFeatureAnnouncement
              }
              removeProductFeatureAnnouncement={
                handleRemoveProductFeatureAnnouncement
              }
            />
          )
        })}
      </div>
    </section>
  )
}

ProductFeatureAnnouncementContainer.propTypes = {
  list: PropTypes.object.isRequired,
  addProductFeatureAnnouncement: PropTypes.func.isRequired,
  updateProductFeatureAnnouncement: PropTypes.func.isRequired,
  removeProductFeatureAnnouncement: PropTypes.func.isRequired
}

export default compose(
  graphql(ADD_NEW_PRODUCT_FEATURE_ANNOUNCEMENT, {
    name: 'addProductFeatureAnnouncement'
  }),
  graphql(UPDATE_NEW_PRODUCT_FEATURE_ANNOUNCEMENT, {
    name: 'updateProductFeatureAnnouncement'
  }),
  graphql(REMOVE_NEW_PRODUCT_FEATURE_ANNOUNCEMENT, {
    name: 'removeProductFeatureAnnouncement'
  })
)(ProductFeatureAnnouncementContainer)
