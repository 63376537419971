import React, { Component } from 'react'

/* Apollo */
import { Mutation } from 'react-apollo'
import { ADD_NOTIFY_MAINTAIN_INFO } from '../../schemas/mutations'

/* Bootstrap */
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Alert
} from 'reactstrap'

import DateTimePicker from 'react-widgets/lib/DateTimePicker'

/* Helper */
import history from '../../../../shared/helpers/history'

import { validSubmitDate } from '../../utils'

class AddForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      errorMessage: '',
      // date
      startedAt: null,
      endedAt: null,
      // language
      en: '',
      zhCN: '',
      zhTW: '',
      ja: '',
      id: '',
      ms: '',
      tr: ''
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange = (name, value) => {
    this.setState({ [name]: value })
  }

  render() {
    const { startedAt, endedAt, ms, en, zhCN, zhTW, id, ja, tr } = this.state
    return (
      <Form>
        <FormGroup>
          <Label>
            Effective time <span className="text-danger">*</span>
          </Label>
          <Row>
            <Col md={4} sm={12}>
              <DateTimePicker
                format="MMM D, YYYY HH:mm A"
                time={true}
                value={startedAt}
                placeholder="From"
                onChange={val => {
                  this.onChange('startedAt', val)
                }}
              />
            </Col>
            <div className="align-content-center">-</div>
            <Col md={4} sm={12}>
              <DateTimePicker
                format="MMM D, YYYY HH:mm A"
                time={true}
                value={endedAt}
                placeholder="To"
                onChange={val => {
                  this.onChange('endedAt', val)
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>
            Content (English) <span className="text-danger">*</span>
          </Label>
          <Input
            name="en"
            value={en}
            maxLength={300}
            onChange={e => {
              this.onChange('en', e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Content (Chinese Simplified) <span className="text-danger">*</span>
          </Label>
          <Input
            name="zhCN"
            value={zhCN}
            maxLength={300}
            onChange={e => {
              this.onChange('zhCN', e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Chinese Traditional)</Label>
          <Input
            name="zhTW"
            value={zhTW}
            maxLength={300}
            onChange={e => {
              this.onChange('zhTW', e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Japanese)</Label>
          <Input
            name="ja"
            value={ja}
            maxLength={300}
            onChange={e => {
              this.onChange('ja', e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Indonesian)</Label>
          <Input
            name="id"
            value={id}
            maxLength={300}
            onChange={e => {
              this.onChange('id', e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Malay)</Label>
          <Input
            name="ms"
            value={ms}
            maxLength={300}
            onChange={e => {
              this.onChange('ms', e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Content (Turkish)</Label>
          <Input
            name="tr"
            value={tr}
            maxLength={300}
            onChange={e => {
              this.onChange('tr', e.target.value)
            }}
          />
        </FormGroup>

        {this.state.error && (
          <Alert color="danger">{this.state.errorMessage}</Alert>
        )}
        <Mutation
          mutation={ADD_NOTIFY_MAINTAIN_INFO}
          onCompleted={() => {
            console.log('success')
            setTimeout(() => {
              history.push('/maintenance/list/1')
            }, 1700)
          }}
          onError={graphQLError => {
            if (graphQLError) {
              this.setState({
                error: true,
                errorMessage: graphQLError.message
              })
            }
          }}
        >
          {(addWebsiteOutage, { data, loading, error }) => (
            <div style={{ textAlign: 'right' }}>
              <Button
                color="info"
                onClick={() => {
                  const input = {
                    startedAt: startedAt
                      ? new Date(startedAt).toISOString()
                      : null,
                    endedAt: endedAt ? new Date(endedAt).toISOString() : null,
                    contentList: [
                      { value: en.trim() || '', languageCode: 'en' },
                      { value: zhCN.trim() || '', languageCode: 'zh-CN' },
                      { value: zhTW.trim() || '', languageCode: 'zh-TW' },
                      { value: id.trim() || '', languageCode: 'id' },
                      { value: ja.trim() || '', languageCode: 'ja' },
                      { value: ms.trim() || '', languageCode: 'ms' },
                      { value: tr.trim() || '', languageCode: 'tr' }
                    ]
                  }

                  const validDataMessage = validSubmitDate({
                    startedAt,
                    endedAt,
                    en: en.trim(),
                    zhCN: zhCN.trim()
                  })

                  if (validDataMessage) {
                    this.setState({
                      error: true,
                      errorMessage: validDataMessage
                    })
                    return
                  }

                  this.setState({
                    error: false,
                    errorMessage: ''
                  })

                  addWebsiteOutage({
                    variables: input
                  })
                }}
              >
                Add
              </Button>
            </div>
          )}
        </Mutation>
      </Form>
    )
  }
}

export default AddForm
