import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import get from 'lodash-es/get'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ME } from '../../../modules/users/schemas/queries'

/* Bootstrap */
import { Alert } from 'reactstrap'

/* Helper */
import { logout } from '../../helpers/authentication'
import { updateAbility, isAbilityRuleEmpty } from '../../helpers/abilityHelper'

/**
 * withSession - HOC that will validate access token expiration before rendering the actual component
 * @param {*} WrappedComponent
 */
const withSession = WrappedComponent => {
  return class Session extends Component {
    state = {
      redirect: false
    }
    setRedirectTimeout() {
      setTimeout(() => this.setState({ redirect: true }), 2000)
    }

    render() {
      return (
        <Query query={GET_ME}>
          {({ loading, error, data }) => {
            if (!loading) {
              if (error) {
                this.setRedirectTimeout()

                if (this.state.redirect) {
                  // user logout
                  logout()
                  return <Redirect to={'/login'} />
                } else {
                  return (
                    <Alert color="danger">
                      {get(
                        error,
                        'graphQLErrors.0.message',
                        'Something went wrong.'
                      )}
                    </Alert>
                  )
                }
              } else {
                // check if ability rules is empty and re-initialise rules
                if (isAbilityRuleEmpty()) {
                  updateAbility(
                    (data.me && data.me.role && data.me.role.roleNodes) || []
                  )
                  if (!data.me || !data.me.role) {
                    localStorage.setItem('accessToken', '')
                    return <Redirect to={'/login'} />
                  }
                }
                return <WrappedComponent {...this.props} />
              }
            }
            return null
          }}
        </Query>
      )
    }
  }
}

export default withSession
