import React from 'react'

import SearchBar from '../../../../shared/components/SearchBar'
import SectionWrapper from '../../../../shared/components/SectionWrapper'
import JoinCompanyDataListContainer from '../../containers/JoinCompanyDataListContainer'
const JoinCompanyData = () => {
  const requestStatusOptions = [
    { active: 'Active' },
    { pending: 'Pending' },
    { rejected: 'Rejected' }
  ]

  const requestTypeOptions = [{ 1: 'Create Company' }, { 2: 'Join Company' }]

  return (
    <div>
      <SearchBar
        hasJoinCompanyDataFilter
        joinCompanyRequestStatusOptions={requestStatusOptions}
        joinCompanyRequestTypeOptions={requestTypeOptions}
      />
      <SectionWrapper sectionTitle="Company Join Request Data" isMainPage>
        <JoinCompanyDataListContainer />
      </SectionWrapper>
    </div>
  )
}

export default JoinCompanyData
