import React, { useEffect, useState } from 'react'

/* Components */
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'
import CompanyListContainer from '../../containers/CompanyListContainer'
import { getNewConfigList } from '../../../../shared/helpers/newConfigList'

/**
 * Companies - main Companies list view
 *
 */

const Companies = () => {
  const [countryData, setCountryData] = useState(null)
  const [registeredCountries, setRegisteredCountries] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getNewConfigList()
      setCountryData(data.country_lists)
      setRegisteredCountries(
        data.country_lists.filter(
          country => country.is_bossjob_supported === true
        )
      )
    }
    fetchData()
  }, [])

  const statusOptions = [
    { all: 'All' },
    { companyPendingVerification: 'Company Pending Verification' }
  ]

  const authStatusOptions = [
    { all: 'All' },
    { verified: 'Verified' },
    { pending: 'Pending' },
    { change_required: 'Change Required' },
    { rejected: 'Rejected' },
    { no_document_uploaded: 'Document not Uploaded' }
  ]

  const activeStatusOptions = [
    { all: 'All' },
    { active: 'Active' },
    { inactive: 'Inactive' },
    { deleted: 'Deleted' }
  ]
  const salesStatusOptions = [{ all: 'All' }, { activated: 'activated' }]

  return (
    <div>
      <SearchBar
        hasAuthStatus
        hasActiveStatus
        hasCountryFilter
        hasSalesPersonFilter
        hasRegisteredCountryFilter
        hasSalesStatusFilter
        countryOptions={countryData}
        registeredCountryOptions={registeredCountries}
        statusOptions={statusOptions}
        authStatusOptions={authStatusOptions}
        activeStatusOptions={activeStatusOptions}
        salesStatusOptions={salesStatusOptions}
      />

      <SectionWrapper
        sectionTitle="Companies"
        isShowReferralLink={true}
        isMainPage
      >
        <CompanyListContainer />
      </SectionWrapper>
    </div>
  )
}

export default Companies
