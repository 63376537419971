import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'

const EventActivityDataList = ({ data }) => {
  const missionNames = {
    1: 'Gift Box',
    2: 'Upload Profile Picture',
    3: 'Complete Company Information',
    4: 'Initiate a chat with talent',
    5: 'Reply a greeting',
    6: 'KPI Competition',
    7: 'Daily gift basket',
    8: 'Invite new user to join Bossjob',
    9: 'Invite new company to join Bossjob'
  }
  return (
    <>
      <Table bordered>
        <thead>
          <tr>
            <th>Mission ID</th>
            <th>Task</th>
            <th>Total Participants</th>
            <th>Total Earned Points</th>
            <th>Point Redemption Limit</th>
          </tr>
        </thead>
        <tbody>
          {data &&
          data.eventActivityDataRequest &&
          data.eventActivityDataRequest.data
            ? data.eventActivityDataRequest.data.map(mission => (
              <tr key={mission.missionId}>
                <td>{mission.missionId}</td>
                <td>{missionNames[mission.missionId]}</td>
                <td>{mission.participants}</td>
                <td>{mission.points}</td>
                <td>{mission.redeemLimit}</td>
              </tr>
            ))
            : null}
        </tbody>
      </Table>
      <Table bordered>
        <thead>
          <tr>
            <th>Overall Budget Statistic</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Points Earned</td>
            <td>{data.eventActivityDataRequest.totalPointsEarned}</td>
          </tr>
          <tr>
            <td>Total Points Redeemed</td>
            <td>{data.eventActivityDataRequest.totalPointsRedeemed}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

EventActivityDataList.propTypes = {
  data: PropTypes.object.isRequired
}

export default EventActivityDataList
