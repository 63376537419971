import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_NOTIFY_MAINTAIN_INFO } from '../../schemas/queries'
import { get } from 'lodash-es'

/* Components */
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import EditForm from './EditForm'

const MaintenanceEdit = ({ match }) => (
  <Query
    query={GET_NOTIFY_MAINTAIN_INFO}
    variables={{ submitUuid: match.params.id }}
  >
    {({ loading, data, client }) => {
      if (loading) {
        return <Loading />
      } else {
        const details = get(data, 'getWebsiteOutage.data', {})
        return (
          <SectionWrapper sectionTitle="Maintenance Edit">
            <EditForm data={details} />
          </SectionWrapper>
        )
      }
    }}
  </Query>
)

MaintenanceEdit.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default withRouter(MaintenanceEdit)
