exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formWrapper__TXvn5 {\n  border-bottom: 1px solid #d8d8d8;\n  margin-bottom: 30px;\n  padding-bottom: 20px;\n}\n.formWrapper__TXvn5:last-child {\n  margin-bottom: 0;\n  border-bottom: none;\n}\n\n.skillItem__yquHk {\n  position: relative;\n  margin-right: 15px;\n  display: inline-block;\n}\n.skillItem__yquHk .delete__bhbYZ {\n  background: #d8d8d8;\n  width: 20px;\n  padding: 0 5px 2px 5px;\n  border-radius: 50%;\n  margin-left: 5px;\n  cursor: pointer;\n}", "", {"version":3,"sources":["/app/src/modules/bossjob_users/views/BossJobUserDetail/UserProfile/WorkExperiences/WorkExperienceForm/WorkExperienceForm.module.scss"],"names":[],"mappings":"AAAA;EACE,iCAAgC;EAChC,oBAAmB;EACnB,qBAAoB;CACtB;AACA;EACE,iBAAgB;EAChB,oBAAmB;CACrB;;AAEA;EACE,mBAAkB;EAClB,mBAAkB;EAClB,sBAAqB;CACvB;AACA;EACE,oBAAmB;EACnB,YAAW;EACX,uBAAsB;EACtB,mBAAkB;EAClB,iBAAgB;EAChB,gBAAe;CACjB","file":"WorkExperienceForm.module.scss","sourcesContent":[".formWrapper {\n  border-bottom: 1px solid #d8d8d8;\n  margin-bottom: 30px;\n  padding-bottom: 20px;\n}\n.formWrapper:last-child {\n  margin-bottom: 0;\n  border-bottom: none;\n}\n\n.skillItem {\n  position: relative;\n  margin-right: 15px;\n  display: inline-block;\n}\n.skillItem .delete {\n  background: #d8d8d8;\n  width: 20px;\n  padding: 0 5px 2px 5px;\n  border-radius: 50%;\n  margin-left: 5px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formWrapper": "formWrapper__TXvn5",
	"skillItem": "skillItem__yquHk",
	"delete": "delete__bhbYZ"
};