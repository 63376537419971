import { GET_LOCAL_COMPANY_DETAIL } from '../schemas/queries'

export default {
  updateCompany: (_, { key, value }, { cache }) => {
    const { companyDetail } = cache.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let newCompanyDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        companyDetail: {
          ...companyDetail,
          ...newCompanyDetail
        }
      }
    })

    return null
  },
  updateLocalCompanyCulture: (_, { cultureId, isChecked }, { cache }) => {
    const { companyDetail } = cache.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let cultures = companyDetail.cultures ? companyDetail.cultures : []

    // append culture to array if isChecked = true and vice versa
    if (isChecked) {
      // if there are no duplicated cultureId, append to existing array
      if (
        !cultures.filter(culture => culture.cultureId === Number(cultureId))
          .length > 0
      ) {
        const culturesObj = Object.assign(
          {},
          { cultureId: Number(cultureId), __typename: 'CompanyCulturesType' }
        )
        cultures.push(culturesObj)
      }
    } else {
      cultures = cultures.filter(
        culture => culture.cultureId !== Number(cultureId)
      )
    }

    cache.writeData({
      data: {
        companyDetail: {
          ...companyDetail,
          cultures: cultures
        }
      }
    })
    return null
  },
  updateLocalCompanyBenefit: (_, { benefitId, isChecked }, { cache }) => {
    const { companyDetail } = cache.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let benefits = companyDetail.benefits ? companyDetail.benefits : []

    // append benefit to array if isChecked = true and vice versa
    if (isChecked) {
      // if there are no duplicated benefitId, append to existing array
      if (
        !benefits.filter(benefit => benefit.benefitId === Number(benefitId))
          .length > 0
      ) {
        const benefitsObj = Object.assign(
          {},
          { benefitId: Number(benefitId), __typename: 'CompanyBenefitsType' }
        )
        benefits.push(benefitsObj)
      }
    } else {
      benefits = benefits.filter(
        benefit => benefit.benefitId !== Number(benefitId)
      )
    }

    cache.writeData({
      data: {
        companyDetail: {
          ...companyDetail,
          benefits: benefits
        }
      }
    })
    return null
  }
}
