import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Input, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

/**
 * BossJobUserList - Bossjob user list table
 *
 */
class BossJobUserList extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    if (e.target.checked) {
      this.props.appendSelectedUser(e.target.value)
    } else {
      this.props.removeSelectedUser(e.target.value)
    }
  }

  render() {
    const { userList, currentPage, totalUsers, totalPages } = this.props

    return (
      <React.Fragment>
        <Table bordered style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th style={{ width: '40px' }} />
              <th style={{ width: '75px' }}>User ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th style={{ width: '230px' }}>Email</th>
              <th>Role</th>
              <th>Account Status</th>
              <th>Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userList.map(user => (
              <tr key={user.id}>
                <td>
                  <Input
                    addon
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    onChange={this.handleChange}
                    value={user.id}
                  />
                </td>
                <td>
                  <a href={`/bossjob/user/${user.id}`}>{user.id}</a>
                </td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.activeKey === 1 ? 'Jobseeker' : 'Recruiter'}</td>
                <td>{user.statusKey}</td>
                <td>{formatDate(user.createdAt, 'DD/MM/YYYY HH:mm:Ss')}</td>
                <td>
                  <div style={{ textAlign: 'center' }}>
                    <a href={`/bossjob/user/${user.id}`}>
                      <Button color="success">
                        <img src={Zoom} alt="Zoom" width="16" height="16" />
                      </Button>
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={Number(currentPage)}
          totalCount={totalUsers}
          totalPages={totalPages}
          pageRange={4}
        />
      </React.Fragment>
    )
  }
}

BossJobUserList.propTypes = {
  /* array of user list */
  userList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalUsers: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  appendSelectedUser: PropTypes.func.isRequired,

  removeSelectedUser: PropTypes.func.isRequired
}

export default BossJobUserList
