exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".wrapper__3TWLy {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n}", "", {"version":3,"sources":["/app/src/modules/bossjob_users/views/BossJobUserDetail/RecruiterInformation/JobPostCredits/JobPostCredits.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,wBAA2B;MAA3B,qBAA2B;UAA3B,4BAA2B;CAC7B","file":"JobPostCredits.module.scss","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "wrapper__3TWLy"
};