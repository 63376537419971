import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Bootstrap */
import { Button } from 'reactstrap'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_LOCAL_BOSSJOB_USER_DETAIL,
  GET_SUBSCRIPTIONS_OF_RECRUITER
} from '../../../schemas/queries'

/* Components */
import SubscriptionHistoryList from '../../../views/BossJobUserDetail/RecruiterInformation/SubscriptionHistory/SubscriptionHistoryList'
import Loading from 'shared/components/Loading'

/* Vendors */
// import queryString from 'query-string'

/**
 * SubscriptionHistoryListContainer - container that contains user's subscriptions
 * and entry point to add offline subscription plan to user
 *
 * @returns {Component}
 *
 */
class SubscriptionHistoryListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    updateLocalCreditModal: PropTypes.func.isRequred
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { match } = this.props
    // const { search, fromDate, toDate, type, status } = queryString.parse(
    //   location.search
    // )

    return (
      <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
        {({ data: { bossjobUserDetail } }) => (
          <Query
            query={GET_SUBSCRIPTIONS_OF_RECRUITER}
            variables={{
              userId: bossjobUserDetail.id
            }}
          >
            {({ loading, data: { subscriptionList } }) => {
              if (loading) {
                return <Loading />
              } else {
                return (
                  <>
                    <div
                      style={
                        location.pathname.includes('subscription')
                          ? { display: 'flex', justifyContent: 'flex-end' }
                          : { display: 'none' }
                      }
                    >
                      <a href={`/bossjob/subscription/new/${match.params.id}`}>
                        <Button color="info">Add</Button>
                      </a>
                    </div>
                    <br />
                    <SubscriptionHistoryList
                      historyList={subscriptionList.data.subscriptions}
                      currentPage={Number(this.state.page)}
                      totalNum={subscriptionList.data.totalNum}
                      totalPages={subscriptionList.data.totalPages}
                    />
                  </>
                )
              }
            }}
          </Query>
        )}
      </Query>
    )
  }
}

SubscriptionHistoryListContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(SubscriptionHistoryListContainer)
