import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, compose } from 'react-apollo'
import {
  ADD_JOB_PAGE_LEFT_SIDE_BANNER,
  UPDATE_JOB_PAGE_LEFT_SIDE_BANNER,
  REMOVE_JOB_PAGE_LEFT_SIDE_BANNER
} from '../../../schemas/mutations'
import { Button } from 'reactstrap'

import LeftSideBannerForm from './components/LeftSideBannerForm'
import styles from './LeftSideBannerContainer.module.scss'

const LeftSideBannerContainer = ({
  sideBanners,
  sideBanners: { data },
  addSideBanner,
  updateSideBanner,
  removeSideBanner
}) => {
  const [formAmount, setFormAmount] =
    useState(sideBanners.data && sideBanners.data.length) || 0

  const addForm = () => {
    setFormAmount(prevState => {
      return prevState + 1
    })
  }

  const handleRemoveSideBanner = async bannerId => {
    try {
      await removeSideBanner({
        variables: {
          bannerId
        }
      })

      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  const handleAddSideBanner = async e => {
    console.log('e', e)
    try {
      e.preventDefault()
      const formData = new FormData(e.target)

      const payload = {
        ...(formData.get('bannerAlt') && {
          alt: formData.get('bannerAlt')
        }),
        ...(formData.get('bannerImage').size && {
          image: formData.get('bannerImage')
        }),
        ...(formData.get('bannerCtaUrl') && {
          ctaUrl: formData.get('bannerCtaUrl')
        })
      }

      await addSideBanner({
        variables: {
          sideBannerInput: payload
        }
      })

      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  const handleUpdateSideBanner = async e => {
    try {
      e.preventDefault()
      const formData = new FormData(e.target)

      let payload = {
        ...(formData.get('bannerAlt') && {
          alt: formData.get('bannerAlt')
        }),
        ...(formData.get('bannerImage').size && {
          image: formData.get('bannerImage')
        }),
        ...(formData.get('bannerCtaUrl') && {
          ctaUrl: formData.get('bannerCtaUrl')
        })
      }
      await updateSideBanner({
        variables: {
          bannerId: formData.get('id'),
          sideBannerInput: payload
        }
      })
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <section>
      <div className={styles.formWrapper}>
        {[...Array(formAmount)].map((nothing, index) => {
          // eslint-disable-line
          return (
            <LeftSideBannerForm
              key={index}
              index={index}
              sideBanner={data[index] || {}}
              addSideBanner={handleAddSideBanner}
              updateSideBanner={handleUpdateSideBanner}
              removeSideBanner={handleRemoveSideBanner}
            />
          )
        })}
      </div>
      <Button
        type="primary"
        color="success"
        className={styles.addFormButton}
        onClick={addForm}
      >
        Add Side Banner
      </Button>
    </section>
  )
}

LeftSideBannerContainer.propTypes = {
  sideBanners: PropTypes.object.isRequired,
  addSideBanner: PropTypes.func.isRequired,
  updateSideBanner: PropTypes.func.isRequired,
  removeSideBanner: PropTypes.func.isRequired
}

export default compose(
  graphql(ADD_JOB_PAGE_LEFT_SIDE_BANNER, { name: 'addSideBanner' }),
  graphql(UPDATE_JOB_PAGE_LEFT_SIDE_BANNER, { name: 'updateSideBanner' }),
  graphql(REMOVE_JOB_PAGE_LEFT_SIDE_BANNER, { name: 'removeSideBanner' })
)(LeftSideBannerContainer)
