import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_JOIN_COMPANY_DATA } from '../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import JoinCompanyDataList from '../../views/JoinCompanyData/JoinCompanyDataList/JoinCompanyDataList'

/**
 * CompanyListContainer - container component to query Bossjob company list
 * and return CompanyList component when is done
 *
 * @returns {Component}
 *
 */
class JoinCompanyDataListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const formatDate = date => date.toISOString().replace(/.\d+Z$/, 'Z')

    const currentDate = new Date()
    const pastDate = new Date(currentDate.getTime() - 28 * 24 * 60 * 60 * 1000)

    const sgTimezoneOffset = 8 * 60 // Singapore timezone offset in minutes (UTC+8)
    const singaporeCurrentDate = new Date(
      currentDate.getTime() + sgTimezoneOffset * 60000
    )
    const singaporePastDate = new Date(
      pastDate.getTime() + sgTimezoneOffset * 60000
    )

    const formattedCurrentDate = formatDate(singaporeCurrentDate)
    const formattedPastDate = formatDate(singaporePastDate)

    const {
      fromDate,
      toDate,
      requestStatus,
      requestTypeId
    } = queryString.parse(location.search)

    let status
    if (requestStatus == '' || requestStatus == null) {
      status = 'pending'
    } else if (requestStatus == 'all') {
      status = ''
    } else {
      status = requestStatus
    }
    let variables = {
      requestTypeId: requestTypeId,
      statusKey: status,
      fromDate: fromDate ? fromDate : formattedPastDate,
      toDate: toDate ? toDate : formattedCurrentDate,
      page: this.state.page,
      size: 10
    }

    const exportPayload = {
      statusKey: status,
      fromDate: fromDate ? fromDate : formattedPastDate,
      toDate: toDate ? toDate : formattedCurrentDate
    }

    return (
      <Query query={GET_JOIN_COMPANY_DATA} variables={variables}>
        {({ loading, data }) => {
          if (loading) {
            return <Loading />
          } else if (data && data.getJoinCompanyData) {
            return (
              <JoinCompanyDataList
                joinRequestList={data.getJoinCompanyData.data.joinCompanyList}
                currentPage={Number(data.getJoinCompanyData.data.currentPage)}
                totalRequests={Number(data.getJoinCompanyData.data.totalCount)}
                totalPages={Number(data.getJoinCompanyData.data.totalPages)}
                exportPayload={exportPayload}
                fromDate={variables.fromDate}
                toDate={variables.toDate}
              />
            )
          }
        }}
      </Query>
    )
  }
}

export default withRouter(JoinCompanyDataListContainer)
