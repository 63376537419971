/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button } from 'reactstrap'

/* Apollo */
import { withApollo, compose, graphql } from 'react-apollo'
import { ADD_OFFLINE_SUBSCRIPTION } from '../../../../../schemas/mutations'

/* Permission */
import { Can } from '../../../../../../../shared/configs/abilityContextConfig'

/* Components */
import Loading from '../../../../../../../shared/components/Loading'

/**
 * SubmitButton - Presentational Component to Update InvoiceDetail
 *
 * @returns {Component}
 */
class SubmitButton extends Component {
  static propTypes = {
    subscriptionDetailForNewForm: PropTypes.object,
    addOfflineSubscription: PropTypes.func.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    renderEmailError: PropTypes.func
  }

  state = {
    error: '',
    loading: false
  }

  handleClick = async () => {
    const { subscriptionDetail, addOfflineSubscription, userId } = this.props
    const { subscriptionDetailForNewForm } = subscriptionDetail

    this.setState({ loading: true })

    // to check if empty field exist before submitting mutation
    const checkIfEmptyFieldExist = object => {
      let isEmpty = ''
      // let abc = false
      for (let value in object) {
        // if field is not mandatory, skip checking (description & remark not mandatory)
        if (value === 'description' || value === 'remark') {
        } else {
          if (object[value] == null) {
            isEmpty = true
          }
        }
      }
      return isEmpty
    }

    let isEmpty = checkIfEmptyFieldExist(subscriptionDetailForNewForm)

    // if fields have empty input, display error, else proceed to submit mutation
    if (isEmpty) {
      this.setState({
        error: 'Please ensure all fields marked as (*) are complete',
        loading: false
      })
    } else {
      try {
        const regex = /\S+@\S+\.\S+/g
        let emailValid = regex.test(subscriptionDetailForNewForm.billerEmail)

        if (emailValid) {
          this.setState({ error: '' })
          let response = await addOfflineSubscription({
            variables: {
              userId,
              subscriptionInput: {
                name: subscriptionDetailForNewForm.name,
                subscriptionPlanId:
                  subscriptionDetailForNewForm.subscriptionPlanId,
                description: subscriptionDetailForNewForm.description,
                paymentDuration: Number(
                  subscriptionDetailForNewForm.paymentDuration
                ),
                noOfInstallment: Number(
                  subscriptionDetailForNewForm.noOfInstallment
                ),
                totalAmount: Number(subscriptionDetailForNewForm.totalAmount),
                jobPost: Number(subscriptionDetailForNewForm.jobPost),
                unlockTalentCredit: Number(
                  subscriptionDetailForNewForm.unlockTalentCredit
                ),
                rhTalentCredit: Number(
                  subscriptionDetailForNewForm.rhTalentCredit
                ),
                featureJobCredit: Number(
                  subscriptionDetailForNewForm.featureJobCredit
                ),
                urgentJobCredit: Number(
                  subscriptionDetailForNewForm.urgentJobCredit
                ),
                bosspoint: Number(subscriptionDetailForNewForm.bosspoint),
                // to remove this when backend have removed
                isServiceGuaranteed: true,
                isOnlineSupported: true,
                //
                remark: subscriptionDetailForNewForm.remark,
                salesPersonEmail: subscriptionDetailForNewForm.salesPersonEmail,
                startedAt: subscriptionDetailForNewForm.startedAt,
                creditTerm: Number(subscriptionDetailForNewForm.creditTerm),
                taxType: subscriptionDetailForNewForm.taxType,
                taxRate: Number(subscriptionDetailForNewForm.taxRate),
                bankAccountId: Number(
                  subscriptionDetailForNewForm.bankAccountId
                ),
                paymentMethodId: Number(
                  subscriptionDetailForNewForm.paymentMethodId
                ),
                billerEmail: subscriptionDetailForNewForm.billerEmail,
                billerFirstName: subscriptionDetailForNewForm.billerFirstName,
                billerLastName: subscriptionDetailForNewForm.billerLastName,
                billerAddress: subscriptionDetailForNewForm.billerAddress,
                billerCompany: subscriptionDetailForNewForm.billerCompany,
                billerCity: subscriptionDetailForNewForm.billerCity,
                billerState: subscriptionDetailForNewForm.billerState,
                billerZipcode: subscriptionDetailForNewForm.billerZipcode,
                billerCountry: subscriptionDetailForNewForm.billerCountry
              }
            }
          })

          if (response) {
            let status = response.data.addOfflineSubscription
            if (status.ok) {
              window.location.href = `/bossjob/user/${userId}/recruiter/subscription/1`
            } else {
              this.setState({
                loading: false,
                error: status.message
              })
            }
          }
        } else {
          this.setState({
            loading: false,
            error: 'Please ensure email is valid'
          })
        }
      } catch (err) {
        this.setState({ loading: false })
      }
    }
  }

  render() {
    const { error, loading } = this.state
    const { isDisabled } = this.props
    return (
      <Can I="edit" a="invoice">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {error ? (
            <span style={{ color: 'red', marginBottom: '10px' }}>{error}</span>
          ) : null}
          {loading ? (
            <Loading />
          ) : (
            <Button
              style={{ marginBottom: '15px' }}
              color="info"
              onClick={this.handleClick}
              disabled={isDisabled}
            >
              Submit
            </Button>
          )}
        </div>
      </Can>
    )
  }
}

export default compose(
  withApollo,
  graphql(ADD_OFFLINE_SUBSCRIPTION, { name: 'addOfflineSubscription' })
)(SubmitButton)
