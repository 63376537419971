const statusMapper = statusId => {
  return (
    (statusId === 1 && 'Active') ||
    (statusId === 2 && 'Close') ||
    (statusId === 3 && 'Archive') ||
    'Invalid Status Id'
  )
}

export default statusMapper
