import React from 'react'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

import JoinCompanyData from './JoinCompanyDataPage'
import Layout from 'shared/components/Layout'
import NotAuthorize from 'shared/components/NotAuthorize'

export default () => (
  <Layout>
    <Can I="edit" a="bossjob_user" passThrough>
      {can => <>{can ? <JoinCompanyData /> : <NotAuthorize />}</>}
    </Can>
  </Layout>
)
