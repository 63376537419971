import React from 'react'
import { Redirect } from 'react-router-dom'

import LoginPage from './Login'

const Login = () => {
  const accessToken = localStorage.getItem('accessToken')

  if (accessToken) {
    return <Redirect to="/" />
  } else {
    return <LoginPage />
  }
}

export default Login
