import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'
import { formatDate } from 'shared/helpers/datetime'

/**
 * RequestDemoList - Bosshunt request list table
 *
 */
const RequestDemoList = ({ demoRequestList }) => {
  const { demoRequests } = demoRequestList

  return (
    <React.Fragment>
      <Table bordered>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Name</th>
            <th>Company Name</th>
            <th>Contact Number</th>
            <th>Source</th>
            <th>Message</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {demoRequests &&
            demoRequests.map((demoRequest, index) => {
              return (
                <tr key={`demoRequest_${index}`}>
                  <td>{demoRequest.id}</td>
                  <td>{demoRequest.email}</td>
                  <td>
                    {demoRequest.firstName} {demoRequest.lastName}
                  </td>
                  <td>{demoRequest.companyName}</td>
                  <td>{demoRequest.contactNum}</td>
                  <td>{demoRequest.source}</td>
                  <td>{demoRequest.message}</td>
                  <td>{formatDate(demoRequest.createdAt, 'D/M/YY h:mm a')}</td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(demoRequestList.currentPage)}
        totalCount={demoRequestList.totalNum}
        totalPages={demoRequestList.totalPages}
        pageRange={4}
      />
    </React.Fragment>
  )
}

RequestDemoList.propTypes = {
  demoRequestList: PropTypes.array.isRequired
}

export default RequestDemoList
