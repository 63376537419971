import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_SUBSCRIPTION_LIST } from '../../schemas/queries'

/* Vendors */
import queryString from 'query-string'

/* Components */
import SubscriptionList from '../../views/Subscription/SubscriptionList/SubscriptionList'
import Loading from 'shared/components/Loading'

// /* Vendors */
// import queryString from 'query-string'

/**
 * SubscriptionListContainer
 *
 * @returns {Component}
 */
class SubscriptionListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { page } = this.state

    const {
      status,
      search,
      minNoOfInstallment,
      subscriptionPlanId
    } = queryString.parse(location.search)

    let isActive = null

    switch (status) {
    case 'active':
      isActive = true
      break
    case 'inactive':
      isActive = false
      break
    default:
      break
    }

    let variables = {
      page: page,
      isActive: isActive,
      email: search,
      minNoOfInstallment: minNoOfInstallment,
      subscriptionPlanId: subscriptionPlanId
    }

    return (
      <Query query={GET_SUBSCRIPTION_LIST} variables={variables}>
        {({ loading, data: { subscriptionList }, refetch }) => {
          if (loading) return <Loading />
          return (
            <SubscriptionList
              subscriptionList={subscriptionList.data.subscriptions}
              totalNum={subscriptionList.data.totalNum}
              currentPage={page}
              totalPages={subscriptionList.data.totalPages}
              refetch={refetch}
            />
          )
        }}
      </Query>
    )
  }
}

export default withRouter(SubscriptionListContainer)
