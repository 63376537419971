import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* React Apollo */
import { Query } from 'react-apollo'

/* Bootstrap */
import { GET_COMPANY_WORKING_ADDRESS_LIST } from '../../../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import CompanyWorkingAddressForm from './CompanyWorkingAddressForm/CompanyWorkingAddressForm'

/* Assets */
import styles from 'shared/assets/scss/main.module.scss'

const CompanyWorkingAddress = ({ companyId, canEdit }) => {
  const defaultWorkingAddress = {
    companyId: companyId
  }
  const [isAddingAddress, setIsAddingAddress] = useState(false)
  const [isSuccessAddingAddress, setIsSuccessAddingAddress] = useState(false)

  const cancelAdding = () => setIsAddingAddress(false)

  const successAdding = () => {
    setIsSuccessAddingAddress(true)
    setIsAddingAddress(false)
  }

  return (
    <>
      {!isAddingAddress && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: '15px',
            borderBottom: '1px solid #d8d8d8'
          }}
        >
          <div
            className={styles.addButton}
            style={{ marginBottom: '10px' }}
            onClick={() => setIsAddingAddress(true)}
            disabled={!canEdit}
          >
            +
          </div>
        </div>
      )}
      {isAddingAddress && (
        <CompanyWorkingAddressForm
          workingAddress={defaultWorkingAddress}
          key="workingAddress-new"
          cancelAdding={cancelAdding}
          successAdding={successAdding}
          isNew
          canEdit={canEdit}
        />
      )}
      <Query
        query={GET_COMPANY_WORKING_ADDRESS_LIST}
        variables={{ companyId: companyId }}
        fetchPolicy="network-only"
      >
        {({ loading, data, error, refetch }) => {
          if (error) {
            console.log(error)
          }
          if (loading) {
            return <Loading />
          }

          if (isSuccessAddingAddress) {
            refetch()
            setIsSuccessAddingAddress(false)
          }

          if (data && data.getCompanyWorkingAddresses.data) {
            const workingAddresses = data.getCompanyWorkingAddresses.data

            return workingAddresses.map(workingAddress => (
              <CompanyWorkingAddressForm
                workingAddress={workingAddress}
                key={`workingAddress-${workingAddress.id}`}
                refetch={refetch}
                canEdit={canEdit}
              />
            ))
          }
        }}
      </Query>
    </>
  )
}

CompanyWorkingAddress.propTypes = {
  companyId: PropTypes.number.isRequired,
  canEdit: PropTypes.bool
}

export default CompanyWorkingAddress
