import React from 'react'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

import BossHuntUsers from './BossHuntUsers'
import Layout from 'shared/components/Layout'
import NotAuthorize from 'shared/components/NotAuthorize'

export default () => (
  <Layout>
    <Can I="view" a="bossjob_user" passThrough>
      {can => (can ? <BossHuntUsers /> : <NotAuthorize />)}
    </Can>
  </Layout>
)
