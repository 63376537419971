import React, { Component } from 'react'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'
import { LOGIN_MUTATION } from '../../../schemas/mutations'

/* Helper */
import { setAccessToken } from 'shared/helpers/authentication'

/* Bootstrap */
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  Alert
} from 'reactstrap'

/* Vendors */
import FontAwesome from 'react-fontawesome'

/* Assets */
import styles from './LoginForm.module.scss'

/**
 * LoginForm - login form using graphql mutation to login and show alert when there's error
 *
 * @returns {Component}
 */
class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    error: false,
    errorMessage: ''
  }

  constructor(props) {
    super(props)

    this._login = this._login.bind(this)
  }

  /**
   *
   * @function _login - login function to call setAccessToken when success
   * and set error message when failed
   * @param {*} apolloClient
   */
  _login(apolloClient) {
    if (this.state.email !== '' && this.state.password !== '') {
      // make sure that the fields are not empty before calling graphql
      apolloClient
        .mutate({
          mutation: LOGIN_MUTATION,
          variables: { email: this.state.email, password: this.state.password }
        })
        .then(response => {
          const data = response.data
          /* set received access token to local storage */
          if (data.loginAdminUser && data.loginAdminUser.ok) {
            setAccessToken(data.loginAdminUser.accessToken)
          }
        })
        .catch(error => {
          /* set error message */
          console.log(error)
          this.setState({
            error: true,
            errorMessage:
              (error.graphQLErrors &&
                error.graphQLErrors.length > 0 &&
                error.graphQLErrors[0].message) ||
              'An unknown error occurred'
          })
        })
    } else {
      /* set error message for empty fields */
      this.setState({
        error: true,
        errorMessage: 'Email and Password cannot be empty'
      })
    }
  }

  /**
   *
   * @function resetError - to reset error state to default
   */
  resetError() {
    this.setState({
      error: false,
      errorMessage: ''
    })
  }

  render() {
    return (
      <Form>
        {this.state.error && (
          <Alert color="danger">{this.state.errorMessage}</Alert>
        )}
        <FormGroup>
          <InputGroup className={styles.inputBoxWrapper}>
            <InputGroupAddon addonType="prepend">@</InputGroupAddon>
            <Input
              placeholder="email"
              onChange={e => {
                this.setState({ email: e.target.value })
                if (this.state.error) {
                  this.resetError()
                }
              }}
            />
          </InputGroup>
          <InputGroup className={styles.inputBoxWrapper}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FontAwesome name="lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="password"
              placeholder="password"
              onChange={e => {
                this.setState({ password: e.target.value })
                if (this.state.error) {
                  this.resetError()
                }
              }}
            />
          </InputGroup>
          <div className={styles.buttonsWrapper}>
            <a href="/" className={styles.forgotText}>
              Forgot Password ?
            </a>
            <ApolloConsumer>
              {client => (
                <Button
                  color="info"
                  size="sm"
                  className={styles.loginButton}
                  onClick={() => this._login(client)}
                >
                  Login
                </Button>
              )}
            </ApolloConsumer>
          </div>
        </FormGroup>
      </Form>
    )
  }
}

export default LoginForm
