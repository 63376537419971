import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Vendors */
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

/* Helpers */
import { convertToISOFormat } from 'shared/helpers/datetime'
import { getCountries } from 'shared/helpers/locations'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Configs */
import noticePeriodOption from 'shared/helpers/noticePeriod'
import { getNewConfigList } from '../../../../../../shared/helpers/newConfigList'

/**
 * InformationForm - form component for Resume page - Information section
 *
 * @returns {Component}
 */
class InformationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      location: props.userData.locationKey ? props.userData.locationKey : '',
      locations: [],
      country: props.userData.countryKey ? props.userData.countryKey : '',
      birthdate: props.userData.birthdate,
      description: props.userData.description ? props.userData.description : '',
      noticePeriodId: props.userData.noticePeriodId
        ? props.userData.noticePeriodId
        : '',
      workingSince: props.userData.workingSince
        ? props.userData.workingSince
        : null
    }
    this.editUser = this.editUser.bind(this)
  }

  componentDidMount() {
    const fetchData = async () => {
      const data = await getNewConfigList()
      const locations = data.country_lists.flatMap(({ region_lists, key }) =>
        region_lists.flatMap(({ location_lists, display_name }) =>
          location_lists.map(location => ({
            ...location,
            region: display_name,
            countryKey: key
          }))
        )
      )
      return locations
    }

    fetchData().then(locations => {
      this.setState(prevState => ({ ...prevState, locations: locations }))
    })
  }

  /**
   *
   * @function editUser - edit user function to consolidate the payload from component state
   * before passing through GraphQL mutation
   *
   */
  editUser() {
    const formPayload = {
      userId: this.props.userData.id,
      payload: {
        birthdate: convertToISOFormat(
          new Date(this.state.birthdate)
        ).toString(),
        locationKey: this.state.location,
        countryKey: this.state.country,
        description: this.state.description,
        noticePeriodId: this.state.noticePeriodId,
        workingSince: convertToISOFormat(
          new Date(this.state.workingSince)
        ).toString()
      }
    }
    console.log(formPayload)

    this.props.updateUser(formPayload)
  }

  render() {
    const {
      location,
      locations,
      country,
      birthdate,
      description,
      noticePeriodId,
      workingSince
    } = this.state
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Location
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.userData.statusKey === 'inactive'}
                  type="select"
                  name="location"
                  value={location}
                  onChange={e => this.setState({ location: e.target.value })}
                >
                  <option value={''}>None</option>
                  {locations.map((location, index) => {
                    if (
                      location.countryKey == country ||
                      location.value == 'Overseas'
                    ) {
                      return (
                        <option key={`location-${index}`} value={location.key}>
                          {location.value} ({location.region})
                        </option>
                      )
                    }
                    return null
                  })}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Country
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="select"
                  name="country"
                  value={country}
                  onChange={e => this.setState({ country: e.target.value })}
                  disabled={
                    location !== 'overseas' ||
                    this.props.userData.statusKey === 'inactive'
                  }
                >
                  <option key="" value="" disabled={country}>
                    None Selected
                  </option>
                  {getCountries().map((country, index) => (
                    <option key={`country-${index}`} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Date of birth
              </Label>
              <Col md={9} sm={12}>
                <DateTimePicker
                  disabled={this.props.userData.statusKey === 'inactive'}
                  format="YYYY-MM-DD"
                  time={false}
                  value={new Date(birthdate)}
                  defaultValue={new Date()}
                  onChange={newDate => this.setState({ birthdate: newDate })}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Notice Period
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="select"
                  name="noticePeriod"
                  value={noticePeriodId ? noticePeriodId : ''}
                  onChange={e => {
                    this.setState({ noticePeriodId: e.target.value })
                  }}
                >
                  <option value="" disabled={noticePeriodId}>
                    None Selected
                  </option>
                  {noticePeriodOption.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.value}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Working Since
              </Label>
              <Col md={9} sm={12}>
                <DateTimePicker
                  disabled={this.props.userData.statusKey === 'inactive'}
                  format="YYYY-MM-DD"
                  time={false}
                  value={new Date(workingSince)}
                  defaultValue={new Date()}
                  onChange={newDate => this.setState({ workingSince: newDate })}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup row>
              <Label md={2} sm={12}>
                Description
              </Label>
              <Col md={10} sm={12}>
                <Input
                  disabled={this.props.userData.statusKey === 'inactive'}
                  type="textarea"
                  value={description}
                  onChange={e => this.setState({ description: e.target.value })}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {this.props.userData.statusKey !== 'inactive' ? (
          <Can I="edit" a="bossjob_user">
            <div style={{ textAlign: 'right' }}>
              <Button
                color="info"
                onClick={this.editUser}
                disabled={this.props.isUpdating}
              >
                Save
              </Button>
            </div>
          </Can>
        ) : (
          <div style={{ textAlign: 'right', color: 'red' }}>
            This user is inactive
          </div>
        )}
      </Form>
    )
  }
}

InformationForm.propTypes = {
  updateUser: PropTypes.func.isRequired,
  userData: PropTypes.object,
  isUpdating: PropTypes.bool
}

export default InformationForm
