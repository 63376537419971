import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import {
  UPDATE_LOCAL_BOSSHUNT_USER,
  UPDATE_LOCAL_BOSSHUNT_USER_FOR_NEW_FORM
} from '../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import CroppingImageTool from 'shared/components/CroppingImageTool'
import AccountSettingForm from '../../../views/BossHuntUserDetail/AccountSetting/AccountSettingForm'
import SectionWrapper from 'shared/components/SectionWrapper'
import { GET_LOCAL_BOSSHUNT_USER_DETAIL_FOR_NEW_FORM } from '../../../schemas/queries'

/**
 * AccountSettingFormContainer - container component to mutate Bossjob user account setting
 * and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class AccountSettingFormContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      file: null,
      isCroppingVisible: false,
      imagePreviewUrl: '',
      previewImage: { url: '', imageFile: '' },
      fileType: ''
    }
  }

  updateLocalBosshuntUserDetailState = (key, value) => {
    const { updateLocalBosshuntUser } = this.props
    updateLocalBosshuntUser({
      variables: {
        key,
        value
      }
    })
  }
  updateLocalBosshuntUserDetailStateForNewForm = (key, value) => {
    const { updateLocalBosshuntUserForNewForm } = this.props
    updateLocalBosshuntUserForNewForm({
      variables: {
        key,
        value
      }
    })
  }

  handleChangeForExistingForm = e => {
    this.updateLocalBosshuntUserDetailState(e.target.name, e.target.value)
  }
  handleChangeForNewForm = e => {
    this.updateLocalBosshuntUserDetailStateForNewForm(
      e.target.name,
      e.target.value
    )
  }

  /**
   *
   * @function handleCroppingVisibility - handle when user cropping or not cropping the image
   *
   * @param {Boolean} visible
   */
  handleCroppingVisibility = visible => {
    this.setState({
      isCroppingVisible: visible
    })
  }

  /**
   *
   * @function handleImageChange - handle when user select new image
   *
   * @param {File} file
   */
  handleImageChange = e => {
    if (window.FileReader) {
      let file = e.target.files[0]
      let reader = new FileReader()

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
      }

      //reset input file
      e.target.value = null
      reader.onloadend = () => {
        let imagePreviewUrl = reader.result
        this.setState({
          imagePreviewUrl: imagePreviewUrl,
          fileType: file.type
        })
        this.handleCroppingVisibility(true)
      }
    }
  }

  /**
   *
   * @function handleCroppingImage - handle when user done with cropping image
   *
   * @param {String} imageUrl
   * @param {File} blobFile
   */
  handleCroppingImage = (imageUrl, blobFile) => {
    this.setState({
      imagePreviewUrl: imageUrl,
      previewImage: {
        ...this.state.previewImage,
        url: imageUrl
      }
    })
    if (blobFile) {
      // for passing image blob file to backend API
      this.setState({
        file: blobFile,
        previewImage: {
          ...this.state.previewImage,
          imageFile: blobFile
        }
      })
    }
  }

  renderExistingAccountSettingForm = userId => {
    return (
      <React.Fragment>
        <SectionWrapper sectionTitle="Account Setting">
          <AccountSettingForm
            {...this.props}
            headhunterId={userId}
            headhunterServerData={this.props.headhunterServerData}
            agencyList={this.props.agencyList}
            handleChange={this.handleChangeForExistingForm}
            previewImage={this.state.previewImage}
            imagePreviewUrl={this.state.imagePreviewUrl}
            handleImageChange={this.handleImageChange}
          />
        </SectionWrapper>
        <CroppingImageTool
          isVisible={this.state.isCroppingVisible}
          width={250}
          height={250}
          handleCloseModal={this.handleCroppingVisibility}
          imageSrc={this.state.imagePreviewUrl}
          fileType={this.state.fileType}
          handleCroppingImage={this.handleCroppingImage}
        />
      </React.Fragment>
    )
  }

  renderNewAccountSettingForm = userId => {
    return (
      <Query query={GET_LOCAL_BOSSHUNT_USER_DETAIL_FOR_NEW_FORM}>
        {({ loading, data: { bosshuntUserDetailForNewForm } }) => {
          if (loading) return <Loading />
          return (
            <React.Fragment>
              <SectionWrapper sectionTitle="Add new user">
                <AccountSettingForm
                  userData={bosshuntUserDetailForNewForm}
                  headhunterId={userId}
                  agencyList={this.props.agencyList}
                  updateUser={this.saveUserDetail}
                  previewImage={this.state.previewImage}
                  handleChange={this.handleChangeForNewForm}
                  handleImageChange={this.handleImageChange}
                  previewLogo={this.state.imagePreviewUrl}
                />
              </SectionWrapper>
              <CroppingImageTool
                isVisible={this.state.isCroppingVisible}
                width={250}
                height={250}
                handleCloseModal={this.handleCroppingVisibility}
                imageSrc={this.state.imagePreviewUrl}
                fileType={this.state.fileType}
                handleCroppingImage={this.handleCroppingImage}
              />
            </React.Fragment>
          )
        }}
      </Query>
    )
  }

  render() {
    const userId = this.props.match.params.id
    if (userId === 'new') {
      return this.renderNewAccountSettingForm(userId)
    } else {
      return this.renderExistingAccountSettingForm(userId)
    }
  }
}

AccountSettingFormContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  headhunterServerData: PropTypes.object.isRequired,
  agencyList: PropTypes.array.isRequired
}

export default compose(
  graphql(UPDATE_LOCAL_BOSSHUNT_USER, {
    name: 'updateLocalBosshuntUser'
  }),
  graphql(UPDATE_LOCAL_BOSSHUNT_USER_FOR_NEW_FORM, {
    name: 'updateLocalBosshuntUserForNewForm'
  })
)(AccountSettingFormContainer)
