import React from 'react'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_BOSSJOB_USER_DETAIL } from '../../../schemas/queries'

/* Component */
//import SectionWrapper from 'shared/components/SectionWrapper'
import AccountSettingForm from '../../../containers/BossJobUserDetailContainer/AccountSettingFormContainer'

/**
 * AccountSetting - main wrapper component for the Account Setting page
 *
 * @returns {Component}
 */
const AccountSetting = () => (
  <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
    {({ data: { bossjobUserDetail } }) => {
      return <AccountSettingForm userData={bossjobUserDetail} />
    }}
  </Query>
)

export default AccountSetting
