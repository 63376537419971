/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button } from 'reactstrap'

/**
 * UpdateButton - Presentational Component to Update Subscription
 *
 * @returns {Component}
 */
class UpdateButton extends Component {
  static propTypes = {
    subscriptionDetail: PropTypes.object,
    UpdateSubscription: PropTypes.func.isRequired
  }

  handleClick() {
    const { subscriptionDetail, UpdateSubscription } = this.props

    UpdateSubscription({
      variables: {
        userSubscriptionId: subscriptionDetail.id,
        subscriptionInput: {
          jobPost: subscriptionDetail.jobPost,
          featureJobCredit: subscriptionDetail.featureJobCredit,
          urgentJobCredit: subscriptionDetail.urgentJobCredit,
          unlockTalentCredit: subscriptionDetail.unlockTalentCredit,
          rhTalentCredit: subscriptionDetail.rhTalentCredit,
          salesPersonEmail: subscriptionDetail.salesPersonEmail
        }
      }
    })
      .then(response => {
        if (response.data.updateSubscription.ok) window.location.reload()
      })
      .catch(err => console.log(err))
  }

  render() {
    return (
      <Button
        style={{ marginBottom: '15px' }}
        color="info"
        onClick={() => this.handleClick()}
      >
        Update
      </Button>
    )
  }
}

export default UpdateButton
