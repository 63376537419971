import React, { useEffect, useState } from 'react'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_BOSSJOB_USER_JOB_PREFERENCE,
  GET_LOCAL_BOSSJOB_USER_DETAIL
} from '../../../../schemas/queries'

/* Components */
import JobPreferenceForm from './JobPreferenceForm'
import Loading from 'shared/components/Loading'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Bootstrap */
import { Card, CardTitle, CardBody } from 'reactstrap'

/* Assets */
import styles from 'shared/assets/scss/main.module.scss'

/* Helper */
import { getConfigList } from 'shared/helpers/configList'

/**
 * JobPreference - main wrapper component for the Profile page - Job Preference section
 *
 * @returns {Component}
 */
const JobPreference = () => {
  const [isAddingPref, setIsAddingPref] = useState(false)
  const [isSuccessAdding, setIsSuccessAdding] = useState(false)
  const [options, setOptions] = useState({})

  useEffect(() => {
    async function updateOptions() {
      const data = await getConfigList()
      const {
        industry_lists,
        main_job_function_lists,
        country_lists
      } = data.inputs
      setOptions({
        industryList: industry_lists,
        mainJobFunctionList: main_job_function_lists,
        countryLists: country_lists
      })
    }
    updateOptions()
  }, [])

  const cancelAdding = () => {
    setIsAddingPref(false)
  }

  const successAdding = () => {
    setIsSuccessAdding(true)
    setIsAddingPref(false)
  }

  return (
    <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
      {({ data: { bossjobUserDetail } }) => {
        return (
          <Card>
            <CardTitle
              className={styles.sectionTitle}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {bossjobUserDetail.statusKey !== 'inactive' && (
                <Can I="edit" a="jobseeker_pref">
                  {!isAddingPref && (
                    <div
                      className={styles.addButton}
                      onClick={() => setIsAddingPref(true)}
                    >
                      +
                    </div>
                  )}
                </Can>
              )}
            </CardTitle>
            <CardBody>
              {isAddingPref && (
                <JobPreferenceForm
                  userId={Number(bossjobUserDetail.id)}
                  cancelAdding={cancelAdding}
                  successAdding={successAdding}
                  options={options}
                  new
                />
              )}
              <Query
                query={GET_BOSSJOB_USER_JOB_PREFERENCE}
                variables={{ userId: bossjobUserDetail.id }}
                fetchPolicy="network-only"
              >
                {({ loading, data, error, refetch }) => {
                  if (error) {
                    console.log(error)
                  }
                  if (loading || Object.keys(options).length === 0) {
                    return <Loading />
                  }

                  if (isSuccessAdding) {
                    refetch()
                    setIsSuccessAdding(false)
                  }

                  if (data && data.preferences.data) {
                    const jobPreferences = data.preferences.data

                    return jobPreferences.map(jobPreference => (
                      <JobPreferenceForm
                        userId={bossjobUserDetail.id}
                        jobPreference={jobPreference}
                        options={options}
                        key={`preference-${jobPreference.id}`}
                        refetch={refetch}
                      />
                    ))
                  }
                }}
              </Query>
            </CardBody>
          </Card>
        )
      }}
    </Query>
  )
}

export default JobPreference
