import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Vendors */
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Config */
import CONFIG from 'config'

/* Helpers */
import { convertToISOFormat } from 'shared/helpers/datetime'
import { getCountries, getLocations } from 'shared/helpers/locations'

/* Assets */
// import mainStyles from 'shared/assets/scss/main.module.scss'
import styles from './EducationForm.module.scss'

const degreeList = [
  {
    label: 'Diploma',
    value: 'diploma'
  },
  {
    label: 'Bachelor',
    value: 'bachelor'
  },
  {
    label: 'Master',
    value: 'master'
  },
  {
    label: 'Doctor',
    value: 'doctor'
  }
]

/**
 * EducationForm - form component for BossJobUserDetail - Resume page
 *
 * @returns {Component}
 */
class EducationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      location:
        props.education && props.education.locationKey
          ? props.education.locationKey
          : 'manila',
      locations: [],
      country:
        props.education && props.education.countryKey
          ? props.education.countryKey
          : 'ph',
      startDate:
        props.education && props.education.studyPeriodFrom
          ? props.education.studyPeriodFrom
          : new Date(),
      endDate:
        props.education && props.education.studyPeriodTo
          ? props.education.studyPeriodTo
          : new Date(),
      isCurrentlyStudying:
        props.education && props.education.isCurrentlyStudying
          ? props.education.isCurrentlyStudying
          : false,
      school:
        props.education && props.education.school ? props.education.school : '',
      degree:
        props.education && props.education.degreeKey
          ? props.education.degreeKey
          : 'diploma',
      field:
        props.education && props.education.fieldOfStudy
          ? props.education.fieldOfStudy
          : '',
      schoolList: []
    }
  }

  componentDidMount() {
    getLocations().then(locationOptions => {
      this.setState(prevState => ({ ...prevState, locations: locationOptions }))
    })
  }

  /**
   *
   * @function manageEducation - manage education function to consolidate payload
   * and checking whether the user is editing or adding education before passing
   * to the related GraphQL mutation
   *
   * @param {*} apolloClient
   */
  manageEducation(apolloClient) {
    let payload = {
      school: this.state.school,
      degreeKey: this.state.degree,
      fieldOfStudy: this.state.field,
      studyPeriodFrom: convertToISOFormat(
        new Date(this.state.startDate),
        false
      ).toString(),
      studyPeriodTo: this.state.isCurrentlyWorkHere
        ? null
        : convertToISOFormat(new Date(this.state.endDate), false).toString(),
      isCurrentlyStudying: this.state.isCurrentlyStudying,
      locationKey: this.state.location,
      countryKey: this.state.country
    }

    if (this.props.education && this.props.education.id) {
      payload.eduId = this.props.education.id
      this.props.handleEdit(apolloClient, this.props.userId, payload)
    } else {
      this.props.handleAdd(apolloClient, this.props.userId, payload)
    }
  }

  /**
   *
   * @function handleSearch - to search and show list of schools
   * @param {String} query
   * @deprecated
   */
  handleSearch(query) {
    this.setState({
      school: query
    })

    if (query !== '') {
      fetch(
        `${CONFIG.SEARCH_BOSSJOB_URL}/search/school_lists?query=${query}&size=6`
      )
        .then(response => response.json())
        .then(data => {
          this.setState({
            schoolList: data.data.schools
          })
        })
    } else {
      this.setState({
        schoolList: []
      })
    }
  }

  /**
   *
   * @function handleClick - handle click on selected school from list
   * @param {Object} school
   */
  handleClick(school) {
    this.setState({
      school: school.name,
      schoolList: []
    })
  }

  render() {
    const {
      location,
      locations,
      country,
      school,
      degree,
      field,
      startDate,
      endDate,
      isCurrentlyStudying
      // schoolList
    } = this.state
    return (
      <Form className={styles.formWrapper}>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                School
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  name="school"
                  value={school}
                  autoComplete="off"
                  onChange={e => {
                    // this.handleSearch(e.target.value)
                    const value = e.target.value || ''
                    this.setState(prevState => ({
                      ...prevState,
                      school: value
                    }))
                  }}
                />
                {/* {schoolList.length !== 0 && (
                  <div className={mainStyles.searchList}>
                    <ul>
                      {schoolList.map(school => (
                        <li
                          key={`school-${school.id}`}
                          onClick={() => this.handleClick(school)}
                        >
                          {school.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )} */}
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Qualification
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="select"
                  name="degree"
                  value={degree}
                  onChange={e => this.setState({ degree: e.target.value })}
                >
                  {degreeList.map((item, index) => (
                    <option key={`degree-${index}`} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Field Of Study
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  name="field"
                  value={field}
                  onChange={e => this.setState({ field: e.target.value })}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Location
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="select"
                  name="location"
                  value={location}
                  onChange={e => this.setState({ location: e.target.value })}
                >
                  {locations.map((location, index) => (
                    <option key={`location-${index}`} value={location.key}>
                      {location.value} ({location.region_display_name})
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Country
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="select"
                  name="country"
                  value={country}
                  onChange={e => this.setState({ country: e.target.value })}
                  disabled={location !== 'overseas' || this.props.isInactive}
                >
                  {getCountries().map((country, index) => (
                    <option key={`country-${index}`} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Start
              </Label>
              <Col md={9} sm={12}>
                <DateTimePicker
                  disabled={this.props.isInactive}
                  format="YYYY-MM-DD"
                  time={false}
                  value={new Date(startDate)}
                  defaultValue={new Date()}
                  onChange={newDate => this.setState({ startDate: newDate })}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                End
              </Label>
              <Col md={9} sm={12}>
                <DateTimePicker
                  format="YYYY-MM-DD"
                  time={false}
                  value={new Date(endDate)}
                  defaultValue={new Date()}
                  disabled={
                    isCurrentlyStudying || this.props.isInactive ? true : false
                  }
                  onChange={newDate => this.setState({ endDate: newDate })}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={2} sm={12}>
                Currently studying here
              </Label>
              <Col md={10} sm={12}>
                <Input
                  type="checkbox"
                  checked={isCurrentlyStudying}
                  onChange={e =>
                    this.setState({ isCurrentlyStudying: e.target.checked })
                  }
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {!this.props.isInactive && (
          <Can I="edit" a="bossjob_user">
            <ApolloConsumer>
              {client => (
                <div>
                  {this.props.new ? (
                    <React.Fragment>
                      <Button
                        color="danger"
                        onClick={() => this.props.handleDelete()}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="info"
                        onClick={() => this.manageEducation(client)}
                      >
                        Add
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        color="danger"
                        onClick={() =>
                          this.props.handleDelete(
                            client,
                            this.props.userId,
                            this.props.education.id
                          )
                        }
                      >
                        Delete
                      </Button>
                      <Button
                        color="info"
                        onClick={() => this.manageEducation(client)}
                      >
                        Save
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              )}
            </ApolloConsumer>
          </Can>
        )}
      </Form>
    )
  }
}

EducationForm.propTypes = {
  userId: PropTypes.number.isRequired,
  education: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleAdd: PropTypes.func,
  new: PropTypes.bool,
  isInactive: PropTypes.bool
}

export default EducationForm
