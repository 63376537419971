import React, { Component } from 'react'
import { Table } from 'reactstrap'

import PaginationComponent from '../Pagination/Pagination'

import styles from '../CompanyAnalytics.module.scss'

const THEAD = [
  'Full Name',
  'Email',
  'Talent Views',
  'Chats Initiated by Boss',
  'Effective Chats Initiated by Boss',
  'Job Views',
  'Job Posted',
  'Job Published',
  'Featured Credits Usage',
  'Urgent Credits Usage',
  'Search Talent Credits Usage',
  'Chats Initiated by Talents',
  'Effective Chats Initiated by Talents',
  'Total Chats',
  'Total Effective Chats'
]
class ChatTable extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, handleChangeChatPage } = this.props
    const { currentPage, totalCount, totalPages, size } = data
    return (
      <div className={styles.table}>
        <Table>
          <thead>
            <tr>
              {THEAD.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.hrs.length ? (
              data.hrs.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{item.fullName}</th>
                  <td>{item.email}</td>
                  <td>{item.totalChatCount}</td>
                  <td>{item.chatInitiatedByBossCount}</td>
                  <td>{item.effectiveChatInitiatedByBossCount}</td>
                  <td>{item.viewJobCount}</td>
                  <td>
                    <a href={item.postedJobUrl}>{item.postedJobCount}</a>
                  </td>
                  <td>{item.publishedJobCount}</td>
                  <td>{item.featureCreditUseCount}</td>
                  <td>{item.urgentCreditUseCount}</td>
                  <td>{item.unlockCreditUseCount}</td>
                  <td>{item.chatInitiatedByTalentCount}</td>
                  <td>{item.effectiveChatInitiatedByTalentCount}</td>
                  <td>{item.totalChatCount}</td>
                  <td>{item.effectiveTotalChatCount}</td>
                </tr>
              ))
            ) : (
              <tr>
                <th>No data available</th>
              </tr>
            )}
          </tbody>
        </Table>

        <PaginationComponent
          currentPage={Number(currentPage)}
          totalCount={totalCount}
          totalPages={totalPages}
          pageRange={size}
          handleChangeChatPage={handleChangeChatPage}
        />
      </div>
    )
  }
}

export default ChatTable
