import gql from 'graphql-tag'

/*====== Local State ======*/
/* Bossjob user */
export const UPDATE_LOCAL_BOSSJOB_USER = gql`
  mutation updateLocalBossjobUser($key: String, $value: String) {
    updateBossjobUser(key: $key, value: $value) @client
  }
`

/* Credit Modal mutation */
export const UPDATE_LOCAL_CREDIT_MODAL = gql`
  mutation updateLocalCreditModal($isVisible: Boolean, $modalData: Object) {
    handleCreditModal(isVisible: $isVisible, modalData: $modalData) @client
  }
`

/* Add new social link mutation */
export const ADD_USER_SOCIAL_LINK = gql`
  mutation addUserSocialLink($socialType: String, $username: String) {
    addSocialLink(socialType: $socialType, username: $username) @client
  }
`

/* Edit social link mutation */
export const EDIT_USER_SOCIAL_LINK = gql`
  mutation editUserSocialLink(
    $socialType: String!
    $username: String!
    $index: Int!
  ) {
    editSocialLink(socialType: $socialType, username: $username, index: $index)
      @client
  }
`

/* Delete social link mutation */
export const DELETE_USER_SOCIAL_LINK = gql`
  mutation deleteUserSocialLink($index: Int!) {
    deleteSocialLink(index: $index) @client
  }
`

/*====== End Local State ======*/

/** update bossjob user account setting */
export const UPDATE_BOSSJOB_USER_DETAIL = gql`
  mutation UpdateUserDetail($userId: Int!, $payload: UserUpdateInputs) {
    updateBossjobUsers(userId: $userId, userInput: $payload) {
      ok
    }
  }
`

/** delete bossjob user work experience */
export const DELETE_BOSSJOB_USER_WORK_XPS = gql`
  mutation DeleteWorkExperience($userId: Int!, $experienceId: Int!) {
    deleteJobseekerWorksXps(userId: $userId, workXpsId: $experienceId) {
      ok
    }
  }
`

/** edit bossjob user work experience */
export const EDIT_BOSSJOB_USER_WORK_XPS = gql`
  mutation EditWorkExperience($userId: Int!, $payload: UpdateWorkXps) {
    updateJobseekerWorkXps(userId: $userId, workXps: $payload) {
      ok
    }
  }
`

/** add bossjob user work experience */
export const ADD_BOSSJOB_USER_WORK_XPS = gql`
  mutation AddWorkExperience($userId: Int!, $payload: CreateWorkXps) {
    createJobseekerWorkXps(userId: $userId, workXps: $payload) {
      ok
    }
  }
`

/** delete bossjob user education */
export const DELETE_BOSSJOB_USER_EDUCATION = gql`
  mutation DeleteEducation($userId: Int!, $educationId: Int!) {
    deleteJobseekerEdu(userId: $userId, eduId: $educationId) {
      ok
    }
  }
`

/** edit bossjob user education */
export const EDIT_BOSSJOB_USER_EDUCATION = gql`
  mutation EditEducation($userId: Int!, $payload: UpdateEducation) {
    updateJobseekerEdu(userId: $userId, education: $payload) {
      ok
    }
  }
`

/** add bossjob user education */
export const ADD_BOSSJOB_USER_EDUCATION = gql`
  mutation AddEducation($userId: Int!, $payload: CreateEducation) {
    createJobseekerEdu(userId: $userId, education: $payload) {
      ok
    }
  }
`

/** update bossjob user skills */
export const UPDATE_BOSSJOB_USER_SKILLS = gql`
  mutation UpdateSkills($userId: Int!, $payload: UpdateJobSkillsInput) {
    updateJobSeekerSkills(userId: $userId, jobSkills: $payload) {
      ok
    }
  }
`

/** update bossjob user job preference */
export const UPDATE_BOSSJOB_USER_PREFERENCES = gql`
  mutation UpdateJobPreferences($userId: Int!, $payload: UpdatePreferences) {
    updateJobseekerPref(userId: $userId, preferences: $payload) {
      ok
    }
  }
`

/** add bossjob user job preference */
export const ADD_BOSSJOB_USER_PREFERENCES = gql`
  mutation AddJobPreferences($userId: Int!, $payload: CreatePreferences) {
    createJobseekerPref(userId: $userId, preferences: $payload) {
      ok
    }
  }
`

/** delete bossjob user job preference */
export const DELETE_BOSSJOB_USER_PREFERENCES = gql`
  mutation DeleteJobPreferences($userId: Int!, $prefId: Int!) {
    deleteJobseekerPref(userId: $userId, prefId: $prefId) {
      ok
    }
  }
`

/** update bossjob recruiter settings */
export const UPDATE_RECRUITER_SETTINGS = gql`
  mutation UpdateRecruiterSettings(
    $userId: Int!
    $email: String!
    $jobTitle: String!
    $isVerify: Boolean!
    $isJobSalaryHidden: Boolean!
    $editExistingJobIsSalaryHidden: String!
  ) {
    updateRecruiterSetting(
      recruiterId: $userId
      contactEmail: $email
      jobTitle: $jobTitle
      verifyStatus: $isVerify
      isJobSalaryHidden: $isJobSalaryHidden
      editExistingJobIsSalaryHidden: $editExistingJobIsSalaryHidden
    ) {
      ok
    }
  }
`

/** update recruiter's company */
export const UPDATE_RECRUITER_COMPANY = gql`
  mutation UpdateRecruiterCompany(
    $userId: Int!
    $companyId: Int!
    $jobTitle: String!
    $workingPeriod: DateTime!
  ) {
    joinCompany(
      recruiterId: $userId
      companyId: $companyId
      jobTitle: $jobTitle
      workingPeriodFrom: $workingPeriod
    ) {
      ok
    }
  }
`

/** add points */
export const ADD_BOSSPOINT_TO_USER = gql`
  mutation AddBosspoint($userId: Int!, $points: Int!, $notes: String) {
    addPoints(userId: $userId, points: $points, notes: $notes) {
      ok
    }
  }
`

/** update point note */
export const UPDATE_BOSSPOINT_NOTE_TO_USER = gql`
  mutation updateBosspointNote($id: Int!, $notes: String) {
    updatePoints(pointId: $id, notes: $notes) {
      ok
    }
  }
`

/** remove blacklisted user */
export const REMOVE_BLACKLISTED_USER = gql`
  mutation removeBlacklistedUser($id: Int!) {
    deleteBlacklistUser(blacklistId: $id) {
      ok
    }
  }
`

/** delete bossjob users is bulk */
export const DELETE_BOSSJOB_USERS = gql`
  mutation deleteBossjobUsers($ids: [Int]!) {
    deleteUsers(userId: $ids) {
      ok
    }
  }
`

/*====== Job Post Credits ======*/
export const ADD_BOSSJOB_USER_JOB_POST_CREDITS = gql`
  mutation addBossjobUserJobPostCredits(
    $userId: Int!
    $remark: String!
    $typeId: Int!
    $value: Int!
  ) {
    addJobPosts(
      userId: $userId
      remark: $remark
      typeId: $typeId
      value: $value
    ) {
      ok
    }
  }
`

/*====== Credits ======*/
export const ADD_BOSSJOB_USER_CREDITS = gql`
  mutation addBossjobUserCredits(
    $value: Int!
    $remark: String!
    $userId: Int!
    $typeId: Int!
  ) {
    addCredits(
      value: $value
      remark: $remark
      userId: $userId
      typeId: $typeId
    ) {
      ok
    }
  }
`

// export const ADD_BOSSJOB_USER_JOB_CREDITS = gql`
//   mutation addBossjobUserCredits($credit: Int!, $notes: String, $userId: Int!) {
//     addCredits(credits: $credit, key: "job", notes: $notes, userId: $userId) {
//       ok
//     }
//   }
// `

export const UPDATE_BOSSJOB_USER_CREDITS_NOTES = gql`
  mutation updateBossjobUserCreditsNotes($id: Int!, $notes: String) {
    updateCredits(creditId: $id, notes: $notes) {
      ok
    }
  }
`

/* update social links */
export const UPDATE_SOCIAL_LINKS = gql`
  mutation updateSocialLinks(
    $userId: Int!
    $socialLinks: [updateSocialLinksObject]
  ) {
    updateSocialLinks(userId: $userId, socialLinks: $socialLinks) {
      ok
    }
  }
`

/* upload resume */
export const UPLOAD_RESUME = gql`
  mutation UploadResume($userId: Int!, $file: Upload!) {
    uploadResume(userId: $userId, file: $file) {
      ok
    }
  }
`
/* delete resume */
export const DELETE_RESUME = gql`
  mutation DeleteResume($userId: Int!, $resumeId: Int!) {
    deleteResume(userId: $userId, resumeId: $resumeId) {
      ok
      message
    }
  }
`

/* Upload user avatar */
export const UPLOAD_USER_AVATAR = gql`
  mutation UploadUserAvatar($id: Int!, $file: Upload!) {
    uploadJobseekerAvatar(userId: $id, file: $file) {
      ok
      message
    }
  }
`
/* Reset user password */
export const RESET_USER_PASSWORD = gql`
  mutation ResetBossjobUserPassword($userId: Int!) {
    resetBossjobUserPassword(userId: $userId) {
      ok
      message
    }
  }
`

/*====== Job stats Excel ======*/
export const EXPORT_JOB_EXCEL = gql`
  mutation ExportJobExcel($jobId: Int!) {
    exportJobExcel(jobId: $jobId) {
      ok
      url
    }
  }
`

export const EXPORT_JOBS_EXCEL = gql`
  mutation ExportJobsExcel(
    $recruiterId: Int!
    $fromDate: DateTime
    $toDate: DateTime
    $status: String
  ) {
    exportJobsExcel(
      recruiterId: $recruiterId
      fromDate: $fromDate
      toDate: $toDate
      status: $status
    ) {
      ok
      message
    }
  }
`

/*====== Job applicant stats Excel ======*/
export const EXPORT_JOB_APPLICANT_EXCEL = gql`
  mutation ExportJobApplicantExcel($jobId: Int!) {
    exportJobApplicantExcel(jobId: $jobId) {
      ok
      url
    }
  }
`

export const EXPORT_JOBS_APPLICANT_EXCEL = gql`
  mutation ExportJobsApplicantExcel(
    $recruiterId: Int!
    $fromDate: DateTime
    $toDate: DateTime
    $status: String
  ) {
    exportJobsApplicantExcel(
      recruiterId: $recruiterId
      fromDate: $fromDate
      toDate: $toDate
      status: $status
    ) {
      ok
      message
    }
  }
`
