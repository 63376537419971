import CONFIG from 'config'

let PRIVATE_CONFIG_OPTIONS = {}

const PRIVATE_DEFAULT_MONTH_SALARY_OPTION = [
  { id: 4, value: 'Annually' },
  { id: 1, value: 'Monthly' }
]
const PRIVATE_DEFAULT_SALARIES_OPTIONS_LIST = [
  { id: 1, value: 'Monthly' },
  { id: 2, value: 'Daily' },
  { id: 3, value: 'Hourly' }
]
const PRIVATE_JOB_TYPE = ['part_time', 'contract', 'temp', 'freelance']

const privateShouldShowSalaryType = (jobTypeKey = 'full_time') => {
  //  2 => part_time
  //  3 => contract
  //  4 => temp
  //  6 => freelance
  //  true 可以日薪或者时薪
  // return [2, 3, 4, 6].includes(salaryTypeId)
  return PRIVATE_JOB_TYPE.includes(jobTypeKey)
}

const privateFetchSpecificConfigFieldOptions = async () => {
  await fetch(`${CONFIG.BOSSJOB_CONFIG_URL}/ph/list?language_code=en`)
    //await fetch(`${CONFIG.BOSSJOB_CONFIG_URL}/list?filters=countries,languages,currencies,salary_ranges,daily_salary_ranges,hourly_salary_ranges,salary_type_lists,currency_lists,language_code=en`)
    .then(response => response.json())
    .then(({ data }) => {
      PRIVATE_CONFIG_OPTIONS = data
      return data
    })
}

const getSalariesTypeOptions = (jobTypeKey = 'full_time') => {
  const { salary_type_lists } = PRIVATE_CONFIG_OPTIONS
  return jobTypeKey === 'full_time'
    ? salary_type_lists.filter(v => [4, 1].includes(v.id))
    : salary_type_lists.filter(v => [1, 2, 3].includes(v.id))
  // const isSingleOrMarried = privateShouldShowSalaryType(jobTypeKey)
  // console.log('jobTypeKey', jobTypeKey, salary_type_lists)

  // if (isSingleOrMarried && salary_type_lists) {
  //   return salary_type_lists
  // }

  // if (isSingleOrMarried && !salary_type_lists) {
  //   privateFetchSpecificConfigFieldOptions()
  //   return PRIVATE_DEFAULT_SALARIES_OPTIONS_LIST
  // }

  // return PRIVATE_DEFAULT_MONTH_SALARY_OPTION
}

const getSalaryOption = (currentSalaryRange, bound) => {
  let salaryOptions = ['']
  if (currentSalaryRange) {
    let { from, to, interval, upper_bound_scale } = currentSalaryRange
    if (bound === 'upper') {
      to = to * upper_bound_scale
    }

    for (let salary = from; salary <= to; salary += interval) {
      salaryOptions.push(salary)
    }
  }
  return salaryOptions
}

const getCurrentShouldShowObject = (salaryTypeId, allSalariesTypes) => {
  const {
    salaryRangeFrom,
    salaryRangeTo,
    dailySalaryRangeFrom,
    dailySalaryRangeTo,
    hourlySalaryRangeFrom,
    hourlySalaryRangeTo,
    annuallySalaryRangeFrom,
    annuallySalaryRangeTo
  } = allSalariesTypes
  // console.log('allSalariesTypes', allSalariesTypes)
  const {
    salary_ranges_lists: salaryRanges,
    hourly_salary_range_lists: hourlySalaryRangeLists,
    daily_salary_range_lists: dailySalaryRangeLists,
    annually_salary_range_lists: annuallySalaryRangeLists
  } = PRIVATE_CONFIG_OPTIONS

  // console.log('PRIVATE_CONFIG_OPTIONS', PRIVATE_CONFIG_OPTIONS)

  let currencyIdToSalaryRangesMap = {}
  let currentSalaryFrom = null
  let currentSalaryTo = null
  let fromName = null
  let toName = null

  if (salaryTypeId == 1) {
    toName = 'salaryRangeTo'
    fromName = 'salaryRangeFrom'

    currentSalaryFrom = salaryRangeFrom
    currentSalaryTo = salaryRangeTo

    currencyIdToSalaryRangesMap =
      salaryRanges &&
      Object.fromEntries(salaryRanges.map(i => [i.currency_id, i]))
  }

  if (salaryTypeId == 2) {
    toName = 'dailySalaryRangeTo'
    fromName = 'dailySalaryRangeFrom'

    currentSalaryFrom = dailySalaryRangeFrom
    currentSalaryTo = dailySalaryRangeTo

    currencyIdToSalaryRangesMap =
      dailySalaryRangeLists &&
      Object.fromEntries(dailySalaryRangeLists.map(i => [i.currency_id, i]))
  }

  if (salaryTypeId == 3) {
    toName = 'hourlySalaryRangeTo'
    fromName = 'hourlySalaryRangeFrom'

    currentSalaryFrom = hourlySalaryRangeFrom
    currentSalaryTo = hourlySalaryRangeTo

    currencyIdToSalaryRangesMap =
      hourlySalaryRangeLists &&
      Object.fromEntries(hourlySalaryRangeLists.map(i => [i.currency_id, i]))
  }

  if (salaryTypeId === 4) {
    toName = 'annuallySalaryRangeTo'
    fromName = 'annuallySalaryRangeFrom'
    currentSalaryFrom = annuallySalaryRangeFrom
    currentSalaryTo = annuallySalaryRangeTo

    currencyIdToSalaryRangesMap =
      annuallySalaryRangeLists &&
      Object.fromEntries(annuallySalaryRangeLists.map(i => [i.currency_id, i]))
  }

  return {
    currencyIdToSalaryRangesMap,
    currentSalaryFrom,
    currentSalaryTo,
    fromName,
    toName
  }
}

export { getSalariesTypeOptions, getCurrentShouldShowObject, getSalaryOption }

// Init data
privateFetchSpecificConfigFieldOptions()
