exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".form__2o0bW {\n  margin-bottom: 0 !important;\n}\n\n.tab__3MhB_ {\n  margin-top: 25px;\n}\n.tab__3MhB_ .card__2qNv4 {\n  background-color: antiquewhite;\n}\n.tab__3MhB_ .tabChild__1Naqs {\n  cursor: pointer;\n}\n\n.export__2Yzpy {\n  text-align: right;\n}\n\n.table__2ndaO {\n  background-color: white;\n}\n\n.tabActive__1TKyy {\n  border-bottom: 1px solid #007bff;\n  color: #007bff;\n}\n\n> .page-link__3SLFa:focus {\n  -webkit-box-shadow: none;\n          box-shadow: none;\n}\n\n.loading__cIg12 {\n  opacity: 0.3;\n  cursor: not-allowed !important;\n}", "", {"version":3,"sources":["/app/src/modules/companies/views/CompanyDetail/CompanyAnalytics/CompanyAnalytics.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAA2B;CAC7B;;AAEA;EACE,iBAAgB;CAClB;AACA;EACE,+BAA8B;CAChC;AACA;EACE,gBAAe;CACjB;;AAEA;EACE,kBAAiB;CACnB;;AAEA;EACE,wBAAuB;CACzB;;AAEA;EACE,iCAAgC;EAChC,eAAc;CAChB;;AAEA;EACE,yBAAgB;UAAhB,iBAAgB;CAClB;;AAEA;EACE,aAAY;EACZ,+BAA8B;CAChC","file":"CompanyAnalytics.module.scss","sourcesContent":[".form {\n  margin-bottom: 0 !important;\n}\n\n.tab {\n  margin-top: 25px;\n}\n.tab .card {\n  background-color: antiquewhite;\n}\n.tab .tabChild {\n  cursor: pointer;\n}\n\n.export {\n  text-align: right;\n}\n\n.table {\n  background-color: white;\n}\n\n.tabActive {\n  border-bottom: 1px solid #007bff;\n  color: #007bff;\n}\n\n> .page-link:focus {\n  box-shadow: none;\n}\n\n.loading {\n  opacity: 0.3;\n  cursor: not-allowed !important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"form": "form__2o0bW",
	"tab": "tab__3MhB_",
	"card": "card__2qNv4",
	"tabChild": "tabChild__1Naqs",
	"export": "export__2Yzpy",
	"table": "table__2ndaO",
	"tabActive": "tabActive__1TKyy",
	"page-link": "page-link__3SLFa",
	"loading": "loading__cIg12"
};