import gql from 'graphql-tag'

const demoRequests = gql`
  fragment demoRequests on DemoRequestType {
    id
    email
    firstName
    companyName
    lastName
    contactNum
    source
    message
    createdAt
    updatedAt
  }
`

export const GET_REQUEST_LIST = gql`
  query getDemoRequestList($size: Int, $page: Int) {
    demoRequestList(size: $size, page: $page) {
      data {
        totalPages
        totalNum
        currentPage
        currentNum
        demoRequests {
          ...demoRequests
        }
      }
    }
  }
  ${demoRequests}
`
