import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, compose, graphql } from 'react-apollo'
import { GET_ROLE_BY_ID } from '../../schemas/queries'
import {
  UPDATE_LOCAL_ROLE_NODES,
  UPDATE_ROLE_NODE
} from '../../schemas/mutations'

/* Component */
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import PermissionList from '../../views/RolePermission/PermissionList'

class RolePermissionFormContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    updateRoleNode: PropTypes.func.isRequired,
    saveNodeAccess: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.updateNodeAccess = this.updateNodeAccess.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  updateNodeAccess(canView, canEdit, roleNodeId) {
    const access = {
      __typename: 'RoleNodeAccess',
      canView: canView,
      canEdit: canEdit
    }

    this.props.updateRoleNode({
      variables: {
        access: access,
        roleNodeId: roleNodeId
      }
    })
  }

  handleSubmit(rolePermissions) {
    let updationCount = 0
    const totalCount = rolePermissions.length

    Object.keys(rolePermissions).forEach(index => {
      const nodeId = Object.keys(rolePermissions[index])[0]
      const roleNodeId = rolePermissions[index][nodeId]

      const access = {
        canView: rolePermissions[index].canView,
        canEdit: rolePermissions[index].canEdit
      }

      try {
        this.props
          .saveNodeAccess({
            variables: {
              access: access,
              roleNodeId: roleNodeId
            }
          })
          .then(response => {
            if (response.data && response.data.updateRoleNode.ok) {
              updationCount++

              if (updationCount === totalCount) {
                window.location.reload()
              }
            }
          })
      } catch (err) {
        console.log(err)
      }
    })
  }

  render() {
    return (
      <Query
        query={GET_ROLE_BY_ID}
        variables={{ id: this.props.match.params.id }}
      >
        {({ loading, data, client }) => {
          if (loading) {
            return <Loading />
          } else {
            client.writeData({
              data: {
                rolePermissions: data.allRoles[0].roleNodes
              }
            })
            return (
              <SectionWrapper
                sectionTitle={`${data.allRoles[0].roleName} Role`}
                isMainPage
              >
                <PermissionList
                  handleToggle={this.updateNodeAccess}
                  handleSubmit={this.handleSubmit}
                />
              </SectionWrapper>
            )
          }
        }}
      </Query>
    )
  }
}

export default compose(
  graphql(UPDATE_LOCAL_ROLE_NODES, { name: 'updateRoleNode' }),
  graphql(UPDATE_ROLE_NODE, { name: 'saveNodeAccess' })
)(withRouter(RolePermissionFormContainer))
