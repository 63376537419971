import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_SUBSCRIPTION_PLAN_LIST } from 'modules/subscription_plans/schemas/queries'

/* Components */
import SubscriptionPlanList from 'modules/subscription_plans/views/SubscriptionPlans/SubscriptionPlanList/SubscriptionPlanList'
import Loading from 'shared/components/Loading'

/**
 * SubscriptionPlanListContainer
 *
 * @returns {Component}
 */
class SubscriptionPlanListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { page } = this.state

    const { search } = queryString.parse(location.search)

    let variables = {
      page: page,
      queryData: search ? search : null
    }

    return (
      <Query query={GET_SUBSCRIPTION_PLAN_LIST} variables={variables}>
        {({ loading, data: { subscriptionPlanList }, refetch }) => {
          if (loading) return <Loading />
          return (
            <SubscriptionPlanList
              subscriptionPlanList={subscriptionPlanList.data.subscriptionPlans}
              totalNum={subscriptionPlanList.data.totalNum}
              currentPage={page}
              totalPages={subscriptionPlanList.data.totalPages}
              refetch={refetch}
            />
          )
        }}
      </Query>
    )
  }
}

export default withRouter(SubscriptionPlanListContainer)
