exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".loginWrapper__3Vb7v {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 5em 0;\n}", "", {"version":3,"sources":["/app/src/modules/authentication/views/Login/Login.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,0BAAmB;MAAnB,uBAAmB;UAAnB,oBAAmB;EACnB,eAAc;CAChB","file":"Login.module.scss","sourcesContent":[".loginWrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 5em 0;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loginWrapper": "loginWrapper__3Vb7v"
};