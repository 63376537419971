import {
  GET_LOCAL_SUBSCRIPTION_PLAN_DETAIL,
  GET_LOCAL_SUBSCRIPTION_PLAN_DETAIL_FOR_NEW_FORM
} from '../schemas/queries'

export default {
  updateLocalSubscriptionPlanDetail: (_, { key, value }, { cache }) => {
    const { subscriptionPlanDetail } = cache.readQuery({
      query: GET_LOCAL_SUBSCRIPTION_PLAN_DETAIL
    })

    let newSubscriptionPlanDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        subscriptionPlanDetail: {
          ...subscriptionPlanDetail,
          ...newSubscriptionPlanDetail
        }
      }
    })

    return null
  },

  updateLocalSubscriptionPlanDetailForNewForm: (
    _,
    { key, value },
    { cache }
  ) => {
    const { subscriptionPlanDetailForNewForm } = cache.readQuery({
      query: GET_LOCAL_SUBSCRIPTION_PLAN_DETAIL_FOR_NEW_FORM
    })

    let newSubscriptionPlanDetailForNewForm = {
      [key]: value
    }

    cache.writeData({
      data: {
        subscriptionPlanDetailForNewForm: {
          ...subscriptionPlanDetailForNewForm,
          ...newSubscriptionPlanDetailForNewForm
        }
      }
    })

    return null
  }
}
