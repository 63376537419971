import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter, Route, Redirect } from 'react-router-dom'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import { GET_AGENCY_BY_ID } from '../../schemas/queries'
import { UPDATE_LOCAL_AGENCY_DETAIL } from '../../schemas/mutations'

/* Components */
import Navigation from './Navigation'
import Loading from 'shared/components/Loading'
import AgencySettings from './AgencySettings'
import Headhunters from './Headhunters'
import FeesFormContainer from '../../containers/AgencyDetailContainer/FeesFormContainer/FeesFormContainer'
import SpecializationFormContainer from '../../containers/AgencyDetailContainer/SpecializationFormContainer/SpecializationFormContainer'

/* Helpers */
import { locationList } from 'shared/helpers/locations'

/**
 * AgencyDetail - main wrapper component for Bosshunt agency details and match url to corresponding component
 * @returns {Component}
 */

const AgencyDetail = ({ match, updateLocalAgencyDetail }) => {
  const agencyId = match.params.id
  const updateLocalAgencyDetailDetailState = (key, value, category) => {
    updateLocalAgencyDetail({
      variables: {
        key,
        value,
        category
      }
    })
  }

  const handleChangeForExistingForm = (e, category) => {
    updateLocalAgencyDetailDetailState(e.target.name, e.target.value, category)
  }

  const handleToggleForExistingForm = (key, value, category) => {
    updateLocalAgencyDetailDetailState(key, value, category)
  }

  const handleSpecializationChange = (actionType, index, type) => {
    if (actionType === 'add') {
      updateLocalAgencyDetail({
        variables: {
          key: type,
          value: null
        }
      })
    } else {
      updateLocalAgencyDetail({
        variables: {
          key: type,
          del: index
        }
      })
    }
  }

  if (agencyId === 'new') {
    return (
      <React.Fragment>
        <Route
          exact
          path={'/bosshunt/agency/new'}
          render={() => <Redirect to={`${match.url}/account`} />}
        />
        <Route
          exact
          path={'/bosshunt/agency/new/account'}
          render={() => (
            <AgencySettings match={match} locationList={locationList} />
          )}
        />
      </React.Fragment>
    )
  } else {
    return (
      <div>
        <Navigation />
        <Query
          query={GET_AGENCY_BY_ID}
          variables={{ companyId: match.params.id }}
        >
          {({ loading, data: { headhunterCompany }, client }) => {
            if (loading) {
              return <Loading />
            } else {
              if (agencyId && headhunterCompany && headhunterCompany.data) {
                client.writeData({
                  data: {
                    agencyDetail: headhunterCompany.data
                  }
                })
                if (!headhunterCompany.data.feeStructure) {
                  client.writeData({
                    data: {
                      bosshuntUserDetail: {
                        ...headhunterCompany.data,
                        // necessary to initialize the bosshuntUserDetail with the following:
                        // failing which will result in cache unable to be updated with data
                        // because initial data structure returned from back end did not contain this:
                        feeStructure: {
                          ...headhunterCompany.data.feeStructure,
                          isContingentFee: false,
                          isRetainedFee: false,
                          isOtherFee: false,
                          annualGrossSalaryFrom: null,
                          annualGrossSalaryTo: null,
                          monthlyFeeFrom: null,
                          monthlyFeeTo: null,
                          otherFee: null,
                          __typename: 'CompanyFeeStructureType'
                        }
                      }
                    }
                  })
                }
                if (!headhunterCompany.data.turnaroundTime) {
                  client.writeData({
                    data: {
                      bosshuntUserDetail: {
                        ...headhunterCompany.data,
                        // necessary to initialize the bosshuntUserDetail with the following:
                        // failing which will result in cache unable to be updated with data
                        // because initial data structure returned from back end did not contain this:
                        turnaroundTime: {
                          ...headhunterCompany.data.turnaroundTime,
                          turnaroundTimeId: 1,
                          fromPeriod: null,
                          toPeriod: null,
                          __typename: 'CompanyTurnaroundTimeType'
                        }
                      }
                    }
                  })
                }
                if (!headhunterCompany.data.guaranteePeriod) {
                  client.writeData({
                    data: {
                      bosshuntUserDetail: {
                        ...headhunterCompany.data,
                        // necessary to initialize the bosshuntUserDetail with the following:
                        // failing which will result in cache unable to be updated with data
                        // because initial data structure returned from back end did not contain this:
                        guaranteePeriod: {
                          ...headhunterCompany.data.guaranteePeriod,
                          guaranteePeriodId: 1,
                          fromPeriod: null,
                          toPeriod: null,
                          __typename: 'CompanyGuaranteePeriodType'
                        }
                      }
                    }
                  })
                }
                if (!headhunterCompany.data.freeReplacement) {
                  client.writeData({
                    data: {
                      bosshuntUserDetail: {
                        ...headhunterCompany.data,
                        // necessary to initialize the bosshuntUserDetail with the following:
                        // failing which will result in cache unable to be updated with data
                        // because initial data structure returned from back end did not contain this:
                        freeReplacement: {
                          ...headhunterCompany.data.freeReplacement,
                          maxFreeReplacement: null,
                          __typename: 'CompanyFreeReplacementType'
                        }
                      }
                    }
                  })
                }
                if (!headhunterCompany.data.services) {
                  client.writeData({
                    data: {
                      agencyDetail: {
                        ...headhunterCompany.data,
                        // necessary to initialize the agencyDetail with the following:
                        // failing which will result in cache unable to be updated with data
                        services: []
                      }
                    }
                  })
                }
                if (!headhunterCompany.data.industries) {
                  client.writeData({
                    data: {
                      agencyDetail: {
                        ...headhunterCompany.data,
                        // necessary to initialize the agencyDetail with the following:
                        // failing which will result in cache unable to be updated with data
                        industries: []
                      }
                    }
                  })
                }
                if (!headhunterCompany.data.serviceLocations) {
                  client.writeData({
                    data: {
                      agencyDetail: {
                        ...headhunterCompany.data,
                        // necessary to initialize the agencyDetail with the following:
                        // failing which will result in cache unable to be updated with data
                        serviceLocations: []
                      }
                    }
                  })
                }
                return (
                  <React.Fragment>
                    <Route
                      exact
                      path={`${match.path}`}
                      component={() => <Redirect to={`${match.url}/account`} />}
                    />
                    <Route
                      exact
                      path="/bosshunt/agency/:id/account"
                      render={() => (
                        <AgencySettings
                          match={match}
                          locationList={locationList}
                        />
                      )}
                    />
                    <Route
                      path="/bosshunt/agency/:id/fees"
                      render={() => (
                        <FeesFormContainer
                          match={match}
                          handleChangeForExistingForm={
                            handleChangeForExistingForm
                          }
                          handleToggleForExistingForm={
                            handleToggleForExistingForm
                          }
                        />
                      )}
                    />
                    <Route
                      path="/bosshunt/agency/:id/specializations"
                      render={() => (
                        <SpecializationFormContainer
                          match={match}
                          handleChangeForExistingForm={
                            handleChangeForExistingForm
                          }
                          handleSpecializationChange={
                            handleSpecializationChange
                          }
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/bosshunt/agency/:id/headhunters/:page"
                      component={Headhunters}
                    />
                  </React.Fragment>
                )
              }
            }
          }}
        </Query>
      </div>
    )
  }
}
AgencyDetail.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default compose(
  graphql(UPDATE_LOCAL_AGENCY_DETAIL, {
    name: 'updateLocalAgencyDetail'
  })
)(withRouter(AgencyDetail))
