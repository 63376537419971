import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button, Input } from 'reactstrap'

/* Vendors */
import FontAwesome from 'react-fontawesome'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/**
 * Role List - Role list table
 *
 */
class RoleList extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    if (e.target.checked) {
      this.props.appendSelectedRole(e.target.value)
    } else {
      this.props.removeSelectedRole(e.target.value)
    }
  }

  render() {
    const { roleList, handleDelete, handleDeactivate } = this.props

    return (
      <Table bordered>
        <thead>
          <tr>
            <th />
            <th>ID</th>
            <th>Role Name</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Last edited</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {roleList.map(role => (
            <tr key={role.id}>
              <td>
                <Input
                  addon
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  onChange={this.handleChange}
                  value={role.id}
                />
              </td>
              <td>
                <a>{role.id}</a>
              </td>
              <td>{role.roleName}</td>
              <td>{role.isActive === 1 ? 'Active' : 'Inactive'}</td>
              <td>{formatDate(role.createdAt, 'DD/MM/YYYY HH:mm:Ss')}</td>
              <td>{formatDate(role.updatedAt, 'DD/MM/YYYY HH:mm:Ss')}</td>
              <td>
                <Can I="edit" a="admin_user">
                  {role.roleName !== 'Administrator' && (
                    <React.Fragment>
                      <Button
                        color="danger"
                        onClick={() => handleDelete(role.id)}
                        style={{ marginRight: '10px' }}
                      >
                        <FontAwesome name="trash" />
                      </Button>
                      <Button
                        color="warning"
                        onClick={() => handleDeactivate(role.id)}
                        style={{ marginRight: '10px' }}
                      >
                        Deactivate
                      </Button>
                    </React.Fragment>
                  )}
                  <a href={`/role/${role.id}`}>
                    <Button color="info">
                      <FontAwesome name="pencil" />
                    </Button>
                  </a>
                </Can>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}

RoleList.propTypes = {
  /* array of role list */
  roleList: PropTypes.array.isRequired,

  handleDelete: PropTypes.func.isRequired,

  appendSelectedRole: PropTypes.func.isRequired,

  removeSelectedRole: PropTypes.func.isRequired,

  handleDeactivate: PropTypes.func.isRequired
}

export default RoleList
