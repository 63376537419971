import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button } from 'reactstrap'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import useReferralLink from '../useReferralLink'

const ReferralCode = ({ id = 0, isMy = false }) => {
  const {
    handleMyInvitation = undefined,
    handleCancelPopover = undefined,
    popoverOpen = undefined,
    referralLink = undefined,
    loading = undefined,
    handleCopyLink = undefined
  } = (useReferralLink && useReferralLink()) || {}

  let text = 'Generate Invitation'

  if (isMy) {
    text = 'My Generate Invitation'
  }

  return (
    <>
      {/* color="info" onClick={() => handleMyInvitation()} */}
      <div>
        <div>
          <Button
            color="info"
            size="sm"
            id={`Popover${id}`}
            type="button"
            disabled={loading}
            onClick={() => handleMyInvitation(id)}
            style={{
              maxWidth: text ? 'unset' : '100px',
              whiteSpace: 'unset',
              marginTop: '5px'
            }}
          >
            {loading ? 'Loading...' : text}
          </Button>
          <Popover
            placement="bottom"
            isOpen={popoverOpen}
            target={`Popover${id}`}
            toggle={handleCancelPopover}
          >
            <PopoverHeader>Referral code</PopoverHeader>
            <PopoverBody>
              <div>{referralLink}</div>
              <div style={{ textAlign: 'right' }}>
                <Button
                  size="sm"
                  style={{ marginRight: '5px' }}
                  onClick={() => handleCancelPopover()}
                >
                  Cancel
                </Button>
                <Button size="sm" color="info" onClick={() => handleCopyLink()}>
                  Copy
                </Button>
              </div>
            </PopoverBody>
          </Popover>
        </div>
      </div>
    </>
  )
}

ReferralCode.propTypes = {
  /* array of company list */
  id: PropTypes.number,
  isMy: PropTypes.bool
}

export default ReferralCode
