import gql from 'graphql-tag'

/*====== Fragments =====*/
/* user detail */
const userDetailFragment = gql`
  fragment UserDetail on BossjobUserType {
    id
    email
    firstName
    lastName
    mobileCountryId
    phoneNum
    avatar
    activeKey
    statusKey
    isSubscribe
    description
    locationKey
    countryKey
    birthdate
    isProfileCompleted
    isCompanyAdmin
    noticePeriodId
    xpLvlKey
    workingSince
    isEmailVerify
    isMobileVerified
    resumes {
      id
      url
      filename
    }
    lastActiveAt
  }
`

/* work experience */
const workXpsFragment = gql`
  fragment WorkXpsObject on JobseekerWorkXpsObj {
    id
    companyId
    company
    jobTitle
    jobTitleId
    workingPeriodFrom
    workingPeriodTo
    isCurrentlyWorkHere
    locationKey
    countryKey
    description
    companyIndustryKey
    companyIndustry
    salary
    categories {
      id
      key
      value
    }
    skills {
      skillId
      value
      skillListId
    }
  }
`

/* Chat Message Fragment */
const chatMessageFragment = gql`
  fragment chatMessageFields on UserDialogueObjectType {
    id
    jobApplicationId
    anotherUserId
    statusId
    initiatedRoleId
    createdAt
    updatedAt
  }
`

/* Social Link Fragment */
const socialLinkFragment = gql`
  fragment socialLinkFields on SocialLinksObj {
    socialType
    username
  }
`

/* Transaction List Fragment */
const transactionFragment = gql`
  fragment transactionFields on TransactionType {
    id
    referenceNum
    userId
    typeId
    type
    statusId
    status
    jobPost
    unlockTalentCredit
    rhTalentCredit
    featureJobCredit
    urgentJobCredit
    bosspoint
    isServiceGuaranteed
    isOnlineSupported
    isCredited
    amount
    paymentKey
    itemName
    itemDescription
    billerEmail
    billerFirstName
    billerLastName
    billerAddress
    billerCompany
    billerZipcode
    billerState
    billerCity
    billerCountry
    invoiceNum
    invoiceUrl
    receiptNum
    receiptUrl
    remark
    paidAt
    expiredAt
    createdAt
    updatedAt
    onlineTransaction {
      id
      transactionId
      status
      methodId
      paymentMethod
      paypalTransaction {
        paymentUrl
      }
      remark
      createdAt
      updatedAt
    }
    offlineTransaction {
      id
      transactionId
      status
      methodId
      remark
      createdAt
      updatedAt
    }
  }
`

/* Subscription  Fragment */
const subscriptionFragment = gql`
  fragment subscriptionFields on SubscriptionType {
    id
    userId
    user {
      id
      email
    }
    paymentKey
    paymentTypeId
    paymentType
    name
    description
    frequency
    amount
    jobPost
    unlockTalentCredit
    rhTalentCredit
    featureJobCredit
    urgentJobCredit
    bosspoint
    isServiceGuaranteed
    isOnlineSupported
    remark
    totalBilledAmount
    cycle
    transactionHistories {
      transactionId
      amount
      isPaid
      paidAt
      createdAt
    }
    creditHistories {
      cycle
      jobPost
      unlockTalentCredit
      rhTalentCredit
      featureJobCredit
      urgentJobCredit
      createdAt
    }
    isActive
    nextPaymentAt
    lastPaymentAt
    createdAt
    updatedAt
  }
`

const subscriptionPlanFragment = gql`
  fragment subscriptionPlanFields on SubscriptionPlanType {
    id
    categoryId
    name
    category
    monthlyFee
    annualFee
    monthlyJobCredit
    monthlyFeatureJobCredit
    monthlyUrgentJobCredit
    monthlyUnlockTalentCredit
    monthlyRhTalentCredit
    maxNoOfScreeningQuestionsPerJob
    maxNoOfEmailsToReceiveResumePerJob
    featureJob
    urgentJob
    redirectExternalUrl
    jobSalaryMasking
    createdAt
    updatedAt
  }
`

/*====== Local State ======*/
/* Bossjob user */
export const GET_LOCAL_BOSSJOB_USER_DETAIL = gql`
  {
    bossjobUserDetail @client {
      ...UserDetail
    }
  }
  ${userDetailFragment}
`

/* Credit Modal Local State */
export const GET_LOCAL_CREDIT_MODAL_DATA = gql`
  {
    creditModal @client {
      isVisible
      modalData {
        header
        userId
        remark
        typeId
        value
      }
    }
  }
`

/* Bossjob user social link */
export const GET_LOCAL_BOSSJOB_USER_SOCIAL_LINK = gql`
  {
    bossjobUserSocialLinks @client {
      ...socialLinkFields
    }
  }
  ${socialLinkFragment}
`

/*====== Query ======*/
/** Get all Bossjob users */
export const GET_ALL_BOSSJOB_USERS = gql`
  query getAllUsers(
    $page: Int
    $query: String
    $date: DateTimeRange
    $activeKey: Int
  ) {
    allBossjobUsers(
      size: 15
      page: $page
      queryData: $query
      date: $date
      activeKey: $activeKey
    ) {
      data {
        users {
          id
          email
          firstName
          lastName
          phoneNum
          statusKey
          activeKey
          createdAt
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

/** Get Bossjob user by User ID */
export const GET_BOSSJOB_USER_BY_ID = gql`
  query getBossJobUserByID($userId: Int!) {
    bossjobUser(userId: $userId) {
      data {
        ...UserDetail
      }
    }
  }
  ${userDetailFragment}
`

/** Get work experiences of user by User ID */
export const GET_USER_WORK_XPS = gql`
  query getWorkXps($userId: Int!) {
    workXps(userId: $userId) {
      data {
        ...WorkXpsObject
      }
    }
  }
  ${workXpsFragment}
`

/** Get educations of user by User ID */
export const GET_USER_EDUCATIONS = gql`
  query getEducations($userId: Int!) {
    educations(userId: $userId) {
      data {
        id
        school
        degreeKey
        fieldOfStudy
        studyPeriodFrom
        studyPeriodTo
        locationKey
        countryKey
        isCurrentlyStudying
      }
    }
  }
`

/** Get social links of user by User ID */
export const GET_USER_SOCIAL_LINKS = gql`
  query getSocialLinks($userId: Int!) {
    socialLinks(userId: $userId) {
      data {
        ...socialLinkFields
      }
    }
  }
  ${socialLinkFragment}
`

/** Get skills of user by User ID */
export const GET_BOSSJOB_USER_SKILLS = gql`
  query getSkills($userId: Int!) {
    bossjobUser(userId: $userId) {
      data {
        id
        skills {
          jobSkills
        }
      }
    }
  }
`

/** Get job preference of user by User ID */
export const GET_BOSSJOB_USER_JOB_PREFERENCE = gql`
  query getJobPreference($userId: Int!) {
    preferences(userId: $userId) {
      data {
        id
        jobTitle
        jobTitleId
        industryKey
        jobTypeKey
        locationKey
        countryKey
        salaryRangeFrom
        salaryRangeTo
        salaryTypeId
        dailySalaryRangeFrom
        dailySalaryRangeTo
        hourlySalaryRangeFrom
        hourlySalaryRangeTo
        currencyId
        locationId
        prefRegionId
        prefCountryId
      }
    }
  }
`

/** Get recruiter settings */
export const GET_BOSSJOB_RECRUITER_SETTINGS = gql`
  query getRecruiterSettings($userId: Int!) {
    recruiterSettings(userId: $userId) {
      data {
        company
        companyId
        statusKey
        jobTitle
        contactEmail
        isJobSalaryHidden
        autoReply
      }
    }
  }
`

/** Get recruiter verification status */
export const GET_BOSSJOB_RECRUITER_VERIFY_STATUS = gql`
  query getBossJobUserByID($userId: Int!) {
    bossjobUser(userId: $userId) {
      data {
        isVerify
      }
    }
  }
`
/** Get recruiter's free, paid and trial job post balances */
export const GET_JOB_POST_BALANCE_OF_RECRUITER = gql`
  query getJobPostBalanceByID($userId: Int!, $page: Int) {
    jobPostBalance(userId: $userId, page: $page) {
      data {
        balances {
          id
          postedJobId
          typeId
          remark
          expiredAt
          createdAt
        }
        totalPages
        totalNum
        currentPage
        currentNum
        availableTrialJobPosts
        availablePaidJobPosts
        availableFreeJobPosts
      }
    }
  }
`

export const GET_JOB_POST_HISTORY_OF_RECRUITER = gql`
  query getJobPostHistoryByID($userId: Int!, $page: Int) {
    jobPostHistory(userId: $userId, page: $page) {
      data {
        histories {
          id
          recruiterId
          jobId
          jobPost
          fromPost
          toPost
          remark
          createdAt
          updatedAt
        }
        totalPages
        totalNum
        currentPage
        currentNum
        availableTrialJobPosts
        availablePaidJobPosts
        availableFreeJobPosts
      }
      message
    }
  }
`

/** Get recruiter chat and job credits */
export const GET_BOSSJOB_CHAT_AND_JOB_CREDITS = gql`
  query getChatJobCreditByID($userId: Int!) {
    userCreditHistory(userId: $userId) {
      data {
        histories {
          id
          userId
          remark
          createdAt
          updatedAt
        }
        totalPages
        totalNum
        currentPage
        currentNum
        unlockTalentCredits
        featureJobCredits
        urgentJobCredits
        roboHeadhuntTalentCredits
      }
    }
  }
`

/** Get credit usage history of a recruiter */
export const GET_CREDIT_HISTORY_OF_RECRUITER = gql`
  query getCreditHistoryByID($userId: Int!, $page: Int) {
    userCreditHistory(userId: $userId, page: $page) {
      data {
        histories {
          id
          userId
          remark
          createdAt
          updatedAt
        }
        totalPages
        totalNum
        currentPage
        currentNum
        unlockTalentCredits
        featureJobCredits
        urgentJobCredits
        roboHeadhuntTalentCredits
      }
    }
  }
`

/** Get invoices list of a recruiter */
export const GET_INVOICES_OF_RECRUITER = gql`
  query getInvoicesByID($userId: Int!, $page: Int) {
    userInvoices(userId: $userId, page: $page) {
      data {
        invoices {
          id
          paymentKey
          title
          price
          paymentStatus
          notes
          createdAt
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

/** Get transaction list of a recruiter */
export const GET_TRANSACTIONS_OF_RECRUITER = gql`
  query getTransactionsByID(
    $size: Int
    $page: Int
    $userId: Int
    $query: String
    $createdDate: TransactionDateTimeRange
    $paidDate: TransactionDateTimeRange
    $typeId: Int
    $statusId: Int
  ) {
    transactionList(
      size: $size
      page: $page
      userId: $userId
      query: $query
      createdDate: $createdDate
      paidDate: $paidDate
      typeId: $typeId
      statusId: $statusId
    ) {
      ok
      message
      data {
        totalPages
        totalNum
        currentPage
        currentNum
        transactions {
          ...transactionFields
        }
      }
    }
  }
  ${transactionFragment}
`

/** Get recruiter's subscription history */
export const GET_SUBSCRIPTIONS_OF_RECRUITER = gql`
  query getSubscriptionPlanByID(
    $size: Int
    $page: Int
    $userId: Int
    $isActive: Boolean
  ) {
    subscriptionList(
      size: $size
      page: $page
      userId: $userId
      isActive: $isActive
    ) {
      ok
      message
      data {
        totalPages
        totalNum
        currentPage
        currentNum
        subscriptions {
          ...subscriptionFields
        }
      }
    }
  }
  ${subscriptionFragment}
`

// /** Get recruiter's current subscription plan */
// export const GET_SUBSCRIPTIONS_OF_RECRUITER = gql`
//   query getSubscriptionPlanByID(
//     $subscriptionId: Int
//   ) {
//     subscription(
//       subscriptionId: $subscriptionId
//     ) {
//       ok
//       message
//       data {
//         id
//         name
//         description
//         amount
//       }
//     }
//   }
// `

/** Get Bosspoint of a Bossjob user */
export const GET_BOSSPOINT_HISTORY_BY_ID = gql`
  query getBosspointHistoryByID($userId: Int!, $page: Int) {
    userPoints(userId: $userId, page: $page) {
      data {
        points {
          id
          title
          key
          point
          notes
          createdAt
        }
        totalPoints
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

/** Get job list of a Bossjob recruiter */
export const GET_RECRUITER_JOB_LIST_BY_ID = gql`
  query getJobListByID(
    $userId: Int!
    $page: Int
    $size: Int
    $queryData: String
    $statusKey: String
    $date: RecruiterJobsDateTimeRange
    $countryId: Int
  ) {
    recruiterJobs(
      recruiterId: $userId
      page: $page
      size: $size
      queryData: $queryData
      statusKey: $statusKey
      date: $date
      countryId: $countryId
    ) {
      data {
        jobs {
          id
          jobTitle
          noOfViews
          noOfApplications
          countryId
          statusKey
          createdAt
          updatedAt
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
`

/** Get Bossjob recruiter's job posted statistics */
export const GET_RECRUITER_JOB_STATISTICS = gql`
  query recruiterJobsStat(
    $userId: Int!
    $page: Int!
    $date: RecruiterJobStatDateTimeRange
    $queryData: String
  ) {
    recruiterJobsStat(
      recruiterId: $userId
      page: $page
      date: $date
      queryData: $queryData
    ) {
      data {
        jobStats {
          jobId
          jobTitle
          statusKey
          jobActivityScore
          publishedAt
          expiredAt
          createdAt
          deletedAt
          totalViews
          totalApplications
          totalInterviewed
          totalHired
          totalNotSuitableInterview
          totalNotSuitableApplication
          initiatedByJobseeker
          initiatedByRecruiter
          totalInProgress
        }
        size
        page
        totalPages
        totalActiveJobs
        totalJobViews
        totalJobApplications
        totalCount
      }
    }
  }
`

/** Get Bossjob recruiter's subscription plan */
export const GET_RECRUITER_SUBSCRIPTION_PLAN_DETAIL = gql`
  query userSubscriptionPlan($userId: Int!) {
    userSubscriptionPlan(userId: $userId) {
      data {
        ...subscriptionPlanFields
      }
    }
  }
  ${subscriptionPlanFragment}
`

/** Get Chat Message list of Bossjob User */
export const GET_BOSSJOB_USER_CHAT_LIST_BY_ID = gql`
  query getBossJobUserChatListByID(
    $userId: Int!
    $userType: Int!
    $page: Int!
    $queryData: String
    $date: UserDialogueDateTimeRange
  ) {
    userDialogues(
      userId: $userId
      userType: $userType
      page: $page
      queryData: $queryData
      date: $date
      size: 15
    ) {
      data {
        dialogues {
          ...chatMessageFields
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
  ${chatMessageFragment}
`

/** Get blacklist user of a Bossjob user */
export const GET_BLACKLIST_USERS_BY_ID = gql`
  query getBlacklistUsersByID($userId: Int!) {
    blacklistUser(userId: $userId) {
      data {
        id
        createdAt
        blacklistedUser {
          ...UserDetail
        }
      }
    }
  }
  ${userDetailFragment}
`
/** Get Email Logs of a Bossjob user */
export const GET_OTP_EMAIL_LOGS = gql`
  query getEmailLogs($page: Int!, $size: Int!, $userId: Int!) {
    getEmailLogs(page: $page, size: $size, userId: $userId) {
      ok
      message
      data {
        currentPage
        totalCount
        totalPages
        emailLogs {
          id
          email
          status
          sendgridTemplateId
          createdAt
          updatedAt
        }
      }
    }
  }
`
