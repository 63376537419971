import React from 'react'
import PropTypes from 'prop-types'

/* Component */
import { Table } from 'reactstrap'
import SectionWrapper from 'shared/components/SectionWrapper'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

const SubscriptionTransactionHistory = ({ transactionHistories }) => (
  <SectionWrapper sectionTitle="Transaction History">
    <Table bordered>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>Cycle</th>
          <th style={{ textAlign: 'center' }}>Transaction ID </th>
          <th style={{ textAlign: 'center' }}>Amount</th>
          <th style={{ textAlign: 'center' }}>Created At</th>
          <th style={{ textAlign: 'center' }}>Paid</th>
          <th style={{ textAlign: 'center' }}>Paid At</th>
        </tr>
      </thead>
      <tbody>
        {transactionHistories &&
          transactionHistories.map((cycleHistory, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'center' }}>{index + 1}</td>
              <td style={{ textAlign: 'center' }}>
                <a
                  href={`/bossjob/transaction/detail/${
                    cycleHistory.transactionId
                  }`}
                >
                  {cycleHistory.transactionId}
                </a>
              </td>
              <td style={{ textAlign: 'center' }}>{cycleHistory.amount}</td>
              <td style={{ textAlign: 'center' }}>
                {formatDate(cycleHistory.createdAt, 'DD/MM/YYYY HH:mm:Ss')}
              </td>
              <td style={{ textAlign: 'center' }}>
                {cycleHistory.isPaid ? 'Yes' : 'No'}
              </td>
              <td style={{ textAlign: 'center' }}>
                {cycleHistory.paidAt
                  ? formatDate(cycleHistory.paidAt, 'DD/MM/YYYY HH:mm:Ss')
                  : '-'}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  </SectionWrapper>
)

SubscriptionTransactionHistory.propTypes = {
  transactionHistories: PropTypes.array.isRequired
}

export default SubscriptionTransactionHistory
