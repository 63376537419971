import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Mutation } from 'react-apollo'
import { MY_ACCOUNT_MUTATION } from '../../../schemas/mutations'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap'

/**
 * MyAccountForm - form component for MyAccount page
 *
 * @returns {Component}
 */
class MyAccountForm extends Component {
  static propTypes = {
    /* user data */
    user: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)

    this.state = {
      name: props.user.name,
      email: props.user.email,
      currentPassword: '',
      newPassword: '',
      alert: false,
      error: false
    }

    this.editAccount = this.editAccount.bind(this)
  }

  editAccount(accountMutation) {
    if (this.state.currentPassword !== '' && this.state.newPassword === '') {
      // validate if user enter currentPassword, must always enter newPassword
      this.setState({
        error: 'Please enter the new password you wish to change to'
      })
      setTimeout(() => this.setState({ error: false }), 2000)
    } else {
      let payload = {
        name: this.state.name
      }
      if (this.state.currentPassword !== '' && this.state.newPassword !== '') {
        payload = {
          name: this.state.name,
          password: {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword
          }
        }
      }

      accountMutation({
        variables: {
          payload: payload
        }
      })
    }
  }

  render() {
    const { name, email, currentPassword, newPassword } = this.state
    return (
      <Form>
        <FormGroup>
          <Label>Name</Label>
          <Input
            name="name"
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input name="email" disabled value={email} />
        </FormGroup>
        <FormGroup>
          <Label>Current Password (only enter if changing)</Label>
          <Input
            type="password"
            name="currentPassword"
            value={currentPassword}
            onChange={e => this.setState({ currentPassword: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label>New Password</Label>
          <Input
            type="password"
            name="newPassword"
            value={newPassword}
            onChange={e => this.setState({ newPassword: e.target.value })}
          />
        </FormGroup>
        {this.state.alert && (
          <Alert>Your account has been successfully updated</Alert>
        )}
        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}

        {/* Call graphQL mutation onClick*/}
        <Mutation
          mutation={MY_ACCOUNT_MUTATION}
          onCompleted={() => {
            this.setState({ alert: true, currentPassword: '', newPassword: '' })
            setTimeout(() => this.setState({ alert: false }), 1700)
          }}
          onError={error => {
            console.log(error)
            if (error.graphQLErrors.length > 0) {
              this.setState({
                error:
                  'Your password is incorrect! Please make sure you enter the correct current password before changing new password.',
                currentPassword: '',
                newPassword: ''
              })
              setTimeout(() => this.setState({ error: false }), 2000)
            }
          }}
        >
          {(AccountSettingMutation, { loading }) => (
            <div style={{ textAlign: 'right' }}>
              <Button
                color="info"
                onClick={() => this.editAccount(AccountSettingMutation)}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          )}
        </Mutation>
      </Form>
    )
  }
}

export default MyAccountForm
