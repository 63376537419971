import React, { Component } from 'react'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_USER_EDUCATIONS,
  GET_LOCAL_BOSSJOB_USER_DETAIL
} from '../../../../schemas/queries'
import {
  DELETE_BOSSJOB_USER_EDUCATION,
  EDIT_BOSSJOB_USER_EDUCATION,
  ADD_BOSSJOB_USER_EDUCATION
} from '../../../../schemas/mutations'

/* Bootstrap */
import { Col, Card, CardTitle, CardBody, Alert } from 'reactstrap'

/* Components */
import EducationForm from './EducationForm'
import Loading from 'shared/components/Loading'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Assets */
import styles from 'shared/assets/scss/main.module.scss'

/**
 * Educations - main wrapper component for the Profile page - Education section
 *
 * @returns {Component}
 */
class Educations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      messageObj: {
        isVisible: false,
        error: false,
        message: ''
      },
      refetch: null,
      addingEducation: false
    }

    this.handleDelete = this.handleDelete.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
  }

  /**
   *
   * @function handleDelete - asynchronous delete function to call GraphQL mutation
   * which will refetch and show success message after delete is done
   *
   * @param {*} apolloClient
   * @param {String|number} userId
   * @param {String|number} educationId
   */
  async handleDelete(apolloClient, userId, educationId) {
    let response = await apolloClient.mutate({
      mutation: DELETE_BOSSJOB_USER_EDUCATION,
      variables: {
        userId: userId,
        educationId: educationId
      }
    })

    if (response) {
      this.state.refetch()
      this.handleMessage(
        'You have successfully deleted the selected education!'
      )
    }
  }

  /**
   *
   * @function handleEdit - asynchronous edit function to call GraphQL mutation
   * which will refetch and show success message after edit is done
   *
   * @param {*} apolloClient
   * @param {String|number} userId
   * @param {Object} payload
   */
  async handleEdit(apolloClient, userId, payload) {
    let response = await apolloClient.mutate({
      mutation: EDIT_BOSSJOB_USER_EDUCATION,
      variables: {
        userId: userId,
        payload: payload
      }
    })

    if (response) {
      this.state.refetch()
      this.handleMessage('You have successfully edited the education!')
    }
  }

  /**
   *
   * @function handleAdd - asynchronous add function to call GraphQL mutation
   * which will refetch and show success message after add is done
   *
   * @param {*} apolloClient
   * @param {String|number} userId
   * @param {Object} payload
   */
  async handleAdd(apolloClient, userId, payload) {
    let response = await apolloClient.mutate({
      mutation: ADD_BOSSJOB_USER_EDUCATION,
      variables: {
        userId: userId,
        payload: payload
      }
    })

    if (response) {
      this.cancelAdding()
      this.state.refetch()
      this.handleMessage(
        'You have successfully added a new education for the user!'
      )
    }
  }

  /**
   *
   * @function cancelAdding - to remove the empty form
   *
   */
  cancelAdding() {
    this.setState({ addingEducation: false })
  }

  /**
   *
   * @function renderEducationForm - render the education form with value for each education
   *
   * @param {Array} educations
   * @param {String|number} userId
   *
   */
  renderEducationForm(educations, userId, userStatusKey) {
    return educations.map(edu => (
      <EducationForm
        education={edu}
        userId={Number(userId)}
        key={`experience-${edu.id}`}
        handleDelete={this.handleDelete}
        handleEdit={this.handleEdit}
        isInactive={userStatusKey === 'inactive'}
      />
    ))
  }

  /**
   *
   * @function handleMessage - setting message
   *
   * @param {String} message
   * @param {Boolean} isError
   *
   */
  handleMessage(message, isError = false) {
    this.setState({
      messageObj: {
        isVisible: true,
        error: isError,
        message: message
      }
    })

    setTimeout(
      () =>
        this.setState({
          messageObj: {
            isVisible: false,
            error: false,
            message: ''
          }
        }),
      1700
    )
  }

  render() {
    return (
      <div>
        <Col md="12">
          <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
            {({ data: { bossjobUserDetail } }) => (
              <Card>
                <CardTitle className={styles.sectionTitle}>
                  Educations
                  {bossjobUserDetail.statusKey !== 'inactive' && (
                    <Can I="edit" a="bossjob_user">
                      <div
                        className={styles.addButton}
                        onClick={() => this.setState({ addingEducation: true })}
                      >
                        +
                      </div>
                    </Can>
                  )}
                </CardTitle>
                <CardBody>
                  {this.state.addingEducation && (
                    <EducationForm
                      userId={Number(bossjobUserDetail.id)}
                      handleAdd={this.handleAdd}
                      handleDelete={this.cancelAdding}
                      new
                    />
                  )}
                  <Query
                    query={GET_USER_EDUCATIONS}
                    variables={{ userId: bossjobUserDetail.id }}
                    fetchPolicy="network-only"
                  >
                    {({ loading, data, error, refetch }) => {
                      if (this.state.refetch === null) {
                        this.setState({ refetch: refetch })
                      }

                      if (error) {
                        console.log(error)
                      }
                      if (loading) {
                        return <Loading />
                      }

                      if (data && data.educations) {
                        if (data.educations.data) {
                          return this.renderEducationForm(
                            data.educations.data,
                            Number(bossjobUserDetail.id),
                            bossjobUserDetail.statusKey
                          )
                        }

                        return <div>There is no educations for this user</div>
                      }

                      return null
                    }}
                  </Query>
                </CardBody>
              </Card>
            )}
          </Query>
          {this.state.messageObj.isVisible && (
            <Alert color={this.state.messageObj.error ? 'danger' : 'success'}>
              {this.state.messageObj.message}
            </Alert>
          )}
        </Col>
      </div>
    )
  }
}

export default Educations
