import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const PopUpMessage = ({ isVisible, message, closeModal, error }) => (
  <Modal isOpen={isVisible}>
    <ModalHeader toggle={closeModal}>
      {error ? 'Oops!' : 'Please read'}
    </ModalHeader>
    <ModalBody>
      <span style={{ color: error ? 'red' : 'black' }}>{message}</span>
    </ModalBody>
    <ModalFooter>
      <Button color="info" onClick={() => closeModal()}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
)

PopUpMessage.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired
}

PopUpMessage.defaultProps = {
  isVisible: false,
  error: false
}

export default PopUpMessage
