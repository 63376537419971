import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Bootstrap */
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge
} from 'reactstrap'

/* Vendors */
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Config */
import CONFIG from 'config'

/* Helpers */
import { convertToISOFormat } from 'shared/helpers/datetime'

/* Assets */
import mainStyles from 'shared/assets/scss/main.module.scss'
import styles from './WorkExperienceForm.module.scss'

import SearchWithSuggestion from 'shared/components/SearchWithSuggestion'

/**
 * WorkExperienceForm - form component for BossJobUserDetail - Resume page
 *
 * @returns {Component}
 */
class WorkExperienceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      location:
        props.workXps && props.workXps.locationKey
          ? props.workXps.locationKey
          : 'manila',
      country:
        props.workXps && props.workXps.countryKey
          ? props.workXps.countryKey
          : 'ph',
      jobTitle:
        props.workXps && props.workXps.jobTitle ? props.workXps.jobTitle : '',
      company:
        props.workXps && props.workXps.company ? props.workXps.company : '',
      startDate:
        props.workXps && props.workXps.workingPeriodFrom
          ? props.workXps.workingPeriodFrom
          : new Date(),
      endDate:
        props.workXps &&
        props.workXps.workingPeriodTo &&
        props.workXps.workingPeriodTo !== '0000-00-00'
          ? props.workXps.workingPeriodTo
          : new Date(),
      isCurrentlyWorkHere:
        props.workXps && props.workXps.isCurrentlyWorkHere
          ? props.workXps.isCurrentlyWorkHere
          : false,
      description:
        props.workXps && props.workXps.description
          ? props.workXps.description
          : '',
      companyList: [],
      companyIndustryKey:
        props.workXps && props.workXps.companyIndustryKey
          ? props.workXps.companyIndustryKey
          : props.configList.industry_lists[0].key,
      salary:
        props.workXps && props.workXps.salary ? props.workXps.salary : null,
      jobCategories:
        props.workXps && props.workXps.categories
          ? props.workXps.categories.map(cat => ({ category: cat }))
          : [
            {
              category: props.configList.job_category_lists[0]
            }
          ],
      jobTitleId:
        props.workXps && props.workXps.jobTitleId
          ? props.workXps.jobTitleId
          : '',
      jobFunctionQuery: '',
      skills:
        props.workXps && props.workXps.skills
          ? props.workXps.skills.map(skill => skill.value)
          : []
    }

    this.manageExperience = this.manageExperience.bind(this)
  }

  /**
   *
   * @function manageExperience - manage work experience function to consolidate payload
   * and checking whether the user is editing or adding work experience before passing
   * to the related GraphQL mutation
   *
   * @param {*} apolloClient
   */
  manageExperience(apolloClient) {
    let payload = {
      company: this.state.company,
      jobTitle: this.state.jobTitle,
      workingPeriodFrom: convertToISOFormat(
        new Date(this.state.startDate)
      ).toString(),
      workingPeriodTo: this.state.isCurrentlyWorkHere
        ? null
        : convertToISOFormat(new Date(this.state.endDate)).toString(),
      isCurrentlyWorkHere: this.state.isCurrentlyWorkHere ? 1 : 0,
      description: this.state.description,
      locationKey: this.state.location,
      countryKey: this.state.country,
      companyIndustryKey: this.state.companyIndustryKey,
      salary: this.state.salary,
      skills: this.state.skills ? this.state.skills.join(',') : '',
      jobTitleId: this.state.jobTitleId
    }

    if (this.props.workXps && this.props.workXps.id) {
      payload.workXpsId = this.props.workXps.id
      this.props.editSuccess(apolloClient, this.props.userId, payload)
    } else {
      this.props.addWorkExperience(apolloClient, this.props.userId, payload)
    }
  }

  /**
   *
   * @function handleSearch - to search and show list of companies that recruiter can join
   * @param {String} query
   */
  handleSearch(query) {
    this.setState({
      company: query
    })

    if (query !== '') {
      fetch(
        `${
          CONFIG.COMPANY_SERVICE_URL
        }/search-suggestion?query=${query}&size=6&query_field=name`
      )
        .then(response => response.json())
        .then(data => {
          this.setState({
            companyList: data.data.items
          })
        })
    } else {
      this.setState({
        companyList: []
      })
    }
  }

  /**
   *
   * @function handleClick - handle click on selected company from list
   * @param {Object} company
   */
  handleClick(company) {
    this.setState({
      company: company,
      companyList: []
    })
  }

  // handleCategoriesChange = (categoryId, action, index) => {
  //   const {
  //     options: {
  //       inputs: { job_category_lists }
  //     }
  //   } = this.props
  //   categoryId = Number(categoryId)
  //   if (action === 'add') {
  //     this.setState(prevState => ({
  //       jobCategories: [
  //         ...prevState.jobCategories,
  //         { category: job_category_lists.find(cat => cat.id === categoryId) }
  //       ]
  //     }))
  //   } else if (action === 'edit') {
  //     this.setState(prevState => {
  //       const newJobCategories = [...prevState.jobCategories]
  //       newJobCategories[index] = {
  //         category: job_category_lists.find(cat => cat.id === categoryId)
  //       }
  //       return {
  //         jobCategories: newJobCategories
  //       }
  //     })
  //   } else if (action === 'delete') {
  //     this.setState(prevState => {
  //       const newJobCategories = [...prevState.jobCategories]
  //       newJobCategories.splice(index, 1)
  //       return {
  //         jobCategories: newJobCategories
  //       }
  //     })
  //   }

  //   return null
  // }

  updateSkillList = skill => {
    let list = this.state.skills
    if (list.length > 9) {
      this.setState({ errorMessage: 'Only a maximum of 10 skills allowed' })
    } else {
      list.push(skill)
      this.setState({ skills: list })
    }
  }

  removeSkill(index) {
    let list = this.state.skills
    list.splice(index, 1)
    this.setState({ skills: list })
  }

  render() {
    const {
      location,
      country,
      jobTitle,
      company,
      startDate,
      endDate,
      isCurrentlyWorkHere,
      description,
      companyList,
      // jobCategories,
      salary,
      companyIndustryKey,
      jobTitleId,
      jobFunctionQuery,
      skills
    } = this.state

    const { configList } = this.props

    return (
      <Form className={styles.formWrapper}>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Job Function Name
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="text"
                  name="jobFunctionQuery"
                  id="jobFunctionQuery"
                  placeholder="Enter job function title here to filter"
                  value={jobFunctionQuery}
                  onChange={e =>
                    this.setState({ jobFunctionQuery: e.target.value })
                  }
                />
                <Input
                  type="select"
                  name="jobTitleId"
                  id="jobTitleId"
                  value={jobTitleId}
                  onChange={e =>
                    this.setState({
                      jobTitleId: e.target.value
                    })
                  }
                >
                  {configList.main_job_function_lists &&
                    configList.main_job_function_lists.map(mainFunctionObj => {
                      const mainFunction = mainFunctionObj.value
                      const subFunctionObjs = mainFunctionObj.sub_function_list
                      const filteredJobTitles =
                        jobFunctionQuery !== ''
                          ? subFunctionObjs.flatMap(subFunctionObj =>
                            subFunctionObj.job_titles.filter(
                              jobTitle =>
                                jobTitle.value
                                  .toLowerCase()
                                  .includes(jobFunctionQuery.toLowerCase()) ||
                                  jobTitle.id == jobTitleId
                            )
                          )
                          : subFunctionObjs.flatMap(
                            subFunctionObj => subFunctionObj.job_titles
                          )

                      return subFunctionObjs.map((subFunctionObj, i) => {
                        return subFunctionObj.job_titles.map(
                          (jobTitle, idx) => {
                            return (
                              <React.Fragment key={`jobFunction_${i}_${idx}`}>
                                {i === 0 &&
                                  idx === 0 &&
                                  jobTitleId === '' && (
                                  <option key={'not_selected'} value={''}>
                                      Not selected
                                  </option>
                                )}
                                {idx === 0 &&
                                  subFunctionObj.job_titles.some(title =>
                                    title.value
                                      .toLowerCase()
                                      .includes(jobFunctionQuery.toLowerCase())
                                  ) && (
                                  <option
                                    key={`subFunction_${
                                      subFunctionObj.value
                                    }`}
                                    disabled
                                  >
                                    {mainFunction} - {subFunctionObj.value}
                                  </option>
                                )}
                                {filteredJobTitles.includes(jobTitle) ? (
                                  <option
                                    key={`jobTitleId_${jobTitle.id}`}
                                    value={jobTitle.id}
                                  >
                                    {jobTitle.value}
                                  </option>
                                ) : null}
                              </React.Fragment>
                            )
                          }
                        )
                      })
                    })}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Job Title
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  name="jobTitle"
                  value={jobTitle}
                  onChange={e => this.setState({ jobTitle: e.target.value })}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Company
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  name="company"
                  value={company}
                  autoComplete="off"
                  onChange={e => {
                    this.handleSearch(e.target.value)
                  }}
                />
                {companyList &&
                  companyList.length !== 0 && (
                  <div className={mainStyles.searchList}>
                    <ul>
                      {companyList.map(company => (
                        <li
                          key={`company-${company}`}
                          onClick={() => this.handleClick(company)}
                        >
                          {company}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Location
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="select"
                  name="location"
                  value={location}
                  onChange={e => this.setState({ location: e.target.value })}
                >
                  {configList.country_lists &&
                    configList.country_lists.flatMap(({ region_lists }) =>
                      region_lists.flatMap(({ location_lists, display_name }) =>
                        location_lists.map(location => (
                          <option
                            key={`location-${location.id}`}
                            value={location.key}
                          >
                            {location.value} ({display_name})
                          </option>
                        ))
                      )
                    )}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Country
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="select"
                  name="country"
                  value={country}
                  onChange={e => this.setState({ country: e.target.value })}
                  disabled={location !== 'overseas' || this.props.isInactive}
                >
                  {configList.country_lists &&
                    configList.country_lists.map((country, index) => (
                      <option key={`country-${index}`} value={country.key}>
                        {country.value}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Start
              </Label>
              <Col md={9} sm={12}>
                <DateTimePicker
                  disabled={this.props.isInactive}
                  format="YYYY-MM-DD"
                  time={false}
                  value={new Date(startDate)}
                  defaultValue={new Date()}
                  onChange={newDate => this.setState({ startDate: newDate })}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                End
              </Label>
              <Col md={9} sm={12}>
                <DateTimePicker
                  format="YYYY-MM-DD"
                  time={false}
                  value={new Date(endDate)}
                  defaultValue={new Date()}
                  disabled={
                    isCurrentlyWorkHere || this.props.isInactive ? true : false
                  }
                  onChange={newDate => this.setState({ endDate: newDate })}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Industry
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="select"
                  name="industry"
                  value={companyIndustryKey}
                  onChange={e =>
                    // handleChange(client, 'industryKey', e.target.value)
                    this.setState({ companyIndustryKey: e.target.value })
                  }
                >
                  {configList.industry_lists &&
                    configList.industry_lists.map((industry, index) => (
                      <option key={`industry-${index}`} value={industry.key}>
                        {industry.value}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Salary
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="number"
                  name="salary"
                  value={salary}
                  onChange={e => this.setState({ salary: e.target.value })}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Job Category
              </Label>
              <Col md={9} sm={12}>
                {jobCategories.length > 0 &&
                  jobCategories.map((category, index) => (
                    <div
                      style={{ position: 'relative', marginBottom: '10px' }}
                      key={`job-category-${index}`}
                    >
                      {index > 0 && (
                        <div
                          style={{
                            position: 'absolute',
                            left: '-15px',
                            top: '7px',
                            fontWeight: 'bold',
                            cursor: 'pointer'
                          }}
                          onClick={() =>
                            this.handleCategoriesChange(null, 'delete', index)
                          }
                        >
                          x
                        </div>
                      )}
                      <Input
                        type="select"
                        name={`jobCategory-${index}`}
                        id="jobCategory"
                        value={category.category.id}
                        // disabled={isInvalid}
                        onChange={e => {
                          e.preventDefault()
                          this.handleCategoriesChange(
                            e.target.value,
                            'edit',
                            index
                          )
                        }}
                      >
                        {options.inputs &&
                          options.inputs.job_category_lists.map(
                            (jobCategory, index) => {
                              return (
                                <option key={index} value={jobCategory.id}>
                                  {jobCategory.value}
                                </option>
                              )
                            }
                          )}
                      </Input>
                    </div>
                  ))}
                {jobCategories.length < 3 &&
                  options.inputs && (
                  <div
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() =>
                      this.handleCategoriesChange(
                        options.inputs.job_category_lists[0].id,
                        'add'
                      )
                    }
                  >
                      + Add category (up to 3 categories)
                  </div>
                )}
              </Col>
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col md={12}>
            <FormGroup row>
              <Label md={2} sm={12}>
                Currently working here
              </Label>
              <Col md={10} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="checkbox"
                  defaultChecked={isCurrentlyWorkHere}
                  onChange={e =>
                    this.setState({ isCurrentlyWorkHere: e.target.checked })
                  }
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup row>
              <Label md={2} sm={12}>
                Description
              </Label>
              <Col md={10} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  type="textarea"
                  value={description}
                  onChange={e => this.setState({ description: e.target.value })}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={2} sm={12}>
                Skills
              </Label>
              <Col md={10}>
                <SearchWithSuggestion
                  handleClick={this.updateSkillList}
                  searchApi="/search/skill_lists"
                />
                {skills.map((skill, index) => (
                  <div className={styles.skillItem} key={`skill-${index}`}>
                    <Badge style={{ fontSize: '0.9em' }} color="secondary" pill>
                      {skill}
                    </Badge>
                    <span
                      className={styles.delete}
                      onClick={() => this.removeSkill(index)}
                    >
                      x
                    </span>
                  </div>
                ))}
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {!this.props.isInactive && (
          <Can I="edit" a="bossjob_user">
            <ApolloConsumer>
              {client => (
                <div>
                  {this.props.new ? (
                    <React.Fragment>
                      <Button
                        color="danger"
                        onClick={() => this.props.deleteSuccess()}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="info"
                        onClick={() => this.manageExperience(client)}
                      >
                        Add
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          color="danger"
                          onClick={() =>
                            this.props.deleteSuccess(
                              client,
                              this.props.userId,
                              this.props.workXps.id
                            )
                          }
                        >
                          Delete
                        </Button>
                        <Button
                          color="info"
                          onClick={() => this.manageExperience(client)}
                        >
                          Save
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )}
            </ApolloConsumer>
          </Can>
        )}
      </Form>
    )
  }
}

WorkExperienceForm.propTypes = {
  userId: PropTypes.number.isRequired,
  workXps: PropTypes.object,
  deleteSuccess: PropTypes.func,
  editSuccess: PropTypes.func,
  addWorkExperience: PropTypes.func,
  new: PropTypes.bool,
  isInactive: PropTypes.bool,
  configList: PropTypes.object.isRequired
}

export default WorkExperienceForm
