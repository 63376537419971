import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Components */
import ToggleButton from 'shared/components/ToggleButton'

/* Config */
import CONFIG from 'config'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Assets */
import styles from './RecruiterSettingsForm.module.scss'
class RecruiterSettingsForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: props.settings.contactEmail ? props.settings.contactEmail : '',
      jobTitle: props.settings.jobTitle ? props.settings.jobTitle : '',
      company: props.settings.company,
      companyId: props.settings.companyId,
      statusKey: props.settings.statusKey,
      isVerify: props.settings.isVerify,
      isJobSalaryHidden: props.settings.isJobSalaryHidden,
      editExistingJobIsSalaryHidden: props.settings
        .editExistingJobIsSalaryHidden
        ? props.settings.editExistingJobIsSalaryHidden
        : 'no_change',
      autoReply: props.settings.autoReply,
      selectedCompanyId: null,
      companyList: []
    }

    this.updateSetting = this.updateSetting.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  /**
   *
   * @function updateSetting - update recruiter setting function to consolidate the payload
   * before passing in to GraphQL mutation
   */
  updateSetting() {
    const payload = {
      userId: this.props.settings.userId,
      email: this.state.email,
      jobTitle: this.state.jobTitle,
      isVerify: this.state.isVerify,
      isJobSalaryHidden: this.state.isJobSalaryHidden,
      editExistingJobIsSalaryHidden: this.state.editExistingJobIsSalaryHidden
    }

    // If new company has been selected, then need to make recruiter join new company
    if (this.state.selectedCompanyId !== null) {
      const joinCompanyPayload = {
        userId: this.props.settings.userId,
        companyId: this.state.selectedCompanyId,
        jobTitle: this.state.jobTitle,
        workingPeriod: new Date()
      }

      this.props.handleUpdateCompany({ variables: joinCompanyPayload })
    }

    this.props.handleUpdateSetting({ variables: payload })
  }

  /**
   *
   * @function handleSearch - to search and show list of companies that recruiter can join
   * @param {String} query
   */
  handleSearch(query) {
    this.setState({
      company: query
    })

    if (query !== '') {
      fetch(
        `${
          CONFIG.SEARCH_BOSSJOB_URL
        }/search/company_lists?query=${query}&size=6`
      )
        .then(response => response.json())
        .then(data => {
          this.setState({
            companyList: data.data.companies
          })
        })
    } else {
      this.setState({
        companyList: []
      })
    }
  }

  /**
   *
   * @function handleClick - handle click on selected company from list
   * @param {Object} company
   */
  handleClick(company) {
    this.setState({
      selectedCompanyId: company.id,
      company: company.name,
      companyList: []
    })
  }

  render() {
    const {
      jobTitle,
      email,
      company,
      isVerify,
      isJobSalaryHidden,
      editExistingJobIsSalaryHidden,
      autoReply,
      companyList
    } = this.state
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Position:
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  name="jobTitle"
                  value={jobTitle}
                  onChange={e => {
                    this.setState({ jobTitle: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Email to receive resume:
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={this.props.isInactive}
                  name="email"
                  value={email}
                  onChange={e => {
                    this.setState({ email: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Company:
              </Label>
              <Col md={9} sm={12} style={{ position: 'relative' }}>
                <Input
                  disabled={this.props.isInactive}
                  name="company"
                  value={company}
                  autoComplete="off"
                  onChange={e => {
                    this.handleSearch(e.target.value)
                  }}
                />
                {companyList.length !== 0 && (
                  <div className={styles.searchList}>
                    <ul>
                      {companyList.map(company => (
                        <li
                          key={`company-${company.id}`}
                          onClick={() => this.handleClick(company)}
                        >
                          {company.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Recruiter Verification:
              </Label>
              <Col
                md={9}
                sm={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <ToggleButton
                  disabled={this.props.isInactive}
                  isChecked={isVerify}
                  onChange={() => this.setState({ isVerify: !isVerify })}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Company Id:
              </Label>
              <Col md={9} sm={12}>
                {this.state.companyId ? (
                  <a href={`/bossjob/company/${this.state.companyId}`}>
                    {this.state.companyId}
                  </a>
                ) : (
                  'No company'
                )}
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Update Existing Job Salary:
              </Label>
              <Col md={9} sm={12}>
                <Input
                  type="select"
                  name="editExistingJobIsSalaryHidden"
                  value={
                    editExistingJobIsSalaryHidden
                      ? editExistingJobIsSalaryHidden
                      : 'no_change'
                  }
                  onChange={e => {
                    this.setState({
                      editExistingJobIsSalaryHidden: e.target.value
                    })
                  }}
                >
                  <option key="no_change" value="no_change">
                    Remain unchanged
                  </option>
                  <option key="show_salary" value="show_salary">
                    Mass update existing jobs to show salary
                  </option>
                  <option key="hide_salary" value="hide_salary">
                    Mass update existing jobs to hide salary
                  </option>
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Hide New Job Salary:
              </Label>
              <Col
                md={9}
                sm={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <ToggleButton
                  disabled={this.props.isInactive}
                  isChecked={isJobSalaryHidden}
                  onChange={() =>
                    this.setState({ isJobSalaryHidden: !isJobSalaryHidden })
                  }
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Auto Response Feature Status:
              </Label>
              <Col md={9} sm={12}>
                <Input
                  readonly="readonly"
                  name="autoReply"
                  value={autoReply}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {!this.props.isInactive ? (
          <Can I="edit" a="recruiter_settings" passThrough>
            {can =>
              can && (
                <div style={{ textAlign: 'right' }}>
                  <Button
                    color="info"
                    onClick={this.updateSetting}
                    disabled={
                      this.props.isUpdating || this.state.statusKey !== 'active'
                    }
                  >
                    Save
                  </Button>
                </div>
              )
            }
          </Can>
        ) : (
          <div style={{ textAlign: 'right', color: 'red' }}>
            This user is inactive
          </div>
        )}
        {this.state.statusKey !== 'active' && (
          <div style={{ textAlign: 'right', color: 'red' }}>
            {this.state.companyId
              ? 'This user is not an active recruiter of company'
              : 'This user has not joined any company'}
          </div>
        )}
      </Form>
    )
  }
}

RecruiterSettingsForm.propTypes = {
  settings: PropTypes.object,
  handleUpdateSetting: PropTypes.func,
  handleUpdateCompany: PropTypes.func,
  isUpdating: PropTypes.bool,
  isInactive: PropTypes.bool
}

export default RecruiterSettingsForm
