import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Components */
import ImageField from 'shared/components/ImageField'
import CroppingImageTool from 'shared/components/CroppingImageTool'

/* Vendors */
import imageCompression from 'browser-image-compression'

/* Bootstrap */
import { Button, Label } from 'reactstrap'

/* apollo */
import { withApollo, compose, graphql } from 'react-apollo'
import {
  ADD_COMPANY_PICTURE,
  UPDATE_COMPANY_PICTURE,
  DELETE_COMPANY_PICTURE
} from '../../../../schemas/mutations'

class PhotoAlbumForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isCroppingVisible: false,
      isUpdating: false,
      imagePreviewUrl: '',
      previewImage: { sortOrder: '', url: '', imageFile: '' },
      fileType: ''
    }
  }

  /**
   *
   * @function handleCroppingVisibility - handle when user cropping or not cropping the image
   *
   * @param {Boolean} visible
   */
  handleCroppingVisibility = visible => {
    this.setState({
      isCroppingVisible: visible
    })
  }

  /**
   *
   * @function handleImageChange - handle when user select new image
   *
   * @param {File} imageFile
   */
  handleImageChange = e => {
    if (window.FileReader) {
      let file = e.target.files[0]
      let reader = new FileReader()

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
      }

      //reset input file
      e.target.value = null
      reader.onloadend = () => {
        let previewImage = reader.result
        this.setState({
          imagePreviewUrl: previewImage,
          fileType: file.type
        })
        this.handleCroppingVisibility(true)
      }
    }
  }

  /**
   *
   * @function handleCroppingImage - handle when user done with cropping image
   *
   * @param {String} imageUrl
   * @param {File} blobFile
   */
  handleCroppingImage = (imageUrl, blobFile, sortOrder) => {
    this.setState({
      previewImage: {
        ...this.state.previewImage,
        sortOrder: parseInt(sortOrder),
        url: imageUrl
      }
    })

    if (blobFile) {
      // for passing image blob file to backend API
      this.setState({
        previewImage: {
          ...this.state.previewImage,
          imageFile: blobFile
        }
      })
    }
  }

  handleAddPicture = async () => {
    const { companyData, addCompanyPicture } = this.props
    const { previewImage } = this.state
    this.setState({ isUpdating: true })
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true
      }
      const compressedCompanyPicture = await imageCompression(
        previewImage.imageFile,
        options
      )
      let data = await addCompanyPicture({
        variables: {
          companyId: companyData.id,
          pic: compressedCompanyPicture,
          sortOrder: previewImage.sortOrder
        }
      })
      if (data && data.data && data.data.addCompanyPicture.ok) {
        this.setState({ isUpdating: false })
        window.location.reload()
      }
    } catch (err) {
      console.log('error adding', err)
    }
  }
  handleUpdatePicture = async () => {
    const { companyData, updateCompanyPicture, sortOrder } = this.props
    const { previewImage } = this.state
    let pic =
      companyData && companyData.pictures
        ? companyData.pictures.find(pic => {
          if (pic.sortOrder === sortOrder) return pic.id
        })
        : null

    this.setState({ isUpdating: true })

    try {
      let data = await updateCompanyPicture({
        variables: {
          companyId: companyData.id,
          pic: previewImage.imageFile,
          picId: pic.id
        }
      })

      if (data.data && data.data.updateCompanyPicture.ok) {
        this.setState({ isUpdating: false })
        window.location.reload()
      }
    } catch (err) {
      console.log('error updating', err)
    }
  }
  handleDeletePicture = async () => {
    const { companyData, deleteCompanyPicture, sortOrder } = this.props
    let pic =
      companyData && companyData.pictures
        ? companyData.pictures.find(pic => {
          if (pic.sortOrder === sortOrder) return pic.id
        })
        : null
    this.setState({ isUpdating: true })

    try {
      let data = await deleteCompanyPicture({
        variables: {
          companyId: companyData.id,
          picId: pic.id
        }
      })

      if (data.data && data.data.deleteCompanyPicture.ok) {
        this.setState({ isUpdating: false })

        window.location.reload()
      }
    } catch (err) {
      console.log('error deleting', err)
    }
  }

  renderImage = () => {
    const { companyData, sortOrder } = this.props
    const { previewImage } = this.state
    if (previewImage && previewImage.sortOrder === sortOrder) {
      return previewImage.url
    } else if (
      companyData.pictures &&
      companyData.pictures.find(pic => pic.sortOrder === sortOrder)
    ) {
      return companyData.pictures.find(pic => pic.sortOrder === sortOrder).url
    } else {
      return null
    }
  }
  render() {
    const { companyData, sortOrder, edit } = this.props
    const { fileType, isUpdating, previewImage, imagePreviewUrl } = this.state
    const style = { margin: '0 1em' }
    let pictureExistInDatabase =
      companyData && companyData.pictures
        ? companyData.pictures.find(pic => {
          if (pic.sortOrder === sortOrder) return pic.url
        })
        : null

    return (
      <React.Fragment>
        <div style={{ textAlign: 'center', marginBottom: '2em' }}>
          <Can I="edit" a="companies" passThrough>
            {can => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Label>{sortOrder}</Label>
                  <ImageField
                    hasPermission={can}
                    name={`companyPicture_${sortOrder}`}
                    sortOrder={sortOrder}
                    imageSrc={this.renderImage()}
                    handleChange={this.handleImageChange}
                    style={style}
                    edit={edit}
                  />

                  {edit && previewImage.url && !pictureExistInDatabase ? (
                    <Button
                      outline
                      color="info"
                      size="sm"
                      id={sortOrder}
                      style={{ width: '4em' }}
                      disabled={isUpdating}
                      onClick={this.handleAddPicture}
                    >
                      Add
                    </Button>
                  ) : null}
                  {edit && previewImage.url && pictureExistInDatabase ? (
                    <Button
                      outline
                      color="info"
                      size="sm"
                      id={sortOrder}
                      style={{ width: '5em' }}
                      disabled={isUpdating}
                      onClick={this.handleUpdatePicture}
                    >
                      Update
                    </Button>
                  ) : null}

                  {edit && !previewImage.url && pictureExistInDatabase ? (
                    <Button
                      outline
                      color="info"
                      size="sm"
                      id={sortOrder}
                      style={{ width: '4em' }}
                      disabled={isUpdating}
                      onClick={this.handleDeletePicture}
                    >
                      Delete
                    </Button>
                  ) : null}

                  <CroppingImageTool
                    sortOrder={sortOrder}
                    isVisible={this.state.isCroppingVisible}
                    width={250}
                    height={250}
                    handleCloseModal={this.handleCroppingVisibility}
                    imageSrc={imagePreviewUrl ? imagePreviewUrl : null}
                    fileType={fileType}
                    handleCroppingImage={this.handleCroppingImage}
                  />
                </div>
              </div>
            )}
          </Can>
        </div>
      </React.Fragment>
    )
  }
}

PhotoAlbumForm.propTypes = {
  companyData: PropTypes.object.isRequired,
  sortOrder: PropTypes.number.isRequired,
  edit: PropTypes.bool
}

export default compose(
  withApollo,
  graphql(ADD_COMPANY_PICTURE, { name: 'addCompanyPicture' }),
  graphql(UPDATE_COMPANY_PICTURE, { name: 'updateCompanyPicture' }),
  graphql(DELETE_COMPANY_PICTURE, { name: 'deleteCompanyPicture' })
)(PhotoAlbumForm)
