import gql from 'graphql-tag'

/*====== Local State ======*/
/* Bosshunt user */
export const GET_LOCAL_BOSSHUNT_USER_DETAIL = gql`
  {
    bosshuntUserDetail @client {
      id
      email
      firstName
      lastName
      avatar
      contactNum
      isActive
      isFeatured
      isActiveAd
      isCompleted
      poeaLicense
      doleLicense
      company {
        id
        name
      }
      description
      gender
      poeaLicense
      doleLicense
      jobTitle
      resumeParserRemainingCredit
      industries {
        id
        name
      }
      serviceLocations {
        id
        name
      }
      services {
        id
        name
      }
      feeStructure {
        isContingentFee
        isRetainedFee
        isOtherFee
        annualGrossSalaryFrom
        annualGrossSalaryTo
        monthlyFeeFrom
        monthlyFeeTo
        otherFee
      }
      freeReplacement {
        maxFreeReplacement
      }
      turnaroundTime {
        turnaroundTimeId
        fromPeriod
        toPeriod
      }
      guaranteePeriod {
        guaranteePeriodId
        fromPeriod
        toPeriod
      }
    }
  }
`

// for new forms, because IDs does not exist yet,
// so no ID will be queried, doing so will throw error
export const GET_LOCAL_BOSSHUNT_USER_DETAIL_FOR_NEW_FORM = gql`
  {
    bosshuntUserDetailForNewForm @client {
      email
      firstName
      lastName
      contactNum
      isActive
      avatar
      company {
        id
        name
      }
    }
  }
`

/*====== Query ======*/
/*
 * Get all Bosshunt users
 * Bosshunt users a.k.a headhunters
 */
export const GET_ALL_BOSSHUNT_USERS = gql`
  query getAllBosshuntUsers(
    $size: Int
    $page: Int
    $query: String
    $createdDate: HeadhunterDateTimeRange
    $isActive: Boolean
  ) {
    headhunterList(
      size: $size
      page: $page
      query: $query
      createdDate: $createdDate
      isActive: $isActive
    ) {
      data {
        headhunters {
          id
          email
          contactNum
          firstName
          lastName
          isActive
          createdAt
          company {
            id
            name
          }
        }
        totalPages
        totalNum
        currentPage
        currentNum
      }
    }
  }
`

// /** Get Bosshunt user by User ID */

export const GET_BOSSHUNT_USER_BY_ID = gql`
  query getBosshuntUserById($headhunterId: Int!) {
    headhunter(headhunterId: $headhunterId) {
      data {
        id
        email
        firstName
        lastName
        avatar
        contactNum
        isActive
        isFeatured
        isActiveAd
        isCompleted
        poeaLicense
        doleLicense
        company {
          id
          name
        }
        description
        gender
        poeaLicense
        doleLicense
        jobTitle
        resumeParserRemainingCredit
        industries {
          id
          name
        }
        serviceLocations {
          id
          name
        }
        services {
          id
          name
        }
        feeStructure {
          isContingentFee
          isRetainedFee
          isOtherFee
          annualGrossSalaryFrom
          annualGrossSalaryTo
          monthlyFeeFrom
          monthlyFeeTo
          otherFee
        }
        freeReplacement {
          maxFreeReplacement
        }
        turnaroundTime {
          turnaroundTimeId
          fromPeriod
          toPeriod
        }
        guaranteePeriod {
          guaranteePeriodId
          fromPeriod
          toPeriod
        }
      }
    }
  }
`
