import React from 'react'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import RecruiterListContainer from '../../../containers/CompanyDetailContainers/RecruiterListContainer'

/**
 * Recruiters - main view for Recruiter list under Company page
 *
 */
const Recruiters = () => (
  <SectionWrapper sectionTitle="Recruiters" isMainPage>
    <RecruiterListContainer />
  </SectionWrapper>
)

export default Recruiters
