import React, { Component } from 'react'

/* Bootstrap */
import { Button } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import SearchBar from 'shared/components/SearchBar'
import MessageListContainer from '../../../containers/BossJobUserDetailContainer/MessageListContainer'

/**
 * Chat - Bossjob User chat list
 *
 * @returns {Component}
 */
class Chat extends Component {
  constructor(props) {
    super(props)

    // userType - 1: JobSeeker, 2: Recruiter
    this.state = {
      userType: '1'
    }

    this.handleUserTypeClick = this.handleUserTypeClick.bind(this)
  }

  /**
   * @function handleUserTypeClick - Toggle between JobSeeker and Recruiter
   * @param {InputEvent} e
   */
  handleUserTypeClick(e) {
    this.setState({
      userType: e.target.name
    })
  }

  render() {
    const { userType } = this.state
    return (
      <>
        <SearchBar />
        <SectionWrapper sectionTitle="Chat">
          <div style={{ marginBottom: 21 }}>
            <Button
              name="1"
              color={userType === '1' ? 'info' : 'link'}
              onClick={this.handleUserTypeClick}
              outline
            >
              As JobSeeker
            </Button>
            <Button
              style={{ marginLeft: 16 }}
              name="2"
              color={userType === '2' ? 'info' : 'link'}
              onClick={this.handleUserTypeClick}
              outline
            >
              As Recruiter
            </Button>
          </div>
          <MessageListContainer userType={userType} />
        </SectionWrapper>
      </>
    )
  }
}

export default Chat
