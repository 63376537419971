export default {
  subscriptionDetail: {
    __typename: 'SubscriptionType',
    data: {}
  },
  subscriptionDetailForNewForm: {
    __typename: 'AddOfflineSubscription',
    name: 'Enterprise',
    subscriptionPlanId: 1,
    description: '',
    paymentDuration: 1,
    noOfInstallment: 1,
    invoiceAmount: 0,
    totalAmount: 0,
    amount: 0,
    jobPost: 0,
    unlockTalentCredit: 0,
    rhTalentCredit: 0,
    featureJobCredit: 0,
    urgentJobCredit: 0,
    bosspoint: 0,
    remark: '',
    salesPersonEmail: '',
    contractFile: '',
    startedAt: '',
    endedAt: '',
    creditTerm: 0,
    taxType: 'gst',
    taxRate: 0,
    bankAccountId: 3,
    paymentMethodId: 1,
    billerEmail: '',
    billerFirstName: '',
    billerLastName: '',
    billerAddress: '',
    billerCompany: '',
    billerCity: '',
    billerState: '',
    billerZipcode: '',
    billerCountry: 'ph'
  }
}
