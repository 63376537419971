import React from 'react'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_DIALOGUE_DETAIL } from '../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import ChatDetailHeader from '../../views/ChatDetail/ChatDetailHeader'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/**
 * ChatDetailHeaderContainer - Apollo Component to fetch the local
 * dialogueDetail state that was written from the DialogueDetailContainer
 *
 * returns {Component}
 */
const ChatDetailHeaderContainer = () => (
  <Query query={GET_LOCAL_DIALOGUE_DETAIL}>
    {({ loading, data: { dialogueDetail } }) => {
      if (loading) return <Loading />

      return (
        <ChatDetailHeader
          id={dialogueDetail.id}
          jobApplicationId={dialogueDetail.jobApplicationId}
          initiatedBy={dialogueDetail.founderId}
          initiatedTo={dialogueDetail.participantId}
          createdAt={formatDate(
            dialogueDetail.createdAt,
            'DD/MM/YYYY hh:mm:ss'
          )}
          statusId={dialogueDetail.statusId}
        />
      )
    }}
  </Query>
)

export default ChatDetailHeaderContainer
