import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Col, Form, Row, Label, FormGroup, Input, Button } from 'reactstrap'

/* Apollo */
import { withApollo, compose, graphql } from 'react-apollo'
import { UPDATE_HEADHUNTER_SPECIALIZATION } from '../../../../schemas/mutations'

/* Component */
import Chip from 'shared/components/Chip'
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'

/* Helpers */
import { servicesList } from 'shared/helpers/services'
import { industryList } from 'shared/helpers/industry'
import { locationList } from 'shared/helpers/locations'
import { getLocationNameById } from 'shared/helpers/locations'
import { getServiceNameById } from 'shared/helpers/services'
import { getIndustryNameById } from 'shared/helpers/industry'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

import styles from '../MyAds.module.scss'

const SpecializationForm = ({
  userDetail,
  handleChange,
  handleSpecializationChange,
  updateHeadhunterSpecialization
}) => {
  const [isLoading, setLoadingState] = useState(false)
  const [error, setError] = useState('')
  const containerStyle = {
    display: 'flex',
    border: '1px solid lightgray',
    margin: '10px 0',
    minHeight: '50px',
    flexWrap: 'wrap'
  }
  const payloadFormatter = arrayOfObjects => {
    // filter off object with id == '', null, undefined
    const filteredObjects = arrayOfObjects.filter(array => array.id)
    // then map array of objects into array of string with id
    return filteredObjects.map(object => {
      if (object.id !== '' || object.id !== null || object.id !== undefined) {
        return object.id
      }
    })
  }

  const updateSpecialization = async () => {
    const arrayOfServiceIds = payloadFormatter(userDetail.services)
    const arrayOfIndustryIds = payloadFormatter(userDetail.industries)
    const arrayOfServiceLocationIds = payloadFormatter(
      userDetail.serviceLocations
    )
    setLoadingState(true)
    try {
      let updateSpecializationResponse = await updateHeadhunterSpecialization({
        variables: {
          headhunterId: userDetail && userDetail.id,
          industryIds: arrayOfIndustryIds,
          serviceIds: arrayOfServiceIds,
          serviceLocationIds: arrayOfServiceLocationIds
        }
      })

      if (updateSpecializationResponse) {
        let status =
          updateSpecializationResponse.data.updateHeadhunterSpecialization

        if (status.ok) {
          setLoadingState(false)
          // just a UX to show user it has been updated
          window.location.reload()
        } else {
          setLoadingState(false)
          setError('Something went wrong, please notify and try again later')
        }
      }
    } catch (err) {
      setLoadingState(false)
      setError(err)
    }
  }

  const _renderServicesField = () => {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Col md={2} style={{ padding: 0 }}>
          <Label>Services</Label>
        </Col>
        <Col md={10}>
          <Input type="select" name="services" onChange={handleChange}>
            <option key="null" />
            {servicesList().map(services => (
              <option key={services.id} value={services.id}>
                {services.name}
              </option>
            ))}
          </Input>
          <div style={containerStyle}>
            {userDetail &&
              userDetail.services &&
              userDetail.services.map((services, index) => (
                <Chip
                  key={services.id}
                  label={getServiceNameById(services.id)}
                  onClick={() =>
                    handleSpecializationChange('delete', index, 'services')
                  }
                />
              ))}
          </div>
        </Col>
      </FormGroup>
    )
  }

  const _renderSpecialisedIndustryField = () => {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Col md={2} style={{ padding: 0 }}>
          <Label>Specialised Industries</Label>
        </Col>
        <Col md={10}>
          <Input type="select" name="industries" onChange={handleChange}>
            <option key="null" />
            {industryList().map(industry => (
              <option key={industry.id} value={industry.id}>
                {industry.name}
              </option>
            ))}
          </Input>
          <div style={containerStyle}>
            {userDetail &&
              userDetail.industries &&
              userDetail.industries.map((industry, index) => (
                <Chip
                  key={industry.id}
                  label={getIndustryNameById(industry.id)}
                  onClick={() =>
                    handleSpecializationChange('delete', index, 'industries')
                  }
                />
              ))}
          </div>
        </Col>
      </FormGroup>
    )
  }
  const _renderServiceLocationField = () => {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Col md={2} style={{ padding: 0 }}>
          <Label>Service Locations</Label>
        </Col>
        <Col md={10}>
          <Input type="select" name="serviceLocations" onChange={handleChange}>
            <option key="null" />
            {locationList().map(location => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </Input>
          <div style={containerStyle}>
            {userDetail &&
              userDetail.serviceLocations &&
              userDetail.serviceLocations.map((loc, index) => (
                <Chip
                  key={loc.id}
                  label={getLocationNameById(loc.id)}
                  onClick={() =>
                    handleSpecializationChange(
                      'delete',
                      index,
                      'serviceLocations'
                    )
                  }
                />
              ))}
          </div>
        </Col>
      </FormGroup>
    )
  }

  return (
    <div>
      <SectionWrapper sectionTitle="Specialization Details">
        <Form>
          <Row>
            <Col md={12}>{_renderServicesField()}</Col>
          </Row>
          <Row>
            <Col md={12}>{_renderSpecialisedIndustryField()}</Col>
          </Row>
          <Row>
            <Col md={12}>{_renderServiceLocationField()}</Col>
          </Row>
        </Form>
      </SectionWrapper>
      {error ? (
        <span style={{ color: 'red', marginBottom: '10px' }}>{error}</span>
      ) : null}
      <Can I="edit" a="headhunters">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '4vw',
            margin: '20px'
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <Button
              color="info"
              className={styles.saveBtn}
              onClick={() => updateSpecialization()}
            >
              Update
            </Button>
          )}
        </div>
      </Can>
    </div>
  )
}

SpecializationForm.propTypes = {
  userDetail: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSpecializationChange: PropTypes.func.isRequired,
  updateHeadhunterSpecialization: PropTypes.func.isRequired
}

export default compose(
  withApollo,
  graphql(UPDATE_HEADHUNTER_SPECIALIZATION, {
    name: 'updateHeadhunterSpecialization'
  })
)(SpecializationForm)
