import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import { GET_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM } from '../../../../transactions/schemas/queries'
import { ADD_OFFLINE_TRANSACTION } from '../../../../transactions/schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import SubmitButton from '../../../views/TransactionDetail/Forms/SubmitForm/SubmitButton'

/**
 * SubmitButtonContainer - Handles the Apollo logic side of the UpdateButton
 *
 * @returns {Component}
 */
const SubmitButtonContainer = ({ userId, addOfflineTransaction }) => (
  <Query query={GET_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM}>
    {({ loading, data }) => {
      if (loading) return <Loading />
      return (
        <div>
          <SubmitButton
            transactionDetail={data}
            addOfflineTransaction={addOfflineTransaction}
            userId={userId}
          />
        </div>
      )
    }}
  </Query>
)

SubmitButtonContainer.propTypes = {
  addOfflineTransaction: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default compose(
  graphql(ADD_OFFLINE_TRANSACTION, { name: 'addOfflineTransaction' })
)(SubmitButtonContainer)
