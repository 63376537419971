import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Users from 'modules/users/views/Users'

const RedirectPage = () => {
  const [changeRouter, setChangeRouter] = useState(false)
  const token = localStorage.getItem('accessToken')

  useEffect(() => {
    const timer = setTimeout(() => {
      setChangeRouter(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])
  return (
    <>
      {!changeRouter ? (
        <div>Loading...</div>
      ) : (
        <Route
          render={props =>
            token ? <Users {...props} /> : <Redirect to={'/login'} />
          }
        />
      )}
    </>
  )
}

export default RedirectPage
