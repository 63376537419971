import React, { useState } from 'react'

import PropTypes from 'prop-types'

/* Components */
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'
import AgencyListContainer from '../../containers/AgencyListContainer'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Apollo */
import { compose, graphql } from 'react-apollo'
import { DELETE_AGENCY } from '../../schemas/mutations'

/**
 * Agencies - main agencies list view
 *
 */
const Agencies = props => {
  Agencies.propTypes = {
    deleteUsers: PropTypes.func.isRequired
  }

  const [selectedAgency, setSelectedAgency] = useState([])

  const addAgency = () => {
    window.location.href = '/bosshunt/agency/new'
  }

  const deleteAgency = async () => {
    try {
      if (window.confirm(`Confirm to delete agency ID: ${selectedAgency}`)) {
        let response = await props.deleteHeadhunterCompanies({
          variables: {
            companyIds: selectedAgency
          }
        })

        if (response) {
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *
   * @function appendSelectedAgency - Append agencyId to selectedAgency state
   * @param {Number} agencyId
   */
  const appendSelectedAgency = agencyId => {
    setSelectedAgency([...selectedAgency, Number(agencyId)])
  }

  /**
   *
   * @function removeSelectedAgency - Remove specific userId from selectedUsers state
   * @param {Number} agencyId
   */
  const removeSelectedAgency = agencyId => {
    let selectedAgencies = [...selectedAgency]
    selectedAgencies.splice(selectedAgency.indexOf(Number(agencyId)), 1)
    setSelectedAgency(selectedAgencies)
  }

  return (
    <div>
      <SearchBar />
      <Can I="edit" a="bossjob_user" passThrough>
        {can => (
          <SectionWrapper
            sectionTitle="Recruitment Agency"
            isMainPage
            handleAdd={can ? addAgency : null}
            handleDelete={can ? deleteAgency : null}
          >
            <AgencyListContainer
              appendSelectedAgency={appendSelectedAgency}
              removeSelectedAgency={removeSelectedAgency}
            />
          </SectionWrapper>
        )}
      </Can>
    </div>
  )
}

Agencies.propTypes = {
  deleteHeadhunterCompanies: PropTypes.func.isRequired
}

export default compose(
  graphql(DELETE_AGENCY, { name: 'deleteHeadhunterCompanies' })
)(Agencies)
