import React from 'react'

import MyAccount from './MyAccount'
import Layout from '../../../../shared/components/Layout'

export default () => (
  <Layout>
    <MyAccount />
  </Layout>
)
