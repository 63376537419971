import React, { Component } from 'react'
import { Table } from 'reactstrap'

import PaginationComponent from '../Pagination/Pagination'

import styles from '../CompanyAnalytics.module.scss'

const THEAD = [
  'Title Name',
  'Active Jobs',
  'Posted Jobs',
  'Talent Views',
  'Chats Initiated by Boss',
  'Effective Chats Initiated by Boss',
  'Job Views',
  'Chats Initiated by Talents',
  'Effective Chats Initiated by Talents',
  'Total Chats',
  'Total Effective Chats'
]

class JobTable extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props, '==========')
    const { data, handleChangeJobPage } = this.props
    const { currentPage, totalCount, totalPages, size, jobs } = data

    return (
      <div className={styles.table}>
        <Table>
          <thead>
            <tr>
              {THEAD.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {jobs.length ? (
              jobs.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{item.titleName}</th>
                  <td scope="row">{item.activeJobCount}</td>
                  <td>{item.postedJobCount}</td>
                  <td>{item.viewTalentCount}</td>
                  <td>{item.chatInitiatedByBossCount}</td>
                  <td>{item.effectiveChatInitiatedByBossCount}</td>
                  <td>{item.viewJobCount}</td>
                  <td>{item.chatInitiatedByTalentCount}</td>
                  <td>{item.effectiveChatInitiatedByTalentCount}</td>
                  <td>{item.totalChatCount}</td>
                  <td>{item.effectiveTotalChatCount}</td>
                </tr>
              ))
            ) : (
              <tr>
                <th>No data available</th>
              </tr>
            )}
          </tbody>
        </Table>

        <PaginationComponent
          currentPage={Number(currentPage)}
          totalCount={totalCount}
          totalPages={totalPages}
          pageRange={size}
          handleChangeChatPage={handleChangeJobPage}
        />
      </div>
    )
  }
}

export default JobTable
