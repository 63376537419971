import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'

import styles from '../MyAds.module.scss'

/* Apollo */
import { withApollo, compose, graphql } from 'react-apollo'
import {
  ACTIVATE_HEADHUNTER_AD,
  DEACTIVATE_HEADHUNTER_AD,
  UPDATE_HEADHUNTER_DETAIL
} from '../../../../schemas/mutations'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

const AdSettingsForm = ({
  handleChange,
  userDetail,
  activateHeadhunterAd,
  deactiveHeadhunterAd,
  updateHeadhunter
}) => {
  const [isLoading, setLoadingState] = useState(false)
  const [error, setError] = useState('')
  const handleUpdate = async () => {
    setLoadingState(true)
    let updateStatus
    let activateOrDeactivateStatus

    try {
      if (
        (userDetail &&
          userDetail.isActiveAd &&
          userDetail.isActiveAd === 'true') ||
        userDetail.isActiveAd === true
      ) {
        let activateResponse = await activateHeadhunterAd({
          variables: {
            headhunterId: userDetail && userDetail.id
          }
        })

        if (activateResponse) {
          activateOrDeactivateStatus =
            activateResponse.data.activateHeadhunterAd.ok
        }
      }
      if (
        (userDetail &&
          userDetail.isActiveAd &&
          userDetail.isActiveAd === 'false') ||
        userDetail.isActiveAd === false
      ) {
        let deactiveResponse = await deactiveHeadhunterAd({
          variables: {
            headhunterId: userDetail && userDetail.id
          }
        })

        if (deactiveResponse) {
          activateOrDeactivateStatus =
            deactiveResponse.data.deactivateHeadhunterAd.ok
        }
      }

      let isFeaturedBoolean = false
      if (
        (userDetail &&
          userDetail.isFeatured &&
          userDetail.isFeatured === 'true') ||
        userDetail.isFeatured === true
      ) {
        isFeaturedBoolean = true
      }

      let updateResponse = await updateHeadhunter({
        variables: {
          headhunterId: userDetail && userDetail.id,
          updateHeadhunterInput: {
            isFeatured: isFeaturedBoolean
          }
        }
      })

      if (updateResponse) {
        updateStatus = updateResponse.data.updateHeadhunter.ok
      }

      if (updateStatus && activateOrDeactivateStatus) {
        setLoadingState(false)
        // just a UX to show user it has been updated
        window.location.reload()
      } else {
        setLoadingState(false)
        setError('Something went wrong, please notify and try again later')
      }
    } catch (err) {
      setLoadingState(false)
      setError(err)
    }
  }

  const _renderAdStatus = () => {
    return (
      <FormGroup row>
        <Label md={3} sm={12}>
          Ad Status
        </Label>
        <Col md={9} sm={12}>
          <Input
            name="isActiveAd"
            type="select"
            value={
              userDetail && userDetail.isActiveAd
                ? userDetail.isActiveAd
                : false
            }
            onChange={handleChange}
            // input field will be disabled if required fields to activate ad is not met
            disabled={userDetail && !userDetail.is_completed ? true : false}
          >
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </Input>
        </Col>
      </FormGroup>
    )
  }
  const _renderFeaturedField = () => {
    return (
      <FormGroup row>
        <Label md={3} sm={12}>
          Featured Status
        </Label>
        <Col md={9} sm={12}>
          <Input
            name="isFeatured"
            type="select"
            value={
              userDetail && userDetail.isFeatured
                ? userDetail.isFeatured
                : false
            }
            onChange={handleChange}
          >
            <option value={true}>Featured</option>
            <option value={false}>Not Featured</option>
          </Input>
        </Col>
      </FormGroup>
    )
  }
  return (
    <div>
      <SectionWrapper sectionTitle="">
        <Form>
          <Row>
            <Col md={6}>{_renderAdStatus()}</Col>
          </Row>
          <Row>
            <Col md={6}>{_renderFeaturedField()}</Col>
          </Row>
        </Form>
      </SectionWrapper>
      {error ? (
        <span style={{ color: 'red', marginBottom: '10px' }}>{error}</span>
      ) : null}
      <Can I="edit" a="headhunters">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '4vw',
            margin: '20px'
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <Button
              color="info"
              className={styles.saveBtn}
              onClick={handleUpdate}
            >
              Update
            </Button>
          )}
        </div>
      </Can>
    </div>
  )
  // }
}

AdSettingsForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  activateHeadhunterAd: PropTypes.func.isRequired,
  deactiveHeadhunterAd: PropTypes.func.isRequired,
  updateHeadhunter: PropTypes.func.isRequired,
  userDetail: PropTypes.object.isRequired
}

export default compose(
  withApollo,
  graphql(ACTIVATE_HEADHUNTER_AD, { name: 'activateHeadhunterAd' }),
  graphql(DEACTIVATE_HEADHUNTER_AD, { name: 'deactiveHeadhunterAd' }),
  graphql(UPDATE_HEADHUNTER_DETAIL, { name: 'updateHeadhunter' })
)(AdSettingsForm)
