import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Input, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

import styles from './JobsList.module.scss'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

/**
 * JobsList - Renders the Job List Table
 *
 * @returns {Component}
 */
class JobsList extends Component {
  static propTypes = {
    jobsList: PropTypes.array.isRequired,

    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,

    totalJobs: PropTypes.number.isRequired,

    totalPages: PropTypes.number.isRequired,

    appendSelectedJob: PropTypes.func.isRequired,

    removeSelectedJob: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedJobs: []
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    if (e.target.checked) {
      this.props.appendSelectedJob(
        e.target.value.split('-')[0],
        e.target.value.split('-')[1]
      )
    } else {
      this.props.removeSelectedJob(
        e.target.value.split('-')[0],
        e.target.value.split('-')[1]
      )
    }
  }

  render() {
    const { jobsList, currentPage, totalJobs, totalPages } = this.props
    return (
      <React.Fragment>
        <Table bordered>
          <thead>
            <tr>
              <th />
              <th>ID</th>
              <th>Job Title</th>
              <th>Company Name</th>
              <th>Recruiter</th>
              <th>Status</th>
              <th>Company Verification Status</th>
              <th>Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobsList.map((job, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className={styles.selectCheckBox}>
                      <Input
                        addon
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        onChange={this.handleChange}
                        value={`${job.id}-${job.recruiterId}`}
                      />
                    </div>
                  </td>
                  <td>
                    <a href={`/bossjob/job/${job.id}`}>{job.id}</a>
                  </td>
                  <td>{job.jobTitle}</td>
                  <td>
                    <a href={`/bossjob/company/${job.companyId}`}>
                      {job.companyName}
                    </a>
                  </td>
                  <td>
                    <a href={`/bossjob/user/${job.recruiterId}`}>
                      {job.recruiterFirstName} {job.recruiterLastName}
                    </a>
                  </td>
                  <td>{job.statusKey}</td>
                  <td>{job.isCompanyVerified.toString()}</td>
                  <td>{formatDate(job.createdAt, 'DD/MM/YYYY HH:mm:ss')}</td>
                  <td>
                    <div style={{ textAlign: 'center' }}>
                      <a href={`/bossjob/job/${job.id}`}>
                        <Button color="success">
                          <img src={Zoom} alt="Zoom" width="16" height="16" />
                        </Button>
                      </a>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <Pagination
          currentPage={Number(currentPage)}
          totalCount={totalJobs}
          totalPages={totalPages}
          pageRange={4}
        />
      </React.Fragment>
    )
  }
}

export default JobsList
