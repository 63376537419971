import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ALL_DIALOGUES } from '../../schemas/queries'

/* Components */
import DialogueList from '../../views/Chat/DialogueList'
import Loading from 'shared/components/Loading'

/**
 * DialogueListContainer
 *
 * @returns {Component}
 */
class DialogueListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { search, fromDate, toDate } = queryString.parse(location.search)

    let variables = {
      page: this.state.page,
      query: search ? search : null,
      date:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null
    }

    return (
      <Query query={GET_ALL_DIALOGUES} variables={variables}>
        {({ loading, data: { allDialogues } }) => {
          if (loading) {
            return <Loading />
          }

          return (
            <DialogueList
              dialoguesList={allDialogues.data.dialogues}
              currentPage={this.state.page}
              totalCount={allDialogues.data.totalCount}
              totalPages={allDialogues.data.totalPages}
            />
          )
        }}
      </Query>
    )
  }
}

export default withRouter(DialogueListContainer)
