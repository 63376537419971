import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Apollo */
import { graphql, compose } from 'react-apollo'
import { UPDATE_LOCAL_BOSSHUNT_USER } from '../../../schemas/mutations'

/* Component */
import FeesFormContainer from '../../../containers/BossHuntUserDetailContainer/FeesFormContainer/FeesFormContainer'
import SpecializationFormContainer from '../../../containers/BossHuntUserDetailContainer/SpecializationFormContainer/SpecializationFormContainer'

/* Routing */
import { withRouter, Route, Redirect } from 'react-router-dom'

import styles from './MyAds.module.scss'

/* Bootstrap */
import { Col, Card, CardBody } from 'reactstrap'
import AdSettingsFormContainer from '../../../containers/BossHuntUserDetailContainer/AdSettingsFormContainer/AdSettingsFormContainer'

/**
 *
 * MyAds - main wrapper component for the Account Setting page
 *
 * @returns {Component}
 */
const MyAds = ({
  match,
  location,
  headhunterServerData,
  updateLocalBosshuntUser
}) => {
  const updateLocalBosshuntUserDetailState = (key, value, category) => {
    updateLocalBosshuntUser({
      variables: {
        key,
        value,
        category
      }
    })
  }

  const handleChangeForExistingForm = (e, category) => {
    updateLocalBosshuntUserDetailState(e.target.name, e.target.value, category)
  }

  const handleToggleForExistingForm = (key, value, category) => {
    updateLocalBosshuntUserDetailState(key, value, category)
  }

  const handleSpecializationChange = (actionType, index, type) => {
    if (actionType === 'add') {
      updateLocalBosshuntUser({
        variables: {
          key: type,
          value: null
        }
      })
    } else {
      updateLocalBosshuntUser({
        variables: {
          key: type,
          del: index
        }
      })
    }
  }

  return (
    <React.Fragment>
      <Col md="12" style={{ marginBottom: '20px' }}>
        <Card>
          <CardBody>
            <div className={styles.subMenu}>
              <div>
                <a
                  href={`/bosshunt/user/${match.params.id}/my-ads/ad-settings`}
                  className={
                    location.pathname.includes('ad-settings')
                      ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                      : styles.subMenuItem
                  }
                >
                  Ad Settings
                </a>
              </div>
              <div>
                <a
                  href={`/bosshunt/user/${match.params.id}/my-ads/fees`}
                  className={
                    location.pathname.includes('fees')
                      ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                      : styles.subMenuItem
                  }
                >
                  Fees
                </a>
              </div>
              <div>
                <a
                  href={`/bosshunt/user/${
                    match.params.id
                  }/my-ads/specialization`}
                  className={
                    location.pathname.includes('specialization')
                      ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                      : styles.subMenuItem
                  }
                >
                  Specialization
                </a>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <React.Fragment>
        <Route
          exact
          path={`${match.path}`}
          component={() => <Redirect to={`${match.url}/ad-settings`} />}
        />

        <Route
          exact
          path="/bosshunt/user/:id/my-ads/ad-settings"
          render={() => (
            <AdSettingsFormContainer
              match={match}
              headhunterServerData={headhunterServerData}
              handleChangeForExistingForm={handleChangeForExistingForm}
            />
          )}
        />
        <Route
          exact
          path="/bosshunt/user/:id/my-ads/fees"
          render={() => (
            <FeesFormContainer
              match={match}
              headhunterServerData={headhunterServerData}
              handleChangeForExistingForm={handleChangeForExistingForm}
              handleToggleForExistingForm={handleToggleForExistingForm}
            />
          )}
        />
        <Route
          exact
          path="/bosshunt/user/:id/my-ads/specialization"
          render={() => (
            <SpecializationFormContainer
              match={match}
              headhunterServerData={headhunterServerData}
              handleChangeForExistingForm={handleChangeForExistingForm}
              handleSpecializationChange={handleSpecializationChange}
            />
          )}
        />
      </React.Fragment>
    </React.Fragment>
  )
}

MyAds.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  updateLocalBosshuntUser: PropTypes.func,
  updateLocalBosshuntUserForNewForm: PropTypes.func,
  headhunterServerData: PropTypes.object
}

export default compose(
  graphql(UPDATE_LOCAL_BOSSHUNT_USER, {
    name: 'updateLocalBosshuntUser'
  })
)(withRouter(MyAds))
