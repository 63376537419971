/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button } from 'reactstrap'

/**
 * UpdateButton - Presentational Component to Update InvoiceDetail
 *
 * @returns {Component}
 */
class UpdateButton extends Component {
  static propTypes = {
    transactionDetail: PropTypes.object,
    UpdateTransaction: PropTypes.func.isRequired
  }

  handleClick() {
    const { transactionDetail, UpdateTransaction } = this.props

    let bankAccountId = Number(transactionDetail.bankAccountId)
    if (
      transactionDetail.offlineTransaction &&
      transactionDetail.offlineTransaction.methodId &&
      transactionDetail.offlineTransaction.methodId == 2
    ) {
      bankAccountId = 3
    }
    UpdateTransaction({
      variables: {
        amount: transactionDetail.amount,
        remark: transactionDetail.remark,
        statusId: transactionDetail.statusId,
        transactionId: transactionDetail.id,
        itemDescription: transactionDetail.itemDescription,
        creditTerm: Number(transactionDetail.creditTerm),
        currency: transactionDetail.currency,
        currencyExchangeRate: Number(transactionDetail.currencyExchangeRate),
        taxType: transactionDetail.taxType ? transactionDetail.taxType : 'gst',
        taxRate: Number(transactionDetail.taxRate),
        billerEmail: transactionDetail.billerEmail,
        salesPersonEmail: transactionDetail.salesPersonEmail,
        billerAddress: transactionDetail.billerAddress,
        billerCity: transactionDetail.billerCity,
        billerCompany: transactionDetail.billerCompany,
        billerCountry: transactionDetail.billerCountry,
        billerFirstName: transactionDetail.billerFirstName,
        billerLastName: transactionDetail.billerLastName,
        billerState: transactionDetail.billerState,
        billerZipcode: transactionDetail.billerZipcode,
        offlinePaymentMethodId:
          transactionDetail.offlineTransaction &&
          transactionDetail.offlineTransaction.methodId
            ? Number(transactionDetail.offlineTransaction.methodId)
            : null,
        bankAccountId
      }
    })
      .then(response => {
        if (response.data.updateTransaction.ok) window.location.reload()
      })
      .catch(err => console.log(err))
  }

  render() {
    return (
      <Button
        style={{ marginBottom: '15px' }}
        color="info"
        onClick={() => this.handleClick()}
      >
        Update
      </Button>
    )
  }
}

export default UpdateButton
