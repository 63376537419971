import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'

/**
 * JobDetailBoostFormContainer
 *
 * @returns {Component}
 *
 */
class JobDetailBoostFormContainer extends Component {
  static propTypes = {
    jobDetail: PropTypes.object,
    recruiterSubscriptionPlanDetail: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    handleCheckBoxChange: PropTypes.func.isRequired,
    handleExternalURLChange: PropTypes.func.isRequired,
    isInvalid: PropTypes.bool.isRequired,
    updateSalaryStatus: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      featured: false,
      urgent: false,
      isURL: false,
      externalApplyUrl: ''
    }

    this.handleCheckBoxToggle = this.handleCheckBoxToggle.bind(this)
    this._setInitialValues = this._setInitialValues.bind(this)
    this.handleURLChange = this.handleURLChange.bind(this)
  }

  componentDidMount() {
    this._setInitialValues(this.props.jobDetail)
  }

  /**
   *
   * @function _setInitialValues - Populate Form values
   * @param {Object} jobDetail
   */
  _setInitialValues(jobDetail) {
    let isURLState = false

    if (
      jobDetail.externalApplyUrl !== null &&
      jobDetail.externalApplyUrl !== ''
    ) {
      isURLState = true
    }

    this.setState({
      urgent: jobDetail.highlighted,
      featured:
        jobDetail.stickies &&
        (jobDetail.stickies.status === 'active' ||
          jobDetail.stickies.status === true)
          ? true
          : false,
      isURL: isURLState,
      externalApplyUrl: jobDetail.externalApplyUrl
    })
  }

  handleURLChange(e) {
    this.setState(
      {
        externalApplyUrl: e.target.value
      },
      () => {
        this.props.handleExternalURLChange(this.state.externalApplyUrl)
      }
    )
  }

  handleCheckBoxToggle(e) {
    let key = e.target.name
    console.log(key)
    this.setState(
      {
        [key]: !this.state[key]
      },
      () => {
        this.props.handleCheckBoxChange(key, this.state[key])
      }
    )
  }

  /**
   *
   * @function _renderBoostOptionsField
   * @returns {Component}
   */
  _renderBoostOptionsField() {
    const { urgent, featured, isURL } = this.state
    const { isInvalid } = this.props

    return (
      <>
        <Label>Boost job</Label>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              name="featured"
              checked={featured}
              value={featured}
              onChange={this.handleCheckBoxToggle}
              disabled={isInvalid}
            />{' '}
            Featured
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              name="urgent"
              checked={urgent}
              value={urgent}
              onChange={this.handleCheckBoxToggle}
              disabled={isInvalid}
            />{' '}
            Urgent
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              name="isURL"
              checked={isURL}
              value={isURL}
              onChange={this.handleCheckBoxToggle}
              disabled={isInvalid}
            />{' '}
            URL
          </Label>
        </FormGroup>
      </>
    )
  }

  /**
   *
   * @function _renderExternalURLField
   * @returns {Component}
   */
  _renderExternalURLField() {
    const { isURL, externalApplyUrl } = this.state
    const { isInvalid } = this.props
    return (
      <FormGroup>
        <Label for="url">URL</Label>
        <Input
          type="text"
          name="url"
          id="externalApplyUrl"
          value={externalApplyUrl ? externalApplyUrl : ''}
          disabled={!isURL || isInvalid}
          onChange={this.handleURLChange}
        />
      </FormGroup>
    )
  }

  handleSalaryStatusSubmit = async e => {
    const { updateSalaryStatus, jobDetail } = this.props
    e.preventDefault()
    const formData = new FormData(e.target)

    try {
      const response = await updateSalaryStatus({
        variables: {
          jobId: jobDetail.id,
          isSalaryHidden: formData.get('isSalaryHidden') || false
        }
      })
      if (response) {
        console.log('response', response)
      }
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  _renderIsSalaryHiddenForm() {
    const { jobDetail } = this.props

    return (
      <Form onSubmit={this.handleSalaryStatusSubmit}>
        <fieldset
          style={{
            border: '2px inset #ddd',
            margin: 0,
            padding: '10px'
          }}
        >
          <legend
            style={{
              width: 'auto',
              padding: '0 10px'
            }}
          >
            <Label style={{ fontSize: '16px' }}>Feature</Label>
          </legend>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="isSalaryHidden"
                defaultChecked={jobDetail.isSalaryHidden}
              />{' '}
              Salary hidden
            </Label>
          </FormGroup>
          <Button size="sm" style={{ marginTop: '20px' }} color="primary">
            Update
          </Button>{' '}
        </fieldset>
      </Form>
    )
  }

  render() {
    return (
      <SectionWrapper sectionTitle="Options">
        <Form>
          {this._renderBoostOptionsField()}
          {this._renderExternalURLField()}
        </Form>
        {this._renderIsSalaryHiddenForm()}
      </SectionWrapper>
    )
  }
}

export default JobDetailBoostFormContainer
