import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import { UPDATE_SUBSCRIPTION } from '../../../schemas/mutations'
import { GET_LOCAL_SUBSCRIPTION_DETAILS } from '../../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import UpdateButton from '../../../views/SubscriptionDetail/Forms/SubmitForm/UpdateButton'

/**
 * UpdateButtonContainer - Handles the Apollo logic side of the UpdateButton
 *
 * @returns {Component}
 */
const UpdateButtonContainer = ({ UpdateSubscription }) => (
  <Query query={GET_LOCAL_SUBSCRIPTION_DETAILS}>
    {({ loading, data: { subscriptionDetail } }) => {
      if (loading) return <Loading />
      return (
        <UpdateButton
          subscriptionDetail={subscriptionDetail}
          UpdateSubscription={UpdateSubscription}
        />
      )
    }}
  </Query>
)

UpdateButtonContainer.propTypes = {
  UpdateSubscription: PropTypes.func.isRequired,
  subscriptionDetail: PropTypes.object
}

export default compose(
  graphql(UPDATE_SUBSCRIPTION, { name: 'UpdateSubscription' })
)(UpdateButtonContainer)
