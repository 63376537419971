import React, { Component } from 'react'

/* Components */
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'
import SubscriptionPlanListContainer from 'modules/subscription_plans/containers/SubscriptionPlanListContainer'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

class SubscriptionPlan extends Component {
  addSubscriptionPlan = () =>
    (window.location.href = '/bossjob/subscription-plan/new')

  render() {
    return (
      <>
        <SearchBar placeholderForSearch={'Name'} hideDate />

        <Can I="edit" a="subscription_plans" passThrough>
          {can => (
            <SectionWrapper
              sectionTitle="Subscription Plans"
              handleAdd={can ? this.addSubscriptionPlan : null}
            >
              <SubscriptionPlanListContainer />
            </SectionWrapper>
          )}
        </Can>
      </>
    )
  }
}

export default SubscriptionPlan
