import gql from 'graphql-tag'

/** Update Job Status **/
export const UPDATE_JOB_STATUS = gql`
  mutation UpdateJobStatus(
    $jobId: Int!
    $statusKey: String!
    $rejectedReason: String
    $changeRequiredReason: String
  ) {
    updateJobStatus(
      jobId: $jobId
      statusKey: $statusKey
      rejectedReason: $rejectedReason
      changeRequiredReason: $changeRequiredReason
    ) {
      ok
      message
    }
  }
`
export const BATCH_UPDATE_JOB_STATUS = gql`
  mutation BatchUpdateJobStatus(
    $jobIds: [Int]!
    $statusKey: String!
    $reason: String
  ) {
    batchUpdateJobStatus(
      jobIds: $jobIds
      statusKey: $statusKey
      reason: $reason
    ) {
      ok
      message
    }
  }
`

/** Update Local Job Details **/
export const UPDATE_LOCAL_JOB_DETAILS = gql`
  mutation UpdateLocalJobDetails($key: String, $value: String) {
    updateLocalJobDetails(key: $key, value: $value) @client {
      ok
    }
  }
`

/** Update Local Job Categories **/
export const UPDATE_LOCAL_JOB_CATEGORIES = gql`
  mutation UpdateLocalJobCategories($categoryId: Int, $index: Int) {
    updateLocalJobCategories(categoryId: $categoryId, index: $index) @client {
      ok
    }
  }
`

/** Update Local Job Benefits **/
export const UPDATE_LOCAL_JOB_BENEFITS = gql`
  mutation UpdateLocalJobBenefits($jobBenefitId: Int, $isChecked: Bool) {
    updateLocalJobBenefits(jobBenefitId: $jobBenefitId, isChecked: $isChecked)
      @client {
      ok
    }
  }
`

/** Add Local Dynamic Input Field **/
export const ADD_LOCAL_DYNAMIC_INPUT_FIELD = gql`
  mutation AddLocalDynamicInputField($keyName: String, $obj: Object) {
    addLocalDynamicInputField(keyName: $keyName, obj: $obj) @client {
      ok
    }
  }
`

/** Delete Local Dynamic Input Field **/
export const DELETE_LOCAL_DYNAMIC_INPUT_FIELD = gql`
  mutation DeleteLocalDynamicInputField($keyName: String, $index: Int) {
    deleteLocalDynamicInputField(keyName: $keyName, index: $index) @client {
      ok
    }
  }
`

/** Update Job Details **/
export const UPDATE_JOB = gql`
  mutation UpdateJob($jobId: Int!, $jobInput: UpdateJobsInput) {
    updateJob(jobId: $jobId, jobInput: $jobInput) {
      ok
      message
    }
  }
`

/** Refresh Job **/
export const REFRESH_JOB = gql`
  mutation RefreshJob($jobId: Int!) {
    refreshJob(jobId: $jobId) {
      ok
    }
  }
`

/* Update Job is_salary_hidden */
export const UPDATE_SALARY_STATUS = gql`
  mutation UpdateSalaryStatus($jobId: Int!, $isSalaryHidden: Boolean!) {
    updateSalaryStatus(jobId: $jobId, isSalaryHidden: $isSalaryHidden) {
      ok
      message
    }
  }
`

/** Restore Job **/
export const RESTORE_JOB = gql`
  mutation RestoreJob($jobId: Int!) {
    restoreJob(jobId: $jobId) {
      ok
    }
  }
`

/** Post Job **/
export const POST_JOB = gql`
  mutation AddJob($recruiterId: Int!, $jobInput: AddJobInput) {
    addJob(recruiterId: $recruiterId, jobInput: $jobInput) {
      ok
      jobId
      message
    }
  }
`

/** Create Draft Job **/
export const CREATE_DRAFT_JOB = gql`
  mutation CreateDraftJob($recruiterId: Int!) {
    createDraftJob(recruiterId: $recruiterId) {
      ok
      jobId
      message
    }
  }
`

/** Update Local Job Detail Screening Questions **/
export const UPDATE_LOCAL_SCREENING_QUESTIONS = gql`
  mutation UpdateLocalScreeningQuestions($question: String, $index: Int) {
    updateLocalScreeningQuestions(question: $question, index: $index) @client {
      ok
    }
  }
`

/** Update Local Job Detail Job Forwarding Email **/
export const UPDATE_LOCAL_JOB_FORWARDING_EMAIL = gql`
  mutation UpdateLocalJobForwardingEmails($email: String, $index: Int) {
    updateLocalJobForwardingEmails(email: $email, index: $index) @client {
      ok
    }
  }
`

/** Update Local Post Job Details **/
export const UPDATE_LOCAL_POST_JOB_DETAILS = gql`
  mutation UpdateLocalPostJobDetails($key: String, $value: String) {
    updateLocalPostJobDetails(key: $key, value: $value) @client {
      ok
    }
  }
`

/** Update Local Post Job Categories **/
export const UPDATE_LOCAL_POST_JOB_CATEGORIES = gql`
  mutation UpdateLocalPostJobCategories($categoryId: Int, $index: Int) {
    updateLocalPostJobCategories(categoryId: $categoryId, index: $index)
      @client {
      ok
    }
  }
`

/** Update Local Post Job Benefits **/
export const UPDATE_LOCAL_POST_JOB_BENEFITS = gql`
  mutation UpdateLocalPostJobBenefits($jobBenefitId: Int, $isChecked: Bool) {
    updateLocalPostJobBenefits(
      jobBenefitId: $jobBenefitId
      isChecked: $isChecked
    ) @client {
      ok
    }
  }
`

/** Update Local Post Job Screening Questions **/
export const UPDATE_LOCAL_POST_JOB_SCREENING_QUESTIONS = gql`
  mutation UpdateLocalPostJobScreeningQuestions(
    $question: String
    $index: Int
  ) {
    updateLocalPostJobScreeningQuestions(question: $question, index: $index)
      @client {
      ok
    }
  }
`

/** Update Local Post Job Forwarding Emails **/
export const UPDATE_LOCAL_POST_JOB_FORWARDING_EMAILS = gql`
  mutation UpdateLocalPostJobForwardingEmails($email: String, $index: Int) {
    updateLocalPostJobForwardingEmails(email: $email, index: $index) @client {
      ok
    }
  }
`

/** Add Local Dynamic Input Field **/
export const ADD_LOCAL_POST_JOB_DYNAMIC_INPUT_FIELD = gql`
  mutation AddLocalPostJobDynamicInputField($keyName: String, $obj: Object) {
    addLocalPostJobDynamicInputField(keyName: $keyName, obj: $obj) @client {
      ok
    }
  }
`

/** Delete LocalPostJob Dynamic Input Field **/
export const DELETE_LOCAL_POST_JOB_DYNAMIC_INPUT_FIELD = gql`
  mutation DeleteLocalPostJobDynamicInputField($keyName: String, $index: Int) {
    deleteLocalPostJobDynamicInputField(keyName: $keyName, index: $index)
      @client {
      ok
    }
  }
`

export const EXPORT_JOBS_EXCEL = gql`
  mutation ExportJobsExcel(
    $recruiterId: Int!
    $fromDate: DateTime
    $toDate: DateTime
    $status: String
  ) {
    exportJobsExcel(
      recruiterId: $recruiterId
      fromDate: $fromDate
      toDate: $toDate
      status: $status
    ) {
      ok
      message
    }
  }
`

export const EXPORT_JOBS_APPLICANT_EXCEL = gql`
  mutation ExportJobsApplicantExcel(
    $recruiterId: Int!
    $fromDate: DateTime
    $toDate: DateTime
    $status: String
  ) {
    exportJobsApplicantExcel(
      recruiterId: $recruiterId
      fromDate: $fromDate
      toDate: $toDate
      status: $status
    ) {
      ok
      message
    }
  }
`
