import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Apollo */
import { Query } from 'react-apollo'

/* Components */
import Loading from 'shared/components/Loading'
import { GET_LOCAL_AGENCY_DETAIL } from '../../../schemas/queries'
import FeesForm from '../../../views/AgencyDetail/FeesForm/FeesForm'

const FeesFormContainer = ({
  match,
  handleToggleForExistingForm,
  handleChangeForExistingForm
}) => {
  return (
    <Query query={GET_LOCAL_AGENCY_DETAIL}>
      {({ loading, data: { agencyDetail } }) => {
        if (loading) {
          return <Loading />
        }
        return (
          <FeesForm
            match={match}
            agencyDetail={agencyDetail}
            handleChange={handleChangeForExistingForm}
            handleToggle={handleToggleForExistingForm}
          />
        )
      }}
    </Query>
  )
}

FeesFormContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  handleChangeForExistingForm: PropTypes.func,
  handleToggleForExistingForm: PropTypes.func
}

export default FeesFormContainer
