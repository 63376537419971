import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { Table } from 'reactstrap'

import styles from '../CompanyAnalytics.module.scss'

const THEAD = [
  'Verified Boss',
  'Logged In Boss',
  'Active Jobs',
  'Published Jobs',
  'Active Job Functions',
  'Posted Jobs (all status)',
  'Job Views',
  'Chats Initiated by Talents',
  'Talent Views',
  'Chats Initiated by Boss',
  'Total Effective Chats'
]

class CompanyTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      company: props.company
    }
  }

  render() {
    const { company } = this.state
    return (
      <div className={styles.table}>
        <Table>
          <thead>
            <tr>
              {THEAD.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            <tr>
              <th scope="row">{company.verifiedHrCount}</th>
              <td>{company.activeHrCount}</td>
              <td>{company.activeJobCount}</td>
              <td>{company.publishedJobCount}</td>
              <td>{company.activeJobFunctionCount}</td>
              <td>{company.postedJobAllStatusCount}</td>
              <td>{company.viewJobCount}</td>
              <td>{company.chatInitiatedByTalentCount}</td>
              <td>{company.viewTalentCount}</td>
              <td>{company.chatInitiatedByBossCount}</td>
              <td>{company.effectiveTotalChatCount}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
}

export default withRouter(CompanyTable)
