import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_COMPANY_DETAIL } from '../../../schemas/queries'

import {
  UPDATE_LOCAL_COMPANY_DETAIL,
  UPDATE_COMPANY_VERIFICATION,
  APPROVE_COMPANY_DOCUMENT,
  REJECT_COMPANY_DOCUMENT,
  REQUIRE_CHANGE_COMPANY_DOCUMENT
} from '../../../schemas/mutations'

/* Permission */
import CompanyAuthenticationForm from '../../../views/CompanyDetail/CompanyInformation/CompanyAuthenticationForm/companyAuthenticationForm'

/**
 * CompanyAuthenticationContainer - container component to query and mutate
 * company information and document and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class CompanyAuthenticationContainer extends Component {
  static propTypes = {
    canEdit: PropTypes.bool
  }
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: '',
      isUpdating: false,
      authStatusKey: 'approve',
      changeRequiredReason: '',
      rejectedReason: '',
      isTextareaInvalid: true,
      isRejectTextareaInvalid: true,

      approveReason: '',
      isShowApproveReason: false
    }
  }

  /**
   *
   * @function handleUserInputUpdate - handle when user updating the field
   * and it will trigger the local data mutation
   *
   * @param apolloClient
   * @param key
   * @param value
   */
  handleUserInputUpdate(apolloClient, key, value) {
    return apolloClient.mutate({
      mutation: UPDATE_LOCAL_COMPANY_DETAIL,
      variables: {
        key: key,
        value: value
      }
    })
  }

  handleSave = async apolloClient => {
    this.setState({
      isUpdating: true
    })

    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let responseVerify = await apolloClient.mutate({
      mutation: UPDATE_COMPANY_VERIFICATION,
      variables: {
        companyId: companyDetail.id,
        isVerify: companyDetail.isVerify
      }
    })

    if (responseVerify.data.updateCompanyVerification.ok) {
      this.setState({
        isUpdating: false
      })
      window.location.reload()
    } else {
      this.setState({
        isUpdating: false,
        errorMessage: 'Something went wrong, please notify and try again later'
      })
    }
  }

  handleSelectChange = value => {
    if (value === 'change_required') {
      this.setState({
        authStatusKey: value,

        isRejectTextareaInvalid: true,
        isTextareaInvalid: false,
        isShowApproveReason: true
      })
    } else if (value === 'reject') {
      this.setState({
        authStatusKey: value,

        isRejectTextareaInvalid: false,
        isTextareaInvalid: true,
        isShowApproveReason: true
      })
    } else {
      this.setState({
        authStatusKey: value,
        isRejectTextareaInvalid: true,
        isTextareaInvalid: true,
        isShowApproveReason: false
      })
    }
  }

  handleSubmit = async apolloClient => {
    if (this.state.authStatusKey === 'approve') {
      this.handleApproveCompanyDocument(apolloClient)
    } else if (this.state.authStatusKey === 'reject') {
      this.handleRejectCompanyDocument(apolloClient)
    } else if (this.state.authStatusKey === 'change_required') {
      this.handleRequireChangeCompanyDocument(apolloClient)
    }
  }

  handleApproveCompanyDocument = async apolloClient => {
    this.setState({
      isUpdating: true
    })

    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    // let responseApprove
    // let error
    // try {
    //   responseApprove = await apolloClient.mutate({
    //     mutation: APPROVE_COMPANY_DOCUMENT,
    //     variables: {
    //       companyId: companyDetail.id,
    //       activeReason: this.state.approveReason
    //     }
    //   })
    // } catch (err) {
    //   if (
    //     err.networkError &&
    //     err.networkError.result &&
    //     err.networkError.result.errors
    //   ) {
    //     console.log('GraphQL errors:', err.networkError.result.errors)
    //     error = err.networkError.result.errors[0]
    //   } else {
    //     // 处理其他类型的错误
    //     console.log('Other errors:', err)
    //   }
    // }

    console.log('debugger product: 判断部署是否成功')
    let responseApprove = await apolloClient.mutate({
      mutation: APPROVE_COMPANY_DOCUMENT,
      variables: {
        companyId: companyDetail.id,
        activeReason: this.state.approveReason
      }
    })

    if (responseApprove.data.approveCompanyDocument.ok) {
      this.setState({
        isUpdating: false
      })
      window.location.reload()
    } else {
      this.setState({
        isUpdating: false,
        errorMessage: responseApprove.data.approveCompanyDocument.message
      })
    }
  }

  handleRejectCompanyDocument = async apolloClient => {
    this.setState({
      isUpdating: true
    })

    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let responseReject = await apolloClient.mutate({
      mutation: REJECT_COMPANY_DOCUMENT,
      variables: {
        companyId: companyDetail.id,
        rejectedReason: this.state.rejectedReason
      }
    })

    if (responseReject.data.rejectCompanyDocument.ok) {
      this.setState({
        isUpdating: false
      })
      window.location.reload()
    } else {
      this.setState({
        isUpdating: false,
        errorMessage: responseReject.data.rejectCompanyDocument.message
      })
    }
  }

  handleRequireChangeCompanyDocument = async apolloClient => {
    this.setState({
      isUpdating: true
    })

    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let responseRequireChange = await apolloClient.mutate({
      mutation: REQUIRE_CHANGE_COMPANY_DOCUMENT,
      variables: {
        companyId: companyDetail.id,
        changeRequiredReason: this.state.changeRequiredReason
      }
    })

    if (responseRequireChange.data.requireChangeCompanyDocument.ok) {
      this.setState({
        isUpdating: false
      })
      window.location.reload()
    } else {
      this.setState({
        isUpdating: false,
        errorMessage:
          responseRequireChange.data.requireChangeCompanyDocument.message
      })
    }
  }

  handleRequireChangeReason = value => {
    this.setState({
      changeRequiredReason: value
    })
  }

  handleRejectedReason = value => {
    this.setState({
      rejectedReason: value
    })
  }

  handleApproveReason = value => {
    this.setState({
      approveReason: value
    })
  }

  render() {
    const {
      errorMessage,
      isUpdating,
      authStatusKey,
      isTextareaInvalid,
      isRejectTextareaInvalid,
      isShowApproveReason
    } = this.state
    return (
      <Query query={GET_LOCAL_COMPANY_DETAIL}>
        {({ data: { companyDetail }, client }) => {
          return (
            <CompanyAuthenticationForm
              isUpdating={isUpdating}
              errorMessage={errorMessage}
              apolloClient={client}
              companyDetail={companyDetail}
              handleSave={this.handleSave}
              handleChange={this.handleUserInputUpdate}
              authStatusKey={authStatusKey}
              isTextareaInvalid={isTextareaInvalid}
              isRejectTextareaInvalid={isRejectTextareaInvalid}
              handleSelectChange={this.handleSelectChange}
              handleSubmit={this.handleSubmit}
              handleRequireChangeReason={this.handleRequireChangeReason}
              handleRejectedReason={this.handleRejectedReason}
              canEdit={this.props.canEdit}
              isShowApproveReason={isShowApproveReason}
              handleApproveReason={this.handleApproveReason}
            />
          )
        }}
      </Query>
    )
  }
}

export default CompanyAuthenticationContainer
