import { GET_LOGIN_FORM_ERROR } from '../schemas/queries'
export default {
  captureError: (_, { errorMessage }, { cache }) => {
    let { loginFormError } = cache.readQuery({
      query: GET_LOGIN_FORM_ERROR
    })
    loginFormError.error = true
    loginFormError.errorMessage = errorMessage
    cache.writeQuery({
      query: GET_LOGIN_FORM_ERROR,
      data: { loginFormError }
    })
    return null
  }
}
