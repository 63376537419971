import React, { Component } from 'react'
import classNames from 'classnames'

import styles from '../CompanyAnalytics.module.scss'

class Tab extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      active,
      handleSwitchActiveTab,
      handleExportRequest,
      exportLoading,
      fromDate,
      toDate
    } = this.props
    return (
      <div className={styles.tab}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-3">
                {fromDate} To {toDate}
              </div>
              <div className="col-2" onClick={() => handleSwitchActiveTab(1)}>
                <span
                  className={classNames([
                    styles.tabChild,
                    active == 1 ? styles.tabActive : null
                  ])}
                >
                  Company data
                </span>
              </div>
              <div className="col-2" onClick={() => handleSwitchActiveTab(2)}>
                <span
                  className={classNames([
                    styles.tabChild,
                    active == 2 ? styles.tabActive : null
                  ])}
                >
                  Chat data
                </span>
              </div>
              <div className="col-2" onClick={() => handleSwitchActiveTab(3)}>
                <span
                  className={classNames([
                    styles.tabChild,
                    active == 3 ? styles.tabActive : null
                  ])}
                >
                  Work data
                </span>
              </div>

              <div className="col-2 offset-md-1">
                <div className={styles.export}>
                  <button
                    type="button"
                    className={classNames([
                      'btn',
                      'btn-primary',
                      exportLoading ? styles.loading : null
                    ])}
                    onClick={() => {
                      exportLoading ? null : handleExportRequest()
                    }}
                  >
                    {exportLoading ? 'Loading' : 'Export Request'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Tab
