import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/**
 * CreditHistoryList - Bossjob recruiter credit history list table
 *
 */
const CreditHistoryList = ({
  historyList,
  currentPage,
  totalCounts,
  totalPages
  // handleModal
}) => (
  <Can I="edit" a="bossjob_user" passThrough>
    <React.Fragment>
      <Table bordered>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>Description</th>
            <th style={{ textAlign: 'center' }}>Created At</th>
          </tr>
        </thead>
        <tbody>
          {historyList.map(item => (
            <tr key={`history-${item.id}`}>
              <td>{item.remark}</td>
              <td style={{ textAlign: 'center' }}>
                {formatDate(item.createdAt, 'DD/MM/YYYY HH:mm:ss')}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={Number(totalCounts)}
        totalPages={Number(totalPages)}
        pageRange={4}
      />
    </React.Fragment>
  </Can>
)

CreditHistoryList.propTypes = {
  /* array of history list */
  historyList: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalCounts: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired
}

export default CreditHistoryList
