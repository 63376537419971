import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Row, Col } from 'reactstrap'

import styles from './ProductFeatureAnnouncementForm.module.scss'

const ProductFeatureAnnouncementForm = ({
  index,
  list,
  addProductFeatureAnnouncement,
  updateProductFeatureAnnouncement,
  removeProductFeatureAnnouncement
}) => {
  const [updating, setUpdating] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const handleSubmit = e => {
    try {
      setUpdating(true)
      if (!list.id) {
        return addProductFeatureAnnouncement(e)
      }
      return updateProductFeatureAnnouncement(e)
    } catch (err) {
      setUpdating(false)
      console.log(err)
    }
  }

  const handleDelete = bannerId => {
    try {
      setDeleting(true)
      removeProductFeatureAnnouncement(bannerId)
      return setDeleting(false)
    } catch (err) {
      setDeleting(false)
      console.log(err)
    }
  }

  return (
    <fieldset key={index} className={styles.fieldset}>
      <legend className={styles.legend}>{`Announcement ${index + 1}`}</legend>
      <Form onSubmit={handleSubmit} className={styles.formContainer}>
        {list.id ? (
          <Input type="hidden" name="id" id="id" value={list.id} />
        ) : null}
        <Row className={styles.row}>
          <Col md="2">Sort Order</Col>
          <Col md="10">
            <Input
              className={styles.input}
              type="number"
              name="sortOrder"
              defaultValue={list.sortOrder}
            />
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md="2">
            Main Header <br /> (max 35 chars)
          </Col>
          <Col md="10">
            <Input
              className={styles.input}
              type="text"
              name="title"
              maxLength="35"
              defaultValue={list.title}
            />
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md="2">
            Sub Heading <br /> (max 40 chars)
          </Col>
          <Col md="10">
            <Input
              className={styles.input}
              type="text"
              name="subTitle"
              maxLength="40"
              defaultValue={list.subTitle}
            />
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md="2">
            Description <br /> (max 120 chars)
          </Col>
          <Col md="10">
            <Input
              className={styles.input}
              type="text"
              name="description"
              maxLength="120"
              defaultValue={list.description}
            />
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md="2">CTA Text</Col>
          <Col md="10">
            <Input
              className={styles.input}
              type="text"
              name="ctaText"
              defaultValue={list.ctaText}
            />
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col md="2">CTA URL</Col>
          <Col md="10">
            <Input
              className={styles.input}
              type="text"
              name="ctaUrl"
              defaultValue={list.ctaUrl}
            />
          </Col>
        </Row>
        <Row className={styles.buttonContainer}>
          <Button
            disabled={updating}
            type="primary"
            onClick={() => {}}
            color={list.id ? 'success' : 'primary'}
            className={styles.button}
          >
            {list.id
              ? updating
                ? 'Updating...'
                : 'Update Banner'
              : updating
                ? 'Creating...'
                : 'Create Banner'}
          </Button>
          {list.id ? (
            <Button
              disabled={deleting}
              type="button"
              color="danger"
              onClick={() => handleDelete(list.id)}
              className={styles.button}
            >
              {deleting ? 'Deleting...' : 'Delete'}
            </Button>
          ) : null}
        </Row>
      </Form>
    </fieldset>
  )
}

ProductFeatureAnnouncementForm.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  list: PropTypes.object,
  addProductFeatureAnnouncement: PropTypes.func.isRequired,
  updateProductFeatureAnnouncement: PropTypes.func.isRequired,
  removeProductFeatureAnnouncement: PropTypes.func.isRequired
}

ProductFeatureAnnouncementForm.defaultProps = {
  list: {}
}

export default memo(ProductFeatureAnnouncementForm)
