import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_ROLE_NODES } from '../../../schemas/queries'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Component */
import ToggleButton from 'shared/components/ToggleButton'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

const PermissionList = ({ handleToggle, handleSubmit }) => (
  <Query query={GET_LOCAL_ROLE_NODES}>
    {({ data: { rolePermissions } }) => (
      <React.Fragment>
        <Table bordered>
          <thead>
            <tr>
              <th>Description</th>
              <th>View</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {rolePermissions.map(node => (
              <tr key={`permission-${node.id}`}>
                <td>{node.description}</td>
                <td>
                  <ToggleButton
                    isChecked={node.canView === 1}
                    onChange={() =>
                      handleToggle(
                        node.canView === 1 ? 0 : 1,
                        node.canEdit,
                        node.id
                      )
                    }
                  />
                </td>
                <td>
                  <ToggleButton
                    isChecked={node.canEdit === 1}
                    onChange={() =>
                      handleToggle(
                        node.canView,
                        node.canEdit === 1 ? 0 : 1,
                        node.id
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Can I="edit" a="admin_user">
          <div style={{ textAlign: 'right' }}>
            <Button color="info" onClick={() => handleSubmit(rolePermissions)}>
              Save
            </Button>
          </div>
        </Can>
      </React.Fragment>
    )}
  </Query>
)

PermissionList.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default PermissionList
