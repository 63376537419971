import React, { Component } from 'react'
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'
import SupportedCountrySelect from '../../../../country/components/CountrySelect'
import { Query } from 'react-apollo'
import { Col, Label, FormGroup } from 'reactstrap'
import { GET_FEATURED_BANNER } from '../../../schemas/queries'
import FeaturedBannerContainer from '../FeaturedBannerContainer'

class LandingPageBannerFilterContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      countryId: this.getLocalCountry()
    }
  }

  getLocalCountry = () => {
    let countryId = localStorage.getItem('landingPageBannerCountryId')
    return countryId ? parseInt(countryId) : 167
  }

  setLocalCountry = value => {
    localStorage.setItem('landingPageBannerCountryId', value)
  }

  onChange = value => {
    this.setState({ countryId: parseInt(value) }, () => {
      this.setLocalCountry(value)
    })
  }

  render() {
    let { countryId } = this.state
    countryId = parseInt(countryId)

    return (
      <div>
        <SectionWrapper sectionTitle="Landing Page Banner" isMainPage>
          <FormGroup row>
            <Label
              for="countrySelect"
              lg={1}
              md={4}
              sm={12}
              className="col-form-label"
            >
              Country
            </Label>
            <Col lg={3} md={8} sm={12}>
              <SupportedCountrySelect
                countryId={countryId}
                onChange={this.onChange}
              />
            </Col>
          </FormGroup>
          <p style={{ color: 'red', fontSize: '16px' }}>
            Sort order must be unique among all the banners! (create and edit
            banner 1 by 1 as the page will be refreshed upon request)
          </p>
          <Query
            query={GET_FEATURED_BANNER}
            variables={{ countryId }}
            fetchPolicy="no-cache"
          >
            {({ data: { featureBanners }, loading, error }) => {
              if (loading) {
                return <Loading />
              } else if (error) {
                return <div>Something went wrong.</div>
              }

              if (featureBanners) {
                return (
                  <FeaturedBannerContainer
                    featureBanners={featureBanners}
                    countryId={countryId}
                  />
                )
              }
              return null
            }}
          </Query>
        </SectionWrapper>
      </div>
    )
  }
}

export default LandingPageBannerFilterContainer
