import gql from 'graphql-tag'

// Get all maintenance list
export const GET_NOTIFY_MAINTAIN_LIST = gql`
  query($page: Int!, $size: Int!) {
    getWebsiteOutageList(page: $page, size: $size) {
      ok
      message
      data {
        dataList {
          submitUuid
          contentList {
            languageCode
            value
          }
          startedAt
          endedAt
          updatedAt
        }
        totalCount
        totalPage
        currentPage
      }
    }
  }
`

// Get maintenance info
export const GET_NOTIFY_MAINTAIN_INFO = gql`
  query getWebsiteOutage($submitUuid: String!) {
    getWebsiteOutage(submitUuid: $submitUuid) {
      ok
      message
      data {
        submitUuid
        startedAt
        endedAt
        contentList {
          languageCode
          value
        }
      }
    }
  }
`
