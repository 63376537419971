exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".navigation__3R6fB {\n  margin-bottom: 25px;\n}\n\n.menu__3kKFZ {\n  padding: 10px 15px;\n  font-weight: bold;\n  color: #2f353a;\n  margin-right: 10px;\n}\n.menuActive__11cUj {\n  background: #20a8d8;\n  color: #fff;\n  border-radius: 5px;\n  pointer-events: none;\n  cursor: default;\n}\n.menuList__2yy7c {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n}", "", {"version":3,"sources":["/app/src/modules/agencies/views/AgencyDetail/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAmB;CACrB;;AAEA;EACE,mBAAkB;EAClB,kBAAiB;EACjB,eAAc;EACd,mBAAkB;CACpB;AACA;EACE,oBAAmB;EACnB,YAAW;EACX,mBAAkB;EAClB,qBAAoB;EACpB,gBAAe;CACjB;AACA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,wBAA2B;MAA3B,qBAA2B;UAA3B,4BAA2B;CAC7B","file":"Navigation.module.scss","sourcesContent":[".navigation {\n  margin-bottom: 25px;\n}\n\n.menu {\n  padding: 10px 15px;\n  font-weight: bold;\n  color: #2f353a;\n  margin-right: 10px;\n}\n.menuActive {\n  background: #20a8d8;\n  color: #fff;\n  border-radius: 5px;\n  pointer-events: none;\n  cursor: default;\n}\n.menuList {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"navigation": "navigation__3R6fB",
	"menu": "menu__3kKFZ",
	"menuActive": "menuActive__11cUj",
	"menuList": "menuList__2yy7c"
};