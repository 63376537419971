import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { withApollo, compose, graphql } from 'react-apollo'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Components */
// import ImageField from 'shared/components/ImageField'
import Loading from 'shared/components/Loading'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'
import {
  UPDATE_HEADHUNTER_DETAIL,
  ADD_HEADHUNTER,
  CHANGE_HEADHUNTER_AGENCY,
  UNASSIGN_HEADHUNTER_AGENCY,
  RESEND_HEADHUNTER_PASSWORD
} from '../../../../schemas/mutations'

/**
 * AccountSettingForm - form component for BossJobUserDetail - AccountSetting page
 *
 * @returns {Component}
 */
const AccountSettingForm = ({
  userData: {
    // avatar,
    firstName,
    lastName,
    email,
    contactNum,
    isActive,
    company,
    poeaLicense,
    doleLicense,
    jobTitle,
    resumeParserRemainingCredit
  },
  agencyList,
  headhunterId,
  handleChange,
  // handleImageChange,
  // previewImage,
  // imagePreviewUrl,
  updateHeadhunter,
  registerHeadhunter,
  changeHeadhunterCompany,
  headhunterServerData,
  unassignHeadhunterCompany,
  resendHeadhunterPassword
}) => {
  const [isLoading, setLoadingState] = useState(false)
  const [isResending, setIsResending] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const conditionalChecking = async companyId => {
    let companyIdFromServer =
      headhunterServerData.company && headhunterServerData.company.id
        ? headhunterServerData.company.id
        : null
    // if no change in agency, do nothing
    if (companyId === companyIdFromServer) {
      return true
      // if agency changed to 0 => user unassigning agency
    } else if (companyId == 0) {
      try {
        let unassignHeadhunterAgencyResponse = await unassignHeadhunterCompany({
          variables: {
            headhunterId: parseInt(headhunterId)
          }
        })
        if (unassignHeadhunterAgencyResponse.deleteHeadhunterCompany.ok)
          return true
        return false
      } catch (err) {
        console.log('Error unassigning agency', err)
      }
    } else {
      try {
        let changeHeadhunterAgencyResponse = await changeHeadhunterCompany({
          variables: {
            companyId,
            headhunterId: parseInt(headhunterId)
          }
        })
        if (changeHeadhunterAgencyResponse.deleteHeadhunterCompany.ok)
          return true
        return false
      } catch (err) {
        console.log('Error changing agency', err)
      }
    }
  }

  const handleSubmit = async submitType => {
    setLoadingState(true)

    // if fields have empty input, display error, else proceed to submit mutation
    if (!firstName || !lastName || !email) {
      // if (firstName == '' || lastName == '' || email == '') {
      setError('Please ensure all fields marked as (*) are complete')
      setLoadingState(false)
    } else {
      try {
        const regex = /\S+@\S+\.\S+/g
        let emailValid = regex.test(email)

        if (emailValid) {
          setError('')

          if (submitType === 'add') {
            let addHeadhunterResponse = await registerHeadhunter({
              variables: {
                headhunterInput: {
                  email,
                  firstName,
                  lastName,
                  poeaLicense,
                  doleLicense,
                  contactNum: contactNum ? contactNum : ''
                }
              }
            })
            if (addHeadhunterResponse) {
              let status = addHeadhunterResponse.data.registerHeadhunter
              if (status.ok) {
                setLoadingState(false)
                // just a UX to show user it has been updated
                window.location.href = '/bosshunt/users/1'
              } else {
                setLoadingState(false)
                setError(
                  'Something went wrong, please notify and try again later'
                )
              }
            }
          } else {
            // turn isActive string into boolean
            let filteredIsActive
            if (isActive == 'true') filteredIsActive = true
            if (isActive == 'false') filteredIsActive = false
            // then update
            let updateHeadhunterResponse = await updateHeadhunter({
              variables: {
                headhunterId: Number(headhunterId),
                updateHeadhunterInput: {
                  // to change to blobFile to pass to backend instead
                  firstName,
                  lastName,
                  email,
                  contactNum,
                  poeaLicense,
                  doleLicense,
                  jobTitle,
                  isActive: filteredIsActive
                }
              }
            })

            let changeAgencyStatus = false

            if (company && company.id) {
              changeAgencyStatus = conditionalChecking(company.id)
            } else {
              changeAgencyStatus = conditionalChecking(0)
            }
            let updateHeadhunterStatus =
              updateHeadhunterResponse.data.updateHeadhunter.ok

            if (updateHeadhunterStatus || changeAgencyStatus) {
              setLoadingState(false)
              // just a UX to show user it has been updated
              window.location.reload()
            } else {
              setLoadingState(false)
              setError(
                'Something went wrong, please notify and try again later.'
              )
            }
          }
        } else {
          setLoadingState(false)
          setError('Please ensure email is valid')
        }
      } catch (err) {
        setError(err.stack)
        // setError(err.graphQLErrors[0].message)
        setLoadingState(false)
      }
    }
  }

  const emailLoginCredentials = async () => {
    setIsResending(true)
    let response = await resendHeadhunterPassword(headhunterId)
    let status = response.data.resendHeadhunterPassword
    if (status.ok) {
      setIsResending(false)
      setMessage('Successfully resend login credentials')
    } else {
      setIsResending(false)
      setError(
        'Unable to resend login credentials, please notify and try again later'
      )
    }
  }

  return (
    <Form>
      {/* <div style={{ textAlign: 'center', marginBottom: '2em' }}>
        <Can I="edit" a="headhunters" passThrough>
          {can => (
            <ImageField
              hasPermission={can && isActive}
              name="avatar"
              imageSrc={imagePreviewUrl !== '' ? imagePreviewUrl : avatar}
              handleChange={handleImageChange}
            />
          )}
        </Can>
      </div> */}
      <Row>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              First Name*
            </Label>
            <Col md={9} sm={12}>
              <Input
                disabled={!isActive}
                name="firstName"
                value={firstName}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              Last Name*
            </Label>
            <Col md={9} sm={12}>
              <Input
                disabled={!isActive}
                name="lastName"
                value={lastName}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              Email*
            </Label>
            <Col md={9} sm={12}>
              <Input
                disabled={!isActive}
                name="email"
                value={email}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              Mobile Number
            </Label>
            <Col md={9} sm={12}>
              <Input
                disabled={!isActive}
                name="contactNum"
                value={contactNum}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              POEA License
            </Label>
            <Col md={9} sm={12}>
              <Input
                name="poeaLicense"
                // value={license}
                value={poeaLicense ? poeaLicense : null}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              DOLE License
            </Label>
            <Col md={9} sm={12}>
              <Input
                name="doleLicense"
                // value={license}
                value={doleLicense ? doleLicense : null}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      {headhunterId !== 'new' ? (
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Agency Name
              </Label>
              <Col md={9} sm={12}>
                <Input
                  disabled={!isActive}
                  type="select"
                  name={'company'}
                  value={company && company.id ? company.id : 0}
                  onChange={handleChange}
                >
                  {agencyList.map(agency => (
                    <option key={agency.id} value={agency.id}>
                      {agency.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={3} sm={12}>
                Job Title
              </Label>
              <Col md={9} sm={12}>
                <Input
                  name="jobTitle"
                  value={jobTitle ? jobTitle : null}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              Password
            </Label>
            <Col md={9} sm={12}>
              <Input disabled name="password" value="xxxx" type="password" />
            </Col>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              Status
            </Label>
            <Col md={9} sm={12}>
              <Input
                disabled={headhunterId === 'new'}
                name="isActive"
                type="select"
                value={isActive}
                onChange={handleChange}
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup row>
            <Label md={3} sm={12}>
              Resume Parser Remaining Credit
            </Label>
            <Col md={9} sm={12}>
              <Input
                disabled
                name="resumeParserRemainingCredit"
                value={resumeParserRemainingCredit}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      {error ? (
        <span style={{ color: 'red', marginBottom: '10px' }}>{error}</span>
      ) : null}
      {message ? (
        <span style={{ color: 'green', marginBottom: '10px' }}>{message}</span>
      ) : null}
      {headhunterId !== 'new' ? (
        isActive ? (
          <Can I="edit" a="headhunters">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '4vw'
              }}
            >
              <div style={{ marginRight: '1vw' }}>
                {isResending ? (
                  <Loading />
                ) : (
                  <Button color="info" onClick={() => emailLoginCredentials()}>
                    Email Login Credentials
                  </Button>
                )}
              </div>
              {isLoading ? (
                <Loading />
              ) : (
                <Button color="info" onClick={() => handleSubmit('update')}>
                  Save
                </Button>
              )}
            </div>
          </Can>
        ) : (
          <>
            <Can I="edit" a="headhunters">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '4vw'
                }}
              >
                <div style={{ marginRight: '1vw' }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Button color="info" onClick={() => handleSubmit('update')}>
                      Save
                    </Button>
                  )}
                </div>
              </div>
            </Can>
          </>
        )
      ) : null}
      {headhunterId === 'new' && (
        <Can I="edit" a="headhunters">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '4vw'
            }}
          >
            <Button color="info" onClick={() => handleSubmit('add')}>
              Add
            </Button>
          </div>
        </Can>
      )}
    </Form>
  )
}

AccountSettingForm.propTypes = {
  userData: PropTypes.object.isRequired,

  handleChange: PropTypes.func.isRequired,

  handleImageChange: PropTypes.func.isRequired,

  imagePreviewUrl: PropTypes.string,

  headhunterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  updateHeadhunter: PropTypes.func.isRequired,

  previewImage: PropTypes.object.isRequired,

  agencyList: PropTypes.array.isRequired,

  registerHeadhunter: PropTypes.func.isRequired,

  changeHeadhunterCompany: PropTypes.func.isRequired,

  unassignHeadhunterCompany: PropTypes.func.isRequired,

  resendHeadhunterPassword: PropTypes.func.isRequired,

  headhunterServerData: PropTypes.object
}

export default compose(
  withApollo,
  graphql(UPDATE_HEADHUNTER_DETAIL, { name: 'updateHeadhunter' }),
  graphql(ADD_HEADHUNTER, { name: 'registerHeadhunter' }),
  graphql(CHANGE_HEADHUNTER_AGENCY, { name: 'changeHeadhunterCompany' }),
  graphql(UNASSIGN_HEADHUNTER_AGENCY, { name: 'unassignHeadhunterCompany' }),
  graphql(RESEND_HEADHUNTER_PASSWORD, { name: 'resendHeadhunterPassword' })
)(AccountSettingForm)
