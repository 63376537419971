import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Bootstrap */
import { Col, Card, CardBody } from 'reactstrap'

/* Assets */
import styles from './Navigation.module.scss'

/**
 * Navigation - navigation component to show menu of Bossjob user detail
 *
 * @returns {Component}
 *
 */
const Navigation = ({ match, location }) => {
  return (
    <div className={styles.navigation}>
      <Col md="12">
        <Card>
          <CardBody>
            <div className={styles.menuList}>
              <div>
                <a
                  href={`/bossjob/company/${match.params.id}/information`}
                  className={
                    location.pathname.includes('information')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Company Information
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/company/${match.params.id}/recruiters/1`}
                  className={
                    location.pathname.includes('recruiters')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Recruiters
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/company/${match.params.id}/reviews/1`}
                  className={
                    location.pathname.includes('reviews')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Reviews
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/company/${match.params.id}/internal/1`}
                  className={
                    location.pathname.includes('internal')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Internal
                </a>
              </div>
              <div>
                <a
                  href={`/bossjob/company/${match.params.id}/analytics/1`}
                  className={
                    location.pathname.includes('analytics')
                      ? `${styles.menu} ${styles.menuActive}`
                      : styles.menu
                  }
                >
                  Analytics
                </a>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}

Navigation.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,

  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(Navigation)
