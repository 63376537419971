import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { withApollo, compose, graphql } from 'react-apollo'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import ToggleButton from 'shared/components/ToggleButton'

import { CREATE_SUBSCRIPTION_PLAN } from '../../../schemas/mutations'

/**
 * NewForm
 *
 * @returns {Component}
 */
class NewForm extends Component {
  static propTypes = {
    subscriptionPlan: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    handleChangeForToggleButton: PropTypes.func.isRequired,
    createSubscriptionPlan: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      isUpdating: false
    }
  }

  _renderSubscriptionPlanIDField() {
    return (
      <FormGroup>
        <Label>Subscription Plan ID</Label>
        <Input type="text" disabled={true} />
      </FormGroup>
    )
  }

  _renderCategoryField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Category</Label>
        <Input
          type="select"
          name="category"
          value={subscriptionPlan.categoryId}
          onChange={handleChange}
        >
          <option value="4">enterprise</option>
        </Input>
      </FormGroup>
    )
  }

  _renderNameField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Name</Label>
        <Input
          type="text"
          name="name"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.name
              ? subscriptionPlan.name
              : ''
          }
        />
      </FormGroup>
    )
  }

  _renderMonthlyFeeField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Monthly Fee</Label>
        <Input
          type="number"
          name="monthlyFee"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.monthlyFee
              ? subscriptionPlan.monthlyFee
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderAnnualFeeField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Annual Fee</Label>
        <Input
          type="number"
          name="annualFee"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.annualFee
              ? subscriptionPlan.annualFee
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderMonthlyJobCreditField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Monthly Job Credit</Label>
        <Input
          type="number"
          name="monthlyJobCredit"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.monthlyJobCredit
              ? subscriptionPlan.monthlyJobCredit
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderMonthlyFeatureJobCreditField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Monthly Feature Job Credit</Label>
        <Input
          type="number"
          name="monthlyFeatureJobCredit"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.monthlyFeatureJobCredit
              ? subscriptionPlan.monthlyFeatureJobCredit
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderMonthlyUrgentJobCreditField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Monthly Urgent Job Credit</Label>
        <Input
          type="number"
          name="monthlyUrgentJobCredit"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.monthlyUrgentJobCredit
              ? subscriptionPlan.monthlyUrgentJobCredit
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderMonthlyUnlockTalentCreditField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Monthly Talent Credit</Label>
        <Input
          type="number"
          name="monthlyUnlockTalentCredit"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.monthlyUnlockTalentCredit
              ? subscriptionPlan.monthlyUnlockTalentCredit
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderMonthlyRhTalentCreditField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Monthly RH Talent Credit</Label>
        <Input
          type="number"
          name="monthlyRhTalentCredit"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.monthlyRhTalentCredit
              ? subscriptionPlan.monthlyRhTalentCredit
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderMaxNoOfScreeningQuestionsPerJobField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Max No Of Screening Questions Per Job</Label>
        <Input
          type="number"
          name="maxNoOfScreeningQuestionsPerJob"
          onChange={handleChange}
          value={
            subscriptionPlan && subscriptionPlan.maxNoOfScreeningQuestionsPerJob
              ? subscriptionPlan.maxNoOfScreeningQuestionsPerJob
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderMaxNoOfEmailsToReceiveResumePerJobField() {
    const { handleChange, subscriptionPlan } = this.props
    return (
      <FormGroup>
        <Label>Max No Of Emails To Receive Resume Per Job</Label>
        <Input
          type="number"
          name="maxNoOfEmailsToReceiveResumePerJob"
          onChange={handleChange}
          value={
            subscriptionPlan &&
            subscriptionPlan.maxNoOfEmailsToReceiveResumePerJob
              ? subscriptionPlan.maxNoOfEmailsToReceiveResumePerJob
              : ''
          }
          placeholder={0}
        />
      </FormGroup>
    )
  }

  _renderPartnerNetworkField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Partner Network
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="partnerNetwork"
            isChecked={subscriptionPlan.partnerNetwork === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderFeatureJobField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Feature Job
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="featureJob"
            isChecked={subscriptionPlan.featureJob === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderUrgentJobField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Urgent Job
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="urgentJob"
            isChecked={subscriptionPlan.urgentJob === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderAutoGenerateJobDescriptionField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Auto Generate Job Description
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="autoGenerateJobDescription"
            isChecked={subscriptionPlan.autoGenerateJobDescription === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderScreeningQuestionField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Screening Question
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="screeningQuestion"
            isChecked={subscriptionPlan.screeningQuestion === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderJobMirroringField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Job Mirroring
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="jobMirroring"
            isChecked={subscriptionPlan.jobMirroring === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderRedirectExternalUrlField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Redirect External Url
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="redirectExternalUrl"
            isChecked={subscriptionPlan.redirectExternalUrl === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderJobSalaryMaskingField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Job Salary Masking
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="jobSalaryMasking"
            isChecked={subscriptionPlan.jobSalaryMasking === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderViewApplicationOnWebsiteField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          View Application On Website
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="viewApplicationOnWebsite"
            isChecked={subscriptionPlan.viewApplicationOnWebsite === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderApplicantStatusTrackingField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Applicant Status Tracking
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="applicantStatusTracking"
            isChecked={subscriptionPlan.applicantStatusTracking === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderReceiveResumeInEmailField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Receive Resume In Email
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="receiveResumeInEmail"
            isChecked={subscriptionPlan.receiveResumeInEmail === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderAdvanceResumeScoringField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Advance Resume Scoring
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="advanceResumeScoring"
            isChecked={subscriptionPlan.advanceResumeScoring === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderExportApplicationInExcelField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Export Application In Excel
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="exportApplicationInExcel"
            isChecked={subscriptionPlan.exportApplicationInExcel === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderLiveChatWithApplicantField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Live Chat With Applicant
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="liveChatWithApplicant"
            isChecked={subscriptionPlan.liveChatWithApplicant === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderBrandedEmployerCareerPageField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Branded Employer Career Page
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="brandedEmployerCareerPage"
            isChecked={subscriptionPlan.brandedEmployerCareerPage === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderTopEmployerLogoField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Top Employer Logo
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="topEmployerLogo"
            isChecked={subscriptionPlan.topEmployerLogo === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderBasicStatisticJobViewAndApplicationField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Basic Statistic Job View And Application
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="basicStatisticJobViewAndApplication"
            isChecked={
              subscriptionPlan.basicStatisticJobViewAndApplication === true
            }
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderExportJobStatisticInExcelField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Export Job Statistic In Excel
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="exportJobStatisticInExcel"
            isChecked={subscriptionPlan.exportJobStatisticInExcel === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderPhoneChatEmailSupportField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Phone Chat Email Support
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="phoneChatEmailSupport"
            isChecked={subscriptionPlan.phoneChatEmailSupport === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderDedicatedAccountManagerField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Dedicated Account Manager
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="dedicatedAccountManager"
            isChecked={subscriptionPlan.dedicatedAccountManager === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderRoboHeadhuntingField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Robo Headhunting
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="roboHeadhunting"
            isChecked={subscriptionPlan.roboHeadhunting === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderResumeRequestField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Resume Request
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="resumeRequest"
            isChecked={subscriptionPlan.resumeRequest === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderBosshuntAtsField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Bosshunt Ats
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="bosshuntAts"
            isChecked={subscriptionPlan.bosshuntAts === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderCustomizeChatAutoResponseField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Customize Chat Auto Response
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="customizeChatAutoResponse"
            isChecked={subscriptionPlan.customizeChatAutoResponse === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderAutoApproveJobField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Auto Approve Job
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="autoApproveJob"
            isChecked={subscriptionPlan.autoApproveJob === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderAutoReplyChatMessageField() {
    const { handleChangeForToggleButton, subscriptionPlan } = this.props
    return (
      <FormGroup row>
        <Label md={6} sm={12}>
          Auto Reply Chat Message
        </Label>
        <Col md={6} sm={12}>
          <ToggleButton
            name="autoReplyChatMessage"
            isChecked={subscriptionPlan.autoReplyChatMessage === true}
            onChange={handleChangeForToggleButton}
          />
        </Col>
      </FormGroup>
    )
  }

  _renderAddButton() {
    const { createSubscriptionPlan, subscriptionPlan } = this.props
    const { isUpdating } = this.state

    const handleSubmit = async () => {
      this.setState({ isUpdating: true })

      let response = await createSubscriptionPlan({
        variables: {
          subscriptionPlanInput: {
            categoryId: subscriptionPlan.categoryId,
            name: subscriptionPlan.name,
            monthlyFee:
              subscriptionPlan.monthlyFee == ''
                ? 0
                : subscriptionPlan.monthlyFee,
            annualFee:
              subscriptionPlan.annualFee == '' ? 0 : subscriptionPlan.annualFee,
            monthlyJobCredit:
              subscriptionPlan.monthlyJobCredit == ''
                ? 0
                : subscriptionPlan.monthlyJobCredit,
            monthlyFeatureJobCredit:
              subscriptionPlan.monthlyFeatureJobCredit == ''
                ? 0
                : subscriptionPlan.monthlyFeatureJobCredit,
            monthlyUrgentJobCredit:
              subscriptionPlan.monthlyUrgentJobCredit == ''
                ? 0
                : subscriptionPlan.monthlyUrgentJobCredit,
            monthlyUnlockTalentCredit:
              subscriptionPlan.monthlyUnlockTalentCredit == ''
                ? 0
                : subscriptionPlan.monthlyUnlockTalentCredit,
            monthlyRhTalentCredit:
              subscriptionPlan.monthlyRhTalentCredit == ''
                ? 0
                : subscriptionPlan.monthlyRhTalentCredit,
            maxNoOfScreeningQuestionsPerJob:
              subscriptionPlan.maxNoOfScreeningQuestionsPerJob == ''
                ? 0
                : subscriptionPlan.maxNoOfScreeningQuestionsPerJob,
            maxNoOfEmailsToReceiveResumePerJob:
              subscriptionPlan.maxNoOfEmailsToReceiveResumePerJob == ''
                ? 0
                : subscriptionPlan.maxNoOfEmailsToReceiveResumePerJob
          },
          subscriptionFeatureInput: {
            partnerNetwork: subscriptionPlan.partnerNetwork,
            featureJob: subscriptionPlan.featureJob,
            urgentJob: subscriptionPlan.urgentJob,
            autoGenerateJobDescription:
              subscriptionPlan.autoGenerateJobDescription,
            screeningQuestion: subscriptionPlan.screeningQuestion,
            jobMirroring: subscriptionPlan.jobMirroring,
            redirectExternalUrl: subscriptionPlan.redirectExternalUrl,
            jobSalaryMasking: subscriptionPlan.jobSalaryMasking,
            viewApplicationOnWebsite: subscriptionPlan.viewApplicationOnWebsite,
            applicantStatusTracking: subscriptionPlan.applicantStatusTracking,
            receiveResumeInEmail: subscriptionPlan.receiveResumeInEmail,
            advanceResumeScoring: subscriptionPlan.advanceResumeScoring,
            exportApplicationInExcel: subscriptionPlan.exportApplicationInExcel,
            liveChatWithApplicant: subscriptionPlan.liveChatWithApplicant,
            brandedEmployerCareerPage:
              subscriptionPlan.brandedEmployerCareerPage,
            topEmployerLogo: subscriptionPlan.topEmployerLogo,
            basicStatisticJobViewAndApplication:
              subscriptionPlan.basicStatisticJobViewAndApplication,
            exportJobStatisticInExcel:
              subscriptionPlan.exportJobStatisticInExcel,
            phoneChatEmailSupport: subscriptionPlan.phoneChatEmailSupport,
            dedicatedAccountManager: subscriptionPlan.dedicatedAccountManager,
            roboHeadhunting: subscriptionPlan.roboHeadhunting,
            resumeRequest: subscriptionPlan.resumeRequest,
            bosshuntAts: subscriptionPlan.bosshuntAts,
            customizeChatAutoResponse:
              subscriptionPlan.customizeChatAutoResponse,
            autoApproveJob: subscriptionPlan.autoApproveJob,
            autoReplyChatMessage: subscriptionPlan.autoReplyChatMessage
          }
        }
      })

      let responseStatus = response.data.createSubscriptionPlan.ok

      if (responseStatus) {
        window.location.href = '/bossjob/subscription-plans/1'
      } else {
        this.setState({ isUpdating: false })
      }
    }

    return (
      <Button color="info" onClick={() => handleSubmit()} disabled={isUpdating}>
        Add
      </Button>
    )
  }

  render() {
    return (
      <>
        <SectionWrapper sectionTitle="Details">
          <Form>
            <Row>
              <Col md={6}>{this._renderSubscriptionPlanIDField()}</Col>
              <Col md={6}>{this._renderCategoryField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderNameField()}</Col>
              <Col md={6}>{this._renderMonthlyFeeField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderAnnualFeeField()}</Col>
              <Col md={6}>{this._renderMonthlyJobCreditField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderMonthlyFeatureJobCreditField()}</Col>
              <Col md={6}>{this._renderMonthlyUrgentJobCreditField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderMonthlyUnlockTalentCreditField()}</Col>
              <Col md={6}>{this._renderMonthlyRhTalentCreditField()}</Col>
            </Row>
            <Row>
              <Col md={6}>
                {this._renderMaxNoOfScreeningQuestionsPerJobField()}
              </Col>
              <Col md={6}>
                {this._renderMaxNoOfEmailsToReceiveResumePerJobField()}
              </Col>
            </Row>
          </Form>
        </SectionWrapper>
        <SectionWrapper sectionTitle="Features">
          <Form>
            <Row>
              <Col md={6}>{this._renderPartnerNetworkField()}</Col>
              <Col md={6}>{this._renderFeatureJobField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderUrgentJobField()}</Col>
              <Col md={6}>{this._renderAutoGenerateJobDescriptionField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderScreeningQuestionField()}</Col>
              <Col md={6}>{this._renderJobMirroringField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderRedirectExternalUrlField()}</Col>
              <Col md={6}>{this._renderJobSalaryMaskingField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderViewApplicationOnWebsiteField()}</Col>
              <Col md={6}>{this._renderApplicantStatusTrackingField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderReceiveResumeInEmailField()}</Col>
              <Col md={6}>{this._renderAdvanceResumeScoringField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderExportApplicationInExcelField()}</Col>
              <Col md={6}>{this._renderLiveChatWithApplicantField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderBrandedEmployerCareerPageField()}</Col>
              <Col md={6}>{this._renderTopEmployerLogoField()}</Col>
            </Row>
            <Row>
              <Col md={6}>
                {this._renderBasicStatisticJobViewAndApplicationField()}
              </Col>
              <Col md={6}>{this._renderExportJobStatisticInExcelField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderPhoneChatEmailSupportField()}</Col>
              <Col md={6}>{this._renderDedicatedAccountManagerField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderRoboHeadhuntingField()}</Col>
              <Col md={6}>{this._renderResumeRequestField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderBosshuntAtsField()}</Col>
              <Col md={6}>{this._renderCustomizeChatAutoResponseField()}</Col>
            </Row>
            <Row>
              <Col md={6}>{this._renderAutoApproveJobField()}</Col>
              <Col md={6}>{this._renderAutoReplyChatMessageField()}</Col>
            </Row>
            <Row>
              <Col md={12} className="text-right">
                {this._renderAddButton()}
              </Col>
            </Row>
          </Form>
        </SectionWrapper>
      </>
    )
  }
}

export default compose(
  withApollo,
  graphql(CREATE_SUBSCRIPTION_PLAN, {
    name: 'createSubscriptionPlan'
  })
)(NewForm)
