import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, Button, FormGroup, Label, Input, Row, Col } from 'reactstrap'

/* Apollo */
import { graphql, compose } from 'react-apollo'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'
import { UPLOAD_SUBSCRIPTION_CONTRACT } from '../../../../schemas/mutations'

/**
 * ContractUploadForm - A part of the Subscription Detail for contract uploading
 * and display of contractUrl, storing of File Upload data will be done via React state
 * instead of local GraphQL cache
 */
const ContractUploadForm = ({
  subscriptionDetail,
  subscriptionId,
  uploadSubscriptionContract
}) => {
  const [file, setFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const handleFileSelect = e => {
    const file = e.target.files[0]
    if (e.target.validity.valid) {
      setFile(file)
    }
  }

  const handleFileUpload = async e => {
    try {
      e.preventDefault()
      setIsUploading(true)

      await uploadSubscriptionContract({
        variables: {
          contractFile: file,
          userSubscriptionId: subscriptionId
        }
      })
      setIsUploading(false)
      window.location.reload()
    } catch (err) {
      console.log('error uploading contract', err)
    }
  }

  return (
    <SectionWrapper sectionTitle="Attachment">
      <fieldset>
        <Form>
          <Row>
            <Col md={6} style={{ marginBottom: '10px' }}>
              <Label style={{ marginRight: '5px' }}>
                Contract Attachment :
              </Label>
              <a
                href={subscriptionDetail.contractUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {subscriptionDetail.contractUrl ? 'Contract' : ' -'}
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  {subscriptionDetail.contractUrl
                    ? 'Choose a file to replace existing contract'
                    : 'Upload Contract'}
                </Label>
                <Input
                  type="file"
                  name="contractFile"
                  onChange={handleFileSelect}
                />
              </FormGroup>
            </Col>
            <Col
              md={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center'
              }}
            >
              {isUploading ? (
                <Loading />
              ) : (
                <Button
                  type="primary"
                  color="info"
                  onClick={handleFileUpload}
                  disabled={!file}
                >
                  {subscriptionDetail.contractUrl ? 'Replace' : 'Upload'}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </fieldset>
    </SectionWrapper>
  )
}

ContractUploadForm.propTypes = {
  subscriptionDetail: PropTypes.object.isRequired,
  subscriptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  uploadSubscriptionContract: PropTypes.func.isRequired
}

export default compose(
  graphql(UPLOAD_SUBSCRIPTION_CONTRACT, {
    name: 'uploadSubscriptionContract'
  })
)(ContractUploadForm)
