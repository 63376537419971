/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

/**
 * SubscriptionList
 *
 * @returns {Component}
 */
const SubscriptionList = ({
  subscriptionList,
  currentPage,
  totalNum,
  totalPages
}) => {
  return (
    <>
      <Table bordered>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Name</th>
            <th>Amount (PHP)</th>
            <th>Purchased on</th>
            <th>Cycle</th>
            <th>Next Credit</th>
            <th>Next Payment</th>
            <th>Active</th>
            <th>Actions</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {subscriptionList.map((subscription, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'center' }}>
                <a href={`/bossjob/subscription/detail/${subscription.id}`}>
                  {subscription.id}
                </a>
              </td>
              <td>
                {' '}
                <a href={`/bossjob/user/${subscription.user.id}`}>
                  {subscription.user.email}
                </a>
              </td>
              <td>{subscription.name}</td>
              <td>{subscription.amount}</td>
              <td>
                {subscription.createdAt
                  ? formatDate(subscription.createdAt, 'DD/MM/YYYY HH:mm:ss')
                  : '-'}
              </td>
              <td>{subscription.cycle}</td>
              <td>
                {subscription.nextCreditAt ? (
                  formatDate(subscription.nextCreditAt, 'DD/MM/YYYY')
                ) : (
                  <span style={{ display: 'block', textAlign: 'center' }}>
                    -
                  </span>
                )}
              </td>
              <td>
                {subscription.nextPaymentAt
                  ? formatDate(subscription.nextPaymentAt, 'DD/MM/YYYY')
                  : '-'}
              </td>
              <td>{subscription.isActive ? 'Yes' : 'No'}</td>
              <td>
                <div style={{ textAlign: 'center' }}>
                  <a href={`/bossjob/subscription/detail/${subscription.id}`}>
                    <Button color="success">
                      <img src={Zoom} alt="Zoom" width="16" height="16" />
                    </Button>
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalNum}
        totalPages={totalPages}
        pageRange={4}
      />
    </>
  )
}

SubscriptionList.propTypes = {
  subscriptionList: PropTypes.array.isRequired,
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalNum: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired
}

export default SubscriptionList
