import gql from 'graphql-tag'

export const DEACTIVATE_SUBSCRIPTION = gql`
  mutation DeactivateSubscription($subscriptionId: Int!) {
    deactivateSubscription(subscriptionId: $subscriptionId) {
      ok
      message
    }
  }
`

export const ADD_OFFLINE_SUBSCRIPTION = gql`
  mutation AddOfflineSubscription(
    $subscriptionInput: AddSubscriptionInput
    $userId: Int!
  ) {
    addOfflineSubscription(
      subscriptionInput: $subscriptionInput
      userId: $userId
    ) {
      ok
      message
    }
  }
`

export const UPLOAD_SUBSCRIPTION_CONTRACT = gql`
  mutation UploadSubscriptionContract(
    $contractFile: Upload!
    $userSubscriptionId: Int!
  ) {
    uploadSubscriptionContract(
      contractFile: $contractFile
      userSubscriptionId: $userSubscriptionId
    ) {
      ok
      message
    }
  }
`

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription(
    $userSubscriptionId: Int!
    $subscriptionInput: UpdateSubscriptionInput
  ) {
    updateSubscription(
      userSubscriptionId: $userSubscriptionId
      subscriptionInput: $subscriptionInput
    ) {
      ok
      message
    }
  }
`

/* LOCAL STATE */
export const UPDATE_LOCAL_SUBSCRIPTION_DETAILS = gql`
  mutation UpdateLocalSubscriptionDetails($key: String, $value: String) {
    updateLocalSubscriptionDetails(key: $key, value: $value) @client {
      ok
    }
  }
`
export const UPDATE_LOCAL_SUBSCRIPTION_DETAILS_FOR_NEW_FORM = gql`
  mutation UpdateLocalSubscriptionDetailsForNewForm(
    $key: String
    $value: String
  ) {
    updateLocalSubscriptionDetailsForNewForm(key: $key, value: $value) @client {
      ok
    }
  }
`
