import gql from 'graphql-tag'

export const GET_LOGIN_FORM_ERROR = gql`
  query {
    loginFormError @client {
      error
      errorMessage
    }
  }
`
