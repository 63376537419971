import React, { Component } from 'react'

/* Components */
import ListContainer from '../../containers/ListContainer'
// import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * Maintenance - list view
 *
 */
class Maintenance extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <Can I="edit" a="website_outage_settings" passThrough>
          {can => (
            <SectionWrapper
              sectionTitle="Maintenance List"
              isMainPage
              addUrl={can ? '/maintenance/list/add' : null}
              handleDelete={null}
            >
              <ListContainer />
            </SectionWrapper>
          )}
        </Can>
      </div>
    )
  }
}

export default Maintenance
