/*eslint-disable*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_SUPPORTED_COUNTRY } from '../../../../src/modules/country/schemas/queries'
import { GET_ALL_USERS, GET_ME } from '../../../modules/users/schemas/queries'

/* Bootstrap */
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
  Form
} from 'reactstrap'

/* Vendors */
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import queryString from 'query-string'

/* Helpers */
import { convertToISOFormat } from '../../helpers/datetime'

import 'react-widgets/lib/scss/react-widgets.scss'

import Loading from '../Loading'
import MultiSelectDropdown from '../MultiSelectDropdown'

class SearchBar extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,

    location: ReactRouterPropTypes.location.isRequired,

    /* Renders a Status Field */
    hasStatus: PropTypes.bool,
    hasSupportedCountry: PropTypes.bool,
    hasAuthStatus: PropTypes.bool,
    hasActiveStatus: PropTypes.bool,
    hasCountryFilter: PropTypes.bool,
    hasSalesPersonFilter: PropTypes.bool,
    hasSalesStatusFilter: PropTypes.bool,
    hasWorkArrangementFilter: PropTypes.bool,

    /* Required if hasStatus is True. Format: [{[key]: value }]*/
    statusOptions: PropTypes.array,
    authStatusOptions: PropTypes.array,
    activeStatusOptions: PropTypes.array,
    countryOption: PropTypes.array,
    salesStatusOptions: PropTypes.array,
    workArrangementOptions: PropTypes.array,

    /* Renders a Type Field */
    hasType: PropTypes.bool,

    /* Required if hasType is True. Format: [{[key]: value }]*/
    typeOptions: PropTypes.array,

    /* Renders a Min Installment Field */
    hasMinInstallment: PropTypes.bool,

    /* Reders Subscription Plan Field*/
    hasSubscriptionPlan: PropTypes.bool,

    /* The placeholder text in search bar */
    placeholderForSearch: PropTypes.string,

    /* Disable date time picker for search bar if true */
    hideDate: PropTypes.bool,

    /* Limit input field to type number if true */
    integerOnly: PropTypes.bool,
    searchArr: PropTypes.array
  }

  constructor(props) {
    super(props)

    const {
      search,
      fromDate,
      toDate,
      authStatus,
      activeStatus,
      requestStatus,
      requestTypeId,
      countryId,
      supportedCountryId,
      registeredCountryId,
      type,
      minNoOfInstallment,
      subscriptionPlanId,
      selectedSalesPerson,
      salesStatus
    } = queryString.parse(props.location.search)

    let { workArrangementIds } = queryString.parse(props.location.search)

    if (workArrangementIds && Array.isArray(workArrangementIds) === false) {
      workArrangementIds = workArrangementIds.split(',').map(id => parseInt(id))
    }

    const {
      hasStatus,
      hasAuthStatus,
      hasActiveStatus,
      hasCountryFilter,
      hasSupportedCountry,
      hasRegisteredCountryFilter,
      hasSalesPersonFilter,
      hasSalesStatusFilter,
      hasType,
      test,
      hasMinInstallment,
      hasSubscriptionPlan,
      statusOptions,
      countryOptions,
      joinCompanyRequestStatusOptions,
      joinCompanyRequestTypeOptions,
      authStatusOptions,
      activeStatusOptions,
      typeOptions,
      subscriptionPlanOptions,
      salesStatusOptions
    } = this.props
    const queryObj = queryString.parse(props.location.search)
    this.state = {
      ...queryObj,
      authStatusKey: authStatus
        ? authStatus
        : hasAuthStatus
          ? Object.keys(authStatusOptions[0])[0]
          : '',
      activeStatusKey: activeStatus
        ? activeStatus
        : hasActiveStatus
          ? Object.keys(activeStatusOptions[0])[0]
          : '',
      countryOption: countryId ? countryId : '',
      supportedCountries: null,
      supportedCountryOption: supportedCountryId ? supportedCountryId : '',
      registeredCountryOption: registeredCountryId ? registeredCountryId : '',
      typeKey: type ? type : hasType ? Object.keys(typeOptions[0])[0] : '',
      requestStatusKey: requestStatus ? requestStatus : 'pending',
      requestTypeId: requestTypeId ? requestTypeId : '',
      search: search ? search : '',
      fromDate: fromDate ? new Date(fromDate) : null,
      toDate: toDate ? new Date(toDate) : null,
      minNoOfInstallment: minNoOfInstallment ? minNoOfInstallment : 1,
      subscriptionPlanId: subscriptionPlanId ? subscriptionPlanId : 'all',
      selectedSalesPerson: selectedSalesPerson ? selectedSalesPerson : '',
      salesStatus: salesStatus
        ? salesStatus
        : hasSalesStatusFilter
          ? Object.keys(salesStatusOptions[0])[0]
          : 'all',
      workArrangementIds: workArrangementIds ? workArrangementIds : '',
      error: false
    }
    if (queryObj.status) {
      this.state.statusKey = queryObj.status
    }
  }

  search = () => {
    let host = this.props.match.url + '?'
    let url = ''
    if (this.state.search !== '') {
      url += `search=${encodeURIComponent(this.state.search)}&`
    }

    if (this.state.fromDate && this.state.toDate) {
      url += `fromDate=${convertToISOFormat(
        this.state.fromDate,
        true
      )}&toDate=${convertToISOFormat(this.state.toDate, true, false)}&`
    } else if (
      (this.state.fromDate && !this.state.toDate) ||
      (!this.state.fromDate && this.state.toDate)
    ) {
      this.setState({ error: true })
      return
    }

    if (
      this.props.hasStatus &&
      this.state.statusKey &&
      this.state.statusKey !== '' &&
      this.state.statusKey !== 'all'
    ) {
      url += `status=${this.state.statusKey}&`
    }
    if (
      this.props.hasAuthStatus &&
      this.state.authStatusKey &&
      this.state.statusKey !== '' &&
      this.state.authStatusKey !== 'all'
    ) {
      url += `authStatus=${this.state.authStatusKey}&`
    }
    if (
      this.props.hasActiveStatus &&
      this.state.activeStatusKey &&
      this.state.statusKey !== '' &&
      this.state.activeStatusKey !== 'all'
    ) {
      url += `activeStatus=${this.state.activeStatusKey}&`
    }
    if (this.props.hasCountryFilter && this.state.countryOption) {
      url += `countryId=${this.state.countryOption}&`
    }
    if (this.props.hasSupportedCountry && this.state.supportedCountryOption) {
      url += `supportedCountryId=${this.state.supportedCountryOption}&`
    }

    if (this.props.hasJoinCompanyDataFilter && this.state.requestStatusKey) {
      url += `requestStatus=${this.state.requestStatusKey}&`
    }
    if (this.props.hasJoinCompanyDataFilter && this.state.requestTypeId) {
      url += `requestTypeId=${this.state.requestTypeId}&`
    }

    if (
      this.props.hasType &&
      this.state.typeKey &&
      this.state.typeKey !== 'all'
    ) {
      url += `type=${this.state.typeKey}&`
    }

    if (this.props.hasMinInstallment && this.state.minNoOfInstallment) {
      url += `minNoOfInstallment=${this.state.minNoOfInstallment}&`
    }

    if (
      this.props.hasSubscriptionPlan &&
      this.state.subscriptionPlanId &&
      this.state.subscriptionPlanId !== 'all'
    ) {
      url += `subscriptionPlanId=${this.state.subscriptionPlanId}&`
    }
    if (
      this.props.hasSalesPersonFilter &&
      this.state.selectedSalesPerson &&
      this.state.selectedSalesPerson !== ''
    ) {
      url += `selectedSalesPerson=${this.state.selectedSalesPerson}&`
    }
    if (
      this.props.hasRegisteredCountryFilter &&
      this.state.registeredCountryOption &&
      this.state.registeredCountryOption !== ''
    ) {
      url += `registeredCountryId=${this.state.registeredCountryOption}&`
    }
    if (
      this.props.hasSalesStatusFilter &&
      this.state.salesStatus &&
      this.state.salesStatus !== 'all'
    ) {
      url += `salesStatus=${this.state.salesStatus}&`
    }
    if (this.props.searchArr) {
      this.props.searchArr.map(item => {
        if (!this.state[item.value]) return
        url += `${item.value}=${this.state[item.value]}&`
      })
    }
    if (
      this.props.hasWorkArrangementFilter &&
      this.state.workArrangementIds !== undefined
    ) {
      console.log(this.state.workArrangementIds)
      const workArrangementIdsString =
        Array.isArray(this.state.workArrangementIds) == true
          ? this.state.workArrangementIds.join(',')
          : this.state.workArrangementIds
      url += `workArrangementIds=${workArrangementIdsString}&`
    }
    if (this.props.onSearch) {
      return this.props.onSearch('?' + url)
    }

    window.location = host + url
  }

  renderTypeField() {
    const { typeOptions } = this.props
    const { typeKey } = this.state
    return (
      <FormGroup row>
        <Label lg={3} md={4} sm={12}>
          Type/Role
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="select"
            name="typeKey"
            value={typeKey}
            onChange={e => this.setState({ typeKey: e.target.value })}
          >
            {typeOptions.map((option, index) => {
              return (
                <option key={index} value={Object.keys(option)[0]}>
                  {Object.values(option)[0]}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  renderRequestStatusField() {
    const { joinCompanyRequestStatusOptions } = this.props
    const { requestStatusKey } = this.state
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Request Status
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="statusKey"
            value={requestStatusKey}
            onChange={e => this.setState({ requestStatusKey: e.target.value })}
          >
            <option value={'all'}>All</option>
            {joinCompanyRequestStatusOptions.map((option, index) => {
              return (
                <option key={index} value={Object.keys(option)[0]}>
                  {Object.values(option)[0]}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  renderRequestTypeField() {
    const { joinCompanyRequestTypeOptions } = this.props
    const { requestTypeId } = this.state
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Request Type
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="requestTypeId"
            value={requestTypeId}
            onChange={e => this.setState({ requestTypeId: e.target.value })}
          >
            <option value={''}>All</option>
            {joinCompanyRequestTypeOptions.map((option, index) => {
              return (
                <option key={index} value={Object.keys(option)[0]}>
                  {Object.values(option)[0]}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  renderStatusField() {
    const { statusOptions } = this.props
    const { statusKey } = this.state
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Status
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="statusKey"
            value={statusKey}
            onChange={e => this.setState({ statusKey: e.target.value })}
          >
            {statusOptions.map((option, index) => {
              return (
                <option key={index} value={Object.keys(option)[0]}>
                  {Object.values(option)[0]}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }
  renderAuthStatusField() {
    const { authStatusOptions } = this.props
    const { authStatusKey } = this.state
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Document Authentication Status
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="authStatusKey"
            value={authStatusKey}
            onChange={e => this.setState({ authStatusKey: e.target.value })}
          >
            {authStatusOptions.map((option, index) => {
              return (
                <option key={index} value={Object.keys(option)[0]}>
                  {Object.values(option)[0]}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }
  renderActiveStatusField() {
    const { activeStatusOptions } = this.props
    const { activeStatusKey } = this.state
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Active Status
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="activeStatusKey"
            value={activeStatusKey}
            onChange={e => this.setState({ activeStatusKey: e.target.value })}
          >
            {activeStatusOptions.map((option, index) => {
              return (
                <option key={index} value={Object.keys(option)[0]}>
                  {Object.values(option)[0]}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }
  renderCountryField() {
    const { countryOptions } = this.props
    const { countryOption } = this.state
    const allowedCountries = [167, 193, 92, 96, 105, 123, 211]
    if (countryOptions) {
      return (
        <FormGroup row>
          <Label lg={3} sm={12}>
            Country
          </Label>
          <Col md={7} sm={12}>
            <Input
              type="select"
              name="countryOption"
              value={countryOption}
              onChange={e => this.setState({ countryOption: e.target.value })}
            >
              <option value="">All</option>
              <option value={167}>Phillipines</option>
              <option value={193}>Singapore</option>
              <option value={96}>Indonesia</option>
              <option value={105}>Japan</option>
              <option value={92}>Hong Kong</option>
              <option value={123}>Macau</option>
              <option value={211}>Taiwan</option>
              {countryOptions.map((option, index) => {
                if (!allowedCountries.includes(option.id)) {
                  return (
                    <option key={index} value={option.id}>
                      {option.value}
                    </option>
                  )
                }
              })}
            </Input>
          </Col>
        </FormGroup>
      )
    }
  }
  // shouldComponentUpdate(){
  //   console.log('search shouldComponentUpdate');
  //   return false
  // }
  renderSupportedCountryField() {
    const { supportedCountryOption } = this.state

    return (
      <Query query={GET_SUPPORTED_COUNTRY}>
        {({ data, loading, error }) => {
          if (loading) {
            return <div>Loading...</div>
          } else if (error) {
            return <div>Something went wrong.</div>
          } else {
            const getSupportedCountry = data && data.getSupportedCountry
            const countryOptions = getSupportedCountry
              ? getSupportedCountry.data
              : []
            return (
              <FormGroup row>
                <Label lg={3} sm={12}>
                  Country
                </Label>
                <Col md={7} sm={12}>
                  <Input
                    type="select"
                    name="countrySelect"
                    value={supportedCountryOption}
                    onChange={e =>
                      this.setState({ supportedCountryOption: e.target.value })
                    }
                  >
                    <option value="">All Countries</option>
                    {countryOptions.length > 0 ? (
                      countryOptions.map(c => (
                        <option key={c.id} value={c.id}>
                          {c.value}
                        </option>
                      ))
                    ) : (
                      <option value="">No options available</option>
                    )}
                  </Input>
                </Col>
              </FormGroup>
            )
          }
        }}
      </Query>
    )
  }

  renderRegisteredCountryField() {
    const { registeredCountryOption } = this.state
    return (
      <Query query={GET_SUPPORTED_COUNTRY}>
        {({ data, loading, error }) => {
          if (loading) {
            return <div>Loading...</div>
          } else if (error) {
            return <div>Something went wrong.</div>
          } else {
            const getSupportedCountry = data && data.getSupportedCountry
            const countryOptions = getSupportedCountry
              ? getSupportedCountry.data
              : []
            return (
              <FormGroup row>
                <Label lg={3} sm={12}>
                  Registered Country
                </Label>
                <Col md={7} sm={12}>
                  <Input
                    type="select"
                    name="registereCountrySelect"
                    value={registeredCountryOption}
                    onChange={e =>
                      this.setState({ registeredCountryOption: e.target.value })
                    }
                  >
                    <option value="">All</option>
                    {countryOptions.length > 0 ? (
                      countryOptions.map(c => (
                        <option key={c.id} value={c.id}>
                          {c.value}
                        </option>
                      ))
                    ) : (
                      <option value="">No options available</option>
                    )}
                  </Input>
                </Col>
              </FormGroup>
            )
          }
        }}
      </Query>
    )
  }

  renderSalesPersonField() {
    const { selectedSalesPerson } = this.state
    return (
      <Query query={GET_ALL_USERS} variables={{ size: 1000 }}>
        {({ loading, data }) => {
          const allUsers = data
          if (loading) {
            return <Loading />
          } else {
            if (allUsers && allUsers.allAdminUsers.data.adminUsers) {
              return (
                <Query query={GET_ME}>
                  {({ loading, data }) => {
                    if (loading) {
                      return <Loading />
                    } else {
                      let users = allUsers.allAdminUsers.data.adminUsers
                      return (
                        <>
                          <FormGroup row>
                            <Label lg={3} sm={12}>
                              Salesperson
                            </Label>
                            <Col md={7} sm={12}>
                              <Input
                                type="select"
                                name="salesPersonEmail"
                                value={selectedSalesPerson}
                                onChange={e =>
                                  this.setState({
                                    selectedSalesPerson: e.target.value
                                  })
                                }
                              >
                                <option value={''}>All</option>
                                <option value={'None'}>No Salesperson</option>
                                {users.map((user, index) => {
                                  if (user.role.roleName == 'BD Team') {
                                    return (
                                      <option key={index} value={user.email}>
                                        {user.email}
                                      </option>
                                    )
                                  }
                                })}
                              </Input>
                            </Col>
                          </FormGroup>
                        </>
                      )
                    }
                  }}
                </Query>
              )
            }
          }
        }}
      </Query>
    )
  }

  renderSalesStatusField() {
    const { salesStatusOptions } = this.props
    const { salesStatus } = this.state
    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Sales Status
        </Label>
        <Col md={7} sm={12}>
          <Input
            type="select"
            name="salesStatus"
            value={salesStatus}
            onChange={e => this.setState({ salesStatus: e.target.value })}
          >
            {salesStatusOptions.map((option, index) => {
              return (
                <option key={index} value={Object.keys(option)[0]}>
                  {Object.values(option)[0]}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  renderMinInstallmentField() {
    const { minNoOfInstallment } = this.state
    return (
      <FormGroup row>
        <Label lg={4} sm={12}>
          Min Installment
        </Label>
        <Col md={8} sm={12}>
          <Input
            name="minNoOfInstallment"
            type="number"
            value={minNoOfInstallment}
            placeholder="Min installment"
            onChange={e =>
              this.setState({ minNoOfInstallment: e.target.value })
            }
          />
        </Col>
      </FormGroup>
    )
  }

  renderSubscriptionPlanField() {
    const { subscriptionPlanOptions } = this.props
    const { subscriptionPlanId } = this.state
    return (
      <FormGroup row>
        <Label lg={3} md={4} sm={12}>
          Plan Name
        </Label>
        <Col md={8} sm={12}>
          <Input
            type="select"
            name="subscriptionPlanId"
            value={subscriptionPlanId}
            onChange={e =>
              this.setState({ subscriptionPlanId: e.target.value })
            }
          >
            <option key="all" value="all">
              All
            </option>
            {subscriptionPlanOptions.map((option, index) => {
              return (
                <option key={index} value={Object.keys(option)[0]}>
                  {Object.values(option)[0]}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
    )
  }

  renderWorkArrangementField() {
    // const { workArrangementOptions } = this.props
    let { workArrangementIds } = this.state
    const workArrangementOptions = [
      {
        id: 1,
        name: 'On-site'
      },
      {
        id: 2,
        name: 'Hybrid'
      },
      {
        id: 3,
        name: 'Remote'
      }
    ]

    if (workArrangementIds && Array.isArray(workArrangementIds) === false) {
      workArrangementIds = workArrangementIds.split(',').map(id => parseInt(id))
    }

    const handleWorkArrangementSelection = (selectedIds, field) => {
      console.log(selectedIds)
      this.setState({
        workArrangementIds: selectedIds
      })
      console.log(workArrangementIds)
    }

    return (
      <FormGroup row>
        <Label lg={3} sm={12}>
          Work Arrangement
        </Label>
        <Col md={7} sm={12}>
          <MultiSelectDropdown
            field="workArrangementIds"
            options={workArrangementOptions}
            onSelect={handleWorkArrangementSelection}
            ids={workArrangementIds}
          />
        </Col>
      </FormGroup>
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    this.search()
  }

  componentDidMount() {
    console.log('search bar componentDidMount')
  }

  render() {
    const { search, fromDate, toDate, error } = this.state
    const {
      hasStatus,
      hasAuthStatus,
      hasCountryFilter,
      hasSupportedCountry,
      hasJoinCompanyDataFilter,
      hasSalesPersonFilter,
      hasRegisteredCountryFilter,
      hasSalesStatusFilter,
      hasActiveStatus,
      hasType,
      hasMinInstallment,
      hasSubscriptionPlan,
      placeholderForSearch,
      hideDate,
      integerOnly,
      hasWorkArrangementFilter,
      searchArr
    } = this.props

    return (
      <Form onSubmit={this.handleSubmit}>
        <Col md="12" style={{ marginBottom: 25 }}>
          <Card>
            <CardBody>
              <Row>
                {!hasJoinCompanyDataFilter ? (
                  searchArr ? null : (
                    <Col lg={5} md={12}>
                      <FormGroup row>
                        <Label lg={3} md={6} sm={12}>
                          Search
                        </Label>
                        <Col lg={8} md={6} sm={12}>
                          <Input
                            name="search"
                            type={integerOnly ? 'number' : null}
                            value={search}
                            placeholder={placeholderForSearch}
                            onChange={e =>
                              this.setState({ search: e.target.value })
                            }
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  )
                ) : (
                  <Col md={5} sm={12}>
                    {this.renderRequestTypeField()}
                  </Col>
                )}

                {searchArr &&
                  searchArr.map((item, index) => {
                    console.log('state', item.value, this.state[item.value])
                    return (
                      <Row style={{ marginLeft: 0 }} key={index}>
                        <Col lg={12} md={12}>
                          <FormGroup row>
                            <Label lg={3} md={6} sm={12}>
                              {item.key}
                            </Label>
                            <Col lg={8} md={6} sm={12}>
                              <Input
                                name={item.value}
                                // type={integerOnly ? 'number' : null}
                                value={this.state[item.value]}
                                placeholder={item.placeholder || item.key}
                                onChange={e =>
                                  this.setState({
                                    [item.value]: e.target.value
                                  })
                                }
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    )
                  })}

                {hasJoinCompanyDataFilter ? (
                  <Col md={5} sm={12}>
                    {this.renderRequestStatusField()}
                  </Col>
                ) : null}

                <Col lg={4} md={12}>
                  {!hideDate ? (
                    <FormGroup row>
                      <Label md={2} sm={12}>
                        Date
                      </Label>
                      <Col md={5} sm={12}>
                        <DateTimePicker
                          format="MMM D, YYYY"
                          time={false}
                          value={fromDate}
                          placeholder="From"
                          onChange={newDate => {
                            this.setState({ fromDate: newDate, error: false })
                          }}
                        />
                      </Col>
                      <Col md={5} sm={12}>
                        <DateTimePicker
                          format="MMM D, YYYY"
                          time={false}
                          value={toDate}
                          placeholder="To"
                          onChange={newDate =>
                            this.setState({ toDate: newDate, error: false })
                          }
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                </Col>

                <Col lg={2} md={2}>
                  <Button type="submit" color="info" onClick={this.search}>
                    Search
                  </Button>
                </Col>

                {hasType ? (
                  <Col md={5} sm={12}>
                    {this.renderTypeField()}
                  </Col>
                ) : null}

                {hasStatus ? (
                  <Col md={5} sm={12}>
                    {this.renderStatusField()}
                  </Col>
                ) : null}

                {hasWorkArrangementFilter ? (
                  <Col md={5} sm={12}>
                    {this.renderWorkArrangementField()}
                  </Col>
                ) : null}

                {hasAuthStatus ? (
                  <Col md={5} sm={12}>
                    {this.renderAuthStatusField()}
                  </Col>
                ) : null}

                {hasActiveStatus ? (
                  <Col md={5} sm={12}>
                    {this.renderActiveStatusField()}
                  </Col>
                ) : null}

                {hasCountryFilter ? (
                  <Col md={5} sm={12}>
                    {this.renderCountryField()}
                  </Col>
                ) : null}

                {hasSupportedCountry ? (
                  <Col md={5} sm={12}>
                    {this.renderSupportedCountryField()}
                  </Col>
                ) : null}

                {hasMinInstallment ? (
                  <Col md={5} sm={12}>
                    {this.renderMinInstallmentField()}
                  </Col>
                ) : null}
                {hasSubscriptionPlan ? (
                  <Col md={5} sm={12}>
                    {this.renderSubscriptionPlanField()}
                  </Col>
                ) : null}

                {hasSalesPersonFilter ? (
                  <Col md={5} sm={12}>
                    {this.renderSalesPersonField()}
                  </Col>
                ) : null}

                {hasRegisteredCountryFilter ? (
                  <Col md={5} sm={12}>
                    {this.renderRegisteredCountryField()}
                  </Col>
                ) : null}

                {hasSalesStatusFilter ? (
                  <Col md={5} sm={12}>
                    {this.renderSalesStatusField()}
                  </Col>
                ) : null}
              </Row>
              {error && (
                <div style={{ color: 'red' }}>Please select both dates</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Form>
    )
  }
}

export default withRouter(SearchBar)
