import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import {
  GET_LOCAL_COMPANY_DETAIL,
  GET_RECRUITER_LIST_BY_COMPANY_ID,
  GET_PENDING_REVIEW_RECRUITER_LIST_BY_COMPANY_ID
} from '../../../schemas/queries'
import {
  CHANGE_RECRUITER_ROLE,
  APPROVE_PENDING_REVIEW_RECRUITER,
  REJECT_PENDING_REVIEW_RECRUITER,
  REMOVE_RECRUITER,
  REASSIGN_COORDINATOR,
  CHANGE_REQUIRED_PENDING_REVIEW_RECRUITER,
  APPROVE_PENDING_REVIEW_RECRUITER_DOCUMENT,
  REJECT_RECRUITER_DOCUMENT
} from '../../../schemas/mutations'

/* Components */
import RecruiterList from '../../../views/CompanyDetail/Recruiters/RecruiterList'
import Loading from 'shared/components/Loading'

/**
 * RecruiterListContainer - container component to query Bossjob recruiter list
 * under a company and return RecruiterList component when is done
 *
 * @returns {Component}
 *
 */
class RecruiterListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    changeRecruiterRole: PropTypes.func.isRequired,
    approvePendingReviewRecruiter: PropTypes.func.isRequired,
    rejectPendingReviewRecruiter: PropTypes.func.isRequired,
    approveRecruiterDocument: PropTypes.func.isRequired,
    rejectedRecruiterDocument: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }

    this.approvePendingReviewRecruiter = this.approvePendingReviewRecruiter.bind(
      this
    )
    this.rejectPendingReviewRecruiter = this.rejectPendingReviewRecruiter.bind(
      this
    )
    this.changeRequiredPendingReviewRecruiter = this.changeRequiredPendingReviewRecruiter.bind(
      this
    )
    this.changeRecruiterRole = this.changeRecruiterRole.bind(this)
    this.removeRecruiter = this.removeRecruiter.bind(this)
    this.reassignCoordinator = this.reassignCoordinator.bind(this)
    this.approveRecruiterDocument = this.approveRecruiterDocument.bind(this)
    this.rejectedRecruiterDocument = this.rejectedRecruiterDocument.bind(this)
  }

  async approvePendingReviewRecruiter(
    companyId,
    recruiterId,
    recruiterRoleKey
  ) {
    const { approvePendingReviewRecruiter } = this.props
    let response = await approvePendingReviewRecruiter({
      variables: {
        companyId: companyId,
        recruiterId: recruiterId,
        recruiterRoleKey: recruiterRoleKey
      }
    })

    if (response.data.approvePendingReviewRecruiter.ok) {
      window.location.reload()
    }
  }

  async rejectPendingReviewRecruiter(companyId, recruiterId) {
    const { rejectPendingReviewRecruiter } = this.props
    let response = await rejectPendingReviewRecruiter({
      variables: {
        companyId: companyId,
        recruiterId: recruiterId
      }
    })

    if (response.data.rejectPendingReviewRecruiter.ok) {
      window.location.reload()
    }
  }
  async rejectedRecruiterDocument(recruiterDocumentId, rejectedReason) {
    const { rejectedRecruiterDocument } = this.props
    let response = await rejectedRecruiterDocument({
      variables: {
        recruiterDocumentId,
        rejectedReason
      }
    })
    if (response.data.rejectedRecruiterDocument.ok) {
      window.location.reload()
    }
  }

  async approveRecruiterDocument(
    recruiterDocumentId,
    recruiterRoleKey = 'recruiter'
  ) {
    const { approveRecruiterDocument } = this.props
    let response = await approveRecruiterDocument({
      variables: {
        recruiterDocumentId,
        recruiterRoleKey
      }
    })
    if (response.data.approveRecruiterDocument.ok) {
      window.location.reload()
    }
  }
  async changeRequiredPendingReviewRecruiter(
    recruiterDocumentId,
    changeRequiredReason
  ) {
    const { changeRequiredPendingReviewRecruiter } = this.props
    let response = await changeRequiredPendingReviewRecruiter({
      variables: {
        recruiterDocumentId,
        changeRequiredReason
      }
    })

    if (response.data.changeRequiredRecruiterDocument.ok) {
      window.location.reload()
    }
  }

  async changeRecruiterRole(companyId, recruiterId, recruiterRoleKey) {
    const { changeRecruiterRole } = this.props
    let response = await changeRecruiterRole({
      variables: {
        companyId: companyId,
        recruiterId: recruiterId,
        recruiterRoleKey: recruiterRoleKey
      }
    })

    if (response.data.changeRecruiterRole.ok) {
      window.location.reload()
    }
  }

  async removeRecruiter(companyId, recruiterId) {
    const { removeRecruiter } = this.props
    let response = await removeRecruiter({
      variables: {
        companyId: companyId,
        recruiterId: recruiterId
      }
    })
    if (response.data.removeRecruiter.ok) {
      window.location.reload()
    }
  }

  async reassignCoordinator(companyId, coordinatorId, recruiterId) {
    const { reassignCoordinator } = this.props
    let response = await reassignCoordinator({
      variables: {
        companyId: companyId,
        coordinatorId: coordinatorId,
        recruiterId: recruiterId
      }
    })

    if (response.data.reassignCoordinator.ok) {
      window.location.reload()
    }
  }

  render() {
    return (
      <Query query={GET_LOCAL_COMPANY_DETAIL}>
        {({ data: { companyDetail } }) => (
          <>
            <h6>Verified Recruiter</h6>
            <Query
              query={GET_RECRUITER_LIST_BY_COMPANY_ID}
              variables={{ companyId: companyDetail.id, page: this.state.page }}
            >
              {({ loading, data }) => {
                if (loading) {
                  return <Loading />
                } else {
                  return (
                    <>
                      <RecruiterList
                        userList={data.getCompanyRecruiters.data.recruiters}
                        adminId={companyDetail.coordinatorId}
                        currentPage={Number(this.state.page)}
                        totalUsers={data.getCompanyRecruiters.data.totalCount}
                        totalPages={data.getCompanyRecruiters.data.totalPages}
                        handleChangeRole={this.changeRecruiterRole}
                        handleRemoveRecruiter={this.removeRecruiter}
                        handleReassignCoordinator={this.reassignCoordinator}
                        companyId={companyDetail.id}
                      />
                    </>
                  )
                }
              }}
            </Query>
            <br />
            <h6>Pending Review Recruiter</h6>
            <Query
              query={GET_PENDING_REVIEW_RECRUITER_LIST_BY_COMPANY_ID}
              variables={{ companyId: companyDetail.id, page: this.state.page }}
            >
              {({ loading, data }) => {
                if (loading) {
                  return <Loading />
                } else {
                  return (
                    <RecruiterList
                      userList={
                        data.getCompanyPendingReviewRecruiters.data.recruiters
                      }
                      adminId={companyDetail.coordinatorId}
                      currentPage={Number(this.state.page)}
                      totalUsers={
                        data.getCompanyPendingReviewRecruiters.data.totalCount
                      }
                      totalPages={
                        data.getCompanyPendingReviewRecruiters.data.totalPages
                      }
                      handleApproveRecruiter={
                        this.approvePendingReviewRecruiter
                      }
                      handleRejectRecruiter={this.rejectPendingReviewRecruiter}
                      changeRequiredPendingReviewRecruiter={
                        this.changeRequiredPendingReviewRecruiter
                      }
                      rejectedRecruiterDocument={this.rejectedRecruiterDocument}
                      approveRecruiterDocument={this.approveRecruiterDocument}
                      companyId={companyDetail.id}
                      hasDocument
                    />
                  )
                }
              }}
            </Query>
          </>
        )}
      </Query>
    )
  }
}

export default compose(
  graphql(APPROVE_PENDING_REVIEW_RECRUITER, {
    name: 'approvePendingReviewRecruiter'
  }),
  graphql(REJECT_PENDING_REVIEW_RECRUITER, {
    name: 'rejectPendingReviewRecruiter'
  }),
  graphql(CHANGE_REQUIRED_PENDING_REVIEW_RECRUITER, {
    name: 'changeRequiredPendingReviewRecruiter'
  }),
  graphql(APPROVE_PENDING_REVIEW_RECRUITER_DOCUMENT, {
    name: 'approveRecruiterDocument'
  }),
  graphql(REJECT_RECRUITER_DOCUMENT, {
    name: 'rejectedRecruiterDocument'
  }),
  graphql(CHANGE_RECRUITER_ROLE, { name: 'changeRecruiterRole' }),
  graphql(REMOVE_RECRUITER, { name: 'removeRecruiter' }),
  graphql(REASSIGN_COORDINATOR, { name: 'reassignCoordinator' })
)(withRouter(RecruiterListContainer))
