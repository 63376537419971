import React from 'react'
import PropTypes from 'prop-types'

/* Vendors */
import FontAwesome from 'react-fontawesome'

/* Assets */
import styles from './CreditBox.module.scss'

/**
 * CreditBox - CreditBox shared component
 *
 * @returns {Component}
 *
 */

const CreditBox = ({
  identifier,
  typeId,
  selectCategory,
  totalCredits,
  totalPosts,
  subText,
  handleModal,
  hasPermission,
  modalHeader
}) => (
  <div className={styles.creditBox}>
    {hasPermission && (
      <div
        style={{ textAlign: 'right', cursor: 'pointer' }}
        onClick={() => {
          handleModal({
            variables: {
              isVisible: true,
              modalData: {
                header: modalHeader,
                userId: null,
                remark: null,
                typeId: null,
                value: null,
                __typename: 'ModalDetail'
              }
            }
          }),
          selectCategory(typeId)
        }}
      >
        <FontAwesome name="edit" /> Adjust
      </div>
    )}
    <div className={styles.creditWrapper}>
      <div className={styles.creditIconHolder} />
      <div style={{ marginLeft: '10px' }}>
        <div className={styles.credit}>
          {identifier === 'PAID' || 'FREE' || 'TRIAL'
            ? totalPosts === null
              ? 0
              : totalPosts
            : null}
          {identifier === 'GENERAL_CREDIT' || 'BOSSPOINT'
            ? totalCredits === null
              ? 0
              : totalCredits
            : null}
        </div>
        {subText &&
          subText !== '' && <div className={styles.creditSub}>{subText}</div>}
      </div>
    </div>
  </div>
)

CreditBox.propTypes = {
  identifier: PropTypes.string.isRequired,
  typeId: PropTypes.number.isRequired,
  totalPosts: PropTypes.number.isRequired,
  totalCredits: PropTypes.number.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  subText: PropTypes.string,
  handleModal: PropTypes.func,
  selectCategory: PropTypes.func,
  modalQuery: PropTypes.object,
  modalHeader: PropTypes.string
}

export default CreditBox
