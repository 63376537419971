import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

/* Apollo */
import { Query } from 'react-apollo'
import { REQUEST_CLAIM_NEPTUNE_COMPANY_LIST } from '../../schemas/queries'

/* Vendors */
import queryString from 'query-string'

/* Components */
import Loading from 'shared/components/Loading'
import NeptuneCompanyList from '../../views/NeptuneCompanies/NeptuneCompanyList'

const NeptuneCompaniesListContainer = ({ match }) => {
  const { page } = match.params

  const {
    companyOperatingValue,
    companyType,
    country,
    legalName,
    name,
    selectedSalesPerson,
    fromDate,
    toDate
  } = queryString.parse(location.search)

  let variables = {
    page: parseInt(page),
    size: parseInt(20),
    operatingStatusIds: companyOperatingValue
      ? [parseInt(companyOperatingValue)]
      : [],
    companyTypeIds: companyType ? [parseInt(companyType)] : [],
    countryIds: country ? [parseInt(country)] : [],
    legalName: legalName ? legalName : '',
    name: name ? name : '',
    salespersonIds: selectedSalesPerson ? [parseInt(selectedSalesPerson)] : [],
    foundingDateFrom: fromDate ? fromDate : '',
    foundingDateTo: toDate ? toDate : ''
  }

  return (
    <Query query={REQUEST_CLAIM_NEPTUNE_COMPANY_LIST} variables={variables}>
      {({ loading, data }) => {
        const list = data
        if (loading) {
          return <Loading />
        }

        if (list && list.requestClaimNeptuneCompanyList) {
          return (
            <NeptuneCompanyList
              list={list.requestClaimNeptuneCompanyList.data.items}
              // currentPage={Number(this.state.page)}
              currentPage={page}
              totalUsers={list.requestClaimNeptuneCompanyList.data.totalNum}
              totalPages={list.requestClaimNeptuneCompanyList.data.totalPages}
            />
          )
        }

        return null
      }}
    </Query>
  )
}

NeptuneCompaniesListContainer.propTypes = {
  /* array of company list */
  match: PropTypes.object.isRequired
}

export default withRouter(NeptuneCompaniesListContainer)
