import gql from 'graphql-tag'

/* Agency Detail */
export const UPDATE_LOCAL_AGENCY_DETAIL = gql`
  mutation UpdateLocalAgencyDetail(
    $key: String
    $value: String
    $category: String
    $del: Int
  ) {
    updateLocalAgencyDetail(
      key: $key
      value: $value
      category: $category
      del: $del
    ) @client
  }
`
export const UPDATE_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM = gql`
  mutation UpdateLocalAgencyDetailForNewForm(
    $key: String
    $value: String
    $del: Int
  ) {
    updateLocalAgencyDetailForNewForm(key: $key, value: $value, del: $del)
      @client
  }
`
export const UPDATE_AGENCY_DETAIL = gql`
  mutation UpdateHeadhunterCompany(
    $companyId: ID!
    $updateHeadhunterCompanyInput: UpdateHeadhunterCompanyInput
  ) {
    updateHeadhunterCompany(
      companyId: $companyId
      updateHeadhunterCompanyInput: $updateHeadhunterCompanyInput
    ) {
      ok
      message
    }
  }
`
export const UPLOAD_AGENCY_LOGO = gql`
  mutation UploadHeadhunterCompanyLogo($companyId: ID!, $logo: Upload!) {
    uploadHeadhunterCompanyLogo(companyId: $companyId, logo: $logo) {
      ok
      message
    }
  }
`

export const UPLOAD_AGENCY_COVER = gql`
  mutation UploadHeadhunterCompanyCover($companyId: ID!, $cover: Upload!) {
    uploadHeadhunterCompanyCover(companyId: $companyId, cover: $cover) {
      ok
      message
    }
  }
`

export const ADD_AGENCY = gql`
  mutation AddHeadhunterCompany(
    $addHeadhunterCompanyInput: AddHeadhunterCompanyInput
  ) {
    addHeadhunterCompany(
      addHeadhunterCompanyInput: $addHeadhunterCompanyInput
    ) {
      ok
      message
      companyId
    }
  }
`

export const DELETE_AGENCY = gql`
  mutation DeleteHeadhunterCompanies($companyIds: [Int]!) {
    deleteHeadhunterCompanies(companyIds: $companyIds) {
      ok
      message
    }
  }
`
/* Update Agency's feeStructure detail */
export const UPDATE_AGENCY_FEE = gql`
  mutation UpdateAgencyFee(
    $companyId: ID!
    $companyFeeStructureInput: CompanyFeeStructureInput!
    $companyFreeReplacementInput: CompanyFreeReplacementInput
    $companyGuaranteePeriodInput: CompanyGuaranteePeriodInput
    $companyTurnaroundTimeInput: CompanyTurnaroundTimeInput
  ) {
    updateHeadhunterCompanyFee(
      companyId: $companyId
      companyFeeStructureInput: $companyFeeStructureInput
      companyFreeReplacementInput: $companyFreeReplacementInput
      companyGuaranteePeriodInput: $companyGuaranteePeriodInput
      companyTurnaroundTimeInput: $companyTurnaroundTimeInput
    ) {
      ok
      message
    }
  }
`

/* Update Agency's specialization detail */
export const UPDATE_AGENCY_SPECIALIZATION = gql`
  mutation UpdateAgencySpecialization(
    $companyId: ID!
    $industryIds: [Int]!
    $serviceIds: [Int]!
    $serviceLocationIds: [Int]!
  ) {
    updateHeadhunterCompanySpecialization(
      companyId: $companyId
      industryIds: $industryIds
      serviceIds: $serviceIds
      serviceLocationIds: $serviceLocationIds
    ) {
      ok
      message
    }
  }
`
