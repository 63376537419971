import gql from 'graphql-tag'

const JoinCompanyDataFragment = gql`
  fragment JoinCompanyData on JoinCompanyTableType {
    id
    userId
    userEmail
    requestType
    companyId
    companyLegalName
    status
    createdTime
  }
`

export const GET_JOIN_COMPANY_DATA = gql`
  query getJoinCompanyData(
    $requestTypeId: Int
    $statusKey: String
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
  ) {
    getJoinCompanyData(
      size: 10
      page: $page
      requestTypeId: $requestTypeId
      statusKey: $statusKey
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ok
      message
      data {
        totalPages
        currentPage
        size
        totalCount
        joinCompanyList {
          ...JoinCompanyData
        }
      }
    }
  }
  ${JoinCompanyDataFragment}
`
