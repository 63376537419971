import React, { Component } from 'react'

/* React Router */
import { Router } from 'react-router-dom'
import Routes from './shared/components/Routes'

/* Helpers */
import history from './shared/helpers/history'

/* Vendors */
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

import styles from './App.module.scss'

class App extends Component {
  constructor(props) {
    super(props)

    Moment.locale('en')
    momentLocalizer()
  }

  render() {
    return (
      <Router history={history}>
        <div className={styles.App}>
          <Routes />
        </div>
      </Router>
    )
  }
}

export default App
