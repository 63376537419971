import React from 'react'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import Loading from 'shared/components/Loading'
import { Query } from 'react-apollo'
import { GET_JOB_PAGE_RIGHT_SIDE_BANNER } from '../../schemas/queries'
import RightSideBannerContainer from './RightSideBannerContainer/RightSideBannerContainer'

const RightSideBanner = () => (
  <div>
    <SectionWrapper sectionTitle="Job Page Right Side Banner" isMainPage>
      <p style={{ color: 'red', fontSize: '16px' }}>
        *Note: The side banner to be displayed is configured to be chosen
        randomly
      </p>
      <Query query={GET_JOB_PAGE_RIGHT_SIDE_BANNER}>
        {({ data: { rightSideBanners }, loading }) => {
          if (loading) {
            return <Loading />
          }
          if (rightSideBanners) {
            return <RightSideBannerContainer sideBanners={rightSideBanners} />
          }
          return null
        }}
      </Query>
    </SectionWrapper>
  </div>
)

export default RightSideBanner
