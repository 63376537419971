/*eslint-disable */
import {
  GET_LOCAL_INVOICE_DETAILS,
  GET_LOCAL_TRANSACTION_DETAILS,
  GET_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM
} from '../schemas/queries'

export default {
  updateLocalInvoiceDetails: (_, { key, value }, { cache }) => {
    const { invoiceDetail } = cache.readQuery({
      query: GET_LOCAL_INVOICE_DETAILS
    })

    let newInvoiceDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        invoiceDetail: {
          ...invoiceDetail,
          ...newInvoiceDetail
        }
      }
    })

    return null
  },
  updateLocalTransactionDetails: (_, { key, value }, { cache }) => {
    const { transactionDetail } = cache.readQuery({
      query: GET_LOCAL_TRANSACTION_DETAILS
    })

    if (key === 'paymentMethodId' && transactionDetail.type === 'Offline') {
      let newMethodId = {
        methodId: value
      }

      let newOfflineTransaction = {
        offlineTransaction: {
          ...transactionDetail.offlineTransaction,
          ...newMethodId
        }
      }
      cache.writeData({
        data: {
          transactionDetail: {
            ...transactionDetail,
            ...newOfflineTransaction
          }
        }
      })
    } else {
      let newTransactionDetail = {
        [key]: value
      }

      cache.writeData({
        data: {
          transactionDetail: {
            ...transactionDetail,
            ...newTransactionDetail
          }
        }
      })
    }

    return null
  },
  updateLocalTransactionDetailsForNewForm: (_, { key, value }, { cache }) => {
    const { transactionDetailForNewForm } = cache.readQuery({
      query: GET_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM
    })

    let newTransactionDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        transactionDetailForNewForm: {
          ...transactionDetailForNewForm,
          ...newTransactionDetail
        }
      }
    })

    return null
  },
  updateLocalInvoiceFile: (_, { file }, { cache }) => {
    // const { invoiceFile } = cache.readQuery({
    //   query: GET_LOCAL_INVOICE_FILE
    // })

    const fileObj = {
      __typename: 'InvoiceFileType',
      file: file
    }
    console.log(fileObj)
    cache.writeData({
      data: {
        invoiceFile: fileObj
      }
    })

    return null
  }
}
