import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ALL_BOSSJOB_USERS } from '../../schemas/queries'

/* Components */
import BossJobUserList from '../../views/BossJobUsers/BossJobUserList'
import Loading from 'shared/components/Loading'

/**
 * BossJobUserListContainer - container component to query Bossjob user list
 * and return BossJobUserList component when is done
 *
 * @returns {Component}
 *
 */
class BossJobUserListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { search, fromDate, toDate, type } = queryString.parse(
      location.search
    )

    let variables = {
      page: parseInt(this.state.page),
      query: search ? search : null,
      date:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null
    }

    if (type) {
      variables.activeKey = type === 'jobseeker' ? 1 : 2
    }

    return (
      <Query query={GET_ALL_BOSSJOB_USERS} variables={variables}>
        {({ loading, data }) => {
          const userList = data
          if (loading) {
            return <Loading />
          }

          if (userList && userList.allBossjobUsers) {
            return (
              <BossJobUserList
                userList={userList.allBossjobUsers.data.users}
                currentPage={Number(this.state.page)}
                totalUsers={userList.allBossjobUsers.data.totalCount}
                totalPages={userList.allBossjobUsers.data.totalPages}
                {...this.props}
              />
            )
          }

          return null
        }}
      </Query>
    )
  }
}

export default withRouter(BossJobUserListContainer)
