import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_COMPANY_REVIEW_DETAIL_BY_ID } from '../../../schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import CompanyReviewDetail from './CompanyReviewDetail'
import FlagList from './FlagList'

/**
 * ReviewDetailWrapper - a wrapper component to show company review detail and flag list
 *
 * @returns {Component}
 */
const ReviewDetailWrapper = ({ match }) => (
  <Query
    query={GET_COMPANY_REVIEW_DETAIL_BY_ID}
    variables={{ id: match.params.reviewId }}
  >
    {({ data, loading }) => {
      if (loading) {
        return <Loading />
      } else {
        return (
          <div>
            <CompanyReviewDetail review={data.companyReview.data} />

            <SectionWrapper sectionTitle="Flag">
              <FlagList flagList={data.companyReview.data.flags} />
            </SectionWrapper>
          </div>
        )
      }
    }}
  </Query>
)

ReviewDetailWrapper.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default withRouter(ReviewDetailWrapper)
