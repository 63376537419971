import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter, Route, Redirect } from 'react-router-dom'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import RecruiterSettingsFormContainer from '../../../containers/BossJobUserDetailContainer/RecruiterSettingsFormContainer'
import Jobs from './Jobs'
import Credits from './Credits'
import JobPostCredits from './JobPostCredits'
import JobPostCreditsDetailWrapper from './JobPostCredits/JobPostCreditsDetailWrapper'
import CreditDetailWrapper from './Credits/CreditDetailWrapper/CreditDetailWrapper'
import BillingHistoryContainer from '../../../containers/BossJobUserDetailContainer/BillingHistoryContainer/BillingHistoryContainer'
import SubscriptionHistoryListContainer from '../../../containers/BossJobUserDetailContainer/SubscriptionHistoryListContainer/SubscriptionHistoryListContainer'
import RecruiterJobStatContainer from '../../../containers/BossJobUserDetailContainer/RecruiterJobStatContainer/RecruiterJobStatContainer'
import RecruiterEventActivityLogs from '../../../../events/views/RecruiterEventActivityLogs'
/* Assets */
import styles from './RecruiterInformation.module.scss'
import { any } from 'prop-types'

const RecruiterInformation = ({ isCompanyAdmin, match, location }) => (
  <React.Fragment>
    <SectionWrapper sectionTitle="Recruiters">
      <div className={styles.subMenu}>
        <div>
          <a
            href={`/bossjob/user/${match.params.id}/recruiter/settings`}
            className={
              location.pathname.includes('settings')
                ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                : styles.subMenuItem
            }
          >
            Settings
          </a>
        </div>
        <div>
          <a
            href={`/bossjob/user/${match.params.id}/recruiter/job-stats/1`}
            className={
              location.pathname.includes('job-stats')
                ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                : styles.subMenuItem
            }
          >
            Job Statistics
          </a>
        </div>
        <div>
          <a
            href={`/bossjob/user/${match.params.id}/recruiter/jobs`}
            className={
              location.pathname.includes('jobs')
                ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                : styles.subMenuItem
            }
          >
            Job Posted
          </a>
        </div>

        {isCompanyAdmin ? (
          <div>
            <a
              href={`/bossjob/user/${match.params.id}/recruiter/job-post`}
              className={
                location.pathname.includes('job-post')
                  ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                  : styles.subMenuItem
              }
            >
              Job Posts
            </a>
            <a
              href={`/bossjob/user/${match.params.id}/recruiter/credits`}
              className={
                location.pathname.includes('credits')
                  ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                  : styles.subMenuItem
              }
            >
              Credits
            </a>
            <a
              href={`/bossjob/user/${match.params.id}/recruiter/transaction/1`}
              className={
                location.pathname.includes('transaction')
                  ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                  : styles.subMenuItem
              }
            >
              Transactions
            </a>
            <a
              href={`/bossjob/user/${match.params.id}/recruiter/subscription/1`}
              className={
                location.pathname.includes('subscription')
                  ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                  : styles.subMenuItem
              }
            >
              Subscription Plans
            </a>
            <a
              href={`/bossjob/user/${match.params.id}/recruiter/event_logs/1`}
              className={
                location.pathname.includes('event_logs')
                  ? `${styles.subMenuItem} ${styles.subMenuItemActive}`
                  : styles.subMenuItem
              }
            >
              Event Activity Logs
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>

      <React.Fragment>
        <Route
          exact
          path={`${match.path}`}
          component={() => <Redirect to={`${match.url}/settings`} />}
        />

        <Route
          exact
          path="/bossjob/user/:id/recruiter/settings"
          component={RecruiterSettingsFormContainer}
        />

        <Route
          exact
          path="/bossjob/user/:id/recruiter/job-stats/:page"
          component={RecruiterJobStatContainer}
        />

        {/* Job List */}
        <Route
          exact
          path="/bossjob/user/:id/recruiter/jobs"
          component={() => <Redirect to={`${match.url}/jobs/1`} />}
        />
        <Route
          exact
          path="/bossjob/user/:id/recruiter/job-post"
          component={() => <Redirect to={`${match.url}/job-post/1`} />}
        />
        <Route
          exact
          path="/bossjob/user/:id/recruiter/jobs/:page"
          component={Jobs}
        />

        <Route
          path="/bossjob/user/:id/recruiter/job-post/:page"
          component={JobPostCredits}
        />

        <Route path="/bossjob/user/:id/recruiter/credits" component={Credits} />

        <Route
          exact
          path="/bossjob/user/:id/recruiter/transaction/:page"
          component={BillingHistoryContainer}
        />

        <Route
          exact
          path="/bossjob/user/:id/recruiter/subscription/:page"
          component={SubscriptionHistoryListContainer}
        />

        <Route
          exact
          path="/bossjob/user/:id/recruiter/event_logs/:page"
          component={RecruiterEventActivityLogs}
        />
      </React.Fragment>
    </SectionWrapper>

    {/* Show details as a separate section */}
    <Route
      path="/bossjob/user/:id/recruiter/credits"
      component={CreditDetailWrapper}
    />
    <Route
      path="/bossjob/user/:id/recruiter/job-post"
      component={JobPostCreditsDetailWrapper}
    />
  </React.Fragment>
)

RecruiterInformation.propTypes = {
  isCompanyAdmin: any,
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

export default withRouter(RecruiterInformation)
