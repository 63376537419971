import gql from 'graphql-tag'

export const ADD_NOTIFY_MAINTAIN_INFO = gql`
  mutation AddWebsiteOutage(
    $startedAt: DateTime!
    $endedAt: DateTime!
    $contentList: [WebOutageContentList]!
  ) {
    addWebsiteOutage(
      websiteOutageInput: {
        startedAt: $startedAt
        endedAt: $endedAt
        contentList: $contentList
      }
    ) {
      ok
      message
    }
  }
`

export const UPDATE_NOTIFY_MAINTAIN_INFO = gql`
  mutation UpdateWebsiteOutage(
    $submitUuid: String!
    $websiteOutageInput: UpdateWebsiteOutageInput
  ) {
    updateWebsiteOutage(
      submitUuid: $submitUuid
      websiteOutageInput: $websiteOutageInput
    ) {
      ok
      message
    }
  }
`
