import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_COMPANY_VERIFICATION_METHOD } from '../../../schemas/queries'
import Loading from 'shared/components/Loading'

const snakeCaseToWords = input => {
  return input
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const CompanyVerificationInfoContainer = ({ match }) => {
  return (
    <Query
      query={GET_COMPANY_VERIFICATION_METHOD}
      variables={{ companyId: match.params.id }}
    >
      {({ loading, data: { getCompany } }) => {
        if (loading) {
          return <Loading />
        } else {
          const { verificationInfo, corppassInfo } = getCompany.data
          return (
            <React.Fragment>
              <Table hover>
                <tbody>
                  <tr>
                    <th>Verification Method:</th>
                    <td>
                      {snakeCaseToWords(verificationInfo.verification_method)}
                    </td>
                  </tr>
                  <tr>
                    <th>Verification Status:</th>
                    <td>
                      {snakeCaseToWords(
                        verificationInfo.verification_status_key
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Reason:</th>
                    <td>{verificationInfo.review_status_reason}</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <b>Corppass Info:</b>
              {verificationInfo.verification_method === 'corppass' ? (
                <Table hover>
                  <tbody>
                    <tr>
                      <th>Entity name:</th>
                      <td>{corppassInfo.entity_name}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <p>No corppass verification info available</p>
              )}
            </React.Fragment>
          )
        }
      }}
    </Query>
  )
}

CompanyVerificationInfoContainer.propTypes = {
  match: PropTypes.object.isRequired
}

export default CompanyVerificationInfoContainer
