import { GET_LOCAL_ADMIN_USER_DETAIL } from '../schemas/queries'

export default {
  updateAdminUser: (_, { key, value }, { cache }) => {
    const { adminUserDetail } = cache.readQuery({
      query: GET_LOCAL_ADMIN_USER_DETAIL
    })

    let newAdminUserDetail = {
      [key]: value
    }

    cache.writeData({
      data: {
        adminUserDetail: {
          ...adminUserDetail,
          ...newAdminUserDetail
        }
      }
    })

    return null
  }
}
