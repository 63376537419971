import React from 'react'

/* Components */
import SearchBar from 'shared/components/SearchBar'
import TransactionHistoryListContainer from '../TransactionHistoryListContainer/TransactionHistoryListContainer'

const statusOptions = [
  { all: 'All' },
  { pending: 'Pending' },
  { processing: 'Processing' },
  { completed: 'Completed' },
  { cancelled: 'Cancelled' },
  { failed: 'Failed' },
  { failed: 'Refunded' }
]

const typeOptions = [
  { all: 'All' },
  { online: 'Online' },
  { offline: 'Offline' }
]

const BillingHistoryContainer = () => (
  <>
    <SearchBar
      hasStatus
      hasType
      placeholderForSearch={'Txn Ref No'}
      statusOptions={statusOptions}
      typeOptions={typeOptions}
    />

    <TransactionHistoryListContainer />
  </>
)

export default BillingHistoryContainer
