import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Row, Input, Badge } from 'reactstrap'

/* Config */
import CONFIG from 'config'

import styles from './SearchWithSuggestion.module.scss'

/**
 * SearchWithSuggestion - search field component that provides suggestion list according to api
 *
 * @returns {Component}
 */
class SearchWithSuggestion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
      list: []
    }
  }

  fetchResult(query) {
    this.setState({
      query: query
    })

    fetch(
      `${CONFIG.BOSSJOB_CONFIG_URL}/search/skill-lists?query=${query}&size=5`
    )
      .then(response => response.json())
      .then(data => {
        let list = data.data.skills

        list.push({ id: null, value: query })

        this.setState({
          list: list
        })
      })
  }

  render() {
    const { query, list } = this.state
    return (
      <Row className={styles.inputWrapper}>
        <Input
          name="searchQuery"
          value={query}
          onChange={e => this.fetchResult(e.target.value)}
        />
        <div className={styles.listItemWrapper}>
          {list.map(item => (
            <Badge
              pill
              className={styles.listItem}
              color="info"
              key={`query-search-${item.id}`}
              onClick={() => this.props.handleClick(item.value)}
            >
              {item.value}
            </Badge>
          ))}
        </div>
      </Row>
    )
  }
}

SearchWithSuggestion.propTypes = {
  handleClick: PropTypes.func.isRequired,
  searchApi: PropTypes.string.isRequired
}

export default SearchWithSuggestion
