exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* ======== Form Section ======== */\n.inputBoxWrapper__h5Hru {\n  margin-bottom: 1em;\n}\n\n/* ======== Button Section ======== */\n.buttonsWrapper__3xbq1 {\n  text-align: right;\n}\n.buttonsWrapper__3xbq1 .forgotText__zPd8h {\n  font-size: 13px;\n  margin-right: 1em;\n}\n.buttonsWrapper__3xbq1 .loginButton__1oGP2 {\n  padding: 0.35em 1.5em !important;\n}", "", {"version":3,"sources":["/app/src/modules/authentication/views/Login/LoginForm/LoginForm.module.scss"],"names":[],"mappings":"AAAA,oCAAmC;AACnC;EACE,mBAAkB;CACpB;;AAEA,sCAAqC;AACrC;EACE,kBAAiB;CACnB;AACA;EACE,gBAAe;EACf,kBAAiB;CACnB;AACA;EACE,iCAAgC;CAClC","file":"LoginForm.module.scss","sourcesContent":["/* ======== Form Section ======== */\n.inputBoxWrapper {\n  margin-bottom: 1em;\n}\n\n/* ======== Button Section ======== */\n.buttonsWrapper {\n  text-align: right;\n}\n.buttonsWrapper .forgotText {\n  font-size: 13px;\n  margin-right: 1em;\n}\n.buttonsWrapper .loginButton {\n  padding: 0.35em 1.5em !important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"inputBoxWrapper": "inputBoxWrapper__h5Hru",
	"buttonsWrapper": "buttonsWrapper__3xbq1",
	"forgotText": "forgotText__zPd8h",
	"loginButton": "loginButton__1oGP2"
};