import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table } from 'reactstrap'

const FlagList = ({ flagList }) => (
  <Table bordered>
    <thead>
      <tr>
        <th>User ID</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Reason for reporting</th>
        <th>Created at</th>
      </tr>
    </thead>
    <tbody>
      {flagList.map(flag => (
        <tr key={`flag-${flag.id}`}>
          <td>
            <a href={`/bossjob/user/${flag.userId}`}>{flag.userId}</a>
          </td>
          <td>{flag.firstName}</td>
          <td>{flag.lastName}</td>
          <td>{flag.value}</td>
          <td>{flag.createdAt}</td>
        </tr>
      ))}
    </tbody>
  </Table>
)

FlagList.propTypes = {
  flagList: PropTypes.array.isRequired
}

export default FlagList
