import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'
import { BATCH_UPDATE_JOB_STATUS } from '../../schemas/mutations'

/* Components */
import { Button } from 'reactstrap'
import ConfirmationAlert from 'shared/components/ConfirmationAlert'

/**
 * CloseJobButton - Apollo Component to set job status to `closed`
 *
 * @returns {Component}
 */
class ExpireJobButton extends Component {
  static propTypes = {
    selectedJobs: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.state = { isVisible: false }
    this._updateJobStatus = this._updateJobStatus.bind(this)
  }

  /**
   *
   * @function _updateJobStatus - Update Job with different status
   * @param {[Number]} jobObject - jobId
   * @param {String} jobStatus
   */
  _updateJobStatus(apolloClient) {
    const { selectedJobs } = this.props
    console.log(selectedJobs)

    let jobIds = []
    Object.keys(selectedJobs).forEach(index => {
      const jobId = Object.keys(selectedJobs[index])[0]
      jobIds.push(parseInt(jobId))
    })

    apolloClient
      .mutate({
        mutation: BATCH_UPDATE_JOB_STATUS,
        variables: {
          jobIds: jobIds,
          statusKey: 'expired'
        }
      })
      .then(response => {
        const data = response.data
        if (data.batchUpdateJobStatus && data.batchUpdateJobStatus.ok) {
          window.location.reload()
        }
      })
      .catch(error => {
        console.log('Error:', error)
      })
  }

  render() {
    const isVisible = this.state.isVisible
    return (
      <ApolloConsumer>
        {client => (
          <React.Fragment>
            <Button
              color="danger"
              onClick={() => this.setState({ isVisible: true })}
            >
              Expire
            </Button>
            <ConfirmationAlert
              isVisible={isVisible}
              title="Expire Jobs"
              body="Are you sure you want to expire all the selected jobs?"
              onConfirm={async () => {
                await this._updateJobStatus(client)
                this.setState({ isVisible: false })
              }}
              onClose={() => this.setState({ isVisible: false })}
            />
          </React.Fragment>
        )}
      </ApolloConsumer>
    )
  }
}

export default ExpireJobButton
