import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'

/* Helpers */
import { getCountries } from 'shared/helpers/locations'

/**
 * BillingAddressForm - A part of the Invoice Detail form which
 * contains the First Name, Last Name, Company Name, Address, Postal Code
 * Country, City and State fields.
 *
 * @returns {Component}
 */
class BillingAddressForm extends Component {
  static propTypes = {
    transactionDetail: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
  }

  /**
   *
   * @function _renderFirstNameField
   * @returns {Component}
   */
  _renderFirstNameField() {
    const { handleChange, isDisabled, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>First Name</Label>
        <Input
          type="text"
          name="billerFirstName"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerFirstName
              ? transactionDetail.billerFirstName
              : null
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderLastNameField
   * @returns {Component}
   */
  _renderLastNameField() {
    const { handleChange, isDisabled, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>Last Name</Label>
        <Input
          type="text"
          name="billerLastName"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerLastName
              ? transactionDetail.billerLastName
              : null
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderCompanyNameField
   * @returns {Component}
   */
  _renderCompanyNameField() {
    const { handleChange, isDisabled, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>Company Name</Label>
        <Input
          type="text"
          name="billerCompany"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerCompany
              ? transactionDetail.billerCompany
              : null
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderAddressField
   * @returns {Component}
   */
  _renderAddressField() {
    const { handleChange, isDisabled, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>Address</Label>
        <Input
          type="text"
          name="billerAddress"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerAddress
              ? transactionDetail.billerAddress
              : null
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderPostalCodeField
   * @returns {Component}
   */
  _renderPostalCodeField() {
    const { handleChange, isDisabled, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>Postal Code</Label>
        <Input
          type="text"
          name="billerZipcode"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerZipcode
              ? transactionDetail.billerZipcode
              : null
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderCountryField
   * @returns {Component}
   */
  _renderCountryField() {
    const { handleChange, isDisabled, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>Country</Label>
        <Input
          type="select"
          name="billerCountry"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerCountry
              ? transactionDetail.billerCountry
              : null
          }
          disabled={isDisabled}
        >
          {getCountries().map((country, index) => (
            <option key={`country-${index}`} value={country.value}>
              {country.label}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderCityField
   * @returns {Component}
   */
  _renderCityField() {
    const { handleChange, isDisabled, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>City</Label>
        <Input
          type="text"
          name="billerCity"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerCity
              ? transactionDetail.billerCity
              : null
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderStateField
   * @returns {Component}
   */
  _renderStateField() {
    const { handleChange, isDisabled, transactionDetail } = this.props
    return (
      <FormGroup>
        <Label>State</Label>
        <Input
          type="text"
          name="billerState"
          onChange={handleChange}
          value={
            transactionDetail && transactionDetail.billerState
              ? transactionDetail.billerState
              : null
          }
          disabled={isDisabled}
        />
      </FormGroup>
    )
  }

  render() {
    return (
      <SectionWrapper sectionTitle="Billing Address">
        <Form>
          <Row>
            <Col md={6}>{this._renderFirstNameField()}</Col>
            <Col md={6}>{this._renderLastNameField()}</Col>
          </Row>

          <Row>
            <Col md={6}>{this._renderCompanyNameField()}</Col>
            <Col md={6}>{this._renderAddressField()}</Col>
          </Row>

          <Row>
            <Col md={6}>{this._renderPostalCodeField()}</Col>
            <Col md={6}>{this._renderCountryField()}</Col>
          </Row>
          <Row>
            <Col md={6}>{this._renderCityField()}</Col>
            <Col md={6}>{this._renderStateField()}</Col>
          </Row>
        </Form>
      </SectionWrapper>
    )
  }
}

export default BillingAddressForm
