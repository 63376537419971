import config from 'shared/configs/config.json'

export const servicesList = () => {
  return config.services
}

export const getServiceNameById = serviceId => {
  let serviceList = config.services
  const getObject = serviceList.find(({ id }) => id === Number(serviceId))
  return getObject && getObject.name ? getObject.name : null
}
