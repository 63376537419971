import React, { useState } from 'react'
import PropTypes from 'prop-types'

import SectionWrapper from 'shared/components/SectionWrapper'

import { graphql, compose } from 'react-apollo'
import { IMPORT_USERS } from '../../schemas/mutations'

import styles from './BulkRegistrationDocument.module.scss'
import classNames from 'classnames'

const BulkRegistration = ({ importUsers }) => {
  const [isProcessing, changeIsProcessing] = useState(false)
  const [importSummary, updateImportSummary] = useState('')

  const handleImportUsers = async e => {
    try {
      const file = e.target.files[0]
      changeIsProcessing(true)

      const response = await importUsers({
        variables: {
          file
        }
      })

      updateImportSummary(
        response.data.importUsers && response.data.importUsers.message
      )
      changeIsProcessing(false)
    } catch (err) {
      changeIsProcessing(false)
      return err
    }
  }

  return (
    <div>
      <SectionWrapper sectionTitle="Import Users" isMainPage>
        <p className={styles.alertText}>
          The excel file must only contain following fields: first_name,
          last_name, email
        </p>
        <h5>Sample format:</h5>
        <table className={styles.excelTable}>
          <thead>
            <tr>
              <th>first_name</th>
              <th>last_name</th>
              <th>email</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>James</td>
              <td>Doe</td>
              <td>james-doe@gmail.com</td>
            </tr>
          </tbody>
        </table>
        <p>
          Download the excel template:{' '}
          <a href="http://assets.bossjob.com/admin/bulk_registration_template.csv">
            Click Me to Download
          </a>
        </p>
        <div className={styles.container}>
          <label
            className={classNames([
              styles.documentLabel,
              isProcessing ? styles.processing : null
            ])}
            id="documentLabel"
            htmlFor="document"
          >
            <input
              type="file"
              onInput={e => handleImportUsers(e)}
              className={styles.document}
              accept=".csv, .xls"
              id="document"
            />
          </label>
          <p className={styles.notificationMessage}>{importSummary}</p>
        </div>
      </SectionWrapper>
    </div>
  )
}

BulkRegistration.propTypes = {
  importUsers: PropTypes.func.isRequired
}

export default compose(graphql(IMPORT_USERS, { name: 'importUsers' }))(
  BulkRegistration
)
