import React from 'react'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import DialogueListContainer from '../../containers/DialogueListContainer'
import SearchBar from 'shared/components/SearchBar'

const Chat = () => (
  <>
    <SearchBar />
    <SectionWrapper sectionTitle="Dialogue">
      <DialogueListContainer />
    </SectionWrapper>
  </>
)

export default Chat
