import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Input } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

class CompanyReviewList extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    if (e.target.checked) {
      this.props.appendSelectedReview(e.target.value)
    } else {
      this.props.removeSelectedReview(e.target.value)
    }
  }

  render() {
    const { reviewList, totalCount, totalPages, currentPage } = this.props

    return (
      <React.Fragment>
        <Table bordered>
          <thead>
            <tr>
              <th />
              <th>ID</th>
              <th>User ID</th>
              <th>Good</th>
              <th>Challenge</th>
              <th style={{ textAlign: 'center' }}>Flags</th>
              <th>Created at</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reviewList.map(review => (
              <tr key={`review-${review.id}`}>
                <td>
                  <Input
                    addon
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    onChange={this.handleChange}
                    value={review.id}
                  />
                </td>
                <td>
                  <a
                    href={`/bossjob/company/${review.companyId}/review/${
                      review.id
                    }`}
                  >
                    {review.id}
                  </a>
                </td>
                <td>
                  <a href={`/bossjob/user/${review.userId}`}>{review.userId}</a>
                </td>
                <td>{review.goodContent}</td>
                <td>{review.challengeContent}</td>
                <td style={{ textAlign: 'center' }}>
                  {review.flags.length !== 0 ? review.flags.length : '-'}
                </td>
                <td>{review.createdAt}</td>
                <td />
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          totalPages={totalPages}
          pageRange={4}
        />
      </React.Fragment>
    )
  }
}

CompanyReviewList.propTypes = {
  reviewList: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  appendSelectedReview: PropTypes.func.isRequired,
  removeSelectedReview: PropTypes.func.isRequired
}

export default CompanyReviewList
