import React from 'react'
import { Query } from 'react-apollo'

import { GET_LOCAL_NEPTUNE_COMPANY_DETAIL } from '../../../schemas/queries'

const DocumentContainer = () => {
  return (
    <div>
      <Query query={GET_LOCAL_NEPTUNE_COMPANY_DETAIL}>
        {({ data: { getNeptuneCompany }, loading, error }) => {
          const {
            ok,
            message,
            data: { neptuneCompanyDocument }
          } = getNeptuneCompany
          if (loading) return <div>Loading...</div>
          if (error) return <div>Error :</div>

          if (!ok) {
            return <div>{message}</div>
          }

          const { url } = neptuneCompanyDocument || {}

          if (url) {
            return (
              <div>
                Down: <a href={url}>Neptune Document Verification File</a>
              </div>
            )
          }

          return <div>Nothing</div>
        }}
      </Query>
    </div>
  )
}

export default DocumentContainer
