import React from 'react'
import PropTypes from 'prop-types'
import DescriptionForm from '../../../views/AgencyDetail/AgencySettings/DescriptionForm'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Bootstrap */
import { Button } from 'reactstrap'

/**
 * DescriptionContainer - main wrapper component for the Agency Information page
 *
 * @returns {Component}
 */
const DescriptionContainer = ({
  agencyDetail,
  saveAgencyDetail,
  isLoading
}) => {
  return (
    <ApolloConsumer>
      {client => (
        <div>
          <DescriptionForm
            description={
              agencyDetail && agencyDetail.description
                ? agencyDetail.description
                : null
            }
            descriptionHtml={
              agencyDetail && agencyDetail.descriptionHtml
                ? agencyDetail.descriptionHtml
                : null
            }
          />
          <Can I="edit" a="companies">
            <div style={{ textAlign: 'right' }}>
              <Button
                color="info"
                onClick={() => saveAgencyDetail(client)}
                disabled={isLoading}
              >
                Update
              </Button>
            </div>
          </Can>
        </div>
      )}
    </ApolloConsumer>
  )
}

DescriptionContainer.propTypes = {
  agencyDetail: PropTypes.object,
  handleChangeForExistingForm: PropTypes.func,
  saveAgencyDetail: PropTypes.func,
  isLoading: PropTypes.bool
}

export default DescriptionContainer
