export default {
  subscriptionPlanDetail: {
    __typename: 'SubscriptionPlanType',
    data: {}
  },
  subscriptionPlanDetailForNewForm: {
    __typename: 'AddSubscriptionPlanType',
    categoryId: 4,
    name: '',
    monthlyFee: 0,
    annualFee: 0,
    monthlyJobCredit: 0,
    monthlyFeatureJobCredit: 0,
    monthlyUrgentJobCredit: 0,
    monthlyUnlockTalentCredit: 0,
    monthlyRhTalentCredit: 0,
    maxNoOfScreeningQuestionsPerJob: 0,
    maxNoOfEmailsToReceiveResumePerJob: 0,
    partnerNetwork: true,
    featureJob: true,
    urgentJob: true,
    autoGenerateJobDescription: true,
    screeningQuestion: true,
    jobMirroring: true,
    redirectExternalUrl: true,
    jobSalaryMasking: true,
    viewApplicationOnWebsite: true,
    applicantStatusTracking: true,
    receiveResumeInEmail: true,
    advanceResumeScoring: true,
    exportApplicationInExcel: true,
    liveChatWithApplicant: true,
    brandedEmployerCareerPage: true,
    topEmployerLogo: true,
    basicStatisticJobViewAndApplication: true,
    exportJobStatisticInExcel: true,
    phoneChatEmailSupport: true,
    dedicatedAccountManager: true,
    roboHeadhunting: true,
    resumeRequest: true,
    bosshuntAts: true,
    customizeChatAutoResponse: true,
    autoApproveJob: true,
    autoReplyChatMessage: true
  }
}
