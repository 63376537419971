exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".credit__d6N0Q {\n  color: #20a8d8;\n  font-size: 18px;\n  font-weight: bold;\n}\n.creditBox__1Rw8G {\n  width: 280px;\n  border: 1px solid #b8babd;\n  background: #fff;\n  border-radius: 5px;\n  padding: 10px;\n  margin-right: 30px;\n}\n.creditWrapper__3drzB {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n}\n.creditSub__18RIK {\n  color: #73818f;\n  font-size: 11px;\n  text-transform: uppercase;\n  font-weight: bold;\n}\n.creditIconHolder__1YGLE {\n  width: 52px;\n  height: 52px;\n  background: #20a8d8;\n}", "", {"version":3,"sources":["/app/src/shared/components/CreditBox/CreditBox.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAc;EACd,gBAAe;EACf,kBAAiB;CACnB;AACA;EACE,aAAY;EACZ,0BAAyB;EACzB,iBAAgB;EAChB,mBAAkB;EAClB,cAAa;EACb,mBAAkB;CACpB;AACA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,wBAA2B;MAA3B,qBAA2B;UAA3B,4BAA2B;EAC3B,uBAAqB;MAArB,oBAAqB;UAArB,sBAAqB;CACvB;AACA;EACE,eAAc;EACd,gBAAe;EACf,0BAAyB;EACzB,kBAAiB;CACnB;AACA;EACE,YAAW;EACX,aAAY;EACZ,oBAAmB;CACrB","file":"CreditBox.module.scss","sourcesContent":[".credit {\n  color: #20a8d8;\n  font-size: 18px;\n  font-weight: bold;\n}\n.creditBox {\n  width: 280px;\n  border: 1px solid #b8babd;\n  background: #fff;\n  border-radius: 5px;\n  padding: 10px;\n  margin-right: 30px;\n}\n.creditWrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-end;\n}\n.creditSub {\n  color: #73818f;\n  font-size: 11px;\n  text-transform: uppercase;\n  font-weight: bold;\n}\n.creditIconHolder {\n  width: 52px;\n  height: 52px;\n  background: #20a8d8;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"credit": "credit__d6N0Q",
	"creditBox": "creditBox__1Rw8G",
	"creditWrapper": "creditWrapper__3drzB",
	"creditSub": "creditSub__18RIK",
	"creditIconHolder": "creditIconHolder__1YGLE"
};