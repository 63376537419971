import React from 'react'

/* Permissions */
import { Can } from 'shared/configs/abilityContextConfig'

import AddRole from './AddRole'
import Layout from 'shared/components/Layout'
import NotAuthorize from 'shared/components/NotAuthorize'

export default () => (
  <Layout>
    <Can I="edit" a="admin_user" passThrough>
      {can => (can ? <AddRole /> : <NotAuthorize />)}
    </Can>
  </Layout>
)
