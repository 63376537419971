import gql from 'graphql-tag'

/*====== Fragments =====*/
const dialogueFragment = gql`
  fragment dialogueFields on DialogueType {
    id
    founderId
    participantId
    jobApplicationId
    statusId
    createdAt
    updatedAt
    lastChatAt
  }
`

const chatMessageFragment = gql`
  fragment chatMessageFields on ChatType {
    id
    sendUserId
    receiveUserId
    dialogueId
    actionId
    content
    fileLocationUrl
    status
    ip
    statusMark
    remark
    createdAt
    updatedAt
  }
`

/*====== Query ======*/
export const GET_ALL_DIALOGUES = gql`
  query GetAllDialogues(
    $page: Int
    $query: String
    $date: AllDialogueDateTimeRange
    $statusId: Int
  ) {
    allDialogues(
      size: 15
      page: $page
      queryData: $query
      date: $date
      statusId: $statusId
    ) {
      data {
        dialogues {
          ...dialogueFields
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
  ${dialogueFragment}
`
export const GET_DIALOGUE_CHAT_MESSAGES = gql`
  query getDialogueChatMessages($dialogueId: Int!, $page: Int) {
    allChats(dialogueId: $dialogueId, size: 15, page: $page) {
      data {
        chats {
          ...chatMessageFields
        }
        dialogue {
          ...dialogueFields
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
  ${chatMessageFragment}
  ${dialogueFragment}
`

/*====== Local State ======*/
export const GET_LOCAL_DIALOGUE_DETAIL = gql`
  {
    dialogueDetail @client {
      ...dialogueFields
    }
  }
  ${dialogueFragment}
`
