exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".tableTd__1sdJM {\n  word-break: break-all;\n  vertical-align: middle !important;\n}", "", {"version":3,"sources":["/app/src/modules/maintenance/views/List/Main.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAqB;EACrB,kCAAiC;CACnC","file":"Main.module.scss","sourcesContent":[".tableTd {\n  word-break: break-all;\n  vertical-align: middle !important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"tableTd": "tableTd__1sdJM"
};