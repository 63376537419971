import React from 'react'
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Query, Mutation } from 'react-apollo'
import { GET_ALL_USERS, GET_ME } from '../../../../users/schemas/queries'
import { GET_COMPANY_COORDINATOR } from '../../../schemas/queries'
import { UPDATE_COMPANY_DETAIL } from '../../../schemas/mutations'

/* Bootstrap */
import { Col, Input, FormGroup, Label, Button } from 'reactstrap'

import SectionWrapper from 'shared/components/SectionWrapper'
import CompanySalesPersonLogsContainer from '../../../containers/CompanySalesPersonLogsContiner'
import Loading from 'shared/components/Loading'

const CompanyInternals = ({ match, data }) => {
  const [selectedSalesPerson, setSelectedSalesPerson] = useState('')

  const handleSalesPersonChange = e => {
    setSelectedSalesPerson(e.target.value)
  }

  const camelCaseToCapitalCase = input => {
    // Split the input string into words based on uppercase letters
    const words = input.split(/(?=[A-Z])/)

    // Capitalize the first letter of each word and join them back together
    const capitalWords = words.map(
      word => word.charAt(0).toUpperCase() + word.slice(1)
    )

    // Join the capital words with spaces to form the final string
    return capitalWords.join(' ')
  }

  const companyDetail = data
  useEffect(
    () => {
      if (companyDetail && companyDetail.salesPersonEmail) {
        setSelectedSalesPerson(data.salesPersonEmail)
      }
    },
    [data]
  )

  return (
    <div>
      <SectionWrapper sectionTitle="Company coordinator details">
        <Query
          query={GET_COMPANY_COORDINATOR}
          variables={{ companyId: match.params.id }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loading />
            } else {
              if (data && data.getCompanyCoordinator) {
                const coordinator_data = data.getCompanyCoordinator.data
                const user_fields = [
                  'email',
                  'firstName',
                  'lastName',
                  'recruiterOnboardingContactNumber'
                ]
                return (
                  <>
                    {
                      <>
                        <Label>
                          User Id:{' '}
                          <a href={`/bossjob/user/${coordinator_data.userId}`}>
                            {coordinator_data.userId}
                          </a>
                        </Label>
                        <br />
                      </>
                    }
                    {user_fields.map(field => (
                      <>
                        <Label>
                          {camelCaseToCapitalCase(field)}:{' '}
                          {coordinator_data.user &&
                            coordinator_data.user[field]}
                        </Label>
                        <br />
                      </>
                    ))}
                  </>
                )
              } else {
                return <p>Coordinator not found</p>
              }
            }
          }}
        </Query>
      </SectionWrapper>

      <SectionWrapper sectionTitle="Salesperson details/history" x>
        <Query query={GET_ALL_USERS} variables={{ size: 1000 }}>
          {({ loading, data }) => {
            const allUsers = data
            if (loading) {
              return <Loading />
            } else {
              if (allUsers && allUsers.allAdminUsers.data.adminUsers) {
                return (
                  <Query query={GET_ME}>
                    {({ loading, data }) => {
                      if (loading) {
                        return <Loading />
                      } else {
                        const userEmail = data.me.email
                        const users = allUsers.allAdminUsers.data.adminUsers
                        const adminList = users.filter(
                          user => user.role.roleName === 'Administrator'
                        )
                        return (
                          <>
                            <FormGroup row>
                              <Label lg={3} sm={12}>
                                Appoint Salesperson
                              </Label>
                              <Col md={4} sm={12}>
                                <Input
                                  type="select"
                                  name="salesPersonEmail"
                                  value={selectedSalesPerson}
                                  onChange={handleSalesPersonChange}
                                >
                                  <option value={''}>None</option>
                                  {users.map((user, index) => {
                                    if (user.role.roleName == 'BD Team') {
                                      return (
                                        <option key={index} value={user.email}>
                                          {user.email}
                                        </option>
                                      )
                                    }
                                  })}
                                </Input>
                              </Col>
                              <Mutation mutation={UPDATE_COMPANY_DETAIL}>
                                {updateCompanyDetail => (
                                  <>
                                    <Button
                                      disabled={
                                        !adminList.some(
                                          user => user.email === userEmail
                                        )
                                      }
                                      onClick={async () => {
                                        await updateCompanyDetail({
                                          variables: {
                                            companyId: match.params.id,
                                            payload: {
                                              salesPersonEmail: selectedSalesPerson,
                                              companySizeKey:
                                                companyDetail.companySizeKey,
                                              industryKey:
                                                companyDetail.industryKey,
                                              locationKey:
                                                companyDetail.locationKey
                                            }
                                          }
                                        })
                                        window.location.reload()
                                      }}
                                    >
                                      Update Salesperson
                                    </Button>
                                  </>
                                )}
                              </Mutation>
                            </FormGroup>
                            <FormGroup row>
                              <CompanySalesPersonLogsContainer
                                companyId={companyDetail.id}
                                adminList={adminList}
                              />
                            </FormGroup>
                          </>
                        )
                      }
                    }}
                  </Query>
                )
              }
            }
          }}
        </Query>
      </SectionWrapper>
    </div>
  )
}

CompanyInternals.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  data: PropTypes.object
}

export default withRouter(CompanyInternals)
