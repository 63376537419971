exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".fieldset__1xi6A {\n  margin-bottom: 15px;\n}\n\n.formContainer__yBfiJ {\n  border: 1px solid grey;\n  padding: 20px;\n}\n\n.row__2CxXv {\n  padding: 5px 0;\n}\n\n.buttonContainer__1Yw23 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  padding: 15px 20px 5px 0;\n}\n.buttonContainer__1Yw23 .button__2i166 {\n  margin-right: 10px;\n}", "", {"version":3,"sources":["/app/src/modules/marketing/views/ProductFeatureAnnouncement/ProductFeatureAnnouncementContainer/components/ProductFeatureAnnouncementForm.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAmB;CACrB;;AAEA;EACE,uBAAsB;EACtB,cAAa;CACf;;AAEA;EACE,eAAc;CAChB;;AAEA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,sBAAyB;MAAzB,mBAAyB;UAAzB,0BAAyB;EACzB,yBAAwB;CAC1B;AACA;EACE,mBAAkB;CACpB","file":"ProductFeatureAnnouncementForm.module.scss","sourcesContent":[".fieldset {\n  margin-bottom: 15px;\n}\n\n.formContainer {\n  border: 1px solid grey;\n  padding: 20px;\n}\n\n.row {\n  padding: 5px 0;\n}\n\n.buttonContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  padding: 15px 20px 5px 0;\n}\n.buttonContainer .button {\n  margin-right: 10px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"fieldset": "fieldset__1xi6A",
	"formContainer": "formContainer__yBfiJ",
	"row": "row__2CxXv",
	"buttonContainer": "buttonContainer__1Yw23",
	"button": "button__2i166"
};