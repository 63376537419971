import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import styles from './FeaturedBannerInputForm.module.scss'

const FeaturedBannerInputForm = ({
  featureBanner,
  addFeatureBanner,
  updateFeatureBanner,
  removeFeatureBanner
}) => {
  const [requesting, setRequesting] = useState(false)

  const handleSubmit = e => {
    try {
      setRequesting(true)
      if (!featureBanner.id) {
        return addFeatureBanner(e)
      }
      return updateFeatureBanner(e)
    } catch (err) {
      setRequesting(false)
      console.log(err)
    }
  }

  const handleDelete = bannerId => {
    try {
      setRequesting(true)
      removeFeatureBanner(bannerId)
      return setRequesting(false)
    } catch (err) {
      setRequesting(false)
      console.log(err)
    }
  }

  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>Banner</legend>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="sortOrder">Sort order</Label>
              <Input
                type="number"
                min="1"
                name="sortOrder"
                id="sortOrder"
                placeholder="Sort order..."
                defaultValue={featureBanner.sortOrder}
                required
              />
            </FormGroup>
          </Col>
          {/* <Col md={6}>
            <FormGroup>
              <Label for="logoPic">Logo</Label>
              {featureBanner.logoUrl ? (
                <a
                  style={{ float: 'right', marginBottom: '15px' }}
                  href={featureBanner.logoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View current logo
                </a>
              ) : null}
              <Input
                type="file"
                name="logoPic"
                id="logoPic" 
                // required={!featureBanner.id}
              />
            </FormGroup>
          </Col> */}
        </Row>
        {/* <FormGroup>
          <Label for="title">Title</Label>
          <Input
            type="text"
            name="title"
            id="title"
            placeholder="Title..."
            defaultValue={featureBanner.title}
          />
          <FormGroup className={styles.colorInput}>
            <Label for="colorCode">Title Color</Label>
            <Input
              type="color"
              name="colorCode"
              id="colorCode"
              defaultValue={featureBanner.colorCode || '#136fd3'}
            />
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            placeholder="Description..."
            defaultValue={featureBanner.description}
          />
        </FormGroup>
        <FormGroup className={styles.colorInput}>
          <Label for="colorCode">Description Color</Label>
          <Input
            type="color"
            name="descriptionColorCode"
            id="descriptionColorCode"
            defaultValue={featureBanner.descriptionColor || '#353535'}
          />
        </FormGroup> */}
        <FormGroup>
          <Label for="coverPic">Cover Picture</Label>
          {featureBanner.coverPicUrl ? (
            <a
              style={{ float: 'right', marginBottom: '15px' }}
              href={featureBanner.coverPicUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View current banner
            </a>
          ) : null}
          <Input type="file" name="coverPic" id="coverPic" />
        </FormGroup>
        <FormGroup>
          <Label for="mobileCoverPic">Tablet Cover Picture</Label>
          {featureBanner.tabletCoverPicUrl ? (
            <a
              style={{ float: 'right', marginBottom: '15px' }}
              href={featureBanner.tabletCoverPicUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View current tablet banner
            </a>
          ) : null}
          <Input type="file" name="tabletCoverPic" id="tabletCoverPic" />
        </FormGroup>
        <FormGroup>
          <Label for="mobileCoverPic">Mobile Cover Picture</Label>
          {featureBanner.mobileCoverPicUrl ? (
            <a
              style={{ float: 'right', marginBottom: '15px' }}
              href={featureBanner.mobileCoverPicUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View current mobile banner
            </a>
          ) : null}
          <Input type="file" name="mobileCoverPic" id="mobileCoverPic" />
        </FormGroup>
        {/* <FormGroup className={styles.colorInput}>
          <Label for="colorCode">Call to action button Color</Label>
          <Input
            type="color"
            name="ctaButtonColorCode"
            id="ctaButtonColorCode"
            defaultValue={featureBanner.buttonColor || '#fff'}
          />
        </FormGroup>
        <FormGroup>
          <Label for="ctaText">Call to action (Text)</Label>
          <Input
            type="textarea"
            name="ctaText"
            id="ctaText"
            placeholder="Cta text..."
            defaultValue={featureBanner.ctaText}
          />
        </FormGroup>
        <FormGroup className={styles.colorInput}>
          <Label for="colorCode">Call to action text Color</Label>
          <Input
            type="color"
            name="ctaTextColorCode"
            id="ctaTextColorCode"
            defaultValue={featureBanner.ctaTextColor || '#fff'}
          />
        </FormGroup> */}
        <FormGroup>
          <Label for="ctaUrl">Call to action (URL)</Label>
          <Input
            type="url"
            name="ctaUrl"
            id="ctaUrl"
            placeholder="Cta url..."
            defaultValue={featureBanner.ctaUrl}
          />
        </FormGroup>
        {featureBanner.id ? (
          <Input
            type="hidden"
            name="bannerId"
            id="bannerId"
            value={featureBanner.id}
          />
        ) : null}
        <Button
          disabled={requesting}
          className={styles.submitBtn}
          type="primary"
          color={featureBanner.id ? 'success' : 'primary'}
        >
          {featureBanner.id
            ? requesting
              ? 'Updating...'
              : 'Update Banner'
            : requesting
              ? 'Creating...'
              : 'Create Banner'}
        </Button>
        {featureBanner.id ? (
          <Button
            disabled={requesting}
            className={styles.deleteBtn}
            type="button"
            color="danger"
            onClick={() => handleDelete(featureBanner.id)}
          >
            {requesting ? 'Deleting...' : 'Delete'}
          </Button>
        ) : null}
      </Form>
    </fieldset>
  )
}

FeaturedBannerInputForm.propTypes = {
  featureBanner: PropTypes.object,
  addFeatureBanner: PropTypes.func.isRequired,
  updateFeatureBanner: PropTypes.func.isRequired,
  removeFeatureBanner: PropTypes.func.isRequired,
  requesting: PropTypes.bool.isRequired
}

FeaturedBannerInputForm.defaultProps = {
  featureBanner: {}
}

export default memo(FeaturedBannerInputForm)
