import React from 'react'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import HeadhunterListContainer from '../../../containers/AgencyDetailContainer/HeadhunterListContainer'

/**
 * Headhunters - main view for Headhunter list under Company page
 *
 */
const Headhunters = () => (
  <SectionWrapper sectionTitle="Headhunters under this agency" isMainPage>
    <HeadhunterListContainer />
  </SectionWrapper>
)

export default Headhunters
