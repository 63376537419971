exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".mapWrapper__28BJK {\n  max-width: 80% !important;\n}", "", {"version":3,"sources":["/app/src/shared/components/GoogleMap/GoogleMap.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAyB;CAC3B","file":"GoogleMap.module.scss","sourcesContent":[".mapWrapper {\n  max-width: 80% !important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mapWrapper": "mapWrapper__28BJK"
};