import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Components */
import ToggleButton from 'shared/components/ToggleButton'
import ImageField from 'shared/components/ImageField'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

import { getNewConfigList } from 'shared/helpers/newConfigList'
/**
 * AccountSettingForm - form component for BossJobUserDetail - AccountSetting page
 *
 * @returns {Component}
 */
const AccountSettingForm = ({
  userData: {
    avatar,
    firstName,
    lastName,
    email,
    phoneNum,
    activeKey,
    isSubscribe,
    statusKey,
    isProfileCompleted,
    isEmailVerify,
    isMobileVerified,
    lastActiveAt,
    mobileCountryId
  },
  handleChange,
  updateUser,
  isUpdating,
  handleImageChange,
  previewLogo
}) => {
  const [options, setOptions] = useState({})
  const [countryCode, setCountryCode] = useState(null)

  useEffect(() => {
    async function updateOptions() {
      const data = await getNewConfigList()
      const filteredCountries = data.country_lists.filter(
        country => country.is_sms_allowed
      )
      setOptions({
        country_lists: filteredCountries
      })
    }
    updateOptions()
  }, [])

  const resolveCountryCode = mobileCountryId => {
    if (options.country_lists) {
      const country = options.country_lists.find(c => c.id === mobileCountryId)
      if (country) {
        setCountryCode(country.code)
      }
    }
  }

  const trimMobileNumber = (mobileNumber, mobileCountryId) => {
    if (options.country_lists) {
      const country = options.country_lists.find(c => c.id === mobileCountryId)
      if (country) {
        setCountryCode(country.code)
        if (mobileNumber && mobileNumber.startsWith(country.code)) {
          return mobileNumber.slice(country.code.length)
        }
        return mobileNumber
      }
    }
  }
  return (
    <ApolloConsumer>
      {client => (
        <Form>
          <div style={{ textAlign: 'center', marginBottom: '2em' }}>
            <Can I="edit" a="bossjob_user" passThrough>
              {can => (
                <ImageField
                  hasPermission={can && statusKey !== 'inactive'}
                  name="userAvatar"
                  imageSrc={previewLogo !== '' ? previewLogo : avatar}
                  handleChange={handleImageChange}
                />
              )}
            </Can>
            <span style={{ display: 'block' }}>Max 1 MB</span>
          </div>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  First Name
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    disabled={statusKey === 'inactive'}
                    name="firstName"
                    value={firstName}
                    onChange={e => {
                      handleChange(client, 'firstName', e.target.value)
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Last Name
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    disabled={statusKey === 'inactive'}
                    name="lastName"
                    value={lastName}
                    onChange={e => {
                      handleChange(client, 'lastName', e.target.value)
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Email
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    disabled={statusKey === 'inactive'}
                    name="email"
                    value={email}
                    onChange={e => {
                      handleChange(client, 'email', e.target.value)
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Mobile Country Code
                </Label>
                <Input
                  type="select"
                  name="mobileCountryId"
                  value={mobileCountryId}
                  key={countryCode}
                  onChange={e => {
                    resolveCountryCode(e.target.key)
                    handleChange(client, 'mobileCountryId', e.target.value)
                    handleChange(client, 'phoneNum', '')
                  }}
                  style={{ maxWidth: '300px', marginLeft: '15px' }}
                >
                  <option key="" value="" disabled={mobileCountryId}>
                    No Country Code Selected
                  </option>
                  {options.country_lists &&
                    options.country_lists.map(country => (
                      <option key={country.code} value={parseInt(country.id)}>
                        {`${country.code} (${country.value})`}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Mobile
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    disabled={statusKey === 'inactive'}
                    name="phoneNum"
                    type="number"
                    value={
                      phoneNum
                        ? trimMobileNumber(phoneNum, mobileCountryId)
                        : phoneNum
                    }
                    onChange={e => {
                      handleChange(client, 'phoneNum', e.target.value)
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Password
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    disabled
                    name="password"
                    value="xxxx"
                    type="password"
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Role
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    disabled
                    name="role"
                    value={activeKey === 1 ? 'Jobseeker' : 'Recruiter'}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Profile Completed
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    disabled
                    name="role"
                    value={isProfileCompleted === true ? 'Yes' : 'No'}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  News Subscription
                </Label>
                <Col
                  md={9}
                  sm={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <ToggleButton
                    disabled={statusKey === 'inactive'}
                    isChecked={isSubscribe === 1}
                    onChange={() => {
                      handleChange(
                        client,
                        'isSubscribe',
                        isSubscribe === 1 ? 0 : 1
                      )
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Email Verification Status
                </Label>
                <Col md={9} sm={12}>
                  <ToggleButton
                    disabled={statusKey === 'inactive'}
                    isChecked={isEmailVerify === 1}
                    onChange={() => {
                      handleChange(
                        client,
                        'isEmailVerify',
                        isEmailVerify === 1 ? 0 : 1
                      )
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Mobile Verification Status
                </Label>
                <Col md={9} sm={12}>
                  <ToggleButton
                    disabled={statusKey === 'inactive'}
                    isChecked={isMobileVerified === 1}
                    onChange={() => {
                      handleChange(
                        client,
                        'isMobileVerified',
                        isMobileVerified === 1 ? 0 : 1
                      )
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row>
                <Label md={3}>Last Login Date</Label>
                <Col md={9}>
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {new Date(lastActiveAt).toLocaleDateString()}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} />
          </Row>
          {statusKey !== 'inactive' ? (
            <Can I="edit" a="bossjob_user">
              <div style={{ textAlign: 'right' }}>
                <Button
                  color="info"
                  onClick={() => updateUser(client)}
                  disabled={isUpdating}
                >
                  Save
                </Button>
              </div>
            </Can>
          ) : (
            <div style={{ textAlign: 'right', color: 'red' }}>
              This user is inactive
            </div>
          )}
        </Form>
      )}
    </ApolloConsumer>
  )
}

AccountSettingForm.propTypes = {
  userData: PropTypes.object,

  updateUser: PropTypes.func.isRequired,

  handleChange: PropTypes.func.isRequired,

  isUpdating: PropTypes.bool.isRequired,

  handleImageChange: PropTypes.func.isRequired,

  previewLogo: PropTypes.string.isRequired
}

export default AccountSettingForm
