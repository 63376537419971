import React from 'react'

/* Components */
import JobDetailContainer from '../../containers/JobDetailContainer'

const JobDetail = () => {
  return (
    <>
      <JobDetailContainer />
    </>
  )
}

export default JobDetail
