exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".uploadBox__3qN8T {\n  text-align: center;\n  border: 1px dashed;\n  padding: 5%;\n  cursor: pointer;\n}\n.uploadBox__3qN8T .fileInput__3cbUg {\n  display: none;\n}", "", {"version":3,"sources":["/app/src/shared/components/FileUploadField/FileUploadField.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAkB;EAClB,mBAAkB;EAClB,YAAW;EACX,gBAAe;CACjB;AACA;EACE,cAAa;CACf","file":"FileUploadField.module.scss","sourcesContent":[".uploadBox {\n  text-align: center;\n  border: 1px dashed;\n  padding: 5%;\n  cursor: pointer;\n}\n.uploadBox .fileInput {\n  display: none;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"uploadBox": "uploadBox__3qN8T",
	"fileInput": "fileInput__3cbUg"
};