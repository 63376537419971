import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'
import dialogueStatusMapper from 'shared/helpers/dialogueStatusMapper'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

/**
 * MessageList - Presentational Component to render message list table
 *
 * @returns {Component}
 */
const MessageList = ({
  dialoguesList,
  currentPage,
  totalCount,
  totalPages
}) => (
  <>
    <Table bordered>
      <thead>
        <tr>
          <th>Dialogue ID</th>
          <th>Job Application ID</th>
          <th>Chat with (Other user ID)</th>
          <th>Status</th>
          <th>Created</th>
          <th>Last modified</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {dialoguesList.map((dialogue, index) => {
          return (
            <tr key={index}>
              <td>
                <a href={`/bossjob/chat-detail/${dialogue.id}/1`}>
                  {dialogue.id}
                </a>
              </td>
              <td>{dialogue.jobApplicationId}</td>
              <td>
                <a href={`/bossjob/user/${dialogue.anotherUserId}`}>
                  {dialogue.anotherUserId}
                </a>
              </td>
              <td>{dialogueStatusMapper(dialogue.statusId)}</td>
              <td>{formatDate(dialogue.createdAt, 'DD/MM/YYYY hh:mm:ss')}</td>
              <td>{formatDate(dialogue.lastUpdated, 'DD/MM/YYYY hh:mm:ss')}</td>
              <td>
                <div style={{ textAlign: 'center' }}>
                  <a href={`/bossjob/chat-detail/${dialogue.id}/1`}>
                    <Button color="success">
                      <img src={Zoom} alt="Zoom" width="16" height="16" />
                    </Button>
                  </a>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
    <Pagination
      currentPage={Number(currentPage)}
      totalCount={totalCount}
      totalPages={totalPages}
      pageRange={4}
    />
  </>
)

MessageList.propTypes = {
  dialoguesList: PropTypes.array.isRequired,
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
}

export default MessageList
