exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".form__278iL {\n  max-width: 450px;\n  padding: 20px;\n}\n\n.fieldset__3R1Bl {\n  border: 1px solid #ddd;\n  margin: 0;\n  padding: 10px;\n}\n\n.legend__11zU3 {\n  width: auto;\n  padding: 0 10px;\n}\n\n.submitBtn__oPi7g {\n  margin-top: 20px;\n}\n\n.deleteBtn__2cdFg {\n  margin-top: 20px;\n  margin-left: 200px;\n}\n\n.colorInput__2WgED {\n  margin-top: 15px;\n}", "", {"version":3,"sources":["/app/src/modules/marketing/views/LandingPageBanner/FeaturedBannerContainer/components/FeaturedBannerInputForm/FeaturedBannerInputForm.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAgB;EAChB,cAAa;CACf;;AAEA;EACE,uBAAsB;EACtB,UAAS;EACT,cAAa;CACf;;AAEA;EACE,YAAW;EACX,gBAAe;CACjB;;AAEA;EACE,iBAAgB;CAClB;;AAEA;EACE,iBAAgB;EAChB,mBAAkB;CACpB;;AAEA;EACE,iBAAgB;CAClB","file":"FeaturedBannerInputForm.module.scss","sourcesContent":[".form {\n  max-width: 450px;\n  padding: 20px;\n}\n\n.fieldset {\n  border: 1px solid #ddd;\n  margin: 0;\n  padding: 10px;\n}\n\n.legend {\n  width: auto;\n  padding: 0 10px;\n}\n\n.submitBtn {\n  margin-top: 20px;\n}\n\n.deleteBtn {\n  margin-top: 20px;\n  margin-left: 200px;\n}\n\n.colorInput {\n  margin-top: 15px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"form": "form__278iL",
	"fieldset": "fieldset__3R1Bl",
	"legend": "legend__11zU3",
	"submitBtn": "submitBtn__oPi7g",
	"deleteBtn": "deleteBtn__2cdFg",
	"colorInput": "colorInput__2WgED"
};