import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import FileUploadField from 'shared/components/FileUploadField'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

const CompanyDocument = ({
  document,
  file,
  handleFileSelect,
  handleFileUpload,
  companyId,
  isUploading,
  fileId
}) => (
  <React.Fragment>
    <div style={{ marginBottom: '10px' }}>
      {document && document.url ? (
        <>
          <a href={`${document.url}`} target="_blank" rel="noopener noreferrer">
            Click to view document
          </a>
          {fileId === 'company-file' ? (
            <>
              <br />
              <p>
                {' '}
                Document status:
                {'  '}
                {(document.statusKey === 'pending' && 'Pending') ||
                  (document.statusKey === 'change_required' &&
                    'Change Required') ||
                  (document.statusKey === 'verified' && 'Verified') ||
                  ''}
              </p>
            </>
          ) : null}
        </>
      ) : (
        <div>No uploaded document</div>
      )}
    </div>
    {/* {!isCompanyVerified && ( */}
    <Can I="edit" a="companies">
      <FileUploadField
        file={file}
        fileId={fileId}
        handleFileSelect={handleFileSelect}
        handleFileUpload={handleFileUpload}
        id={companyId}
        isUploading={isUploading}
      />
    </Can>
    {/* )} */}
  </React.Fragment>
)

CompanyDocument.propTypes = {
  handleFileSelect: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  isCompanyVerified: PropTypes.bool,
  isUploading: PropTypes.bool.isRequired,
  file: PropTypes.object,
  fileId: PropTypes.string,
  document: PropTypes.object,
  canEdit: PropTypes.bool
}

export default CompanyDocument
