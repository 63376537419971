import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Col, Form, FormGroup, Label } from 'reactstrap'

/* Component */
import TextEditorField from 'shared/components/TextEditorField'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Apollo */
import { compose, graphql } from 'react-apollo'
import { UPDATE_LOCAL_AGENCY_DETAIL } from '../../../../schemas/mutations'

const DescriptionForm = ({ updateLocalAgencyDetail, descriptionHtml }) => {
  // instead of using the usual handler passed by parent, importing it directly
  // becos not able to extract event.target from ReactQuill
  const handleChange = (html, text) => {
    updateLocalAgencyDetail({
      variables: {
        key: 'descriptionHtml',
        value: html
      }
    })
    updateLocalAgencyDetail({
      variables: {
        key: 'description',
        value: text
      }
    })
  }

  return (
    <Form>
      <FormGroup row md={12}>
        <Label md={2}>Description</Label>
        <Col md={10}>
          <Can I="edit" a="companies" passThrough>
            {can =>
              can ? (
                <div>
                  <TextEditorField
                    // value={
                    //   agencyDetail && agencyDetail.descriptionHtml
                    //     ? agencyDetail.descriptionHtml
                    //     : ''
                    // }
                    value={descriptionHtml ? descriptionHtml : ''}
                    name="descriptionHtml"
                    handleChange={handleChange}
                  />
                </div>
              ) : (
                <div>
                  {descriptionHtml !== '' ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: descriptionHtml
                      }}
                    />
                  ) : (
                    // {agencyDetail &&
                    // agencyDetail.descriptionHtml &&
                    // agencyDetail.descriptionHtml !== '' ? (
                    //     <span
                    //       dangerouslySetInnerHTML={{
                    //         __html: agencyDetail.descriptionHtml
                    //       }}
                    //     />
                    <em>No company description</em>
                  )}
                </div>
              )
            }
          </Can>
        </Col>
      </FormGroup>
    </Form>
  )
}

DescriptionForm.propTypes = {
  description: PropTypes.string,
  descriptionHtml: PropTypes.string,
  handleChange: PropTypes.func,
  updateLocalAgencyDetail: PropTypes.func,
  agencyDetail: PropTypes.object,
  isLoading: PropTypes.bool
}

export default compose(
  graphql(UPDATE_LOCAL_AGENCY_DETAIL, {
    name: 'updateLocalAgencyDetail'
  })
)(DescriptionForm)
