import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ConfirmationAlert = ({ isVisible, onConfirm, onClose, title, body }) => (
  <Modal isOpen={isVisible}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>{body}</ModalBody>
    <ModalFooter>
      <Button color="success" onClick={onConfirm}>
        Yes
      </Button>{' '}
      <Button color="secondary" onClick={onClose}>
        No
      </Button>
    </ModalFooter>
  </Modal>
)

ConfirmationAlert.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]).isRequired
}

ConfirmationAlert.defaultProps = {
  isVisible: false
}

export default ConfirmationAlert
