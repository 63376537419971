import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { withRouter } from 'react-router-dom'

/* Assets */
import styles from './Pagination.module.scss'

class Pagination extends Component {
  constructor(props) {
    super(props)

    this.state = {
      renderedPageRange: [1]
    }
  }

  componentDidMount() {
    const { pageRange, currentPage } = this.props
    this.updateActivePageRange(currentPage, pageRange)
  }

  //@TODO: Something is wrong with the typing of `page`, it's being used as a String.
  updateActivePageRange(activePage, pageRange) {
    let activePages = [activePage]
    const { totalPages } = this.props
    const gap = pageRange / 2

    for (let i = 1; i <= gap; i++) {
      if (Number(activePage) + i <= totalPages) {
        activePages.push(Number(activePage) + i)
      }
    }

    for (let i = 1; i <= gap; i++) {
      if (activePage - i > 0) {
        activePages.unshift(activePage - i)
      }
    }

    this.setState({
      renderedPageRange: activePages
    })
  }

  renderPageButton(page) {
    const { currentPage } = this.props

    if (Number(currentPage) === page) {
      return (
        <div key={page} className={styles.pagination}>
          {page}
        </div>
      )
    } else {
      return (
        <div key={page} className={styles.pagination}>
          <a href={`${page}${location.search}`}>{page}</a>
        </div>
      )
    }
  }

  render() {
    const {
      totalPages,
      totalCount,
      currentPage,
      pageRange,
      location
    } = this.props
    const { renderedPageRange } = this.state

    if (pageRange > Math.ceil(totalPages / 2)) {
      return (
        <div>
          <div className={styles.paginationWrapper}>
            <div className={styles.paginationHeader}>
              {totalCount} records, {totalPages} pages
            </div>
            {renderedPageRange.map(page => this.renderPageButton(page))}
          </div>
        </div>
      )
    } else {
      const { renderedPageRange } = this.state
      let endOfRange = false
      let showStartOfRange = false

      if (renderedPageRange.includes(totalPages)) {
        endOfRange = true
      }

      if (totalPages - Number(currentPage) === Math.ceil(pageRange / 2)) {
        endOfRange = true
        renderedPageRange.push(Number(totalPages))
      }

      if (renderedPageRange[0] - 1 >= Math.ceil(pageRange / 2)) {
        showStartOfRange = true
      }

      if (Number(currentPage) - pageRange === 0) {
        renderedPageRange.unshift(Number(1))
      }

      return (
        <div>
          <div className={styles.paginationWrapper}>
            <div className={styles.paginationHeader}>
              {totalCount} records, {totalPages} pages
            </div>
            {showStartOfRange ? (
              <React.Fragment>
                <div key={1} className={styles.pagination}>
                  {totalPages === 1 ? (
                    '1'
                  ) : (
                    <a href={`${1}${location.search}`}>1</a>
                  )}
                </div>
                <div key={'first-half'} className={styles.pagination}>
                  ...
                </div>
              </React.Fragment>
            ) : null}
            {renderedPageRange.map(page => this.renderPageButton(page))}
            {endOfRange ? null : (
              <React.Fragment>
                <div key={'second-half'} className={styles.pagination}>
                  ...
                </div>
                <div key={totalPages} className={styles.pagination}>
                  {totalPages === Number(currentPage) ? (
                    totalPages
                  ) : (
                    <a href={`${totalPages}${location.search}`}>{totalPages}</a>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      )
    }
  }
}

Pagination.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageRange: PropTypes.number.isRequired
}

export default withRouter(Pagination)
