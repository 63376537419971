import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'

import Pagination from 'shared/components/Pagination'

const OtpEmailLogsList = ({ data, currentPage, totalPages, totalCount }) => {
  let emailLogs = Array.from(data.emailLogs)
  return (
    <React.Fragment>
      <Table bordered>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {emailLogs.map(emailLog => (
            <tr key={emailLog.id}>
              <td>{emailLog.id}</td>
              <td>{emailLog.email}</td>
              <td>{emailLog.status}</td>
              <td>{emailLog.createdAt}</td>
              <td>{emailLog.updatedAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        currentPage={currentPage}
        totalCount={totalCount}
        totalPages={totalPages}
        pageRange={4}
      />
    </React.Fragment>
  )
}

OtpEmailLogsList.propTypes = {
  data: PropTypes.object,

  currentPage: PropTypes.number,

  totalCount: PropTypes.number,

  totalPages: PropTypes.number
}

export default OtpEmailLogsList
