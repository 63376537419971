import React from 'react'
import { useState, useEffect } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter, Route, Redirect } from 'react-router-dom'

/* Apollo */
import { Query, withApollo } from 'react-apollo'
import { GET_COMPANY_BY_ID, GET_COMPANY_DOCUMENTS } from '../../schemas/queries'
import { GET_ALL_USERS, GET_ME } from '../../../users/schemas/queries'
/* Components */
import Navigation from './Navigation'
import Loading from 'shared/components/Loading'
import Recruiters from './Recruiters'
import CompanyInformation from './CompanyInformation'
import CompanyReviews from './CompanyReviews'
import ReviewDetailWrapper from './ReviewDetailWrapper'
import CompanyInternals from './CompanyInternals'
import CompanyAnalytics from './CompanyAnalytics'
import { Alert } from 'reactstrap'

/**
 * CompanyDetail - main wrapper component for Bossjob company details and match url to corresponding component
 *
 * @returns {Component}
 */
const CompanyDetail = ({ match, client }) => {
  const [userEmail, setUserEmail] = useState('')
  const [adminList, setAdminList] = useState([])

  useEffect(() => {
    const fetchUserEmail = async () => {
      const response = await client.query({ query: GET_ME })
      setUserEmail(response.data.me.email)
    }
    fetchUserEmail()
  }, [])
  useEffect(() => {
    const fetchAdminList = async () => {
      const response = await client.query({
        query: GET_ALL_USERS,
        variables: { size: 1000 }
      })
      const users = response.data.allAdminUsers.data.adminUsers
      setAdminList(users.filter(user => user.role.roleName === 'Administrator'))
    }
    fetchAdminList()
  }, [])

  return (
    <div>
      <Navigation />

      <Query
        query={GET_COMPANY_BY_ID}
        variables={{ companyId: match.params.id }}
      >
        {({ loading, data, client }) => {
          if (loading) {
            return <Loading />
          } else {
            client.writeData({
              data: { companyDetail: data && data.getCompany && data.getCompany.data || {} }
            })
            const companySalesPerson = data && data.getCompany && data.getCompany.data && data.getCompany.data.salesPersonEmail
            const canEdit =
              adminList.some(user => user.email === userEmail) ||
              companySalesPerson === userEmail

            return (
              <React.Fragment>
                <Query
                  query={GET_COMPANY_DOCUMENTS}
                  variables={{ companyId: match.params.id }}
                >
                  {({ loading, data: companyDocuments }) => {
                    if (loading) {
                      return <Loading />
                    } else {
                      let rejectedReason
                      if (
                        companyDocuments.getCompanyDocuments.data.length > 0
                      ) {
                        rejectedReason =
                          companyDocuments.getCompanyDocuments.data[0]
                            .rejectedReason
                      }
                      return (
                        <>
                          {data.getCompany.data.deletedAt && (
                            <Alert
                              color="danger"
                              style={{ maxWidth: '50%', marginLeft: '15px' }}
                            >
                              This company is deleted at{' '}
                              {data.getCompany.data.deletedAt}
                              <br />
                              Reason:{' '}
                              {data.getCompany.data.deleteCompanyReason
                                ? data.getCompany.data.deleteCompanyReason
                                : 'Deleted by system.'}
                              <br />
                              {rejectedReason
                                ? 'Rejected reason: ' + rejectedReason
                                : null}
                            </Alert>
                          )}
                        </>
                      )
                    }
                  }}
                </Query>

                <Route
                  exact
                  path={`${match.path}`}
                  component={() => <Redirect to={`${match.url}/information`} />}
                />
                <Route
                  exact
                  path="/bossjob/company/:id/information"
                  render={props => (
                    <CompanyInformation
                      {...props}
                      match={match}
                      canEdit={canEdit}
                    />
                  )}
                />
                <Route
                  exact
                  path="/bossjob/company/:id/recruiters/:page"
                  component={Recruiters}
                />
                <Route
                  exact
                  path="/bossjob/company/:id/reviews/:page"
                  component={CompanyReviews}
                />
                <Route
                  exact
                  path="/bossjob/company/:id/review/:reviewId"
                  component={ReviewDetailWrapper}
                />
                <Route
                  exact
                  path="/bossjob/company/:id/internal/:page"
                  render={props => (
                    <CompanyInternals {...props} data={data.getCompany.data} />
                  )}
                />
                <Route
                  exact
                  path="/bossjob/company/:id/analytics/:page"
                  render={props => <CompanyAnalytics {...props} />}
                />
              </React.Fragment>
            )
          }
        }}
      </Query>
    </div>
  )
}

CompanyDetail.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default withRouter(withApollo(CompanyDetail))
