import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/**
 * MessageList - Renders a table with the chat message details
 *
 * returns {Component}
 */
class MessageList extends Component {
  static propTypes = {
    messagesList: PropTypes.array.isRequired,
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    totalCount: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
  }

  render() {
    const { messagesList, currentPage, totalCount, totalPages } = this.props
    return (
      <>
        <Table bordered>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Date & Time</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {messagesList.map((message, index) => {
              return (
                <tr key={index}>
                  <td>
                    <a href={`/bossjob/user/${message.sendUserId}`}>
                      {message.sendUserId}
                    </a>
                  </td>
                  <td>
                    {formatDate(message.createdAt, 'DD/MM/YYYY HH:mm:ss')}
                  </td>
                  <td>{message.content}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <Pagination
          currentPage={Number(currentPage)}
          totalCount={totalCount}
          totalPages={totalPages}
          pageRange={4}
        />
      </>
    )
  }
}

export default MessageList
