import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_AGENCY_LIST } from '../../schemas/queries'

/* Components */
import AgencyList from '../../views/Agencies/AgencyList'
import Loading from 'shared/components/Loading'

/**
 * AgencyListContainer - container component to query Bosshunt agency list
 * and return AgencyList component when is done
 *
 * @returns {Component}
 *
 */
class AgencyListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const { search, fromDate, toDate } = queryString.parse(location.search)

    let variables = {
      page: this.state.page,
      query: search ? search : null,
      createdDate:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null
    }

    return (
      <Query query={GET_AGENCY_LIST} variables={variables}>
        {({ loading, data: { headhunterCompanyList } }) => {
          if (loading) {
            return <Loading />
          } else {
            return (
              <AgencyList
                agencyList={headhunterCompanyList.data.companies}
                currentPage={this.state.page}
                totalCompanies={headhunterCompanyList.data.totalNum}
                totalPages={headhunterCompanyList.data.totalPages}
                {...this.props}
              />
            )
          }
        }}
      </Query>
    )
  }
}

export default withRouter(AgencyListContainer)
