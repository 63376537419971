/*eslint-disable*/
import React, { Component } from 'react'

/* Apollo */
import { Query, compose, graphql } from 'react-apollo'
import { GET_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM } from '../../../schemas/queries'
import {
  UPDATE_LOCAL_AGENCY_DETAIL,
  ADD_AGENCY,
  // UPLOAD_AGENCY_LOGO,
  UPDATE_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM
} from '../../../schemas/mutations'

/* Components */
import Loading from 'shared/components/Loading'
import CroppingImageTool from 'shared/components/CroppingImageTool'
import AgencyInformationForm from 'modules/agencies/views/AgencyDetail/AgencySettings/AgencyInformationForm'

/**
 * AgencyBasicInfoContainer - container component to query and mutate
 * agency information and document and refresh the page when is done
 *
 * @returns {Component}
 *
 */
class AgencyBasicInfoContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isUpdating: false,
      updateHeadhunterCompanyError: ''
    }
  }

  /**
   *
   * @function addAgency - handle when user clicks on add button
   *
   * @param apolloClient
   */
  addAgency = async apolloClient => {
    const { addHeadhunterCompany, imageFile } = this.props
    this.setState({
      isUpdating: true
    })

    const { agencyDetailForNewForm } = apolloClient.readQuery({
      query: GET_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM
    })

    let response = await addHeadhunterCompany({
      variables: {
        addHeadhunterCompanyInput: {
          name: agencyDetailForNewForm.name,
          websiteUrl: agencyDetailForNewForm.website,
          address: agencyDetailForNewForm.address,
          facebookUrl: agencyDetailForNewForm.facebookUrl,
          contactNum: agencyDetailForNewForm.contactNum,
          email: agencyDetailForNewForm.email,
          poeaLicense: agencyDetailForNewForm.poeaLicense,
          doleLicense: agencyDetailForNewForm.doleLicense,
          locationId: agencyDetailForNewForm.location.id
        }
      }
    })

    if (
      response.data &&
      response.data.addHeadhunterCompany &&
      response.data.addHeadhunterCompany.ok &&
      response.data.addHeadhunterCompany.companyId
    ) {
      this.setState({
        isUpdating: false
      })
      window.location.href = `/bosshunt/agency/${
        response.data.addHeadhunterCompany.companyId
      }/information`
    }
  }

  renderExistingForm() {
    const { updateHeadhunterCompanyError } = this.state
    return (
      <React.Fragment>
        <AgencyInformationForm
          {...this.props}
          agencyId={this.props.match.params.id}
          handleChange={this.props.handleChangeForExistingForm}
          saveCompany={this.props.saveAgencyDetail}
          // isLoading={this.state.isUpdating}
          handleImageChange={this.props.handleImageChange}
          previewLogo={this.props.imagePreviewUrl}
          previewCover={this.props.coverPreviewUrl}
          options={this.state.options}
          updateHeadhunterCompanyError={updateHeadhunterCompanyError}
          submitType="update"
        />

        <CroppingImageTool
          isVisible={this.props.isCroppingVisible}
          width={this.props.uploadType === 'agencyCover' ? 400 : 250}
          height={this.props.uploadType === 'agencyCover' ? 79.07 : 250}
          handleCloseModal={this.props.handleCroppingVisibility}
          imageSrc={
            this.props.uploadType === 'logo'
              ? this.props.imagePreviewUrl
              : this.props.coverPreviewUrl
          }
          fileType={this.props.fileType}
          handleCroppingImage={this.props.handleCroppingImage}
        />
      </React.Fragment>
    )
  }

  renderNewForm() {
    const { uploadType } = this.state
    return (
      <Query query={GET_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM}>
        {({ loading }) => {
          if (loading) {
            return <Loading />
          }
          return (
            <React.Fragment>
              <AgencyInformationForm
                agencyId={this.props.match.params.id}
                // agencyDetail={agencyDetailForNewForm}
                handleChange={this.props.handleChangeForNewForm}
                addAgency={this.addAgency}
                isLoading={this.state.isUpdating}
                handleImageChange={this.props.handleImageChange}
                previewLogo={this.props.imagePreviewUrl}
                previewCover={this.props.coverPreviewUrl}
                submitType="add"
                errorMessage={this.props.errorMessage}
              />

              <CroppingImageTool
                isVisible={this.props.isCroppingVisible}
                width={this.props.uploadType === 'agencyCover' ? 400 : 250}
                height={this.props.uploadType === 'agencyCover' ? 79.07 : 250}
                handleCloseModal={this.props.handleCroppingVisibility}
                imageSrc={
                  this.props.uploadType === 'logo'
                    ? this.props.imagePreviewUrl
                    : this.props.coverPreviewUrl
                }
                fileType={this.props.fileType}
                handleCroppingImage={this.props.handleCroppingImage}
              />
            </React.Fragment>
          )
        }}
      </Query>
    )
  }

  render() {
    const agencyId = this.props.match.params.id
    if (agencyId === 'new') {
      return this.renderNewForm()
    } else {
      return this.renderExistingForm()
    }
  }
}

export default compose(
  graphql(UPDATE_LOCAL_AGENCY_DETAIL, {
    name: 'updateLocalAgencyDetail'
  }),
  graphql(UPDATE_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM, {
    name: 'updateLocalAgencyDetailForNewForm'
  }),
  graphql(ADD_AGENCY, {
    name: 'addHeadhunterCompany'
  })
  // graphql(UPLOAD_AGENCY_LOGO,{
  //   name: 'uploadHeadhunterCompanyLogo'
  // })
)(AgencyBasicInfoContainer)
