import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { withRouter } from 'react-router-dom'

/* Bootstrap */
import { Button } from 'reactstrap'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query, compose, graphql } from 'react-apollo'
import { GET_ALL_ROLES } from '../../schemas/queries'
import {
  DELETE_ROLE,
  ACTIVATE_ROLES,
  DEACTIVATE_ROLES
} from '../../schemas/mutations'

/* Components */
import RoleList from '../../views/Roles/RoleList'
import Loading from '../../../../shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import PopUpMessage from 'shared/components/PopUpMessage'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * RoleListContainer - container component to query role list
 * and return RoleList component when is done
 *
 * @returns {Component}
 *
 */
class RoleListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedRoles: [],
      isModalVisible: false,
      message: ''
    }

    this.appendSelectedRole = this.appendSelectedRole.bind(this)
    this.removeSelectedRole = this.removeSelectedRole.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.deactivate = this.deactivate.bind(this)
  }

  closeModal() {
    this.setState({
      isModalVisible: false,
      message: ''
    })
  }

  async onDelete(roleId) {
    try {
      let response = await this.props.deleteRole({
        variables: { roleId: roleId }
      })

      if (response) {
        if (response.data.deleteRole && response.data.deleteRole.ok) {
          window.location.reload()
        } else {
          console.log(response)
          this.setState({
            isModalVisible: true,
            message: response.data.deleteRole.message
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  async deactivate(roleId) {
    try {
      let response = await this.props.deactivateRole({
        variables: { ids: [roleId] }
      })

      if (response) {
        if (response.data.deactivateRole && response.data.deactivateRole.ok) {
          window.location.reload()
        } else {
          console.log(response)
          this.setState({
            isModalVisible: true,
            message: response.data.deactivateRole.message
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *
   * @function appendSelectedRole - Append roleId to selectedRoles state
   * @param {Number} roleId
   */
  appendSelectedRole(roleId) {
    this.setState({
      selectedRoles: [...this.state.selectedRoles, Number(roleId)]
    })
  }

  /**
   *
   * @function removeSelectedRole - Remove specific roleId from selectedRoles state
   * @param {Number} roleId
   */
  removeSelectedRole(roleId) {
    let selectedRoles = this.state.selectedRoles
    selectedRoles.splice(this.state.selectedRoles.indexOf(Number(roleId)), 1)
    this.setState({
      selectedRoles: selectedRoles
    })
  }

  updateRoleStatus() {
    const { activateRole } = this.props
    const { selectedRoles } = this.state

    activateRole({
      variables: {
        ids: selectedRoles
      }
    })
      .then(response => {
        const data = response.data
        if (data.activateRole && data.activateRole.ok) {
          window.location.reload()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    const { location } = this.props
    const { search, fromDate, toDate } = queryString.parse(location.search)

    let variables = {
      query: search ? search : null,
      date:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null
    }
    return (
      <Query query={GET_ALL_ROLES} variables={variables}>
        {({ loading, data }) => {
          const roles = data

          if (loading) {
            return <Loading />
          }

          if (roles && roles.allRoles) {
            return (
              <Can I="edit" a="admin_user" passThrough>
                {can => (
                  <SectionWrapper
                    sectionTitle="Roles"
                    isMainPage
                    addUrl={can ? '/roles/add' : null}
                    otherActions={
                      can ? (
                        <React.Fragment>
                          <Button
                            color="success"
                            onClick={() => this.updateRoleStatus(1)}
                            style={{ marginLeft: '10px' }}
                          >
                            Activate
                          </Button>
                        </React.Fragment>
                      ) : null
                    }
                  >
                    <RoleList
                      roleList={roles.allRoles}
                      appendSelectedRole={this.appendSelectedRole}
                      removeSelectedRole={this.removeSelectedRole}
                      handleDelete={this.onDelete}
                      handleDeactivate={this.deactivate}
                    />
                    <PopUpMessage
                      isVisible={this.state.isModalVisible}
                      message={this.state.message}
                      closeModal={this.closeModal}
                      error
                    />
                  </SectionWrapper>
                )}
              </Can>
            )
          }

          return null
        }}
      </Query>
    )
  }
}

RoleListContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,

  location: ReactRouterPropTypes.location.isRequired,

  deleteRole: PropTypes.func.isRequired,

  activateRole: PropTypes.func.isRequired,

  deactivateRole: PropTypes.func.isRequired
}

export default compose(
  graphql(ACTIVATE_ROLES, { name: 'activateRole' }),
  graphql(DEACTIVATE_ROLES, { name: 'deactivateRole' }),
  graphql(DELETE_ROLE, { name: 'deleteRole' })
)(withRouter(RoleListContainer))
