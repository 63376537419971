import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table, Input, Button } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Helpers */
import { formatDate } from 'shared/helpers/datetime'

/* Assets */
import Zoom from 'shared/assets/images/zoom-in.svg'

const NeptuneCompanyList = ({ list, currentPage, totalUsers, totalPages }) => {
  const handleChange = () => {}

  console.log(currentPage, 'currentPage')

  return (
    <React.Fragment>
      <Table bordered style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '40px' }} />
            <th style={{ width: '75px' }}>ID</th>
            <th>Country</th>
            <th>Name</th>
            <th>Legal Name</th>
            <th>Operating Status</th>
            <th>Company Type</th>
          </tr>
        </thead>
        <tbody>
          {list.map(({ neptuneCompany: user }) => (
            <tr key={user.id}>
              <td>
                <Input
                  addon
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  onChange={handleChange}
                  value={user.id}
                />
              </td>
              <td>
                <a href={`/bossjob/NeptuneCompany/${user.id}`}>{user.id}</a>
              </td>
              <td>{user.country.value}</td>
              <td>{user.name}</td>
              <td>{user.legalName}</td>
              <td>
                {user.companyFinancial &&
                  user.companyFinancial.operatingStatus &&
                  user.companyFinancial.operatingStatus.value}
              </td>
              <td>
                {user.companyBusinessInformation &&
                  user.companyBusinessInformation.companyType &&
                  user.companyBusinessInformation.companyType.value}
              </td>
              <td>
                <div style={{ textAlign: 'center' }}>
                  <a href={`/bossjob/NeptuneCompany/${user.id}`}>
                    <Button color="success">
                      <img src={Zoom} alt="Zoom" width="16" height="16" />
                    </Button>
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={Number(currentPage)}
        totalCount={totalUsers}
        totalPages={totalPages}
        pageRange={4}
      />
    </React.Fragment>
  )
}

NeptuneCompanyList.propTypes = {
  /* array of user list */
  list: PropTypes.array.isRequired,

  currentPage: PropTypes.number.isRequired,

  totalUsers: PropTypes.number.isRequired,

  totalPages: PropTypes.number.isRequired,

  appendSelectedUser: PropTypes.func.isRequired,

  removeSelectedUser: PropTypes.func.isRequired
}

export default NeptuneCompanyList
