import React from 'react'

import EventStatistics from './EventStatistics'
import Layout from 'shared/components/Layout'

export default () => {
  return (
    <Layout>
      <EventStatistics />
    </Layout>
  )
}
