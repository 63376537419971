import React from 'react'

/* Components */
import RoleListContainer from '../../containers/RoleListContainer'
import SearchBar from 'shared/components/SearchBar'

/**
 * Roles - main Role list view
 *
 */
const Roles = () => (
  <div>
    <SearchBar />
    <RoleListContainer />
  </div>
)

export default Roles
