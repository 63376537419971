import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_ALL_COMPANIES } from '../../schemas/queries'
import { GET_SUPPORTED_COUNTRY } from '../../../country/schemas/queries'
/* Components */
import CompanyList from '../../views/Companies/CompanyList'
import Loading from 'shared/components/Loading'

/**
 * CompanyListContainer - container component to query Bossjob company list
 * and return CompanyList component when is done
 *
 * @returns {Component}
 *
 */
class CompanyListContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      page: props.match.params.page
    }
  }

  render() {
    const {
      search,
      fromDate,
      toDate,
      status,
      authStatus,
      activeStatus,
      countryId,
      selectedSalesPerson,
      registeredCountryId,
      salesStatus
    } = queryString.parse(location.search)

    const authenticationStatuses = [
      'verified',
      'change_required',
      'pending',
      'rejected',
      'no_document_uploaded'
    ]

    let variables = {
      page: this.state.page,
      query: search ? search : null,
      date:
        fromDate && toDate
          ? {
            fromDate: fromDate,
            toDate: toDate
          }
          : null,
      // edit here to apply the new filters
      isCompanyPendingVerification: status
        ? status === 'companyPendingVerification'
        : null,
      authenticationStatus: authenticationStatuses.includes(authStatus)
        ? authStatus
        : null,
      activeStatus: activeStatus ? activeStatus : null,
      countryId: parseInt(countryId),
      salesPersonEmail: selectedSalesPerson ? selectedSalesPerson : null,
      registeredCountryId: registeredCountryId ? registeredCountryId : null,
      salesStatus: salesStatus ? salesStatus : null
    }

    return (
      <>
        <Query query={GET_SUPPORTED_COUNTRY}>
          {({ data, loading }) => {
            if (loading) {
              return null
            } else {
              const supportedCountries = data.getSupportedCountry.data
              return (
                <Query query={GET_ALL_COMPANIES} variables={variables}>
                  {({ loading, data }) => {
                    if (loading) {
                      return <Loading />
                    } else {
                      return (
                        <CompanyList
                          companyList={data.allCompanies.data.companies}
                          currentPage={Number(this.state.page)}
                          totalCompanies={data.allCompanies.data.totalCount}
                          totalPages={data.allCompanies.data.totalPages}
                          supportedCountries={supportedCountries}
                        />
                      )
                    }
                  }}
                </Query>
              )
            }
          }}
        </Query>
      </>
    )
  }
}

export default withRouter(CompanyListContainer)
