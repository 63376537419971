import gql from 'graphql-tag'

export const IMPORT_USERS = gql`
  mutation ImportUsers($file: Upload!) {
    importUsers(file: $file) {
      ok
      message
    }
  }
`

export const EXPORT_JOIN_COMPANY_EXCEL = gql`
  mutation ExportJoinCompanyExcel(
    $statusKey: String
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    exportJoinCompanyExcel(
      statusKey: $statusKey
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ok
      url
    }
  }
`
