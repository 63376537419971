import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter, Route, Redirect } from 'react-router-dom'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_BOSSJOB_USER_BY_ID } from '../../schemas/queries'

/* Components */
import Navigation from './Navigation'
import AccountSetting from './AccountSetting'
import UserProfile from './UserProfile'
import RecruiterInformation from './RecruiterInformation'
import Bosspoints from './Bosspoints'
import Chat from './Chat'
import Blacklists from './Blacklists'
import Loading from 'shared/components/Loading'
import OtpEmailLogs from './OtpEmailLogs'

/**
 * BossJobUserDetail - main wrapper component for Bossjob user details and match url to corresponding component
 *
 * @returns {Component}
 */
const BossJobUserDetail = ({ match }) => {
  return (
    <div>
      <Navigation />

      <Query
        query={GET_BOSSJOB_USER_BY_ID}
        variables={{ userId: match.params.id }}
      >
        {({ loading, data, client }) => {
          const userData = data
          if (loading) {
            return <Loading />
          }

          if (userData.bossjobUser && userData.bossjobUser.data) {
            const bossjobUserDetail = userData.bossjobUser.data

            client.writeData({
              data: { bossjobUserDetail: bossjobUserDetail }
            })

            return (
              <React.Fragment>
                <Route
                  exact
                  path={`${match.path}`}
                  component={() => <Redirect to={`${match.url}/account`} />}
                />
                <Route
                  exact
                  path="/bossjob/user/:id/account"
                  component={AccountSetting}
                />
                <Route
                  exact
                  path="/bossjob/user/:id/profile"
                  component={UserProfile}
                />
                <Route
                  path="/bossjob/user/:id/recruiter"
                  component={() => (
                    <RecruiterInformation
                      isCompanyAdmin={bossjobUserDetail.isCompanyAdmin}
                    />
                  )}
                />
                <Route
                  exact
                  path="/bossjob/user/:id/bosspoints/:page"
                  component={Bosspoints}
                />
                <Route
                  exact
                  path="/bossjob/user/:id/chat/:page"
                  component={Chat}
                />
                <Route
                  exact
                  path="/bossjob/user/:id/blacklist"
                  component={Blacklists}
                />
                <Route
                  exact
                  path="/bossjob/user/:id/otp-email-logs/:page"
                  component={OtpEmailLogs}
                />
              </React.Fragment>
            )
          }

          return null
        }}
      </Query>
    </div>
  )
}

BossJobUserDetail.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

export default withRouter(BossJobUserDetail)
