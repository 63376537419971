import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Table } from 'reactstrap'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_RECRUITER_JOB_STATISTICS } from '../../../../bossjob_users/schemas/queries'

/* Components */
import Loading from 'shared/components/Loading'
import useWindowDimensions from 'shared/hooks/useWindowDimensions'

const JobStat = ({ jobDetail }) => {
  const { width } = useWindowDimensions()
  const width_limit = 1900

  return (
    <Query
      query={GET_RECRUITER_JOB_STATISTICS}
      variables={{
        page: 1,
        userId: jobDetail.recruiterId,
        queryData: jobDetail.id
      }}
    >
      {({ loading, data: { recruiterJobsStat } }) => {
        const jobStat =
          recruiterJobsStat && recruiterJobsStat.data.jobStats
            ? recruiterJobsStat.data.jobStats[0]
            : null
        if (loading) {
          return <Loading />
        } else {
          return (
            <Table bordered>
              <thead>
                <tr>
                  <th>Job Title</th>
                  {width >= width_limit && <th>Status</th>}
                  <th>Activity Score</th>
                  <th>Total Views</th>
                  <th>Total Applications</th>
                  <th>Total Interviewed</th>
                  <th>Total Hired</th>
                  <th>Not Suitable (Interview)</th>
                  <th>Not Suitable (Application)</th>
                  <th>Initiated By Jobseeker</th>
                  <th>Initiated By Recruiter</th>
                  <th>Total In Progress</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{jobStat.jobTitle}</td>
                  {width >= width_limit && <td>{jobStat.statusKey}</td>}
                  <td>{jobStat.jobActivityScore}</td>
                  <td>{jobStat.totalViews}</td>
                  <td>{jobStat.totalApplications}</td>
                  <td>{jobStat.totalInterviewed}</td>
                  <td>{jobStat.totalHired}</td>
                  <td>{jobStat.totalNotSuitableInterview}</td>
                  <td>{jobStat.totalNotSuitableApplication}</td>
                  <td>{jobStat.initiatedByJobseeker}</td>
                  <td>{jobStat.initiatedByRecruiter}</td>
                  <td>{jobStat.totalInProgress}</td>
                </tr>
              </tbody>
            </Table>
          )
        }
      }}
    </Query>
  )
}

JobStat.propTypes = {
  jobDetail: PropTypes.object
}
export default JobStat
