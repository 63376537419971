import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Vendor */
import ReactQuill from 'react-quill'

class TextEditorField extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ReactQuill
        value={this.props.value}
        onChange={(newValue, delta, source, editor) => {
          if (source === 'user') {
            this.props.handleChange(newValue, editor.getText())
          }
        }}
        modules={TextEditorField.modules}
        readOnly={this.props.disabled}
      />
    )
  }
}

TextEditorField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
TextEditorField.modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
}

export default TextEditorField
