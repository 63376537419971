export default {
  transactionDetail: {
    __typename: 'TransactionType',
    data: {}
  },
  transactionDetailForNewForm: {
    __typename: 'AddTransaction',
    typeId: 2,
    type: 'Offline',
    jobPost: null,
    unlockTalentCredit: null,
    rhTalentCredit: null,
    featureJobCredit: null,
    urgentJobCredit: null,
    bosspoint: null,
    isServiceGuaranteed: false,
    isOnlineSupported: false,
    amount: null,
    totalAmount: null,
    currencyAmount: null,
    itemName: null,
    itemDescription: null,
    paymentKey: null,
    creditTerm: 0,
    taxType: 'gst',
    taxRate: 0,
    currency: 'php',
    currencyExchangeRate: 1,
    salesPersonEmail: null,
    billerEmail: null,
    billerFirstName: null,
    billerLastName: null,
    billerAddress: null,
    billerCompany: null,
    billerCity: null,
    billerState: null,
    billerZipcode: null,
    billerCountry: 'ph',
    remark: null,
    paymentStatus: 'Pending',
    statusId: 1,
    bankAccountId: 3,
    paymentMethodId: 1
  }
  // invoiceFile: {
  //   __typename: 'InvoiceFileType',
  //   data: {}
  // }
}
