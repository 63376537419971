import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Col, Card, CardTitle, CardBody, Button } from 'reactstrap'

import ReferralCode from '../../../modules/companies/views/Companies/ReferralCode'

/* Assets */
import styles from 'shared/assets/scss/main.module.scss'

const SectionWrapper = ({
  children,
  style,
  sectionTitle,
  isMainPage,
  handleAdd,
  handleDelete,
  // handleDeactivate,
  addUrl,
  otherActions,
  isShowReferralLink
}) => {
  return (
    <div style={style}>
      <Col md="12">
        <Card>
          <CardTitle
            className={isMainPage ? styles.pageTitle : styles.sectionTitle}
          >
            {sectionTitle}
            <div>
              <div style={{ display: 'flex' }}>
                {handleAdd && (
                  <div style={{ paddingRight: '1vw' }}>
                    <Button color="info" onClick={() => handleAdd()}>
                      Add
                    </Button>
                  </div>
                )}
                {addUrl && (
                  <div style={{ paddingRight: '1vw' }}>
                    <Button
                      color="info"
                      onClick={() => (window.location.href = addUrl)}
                    >
                      Add
                    </Button>
                  </div>
                )}
                {/* {handleDeactivate && (
                  <div style={{ paddingRight: '1vw' }}>
                    <Button color="danger" onClick={() => handleDeactivate()}>
                      Deactivate
                    </Button>
                  </div>
                )} */}
                {handleDelete && (
                  <Button color="danger" onClick={() => handleDelete()}>
                    Delete
                  </Button>
                )}

                {isShowReferralLink && <ReferralCode isMy={true} />}

                {otherActions}
              </div>
            </div>
          </CardTitle>
          <CardBody>{children}</CardBody>
        </Card>
      </Col>
    </div>
  )
}

SectionWrapper.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  style: PropTypes.object,
  isMainPage: PropTypes.bool,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDeactivate: PropTypes.func,
  addUrl: PropTypes.string,
  otherActions: PropTypes.object,
  isShowReferralLink: PropTypes.bool
}

export default SectionWrapper
