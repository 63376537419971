export default {
  bosshuntUserDetail: {
    __typename: 'HeadhunterType',
    data: {
      feeStructure: {
        isContingentFee: false,
        isRetainedFee: false,
        isOtherFee: false,
        annualGrossSalaryFrom: null,
        annualGrossSalaryTo: null,
        monthlyFeeFrom: null,
        monthlyFeeTo: null,
        otherFee: null,
        __typename: 'HeadhunterFeeStructureType'
      },
      turnaroundTime: {
        turnaroundTimeId: 1,
        fromPeriod: null,
        toPeriod: null,
        __typename: 'HeadhunterTurnaroundTimeType'
      },
      guaranteePeriod: {
        guaranteePeriodId: 1,
        fromPeriod: null,
        toPeriod: null,
        __typename: 'HeadhunterGuaranteePeriodType'
      },
      freeReplacement: {
        maxFreeReplacement: null,
        __typename: 'HeadhunterFreeReplacementType'
      }
    }
  },
  bosshuntUserDetailForNewForm: {
    __typename: 'RegisterBosshuntUser',
    firstName: null,
    lastName: null,
    email: null,
    contactNum: null,
    isActive: true,
    avatar: null,
    company: {
      id: null,
      name: null,
      __typename: 'CompanyType'
    }
  }
}
