import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { graphql, compose } from 'react-apollo'
import { EXPORT_JOBS_EXCEL } from '../../schemas/mutations'

/* Components */
import { Button } from 'reactstrap'
import ConfirmationAlert from 'shared/components/ConfirmationAlert'

/**
 * CloseJobButton - Apollo Component to export all jobs
 *
 * @returns {Component}
 */
class ExportJobButton extends Component {
  static propTypes = {
    exportPayload: PropTypes.object,

    exportJobsExcel: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { isVisible: false }
  }

  render() {
    const isVisible = this.state.isVisible
    const { exportPayload, exportJobsExcel } = this.props

    const exportJob = async () => {
      exportJobsExcel({
        variables: exportPayload
      }).then(response => {
        if (response.data && response.data.exportJobsExcel.message) {
          alert(response.data.exportJobsExcel.message)
        }
      })

      this.setState({ isVisible: false })
    }

    return (
      <React.Fragment>
        <Button
          color="success"
          onClick={() => this.setState({ isVisible: true })}
        >
          Export Jobs
        </Button>
        <ConfirmationAlert
          isVisible={isVisible}
          title="Export all jobs"
          body="Are you sure you want to export all jobs with the selected filters?"
          onConfirm={exportJob}
          onClose={() => this.setState({ isVisible: false })}
        />
      </React.Fragment>
    )
  }
}

export default compose(
  graphql(EXPORT_JOBS_EXCEL, {
    name: 'exportJobsExcel'
  })
)(ExportJobButton)
