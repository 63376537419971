import React, { Component } from 'react'

/* Apollo */
import { Query } from 'react-apollo'
import {
  GET_USER_WORK_XPS,
  GET_LOCAL_BOSSJOB_USER_DETAIL
} from '../../../../schemas/queries'
import {
  DELETE_BOSSJOB_USER_WORK_XPS,
  EDIT_BOSSJOB_USER_WORK_XPS,
  ADD_BOSSJOB_USER_WORK_XPS
} from '../../../../schemas/mutations'

import CONFIG from 'config'

/* Bootstrap */
import { Col, Card, CardTitle, CardBody, Alert } from 'reactstrap'

/* Components */
import WorkExperienceForm from './WorkExperienceForm'
import Loading from 'shared/components/Loading'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/* Assets */
import styles from 'shared/assets/scss/main.module.scss'

/**
 * WorkExperiences - main wrapper component for the Profile page - Work Experience section
 *
 * @returns {Component}
 */
class WorkExperiences extends Component {
  constructor(props) {
    super(props)

    this.state = {
      messageObj: {
        isVisible: false,
        error: false,
        message: ''
      },
      refetch: null,
      addingWorkXps: false,
      options: null,
      isFetchingOption: false,
      jobFunctions: null
    }

    this.handleMessage = this.handleMessage.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.cancelAdding = this.cancelAdding.bind(this)
  }

  componentDidMount() {
    this._fetchFieldOptions()
  }

  _fetchFieldOptions() {
    this.setState({
      isFetchingOption: true
    })

    fetch(`${CONFIG.BOSSJOB_CONFIG_URL}/ph/list`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          configList: data.data
        })
      })
      .finally(() => {
        this.setState({
          isFetchingOption: false
        })
      })
  }

  /**
   *
   * @function renderExperienceForm - render the work experience form with value for each work experience
   *
   * @param {Array} experiences
   * @param {String|number} userId
   * @param {String} userStatusKey
   *
   */
  renderExperienceForm(experiences, userId, userStatusKey, configList) {
    return experiences.map(experience => (
      <WorkExperienceForm
        workXps={experience}
        userId={Number(userId)}
        key={`experience-${experience.id}`}
        deleteSuccess={this.handleDelete}
        editSuccess={this.handleEdit}
        isInactive={userStatusKey === 'inactive'}
        configList={configList}
      />
    ))
  }

  /**
   *
   * @function handleDelete - asynchronous delete function to call GraphQL mutation
   * which will refetch and show success message after delete is done
   *
   * @param {*} apolloClient
   * @param {String|number} userId
   * @param {String|number} educationId
   */
  async handleDelete(apolloClient, userId, experienceId) {
    let response = await apolloClient.mutate({
      mutation: DELETE_BOSSJOB_USER_WORK_XPS,
      variables: {
        userId: userId,
        experienceId: experienceId
      }
    })

    if (response) {
      this.state.refetch()
      this.handleMessage(
        'You have successfully deleted the selected work experience!'
      )
    }
  }

  /**
   *
   * @function handleEdit - asynchronous edit function to call GraphQL mutation
   * which will refetch and show success message after edit is done
   *
   * @param {*} apolloClient
   * @param {String|number} userId
   * @param {Object} payload
   */
  async handleEdit(apolloClient, userId, payload) {
    let response = await apolloClient.mutate({
      mutation: EDIT_BOSSJOB_USER_WORK_XPS,
      variables: {
        userId: userId,
        payload: payload
      }
    })

    if (response) {
      this.state.refetch()
      this.handleMessage('You have successfully edited the work experience!')
    }
  }

  /**
   *
   * @function handleAdd - asynchronous add function to call GraphQL mutation
   * which will refetch and show success message after add is done
   *
   * @param {*} apolloClient
   * @param {String|number} userId
   * @param {Object} payload
   */
  async handleAdd(apolloClient, userId, payload) {
    let response = await apolloClient.mutate({
      mutation: ADD_BOSSJOB_USER_WORK_XPS,
      variables: {
        userId: userId,
        payload: payload
      }
    })

    if (response) {
      this.cancelAdding()
      this.state.refetch()
      this.handleMessage(
        'You have successfully added a new work experience for the user!'
      )
    }
  }

  /**
   *
   * @function cancelAdding - to remove the empty form
   *
   */
  cancelAdding() {
    this.setState({ addingWorkXps: false })
  }

  /**
   *
   * @function handleMessage - setting message
   *
   * @param {String} message
   * @param {Boolean} isError
   *
   */
  handleMessage(message, isError = false) {
    this.setState({
      messageObj: {
        isVisible: true,
        error: isError,
        message: message
      }
    })

    setTimeout(
      () =>
        this.setState({
          messageObj: {
            isVisible: false,
            error: false,
            message: ''
          }
        }),
      1700
    )
  }

  render() {
    const { isFetchingOption, configList } = this.state

    return (
      <div>
        <Col md="12">
          <Query query={GET_LOCAL_BOSSJOB_USER_DETAIL}>
            {({ data: { bossjobUserDetail } }) => (
              <Card>
                <CardTitle className={styles.sectionTitle}>
                  Work Experiences
                  {bossjobUserDetail.statusKey !== 'inactive' && (
                    <Can I="edit" a="jobseeker_workxps">
                      <div
                        className={styles.addButton}
                        onClick={() => this.setState({ addingWorkXps: true })}
                      >
                        +
                      </div>
                    </Can>
                  )}
                </CardTitle>
                <CardBody>
                  {this.state.addingWorkXps &&
                    !isFetchingOption && (
                    <WorkExperienceForm
                      userId={Number(bossjobUserDetail.id)}
                      addWorkExperience={this.handleAdd}
                      deleteSuccess={this.cancelAdding}
                      configList={configList}
                      new
                    />
                  )}
                  <Query
                    query={GET_USER_WORK_XPS}
                    variables={{ userId: bossjobUserDetail.id }}
                    fetchPolicy="network-only"
                  >
                    {({ loading, data, error, refetch }) => {
                      if (this.state.refetch === null) {
                        this.setState({ refetch: refetch })
                      }

                      if (error) {
                        console.log(error)
                      }
                      if (loading) {
                        return <Loading />
                      }

                      if (data && data.workXps) {
                        if (data.workXps.data && !isFetchingOption) {
                          return this.renderExperienceForm(
                            data.workXps.data,
                            Number(bossjobUserDetail.id),
                            bossjobUserDetail.statusKey,
                            configList
                          )
                        }

                        return (
                          <div>There is no work experiences for this user</div>
                        )
                      }

                      return null
                    }}
                  </Query>
                </CardBody>
              </Card>
            )}
          </Query>
          {this.state.messageObj.isVisible && (
            <Alert color={this.state.messageObj.error ? 'danger' : 'success'}>
              {this.state.messageObj.message}
            </Alert>
          )}
        </Col>
      </div>
    )
  }
}

export default WorkExperiences
