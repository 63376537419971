/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
// import {
//   GET_TRANSACTION_BY_ID,
//   GET_BOSSJOB_USER_BY_ID,
//   GET_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM
// } from '../../schemas/queries'
// import {
//   UPDATE_LOCAL_TRANSACTION_DETAILS,
//   UPDATE_LOCAL_TRANSACTION_DETAILS_FOR_NEW_FORM
// } from '../../schemas/mutations'

/* Components */
import ToggleButton from 'shared/components/ToggleButton'

// import DetailForm from '../../views/TransactionDetail/Forms/DetailForm'
// import BillingAddressForm from '../../views/TransactionDetail/Forms/BillingAddressForm'
// import SubmitForm from '../../views/TransactionDetail/Forms/SubmitForm'
// import Loading from 'shared/components/Loading'
// import Attachments from '../../views/TransactionDetail/Attachments'

/**
 * MyResumeDetail
 *
 * @returns {Component}
 */
class MyResumeDetail extends Component {
  // static propTypes = {
  //   match: ReactRouterPropTypes.match.isRequired,
  //   updateLocalTransactionDetails: PropTypes.func.isRequired,
  //   updateLocalTransactionDetailsForNewForm: PropTypes.func.isRequired
  // }

  constructor(props) {
    super(props)
    this.state = {}
  }

  renderStatusField = () => {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Status</Label>
        <Input
          type="select"
          name="status"
          // value={}
          // onChange={}
        >
          <option>Active</option>
          <option>Inactive</option>
        </Input>
      </FormGroup>
    )
  }

  renderFirstNameField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>First Name</Label>
        <Input
          type="text"
          name="firstName"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }

  renderLastNameField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Last Name</Label>
        <Input
          type="text"
          name="lastName"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderCurrentLocationField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Current Location</Label>
        <Input
          type="text"
          name="currentLocation"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderAgeField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Age</Label>
        <Input
          type="text"
          name="age"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderGenderField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Gender</Label>
        <Input
          type="text"
          name="gender"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderEmailField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Email</Label>
        <Input
          type="text"
          name="email"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderMobileNumberField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Mobile Number</Label>
        <Input
          type="text"
          name="mobileNumber"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderLastDrawnSalaryField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Last Drawn Salary</Label>
        <Input
          type="text"
          name="lastDrawnSalary"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderExpectedSalaryField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Expected Salary</Label>
        <Input
          type="text"
          name="expectedSalary"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }

  renderNoticePeriodField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Notice Period</Label>
        <Input
          type="text"
          name="noticePeriod"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }

  renderExperienceField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Years of Experience</Label>
        <Input
          type="text"
          name="experience"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderAttachmentField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Attachments</Label>
        <Input
          type="text"
          name="Attachments"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }

  renderJobTitleField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Job Title</Label>
        <Input
          type="text"
          name="jobTitle"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }

  renderCompanyNameField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Company Name</Label>
        <Input
          type="text"
          name="companyName"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderStartDateField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={3}>Start</Label>
        <Input
          type="text"
          name="start"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderEndDateField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={3}>To</Label>
        <Input
          type="text"
          name="to"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }
  renderPresentToggle() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Present</Label>
        <ToggleButton
          name="isPresent"
          // value={}
          // isChecked={}
          // onChange={}
        />
      </FormGroup>
    )
  }

  renderDescriptionField() {
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        <Label md={2}>Description</Label>
        <Input
          type="textarea"
          name="description"
          // onChange={}
          // value={}
        />
      </FormGroup>
    )
  }

  render() {
    return (
      <Form>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderStatusField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderFirstNameField()}</Col>
          <Col md={6}>{this.renderLastNameField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderCurrentLocationField()}</Col>
          <Col md={6}>{this.renderAgeField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderGenderField()}</Col>
          <Col md={6}>{this.renderEmailField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderMobileNumberField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderLastDrawnSalaryField()}</Col>
          <Col md={6}>{this.renderExpectedSalaryField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderNoticePeriodField()}</Col>
          <Col md={6}>{this.renderExperienceField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderAttachmentField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={6}>{this.renderJobTitleField()}</Col>
          <Col md={6}>{this.renderCompanyNameField()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={5}>{this.renderStartDateField()}</Col>
          <Col md={5}>{this.renderEndDateField()}</Col>
          <Col md={2}>{this.renderPresentToggle()}</Col>
        </Row>
        <Row style={{ marginRight: 0 }}>
          <Col md={10}>{this.renderDescriptionField()}</Col>
        </Row>
      </Form>
    )
  }
}

export default MyResumeDetail
