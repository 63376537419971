import gql from 'graphql-tag'

/* Bosshunt agency */
export const GET_LOCAL_AGENCY_DETAIL = gql`
  {
    agencyDetail @client {
      id
      name
      logo
      cover
      websiteUrl
      description
      descriptionHtml
      address
      contactNum
      email
      poeaLicense
      doleLicense
      foundingYear
      companySize {
        id
        name
      }
      isActive
      isFeatured
      location {
        id
        name
      }
      services {
        id
        name
      }
      industries {
        id
        name
      }
      serviceLocations {
        id
        name
      }
      feeStructure {
        isContingentFee
        isRetainedFee
        isOtherFee
        annualGrossSalaryFrom
        annualGrossSalaryTo
        monthlyFeeFrom
        monthlyFeeTo
        otherFee
      }
      freeReplacement {
        maxFreeReplacement
      }
      turnaroundTime {
        turnaroundTimeId
        fromPeriod
        toPeriod
      }
      guaranteePeriod {
        guaranteePeriodId
        fromPeriod
        toPeriod
      }
    }
  }
`
export const GET_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM = gql`
  {
    agencyDetailForNewForm @client {
      name
      logo
      cover
      websiteUrl
      description
      descriptionHtml
      address
      contactNum
      email
      poeaLicense
      doleLicense
      foundingYear
      companySize {
        id
        name
      }
      isActive
      isFeatured
      location {
        id
        name
      }
    }
  }
`

/** Get list of agencies */
export const GET_AGENCY_LIST = gql`
  query getAgencyList(
    $size: Int
    $page: Int
    $query: String
    $createdDate: HeadhunterCompanyDateTimeRange
  ) {
    headhunterCompanyList(
      size: $size
      page: $page
      query: $query
      createdDate: $createdDate
    ) {
      data {
        totalPages
        totalNum
        currentPage
        currentNum
        companies {
          id
          name
          logo
          cover
          websiteUrl
          description
          descriptionHtml
          address
          contactNum
          email
          poeaLicense
          doleLicense
          foundingYear
          companySize {
            id
            name
          }
          isActive
          isFeatured
          location {
            id
            name
          }
          services {
            id
            name
          }
          industries {
            id
            name
          }
          serviceLocations {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`

/** Get agency by ID */
export const GET_AGENCY_BY_ID = gql`
  query getAgencyById($companyId: Int!) {
    headhunterCompany(companyId: $companyId) {
      data {
        id
        name
        logo
        cover
        websiteUrl
        description
        descriptionHtml
        address
        contactNum
        email
        poeaLicense
        doleLicense
        foundingYear
        companySize {
          id
          name
        }
        isActive
        isFeatured
        location {
          id
          name
        }
        services {
          id
          name
        }
        industries {
          id
          name
        }
        serviceLocations {
          id
          name
        }
        feeStructure {
          isContingentFee
          isRetainedFee
          isOtherFee
          annualGrossSalaryFrom
          annualGrossSalaryTo
          monthlyFeeFrom
          monthlyFeeTo
          otherFee
        }
        freeReplacement {
          maxFreeReplacement
        }
        turnaroundTime {
          turnaroundTimeId
          fromPeriod
          toPeriod
        }
        guaranteePeriod {
          guaranteePeriodId
          fromPeriod
          toPeriod
        }
        createdAt
        updatedAt
      }
    }
  }
`

/* get list of headhunters under the agency */

export const GET_HEADHUNTERS_UNDER_AGENCY = gql`
  query getHeadhuntersUnderAgency($page: Int, $companyId: Int) {
    headhunterList(page: $page, companyId: $companyId) {
      data {
        headhunters {
          id
          email
          contactNum
          firstName
          lastName
          isActive
          createdAt
          jobTitle
        }
        totalPages
        totalNum
        currentPage
        currentNum
      }
    }
  }
`
