import React, { Component } from 'react'

/* Bootstrap */
import { Table, Button, Input } from 'reactstrap'

/* Components */
import Pagination from 'shared/components/Pagination'

/* Vendors */
import FontAwesome from 'react-fontawesome'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'
import { formatDate } from 'shared/helpers/datetime'

import styles from './Main.module.scss'

/* Helpers */
const langMap = [
  ['en', 'English'],
  ['zh-CN', 'Chinese (Simplified)']
  // ['zh-TW', 'Chinese (Traditional)'],
  // ['ja', 'Japanese'],
  // ['id', 'Indonesian'],
  // ['ms', 'Malay'],
  // ['tr', 'Turkish']
]

function formateLangList(langList) {
  if (!langList) return []
  const res = []
  langMap.forEach(item => {
    const find = langList.find(i => i.languageCode === item[0])
    if (find) {
      res.push(find)
    } else {
      res.push({
        languageCode: item[0],
        value: ''
      })
    }
  })

  return res
}

/**
 * list table
 *
 */
class MaintenanceTable extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { list, currentPage, totalPages, totalCount } = this.props
    return (
      <React.Fragment>
        <Table bordered hover responsive>
          <thead className="thead-light text-center text-nowrap">
            <tr scope="row">
              <th>ID</th>
              <th>Effective time</th>
              {langMap.map((item, index) => {
                return <th key={index}>{item[1]}</th>
              })}
              <th>Update Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {list.map(item => (
              <tr key={`admin-${item.submitUuid}`}>
                <td width="8%" className={styles.tableTd}>
                  {item.submitUuid}
                </td>
                <td width="12%" className={styles.tableTd}>
                  <div>{formatDate(item.startedAt)}</div>
                  <div>~</div>
                  <div>{formatDate(item.endedAt)}</div>
                </td>
                {formateLangList(item.contentList).map((item, index) => {
                  return (
                    <td width="30%" className={styles.tableTd} key={index}>
                      {item.value || '-'}
                    </td>
                  )
                })}
                <td width="12%" className={styles.tableTd}>
                  {formatDate(item.updatedAt)}
                </td>
                <td width="8%" className={styles.tableTd}>
                  <Can I="edit" a="website_outage_settings">
                    <a href={`/maintenance/edit/${item.submitUuid}`}>
                      <Button color="info">
                        <FontAwesome name="edit" />
                      </Button>
                    </a>
                  </Can>
                </td>
              </tr>
            ))}
            {list.length === 0 && (
              <tr>
                <td colSpan="6">no Data</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          className="justify-content-center text-center text-nowrap d-flex align-items-center"
          currentPage={Number(currentPage)}
          totalCount={Number(totalCount)}
          totalPages={Number(totalPages)}
          pageRange={4}
        />
      </React.Fragment>
    )
  }
}

export default MaintenanceTable
