exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formWrapper__2Kpg2 {\n  border-bottom: 1px solid #d8d8d8;\n  margin-bottom: 30px;\n  padding-bottom: 20px;\n}\n.formWrapper__2Kpg2:last-child {\n  margin-bottom: 0;\n  border-bottom: none;\n}", "", {"version":3,"sources":["/app/src/modules/bossjob_users/views/BossJobUserDetail/UserProfile/JobPreference/JobPreferenceForm/JobPreferenceForm.module.scss"],"names":[],"mappings":"AAAA;EACE,iCAAgC;EAChC,oBAAmB;EACnB,qBAAoB;CACtB;AACA;EACE,iBAAgB;EAChB,oBAAmB;CACrB","file":"JobPreferenceForm.module.scss","sourcesContent":[".formWrapper {\n  border-bottom: 1px solid #d8d8d8;\n  margin-bottom: 30px;\n  padding-bottom: 20px;\n}\n.formWrapper:last-child {\n  margin-bottom: 0;\n  border-bottom: none;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formWrapper": "formWrapper__2Kpg2"
};