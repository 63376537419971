exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formWrapper__1gqVW {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n}", "", {"version":3,"sources":["/app/src/modules/marketing/views/LeftSideBanner/LeftSideBannerContainer/LeftSideBannerContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,qBAAa;EAAb,cAAa;EACb,+BAAmB;EAAnB,8BAAmB;MAAnB,wBAAmB;UAAnB,oBAAmB;EACnB,oBAAe;MAAf,gBAAe;EACf,wBAA2B;MAA3B,qBAA2B;UAA3B,4BAA2B;CAC7B","file":"LeftSideBannerContainer.module.scss","sourcesContent":[".formWrapper {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formWrapper": "formWrapper__1gqVW"
};