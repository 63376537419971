import React from 'react'
import { withRouter } from 'react-router-dom'

import { Query } from 'react-apollo'
import Loading from 'shared/components/Loading'
import CompanySalesPersonLogsList from '../../views/CompanyDetail/CompanyInternals/CompanySalesPersonLogsList'

import { GET_COMPANY_SALES_PERSON_LOGS } from '../../schemas/queries'
const CompanySalesPersonLogsContainer = ({ match, companyId, adminList }) => {
  return (
    <>
      <Query
        query={GET_COMPANY_SALES_PERSON_LOGS}
        variables={{
          companyId: companyId,
          page: match.params.page
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return <Loading />
          } else {
            let currentPage = data.getCompanySalesPersonLogs.data.currentPage
            let totalPages = data.getCompanySalesPersonLogs.data.totalPages
            let totalCount = data.getCompanySalesPersonLogs.data.totalCount
            return (
              <>
                <CompanySalesPersonLogsList
                  adminList={adminList}
                  data={data}
                  currentPage={Number(currentPage)}
                  totalPages={totalPages}
                  totalCount={totalCount}
                />
              </>
            )
          }
        }}
      </Query>
    </>
  )
}

export default withRouter(CompanySalesPersonLogsContainer)
