import React from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { ApolloConsumer } from 'react-apollo'

/* Bootstrap */
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

/* Component */
import ImageField from 'shared/components/ImageField'

/* Helpers */
import { getCountries } from 'shared/helpers/locations'
import { getConfigList } from '../../../../../../shared/helpers/configList'
import { getNewConfigList } from '../../../../../../shared/helpers/newConfigList'
/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'
import { useState, useEffect } from 'react'

const INTERNATIONAL_LABEL_OTHER = 'Unique Enterprise Identifier'

const INTERNATIONAL_LABEL = {
  // 193: 'UEN',
  sg: 'UEN',

  // 167: 'TIN',
  ph: 'TIN',

  // 105: '法人番号',
  jp: '法人番号'
}

/**
 * CompanyInformationForm - form component for Company page - Information section
 *
 * @returns {Component}
 */
const CompanyInformationForm = ({
  canEdit,
  companyData: {
    countryKey,
    name,
    legalName,
    industryKey,
    companySizeKey,
    registeredCountryId,
    website,
    phoneNum,
    logo,
    coverPicUrl,
    financingStageId,
    companyCountryNumber = {}
  },
  handleChange,
  saveCompany,
  isLoading,
  handleImageChange,
  previewLogo,
  previewCover,
  options,
  updateCompanyError
}) => {
  const [financingStages, setFinancingStages] = useState([])
  const [countryOptions, setCountryOptions] = useState({})

  const { companyNumber = null } = companyCountryNumber || {}

  useEffect(async () => {
    const updateLocation = async () => {
      const data = await getConfigList()
      const { company_financing_stage_lists } = data.inputs
      setFinancingStages(company_financing_stage_lists)
    }
    updateLocation()
  }, [])

  useEffect(() => {
    async function updateOptions() {
      const data = await getNewConfigList()
      setCountryOptions({
        country_lists: data.country_lists
      })
    }

    updateOptions()
  }, [])

  return (
    <ApolloConsumer>
      {client => (
        <Form>
          <div style={{ textAlign: 'center', marginBottom: '2em' }}>
            <Can I="edit" a="companies" passThrough>
              {can => (
                <ImageField
                  hasPermission={can}
                  name="companyLogo"
                  imageSrc={previewLogo !== '' ? previewLogo : logo}
                  handleChange={handleImageChange}
                />
              )}
            </Can>
            <span style={{ display: 'block' }}>Max 1 MB</span>
          </div>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Company Display Name
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="companyName"
                    value={name}
                    onChange={e => handleChange(client, 'name', e.target.value)}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Company Legal Name
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="companyLegalName"
                    value={legalName}
                    onChange={e =>
                      handleChange(client, 'legalName', e.target.value)
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Country
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    type="select"
                    name="country"
                    value={countryKey}
                    onChange={e =>
                      handleChange(client, 'countryKey', e.target.value)
                    }
                  >
                    <option value={''} disabled={countryKey != ''}>
                      {' '}
                      Not selected{' '}
                    </option>
                    {getCountries().map((country, index) => (
                      <option key={`country-${index}`} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Financing Stage
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    type="select"
                    name="financingStage"
                    value={
                      financingStageId ? financingStageId.toString() : null
                    }
                    onChange={e =>
                      handleChange(client, 'financingStageId', e.target.value)
                    }
                  >
                    <option value={null} disabled={financingStageId != null}>
                      {' '}
                      Not selected{' '}
                    </option>
                    {financingStages.map((financingStage, index) => (
                      <option
                        key={`financingStage-${index}`}
                        value={financingStage.id}
                      >
                        {financingStage.value}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Registered Country
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    type="select"
                    name="registeredCountryId"
                    value={registeredCountryId}
                    onChange={e =>
                      handleChange(
                        client,
                        'registeredCountryId',
                        e.target.value
                      )
                    }
                  >
                    {countryOptions.country_lists &&
                      countryOptions.country_lists.map((country, index) => {
                        if (country.is_bossjob_supported == true) {
                          return (
                            <option key={`country-${index}`} value={country.id}>
                              {country.value}
                            </option>
                          )
                        }
                      })}
                  </Input>
                </Col>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Industry
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    type="select"
                    name="industry"
                    value={industryKey}
                    onChange={e =>
                      handleChange(client, 'industryKey', e.target.value)
                    }
                  >
                    <option value={''} disabled={industryKey != ''}>
                      {' '}
                      Not selected{' '}
                    </option>
                    {options.industry_lists.map((industry, index) => (
                      <option key={`industry-${index}`} value={industry.key}>
                        {industry.value}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Company Size
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    type="select"
                    name="companySize"
                    value={companySizeKey}
                    onChange={e =>
                      handleChange(client, 'companySizeKey', e.target.value)
                    }
                  >
                    <option value={''} disabled={companySizeKey != ''}>
                      {' '}
                      Not selected{' '}
                    </option>
                    {options.company_sizes.map((size, index) => (
                      <option key={`size-${index}`} value={size.key}>
                        {size.value}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Contact
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="phoneNum"
                    value={phoneNum ? phoneNum : ''}
                    onChange={e =>
                      handleChange(client, 'phoneNum', e.target.value)
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Website
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="website"
                    value={website ? website : ''}
                    onChange={e =>
                      handleChange(client, 'website', e.target.value)
                    }
                  />
                </Col>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  {INTERNATIONAL_LABEL[countryKey] || INTERNATIONAL_LABEL_OTHER}
                  :
                </Label>
                <Col md={9} sm={12}>
                  <Input
                    name="companyNumber"
                    value={companyNumber ? companyNumber : ''}
                    onChange={e =>
                      handleChange(client, 'companyCountryNumber', {
                        ...companyCountryNumber,
                        companyNumber: e.target.value
                      })
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} sm={12}>
                  Cover Picture{' '}
                  <span style={{ display: 'block' }}>(Max 1 MB)</span>
                </Label>
                <div style={{ padding: '15px' }}>
                  <Can I="edit" a="companies" passThrough>
                    {can => (
                      <ImageField
                        hasPermission={can}
                        name="companyCover"
                        imageSrc={
                          previewCover !== '' ? previewCover : coverPicUrl
                        }
                        handleChange={handleImageChange}
                      />
                    )}
                  </Can>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label style={{ color: 'red' }} md={12} sm={12}>
                {updateCompanyError}
              </Label>
            </Col>
          </Row>
          <Can I="edit" a="companies">
            <div style={{ textAlign: 'right' }}>
              <Button
                color="info"
                onClick={() => saveCompany(client)}
                disabled={isLoading || !canEdit}
              >
                Update
              </Button>
            </div>
          </Can>
        </Form>
      )}
    </ApolloConsumer>
  )
}

CompanyInformationForm.propTypes = {
  companyData: PropTypes.object,
  handleChange: PropTypes.func,
  saveCompany: PropTypes.func,
  isLoading: PropTypes.bool,
  handleImageChange: PropTypes.func,
  previewLogo: PropTypes.string,
  previewCover: PropTypes.string,
  options: PropTypes.object,
  updateCompanyError: PropTypes.string,
  canEdit: PropTypes.bool
}

export default CompanyInformationForm
