import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Button } from 'reactstrap'

/* Component */
import PopUpMessage from 'shared/components/PopUpMessage'

import styles from './FileUploadField.module.scss'

class FileUploadField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fileError: false,
      fileErrorMsg: ''
    }

    this.closeModal = this.closeModal.bind(this)
  }

  isFileValid(file) {
    let maxFileSize = 5
    if (this.props.fileId === 'company-admin-file') maxFileSize = 10
    let result = file.size <= maxFileSize * 1024 * 1024
    this.setState({
      fileErrorMsg:
        'Your file size is more than ' +
        maxFileSize +
        'MB. Please select a new file.'
    })
    return result
  }

  closeModal() {
    this.setState({
      fileError: false
    })
  }

  render() {
    const {
      file,
      fileId,
      handleFileSelect,
      handleFileUpload,
      isUploading,
      noUploadButton,
      userId,
      id
    } = this.props

    return (
      <React.Fragment>
        <label htmlFor={fileId} style={{ width: '100%' }}>
          <div className={styles.uploadBox}>
            <span>Click to select file</span>
            {file && <div>{file.name}</div>}
            <input
              type="file"
              required
              className={styles.fileInput}
              name={fileId}
              id={fileId}
              onChange={e => {
                const file = e.target.files[0]
                if (e.target.validity.valid) {
                  if (this.isFileValid(file)) {
                    handleFileSelect(file)
                  } else {
                    e.target.value = null
                    this.setState({
                      fileError: true
                    })
                  }
                }
              }}
            />

            {!noUploadButton && file && file.name && (
              <div style={{ marginTop: '20px' }}>
                <Button
                  color="info"
                  disabled={isUploading}
                  onClick={() => {
                    const variables = {
                      userId: userId,
                      file
                    }
                    variables.id = id
                    if (fileId === 'company-admin-file') {
                      variables.companyId = id
                    }
                    handleFileUpload({
                      variables: variables
                    })
                  }}
                >
                  Upload
                </Button>
              </div>
            )}
          </div>
        </label>
        <PopUpMessage
          isVisible={this.state.fileError}
          message={this.state.fileErrorMsg}
          closeModal={this.closeModal}
          error
        />
      </React.Fragment>
    )
  }
}

FileUploadField.propTypes = {
  handleFileSelect: PropTypes.func.isRequired,
  noUploadButton: PropTypes.bool.isRequired,
  handleFileUpload: PropTypes.func,
  /* id is depend on mutation what kind of id it need (userId, invoiceId) */
  id: PropTypes.number,
  userId: PropTypes.number,
  isUploading: PropTypes.bool,
  file: PropTypes.object,
  fileId: PropTypes.string
}

FileUploadField.defaultProps = {
  noUploadButton: false
}

export default FileUploadField
