import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Component */
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'
import AgencyBasicInfoContainer from '../../../containers/AgencyDetailContainer/AgencyBasicInfoContainer'
import DescriptionContainer from '../../../containers/AgencyDetailContainer/DescriptionContainer'

/* Apollo */
import { Query, compose, graphql } from 'react-apollo'
import { GET_LOCAL_AGENCY_DETAIL } from '../../../schemas/queries'
import {
  UPDATE_AGENCY_DETAIL,
  UPDATE_LOCAL_AGENCY_DETAIL,
  UPDATE_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM,
  UPLOAD_AGENCY_LOGO,
  UPLOAD_AGENCY_COVER
} from '../../../schemas/mutations'

/**
 * AgencySettings - main wrapper component for the Agency Information page
 *
 * @returns {Component}
 */
const AgencySettings = props => {
  const [imageFile, setImageFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [agencyCoverFile, setAgencyCoverFile] = useState(null)
  const [fileType, setFileType] = useState('')
  const [imagePreviewUrl, setImagePreviewUrl] = useState('')
  const [coverPreviewUrl, setCoverPreviewUrl] = useState('')
  const [uploadType, setUploadType] = useState('')
  const [isCroppingVisible, setIsCroppingVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const updateLocalAgencyDetailState = (key, value) => {
    const { updateLocalAgencyDetail } = props
    updateLocalAgencyDetail({
      variables: {
        key: key,
        value: value
      }
    })
  }
  const updateLocalAgencyDetailStateForNewForm = (key, value) => {
    const { updateLocalAgencyDetailForNewForm } = props
    updateLocalAgencyDetailForNewForm({
      variables: {
        key: key,
        value: value
      }
    })
  }

  const handleChangeForExistingForm = e => {
    updateLocalAgencyDetailState(e.target.name, e.target.value)
  }

  const handleChangeForNewForm = e => {
    updateLocalAgencyDetailStateForNewForm(e.target.name, e.target.value)
  }

  /**
   *
   * @function handleCroppingImage - handle when user done with cropping image
   *
   * @param {String} url
   * @param {File} blobFile
   */
  const handleCroppingImage = (url, blobFile) => {
    switch (uploadType) {
    case 'logo':
      setImagePreviewUrl(url)

      if (blobFile) {
        // for passing image blob file to backend API
        setImageFile(blobFile)
      }
      break
    case 'agencyCover':
      setCoverPreviewUrl(url)
      if (blobFile) {
        // for passing image blob file to backend API
        setAgencyCoverFile(blobFile)
      }
      break
    default:
      break
    }
  }

  /**
   *
   * @function handleCroppingVisibility - handle when user cropping or not cropping the image
   *
   * @param {Boolean} visible
   */
  const handleCroppingVisibility = visible => {
    setIsCroppingVisible(visible)
  }

  /**
   *
   * @function handleImageChange - handle when user select new image
   *
   * @param {File} imageFile
   */
  const handleImageChange = e => {
    if (window.FileReader) {
      let file = e.target.files[0]
      let reader = new FileReader()

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
      }

      //reset input file
      e.target.value = null
      switch (e.target.name) {
      case 'agencyLogo':
        reader.onloadend = () => {
          let imagePreviewUrl = reader.result
          setImagePreviewUrl(imagePreviewUrl)
          setFileType(file.type)
          setUploadType('logo')
          handleCroppingVisibility(true)
        }
        break
      case 'agencyCover':
        reader.onloadend = () => {
          let agencyCoverPreviewUrl = reader.result
          setCoverPreviewUrl(agencyCoverPreviewUrl)
          setFileType(file.type)
          setUploadType('agencyCover')
          handleCroppingVisibility(true)
        }
        break
      default:
        break
      }
    }
  }

  /**
   *
   * @function saveAgencyDetail - handle when user updating the field
   * and it will trigger the network mutation
   *
   * @param apolloClient
   */
  const saveAgencyDetail = async apolloClient => {
    setIsLoading(true)

    const { agencyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_AGENCY_DETAIL
    })

    const isActiveBoolean = agencyDetail.isActive === 'true' ? true : false
    const isFeaturedBoolean = agencyDetail.isFeatured === 'true' ? true : false
    // then update
    const formPayload = {
      name: agencyDetail.name,
      websiteUrl: agencyDetail.websiteUrl,
      facebookUrl: agencyDetail.facebookUrl,
      description: agencyDetail.description,
      descriptionHtml: agencyDetail.descriptionHtml,
      address: agencyDetail.address,
      contactNum: agencyDetail.contactNum,
      email: agencyDetail.email,
      poeaLicense: agencyDetail.poeaLicense,
      doleLicense: agencyDetail.doleLicense,
      foundingYear: agencyDetail.foundingYear,
      companySizeId:
        agencyDetail.companySize && agencyDetail.companySize.id
          ? agencyDetail.companySize.id
          : null,
      isActive: isActiveBoolean,
      isFeatured: isFeaturedBoolean,
      locationId:
        agencyDetail.location && agencyDetail.location.id
          ? agencyDetail.location.id
          : null
    }

    let response = await apolloClient.mutate({
      mutation: UPDATE_AGENCY_DETAIL,
      variables: {
        companyId: agencyDetail.id,
        updateHeadhunterCompanyInput: formPayload
      }
    })
    let logoResponse, coverResponse

    if (imageFile !== null) {
      try {
        logoResponse = await apolloClient.mutate({
          mutation: UPLOAD_AGENCY_LOGO,
          variables: {
            companyId: agencyDetail.id,
            logo: imageFile
          }
        })
      } catch (err) {
        console.log('err', err)
      }
    }

    if (agencyCoverFile !== null) {
      try {
        coverResponse = await apolloClient.mutate({
          mutation: UPLOAD_AGENCY_COVER,
          variables: {
            companyId: agencyDetail.id,
            cover: agencyCoverFile
          }
        })
      } catch (err) {
        console.log('err', err)
      }
    }

    // if image file or agencycover file is not empty, check if updating logo and general update status is successful
    if (imageFile !== null || agencyCoverFile !== null) {
      // if both logo and cover exists, check if both has been successfully updated
      if (imageFile && agencyCoverFile) {
        if (
          response.data &&
          response.data.updateHeadhunterCompany &&
          response.data.updateHeadhunterCompany.ok &&
          logoResponse &&
          logoResponse.data &&
          logoResponse.data.uploadHeadhunterCompanyLogo &&
          logoResponse.data.uploadHeadhunterCompanyLogo.ok &&
          coverResponse &&
          coverResponse.data &&
          coverResponse.data.uploadHeadhunterCompanyCover &&
          coverResponse.data.uploadHeadhunterCompanyCover.ok
        ) {
          setIsLoading(false)
          window.location.reload()
        } else {
          setIsLoading(false)
          setErrorMessage(
            'Failed to update images, please try again or notify engineering team'
          )
        }
      } else if (imageFile) {
        // checking for just logo
        if (
          response.data &&
          response.data.updateHeadhunterCompany &&
          response.data.updateHeadhunterCompany.ok &&
          logoResponse &&
          logoResponse.data &&
          logoResponse.data.uploadHeadhunterCompanyLogo &&
          logoResponse.data.uploadHeadhunterCompanyLogo.ok
        ) {
          setIsLoading(false)
          window.location.reload()
        } else {
          setIsLoading(false)
          setErrorMessage(
            'Failed to update logo image, please try again or notify engineering team'
          )
        }
      } else {
        // checking for just cover
        if (
          response.data &&
          response.data.updateHeadhunterCompany &&
          response.data.updateHeadhunterCompany.ok &&
          coverResponse &&
          coverResponse.data &&
          coverResponse.data.uploadHeadhunterCompanyCover &&
          coverResponse.data.uploadHeadhunterCompanyCover.ok
        ) {
          setIsLoading(false)
          window.location.reload()
        } else {
          setIsLoading(false)
          setErrorMessage(
            'Failed to update cover image, please try again or notify engineering team'
          )
        }
      }
    } else {
      // if image file or agency cover is empty, just check if general update status
      if (
        response.data &&
        response.data.updateHeadhunterCompany &&
        response.data.updateHeadhunterCompany.ok
      ) {
        setIsLoading(false)
        window.location.reload()
      } else {
        setIsLoading(false)
        setErrorMessage(
          'Failed to update, please try again or notify engineering team'
        )
      }
    }
  }

  return (
    <Query query={GET_LOCAL_AGENCY_DETAIL}>
      {({ loading, data: { agencyDetail } }) => {
        if (loading) {
          return <Loading />
        }
        return (
          <div>
            {/** Basic Agency Information section */}
            <SectionWrapper
              sectionTitle="Basic Information"
              style={{ marginTop: '20px' }}
            >
              <AgencyBasicInfoContainer
                {...props}
                agencyDetail={agencyDetail}
                handleChangeForExistingForm={handleChangeForExistingForm}
                handleChangeForNewForm={handleChangeForNewForm}
                saveAgencyDetail={saveAgencyDetail}
                handleImageChange={handleImageChange}
                uploadType={uploadType}
                fileType={fileType}
                imagePreviewUrl={imagePreviewUrl}
                coverPreviewUrl={coverPreviewUrl}
                handleCloseModal={handleCroppingVisibility}
                handleCroppingImage={handleCroppingImage}
                handleCroppingVisibility={handleCroppingVisibility}
                isCroppingVisible={isCroppingVisible}
                imageFile={imageFile}
                errorMessage={errorMessage}
                // agencyCoverFile={agencyCoverFile}
              />
            </SectionWrapper>

            {/* * Agency description section */}
            {props.match.params.id !== 'new' ? (
              <>
                <SectionWrapper
                  sectionTitle="Description"
                  style={{ marginTop: '20px' }}
                >
                  <DescriptionContainer
                    {...props}
                    agencyDetail={agencyDetail}
                    handleChangeForExistingForm={handleChangeForExistingForm}
                    saveAgencyDetail={saveAgencyDetail}
                    isLoading={isLoading}
                  />
                </SectionWrapper>
              </>
            ) : null}
          </div>
        )
      }}
    </Query>
  )
}

AgencySettings.propTypes = {
  match: PropTypes.object,
  agencyDetail: PropTypes.object
  // client:PropTypes.string,
}
// export default AgencySettings

export default compose(
  graphql(UPDATE_LOCAL_AGENCY_DETAIL, {
    name: 'updateLocalAgencyDetail'
  }),
  graphql(UPDATE_LOCAL_AGENCY_DETAIL_FOR_NEW_FORM, {
    name: 'updateLocalAgencyDetailForNewForm'
  })
)(AgencySettings)
