import gql from 'graphql-tag'

/* ==== Local State ==== */
/* Bossjob user */
export const UPDATE_LOCAL_ROLE_NODES = gql`
  mutation updateLocalRoleNodes($access: RoleNodeAccess!, $roleNodeId: Int!) {
    updateRoleNode(access: $access, roleNodeId: $roleNodeId) @client
  }
`
/* ==== End Local State ==== */

/** Add new role **/
export const ADD_ROLE = gql`
  mutation AddRole($name: String) {
    createRole(roleName: $name) {
      ok
    }
  }
`

/** Delete role **/
export const DELETE_ROLE = gql`
  mutation DeleteRole($roleId: ID!) {
    deleteRole(roleId: $roleId) {
      ok
      message
    }
  }
`

/** Update a role **/
export const UPDATE_ROLE = gql`
  mutation UpdateRole($data: UpdateRoleType!, $roleId: ID!) {
    updateRole(data: $data, roleId: $roleId) {
      ok
    }
  }
`

/** Update a role node access **/
export const UPDATE_ROLE_NODE = gql`
  mutation UpdateRoleNode($access: RoleNodeAccessType!, $roleNodeId: ID!) {
    updateRoleNode(access: $access, roleNodeId: $roleNodeId) {
      ok
    }
  }
`

/** activate roles **/
export const ACTIVATE_ROLES = gql`
  mutation ActiveRoles($ids: [Int]!) {
    activateRole(roleId: $ids) {
      ok
    }
  }
`

/** deactivate roles **/
export const DEACTIVATE_ROLES = gql`
  mutation DeactiveRoles($ids: [Int]!) {
    deactivateRole(roleId: $ids) {
      ok
      message
    }
  }
`
