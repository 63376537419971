import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_DIALOGUE_CHAT_MESSAGES } from '../../schemas/queries'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Components */
import Loading from 'shared/components/Loading'
import MessageList from '../../views/ChatDetail/MessageList'

/**
 * DialogueDetailContainer
 *
 * @returns {Component}
 */
class DialogueDetailContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      dialogueId: props.match.params.dialogueId,
      page: props.match.params.page
    }
  }

  render() {
    const { dialogueId, page } = this.state
    let variables = {
      dialogueId: dialogueId,
      page: page
    }
    return (
      <Query query={GET_DIALOGUE_CHAT_MESSAGES} variables={variables}>
        {({ loading, data: { allChats }, client }) => {
          if (loading) return <Loading />

          client.writeData({
            data: {
              dialogueDetail: allChats.data.dialogue
            }
          })

          return (
            <MessageList
              messagesList={allChats.data.chats}
              currentPage={page}
              totalCount={allChats.data.totalCount}
              totalPages={allChats.data.totalPages}
            />
          )
        }}
      </Query>
    )
  }
}

export default withRouter(DialogueDetailContainer)
