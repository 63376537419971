import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'

import {
  GET_SUBSCRIPTION_PLAN_DETAIL,
  GET_LOCAL_SUBSCRIPTION_PLAN_DETAIL_FOR_NEW_FORM
} from '../../schemas/queries'

import {
  UPDATE_LOCAL_SUBSCRIPTION_PLAN_DETAIL,
  UPDATE_LOCAL_SUBSCRIPTION_PLAN_DETAIL_FOR_NEW_FORM
} from '../../schemas/mutations'

/* Components */
import DetailForm from '../../views/SubscriptionPlanDetail/DetailForm'
import NewForm from '../../views/SubscriptionPlanDetail/NewForm'
import Loading from 'shared/components/Loading'

/**
 * SubscriptionPlanDetailContainer
 *
 * @returns {Component}
 */
class SubscriptionPlanDetailContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    updateLocalSubscriptionPlanDetail: PropTypes.func.isRequired,
    updateLocalSubscriptionPlanDetailForNewForm: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      subscriptionPlanId: props.match.params.subscriptionPlanId
    }
  }

  _updateLocalSubscriptionPlanDetailState = (key, value) => {
    const { updateLocalSubscriptionPlanDetail } = this.props
    updateLocalSubscriptionPlanDetail({
      variables: {
        key: key,
        value: value
      }
    })
  }

  handleChange = e => {
    this._updateLocalSubscriptionPlanDetailState(e.target.name, e.target.value)
  }

  handleChangeForToggleButton = e => {
    let is_enabled = e.target.checked === true
    this._updateLocalSubscriptionPlanDetailState(e.target.name, is_enabled)
  }

  _updateLocalSubscriptionPlanDetailForNewFormState = (key, value) => {
    const { updateLocalSubscriptionPlanDetailForNewForm } = this.props
    updateLocalSubscriptionPlanDetailForNewForm({
      variables: {
        key: key,
        value: value
      }
    })
  }

  handleChangeForNewForm = e => {
    let name = e.target.name

    if (name == 'category') {
      name = 'categoryId'
    }
    this._updateLocalSubscriptionPlanDetailForNewFormState(name, e.target.value)
  }

  handleChangeForNewFormToggleButton = e => {
    let is_enabled = e.target.checked === true
    this._updateLocalSubscriptionPlanDetailForNewFormState(
      e.target.name,
      is_enabled
    )
  }

  renderDetailForm(subscriptionPlanId) {
    let variables = {
      subscriptionPlanId: subscriptionPlanId
    }

    return (
      <Query query={GET_SUBSCRIPTION_PLAN_DETAIL} variables={variables}>
        {({ loading, data: { subscriptionPlan }, client, refetch }) => {
          if (loading) return <Loading />
          client.writeData({
            data: {
              subscriptionPlanDetail: subscriptionPlan.data
            }
          })

          let isDisabled =
            subscriptionPlan.data.categoryId !== 4 ||
            subscriptionPlan.data.id == 5
              ? true
              : false

          return (
            <DetailForm
              subscriptionPlan={subscriptionPlan.data}
              handleChange={this.handleChange}
              handleChangeForToggleButton={this.handleChangeForToggleButton}
              isDisabled={isDisabled}
              refetch={refetch}
            />
          )
        }}
      </Query>
    )
  }

  renderNewForm() {
    return (
      <Query query={GET_LOCAL_SUBSCRIPTION_PLAN_DETAIL_FOR_NEW_FORM}>
        {({ loading, data: { subscriptionPlanDetailForNewForm }, refetch }) => {
          if (loading) return <Loading />

          return (
            <NewForm
              subscriptionPlan={subscriptionPlanDetailForNewForm}
              handleChange={this.handleChangeForNewForm}
              handleChangeForToggleButton={
                this.handleChangeForNewFormToggleButton
              }
              isDisabled={false}
              refetch={refetch}
            />
          )
        }}
      </Query>
    )
  }

  render() {
    const { subscriptionPlanId } = this.state
    if (subscriptionPlanId) {
      return this.renderDetailForm(subscriptionPlanId)
    } else {
      return this.renderNewForm()
    }
  }
}

export default compose(
  graphql(UPDATE_LOCAL_SUBSCRIPTION_PLAN_DETAIL, {
    name: 'updateLocalSubscriptionPlanDetail'
  }),
  graphql(UPDATE_LOCAL_SUBSCRIPTION_PLAN_DETAIL_FOR_NEW_FORM, {
    name: 'updateLocalSubscriptionPlanDetailForNewForm'
  })
)(withRouter(SubscriptionPlanDetailContainer))
