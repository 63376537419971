import {
  GET_LOCAL_BOSSHUNT_USER_DETAIL,
  GET_LOCAL_BOSSHUNT_USER_DETAIL_FOR_NEW_FORM
} from '../schemas/queries'

export default {
  updateLocalBosshuntUser: (_, { key, value, category, del }, { cache }) => {
    const { bosshuntUserDetail } = cache.readQuery({
      query: GET_LOCAL_BOSSHUNT_USER_DETAIL
    })

    let newBosshuntUserDetail
    const newServiceList = [...bosshuntUserDetail.services]
    const newIndustryList = [...bosshuntUserDetail.industries]
    const newServiceLocationList = [...bosshuntUserDetail.serviceLocations]

    switch (key) {
    case 'company':
      // for nested objects with id, without including __typename will throw error
      // reference: https://github.com/apollographql/apollo-client/issues/2510
      newBosshuntUserDetail = { id: Number(value), __typename: 'CompanyType' }
      cache.writeData({
        data: {
          bosshuntUserDetail: {
            ...bosshuntUserDetail,
            company: newBosshuntUserDetail,
            __typename: 'updatedHeadhunterType'
          }
        }
      })
      return null
    case 'services':
      newBosshuntUserDetail = {
        id: value,
        name: '',
        __typename: 'ServiceType'
      }
      // del represents index of item to be deleted
      if (del !== null && del >= 0) {
        const updatedServiceList = newServiceList.filter(
          (object, i) => i !== del
        )
        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              services: updatedServiceList
            }
          }
        })
      } else {
        //filter off object with id == ''
        const filteredNewServiceList = newServiceList.filter(
          object => object.id
        )
        // then push new value to array
        filteredNewServiceList.push(newBosshuntUserDetail)

        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              services: filteredNewServiceList
            }
          }
        })
      }
      return null
    case 'industries':
      newBosshuntUserDetail = {
        id: value,
        name: '',
        __typename: 'IndustryType'
      }
      // del represents index of item to be deleted
      if (del !== null && del >= 0) {
        const updatedIndustryList = newIndustryList.filter(
          (object, i) => i !== del
        )
        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              industries: updatedIndustryList
            }
          }
        })
      } else {
        //filter off object with id == ''
        const filteredNewIndustryList = newIndustryList.filter(
          object => object.id
        )
        //
        // then push new value to array
        filteredNewIndustryList.push(newBosshuntUserDetail)

        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              industries: filteredNewIndustryList
            }
          }
        })
      }
      return null
    case 'serviceLocations':
      newBosshuntUserDetail = {
        id: value,
        name: '',
        __typename: 'ServiceLocationType'
      }

      // del represents index of item to be deleted
      if (del !== null && del >= 0) {
        const updatedServiceLocationList = newServiceLocationList.filter(
          (object, i) => i !== del
        )

        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              serviceLocations: updatedServiceLocationList
            }
          }
        })
      } else {
        //filter off object with id == ''
        const filteredNewServiceLocationList = newServiceLocationList.filter(
          object => object.id
        )

        // then push new value to array
        filteredNewServiceLocationList.push(newBosshuntUserDetail)

        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              serviceLocations: filteredNewServiceLocationList
            }
          }
        })
      }
      return null
    default:
      // whenever apollo client fetches query results from server, it will automatically caches results locally
      // apollo client caches data by matching id & __typename
      // hence, to prevent mutating the single source of truth (the initial data fetched from server),
      // we intentionally change __typename
      switch (category) {
      case 'feeStructure':
        newBosshuntUserDetail = {
          [key]: value,
          __typename: 'HeadhunterFeeStructureType'
        }
        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              feeStructure: {
                ...bosshuntUserDetail.feeStructure,
                ...newBosshuntUserDetail
              },
              __typename: 'updatedHeadhunterType'
            }
          }
        })
        return null
      case 'turnaround':
        newBosshuntUserDetail = {
          [key]: Number(value),
          __typename: 'HeadhunterTurnaroundTimeType'
        }
        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              turnaroundTime: {
                ...bosshuntUserDetail.turnaroundTime,
                ...newBosshuntUserDetail
              },
              __typename: 'updatedHeadhunterType'
            }
          }
        })
        return null
      case 'guarantee':
        newBosshuntUserDetail = {
          [key]: Number(value),
          __typename: 'HeadhunterGuaranteePeriodType'
        }
        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              guaranteePeriod: {
                ...bosshuntUserDetail.guaranteePeriod,
                ...newBosshuntUserDetail
              },
              __typename: 'updatedHeadhunterType'
            }
          }
        })
        return null
      case 'replacement':
        newBosshuntUserDetail = {
          [key]: value,
          __typename: 'HeadhunterFreeReplacementType'
        }
        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              freeReplacement: {
                ...bosshuntUserDetail.freeReplacement,
                ...newBosshuntUserDetail
              },
              __typename: 'updatedHeadhunterType'
            }
          }
        })
        return null
      default:
        newBosshuntUserDetail = {
          [key]: value,
          __typename: 'updatedHeahunterType'
        }

        cache.writeData({
          data: {
            bosshuntUserDetail: {
              ...bosshuntUserDetail,
              ...newBosshuntUserDetail
            }
          }
        })
      }
      return null
    }
  },
  updateLocalBosshuntUserForNewForm: (_, { key, value }, { cache }) => {
    const { bosshuntUserDetailForNewForm } = cache.readQuery({
      query: GET_LOCAL_BOSSHUNT_USER_DETAIL_FOR_NEW_FORM
    })

    let newBosshuntUserDetailForNewForm
    if (key === 'company') {
      // for nested objects with id, without including __typename will throw error
      // reference: https://github.com/apollographql/apollo-client/issues/2510
      newBosshuntUserDetailForNewForm = {
        id: Number(value),
        __typename: 'CompanyType'
      }
      cache.writeData({
        data: {
          bosshuntUserDetailForNewForm: {
            ...bosshuntUserDetailForNewForm,
            company: newBosshuntUserDetailForNewForm
          }
        }
      })
    } else {
      newBosshuntUserDetailForNewForm = { [key]: value }

      cache.writeData({
        data: {
          bosshuntUserDetailForNewForm: {
            ...bosshuntUserDetailForNewForm,
            ...newBosshuntUserDetailForNewForm
          }
        }
      })
    }
    return null
  }
}
