exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".star__3MwIJ {\n  width: 24px;\n  height: 24px;\n}\n.star__3MwIJ:not(:last-child) {\n  margin-right: 15px;\n}", "", {"version":3,"sources":["/app/src/modules/companies/views/CompanyDetail/ReviewDetailWrapper/CompanyReviewDetail/CompanyReviewDetail.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAW;EACX,aAAY;CACd;AACA;EACE,mBAAkB;CACpB","file":"CompanyReviewDetail.module.scss","sourcesContent":[".star {\n  width: 24px;\n  height: 24px;\n}\n.star:not(:last-child) {\n  margin-right: 15px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"star": "star__3MwIJ"
};