import React from 'react'

/**
 * NotAuthorize - to render when user is not authorized to access the page
 */
const NotAuthorize = () => (
  <div>
    <h3>You have no permission to access this page</h3>
  </div>
)

export default NotAuthorize
