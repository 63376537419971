import config from 'shared/configs/config.json'

export const industryList = () => {
  return config.industries
}

export const getIndustryNameById = industryId => {
  let industryList = config.industries
  const getObject = industryList.find(({ id }) => id === Number(industryId))
  return getObject && getObject.name ? getObject.name : null
}
