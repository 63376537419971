import React from 'react'
import PropTypes from 'prop-types'

/* Bootstrap */
import { Row, Col, Card, CardBody, Label } from 'reactstrap'

/* Helpers */
import dialogueStatusMapper from 'shared/helpers/dialogueStatusMapper'
/**
 * ChatDetailHeader - Renders a Header Bar with the specific Dialogue details
 *
 * returns {Component}
 */
const ChatDetailHeader = ({
  id,
  jobApplicationId,
  initiatedBy,
  initiatedTo,
  createdAt,
  statusId
}) => (
  <Col md="12" style={{ marginBottom: 25 }}>
    <Card>
      <CardBody>
        <Row>
          <Col>
            <Label>
              <b>Dialogue ID:</b>
            </Label>
            <a href="">{id}</a>
          </Col>
          <Col>
            <Label>
              <th>Job Application ID</th>
            </Label>
            <span>{jobApplicationId}</span>
          </Col>
          <Col>
            <Label>
              <b>Initiated By:</b>
            </Label>
            <a href="">{initiatedBy}</a>
          </Col>
          <Col>
            <Label>
              <b>Initiated To:</b>
            </Label>
            <a href="">{initiatedTo}</a>
          </Col>
          <Col>
            <Label>
              <b>Chat created on:</b>
            </Label>
            <span>{createdAt}</span>
          </Col>
          <Col>
            <Label>
              <b>Status:</b>
            </Label>
            <span>{dialogueStatusMapper(statusId)}</span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
)

ChatDetailHeader.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  jobApplicationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  initiatedBy: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  initiatedTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  createdAt: PropTypes.string,
  statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default ChatDetailHeader
