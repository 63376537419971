import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { Query, compose, graphql } from 'react-apollo'
import { GET_LOCAL_COMPANY_DETAIL } from '../../../schemas/queries'
import {
  UPDATE_LOCAL_COMPANY_DETAIL,
  UPDATE_LOCAL_COMPANY_BENEFIT,
  UPDATE_LOCAL_COMPANY_CULTURE,
  UPDATE_COMPANY_DETAIL,
  ACTIVATE_COMPANY,
  DEACTIVATE_COMPANY
} from '../../../schemas/mutations'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import DescriptionForm from '../../../views/CompanyDetail/CompanyInformation/DescriptionForm'
import CompanyCulture from '../../../views/CompanyDetail/CompanyInformation/CompanyCulture'
import Benefits from '../../../views/CompanyDetail/CompanyInformation/Benefits'
import CompanyStatus from '../../../views/CompanyDetail/CompanyInformation/CompanyStatus'
import CompanyWorkingAddress from '../../../views/CompanyDetail/CompanyInformation/CompanyWorkingAddress'

/**
 * CompanyFormContainer - container component to mutate Bossjob company description
 * and refresh the page when is done
 *
 * @returns {Component}
 *
 */

const CompanyFormContainer = ({
  config,
  updateLocalCompanyBenefit,
  updateLocalCompanyCulture,
  canEdit
}) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [deleteCompanyReason, setDeleteCompanyReason] = useState('')

  const handleUserInputUpdate = (apolloClient, key, value) => {
    apolloClient.mutate({
      mutation: UPDATE_LOCAL_COMPANY_DETAIL,
      variables: {
        key: key,
        value: value
      }
    })
  }

  const saveCompanyDetail = async apolloClient => {
    setIsUpdating(true)

    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let benefits = companyDetail.benefits.map(benefit => benefit.benefitId)
    let cultures = companyDetail.cultures.map(culture => culture.cultureId)
    benefits = benefits.filter(q => q)
    cultures = cultures.filter(q => q)

    const formPayload = {
      description: companyDetail.description,
      descriptionHtml: companyDetail.descriptionHtml,
      culturesId: cultures,
      benefitsId: benefits,
      industryKey: companyDetail.industryKey,
      countryKey: companyDetail.countryKey,
      locationKey: companyDetail.locationKey,
      companySizeKey: companyDetail.companySizeKey
        ? companyDetail.companySizeKey
        : '0_to_50',
      name: companyDetail.name
    }

    let response = await apolloClient.mutate({
      mutation: UPDATE_COMPANY_DETAIL,
      variables: {
        companyId: companyDetail.id,
        payload: formPayload
      }
    })

    if (response) {
      window.location.reload()
    }
  }

  const activateCompany = async apolloClient => {
    setIsUpdating(true)

    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let response = await apolloClient.mutate({
      mutation: ACTIVATE_COMPANY,
      variables: {
        companyId: companyDetail.id
      }
    })

    if (response) {
      window.location.reload()
    }
  }

  const deactivateCompany = async apolloClient => {
    setIsUpdating(true)

    const { companyDetail } = apolloClient.readQuery({
      query: GET_LOCAL_COMPANY_DETAIL
    })

    let response = await apolloClient.mutate({
      mutation: DEACTIVATE_COMPANY,
      variables: {
        companyId: companyDetail.id,
        deleteCompanyReason: deleteCompanyReason
      }
    })

    if (response) {
      window.location.reload()
    }
  }

  const handleBenefitFieldChange = e => {
    updateLocalCompanyBenefit({
      variables: {
        benefitId: e.target.value,
        isChecked: e.target.checked
      }
    })
  }

  const handleCultureFieldChange = e => {
    updateLocalCompanyCulture({
      variables: {
        cultureId: e.target.value,
        isChecked: e.target.checked
      }
    })
  }

  const handleDeleteCompanyReason = e => {
    setDeleteCompanyReason(e)
  }
  return (
    <Query query={GET_LOCAL_COMPANY_DETAIL}>
      {({ data: { companyDetail }, client }) => {
        return (
          <React.Fragment>
            {/* Description */}
            <SectionWrapper
              sectionTitle="Description"
              style={{ marginTop: '20px' }}
            >
              <DescriptionForm
                description={companyDetail.description}
                descriptionHtml={companyDetail.descriptionHtml}
                handleChange={handleUserInputUpdate}
                saveCompany={saveCompanyDetail}
                isLoading={isUpdating}
                apolloClient={client}
                canEdit={canEdit}
              />
            </SectionWrapper>

            {/* Company Working Address */}
            <SectionWrapper
              sectionTitle="Company Working Addresses"
              style={{ marginTop: '20px' }}
            >
              <CompanyWorkingAddress
                companyId={companyDetail.id}
                canEdit={canEdit}
              />
            </SectionWrapper>

            {/* Company Culture */}
            <SectionWrapper
              sectionTitle="Company Cultures"
              style={{ marginTop: '20px' }}
            >
              {config && (
                <CompanyCulture
                  cultures={companyDetail.cultures}
                  handleChange={handleCultureFieldChange}
                  saveCompany={saveCompanyDetail}
                  isLoading={isUpdating}
                  apolloClient={client}
                  config={config}
                  canEdit={canEdit}
                />
              )}
            </SectionWrapper>

            {/* Company Benefits */}
            <SectionWrapper
              sectionTitle="Benefits"
              style={{ marginTop: '20px' }}
            >
              {config && (
                <Benefits
                  benefits={companyDetail.benefits}
                  handleChange={handleBenefitFieldChange}
                  saveCompany={saveCompanyDetail}
                  isLoading={isUpdating}
                  apolloClient={client}
                  config={config}
                  canEdit={canEdit}
                />
              )}
            </SectionWrapper>

            {/* Company Status */}
            {companyDetail.deletedAt ? null : (
              <SectionWrapper
                sectionTitle="Company Status"
                style={{ marginTop: '20px' }}
              >
                <CompanyStatus
                  handleChange={handleUserInputUpdate}
                  isActive={companyDetail.isActive}
                  isVerify={companyDetail.isVerify}
                  isLoading={isUpdating}
                  apolloClient={client}
                  activateCompany={activateCompany}
                  deactivateCompany={deactivateCompany}
                  handleDeleteCompanyReason={handleDeleteCompanyReason}
                  canEdit={canEdit}
                />
              </SectionWrapper>
            )}
          </React.Fragment>
        )
      }}
    </Query>
  )
}

CompanyFormContainer.propTypes = {
  config: PropTypes.object,
  updateLocalCompanyBenefit: PropTypes.func.isRequired,
  updateLocalCompanyCulture: PropTypes.func.isRequired,
  canEdit: PropTypes.bool
}

export default compose(
  graphql(UPDATE_LOCAL_COMPANY_CULTURE, {
    name: 'updateLocalCompanyCulture'
  }),
  graphql(UPDATE_LOCAL_COMPANY_BENEFIT, {
    name: 'updateLocalCompanyBenefit'
  })
)(CompanyFormContainer)
