import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* Apollo */
import { compose, graphql } from 'react-apollo'
import { DELETE_COMPANY_REVIEWS } from '../../../schemas/mutations'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import CompanyReviewListContainer from '../../../containers/CompanyDetailContainers/CompanyReviewListContainer'

/* Permission */
import { Can } from 'shared/configs/abilityContextConfig'

/**
 * Recruiters - main view for Recruiter list under Company page
 *
 */
class CompanyReviews extends Component {
  static propTypes = {
    deleteReviews: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedReviews: []
    }

    this.appendSelectedReview = this.appendSelectedReview.bind(this)
    this.removeSelectedReview = this.removeSelectedReview.bind(this)
    this.deleteReviews = this.deleteReviews.bind(this)
  }

  /**
   *
   * @function appendSelectedReview - Append reviewId to selectedReviews state
   * @param {Number} reviewId
   */
  appendSelectedReview(reviewId) {
    this.setState({
      selectedReviews: [...this.state.selectedReviews, Number(reviewId)]
    })
  }

  /**
   *
   * @function removeSelectedReview - Remove specific reviewId from selectedReviews state
   * @param {Number} reviewId
   */
  removeSelectedReview(reviewId) {
    let selectedReviews = this.state.selectedReviews
    selectedReviews.splice(
      this.state.selectedReviews.indexOf(Number(reviewId)),
      1
    )
    this.setState({
      selectedReviews: selectedReviews
    })
  }

  async deleteReviews() {
    if (this.state.selectedReviews.length === 0) return

    try {
      let response = await this.props.deleteReviews({
        variables: {
          ids: this.state.selectedReviews
        }
      })

      if (response) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    return (
      <div>
        <Can I="edit" a="companies" passThrough>
          {can => (
            <SectionWrapper
              sectionTitle="Company Reviews"
              handleDelete={can ? this.deleteReviews : null}
            >
              <CompanyReviewListContainer
                appendSelectedReview={this.appendSelectedReview}
                removeSelectedReview={this.removeSelectedReview}
              />
            </SectionWrapper>
          )}
        </Can>
      </div>
    )
  }
}

export default compose(
  graphql(DELETE_COMPANY_REVIEWS, { name: 'deleteReviews' })
)(CompanyReviews)
