import React from 'react'
import PropTypes from 'prop-types'

/* Vendors */
import classNames from 'classnames'

/* Assets */
import styles from './Chip.module.scss'

/**
 * Chip Component
 *
 * @returns {Component}
 */
const Chip = ({ label, className, style, ...rest }) => (
  <span
    style={style}
    className={classNames([styles.chip, className])}
    {...rest}
  >
    {label}
  </span>
)

Chip.propTypes = {
  /* Chip label to be shown */
  label: PropTypes.string.isRequired,

  /* Additional classNames */
  className: PropTypes.string,

  /* Additional styles */
  style: PropTypes.object
}

export default Chip
