import React from 'react'

/* Components */
import SearchBar from 'shared/components/SearchBar'
import SectionWrapper from 'shared/components/SectionWrapper'
import ExportTransactionListButton from '../../containers/ExportTransactionListButton/ExportTransactionListButton'
import TransactionListContainer from '../../containers/TransactionListContainer'

const statusOptions = [
  { all: 'All' },
  { pending: 'Pending' },
  { processing: 'Processing' },
  { completed: 'Completed' },
  { cancelled: 'Cancelled' },
  { failed: 'Failed' },
  { failed: 'Refunded' }
]

const typeOptions = [
  { all: 'All' },
  { online: 'Online' },
  { offline: 'Offline' }
]

const Transaction = () => (
  <>
    <SearchBar
      hasStatus
      hasType
      placeholderForSearch={'Txn Ref No'}
      statusOptions={statusOptions}
      typeOptions={typeOptions}
    />

    <SectionWrapper
      sectionTitle="Transaction"
      otherActions={<ExportTransactionListButton />}
    >
      <TransactionListContainer />
    </SectionWrapper>
  </>
)

export default Transaction
